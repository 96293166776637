import React, { useState, useEffect } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";

export const WareHouseReturnTableView = (props) => {
    const [data, setData] = useState([]);
  const { materials } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);
  return (
    <div>
      <TableContainer className="tableContainer">
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="head-cell">SerialNo</TableCell>
              <TableCell className="head-cell">MaterialCode</TableCell>
              <TableCell className="head-cell">MaterialDescription</TableCell>
              <TableCell className="head-cell">UOM</TableCell>
              <TableCell className="head-cell">Activity/Machine</TableCell>
              <TableCell className="head-cell">Activity/Machine Description</TableCell>
              <TableCell className="head-cell">Return Quantity</TableCell>
              <TableCell className="head-cell">Accepted Qunatity</TableCell>
              <TableCell className="head-cell">Net Rate</TableCell>
              <TableCell className="head-cell">Item Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="table-data1">{row.SerialNo}</TableCell>
                <TableCell className="table-data2">{row.MaterialCode}</TableCell>
                <TableCell className="table-data2">{row.MaterialDescription}</TableCell>
                <TableCell className="table-data2">{row.UOM}</TableCell>
                <TableCell className="table-data2">
                  {row.ActivityMachineSelect}
                </TableCell>
                <TableCell className="table-data2">
                  {row.ActivityMachineDescription}
                </TableCell>
                <TableCell className="table-data2">{row.ReturnQty}</TableCell>
                <TableCell className="table-data2">{row.AcceptedQty}</TableCell>
                <TableCell className="table-data2">{row.NetRate}</TableCell>
                <TableCell className="table-data4">{row.ItemAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </div>
  );
}
