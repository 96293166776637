const initialState = {
    SingleEmployeeFunctionalRoleLink: {},
    AllEmployeeFunctionalRoleLink: [],
    SingleDefaultAccess: {},
    AllDefaultAccess: [],
    SingleAccessRequest: {},
    AllAccessRequest: [],
    SingleAccessRemovalRequest: {},
    AllAccessRemovalRequest: [],
};

const accessControl = (state = initialState, action) => {

    switch (action.type) {
        case "SET_SINGLE_EMPLOYEE_FUNCTIONAL_ROLE_LINK": {
            return {
                ...state,
                SingleEmployeeFunctionalRoleLink: action.payload
            }
        }

        case "SET_ALL_EMPLOYEE_FUNCTIONAL_ROLE_LINK": {
            return {
                ...state,
                AllEmployeeFunctionalRoleLink: action.payload
            }
        }

        case "SET_SINGLE_DEFAULT_ACCESS": {
            return {
                ...state,
                SingleDefaultAccess: action.payload
            }
        }

        case "SET_ALL_DEFAULT_ACCESS": {
            return {
                ...state,
                AllDefaultAccess: action.payload
            }
        }


        case "SET_SINGLE_ACCESS_REQUEST": {
            return {
                ...state,
                SingleAccessRequest: action.payload
            }
        }

        case "SET_ALL_ACCESS_REQUEST": {
            return {
                ...state,
                AllAccessRequest: action.payload
            }
        }
        case "SET_SINGLE_ACCESS_REMOVAL_REQUEST": {
            return {
                ...state,
                SingleAccessRemovalRequest: action.payload
            }
        }

        case "SET_ALL_ACCESS_REMOVAL_REQUEST": {
            return {
                ...state,
                AllAccessRemovalRequest: action.payload
            }
        }











        default: return state
    }


}
export default accessControl