import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { editDailyAttendance } from "../../../redux/actions/ProjectWorkforceManagementAction";
import { DailyAttendanceTableView } from "../../../Tables/ProjectWorkforceManagement/View/DailyAttendanceTableView";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";

import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import CustomDataGrid from "../../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";

export const OvertimeEntryDashboard = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const gridRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        {/* <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button> */}
      </div>
    );
    // return;
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    fetch();
  }, [dispatch, history, match.params.id]);

  async function fetch() {
    try {
      const response = await axios.get(
        `workforceManagement/dailyAttendence/${match.params.id}`
      );
      let data = response.data;
      console.log("dailyAttendence ->", data);
      setData(data);
    } catch (err) {
      dispatch(userLogout(history));
    }
  }

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleSubmit = async () => {
    const tableData = [];
    gridRef.current?.getRowModels()?.forEach(item => tableData.push({...item, Present: "Yes"}));
    await axios.put(`/workforceManagement/dailyAttendence/${match.params.id}`, {
      ...data,
      tableData,
    });
    fetch()
  };


  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const columns = [
    {
      field: "SerialNo",
      headerName: "Serial No",
      flex: 1,
    },
    {
      field: "TokenNo",
      headerName: "Token No",
      flex: 1,
    },
    {
      field: "WorkmenName",
      headerName: "Workmen Name",
      flex: 1,
    },
    {
      field: "Present",
      headerName: "Present",
      valueFormatter: ( { value } ) => value ? "Yes" : "No",
      flex: 1,
    },
    {
      field: "GangCode",
      headerName: "Gang Code",
      flex: 1,
    },
    {
      field: "ShiftCode",
      headerName: "Shift Code",
      flex: 1,
    },
    {
      field: "TimeIn",
      headerName: "Time In",
      flex: 1,
    },
    {
      field: "TimeOut",
      headerName: "TimeOut",
      flex: 1,
    },
    {
      field: "NormalHour",
      headerName: "Normal Hours",
      flex: 1,
    },
    {
      field: "Overtime",
      headerName: "Overtime",
      editable: true,
      flex: 1,
      valueFormatter: ({ api, value }) => {
        if (!gridRef.current) {
          gridRef.current = api;
        }
        return value;
      },
    },
    {
      field: "ActivityMachineSelect",
      headerName: "Activity / Machine",
      flex: 1,
    },
    {
      field: "ActivityMachineDesc",
      headerName: "Activity / Machine Description",
      flex: 1,
    },
  ];

  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Daily Attendance View"
        linkName1="Daily Attendance Dashboard"
        to1="/workforceManagement/dailyAttendence/"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Overtime Entries</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <CustomDataGrid
              columns={columns}
              rows={data?.tables || []}
              hideActions
              showFilter={false}
              hideFooter
            />
            <SubmitModelPopup
              handleSubmitForm={handleSubmit}
              formTitle="Overtime Entry"
              onFormClick={toggleModal}
              open={showModal}
              onClose={toggleModal}
              closeAfterTransition
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
