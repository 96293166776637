import { IconButton } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

const SalesOrderInvoiceTable = ({
  invoiceTable,
  salesOrderTable,
  staged,
  stageData,
  invoiceSavedData,
  invoiceEditData,
  invoiceTotalAmt,
  saveInvoiceTable,
  materialQty,
  disabled,
}) => {
  const [inputFields, setInputFields] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  // edit
  useEffect(() => {
    if (invoiceEditData) {
      setTotalAmt(invoiceTotalAmt);
      setInputFields([...invoiceEditData]);
    }
    // eslint-disable-next-line
  }, [invoiceEditData, invoiceTotalAmt]);

  // console.log("data->", invoiceTable, salesOrderTable, stageData);

  // create
  useEffect(() => {
    let values = [];
    if (invoiceTable) {
      for (let i = 0; i < invoiceTable.length; i++) {
        values = [
          ...values,
          {
            SlNo: invoiceTable[i].SlNo,
            BOQNo: invoiceTable[i].BOQNo,
            ItemDesc: invoiceTable[i].ItemDesc,
            UOM: invoiceTable[i].UOM,
            StageCode: invoiceTable[i].StageCode,
            StagePercentage: invoiceTable[i].StagePercentage,
            HSNSACCode: invoiceTable[i].HSNSACCode,
            Rate: +invoiceTable[i].Rate,
            UptoLastBillQty: +invoiceTable[i].CummQty,
          },
        ];
      }
    } else if (salesOrderTable) {
      for (let i = 0; i < salesOrderTable.length; i++) {
        values = [
          ...values,
          {
            SlNo: salesOrderTable[i].SlNo,
            BOQNo: salesOrderTable[i].BOQNo,
            ItemDesc: salesOrderTable[i].ItemDescription,
            UOM: salesOrderTable[i].UOM,
            HSNSACCode: salesOrderTable[i].HSNSACCode,
            Rate: +salesOrderTable[i].NetRate,
            UptoLastBillQty: 0,
            CummQty: 0,
          },
        ];
      }
      if (staged === "No") {
        for (let i = 0; i < salesOrderTable.length; i++) {
          values[i].StageCode = "N/A";
          values[i].StagePercentage = "100%";
        }
      }
    }
    setInputFields([...values]);
  }, [invoiceTable, salesOrderTable, staged, stageData]);

  // saved
  useEffect(() => {
    if (invoiceSavedData) {
      setInputFields([...invoiceSavedData.fields]);
      setTotalAmt(invoiceSavedData.amt);
    }
  }, [invoiceSavedData]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;

    if (materialQty) {
      const material = materialQty.find(
        (item) => item.BOQNo === values[index].BOQNo
      );
      console.log(material);
      if (+material.Quantity >= +values[index].ThisBillQty) {
        setInputFields(values);
        calculateSum(index);
      } else {
        enqueueSnackbar(`Quantity cannot be more than ${material.Quantity}`, {
          variant: "warning",
        });
      }
    } else {
      setInputFields(values);
      calculateSum();
    }
  };

  const onStageSelect = (event, values, index) => {
    const arr = [...inputFields];
    let row = inputFields[index];
    console.log(values);
    try {
      row.StageCode = values.StageName;
      row.StagePercentage = values.StagePercentage;
      arr[index] = row;
      setInputFields(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const calculateSum = () => {
    const values = [...inputFields];
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
      let row = values[i];
      const totalQty = +row.UptoLastBillQty + +row.ThisBillQty;
      row.CummQty = totalQty;
      sum += +totalQty * +row.Rate;
      values[i] = row;
    }
    setInputFields(values);
    setTotalAmt(sum);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="tableContainer"
    >
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">Sl. No</th>
            <th className="head-cell">BOQ No</th>
            <th className="head-cell">Item Description</th>
            <th className="head-cell">HSN / SAC Code</th>
            <th className="head-cell">UOM</th>
            <th className="head-cell">Stage Code</th>
            <th className="head-cell">Stage Percentage</th>
            <th className="head-cell">Rate</th>
            <th className="head-cell">Upto Last Bill Qty</th>
            <th className="head-cell">This Bill Qty</th>
            <th className="head-cell">Cumm upto This Bill Qty</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1            ">{index + 1}</td>
              <td className="table-data2">{inputField.BOQNo}</td>
              <td className="table-data2">{inputField.ItemDesc}</td>
              <td className="table-data2">{inputField.HSNSACCode}</td>
              <td className="table-data2">{inputField.UOM}</td>
              {staged === "No" ? (
                <>
                  <td className="table-data2">{inputField.StageCode}</td>
                  <td className="table-data2">{inputField.StagePercentage}</td>
                </>
              ) : (
                <>
                  <td className="table-data2">
                    {stageData ? (
                      <Autocomplete
                        id="RecoveryDesc"
                        options={stageData}
                        getOptionLabel={(option) => option.StageName}
                        style={{ width: "10rem" }}
                        onChange={(event, values) =>
                          onStageSelect(event, values, index)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={stageData.find(
                          (item) => item.StageName === inputField.StageCode
                        )}
                      />
                    ) : (
                      <span>{inputField.StageCode}</span>
                    )}
                  </td>
                  <td className="table-data2">{inputField.StagePercentage}</td>
                </>
              )}
              <td className="table-data2">{inputField.Rate}</td>
              <td className="table-data2">{inputField.UptoLastBillQty}</td>
              <td className="table-data2">
                {disabled ? (
                  <span>{inputField.ThisBillQty}</span>
                ) : (
                  <input
                    type="number"
                    name="ThisBillQty"
                    value={inputField.ThisBillQty}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                    }}
                    style={{ width: "6rem" }}
                  />
                )}
                {/* <input
                  type="number"
                  name="ThisBillQty"
                  value={inputField.ThisBillQty}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                  }}
                  style={{ width: "6rem" }}
                /> */}
              </td>
              <td className="table-data4">{inputField.CummQty}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td className="table-data1">Total Amount</td>
            <td className="table-data4">{totalAmt}</td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td style={{ borderLeft: "none" }}></td>
          </tr>
        </tfoot>
      </table>
      <IconButton
        color="primary"
        onClick={() => saveInvoiceTable(inputFields, totalAmt)}
      >
        <CheckIcon />
      </IconButton>
    </div>
  );
};

export default SalesOrderInvoiceTable;
