import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allMachinePhysicalVerification } from "../../../redux/actions/MachineManagementaction";
import Table from "../../../components/FormDashBoard/FormDashBoard";
import RoleList from "../../../components/helpers/RoleList";

export const MachinePhysicalVerificationDash = (props) => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const button =
    store.userRoot.workingOn.Role === "sitecommercialAssistantAdminHead" ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  useEffect(() => {
    dispatch(allMachinePhysicalVerification());
  }, [dispatch]);
  useEffect(() => {
    if (
      store.machineMangementSystemRoot.AllMachinePhysicalVerification.length !==
      0
    ) {
      setLists(store.machineMangementSystemRoot.AllMachinePhysicalVerification);
    }
  }, [store.machineMangementSystemRoot.AllMachinePhysicalVerification]);
  return (
    <Table
      DeleteLink="/machineManagement/machinePhysicalVerification"
      lists={lists}
      tablecol1="MachinePhysicalVerificationNo"
      heading="Machine Physical Verification"
      label1="Machine Physical Verification no"
      link="/machineManagement/machinePhysicalVerification/create"
      editLink="/machineManagement/machinePhysicalVerification/edit"
      viewLink="/machineManagement/machinePhysicalVerification/view"
      button={button}
      visibleIcon={visibleIcon}
    />
  );
};
