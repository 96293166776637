import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../helpers/UseStyle";
import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

const BankTable = (props) => {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    let lists = props.lists;
    setLists(lists);
  }, [props.lists]);

  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Cost Centre</TableCell>
              <TableCell align="center">Total Bank Balance</TableCell>
              <TableCell align="center">Total Cash Balance</TableCell>
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lists.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{item.CostCentre}</TableCell>
                <TableCell align="center">
                  {item.BankDetails.reduce(
                    (total, bank) => total + +bank.Balance,
                    0
                  )}
                </TableCell>
                <TableCell align="center">{item.TotalCash}</TableCell>
                <TableCell align="center">
                  <span>
                    <IconButton onClick={() => props.onViewDetailsClick(item)}>
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BankTable;
