import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import { Button } from "@material-ui/core";
import "./main.css";

class MachineRepairRequest extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              component={Control.RenderTextField}
              name="ProjectCodeName"
              label="Project code / Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="CustomerName"
              label="Customer Name"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="MachineCodeName"
              label="Machine Code / Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="RepairType"
              label="Repair Type"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderDateTimeField}
              name="RepairDateTime"
              label="Repair Date and Time"
              style={{ width: "30%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              name="ExpectedCommDate"
              label="Expected Comm. Date"
              style={{ width: "20%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="NatureOfDefects"
              label="Nature Of Defects"
              style={{ width: "35%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="Remarks"
              label="Remarks"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div className="middle">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "MachineCodeName",
    "RepairType",
    "RepairDateTime",
    "ExpectedCommDate",
    "NatureOfDefects",
    "Remarks",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachineRepairRequest",
  validate,
  enableReinitialize: true,
})(MachineRepairRequest);
