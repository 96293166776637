import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import * as Create from "../../pages/CommonMaster/Create/export";
import * as Edit from "../../pages/CommonMaster/Edit/export";
import * as Dash from "../../pages/CommonMaster/DashBoard/export";
import * as View from "../../pages/CommonMaster/View/ExportViewCM";

const CommonMaster = (
  <Switch>
    {/**EMPLOYEE_DETAILS */}
    <Route
      exact
      path="/commonMaster/employeeDetails/"
      component={Dash.EmployeeDetailsDash}
    />
    <Route
      exact
      path="/commonMaster/employeeDetails/view/:id"
      component={View.EmployeeDetailsView}
    />
    <Route
      exact
      path="/commonMaster/employeeDetails/create"
      component={Create.EmployeeDetailsCreate}
    />
    <Route
      exact
      path="/commonMaster/employeeDetails/edit/:id"
      component={Edit.EmployeeDetailsEdit}
    />
    {/**EMPLOYEE_DETAILS */}

    {/* Company Details Start*/}
    <Route
      exact
      path="/commonMaster/companyDetails/"
      component={Dash.CompanyDetailsDash}
    />
    <Route
      exact
      path="/commonMaster/companyDetails/view/:id"
      component={View.CompanyDetailsView}
    />
    <Route
      exact
      path="/commonMaster/companyDetails/create"
      component={Create.CompanyDetailsCreate}
    />
    <Route
      exact
      path="/commonMaster/companyDetails/edit/:id"
      component={Edit.CompanyDetailsEdit}
    />
    {/* Company Details End*/}
    {/* machine class Start*/}
    <Route
      exact
      path="/commonMaster/machineClass/"
      component={Dash.MachineClass}
    />
    <Route
      exact
      path="/commonMaster/machineClass/view/:id"
      component={View.MachineClassView}
    />
    <Route
      exact
      path="/commonMaster/machineClass/create"
      component={Create.MachineClassCreate}
    />
    <Route
      exact
      path="/commonMaster/machineClass/edit/:id"
      component={Edit.MachineClassEdit}
    />
    {/* machine class end*/}
    {/* machine group start*/}
    <Route
      exact
      path="/commonMaster/machineGroup/"
      component={Dash.MachineGroupDash}
    />
    <Route
      exact
      path="/commonMaster/machineGroup/view/:id"
      component={View.MachineGroupView}
    />
    <Route
      exact
      path="/commonMaster/machineGroup/create"
      component={Create.MachineGroupCreate}
    />
    <Route
      exact
      path="/commonMaster/machineGroup/edit/:id"
      component={Edit.MachineGroupEdit}
    />
    {/* machine group end*/}
    {/* machine Start*/}
    <Route exact path="/commonMaster/machine/" component={Dash.MachineDash} />
    <Route
      exact
      path="/commonMaster/machine/view/:id"
      component={View.MachineView}
    />
    <Route
      exact
      path="/commonMaster/machine/create"
      component={Create.MachineCreate}
    />
    <Route
      exact
      path="/commonMaster/machine/edit/:id"
      component={Edit.MachineEdit}
    />

    {/* machine end*/}
    {/* activity start*/}
    <Route exact path="/commonMaster/activity/" component={Dash.ActivityDash} />
    <Route
      exact
      path="/commonMaster/activity/view/:id"
      component={View.ActivityView}
    />
    <Route
      exact
      path="/commonMaster/activity/create"
      component={Create.ActivityCreate}
    />
    <Route
      exact
      path="/commonMaster/activity/edit/:id"
      component={Edit.ActivityEdit}
    />

    {/* activity end*/}

    {/*account start */}
    <Route exact path="/commonMaster/account/" component={Dash.AccountDash} />
    <Route
      exact
      path="/commonMaster/account/view/:id"
      component={View.AccountView}
    />
    <Route
      exact
      path="/commonMaster/account/create"
      component={Create.AccountCreate}
    />
    <Route
      exact
      path="/commonMaster/account/edit/:id"
      component={Edit.AccountEdit}
    />
    {/* account end*/}
    {/* Major SC Item Group end*/}
    <Route
      exact
      path="/commonMaster/majorSCItemGroup/"
      component={Dash.MajorScItemGroupDash}
    />
    <Route
      exact
      path="/commonMaster/majorSCItemGroup/view/:id"
      component={View.MajorSCItemsGroupViewView}
    />
    <Route
      exact
      path="/commonMaster/majorSCItemGroup/create"
      component={Create.MajorScItemGroupCreate}
    />
    <Route
      exact
      path="/commonMaster/majorSCItemGroup/edit/:id"
      component={Edit.MajorSCItemGroupEdit}
    />
    {/* Major SC Item Group end*/}
    {/* SC Item Code start*/}
    <Route
      exact
      path="/commonMaster/scItemCodeRequest/"
      component={Dash.SCItemCodeRequestDash}
    />
    <Route
      exact
      path="/commonMaster/scItemCodeRequest/view/:id"
      component={View.SCItemCodeRequestView}
    />
    <Route
      exact
      path="/commonMaster/scItemCodeRequest/create"
      component={Create.SCItemCodeRequestCreate}
    />
    <Route
      exact
      path="/commonMaster/scItemCodeRequest/edit/:id"
      component={Edit.SCItemCodeRequestEdit}
    />
    {/*SC Item Code end */}
    {/* GoodsReceipt start */}
    <Route
      exact
      path="/commonMaster/goodsReceiptType/"
      component={Dash.GoodsReceiptTypeDash}
    />
    <Route
      exact
      path="/commonMaster/goodsReceiptType/view/:id"
      component={View.GoodsReceiptTypeView}
    />
    <Route
      exact
      path="/commonMaster/goodsReceiptType/create"
      component={Create.GoodsReceiptTypeCreate}
    />
    <Route
      exact
      path="/commonMaster/goodsReceiptType/edit/:id"
      component={Edit.GoodsReceiptTypeEdit}
    />
    {/* goodsReceiptType end */}
    {/* unitOfMeasurement start */}
    <Route
      exact
      path="/commonMaster/unitOfMeasurement"
      component={Dash.UnitOfMeasurementDash}
    />
    <Route
      exact
      path="/commonMaster/unitOfMeasurement/view/:id"
      component={View.UnitOfMeasurmentView}
    />
    <Route
      exact
      path="/commonMaster/unitOfMeasurement/create"
      component={Create.UnitOfMeasurementCreate}
    />
    <Route
      exact
      path="/commonMaster/unitOfMeasurement/edit/:id"
      component={Edit.UnitOfMeasurementEdit}
    />
    {/* unitOfMeasurement End */}
    {/* RecoveryType start */}
    <Route
      exact
      path="/commonMaster/recoveryType"
      component={Dash.RecoveryTypeDash}
    />
    <Route
      exact
      path="/commonMaster/recoveryType/view/:id"
      component={View.RecoveryTypeView}
    />
    <Route
      exact
      path="/commonMaster/recoveryType/create"
      component={Create.RecoveryTypeCreate}
    />
    <Route
      exact
      path="/commonMaster/recoveryType/edit/:id"
      component={Edit.RecoveryTypeEdit}
    />
    {/* RecoveryType end */}
    {/* invoiceType */}
    <Route
      exact
      path="/commonMaster/invoiceType"
      component={Dash.InvoiceTypeDash}
    />
    <Route
      exact
      path="/commonMaster/invoiceType/view/:id"
      component={View.InvoiceTypeView}
    />
    <Route
      exact
      path="/commonMaster/invoiceType/create"
      component={Create.InvoiceTypeCreate}
    />
    <Route
      exact
      path="/commonMaster/invoiceType/edit/:id"
      component={Edit.InvoiceTypeEdit}
    />
    {/* currency */}
    <Route exact path="/commonMaster/currency" component={Dash.CurrencyDash} />
    <Route
      exact
      path="/commonMaster/currency/view/:id"
      component={View.CurrencyView}
    />
    <Route
      exact
      path="/commonMaster/currency/create"
      component={Create.CurrencyCreate}
    />
    <Route
      exact
      path="/commonMaster/currency/edit/:id"
      component={Edit.CurrencyEdit}
    />
    {/* wareHouse */}
    <Route
      exact
      path="/commonMaster/wareHouse"
      component={Dash.WareHouseDash}
    />
    <Route
      exact
      path="/commonMaster/wareHouse/view/:id"
      component={View.WareHouseView}
    />
    <Route
      exact
      path="/commonMaster/wareHouse/create"
      component={Create.WareHouseCreate}
    />
    <Route
      exact
      path="/commonMaster/wareHouse/edit/:id"
      component={Edit.WareHouseEdit}
    />
    {/* businessUnit */}
    <Route
      exact
      path="/commonMaster/businessUnit"
      component={Dash.BusinessUnitDash}
    />
    <Route
      exact
      path="/commonMaster/businessUnit/view/:id"
      component={View.BusinessUnitView}
    />
    <Route
      exact
      path="/commonMaster/businessUnit/create"
      component={Create.BusinessUnitCreate}
    />
    <Route
      exact
      path="/commonMaster/businessUnit/edit/:id"
      component={Edit.BusinessUnitEdit}
    />
    {/* vendor */}
    <Route exact path="/commonMaster/vendor" component={Dash.VendorDash} />
    <Route
      exact
      path="/commonMaster/vendor/view/:id"
      component={View.VendorView}
    />
    <Route
      exact
      path="/commonMaster/vendor/create"
      component={Create.VendorCreate}
    />
    <Route
      exact
      path="/commonMaster/vendor/edit/:id"
      component={Edit.VendorEdit}
    />
    {/* materialGroup */}
    <Route
      exact
      path="/commonMaster/materialGroup"
      component={Dash.MaterialGroupDash}
    />
    <Route
      exact
      path="/commonMaster/materialGroup/view/:id"
      component={View.MaterialGroupView}
    />
    <Route
      exact
      path="/commonMaster/materialGroup/create"
      component={Create.MaterialGroupCreate}
    />
    <Route
      exact
      path="/commonMaster/materialGroup/edit/:id"
      component={Edit.MaterialGroupEdit}
    />
    {/* MaterialView */}
    <Route exact path="/commonMaster/material" component={Dash.MaterialDash} />
    <Route
      exact
      path="/commonMaster/material/view/:id"
      component={View.MaterialView}
    />
    <Route
      exact
      path="/commonMaster/material/create"
      component={Create.MaterialCreate}
    />
    <Route
      exact
      path="/commonMaster/material/edit/:id"
      component={Edit.MaterialEdit}
    />
    {/* customer */}
    <Route exact path="/commonMaster/customer" component={Dash.CustomerDash} />
    <Route
      exact
      path="/commonMaster/customer/view/:id"
      component={View.CustomerView}
    />
    <Route
      exact
      path="/commonMaster/customer/create"
      component={Create.CustomerCreate}
    />
    <Route
      exact
      path="/commonMaster/customer/edit/:id"
      component={Edit.CustomerEdit}
    />
    {/* costCentre */}
    <Route
      exact
      path="/commonMaster/costCentre"
      component={Dash.CostCentreDash}
    />
    <Route
      exact
      path="/commonMaster/costCentre/view/:id"
      component={View.CostCentreView}
    />
    <Route
      exact
      path="/commonMaster/costCentre/create"
      component={Create.CostCentreCreate}
    />
    <Route
      exact
      path="/commonMaster/costCentre/edit/:id"
      component={Edit.CostCentreEdit}
    />
    {/* bank */}
    <Route exact path="/commonMaster/bank" component={Dash.BankDash} />
    <Route exact path="/commonMaster/bank/view/:id" component={View.BankView} />
    <Route
      exact
      path="/commonMaster/bank/create"
      component={Create.BankCreate}
    />
    <Route exact path="/commonMaster/bank/edit/:id" component={Edit.BankEdit} />
    {/* shifts */}
    <Route exact path="/commonMaster/shifts" component={Dash.ShiftsDash} />
    <Route
      exact
      path="/commonMaster/shifts/create"
      component={Create.ShiftsCreate}
    />
    <Route exact path="/commonMaster/shifts/view/:id" component={View.ShiftsView} />
    <Route
      exact
      path="/commonMaster/shifts/edit/:id"
      component={Edit.ShiftEdit}
    />

    {/* Benefits */}
    <Route exact path="/commonMaster/benefits" component={Dash.BenefitsDash} />
    <Route
      exact
      path="/commonMaster/benefits/create"
      component={Create.BenefitsCreate}
    />
    <Route exact path="/commonMaster/benefits/view/:id" component={View.BenefitView} />
    <Route
      exact
      path="/commonMaster/benefits/edit/:id"
      component={Edit.BenefitEdit}
    />
    {/* Workmen  */}
    <Route exact path="/commonMaster/workmen" component={Dash.WorkmenDash} />
    <Route
      exact
      path="/commonMaster/workmen/create"
      component={Create.WorkmenCreate}
    />
    <Route exact path="/commonMaster/workmen/view/:id" component={View.WorkmenView} />
    <Route
      exact
      path="/commonMaster/workmen/edit/:id"
      component={Edit.WorkmenEdit}
    />
  </Switch>
);

export default CommonMaster;
