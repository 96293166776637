import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
    },
    heading: {
        margin: theme.spacing(3,0,6),
        width: '200px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 'bold',
    },
    emailText: {
        marginBottom: theme.spacing(5),
    },
    inputEmail: {
        marginBottom: theme.spacing(3),
    },
    successMsg: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    messagePart: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    successIcon: {
        color: '#16F506',
        transform: 'scale(2.4)',
        margin: theme.spacing(1.5,0,4),
    },
    setFailure: {
        position: 'relative',
        width: '275px',
        marginTop: theme.spacing(3),
        color: 'red'
    },
    failureText: {
        position: 'absolute',
        right: 0,
    },
    incorrectIcon: {
        position: 'absolute',
        top: '3px',
        left: 0,
    } 
}));