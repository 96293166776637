import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "./main.css";
import { Button } from "@material-ui/core";

class MachinePhysicalVerification extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              component={Control.RenderTextField}
              name="ProjectCodeName"
              label="Project code / Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="CustomerName"
              label="Customer Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="MachineCodeName"
              label="Machine Code /Name"
              style={{ width: "40%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              name="VerificationDate"
              label="Verification Date"
              style={{ width: "40%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderDateTimeField}
              name="CommonDateTime"
              label="Common Date Time"
              style={{ width: "40%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="Availability"
              label="Availability​"
              style={{ width: "45%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderSelectField}
              name="Condition"
              label="Condition"
            >
              <option value={""}></option>
              <option value={"Working"}>Working</option>
              <option value={"Damage​"}>Damage​</option>
              <option value={"BreakDown"}>Break Down​</option>
            </Field>
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="Remarks"
              label="Remarks"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div className="middle">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "MachineCodeName",
    "VerificationDate",
    "CommonDateTime",
    "Condition",
    "Remarks",
    "Availability",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "MachinePhysicalVerification",
  validate,
  enableReinitialize: true,
})(MachinePhysicalVerification);
