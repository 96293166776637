export const AllOptionList = [
  {
    type: "RECEIPT FROM VENDORS AGAINST PO​",
    options: [
      "",
      "REGULAR STOCK-CAPITAL",
      "REGULAR STOCK-REVENUE",
      "TRANSIT STOCK",
      "DIRECT SUPPLY",
    ],
  },
  {
    type: "RECEIPT FROM VENDORS WITHOUT PO",
    options: ["", "REGULAR STOCK-REVENUE"],
  },
  {
    type: "RECEIPT FROM SUB-CONTRACTOR",
    options: ["", "REGULAR STOCK-REVENUE", "SCRAP STOCK", "MAINTENANCE STOCK"],
  },
  {
    type: "RECEIPT FROM OTHER WAREHOUSE",
    options: ["", "REGULAR STOCK-CAPITAL", "REGULAR STOCK-REVENUE"],
  },
  {
    type: "RECEIPT FROM OTHER HEC GROUPS",
    options: ["", "REGULAR STOCK-CAPITAL", "REGULAR STOCK-REVENUE"],
  },
  {
    type: "RECEIPT FROM OTHER HEC GROUPS",
    options: ["", "REGULAR STOCK-CAPITAL", "REGULAR STOCK-REVENUE"],
  },
  {
    type: "RECEIPT FROM CLIENTS - FREE BASIS",
    options: ["", "CLIENT'S STOCK NIL VALUE"],
  },
  {
    type: "RECEIPT FROM CLIENTS - FREE BASIS",
    options: ["", "CLIENT'S STOCK NIL VALUE"],
  },
  {
    type: "RECEIPT FROM CLIENTS - CHARGEABLE BASIS",
    options: ["", "CLIENTS STOCK WITH RATE"],
  },
  {
    type: "RECEIPT FROM CLIENTS - AGAINST LOAN",
    options: ["", "CLIENT'S STOCK NIL VALUE"],
  },
];
