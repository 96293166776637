import React, { useEffect, useState } from "react";
import Calender from "../../../components/AttendanceTable-Calender/Calender";
import useStyle from "./UseStyle";
import Paper from "@material-ui/core/Paper";
import axios from "../../../components/apis/axios";

import Grid from "@material-ui/core/Grid";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const LogCalender = ({ MachineCodeName, ProjectCodeName }) => {
  const classes = useStyle();
  const [machineDetails, setMachineDetails] = useState(null);

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.post(
          `machineManagement/logSheetEntry/getLogSheetReportsDetails/`,
          {
            MachineCodeName: MachineCodeName,
            ProjectCodeName: ProjectCodeName,
          }
        );

        if (data) {
          let values = [];
          for (let index = 0; index < data.length; index++) {
            let date = new Date(data[index].LogSheetDate)
              .toISOString()
              .split("T")[0]
              .split("-");
            // console.log(date, new Date(date[0], date[1], date[1], 0, 0, 0));
            values = [
              ...values,
              {
                title: data[index].MachineCodeName,
                startDate: new Date(date[0], date[1], date[1], 0, 0, 0),
                endDate: new Date(date[0], date[1], date[1], 23, 0, 0),
                info: data[index].WorkingHour,
                Present: true,
              },
            ];
          }
          console.log("values", values);
          setMachineDetails(values);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [MachineCodeName, ProjectCodeName]);

 
 const Content = ({ appointmentData, ...restProps }) => (
   <AppointmentTooltip.Content {...restProps} appointmentData={machineDetails}>
     <Grid container alignItems="center">
       <Grid item xs={2} align="center">
         <DesktopMacIcon />
       </Grid>
       <Grid item xs={10}>
         <span>
           <b>Machine</b> : {appointmentData.title}
         </span>
       </Grid>
       <Grid item xs={2} align="center">
         <QueryBuilderIcon />
       </Grid>
       <Grid item xs={10}>
         <span>
           <b>Working Hour</b> : {appointmentData.info}
         </span>
       </Grid>
     </Grid>
   </AppointmentTooltip.Content>
 );
  
  return (
    <>
      <BreadCrumbs
        name="Attendance View"
        linkName1="Logsheet Receipt Reports"
        to1="/machineManagement/reports/logsheetReport"
        linkName2="All Machine Man. Reports"
        to2="/machineManagement/reports"
      />
      <Paper className={classes.Container}>
        <h2 className="text-center">Attendance View</h2>
        <Calender details={machineDetails} Content={Content} />
      </Paper>
    </>
  );
};
