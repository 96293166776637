import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyle from "./UseStyle";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { allprojectCircularionvoucher } from "../../../redux/actions/SalesAcounts";
import { allMachineReceipt } from "../../../redux/actions/MachineManagementaction";

export const ReportsList = () => {
  const classes = useStyle();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allprojectCircularionvoucher());
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs name="All Workforce Management Reports" />
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>All Reports</h3>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <Link to="/workforceManagement/reports/workmenJoining">
                WorkMenJoining
              </Link>
            </Paper>
            <Paper className={classes.paper}>
              <Link to="/workforceManagement/reports/dailyAttendence">
                Daily Attendence
              </Link>
            </Paper>
          </div>
        </Box>
      </Paper>
    </>
  );
}