import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import CurrencyTable from "../../Tables/AccountingSystem/CurrencyTable";
import AccountingSystemFormHeader from "../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import BankTable from "../../Tables/AccountingSystem/BankTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const SystemParameter = (props) => {
  const [state, setState] = useState({
    isSubmit: false,
    selection: "",
  });
  const [show, setShow] = useState(false);

  const toggleModal = () => setShow(!show);
  const [totalNotes, setTotalNotes] = useState(null);

  const bankDetails = (data) => {
    if (!data) {
      setState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        bankDetails: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const onSubmit = (formValues) => {
    console.log(JSON.stringify(formValues));
    if (!state.bankDetails) {
      setState({ ...state, bankDetails: true });
    } else if (state.bankDetails) {
      props.onSubmit({
        ...formValues,
        cashDetails: totalNotes,
        bankDetails: state.bankDetails,
      });
    }
  };

  const handleCurrencyChange = (data) => {
    setTotalNotes(data);
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />

        <h2>Opening Bank Balance</h2>
        <div>
          <div className="excel">
            <BankTable
              BList={props.BList}
              bankDetails={bankDetails}
              BankEdit={props.BankEdit}
              edit={props.edit}
            />
          </div>
        </div>
        <h2>Voucher Opening Serial No</h2>
        <div className="field-container">
          <Field
            name="PaymentRequest"
            component={Control.RenderTextField}
            type="number"
            label="Payment Request"
          />
          <Field
            name="ReceiptVoucher"
            type="number"
            component={Control.RenderTextField}
            label="Receipt Voucher"
          />
          <Field
            name="ScrutinyVoucher"
            type="number"
            component={Control.RenderTextField}
            label="Scrutiny Voucher"
          />
          <Field
            name="PurchaseJournalVoucher"
            component={Control.RenderTextField}
            type="number"
            label="Purchase Journal Voucher"
          />
          <Field
            name="ServiceJournalVoucher"
            component={Control.RenderTextField}
            type="number"
            label="Service Journal Voucher"
          />
          <Field
            name="JournalVoucher"
            component={Control.RenderTextField}
            type="number"
            label="Journal Voucher"
          />
          <Field
            name="PaymentVoucher"
            component={Control.RenderTextField}
            type="number"
            label="Payment Voucher"
          />
          <Field
            name="ContraEntry"
            component={Control.RenderTextField}
            type="number"
            label="Contra Entry"
          />
        </div>

        <div className="middle">
          <div>
            <CurrencyTable
              handleCurrencyChange={handleCurrencyChange}
              cashDetails={props.cashDetails}
            />
            <div className="middle">
              <p className=" Mui-error excelError"></p>
            </div>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="System Parameter"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "PaymentRequest",
    "ReceiptVoucher",
    "ScrutinyVoucher",
    "PurchaseJournalVoucher",
    "ServiceJournalVoucher",
    "JournalVoucher",
    "PaymentVoucher",
    "ContraEntry",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "SystemParameter",
  validate,
  enableReinitialize: true,
})(SystemParameter);
