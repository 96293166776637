import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
export const MachinePurchaseOrderTableView = (props) => {
  const [data, setData] = useState([]);
  const { materials } = props;
  const { column } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2" className="head-cell">
              Material Code
            </th>
            <th rowSpan="2" className="head-cell">
              Material Description
            </th>
            <th rowSpan="2" className="head-cell">
              {" "}
              HSN / SAC Code
            </th>
            <th rowSpan="2" className="head-cell">
              UOM
            </th>
            <th rowSpan="2" className="head-cell">
              Quantity
            </th>
            <th rowSpan="2" className="head-cell">
              Rate
            </th>
            <th rowSpan="2" className="head-cell">
              Discount
            </th>
            <th rowSpan="2" className="head-cell">
              Net Rate
            </th>
            <th rowSpan="2" className="head-cell">
              Net Amount
            </th>
            <th colSpan="2">C-GST</th>
            <th colSpan="2">S-GST</th>
            <th colSpan="2">I-GST</th>
            <th rowSpan="2" className="head-cell">
              Total GST
            </th>
            <th rowSpan="2" className="head-cell">
              Item Total
            </th>
          </tr>
          <tr>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">
                {column && row ? row[column.col1] : ""}
              </td>
              <td className="table-data2">
                {column && row ? row[column.col2] : ""}
              </td>
              <td className="table-data2">{row.HSNSACCode}</td>
              <td className="table-data2">{row.UOM}</td>
              <td className="table-data2">{row.Quantity}</td>
              <td className="table-data2">{row.SuggestedRate}</td>
              <td className="table-data2">{row.Discount}</td>
              <td className="table-data2">{row.NetRate}</td>
              <td className="table-data2">{row.NetAmount}</td>
              <td className="table-data2">{row.CGSTPercentage}</td>
              <td className="table-data2">{row.CGSTValue}</td>
              <td className="table-data2">{row.SGSTPercentage}</td>
              <td className="table-data2">{row.SGSTValue}</td>
              <td className="table-data2">{row.IGSTPercentage}</td>
              <td className="table-data2">{row.IGSTValue}</td>
              <td className="table-data4">{row.TotalGST}</td>
              <td>
                {column && row ? row[column.colL] : "Loading.."}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </div>
  );
};
