import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
import Table from "../../../Tables/AccountingSystem/View/PaymentRequestDetailsTableView";
import PaymentRequestTable from "../../../Tables/AccountingSystem/PaymentRequestTable";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import "../../../components/helpers/form.style.css";

const JournalOtherForm = (props) => {
  const [state, setState] = useState({
    isSubmit: false,
    selection: "",
    paymentDebitDetails: null,
    debitTableErrorKind: "",
    debitTableError: false,
    paymentCreditDetails: null,
    creditTableErrorKind: "",
    creditTableError: false,
  });
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    console.log(JSON.stringify(formValues));
    if (!state.paymentDebitDetails && !state.paymentCreditDetails) {
      setState({ ...state, creditTableError: true, debitTableError: true });
    } else {
      props.onSubmit({
        ...formValues,
        debitData: state.paymentDebitDetails,
        creditData: state.paymentCreditDetails,
      });
    }
  };

  const paymentDebitDetails = (data) => {
    if (!data) {
      setState({
        ...state,
        debitTableErrorKind: "table row are not fully field",
        debitTableError: true,
      });
    } else {
      setState({
        ...state,
        paymentDebitDetails: data,
        debitTableErrorKind: "",
        debitTableError: false,
      });
    }
  };

  const paymentCreditDetails = (data) => {
    if (!data) {
      setState({
        ...state,
        creditTableErrorKind: "table row are not fully field",
        creditTableError: true,
      });
    } else {
      setState({
        ...state,
        paymentCreditDetails: data,
        creditTableErrorKind: "",
        creditTableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
          Reference Voucher
        </h4>
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            label="Project Code"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="JournalOtherCostCentre"
            component={Control.RenderTextField}
            label="Cost Center"
            disabled
          />
          <Field
            name="VoucherNo"
            component={Control.AutoCompleteInput}
            label="Voucher No"
            list={props.voucherNoList}
            className="text-field"
            onChange={(value) =>
              props.selectVoucher && props.selectVoucher(value)
            }
          />
        </div>
        <Table paymentDetails={props.paymentDetails} />
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>Debit</h4>
        <div>
          <div className="excel">
            <PaymentRequestTable
              paymentDebitDetails={paymentDebitDetails}
              accounts={props.accounts}
              type={"debit"}
              paymentDebit={props.paymentDebit}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
          <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
            Credit
          </h4>
          <div className="excel">
            <PaymentRequestTable
              paymentCreditDetails={paymentCreditDetails}
              accounts={props.accounts}
              type={"credit"}
              paymentCredit={props.paymentCredit}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Journal Other"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "ProjectCodeName",
    "CustomerName",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "JournalOtherForm",
  validate,
  enableReinitialize: true,
})(JournalOtherForm);
