import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyle from "./UseStyle";
import { Link } from "react-router-dom";

export const ReportsList = () => {
  const classes = useStyle();

  return (
    <Paper className={classes.Container}>
      <h3 className={classes.Heading}>All Reports</h3>
      <Box border={1} borderRadius={16} className={classes.Box}>
        <div className={classes.row}>
          <Paper className={classes.paper}>
            <Link to="/accountingSystem/accountingSystemReports/LiabilityStatement">
              Liability Statement
            </Link>
          </Paper>
          <Paper className={classes.paper}>
            <Link to="/accountingSystem/accountingSystemReports/AdvanceStatement">
              Advance Statement
            </Link>
          </Paper>
          <Paper className={classes.paper}>
            <Link to="/accountingSystem/accountingSystemReports/CashAndBankBalance">
              Cash & Bank Balance
            </Link>
          </Paper>
        </div>
      </Box>
    </Paper>
  );
};
