import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import WorkmenExit from "../../../forms/ProjectWorkforceManagement/WorkmenExitForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllVendor = [];
let AllCostCentre = [];
let Allworkmen = [];


export const WorkmenExitCreate = () => {
  const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [costCentre, setCostCentrelist] = useState([]);
  const [workmenlist, setWorkmenlist] = useState([]);
  const history = useHistory();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "workforceManagement/workmenExit",
      formValues
    );
    console.log(response);
    history.push("/workforceManagement/workmenExit/");
  };

  const selectProjectName = async (projectName) => {

    const item = allProject.find(
      (item) => item.ProjectCode === projectName?.split(" ")[0]
    );
    if(item){
      let payload ={
        ...formDetail,
        ProjectCode: projectName,
        CustomerName: item.CustomerName,
        CostCentre: item.CostCentre,
      }
      setformDetail(payload);
    }

    
  };
  const selectWorkmenName = async (WorkmenName) => {

    const item = Allworkmen.find(
      (item) => item.WorkmenName === WorkmenName?.split(" ")[0]
    );
    console.log(item)
    if(item){
      let payload ={
        ...formDetail,
        WorkmenName: WorkmenName,
        WorkmenCode: item.WorkmenCode,
        WorkmenReligion: item.WorkmenReligion,
        WorkmenFather: item.WorkmenFather,
        WorkmenDOB: item.WorkmenDOB,
        WorkmenGender: item.WorkmenGender,
        WorkmenMotherTongue: item.WorkmenMotherTongue,
        WorkmenPresentAddress: item.WorkmenPresentAddress,
        WorkmenPermanentAddress: item.WorkmenPermanentAddress,
        WorkmenEmergencyContact: item.WorkmenContact,
        WorkmenPhone: item.WorkmenPhone,
        WorkmenMarital: item.WorkmenMarital,
        WorkmenChild: item.WorkmenChild,
        WorkmenIdentification: item.WorkmenIdentification,
        WorkmenHeight: item.WorkmenHeight,
        WorkmenWeight: item.WorkmenWeight,
        WorkmenVision: item.WorkmenVision,
        WorkmenVisionLeft: item.WorkmenVisionLeft,
        WorkmenEducation: item.WorkmenEducation,
        WorkmenVisionRight: item.WorkmenVisionRight,
        WorkmenExpInYears: item.WorkmenExpInYears,
        WorkmenExpInMonths: item.WorkmenExpInMonths,
        WorkmenSkill: item.WorkmenSkill,
        WorkmenPF: item.WorkmenPF,
        WorkmenUniAccNo: item.WorkmenUniAccNo,
        WorkmenESIC: item.WorkmenESIC,
        WorkmenBankAccount: item.WorkmenBankAccount,
        WorkmenBankName: item.WorkmenBankName,
        WorkmenBankIFSC: item.WorkmenBankIFSC,
        WorkmenAadhar: item.WorkmenAadhar,
        WorkmenPAN: item.WorkmenPAN,
        WorkmenDOE: item.WorkmenDOE,
        WorkmenDOJ: item.WorkmenDOJ,
     }
      setformDetail(payload);
    }

    
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        console.log(data)
        const filtered = data.filter((item) =>  item?.Status === "accepted" && item?.projectStatus === "Active");
        allProject = filtered;
        let PNlist = filtered.map(
          (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
        );
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    async function getVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");

        AllVendor = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let VNList = AllVendor.map((item) => item.VendorName);
        setVendorList(VNList);
      } catch (err) {
        alert(err);
      }
    }
    async function getWorkmen() {
      try {
        let { data } = await axios.get("/commonMaster/workmen");
        console.log(data)
        Allworkmen = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let WorkmenList = Allworkmen.map((item) => item?.WorkmenName);
        setWorkmenlist(WorkmenList);
      } catch (err) {
        alert(err);
      }
    }
    async function getCostCentre() {
      try {
        let { data } = await axios.get("/commonMaster/costCentre");
        console.log(data);
        
        AllCostCentre = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let CCList = AllCostCentre.map((item) => item.CostCenterName);
        setCostCentrelist(CCList);
      } catch (err) {
        alert(err);
      }
    }

    getCostCentre();
    getVendor();
    fetchProject();
    getWorkmen();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Workmen Exit Create"
        linkName1="Workmen Exit Dashboard"
        to1="/workforceManagement/workmenExit/"
      />
      <h3 style={{ textAlign: "center" }}>Workmen Exit</h3>
      <WorkmenExit
        onSubmit={onSubmit}
        list={list}
        VNList={vendorList}
        workmenlist={workmenlist}
        CCList={costCentre}
        selectProjectName={selectProjectName}
        selectWorkmenName={selectWorkmenName}
        initialValues={pick(
          formDetail,
          "ProjectCode",
          "CustomerName",
          "CostCentre",
          "WorkmenName",
          "WorkmenCode",
          "WorkmenReligion",
          "WorkmenFather",
          "WorkmenGender",
          "WorkmenMotherTongue",
          "WorkmenPresentAddress",
          "WorkmenPermanentAddress",
          "WorkmenEmergencyContact",
          "WorkmenPhone",
          "WorkmenMarital",
          "WorkmenChild",
          "WorkmenIdentification",
          "WorkmenHeight",
          "WorkmenWeight",
          "WorkmenVision",
          "WorkmenVisionLeft",
          "WorkmenVisionRight",
          "WorkmenEducation",
          "WorkmenExpInYears",
          "WorkmenExpInMonths",
          "WorkmenSkill",
          "WorkmenPF",
          "WorkmenUniAccNo",
          "WorkmenESIC",
          "WorkmenBankAccount",
          "WorkmenBankIFSC",
          "WorkmenBankName",
          "WorkmenAadhar",
          "WorkmenPAN",
          "WorkmenDOB",
          "WorkmenDOJ",
          "WorkmenDOE",
        )}
      />
    </div>
  );
};
