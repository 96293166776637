import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/SalesAccountingSystem/SalesInvoiceCertificationForm";
import axios from "../../../components/apis/axios";
import { useDispatch, useSelector } from "react-redux";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let allInvoices = [];

export const SalesInvoiceCertificationEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [salesInvoiceCertification, setSalesInvoiceCertification] = useState();
  const [previousFiles, setPreviousFiles] = useState();
  const { match } = props;
  const [recoveryTypes, setRecoveryTypes] = useState(null);
  const [list, setList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceEditData, setInvoiceEditData] = useState(null);
  const [recoverTableEdit, setRecoverTableEdit] = useState(null);
  const [invoiceTotalAmt, setInvoiceTotalAmt] = useState(0);
  const [recoveryTotalAmt, setRecoveryTotalAmt] = useState(0);
  const [summaryTableEdit, setSummaryTableEdit] = useState(null);

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    await axios.put(
      `/salesAccounting/salesInvoiceCertification/${props.match.params.id}`,
      formValues
    );
    history.push("/salesAccounting/salesInvoiceCertification/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesInvoiceCertification/${match.params.id}`
        );
        let data = response.data;
        console.log("salesInvoiceCertification ->", data);
        setSalesInvoiceCertification(data);
        if (data.Files) setPreviousFiles([...data.Files]);
        setInvoiceEditData(data.InvoiceDetails);
        setRecoverTableEdit(data.InvoiceRecovery);
        setSummaryTableEdit(data.InvoiceSummary);
        setInvoiceTotalAmt(data.TotalInvoiceAmt);
        setRecoveryTotalAmt(data.TotalRecoveryAmt);
      } catch (err) {
        console.error(err);
      }
    }
    console.log(match.params);
    fetch();
  }, [match.params]);

  const selectInvoice = async (invoice) => {
    if (invoice) {
      let detail = allInvoices.find((item) => item.SalesInvoiceNo === invoice);
      try {
        const { data } = await axios.get(
          `/salesAccounting/salesInvoice/${detail.id}`
        );
        // console.log(data);
        setInvoiceEditData(data.InvoiceDetails);
        setRecoverTableEdit(data.InvoiceRecovery);
        setInvoiceTotalAmt(data.TotalInvoiceAmt);
        setRecoveryTotalAmt(data.TotalRecoveryAmt);
        setSummaryTableEdit(data.InvoiceSummary);
        detail = {
          ...salesInvoiceCertification,
          InvoiceNo: detail.SalesInvoiceNo,
          InvoiceDate: new Date(detail.created_at).toISOString().split("T")[0],
        };
      } catch (err) {
        console.log(err);
      }
      setSalesInvoiceCertification({ ...salesInvoiceCertification, ...detail });
    }
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      try {
        const { data } = await axios.post(
          `salesAccounting/salesInvoice/getInvoices`,
          {
            ProjectCodeName: detail.ProjectShortDescription,
          }
        );
        allInvoices = data;
        const mapped = data.map((item) => item.SalesInvoiceNo);
        setInvoiceList(mapped);
      } catch (err) {
        console.log(err);
      }
      setSalesInvoiceCertification({ ...salesInvoiceCertification, ...detail });
    }
  };

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);
  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      allProject = store.GeneralRoot.AllProjectName;
      let approvedList = allProject.filter(
        (item) => item.Status === "accepted"
      );
      // console.log(approvedList);
      let PNList = approvedList.map((item) => item.ProjectShortDescription);
      setList(PNList);
    }

    const fetchRecoveryType = async () => {
      try {
        const { data } = await axios.get("/commonMaster/recoveryType/");
        setRecoveryTypes(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    };
    fetchRecoveryType();
  }, [store.GeneralRoot.AllProjectName]);

  return (
    <div>
      <BreadCrumbs
        name="Sales Invoice Certification Edit"
        linkName1="Sales Invoice Certification Dashboard"
        to1="/salesAccounting/salesInvoiceCertification"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Invoice Certification Edit
      </h3>
      <Form
        onSubmit={onSubmit}
        list={list}
        invoiceList={invoiceList}
        selectProjectName={selectProjectName}
        selectInvoice={selectInvoice}
        invoiceEditData={invoiceEditData}
        recoverTableEdit={recoverTableEdit}
        invoiceTotalAmt={invoiceTotalAmt}
        recoveryTotalAmt={recoveryTotalAmt}
        summaryTableEdit={summaryTableEdit}
        previousFiles={previousFiles}
        recoveryTypes={recoveryTypes}
        initialValues={pick(
          salesInvoiceCertification,
          "OriginalFormNo",
          "ProjectCodeName",
          "CustomerName",
          "InvoiceDate",
          "InvoiceNo",
          "CertificationDate",
          "TotalInvoiceAmt",
          "TotalRecoveryAmt",
          "CertificationValue"
        )}
      />
    </div>
  );
};
