import React, { useState, useEffect } from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'
import { Button } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from '../../../components/apis/axios'

const SalesOrderTable = ({
  order,
  tableData,
  uom,
  projectOrderValue,
  edit,
  orderType
}) => {
  const [totalVal, setTotalVal] = useState(0)
  const [netTotal, setNetTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [allowable, setAllowable] = useState([
    {
      decimal: ''
    }
  ])

  const [inputFields, setInputFields] = useState([
    {
      SlNo: '',
      BOQNo: '',
      HeadingBOQNo: '',
      ItemDescription: '',
      UOM: '',
      HSNSACCode: '',
      Quantity: '0',
      Rate: '',
      Discount: '',
      NetRate: '',
      NetAmount: '',
      CGSTPercentage: '',
      CGSTValue: '',
      SGSTPercentage: '',
      SGSTValue: '',
      IGSTPercentage: '',
      IGSTValue: '',
      TotalGST: '',
      ItemTotal: '',
      id: ''
    }
  ])

  useEffect(() => {
    if (order) {
      setLoading(true)
      setInputFields([...order])
      if (tableData) tableData(order)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [order])

  useEffect(() => {
    if (uom) {
      if (edit && order) {
        let temp = []

        for (let i = 0; i < order.length; i++) {
          const item = uom.find(item => item.UOMCode === order[i].UOM)
          temp.push({
            decimal: item?.AllowableDecimal
          })
        }
        setAllowable(temp)
      }
    }
  }, [uom, order, edit])

  useEffect(() => {
    const values = [...inputFields]
    let sumTotal = 0
    let netSum
    netSum = values.map(item => item.ItemTotal)
    for (let i = 0; i < netSum.length; i++) {
      sumTotal += parseInt(netSum[i])
      setTotalVal(sumTotal)
    }
  }, [inputFields])

  useEffect(() => {
    const values = [...inputFields]
    let sumTotal = 0
    let netSum
    netSum = values.map(item => item.NetAmount)
    for (let i = 0; i < netSum.length; i++) {
      sumTotal += parseInt(netSum[i])
      setNetTotal(sumTotal)
    }
  }, [inputFields])

  const handleRemoveFields = async (id, e, index) => {
    e.preventDefault()
    await axios.put(`/salesAccounting/salesOrder/deleteRows/${id}`)
    const values = [...inputFields]

    values.splice(index, 1)
    setInputFields(values)
    tableData(values)
  }

  const handleAddFields = e => {
    e.preventDefault()
    setInputFields([
      ...inputFields,
      {
        SlNo: '',
        BOQNo: '',
        HeadingBOQNo: '',
        ItemDescription: '',
        UOM: '',
        HSNSACCode: '',
        Quantity: '',
        Rate: '',
        Discount: '',
        NetRate: '',
        NetAmount: '',
        CGSTPercentage: '',
        CGSTValue: '',
        SGSTPercentage: '',
        SGSTValue: '',
        IGSTPercentage: '',
        IGSTValue: '',
        TotalGST: '',
        ItemTotal: '',
        id: 'new'
      }
    ])
  }
  const handleChangeInput = (index, event) => {
    const values = [...inputFields]
    values[index][event.target.name] = event.target.value
    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: 'warning'
      })
    }
    if (event.target.name === 'Quantity') {
      let value = event.target.value
      try {
        value = value.split(".")
        if (value[1].length > allowable[index].decimal) {
          value[1] = value[1].substring(0, allowable[index].decimal)
        }
        let no = `${value[0]}.${value[1]}`
        values[index][event.target.name] = no
      } catch (e) {
        console.error(e)
        values[index][event.target.name] = event.target.value
      }
    } else values[index][event.target.name] = event.target.value
    setInputFields(values)
    onChange(values)
  }

  const updatevalues = index => {
    const values = [...inputFields]
    let row = inputFields[index]
    row.NetRate = +row.Rate - ((+row.Rate * +row.Discount) / 100).toFixed(2)
    row.NetAmount = (+row.Quantity * +row.NetRate).toFixed(2)
    if (+row.NetAmount > projectOrderValue) {
      row.NetAmount = 0
      row.NetRate = 0
      return enqueueSnackbar('Net value cannot be greater than project value', {
        variant: 'warning'
      })
    }
    if (+row.CGSTPercentage > 0) {
      row.SGSTPercentage = +row.CGSTPercentage
      row.IGSTPercentage = 0
    } else if (+row.IGSTPercentage > 0) {
      row.SGSTPercentage = 0
      row.CGSTPercentage = 0
    }
    row.CGSTValue = ((+row.CGSTPercentage / 100) * +row.NetAmount).toFixed(2)
    row.IGSTValue = ((+row.IGSTPercentage / 100) * +row.NetAmount).toFixed(2)
    row.SGSTValue = ((+row.SGSTPercentage / 100) * +row.NetAmount).toFixed(2)
    row.TotalGST = (+row.IGSTValue + +row.CGSTValue + +row.SGSTValue).toFixed(2)
    row.ItemTotal = (+row.TotalGST + +row.NetAmount).toFixed(2)
    values[index] = row
    setInputFields(values)
    tableData(values)
  }

  const onChange = values => {
    console.log(values, 'onchnagetabledata')
    var error = false
    if (values) {
      // for (let item of values) {
      //   for (let field of Object.keys(item)) {
      //     if (item[field]) {
      //       tableData({
      //         error: true,
      //         errorKind: 'some input field is empty'
      //       })
      //       error = true
      //       break
      //     }
      //   }
      //   if (error) {
      //     break
      //   }
      // }
      // if (!error) {
      //   tableData(values)
      // }
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: 'some input field is empty'
      })
    }
  }
  const onItemSelect = (event, values, index) => {
    let tdata = inputFields
    let row = tdata[index]
    try {
      if (values !== null) {
        row.UOM = values.UOMCode
        const tempAllowable = [...allowable]
        tempAllowable[index] = { decimal: values.AllowableDecimal }
        setAllowable(tempAllowable)
        tdata[index] = row
        setInputFields(tdata)
        onChange(tdata)
      } else {
        row.UOM = ''
        row.Quantity = 0
        row.Rate = 0
      }
    } catch (err) {
      console.error('err')
    }
  }

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields]
    const decimal = 2

    if (event.target.name === fieldName) {
      let value = event.target.value
      try {
        value = value.split(".")
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal)
        }
        let no = `${value[0]}.${value[1]}`
        values[index][event.target.name] = no
      } catch (e) {
        console.error(e)
        values[index][event.target.name] = event.target.value
      }
    } else values[index][event.target.name] = event.target.value
    setInputFields(values)
    onChange(values)
  }

  return (
    <div className='tableContainer'>
    {/* <h3><strong>Work Details</strong></h3> */}
      <table>
        <thead className='table-head'>
          <tr>
            <th rowSpan='2' className='head-cell'>
              Sl. No
            </th>
            <th rowSpan='2' className='head-cell'>
             BOQNo
            </th>
            <th rowSpan='2' className='head-cell'>
           HeadingBOQNo
            </th>
            <th rowSpan='2' className='head-cell'>
              ItemDescription
            </th>
            <th rowSpan='2' className='head-cell'>
              UOM
            </th>
            <th rowSpan='2' className='head-cell'>
              HSN / SAC Code
            </th>
            <th rowSpan='2' className='head-cell'>
              Qty
            </th>
            <th rowSpan='2' className='head-cell'>
              Rate
            </th>
            <th rowSpan='2' className='head-cell'>
              Discount
            </th>
            <th rowSpan='2' className='head-cell'>
              Net Rate
            </th>
            <th rowSpan='2' className='head-cell'>
              Net Amount
            </th>
            <th colSpan='2'>C-GST</th>
            <th colSpan='2'>S-GST</th>
            <th colSpan='2'>I-GST</th>
            <th rowSpan='2' className='head-cell'>
              Total GST
            </th>
            <th rowSpan='2' className='head-cell'>
              Item Total
            </th>
          </tr>
          <tr>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className='table-data1'>{index + 1}</td>
              <td className='table-data3'>
                <input
                  type='text'
                  name='BOQNo'
                  value={inputField.BOQNo}
                  onChange={event => handleChangeInput(index, event)}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>
                <input
                  type='text'
                  name='HeadingBOQNo'
                  value={inputField.HeadingBOQNo}
                  onChange={event => handleChangeInput(index, event)}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>
                <input
                  type='text'
                  name='ItemDescription'
                  onChange={event => handleChangeInput(index, event)}
                  value={inputField.ItemDescription}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>
                {uom && !loading && (
                  <Autocomplete
                    id='UOMDes'
                    options={uom}
                    getOptionLabel={option => option.UOMCode}
                    style={{ width: '10rem' }}
                    onChange={(event, values) =>
                      onItemSelect(event, values, index)
                    }
                    renderInput={params => <TextField {...params} />}
                    defaultValue={uom.find(
                      item => item.UOMCode === inputField.UOM
                    )}
                  />
                )}
              </td>
              <td className='table-data3'>
                <input
                  type='text'
                  name='HSNSACCode'
                  value={inputField.HSNSACCode}
                  onChange={event => handleChangeInput(index, event)}
                  style={{ width: '8rem' }}
                />
              </td>
              <td className='table-data3'>
                <input
                  type='number'
                  name='Quantity'
                  min={0}
                  step={0.01}
                  value={inputField.Quantity}
                  onChange={event => {
                    handleChangeInput(index, event)
                    updatevalues(index)
                  }}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>
                {orderType === 'Unit Rate' ? (
                  <>{inputField.Rate}</>
                ) : orderType === 'Rate Contract' ? (
                  <>{inputField.Rate}</>
                ) : (
                  <>
                    <input
                      type='number'
                      name='Rate'
                      min={0}
                      step={0.01}
                      value={inputField?.Rate}
                      onChange={event => {
                        handleChangeInput(index, event)
                        updatevalues(index)
                        fixedDecimal(index, event, 'Rate')
                      }}
                      style={{ width: '5rem' }}
                    />
                  </>
                )}
              </td>
              <td className='table-data3'>
                <input
                  type='number'
                  name='Discount'
                  min={0}
                  step={0.01}
                  value={inputField.Discount}
                  onChange={event => {
                    handleChangeInput(index, event)
                    fixedDecimal(index, event, 'Discount')
                    updatevalues(index)
                  }}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>{inputField.NetRate}</td>

              <td className='table-data3'>{inputField.NetAmount}</td>
              <td className='table-data3'>
                <input
                  type='number'
                  name='CGSTPercentage'
                  min={0}
                  step={1}
                  value={inputField.CGSTPercentage}
                  onChange={event => {
                    handleChangeInput(index, event)
                    updatevalues(index)
                  }}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>{inputField.CGSTValue}</td>
              <td className='table-data3'>
                <input
                  type='number'
                  name='SGSTPercentage'
                  min={0}
                  step={1}
                  value={inputField.SGSTPercentage}
                  onChange={event => {
                    handleChangeInput(index, event)
                    updatevalues(index)
                  }}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>{inputField.SGSTValue}</td>
              <td className='table-data3'>
                <input
                  type='number'
                  name='IGSTPercentage'
                  min={0}
                  step={1}
                  value={inputField.IGSTPercentage}
                  onChange={event => {
                    handleChangeInput(index, event)
                    updatevalues(index)
                  }}
                  style={{ width: '5rem' }}
                />
              </td>
              <td className='table-data3'>{inputField.IGSTValue}</td>
              <td className='table-data3'>{inputField.TotalGST}</td>
              <td className='table-data4'>{inputField.ItemTotal}</td>
              {order?.map(item =>
                index >= 0 ? (
                  <td>
                    <IconButton
                      onClick={e => handleRemoveFields(item.id, e, index)}
                    >
                      <RemoveCircleIcon className='width5' color='secondary' />
                    </IconButton>
                  </td>
                ) : (
                  ''
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='middle'>
        <Button
          onClick={e => handleAddFields(e)}
          variant='contained'
          color='primary'
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
      <div
        style={{
          margin: '1rem 0 1rem'
        }}
      >
        <div>
          <label for='LastPayment' className='table-data1'>
            Total amount with GST:
          </label>
          <input
            type='number'
            name='TotalSum'
            className='table-data4'
            value={totalVal}
            style={{ height: '1.2rem' }}
          />
        </div>
        <div>
          <label for='NetPayableAmt' className='table-data1'>
            Total amount without GST:
          </label>
          <input
            type='number'
            name='NetSum'
            className='table-data4'
            value={netTotal}
            style={{ height: '1.2rem' }}
          />
        </div>
      </div>
    </div>
  )
}

export default SalesOrderTable
