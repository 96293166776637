import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../style/materialSystem.css";
import WarehouseIssueVoucherForm from "../../../forms/MaterialSystem/WarehouseIssueVoucherForm";
import { WarehouseIssueVoucher } from "../../../redux/actions/materialSystemAction";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllWarehouseIndentVoucher = [];

export const WarehouseIssueVoucherFormCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [wareHouseIndentList, setWareHouseIndentList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [Warehouse, setWarehouse] = useState("");
  const [stocksDetails, setStocksDetails] = useState([]);
  const [materials, setMaterials] = useState(null);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(WarehouseIssueVoucher(formValues, history));
  };

  const selectProject = (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      console.log(detail);
      let payload = {
        ...formDetail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Warehouse: detail.WareHouse,
        WareHouseGSTNo: detail.WareHouseGst,
      };
      setWarehouse(detail.WareHouse);
      setFormDetail(payload);
      fetchWarehouseIndent(projectName);
    }
  };

  const selectWarehouseIndent = async (WarehouseIntentVoucherNo) => {
    let detail = AllWarehouseIndentVoucher.find(
      (item) => item.WarehouseIntentVoucherNo === WarehouseIntentVoucherNo
    );

    if (detail) {
      let payload = await axios.get(
        `/materialManagement/warehouseIntent/${detail.id}`
      );
      setMaterials(payload.data.materials);

      console.log("warehouseIntent", payload);

      try {
        const materials = payload.data.materials;
        const materialCodes = materials.map((item) => item.MaterialCode);
        const req = {
          WareHouse: Warehouse,
          Code: materialCodes,
        };
        const { data } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          req
        );
        console.log(data, req);
        setStocksDetails(data);
      } catch (err) {
        console.log(err);
      }

      payload = {
        ...formDetail,
        WarehouseIntentVoucherNo: WarehouseIntentVoucherNo,
        GoodsIssueType: payload.data.GoodsIssueType,
        WorkSalesNo: payload.data.SalesOrderNo,
        WorkOrderNo: payload.data.WorkOrderNo,
        Date: payload.data.WorkSalesDate,
        CustomerCodeName: payload.data.CustomerCodeName,
        // DateofIssue: payload.data.IssueDate,
        VendorCodeName: payload.data.VendorCodeName,
        VendorGstNo: payload.data.VendorGstNo,
        Remarks: payload.data.Remarks,
      };
      console.log("payload", payload);
      setFormDetail(payload);
    }
  };

  async function fetchProject() {
    try {
      let { data } = await axios.get("/salesAccounting/projectCircular");
      const filtered = data.filter((item) => item.Status === "accepted");
      allProject = filtered;
      let PNlist = filtered.map((item) => item.ProjectShortDescription);
      setProjectList(PNlist);
    } catch (error) {
      alert(error);
    }
  }

  async function fetchWarehouseIndent(ProjectCodeName) {
    try {
      let { data } = await axios.post(
        `/materialManagement/warehouseIntent/getByProjectCodeName`,
        {
          ProjectCodeName,
        }
      );
      AllWarehouseIndentVoucher = data;
      let WIlist = data.map((item) => item.WarehouseIntentVoucherNo);
      setWareHouseIndentList(WIlist);
    } catch (error) {
      alert("Failed to fetch", error);
    }
  }
  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Issue Voucher  Create"
        linkName1=" Warehouse Issue Voucher  Dashboard"
        to1="/materialManagement/warehouseIssue"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Issue Voucher Form
      </h3>
      <div className="line"></div>
      <WarehouseIssueVoucherForm
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProject={selectProject}
        WIlist={wareHouseIndentList}
        selectWarehouseIndent={selectWarehouseIndent}
        initialValues={pick(
          formDetail,
          "Warehouse",
          "ProjectCodeName",
          "CustomerName",
          "ProjectName",
          "WarehouseIntentVoucherNo",
          "GoodsIssueType",
          "WorkSalesNo",
          "WorkOrderNo",
          "Date",
          "CustomerCodeName",
          "DateofIssue",
          "VendorCodeName",
          "VendorGstNo",
          "WareHouseGSTNo"
        )}
        materialsTable={materials}
        stocksDetails={stocksDetails}
      />
    </div>
  );
};
