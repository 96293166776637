import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineDeHireRequest from "../../../forms/MachineManagementSystem/MachineDeHireRequest";
import { useHistory } from "react-router-dom";
import {
  editMachineDeHireRequest,
  searchMachineDeHireRequestById,
} from "../../../redux/actions/MachineManagementaction";

export const MachineDeHireRequestEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachineDeHireRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachineDeHireRequest) {
      console.log(store.SingleMachineDeHireRequest);
      setData(store.SingleMachineDeHireRequest);
    }
  }, [store.SingleMachineDeHireRequest]);
  const onSubmit = (formValues) => {
    dispatch(
      editMachineDeHireRequest(formValues, props.match.params.id, history)
    );
  };

  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine De Hire Request Edit
      </h3>
      <div className="line"></div>
      <MachineDeHireRequest
        initialValues={pick(
          data,
          "ProjectCodeName",
          "RequestType",
          "MachineCategory",
          "Currency",
          "MachineCodeName",
          "SurplusDate",
          "LastLogSheetDate",
          "TimeRequiredtoRelease",
          "Remarks"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
