import React from "react";
import { useSnackbar } from "notistack";
import { FormControl, InputLabel, Checkbox } from "@material-ui/core";
export const RenderCheckBoxField = ({
  label,
  input,
  meta: { touched, invalid, error },
  value,
  ...custom
}) => {
  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }
  return (
    <FormControl
      error={touched && error}
      style={{ minWidth: "10%", margin: "1.2rem" }}
    >
      <InputLabel htmlFor="CheckBox" variant="outlined">
        {label}
      </InputLabel>
      <Checkbox
        id="CheckBox"
        color="primary"
        label={label}
        //placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
        value={value}
      />
    </FormControl>
  );
};
