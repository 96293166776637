import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import CostCentre from "../../../forms/CommonMaster/CostCentre";
import { useHistory } from "react-router-dom";
import {
  editCostCentre,
  searchCostCentreById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
let allCompany = [];

export const CostCentreEdit = (props) => {
  const [data, setData] = useState({});
  const [companyDetail, setCompanyDetail] = useState([]);
  const [gst, setGst] = useState(null);
  const [companyGsts, setCompanyGsts] = useState([]);

  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  
  useEffect(() => {
    dispatch(searchCostCentreById(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (store.SingleCostCentre) {
      setData(store.SingleCostCentre);
      setGst(store.SingleCostCentre.Gsts);
      console.log("data", store.SingleCostCentre);
    }
  }, [store.SingleCostCentre]);

  const onSubmit = (formValues) => {
    dispatch(editCostCentre(formValues, props.match.params.id, history));
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        console.log(data);
        let mapedData = data.map((item) => item.CompanyName);
        setCompanyDetail(mapedData);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);

  const findGst = async (value) => {
    let id;

    try {
      id = allCompany.find((item) => item.CompanyName === value);
      const payload = await axios.get(`commonMaster/companyDetails/${id.id}`);
      // console.log("payload", payload);
      const gsts = payload.data.Gsts;
      setCompanyGsts(gsts);
      console.log(gsts);
      // const gstRegNos = gsts.map((item) => item.GSTRegNo);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Breadcrumbs
        name="Cost Center Edit"
        linkName1="Cost Center Dashbord"
        to1="/commonMaster/costCentre"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Cost Centre Edit
      </h3>
      <div className="line"></div>
      <CostCentre
        companyDetail={companyDetail}
        initialValues={pick(
          data,
          "ProjectName",
          "CostCenterName",
          "ProjectSiteAddress",
          "GSTRegNo",
          "Tags",
          "CompanyName"
        )}
        costcenteredit={gst}
        Gst={gst}
        companyGsts={companyGsts}
        findGst={findGst}
        edit={true}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
