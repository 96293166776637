import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import UnitOfMeasurement from "../../../forms/CommonMaster/UnitOfMeasurement";
import { useHistory } from "react-router-dom";
import {
  editUnitOfMeasurement,
  searchUnitOfMeasurementById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const UnitOfMeasurementEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchUnitOfMeasurementById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleUnitOfMeasurement) {
      setData(store.SingleUnitOfMeasurement);
    }
  }, [store.SingleUnitOfMeasurement]);

  const onSubmit = (formValues) => {
    dispatch(editUnitOfMeasurement(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="UOM Edit"
        linkName1="UOM Dashboard"
        to1="/commonMaster/unitOfMeasurement"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Unit Of Measurement
      </h3>
      <div className="line"></div>
      <UnitOfMeasurement
        initialValues={pick(
          data,
          "UOMCode",
          "UOMDescription",
          "AllowableDecimal",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
