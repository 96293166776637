import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import DailyProgressMachineTableView from "./DailyProgressMachineTableView";
import DailyProgressMaterialTableView from "./DailyProgressMaterialTableView";
import DailyProgressSCItemTableView from "./DailyProgressSCItemTableView";
import { format } from "date-fns";

import useStyle from "./UseStyle";
export const DailyProgressActivityTableView = ({ DailyProgressReportSub }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (DailyProgressReportSub) {
      setData([...DailyProgressReportSub]);
    }
  }, [DailyProgressReportSub]);
  const classes = useStyle();
  return (
    <>
      {data.map((item) => (
        <div>
          <Box border={1} borderRadius={16} className={classes.Box}>
            <h2>Activity :</h2>
            <div className={classes.boxInner}>
              <Paper className={classes.paper}>
                <h6>SerialNo:</h6>
                <p>{item.SerialNo}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>ActivityCodeName:</h6>
                <p>{item.ActivityCodeName}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>UOM:</h6>
                <p>{item.UOM}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>WorkDoneQuantity:</h6>
                <p>{item.WorkDoneQuantity}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>DoneBy:</h6>
                <p>{item.DoneBy}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Involving:</h6>
                <p>{item.Involving}</p>
              </Paper>
              {item.VendorCodeName && (
                <Paper className={classes.paper}>
                  <h6>VendorCodeName:</h6>
                  <p>{item.VendorCodeName}</p>
                </Paper>
              )}
              {item.WorkOrderNo && (
                <Paper className={classes.paper}>
                  <h6>WorkOrderNo:</h6>
                  <p>{item.WorkOrderNo}</p>
                </Paper>
              )}
              {item.WorkOrderDate && (
                <Paper className={classes.paper}>
                  <h6>WorkOrderDate:</h6>
                  <p>{format(new Date(item.WorkOrderDate), "d MMM yyyy")}</p>
                </Paper>
              )}
            </div>
            <div style={{ paddingLeft: "10%" }}>
              <h3>SC ItemCode :</h3>
              <DailyProgressSCItemTableView
                DailyProgressSCItemTableView={item.ScItemDetails}
              />
              <br />
              {item.MachineDetails && item.MachineDetails.length > 0 && (
                <>
                  <h3>Machine Code :</h3>
                  <DailyProgressMachineTableView
                    DailyProgressMachineTableView={item.MachineDetails}
                  />
                </>
              )}
              <br />
              {item.MaterialDetails && item.MaterialDetails.length > 0 && (
                <>
                  <h3>Material Code :</h3>
                  <DailyProgressMaterialTableView
                    DailyProgressMaterialTableView={item.MaterialDetails}
                  />
                </>
              )}
            </div>
          </Box>
        </div>
      ))}
    </>
  );
};
