import React, { useState, useEffect } from "react";

export const DailyAttendanceTableView = (props) => {
  const [data, setData] = useState([]);
  const { DailyAttendanceTableView } = props;

  useEffect(() => {
    let values = [];
    if (DailyAttendanceTableView) {
      values = [...DailyAttendanceTableView];
    }
    setData(values);
  }, [DailyAttendanceTableView]);

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2">Serial No</th>
            <th rowSpan="2">Token No</th>
            <th rowSpan="2">Workmen Name</th>
            <th rowSpan="2">Present</th>
            <th rowSpan="2">Gang Code</th>
            <th rowSpan="2">Shift Code</th>
            <th rowSpan="2">Time In</th>
            <th rowSpan="2">Time Out</th>
            <th rowSpan="2">Normal Hour</th>
            <th rowSpan="2">Over time</th>
            <th rowSpan="2">Activity / Machine</th>
            <th rowSpan="2">Activity / Machine Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{row.SerialNo}</td>
              <td className="table-data2">{row.TokenNo}</td>
              <td className="table-data2">{row.WorkmenName}</td>
              <td className="table-data2">{row.Present ? "Yes" : "No"}</td>
              <td className="table-data2">{row.GangCode ? row.GangCode : "-"}</td>
              <td className="table-data2">{row?.ShiftCode ? row?.ShiftCode : "-"}</td>
              <td className="table-data2">{row?.TimeIn ? row?.TimeIn : "-"}</td>
              <td className="table-data2">{row?.TimeOut ? row?.TimeOut : "-"}</td>
              <td className="table-data2">{row.NormalHour ? row.NormalHour : "-"}</td>
              <td className="table-data2">{row.Overtime ? row.Overtime : "-"}</td>
              <td className="table-data2">
                {row?.ActivityMachineSelect ? row?.ActivityMachineSelect : "-"}
              </td>
              <td className="table-data2">{row?.ActivityMachineDesc ? row?.ActivityMachineDesc : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
