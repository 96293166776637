import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const JournalSubSupplierTable = ({ tableData, populatedData, journalEdit }) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (journalEdit) {
      setLoading(true);
      setInputFields([...journalEdit]);
      if (tableData) tableData(journalEdit);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [journalEdit]);

  useEffect(() => {
    let values = [];
    if (populatedData) {
      for (let i = 0; i < populatedData.length; i++) {
        values = [
          ...values,
          {
            PurchaseOrderNo: populatedData[i].PurchaseOrderNo,
            PurchaseOrderDate: populatedData[i].PurchaseOrderDate,
            WarehouseReceiptVoucherNo:
              populatedData[i].WarehouseReceiptVoucherNo,
            WarehouseReceiptVoucherDate: populatedData[i].WarehouseReceiptDate,
            SupplierInvoiceNo: populatedData[i].SupplierInvoiceNo,
            SupplierInvoiceDate: populatedData[i].SupplierInvoiceDate,
            SupplierInvoiceAmount: populatedData[i].SupplierInvoiceAmount,
            WarehouseReceiptAmount: populatedData[i].WarehouseReceiptAmount,
          },
        ];
      }
      setInputFields(values);
    }
    if (tableData) {
      if (inputFields) {
        tableData(inputFields);
      } else {
        tableData({ error: true, errorKind: "some input field is empty" });
      }
    }
    // eslint-disable-next-line
  }, [populatedData]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    if (tableData) tableData(values);
  };

  return (
    <>
      {!loading && inputFields ? (
        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                <th>Sl. No</th>
                <th>Purchase Order No</th>
                <th>Purchase Order Date</th>
                <th>Warehouse Receipt No</th>
                <th>Warehouse Receipt Date</th>
                <th>Supplier Invoice No</th>
                <th>Supplier Invoice Date</th>
                <th>Amount</th>
                <th>Authorize?</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{inputField.PurchaseOrderNo}</td>
                  <td>
                    {format(
                      new Date(inputField.PurchaseOrderDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td>{inputField.WarehouseReceiptVoucherNo}</td>
                  <td>
                    {format(
                      new Date(inputField.WarehouseReceiptVoucherDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td>{inputField.SupplierInvoiceNo}</td>
                  <td>
                    {format(
                      new Date(inputField.SupplierInvoiceDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td>{inputField.SupplierInvoiceAmount}</td>
                  <td>
                    <select
                      style={{ width: "8rem", height: "2rem" }}
                      name="Authorize"
                      value={inputField.Authorize}
                      onChange={(event) => handleChangeInput(index, event)}
                    >
                      <option value={""}></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default JournalSubSupplierTable;
