import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachinePurchaseRequest from "../../../forms/MachineManagementSystem/MachinePurchaseRequest";
import { useHistory } from "react-router-dom";
import {
  editMachinePurchaseRequest,
  searchMachinePurchaseRequestById,
} from "../../../redux/actions/MachineManagementaction";

export const MachinePurchaseRequestEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachinePurchaseRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachinePurchaseRequest) {
      setData(store.SingleMachinePurchaseRequest);
    }
  }, [store.SingleMachinePurchaseRequest]);
  const onSubmit = (formValues) => {
    dispatch(
      editMachinePurchaseRequest(formValues, props.match.params.id, history)
    );
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Purchase Request Edit
      </h3>
      <div className="line"></div>
      <MachinePurchaseRequest
        initialValues={pick(
          data,
          "ProjectCodeName",
          "PRType",
          "MachineCategory",
          "Currency",
          "Remarks",
          "TotalMPRValue",
          "DeliveryAt",
          "RequiredOn"
        )}
        materials={data.materials}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
