import React from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Disbursement/DisbursementContraForm";
import axios from "../../../../components/apis/axios";

export const DisbursementContraCreate = () => {
  const history = useHistory();

  const onSubmit = async (formValues) => {
    const response = await axios.post(
      "/accountingSystem/disbursement/contra/create",
      formValues
    );
    console.log(response);
    history.push("/accountingSystem/disbursement/contra/create");
  };

  return (
    <div style={{ paddingTop: "80px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Disbursement Contra
      </h3>
      <Form onSubmit={onSubmit} />
    </div>
  );
};
