import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalProjectWorkforceManagement } from "../../../redux/actions/ProjectWorkforceManagementAction";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FileListArea } from "../../../components/formcomponents/control";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";


export const WorkmenView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `commonMaster/workmen/${match.params.id}`
        );
        let data = response.data;
        console.log("workmen ->", data);
        setData(data);
      } catch (err) {
       console.log("workmen ->", err);
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalProjectWorkforceManagement(
        "workmenJoining",
        match.params.id,
        history,
        data
      )
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
       <BreadCrumbs
        name="Workmen View"
        linkName1="Workmen Dashboard"
        to1="/commonMaster/Workmen/"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Workmen</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>WorkmenType:</h6>
                  <p>{data.WorkmenType}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>VendorCode:</h6>
                  <p>{data.VendorCode}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenName:</h6>
                  <p>{data.WorkmenName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenReligion:</h6>
                  <p>{data.WorkmenReligion}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenFather:</h6>
                  <p>{data.WorkmenFather}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenDOB:</h6>
                  <p>{data.WorkmenDOB}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenGender:</h6>
                  <p>{data.WorkmenGender}</p>
                </Paper>
                
                <Paper className={classes.paper}>
                  <h6>WorkmenPresentAddress:</h6>
                  <p>{data.WorkmenPresentAddress}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenPermanentAddress:</h6>
                  <p>{data.WorkmenPermanentAddress}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenEmail:</h6>
                  <p>{data.WorkmenEmail}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenPhone:</h6>
                  <p>{data.WorkmenPhone}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenContact:</h6>
                  <p>{data.WorkmenContact}</p>
                </Paper>  
                <Paper className={classes.paper}>
                  <h6>WorkmenMarital:</h6>
                  <p>{data.WorkmenMarital}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenChild:</h6>
                  <p>{data.WorkmenChild}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenMotherTongue:</h6>
                  <p>{data.WorkmenMotherTongue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenIdentification:</h6>
                  <p>{data.WorkmenIdentification}</p>
                </Paper>
                 
                <Paper className={classes.paper}>
                  <h6>WorkmenHeight:</h6>
                  <p>{data.WorkmenHeight}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenWeight:</h6>
                  <p>{data.WorkmenWeight}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenVision:</h6>
                  <p>{data.WorkmenVision}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenEducation:</h6>
                  <p>{data.WorkmenEducation}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenPast:</h6>
                  <p>{`${data.WorkmenExpInYears} Years & ${data.WorkmenExpInMonths} Months`}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenDOJ:</h6>
                  <p>{data.WorkmenDOJ}</p>
                </Paper>
                
                <Paper className={classes.paper}>
                  <h6>WorkmenDOE:</h6>
                  <p>{data.WorkmenDOE}</p>
                </Paper>
                
                <Paper className={classes.paper}>
                  <h6>WorkmenSkill:</h6>
                  <p>{data.WorkmenSkill}</p>
                </Paper>
                 
                <Paper className={classes.paper}>
                  <h6>WorkmenDesignation:</h6>
                  <p>{data.WorkmenDesignation}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenPF:</h6>
                  <p>{data.WorkmenPF}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenUniAccNo:</h6>
                  <p>{data.WorkmenUniAccNo}</p>
                </Paper>
                
                <Paper className={classes.paper}>
                  <h6>WorkmenESIC:</h6>
                  <p>{data.WorkmenESIC}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenBankAccount:</h6>
                  <p>{data.WorkmenBankAccount}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenBankName:</h6>
                  <p>{data.WorkmenBankName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenBankIFSC:</h6>
                  <p>{data.WorkmenBankIFSC}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenAadhar:</h6>
                  <p>{data.WorkmenAadhar}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenPAN:</h6>
                  <p>{data.WorkmenPAN}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenWages:</h6>
                  <p>{data.WorkmenWages}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenWageType:</h6>
                  <p>{data.WorkmenWageType}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenOT:</h6>
                  <p>{data.WorkmenOT}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>WorkmenBenefits:</h6>
                  <p>{data.WorkmenBenefits}</p>
                </Paper>
            </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>
            {(post === RoleList.ADMIN.code ||
              post === RoleList.SITE_INCHARGE.code ||
              post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
              post === RoleList.DIRECTOR.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"Workmen Joining"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
