import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Workmen from "../../../forms/CommonMaster/Workmen";
import axios from "../../../components/apis/axios";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import FormHeading from "../../../components/FormHeading/Heading";
import { getNumberFromString } from "../../../utils/common";


let AllVendor = [];
let AllCostCentre = [];


export const WorkmenCreate = () => {
  const [vendorList, setVendorList] = useState([]);
  const [costCentre, setCostCentrelist] = useState([]);
  const [benefitlist, setBenefitlist] = useState([]);
  const [newWorkmenCode, setNewWorkmenCode] = useState("");
  const history = useHistory();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "/commonMaster/workmen",
      formValues
    );
    console.log(response);
    history.push("/commonMaster/workmen/");
  };

  useEffect(() => {

    async function getWorkmenCode(){
      try{
      const { data } = await axios.get("/commonMaster/workmen");
      if (data.length > 0) {
        const lastWorkmenCode = data[0]?.WorkmenCode;
        if (lastWorkmenCode) {
          let bnenefitCodeNumber = getNumberFromString(lastWorkmenCode);
          let newWorkmenCodeValue = "";
          if (bnenefitCodeNumber?.toString()?.length === 1) {
            newWorkmenCodeValue = `WM00000${bnenefitCodeNumber + 1}`;
          } else {
            bnenefitCodeNumber = (bnenefitCodeNumber + 1).toString().padStart(6, 0);
            newWorkmenCodeValue = `WM${bnenefitCodeNumber}`;
          }
          setNewWorkmenCode(newWorkmenCodeValue);
        } 
        console.log(newWorkmenCode)
      } else {
        setNewWorkmenCode("WM00001");
      }
    } catch (error) {
      //
    }
    }
    async function getVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");

        AllVendor = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let VNList = AllVendor.map((item) => item.VendorName);
        setVendorList(VNList);
      } catch (err) {
        alert(err);
      }
    }
    async function getCostCentre() {
      try {
        let { data } = await axios.get("/commonMaster/costCentre");
        console.log(data);
        
        AllCostCentre = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let CCList = AllCostCentre.map((item) => item.CostCenterName);
        setCostCentrelist(CCList);
      } catch (err) {
        alert(err);
      }
    }
    async function getBenefits() {
      try {
        let { data } = await axios.get("/commonMaster/benefits");
        console.log(data);
        
        
        
        let benefitlist = data.map((item) =>item?.benefitCode + " - " + item?.benefitDescription);
        setBenefitlist(benefitlist);
        console.log(benefitlist);
      } catch (err) {
        alert(err);
      }
    }
    getBenefits();
    getCostCentre();
    getVendor();
    getWorkmenCode()
    // fetchProject();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Workmen Create"
        linkName1="Workmen Dashboard"
        to1="/commonMaster/Workmen/"
      />
   <FormHeading label="Workmen Master" />
      <Workmen
       initialValues={{
          WorkmenCode: newWorkmenCode,
        }} 
        onSubmit={onSubmit}
        benefitlist={benefitlist}
        VNList={vendorList}
        CCList={costCentre}

      />
    </div>
  );
};
