/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import pick from "lodash/pick";
import { useHistory } from "react-router-dom";
import PurchaseOrderForm from "../../../forms/MaterialSystem/PurchaseOrder​Form";
import "../style/materialSystem.css";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllVendorData = [];
let allProjects = [];

export const PurchaseOrderFromEdit = (props) => {
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  const [list, setList] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [vendorNameList, setVendorNameList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [materials, setMaterials] = useState();
  const [VendorGsts, setVendorGsts] = useState([]);
  const [projects, setProjects] = useState();
  const [materialsQty, setMaterialsQty] = useState();

  const user = useSelector((store) => store.userRoot);
  const selectProject = async (project) => {
    const { data } = await axios.post(
      "/materialManagement/shared/getPurchaseRequest/",
      {
        requestedFrom: "PurchaseOrder",
        ProjectCodeName: project,
      }
    );
    const mapped = data.map((item) => item.PurchaseRequestNo);
    setList(mapped);
    const details = allProjects.find(
      (item) => item?.ProjectShortDescription === project
    );
    let payload = {
      ...formDetail,
      ProjectCodeName: project,
      CustomerName: details.CustomerName,
    };
    setFormDetail(payload);
  };

  const selectPurchaseReq = async (PurchaseRequestNo) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/purchaseRequest/getByPurchaseRequestNo/${PurchaseRequestNo}`
      );
      setMaterials(data.materials);
      let payload = {
        ...formDetail,
        PurchaseRequestNo: data.PurchaseRequestNo,
        Warehouse: data.WarehouseName,
        FNCGSTNo: data.WarehouseGSTNo,
        Currency: data.Currency,
        Date: data.created_at,
        DeliveryAt: data.DeliveryAt,
      };
      setFormDetail(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const SelectVendorName = async (VendorName) => {
    const item = AllVendorData.find((item) => item.VendorName === VendorName);
    setFormDetail({
      ...formDetail,
      VendorName: item?.VendorName,
    });
    try {
      const response = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gsts = response.data.Gsts;
      const list = gsts.map((item) => item.GSTRegNo);
      setVendorGsts(list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchPR() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const newData = [
          ...new Map(data.map((item) => [item["ProjectCode"], item])).values(),
        ];
        let approvedList = newData.filter((item) => item.Status === "accepted");
        allProjects = approvedList;

        if (user.user.first_name === "admin") {
          let PNList = approvedList.map(
            (item) => item?.ProjectShortDescription
          );
          setProjects(PNList);
        } else {
          let newPNList = [];
          for (let i = 0; i < user.user.working_on.length; i++) {
            const PNList = approvedList.find(
              (item) =>
                item?.ProjectShortDescription ===
                user.user?.working_on[i].ProjectName
            );
            newPNList.push(PNList);
          }
          let PNList = newPNList.map((item) => item?.ProjectShortDescription);
          setProjects(PNList);
        }
      } catch (error) {
        alert(error);
      }
    }

    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        AllVendorData = data;
        let VNlist = data.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }

    fetchPR();
    fetchVendor();
  }, []);

  useEffect(() => {
    let files = "";
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/materialManagement/purchaseOrder/${match.params.purchaseOrderId}`
        );
        console.log(data);
        setMaterials(data.materials);
        setPurchaseOrder(data);
        files = data.Files;
        setPreviousFiles(files);

        const { data: qty } = await axios.get(
          `/materialManagement/shared/getPurchaseRequestQty/${data.PurchaseRequestNo}`
        );
        setMaterialsQty(qty.materials);
      } catch (err) {
        console.error(err);
      }
    }
    //console.log(match.params);
    fetch();
  }, [match.params]);

  const onSubmit = async (formValues, history) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log("kkkkkk");
    console.log(JSON.stringify(formValues));
    try {
      await axios.put(
        `/materialManagement/purchaseOrder/${props.match.params.purchaseOrderId}`,
        formValues
      );
      alert("Updated Successfully");
      history.push("/materialManagement/purchaseOrder");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      } else if (err.response.status === 422) {
        alert("server error!");
      }
      console.log("Error in purchaseRequest", err);
    }
  };

  return (
    <div>
      <Breadcrumbs
        name=" Purchase Order  Create"
        linkName1=" Purchase Order  Dashboard"
        to1="/materialManagement/purchaseOrder"
      />
      <h3
        style={{
          textAlign: "center",
          margin: "1rem 1rem 0 1rem",
        }}
      >
        Edit the Purchase Order
      </h3>
      <div className="line"></div>
      {console.log("all", purchaseOrder)}
      <PurchaseOrderForm
        VNlist={vendorNameList}
        list={list}
        selectProject={selectProject}
        SelectVendorName={SelectVendorName}
        selectPurchaseReq={selectPurchaseReq}
        projects={projects}
        initialValues={pick(
          purchaseOrder,
          "Date",
          "CustomerName",
          "ProjectCodeName",
          "PurchaseRequestNo",
          "TransportWorkOrder",
          "OurRefNoDate",
          "YourRefNoDate",
          "VendorCodeName",
          "VendorGSTNo",
          "FNCGSTNo",
          "Warehouse",
          "DeliveryAt",
          "DeliveryPeriodFrom",
          "DeliveryPeriodTo",
          "Inspection",
          "Currency",
          "LDClause",
          "PriceVariationClause",
          "PurchaseOrderValue"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        materialsEditData={materials}
        payments={purchaseOrder.totalpayments}
        previousFiles={previousFiles}
        VendorGsts={VendorGsts}
        setPreviousFiles={setPreviousFiles}
        materialsQty={materialsQty}
      />
    </div>
  );
};
