import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CompanyDetails from "../../../forms/CommonMaster/CompanyDetails";
import { companyDetails } from "../../../redux/actions/CommonMasterAction";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import StateList from "../../../components/helpers/StateList";

export const CompanyDetailsCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [stateList, setStateList] = useState([]);

  const onSubmit = (formValues) => {
    dispatch(companyDetails(formValues, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Company Details"
        linkName1="Company Details Dashboard"
        to1="/commonMaster/companyDetails"
      />
      <FormHeading label="Company Details" />
      <CompanyDetails onSubmit={onSubmit} stateList={stateList} />
    </div>
  );
};
