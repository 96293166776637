import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import Table from "../../../components/FormDashBoard/FormDashboardLessPagination";
import { userLogout } from "../../../redux/actions/userAction";
import RoleList from "../../../components/helpers/RoleList";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useCallback } from "react";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const AccessRequestDash = () => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;

  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;
  const fetch = useCallback(async () => {
    try {
      const { data } = await axios.get("/users", {
        params: {
          status: "Accepted",
          page: 1,
          ...readAllFromQueryParams(search),
        },
      });
      console.log(data);
      const newData = [
        ...new Map(
          data.data?.map((item) => [item["EmployeeCode"], item])
        ).values(),
      ];
      console.log(newData);
      newData.forEach((element) => {
        element.EmployeeCodeName =
          element?.EmployeeCode +
          "-" +
          element?.first_name +
          " " +
          element?.last_name;
        element.created_at = moment(element.created_at).format(
          "DD/MM/YYYY, h:mm a"
        );
        element.created_by = {
          first_name: "admin",
          id: store.userRoot.user.id,
        };
      });
      setLists(newData);
      setPaginatedResults({ ...data, data: newData });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    }
  }, [dispatch, history, search]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Breadcrumbs name="Access Control Dashboard" />
      <Table
        paginateResults={paginatedResults}
        total={lists.total}
        DeleteLink="/users"
        lists={lists}
        tablecol1="EmployeeCodeName"
        heading="Access Request"
        label1="Access Request"
        link="/accessControl/accessRequest/create"
        editLink="/accessControl/accessRequest/edit"
        viewLink="/accessControl/accessRequest/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </>
  );
};
