import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Disbursement/DisbursementSubSupplierForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import { useSnackbar } from "notistack";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";

let allProject = [];

export const DisbursementSubSupplierEdit = (props) => {
  const history = useHistory();
  const [list, setList] = useState([]);

  const [formDetails, setFormDetails] = useState({});
  const [vendorNameList, setVendorNameList] = useState([]);
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [ProjectCodeName, setProjectCodeName] = useState();
  const [VendorCodeName, setVendorCodeName] = useState();
  const [populatedData, setPopulatedData] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/disbursementSubSupplier/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/disbursementSubSupplier/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/disbursementSubSupplier/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/disbursementSubSupplier/");

        setFormDetails(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectProject = (project) => {
    const item = allProject.find(
      (item) => item.ProjectShortDescription === project
    );
    let detail = {
      ProjectCodeName: item.ProjectShortDescription,
      CustomerName: item.CustomerName,
    };
    setFormDetails({ ...formDetails, detail });
    setProjectCodeName(item.ProjectShortDescription);
    getData(FromDate, ToDate, project, VendorCodeName);
  };

  const onVendorChange = (value) => {
    setVendorCodeName(value);
    getData(FromDate, ToDate, ProjectCodeName, value);
  };

  const onFromDateChange = (date) => {
    setFromDate({ ...formDetails, ...date });
    getData(date, ToDate, ProjectCodeName, VendorCodeName);
  };

  const onToDateChange = (date) => {
    setToDate({ ...formDetails, ...date });
    getData(FromDate, date, ProjectCodeName, VendorCodeName);
  };

  const getData = async (fromDate, toDate, project, vendor) => {
    if (fromDate && toDate && project && vendor)
      try {
        const request = {
          FromDate: fromDate,
          ToDate: toDate,
          ProjectCodeName: project,
          VendorCodeName: vendor,
        };
        // console.log("request ->", request);
        const { data } = await axios.post(
          "/accountingSystem/shared/getDataForDisbursementSubSupplier",
          request
        );
        // console.log(data);
        if (data.length > 0) {
          setPopulatedData(data);
        } else
          enqueueSnackbar("Oops No data found", {
            variant: "warning",
          });
      } catch (err) {
        console.error(err);
      }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }

    async function getCostCenterDetails() {
      try {
        setFormDetails({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }

    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNlist = data.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }
    fetchProject();
    getCostCenterDetails();
    fetchVendor();
  }, []);

  return (
    <div style={{ paddingTop: "80px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Disbursement Sub-Supplier
      </h3>
      <Form
        onSubmit={onSubmit}
        selectProject={selectProject}
        list={list}
        vendorNameList={vendorNameList}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        onVendorChange={onVendorChange}
        populatedData={populatedData}
        initialValues={pick(
          formDetails,
          "CostCentre",
          "Currency",
          "FinancialPeriod",
          "ProjectCodeName",
          "CustomerName",
          "VendorCodeName",
          "FromDate",
          "ToDate",
          "AdvanceAsPerBooks",
          "AdvanceAdjusted",
          "IfPaymentMode",
          "FromBank",
          "AvailableBankBalance",
          "DisbursedValue",
          "Cheque_NEFT_RTGS_IMPS_No",
          "Date",
          "AvailableCashBalance",
          "NarrationCash",
          "AdvanceAfterAdjustment"
        )}
        DisbursementSubSupplierEdit={formDetails.paymentDetails}
        cashDetails={formDetails.CashDetails}
      />
    </div>
  );
};
