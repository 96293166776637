import React, { useEffect, useState } from "react";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { Select } from "antd";
import { useCallback } from "react";
import moment from "moment";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import ExportProjectCircular from "../Export/ExportProjectCircular";
import useUpdateSearch from "../../../utils/hooks/useUpdateSearch";

const { Option } = Select;

export const ProjectCircularDashBoard = () => {
  const { search } = useLocation();
  const [lists, setLists] = useState({
    data: [],
  });
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const [updateSearch] = useUpdateSearch(search);
  const params = readAllFromQueryParams(search);

  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code
      ? true
      : false;

  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code
      ? true
      : false;

  const fetch = useCallback(async () => {
    try {
      const { data } = await axios.get("/salesAccounting/projectCircular", {
        params: {
          status: "not_reviewed",
          page: 1,
          ...readAllFromQueryParams(search),
        },
      });
      const newData = [
        ...new Map(
          data.data.map((item) => [item["ProjectCode"], item])
        ).values(),
      ];
      // newData.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
      newData.forEach((element) => {
        element.ProjectCode =
          element.ProjectCode + "-" + element.ProjectShortDescription;
        let endDate = moment(element.updated_at).format("DD/MM/YYYY, h:mm a");
        element.updated_at = moment(endDate).format("DD/MM/YYYY, h:mm a");
        element.created_at = moment(element.created_at).format(
          "DD/MM/YYYY, h:mm a"
        );

        element.approved_at =
          element.approved_at &&
          moment(element.approved_at).format("DD/MM/YYYY, h:mm a");
      });

      setLists({ ...data, data: newData });
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    }
  }, [dispatch, history, search]);

  useEffect(() => {
    fetch();
  }, [fetch, search]);

  return (
    <>
      <BreadCrumbs name="Project Circular DashBoard" />
      <Table
        paginateResults={lists}
        total={lists.total}
        DeleteLink="/salesAccounting/projectCircular"
        lists={lists.data}
        tablecol1="ProjectCode"
        heading="Project Circular DashBoard"
        label1="Project Code"
        link="/salesAccounting/projectCircular/create"
        editLink="/salesAccounting/projectCircular/edit"
        viewLink="/salesAccounting/projectCircular/view"
        button={button}
        visibleIcon={visibleIcon}
        exportComponent={
          <ExportProjectCircular fileName="Project Circular DashBoard" />
        }
        additionalFilter={
          <Select
            defaultValue={params?.query}
            placeholder="Company"
            onChange={(value) => {
              updateSearch({
                query: value,
                page: 1,
              });
            }}
          >
            {["HPRO", "FPRO"].map((option) => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))}
          </Select>
        }
        statusfilter={["all", "accepted", "not_reviewed"]}
        defaultStatusValue="not_reviewed"
        enableFilters={["Available"]}
      />
    </>
  );
};
