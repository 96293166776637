import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/SalesAccountingSystem/SalesOrderForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];

export const SalesOrderCreate = () => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [orderValue, setOrderValue] = useState(0);
  const [uom, setUom] = useState(null);

  const onSubmit = async (formValues) => {
    console.log(formValues,'fghjkfgh');
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.post(
      "/salesAccounting/salesOrder/",
      formValues
    );
    console.log(response);
    history.push("/salesAccounting/salesOrder/");
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProjects.find((item) =>
        item.ProjectCode + " - " + item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail.id}`
        );
        detail = {
          ...formDetail,
          ProjectCodeName: data.ProjectShortDescription,
          CustomerName: data.CustomerName,
          OrderReferenceNo: data.OrderReferenceNo,
          OrderReferenceDate: data.OrderReferenceDate,
          OrderValue: data.ProjectOrderValue,
          OrderType: data.OrderType,
          CustomerGSTNo: data.CustomerGstNo,
          ProjectStartDate: data.ProjectStartDate,
          ProjectEndDate: data.ProjectEndDate,
          StagePercentage: "100%",
          Stage: "No",
          CompanyName: data.CompanyName,
          CompanyGstNo: data.CompanyGstNo,
        };
        setOrderValue(data.ProjectOrderValue);
        setFormDetail(detail);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const newData = [
          ...new Map(data.map((item) => [item["ProjectCode"], item])).values(),
        ];
        let filteredData = newData.filter((item) => item.Status === "accepted");
        let mapedData = filteredData.map(
          (item) => item.ProjectCode + " - " + item.ProjectShortDescription
        );
        setProjectList(mapedData);
        allProjects = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    async function getUOM() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        setUom(data);
      } catch (err) {
        console.error(err);
      }
    }

    fetch();
    getUOM();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Sales Order  Create"
        linkName1="Sales Order  Dashboard"
        to1="/salesAccounting/salesOrder"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Order
      </h3>
      <Form
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        orderValue={orderValue}
        uom={uom}
        edit={false}
        initialValues={pick(
          formDetail,
          "CustomerName",
          "ProjectCodeName",
          "OrderReferenceNo",
          "OrderReferenceDate",
          "OrderValue",
          "OrderType",
          "CustomerGSTNo",
          "StagePercentage",
          "Stage",
          "LdClause",
          "ProjectStartDate",
          "ProjectEndDate",
          "CompanyName",
          "CompanyGstNo"
        )}
      />
    </div>
  );
};
