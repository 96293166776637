import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";

import ProfileRoute from "../../pages/Profile/Profile";

const Profile = (
  <Switch>
    <Route exact path="/profile" component={ProfileRoute} />
  </Switch>
);

export default Profile;
