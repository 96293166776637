import React, { useState, useEffect } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";

export const WareHouseReceiptTableView = (props) => {
  const [data, setData] = useState([]);
  const { materials } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);
  return (
    <>
      <TableContainer className="tableContainer">
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell align="center" className="head-cell">MaterialCode</TableCell>
              <TableCell align="center" className="head-cell">MaterialDescription</TableCell>
              <TableCell align="center" className="head-cell">HSNSACCode</TableCell>
              <TableCell align="center" className="head-cell">UOM</TableCell>
              <TableCell align="center" className="head-cell">Received Qty</TableCell>
              <TableCell align="center" className="head-cell">Accepted Qty</TableCell>
              <TableCell align="center" className="head-cell">Net Rate</TableCell>
              <TableCell align="center" className="head-cell">C-GST</TableCell>
              <TableCell align="center" className="head-cell">S-GST</TableCell>
              <TableCell align="center" className="head-cell">I-GST</TableCell>
              <TableCell align="center" className="head-cell">Item Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" className="table-data1">{row.MaterialCode}</TableCell>
                <TableCell align="center" className="table-data2">{row.MaterialDescription}</TableCell>
                <TableCell align="center" className="table-data2">{row.HSNSACCode}</TableCell>
                <TableCell align="center" className="table-data2">{row.UOM}</TableCell>
                <TableCell align="center" className="table-data2">{row.ReceivedQuantity}</TableCell>
                <TableCell align="center" className="table-data2">{row.AcceptQuantity}</TableCell>
                <TableCell align="center" className="table-data2">{row.NetRate}</TableCell>
                <TableCell align="center" className="table-data2">{row.CGST}</TableCell>
                <TableCell align="center" className="table-data2">{row.SGST}</TableCell>
                <TableCell align="center" className="table-data2">{row.IGST}</TableCell>
                <TableCell align="center" className="table-data4">{row.ItemAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </>
  );
};
