import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  Container: {
    width: "90%",
    margin: "80px auto 0 auto",
    padding: "1rem",
  },
  Container2: {
    width: "100%",
    margin: "80px auto 0 auto",
    padding: "1rem",
  },
  Heading: {
    margin: "1rem auto",
    textAlign: "center",
  },
  Box: {
    padding: "0.5rem",
    margin: "1rem",
  },
  paper: {
    margin: "0.5rem 0rem",
    backgroundColor: "white",
    border: "1px solid #000",
    padding: "15px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& p": {
      marginRight: "0.5rem ",
      display: "inline",
      color: "#000",
    },
    "& h6": {
      marginRight: "0.5rem",
      display: "inline",
      color: "#2c387e",
    },
    "& span": {
      margin: "0.5rem",
    },
  },
  ButtonGrp: {
    margin: "2rem 0",
    display: "flex",
    justifyContent: "space-evenly",
  },
  approve: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "white",
    background:
      "linear-gradient(to right top, #051937, #00466b, #00799a, #00afbf, #55e7d8)",
  },
  reject: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "white",
    background:
      "linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  yesApprove: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "black",
    background: "#0CE90B",
  },
  NoApprove: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "black",
    background: "#FF4741",
  },
  textField: {
    margin: "0.5rem",
    width: "10px",
  },
  table: {
    width: "90% !important",
    margin: "40px auto 0 auto",
  },
  button: {
    color: "white",
    position: "fixed",
    zIndex: "999",
    bottom: "40px",
    right: "40px",
    // background:
    //   "linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)",
  },
  buttonIcon: {
    color: "white",
    margin: "12px",
    fontSize: "30px",
  },
  approved: {
    fontSize: "15px",
    borderRadius: "15px",
    padding: "0.5rem",
    color: "#01C53D",
    fontWeight: "bold",
    // background:
    //   "linear-gradient(to right top, #5cf78b, #84f787, #a2f685, #baf687, #cff58b, #d6f487, #dcf383, #e3f27f, #e1f06e, #deef5c, #dced47, #daeb2d)",
  },
  delete: {
    fontSize: "15px",
    borderRadius: "15px",
    padding: "0.5rem",
    fontWeight: "bold",
    color: "black",
    background: "red",
  },
  rejected: {
    fontSize: "15px",
    borderRadius: "15px",
    padding: "0.5rem",
    fontWeight: "bold",
    color: "#E67E22",
    // background:
    //   "linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)",
  },
  deleted: {
    fontSize: "15px",
    borderRadius: "15px",
    padding: "0.5rem",
    fontWeight: "bold",
    color: "#E40007",
    // background:
    //   "linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)",
  },
  notReviewed: {
    fontSize: "15px",
    borderRadius: "15px",
    padding: "0.5rem",
    color: "#878788",
    fontWeight: "bold",
    // background:
    //   "linear-gradient(to right top, #051937, #00466b, #00799a, #00afbf, #55e7d8)",
  },
  align: {
    display: "inline-flex",
    color: "#000",
  },
  columnHeader: {
    backgroundColor: "#8383fa",
    color: "white",
    fontSize: "15px",
    fontWeight: "600",
    border: "none",
    borderRadius: "3px",
  },
});

export default useStyle;
