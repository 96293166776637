import axios from "../../components/apis/axios";

//Approval

export const approvalProjectWorkforceManagement =
  (link, id, history, data) => async (dispatch) => {
    axios
      .put(`/workforceManagement/${link}/approval/${id}`, data)
      .then((res) => {
        console.log(res);
        history.push(`/workforceManagement/${link}`);
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

export const filterAllWorkmenJoining = (params) => {
  return async (dispatch) => {
    try {
      const { data: workmenJoining } = await axios.get(
        "/workforceManagement/reports/workmenJoining",
        {
          params,
        }
      );
    
      workmenJoining.forEach((element) => {
        element.created_at = new Date(element.created_at).toDateString();
      });
      dispatch({
        type: "SET_FILTERED_WORKMEN_JOINING",
        payload: workmenJoining,
      });
    } catch (err) {
      alert(err);
      console.log("Error in workmenjoining", err);
    }
  };
};

//work men joining
// export const workmenJoining = (formValues, history) => {
//   return async (dispatch) => {
//     try {
//
//       await axios.post(
//         "/workforceManagement/workmenJoining",
//         formValues
//       );
//       alert("Submit Successfull");
//       history.push("/projectWorkforceManagement/workmenJoining");
//     } catch (error) {
//       alert(error);
//     }
//   };
// };

// export const editWorkmenJoining = (formValues, Id, history) => {
//   return async (dispatch) => {
//     try {
//       await axios.put(
//         `/projectWorkforceManagement/workmenJoining/${Id}`,
//         formValues
//       );
//       alert("Updated Successfully");
//       history.push("/projectWorkforceManagement/workmenJoining");
//     } catch (err) {
//       alert(err);
//       console.log("Error in projectWorkforceManagement", err);
//     }
//   };
// };

export const allWorkmenJoining = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/workforceManagement/workmenJoining/");
      dispatch({
        type: "SET_All_WORKMEN_JOINING",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in projectWorkforceManagement", err);
    }
  };
};

// export const searchWorkmenJoiningById = (Id) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get(
//         `/projectWorkforceManagement/workmenJoining/${Id}`
//       );
//       dispatch({
//         type: "SET_SINGLE_WORKMEN_JOINING",
//         payload: data,
//       });
//     } catch (err) {
//       console.log("Error in projectWorkforceManagement", err);
//     }
//   };
// };

//work men joining

// //dailyAttendance
// export const dailyAttendance = (formValues, history) => {
//     return async dispatch => {
//         try {
//
//             await axios.post("/workforceManagement/dailyAttendence", formValues);
//             alert("Submit Successfull");
//             history.push("/projectWorkforceManagement/dailyAttendance");
//         } catch (error) {
//             alert(error);
//         }
//     };
// };

// export const editDailyAttendance = (formValues, Id, history) => {
//     return async dispatch => {
//         try {
//             await axios.put(`/projectWorkforceManagement/dailyAttendance/${Id}`, formValues);
//             alert("Updated Successfully");
//             history.push("/projectWorkforceManagement/dailyAttendance");
//         } catch (err) {
//             alert(err);
//             console.log("Error in projectWorkforceManagement", err);
//         }
//     };
// };

// export const allDailyAttendance = () => {
//     return async dispatch => {
//         try {
//             const { data } = await axios.get("/projectWorkforceManagement/dailyAttendance");
//             dispatch({
//                 type: "SET_All_WORKMEN_JOINING",
//                 payload: data
//             });
//         } catch (err) {
//             alert(err);
//             console.log("Error in projectWorkforceManagement", err);
//         }
//     };
// };

// export const searchDailyAttendanceById = Id => {
//     return async dispatch => {
//         try {
//             const { data } = await axios.get(`/projectWorkforceManagement/dailyAttendance/${Id}`);
//             dispatch({
//                 type: "SET_SINGLE_WORKMEN_JOINING",
//                 payload: data
//             });
//         } catch (err) {
//             console.log("Error in projectWorkforceManagement", err);
//         }
//     };
// };

//dailyAttendance

export const getworkmenJoiningById = async (Id) => {
  try {
    const { data } = await axios.get(`/workforceManagement/workmenJoining/${Id}`);
    return data;
  } catch (err) {
    console.log("Error in Workmen", err);
  }
};

export const editWorkmenJoining = async (formValues, Id, history) => {
  try {
    await axios.put(`/workforceManagement/workmenJoining/${Id}`, formValues);
    alert("Updated Successfully");
    history.push("/workforceManagement/workmenJoining");
  } catch (err) {
    alert(err);
    console.log("Error in workmen", err);
  }
};
export const getworkmenExitById = async (Id) => {
  try {
    const { data } = await axios.get(`/workforceManagement/workmenExit/${Id}`);
    return data;
  } catch (err) {
    console.log("Error in Workmen", err);
  }
};

export const editWorkmenExit = async (formValues, Id, history) => {
  try {
    await axios.put(`/workforceManagement/workmenExit/${Id}`, formValues);
    alert("Updated Successfully");
    history.push("/workforceManagement/workmenExit");
  } catch (err) {
    alert(err);
    console.log("Error in workmen", err);
  }
};

export const getServiceItemById = async (Id) => {
  try {
    const { data } = await axios.get(`/workforceManagement/serviceItem/${Id}`);
    return data;
  } catch (err) {
    console.log("Error in ServiceItem", err);
  }
};

export const editServiceItem = async (formValues, Id, history) => {
  try {
    await axios.put(`/workforceManagement/serviceItem/${Id}`, formValues);
    alert("Updated Successfully");
    history.push("/workforceManagement/serviceItem");
  } catch (err) {
    alert(err);
    console.log("Error in ServiceItem", err);
  }
};

export const getdailyAttendenceById = async (Id) => {
  try {
    const { data } = await axios.get(`/workforceManagement/dailyAttendence/${Id}`);
    return data;
  } catch (err) {
    console.log("Error in Workmen", err);
  }
};

export const editDailyAttendance = async (formValues, Id, history) => {
  try {
    await axios.put(`/workforceManagement/dailyAttendence/${Id}`, formValues);
    alert("Updated Successfully");
    history.push("/workforceManagement/dailyAttendence");
  } catch (err) {
    alert(err);
    console.log("Error in ServiceItem", err);
  }
};