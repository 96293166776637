import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import * as Create from "../../pages/AccessControl/Create/export";
import * as Dash from "../../pages/AccessControl/Dashboard/export";
import * as Edit from "../../pages/AccessControl/Edit/export";
import * as View from "../../pages/AccessControl/View/export";


const AccessControl = (
  <Switch>
    {/**AccessRequest */}

    <Route
      exact
      path="/accessControl/accessRequest"
      component={Dash.AccessRequestDash}
    />
    <Route
      exact
      path="/accessControl/accessRequest/view/:id"
      component={View.AccessRequestView}
    />
    <Route
      exact
      path="/accessControl/accessRequest/create"
      component={Create.AccessRequestCreate}
    />
    <Route exact path="/accessControl/accessRequest/edit/:id" component={Edit.AccessRequestEdit} />

    {/**AccessRequest */}
  </Switch>
);
export default AccessControl;
