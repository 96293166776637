import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { approvalMaterialManagement } from "../../../redux/actions/materialSystemAction";
import { PurchaseOrderViewTable } from "../../../Tables/MaterialSystem/View/PurchaseOrderViewTable";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { FileListArea } from "../../../components/formcomponents/control";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { SalesOrderPaymentDataTableView as PaymentTerms } from "../../../Tables/SalesAcountingSystem/view/SalesOrderPaymentDataTableView";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import "./table.css";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { format } from "date-fns";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const PurchaseOrderView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const { match } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/materialManagement/purchaseOrder/${match.params.id}`
        );
        setData(data);
        console.log("purchaseOrder->", data);
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }

    fetch();
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot, match.params]);
  const classes = useStyle();

  const handleModalAction = (data) => {
    dispatch(
      approvalMaterialManagement(
        "purchaseOrder",
        match.params.id,
        history,
        data
      )
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name=" Purchase Order  View"
        linkName1=" Purchase Order  Dashboard"
        to1="/materialManagement/purchaseOrder"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Purchase Order</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Purchase Order No:</h6>
              <p>{data.PurchaseOrderNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>ProjectCode / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase Request No:</h6>
              <p>{data.PurchaseRequestNo}</p>
            </Paper>
            {data.Date && (
              <Paper className={classes.paper}>
                <h6>Date:</h6>
                <p>{format(new Date(data.Date), "d MMM yyyy")}</p>
              </Paper>
            )}
            <Paper className={classes.paper}>
              <h6>Transport Work Order​:</h6>
              <p>{data.TransportWorkOrder}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>OurRef. No / Date:</h6>
              <p>{data.OurRefNoDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Your Ref. No / Date:</h6>
              <p>{data.YourRefNoDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor Code / Name:</h6>
              <p>{data.VendorCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor GST No:</h6>
              <p>{data.VendorGSTNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>FNC GST No:</h6>
              <p>{data.FNCGSTNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse:</h6>
              <p>{data.Warehouse}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery At:</h6>
              <p>{data.DeliveryAt}</p>
            </Paper>
            {data.DeliveryPeriodFrom && (
              <Paper className={classes.paper}>
                <h6>Delivery Period From:</h6>
                <p>{format(new Date(data.DeliveryPeriodFrom), "d MMM yyyy")}</p>
              </Paper>
            )}
            {data.DeliveryPeriodTo && (
              <Paper className={classes.paper}>
                <h6>Delivery Period To​:</h6>
                <p>{format(new Date(data.DeliveryPeriodTo), "d MMM yyyy")}</p>
              </Paper>
            )}

            <Paper className={classes.paper}>
              <h6>Inspection:</h6>
              <p>{data.Inspection}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Currency:</h6>
              <p>{data.Currency}</p>
            </Paper>
            {data.LDClause && (
              <Paper className={classes.paper}>
                <h6>LD Clause​​:</h6>
                <p>{data.LDClause}</p>
              </Paper>
            )}
            {data.PriceVariationClause && (
              <Paper className={classes.paper}>
                <h6>Price Variation Clause:</h6>
                <p>{data.PriceVariationClause}</p>
              </Paper>
            )}
            <Paper className={classes.paper}>
              <h6>Purchase Order Value​​:</h6>
              <p>{data.PurchaseOrderValue}</p>
            </Paper>
          </div>
          <div style={{ paddingLeft: "10%" }}>
            <br />
            <PaymentTerms payments={data.totalpayments} />
            <h5 className={classes.Heading}>Material Detail</h5>

            <PurchaseOrderViewTable materials={data.materials} />
          </div>
        </Box>

        <br />
        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>

      {(post === RoleList.ADMIN.code ||
        post === RoleList.BU_HEAD.code ||
        post === RoleList.DIRECTOR.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}

      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Purchase Order"}
      />
    </div>
  );
};
