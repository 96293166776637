import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import SalesOrderForm from "../../../forms/SalesAccountingSystem/SalesOrderForm";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];
let allCompany = [];

export const SalesOrderEdit = (props) => {
  const history = useHistory();
  const [salesOrder, setSalesOrder] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const [orderValue, setOrderValue] = useState(0);
  const [uom, setUom] = useState(null);
  const [previousFiles, setPreviousFiles] = useState();

  const onSubmit = async (formValues) => {
    console.log('form values', formValues);
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
     await axios.put(
       `/salesAccounting/salesOrder/${props.match.params.id}`,
       formValues
     );
    history.push("/salesAccounting/salesOrder/");
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProjects.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail.id}`
        );

        detail = {
          ...salesOrder,
          ProjectCodeName: data.ProjectShortDescription,
          CustomerName: data.CustomerName,
          OrderReferenceNo: data.OrderReferenceNo,
          OrderReferenceDate: data.OrderReferenceDate,
          ProjectOrderValue: data.ProjectOrderValue,
          OrderType: data.OrderType,
          CustomerGSTNo: data.CustomerGstNo,
          ProjectStartDate: data.ProjectStartDate,
          ProjectEndDate: data.ProjectEndDate,
          StagePercentage: "100%",
          Stage: "No",
        };
        setOrderValue(detail.ProjectOrderValue);
        setSalesOrder({ ...salesOrder, ...detail });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const selectCompany = async (company) => {
    if (company) {
      let detail = allCompany.find((item) => item.CompanyName === company);
      try {
        const { data } = await axios.get(
          `/commonMaster/companyDetails/${detail.id}`
        );
        const gsts = data.Gsts.map((item) => item.GSTRegNo);
        setCompanyGsts(gsts);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesOrder/${props.match.params.id}`
        );
        let sales_order = response.data;
        setSalesOrder({ ...sales_order, StagePercentage: "100%" });
        setPreviousFiles([...sales_order.Files]);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id]);

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        let filteredData = data.filter((item) => item.Status === "accepted");
        let mapedData = filteredData.map(
          (item) => item.ProjectShortDescription
        );
        setProjectList(mapedData);
        allProjects = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        const filteredData = data.map((item) => item.CompanyName);
        setCompanyList(filteredData);
        allCompany = data;
      } catch (err) {
        console.error(err);
      }
    }
    async function getUOM() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        setUom(data);
      } catch (err) {
        console.error(err);
      }
    }

    fetch();
    getCompanyDetails();
    getUOM();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Sales Order  Edit"
        linkName1="Sales Order  Dashboard"
        to1="/salesAccounting/salesOrder"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Order
      </h3>
      <SalesOrderForm
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        companyList={companyList}
        selectCompany={selectCompany}
        companyGsts={companyGsts}
        orderValue={orderValue}
        uom={uom}
        initialValues={pick(
          salesOrder,
          "OriginalFormNo",
          "ProjectCodeName",
          "CustomerName",
          "OrderReferenceNo",
          "CompanyName",
          "OrderReferenceDate",
          "OrderType",
          "AdvanceBG",
          "Insurance",
          "CustomerGSTNo",
          "Stage",
          "OrderCategory",
          "DefectLiabilityPeriod",
          "OrderValue",
          "LDClause",
          "PriceVariationClause",
          "StagePercentage",
          "ProjectStartDate",
          "ProjectEndDate",
          "CompanyName",
          "CompanyGstNo"
        )}
        order={salesOrder.materials}
        payments={salesOrder.payments}
        retentions={salesOrder.retentions}
        stage={salesOrder.stages}
        edit={true}
        previousFiles={previousFiles}
      />
    </div>
  );
};
