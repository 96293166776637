import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "./main.css";
import { Button } from "@material-ui/core";

class MachinePurchaseRequest extends React.Component {
  state = {
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  };

  onSubmit = (formValues) => {
    if (!this.state.tableData) {
      this.setState({ tableError: true });
    } else {
      console.log({ ...formValues, excelData: this.state.tableData });
      this.props.onSubmit({ ...formValues, tableData: this.state.tableData });
    }
  };
  tableData = (data) => {
    if (!data) {
      this.setState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      this.setState({ tableData: data, tableErrorKind: "", tableError: false });
    }
  };
  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              component={Control.RenderTextField}
              name="ProjectCodeName"
              label="Project code / Name"
              style={{ width: "40%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="PRType"
              label="PR Type"
              style={{ width: "45%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="MachineCategory"
              label="Machine Category"
              style={{ width: "45%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="Currency"
              label="Currency"
              style={{ width: "30%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderDateField}
              name="RequiredOn"
              label="Required On"
              style={{ width: "20%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="DeliveryAt"
              label="Delivery At"
              style={{ width: "65%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="Remarks"
              label="Remarks"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="TotalMPRValue"
              label="Total MPR Values"
              type="number"
              style={{ width: "20%", margin: "1rem" }}
            />
          </div>
          {/* <div className="excel ">
            <Table
              tableData={this.tableData}
              materials={this.props.materials}
            />
            <div className="middle">
              <p className=" Mui-error excelError">
                {this.state.tableError ? `${this.state.tableErrorKind}` : ""}
              </p>
            </div>
          </div> */}
          <div className="middle">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "PRType",
    "MachineCategory",
    "Currency",
    "Remarks",
    "TotalMPRValue",
    "DeliveryAt",
    "RequiredOn",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachinePurchaseRequest",
  validate,
  enableReinitialize: true,
})(MachinePurchaseRequest);
