/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PaymentRequestForm from "../../../forms/AccountingSystem/PaymentRequestForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import { getCurrentFinancialYear } from "../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useSelector } from "react-redux";

let allProject = [];
export const PaymentRequestFormCreate = () => {
  const history = useHistory();
  const user = useSelector((store) => store.userRoot);
  const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [accounts, setAccounts] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [costCentreList, setCostCentreList] = useState([]);
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    try {
      await axios.post("accountingSystem/paymentRequest/", formValues);
      history.push("/accountingSystem/paymentRequest/");
    } catch (e) {
      alert(e);
    }
  };

  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      console.log("detail", detail);
      detail = {
        ...formDetail,
        ...headerState,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        CostCentre: detail.CostCentre,
      };
      setformDetail(detail);
    }
  };
  const SelectProjectFromCostCentre = async (CostCenterCode) => {
    if (CostCenterCode) {
      try {
        const { data } = await axios.post(
          `/salesAccounting/projectCircular/costCentre/Name`,
          { CostCentre: CostCenterCode }
        );
        console.log("fetch projject==>", data);
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    async function getEmployees() {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        let newData = data.filter((item) => item.Tags === "Active");
        const mapped = newData.map(
          (item) =>
            item.EmployeeCode + "-" + item.FirstName + "-" + item.LastName
        );
        setEmployeeList(mapped);
      } catch (err) {
        console.error(err);
      }
    }
    const fetch = async () => {
      try {
        const { data: accounts } = await axios.get("/commonMaster/account/");
        let newData = accounts.filter((item) => item.Tags === "Active");
        setAccounts(newData);
      } catch (err) {
        console.error(err);
      }
    };

    // header componenet logic
    async function getData() {
      try {
        setformDetail({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }
    async function getVendor() {
      try {
        const { data } = await axios.get("/commonMaster/vendor");
        let newData = data.filter((item) => item.Tags === "Active");
        const mapped = newData.map((item) => item.VendorName);
        setVendorList(mapped);
      } catch (err) {
        console.error(err);
      }
    }

    async function costFetch() {
      if (user.user.first_name === "admin") {
        const { data } = await axios.get("/commonMaster/costCentre");
        const filteredData = data.map((item) => item.CostCenterName);
        setCostCentreList(filteredData);
      } else {
        const { data } = await axios.post(
          `commonMaster/costCentre/getByCompanyName/name/`,
          {
            CompanyName: user.user?.company,
          }
        );
        const filteredData = data.map((item) => item.CostCenterName);
        setCostCentreList(filteredData);
      }
    }
    costFetch();
    getVendor();
    getData();
    fetch();
    getEmployees();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Payment Request Create"
        linkName1="Payment Request Dashboard"
        to1="/accountingSystem/paymentRequest"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Request
      </h3>
      <PaymentRequestForm
        onSubmit={onSubmit}
        list={list}
        selectProjectName={selectProjectName}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "FinancialPeriod",
          "CostCentre"
        )}
        employeeList={employeeList}
        accounts={accounts}
        vendorList={vendorList}
        handleHeaderChange={handleHeaderChange}
        costCentreList={costCentreList}
        SelectProjectFromCostCentre={SelectProjectFromCostCentre}
      />
    </div>
  );
};
