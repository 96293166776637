import React from "react";

import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DeleteModal = ({ showModal, toggleModal, onDelete, itemId }) => {
  // const classes = useStyle();

  return (
    <div>
      <div>
        <Dialog
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={toggleModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Are you sure you want to delete ?
          </DialogTitle>
          <DialogActions style={{ margin: "10px", padding: "2px" }}>
            <Button
              style={{
                borderRadius: "15px",
                color: "white",
                background: "#FF4741",
                outline: "none",
              }}
              onClick={() => {
                toggleModal();
              }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              type="submit"
              style={{
                borderRadius: "15px",
                color: "white",
                fontWeight: "600",
                background: "#02BD3D",
                marginLeft: "10px",
                outline: "none",
              }}
              onClick={() => onDelete(itemId)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default DeleteModal;
