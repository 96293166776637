import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const user = useSelector((store) => store.userRoot);
  return (
    <>
      <Route
        {...rest}
        component={(props) =>
          user.workingOn != null ? (
            <Redirect to="/dashboard" />
          ) : (
            <Component {...props} />
          )
        }
      />
    </>
  );
};

export default PrivateRoutes;
