import axios from "../../components/apis/axios";

//Approval

export const approvalExecutionSystem =
  (link, id, history, req, ) => async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/executionSystem/${link}/approval/${id}`,
        req
      );

      console.log(data);
      history.push(`/executionSystem/${link}`);
    } catch (error) {
      alert(error);
    }
  };