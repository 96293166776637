import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import { createShift } from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import Shift from "../../../forms/CommonMaster/Shift/Shift";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../components/apis/axios";
import { getNumberFromString } from "../../../utils/common";

export const ShiftsCreate = () => {
  const [newShiftCode, setNewShiftCode] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("/commonMaster/shifts");
        if (data.length > 0) {
          const lastShift = data[0]?.shift_code;
          if (lastShift) {
            const shiftNumber = getNumberFromString(lastShift);
            let newShiftValue = "";
            if (shiftNumber?.toString()?.length === 1) {
              newShiftValue = `SHF0${shiftNumber + 1}`;
            } else {
              newShiftValue = `SHF${shiftNumber + 1}`;
            }
            setNewShiftCode(newShiftValue);
          }
        } else {
          setNewShiftCode("SHF01");
        }
      } catch (error) {
        //
      }
    };
    fetch();
  }, []);

  const onSubmit = (formValues) => {
    dispatch(
      createShift(
        {
          shiftCode: formValues.shift_code,
          tag: formValues.tag,
          shiftInTime: formValues.shift_in_Time,
          shiftOutTime: formValues.shift_out_Time,
        },
        history
      )
    );
  };

  return (
    <div>
      <Breadcrumbs
        name="Shift Create"
        linkName1="Shift Dashboard"
        to1="/commonMaster/shifts"
      />
      <FormHeading label="Shift" />
      <Shift
        initialValues={{
          shift_code: newShiftCode,
        }}
        onSubmit={onSubmit}
      />
    </div>
  );
};
