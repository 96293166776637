import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";

const Machine = (props) => {
  const [show, setShow] = useState(false);
  const [machineCode, setMachineCode] = useState("");
  const [machineDes, setMachineDes] = useState("");

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="MachineCode"
            label="Machine Code"
            onChange={(e) => {
              setMachineCode(e.target.value);
            }}
          />
          <Field
            component={Control.RenderTextField}
            name="Machinedes"
            label="Machine Description"
            onChange={(e) => setMachineDes(e.target.value)}
          />

          <Field
            component={Control.AutoCompleteInput}
            name="MachineGcode"
            label="Machine Group Code"
            list={props.machineGroup}
            className="text-field"
            onChange={(value) => {
              props.SelectMachineGroup &&
                props.SelectMachineGroup(value, { machineCode, machineDes });
            }}
          />
          <Field
            component={Control.RenderTextField}
            name="MachineGdes"
            label="Machine Group Description"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialCcode"
            label="Machine Class Code"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialCdes"
            label="Machine Class Description"
            disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="MachineOwnership"
            label="Machine Ownership"
          >
            <option value=""></option>
            <option value="hired">Hired</option>
            <option value="owned">Owned</option>
          </Field>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Machine"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "MachineCode",
    "MachineGcode",
    "MaterialCdes",
    "MaterialCcode",
    "MachineGdes",
    "Machinedes",
    "MachineOwnership",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "machine",
  validate,
  enableReinitialize: true,
})(Machine);
