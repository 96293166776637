import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GoodsInspectionVoucherForm from "../../../forms/MaterialSystem/GoodsInspectionVoucherForm";
import { goodsInspectVoucher } from "../../../redux/actions/materialSystemAction";
import "../style/materialSystem.css";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allGoodsRec = [];

export const GoodsInspectionVoucherFormCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [materials, setMaterials] = useState(null);
  const [projectNameList, setprojectNameList] = useState([]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(goodsInspectVoucher(formValues, history));
  };

  const selectGoodRec = async (GoodsReceiptNo) => {
    const item = allGoodsRec.find(
      (item) => item.GoodsReceiptVoucherNo === GoodsReceiptNo
    );
    if (item) {
      let { data } = await axios.get(
        `/materialManagement/goodsReceipt/${item.id}`
      );
      console.log("data", data);
      setMaterials(data.materials);
      setFormDetail({
        ...data,
        PurchaseDate: data.PurchaseDate,
        DateofReceipt: data.DateofReceipt,
        DeliveryDate: data.DeliveryDate,
      });
    }
  };

  const selectProject = async (value) => {
    if (value) {
      const { data } = await axios.post(
        "materialManagement/goodsInspection/getNotInspectedGoodsReceipt",
        { ProjectCodeName: value }
      );
      allGoodsRec = data;
      let mapped = data.map((item) => item.GoodsReceiptVoucherNo);
      setList(mapped);
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setprojectNameList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    fetchProject();
  }, [dispatch, history]);

  return (
    <div>
      <Breadcrumbs
        name="Goods Inspection Voucher Create"
        linkName1="Goods Inspection Voucher Dashboard"
        to1="/materialManagement/goodsInspection"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Goods Inspection Voucher
      </h3>
      <div className="line"></div>
      <GoodsInspectionVoucherForm
        list={list}
        onSubmit={onSubmit}
        selectGoodRec={selectGoodRec}
        materialsTable={materials}
        projectNameList={projectNameList}
        selectProject={selectProject}
        initialValues={pick(
          formDetail,
          "BatchIdentificationNo",
          "CustomerCodeName",
          "CustomerName",
          "Date",
          "DateofReceipt",
          "DeliveryChallanNo",
          "DeliveryDate",
          "GoodsReceiptType",
          "GoodsReceiptVoucherNo",
          "LRDate",
          "LRRRConsignmentNo",
          "ProjectCodeName",
          "PurchaseDate",
          "PurchaseRequestNo",
          "SalesOrderNo",
          "SalesOrderNo",
          "PurchaseOrderNo",
          "UnloadedAt",
          "VendorCodeName",
          "VendorGstNo",
          "Warehouse"
        )}
      />
    </div>
  );
};
