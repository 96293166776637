import React from "react";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { createEmployeeJoinReport } from "../../../redux/actions/HRManagementActions";
import EmployeeJoinReport from "../../../forms/ProjectHRManagement/EmployeeJoinReport/EmployeeJoinReport";

export const EmployeeJoinReportCreate = () => {
  const history = useHistory();

  const onSubmit = (formValues) => {
    createEmployeeJoinReport(formValues, history);
  };

  return (
    <div>
      <Breadcrumbs
        name="Employee Join Report Create"
        linkName1="Employee Join Report Dashboard"
        to1="/project-hr-management/employee-join-report"
      />
      <FormHeading label="Employee Join Report" />
      <EmployeeJoinReport
        onSubmit={onSubmit}
      />
    </div>
  );
};
