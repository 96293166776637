const initialState = {
  SingleMachineRepairRegister: {},
  SingleMachineRepairRequest: {},
  SingleMachinePhysicalVerification: {},
  SingleMachineRelease: {},
  SingleMachineDeHireRequest: {},
  SingleMachineReceipt: {},
  SingleMachinePurchaseRequest: {},
  SingleMachinePurchaseOrder: {},
  SingleMachinePurchaseOrderAmendment: {},
  SingleMinorMachineLink: {},
  SingleLogSheetEntry: {},
  SingleMachineHireRequest: {},
  AllMachinePurchaseRequest: [],
  AllMachinePurchaseOrder: [],
  AllMachinePurchaseOrderAmendment: [],
  AllMachineReceipt: [],
  FilteredMachineReceipt: [],
  AllMinorMachineLink: [],
  AllLogSheetEntry: [],
  AllMachineRepairRequest: [],
  AllMachineRepairRegister: [],
  AllMachinePhysicalVerification: [],
  AllMachineRelease: [],
  AllMachineHireRequest: [],
  AllMachineDeHireRequest: [],
};

const machineMangementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_MACHINE_PURCHASE_REQUEST": {
      return {
        ...state,
        AllMachinePurchaseRequest: action.payload,
      };
    }
    case "SET_ALL_MACHINE_PURCHASE_ORDER": {
      return {
        ...state,
        AllMachinePurchaseOrder: action.payload,
      };
    }
    case "SET_ALL_MACHINE_PURCHASE_ORDER_AMENDMENT": {
      return {
        ...state,
        AllMachinePurchaseOrderAmendment: action.payload,
      };
    }
    case "SET_ALL_MACHINE_RECEIPT": {
      return {
        ...state,
        AllMachineReceipt: action.payload,
      };
    }
    case "SET_FILTERED_MACHINE_RECEIPT": {
      return {
        ...state,
        FilteredMachineReceipt: action.payload,
      };
    }
    case "SET_ALL_MINOR_MACHINE_LINK": {
      return {
        ...state,
        AllMinorMachineLink: action.payload,
      };
    }
    case "SET_ALL_LOG_SHEET_ENTRY": {
      return {
        ...state,
        AllLogSheetEntry: action.payload,
      };
    }
    case "SET_ALL_MACHINE_REPAIR_REQUEST": {
      return {
        ...state,
        AllMachineRepairRequest: action.payload,
      };
    }
    case "SET_ALL_MACHINE_REPAIR_REGISTER": {
      return {
        ...state,
        AllMachineRepairRegister: action.payload,
      };
    }
    case "SET_ALL_MACHINE_PHYSICAL_VERIFICATION": {
      return {
        ...state,
        AllMachinePhysicalVerification: action.payload,
      };
    }
    case "SET_ALL_MACHINE_RELEASE": {
      return {
        ...state,
        AllMachineRelease: action.payload,
      };
    }
    case "SET_ALL_MACHINE_HIRE_REQUEST": {
      return {
        ...state,
        AllMachineHireRequest: action.payload,
      };
    }
    case "SET_ALL_MACHINE_DE_HIRE_REQUEST": {
      return {
        ...state,
        AllMachineDeHireRequest: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_RELEASE": {
      return {
        ...state,
        SingleMachineRelease: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_HIRE_REQUEST": {
      return {
        ...state,
        SingleMachineHireRequest: action.payload,
      };
    }
    case "SET_SINGLE_LOG_SHEET_ENTRY": {
      return {
        ...state,
        SingleLogSheetEntry: action.payload,
      };
    }
    case "SET_SINGLE_MINOR_MACHINE_LINK": {
      return {
        ...state,
        SingleMinorMachineLink: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_PURCHASE_ORDER_AMENDMENT": {
      return {
        ...state,
        SingleMachinePurchaseOrderAmendment: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_PURCHASE_ORDER": {
      return {
        ...state,
        SingleMachinePurchaseOrder: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_PURCHASE_REQUEST": {
      return {
        ...state,
        SingleMachinePurchaseRequest: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_PHYSICAL_VERIFICATION": {
      return {
        ...state,
        SingleMachinePhysicalVerification: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_REPAIR_REQUEST": {
      return {
        ...state,
        SingleMachineRepairRequest: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_REPAIR_REGISTER": {
      return {
        ...state,
        SingleMachineRepairRegister: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_RECEIPT": {
      return {
        ...state,
        SingleMachineReceipt: action.payload,
      };
    }
    case "SET_SINGLE_MACHINE_DE_HIRE_REQUEST": {
      return {
        ...state,
        SingleMachineDeHireRequest: action.payload,
      };
    }

    default:
      return state;
  }
};

export default machineMangementReducer;
