import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/MachineManagementSystemTable/MachinePurchaseOrder";
import "./main.css";
import { Button } from "@material-ui/core";

class MachinePurchaseOrderForm extends React.Component {
  state = {
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  };

  onSubmit = (formValues) => {
    if (!this.state.tableData) {
      this.setState({ tableError: true });
    } else {
      console.log({ ...formValues, excelData: this.state.tableData });
      this.props.onSubmit({ ...formValues, tableData: this.state.tableData });
    }
  };
  tableData = (data) => {
    if (!data) {
      this.setState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      this.setState({ tableData: data, tableErrorKind: "", tableError: false });
    }
  };
  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              name="ProjectCodeName"
              component={Control.RenderTextField}
              label="ProjectCode / Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="CustomerName"
              component={Control.RenderTextField}
              label="Customer Name"
              style={{ width: "45%", margin: "1rem" }}
            />
            <Field
              name="PurchaseRequestNo"
              component={Control.RenderTextField}
              label="Purchase Request No"
              style={{ width: "23%", margin: "1rem" }}
            />
            <Field
              name="Date"
              component={Control.RenderDateField}
              label="Date"
              style={{ width: "16%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              component={Control.RenderTextField}
              type="number"
              name="TransportWorkOrderNo"
              label="Transport Work Order​ No"
              style={{ Width: "20%", margin: "1rem" }}
            />
            <Field
              name="YourRefNoDate"
              component={Control.RenderTextField}
              label="Your Ref. No / Date"
              style={{ width: "32%", margin: "1rem" }}
            />
            <Field
              name="OurRefNoDate"
              component={Control.RenderTextField}
              label="Our Ref. No / Date"
              style={{ width: "32%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="VendorCodeName"
              component={Control.RenderTextField}
              label="Vendor Code / Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="VendorGSTNo"
              component={Control.RenderTextField}
              label="Vendor GST No"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              name="FNCGSTNo"
              component={Control.RenderTextField}
              label="FNC GST No"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="Warehouse"
              component={Control.RenderTextField}
              label="Warehouse"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              name="DeliveryAt"
              component={Control.RenderTextField}
              label="Delivery At"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="DeliveryPeriodFrom"
              component={Control.RenderDateField}
              type="date"
              label="Delivery Period From"
              style={{ width: "33%", margin: "1rem" }}
              className="dateTransparent"
            />
            <Field
              name="DeliveryPeriodTo"
              component={Control.RenderDateField}
              type="date"
              label="Delivery Period To"
              style={{ width: "33%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderSelectField}
              name="Inspection"
              label="Inspection"
            >
              <option value={""}></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No​</option>
            </Field>
          </div>
          <div className="">
            <Field
              name="PaymentTerms"
              component={Control.RenderTextField}
              label="Payment Terms"
              style={{ width: "16%", margin: "1rem" }}
            />
            <Field
              name="Percentage"
              component={Control.RenderTextField}
              type="number"
              label="Percentage(%)"
              style={{ width: "25%", margin: "1rem" }}
            />
            <Field
              name="Days"
              component={Control.RenderTextField}
              label="Days"
              type="number"
              style={{ width: "16%", margin: "1rem" }}
            />
            <Field
              name="Currency"
              component={Control.RenderTextField}
              label="Currency"
              style={{ width: "28%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="LDClause"
              component={Control.RenderTextField}
              label="LD Clause"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              name="PriceVariationClause"
              component={Control.RenderTextField}
              label="Price Variation Clause"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div className="">
            <Field
              name="MachinePurchaseOrderValue"
              component={Control.RenderTextField}
              label="Machine Purchase Order Value"
              type="number"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div className="excel ">
            <Table
              tableData={this.tableData}
              materials={this.props.materials}
            />
            <div className="middle">
              <p className=" Mui-error excelError">
                {this.state.tableError ? `${this.state.tableErrorKind}` : ""}
              </p>
            </div>
          </div>
          <div className="middle">
            <Button
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "Date",
    "CustomerName",
    "ProjectCodeName",
    "PurchaseRequestNo",
    "TransportWorkOrderNo",
    "OurRefNoDate",
    "YourRefNoDate",
    "VendorCodeName",
    "VendorGSTNo",
    "FNCGSTNo",
    "Warehouse",
    "DeliveryAt",
    "DeliveryPeriodFrom",
    "DeliveryPeriodTo",
    "Inspection",
    "PaymentTerms",
    "Percentage",
    "Days",
    "Currency",
    "LDClause",
    "PriceVariationClause",
    "MachinePurchaseOrderValue",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};
export default reduxForm({
  form: "MachinePurchaseOrderForm",
  validate,
  enableReinitialize: true,
})(MachinePurchaseOrderForm);
