import React from "react";
import Route from "../PrivateRouter";
import * as Create from "../../pages/AccountingSystem/Create/export";
import * as Dashboard from "../../pages/AccountingSystem/Dashboard/export";
import * as View from "../../pages/AccountingSystem/View/export";
import * as Edit from "../../pages/AccountingSystem/Edit/export";
import * as Reports from "../../pages/AccountingSystem/Reports/export";
import { Switch } from "react-router-dom";

const AccountingSystem = (
  <Switch>
    {/*reports */}
    <Route
      exact
      path="/accountingSystem/accountingSystemReports"
      component={Reports.ReportsList}
    />
    <Route
      exact
      path="/accountingSystem/accountingSystemReports/AdvanceStatement"
      component={Reports.AdvanceStatement}
    />
    <Route
      exact
      path="/accountingSystem/accountingSystemReports/CashAndBankBalance"
      component={Reports.CashAndBankBalance}
    />
    <Route
      exact
      path="/accountingSystem/accountingSystemReports/LiabilityStatement"
      component={Reports.LiabilityStatement}
    />
    {/*reports */}
    {/* Dash */}
    <Route
      exact
      path="/accountingSystem/systemParameter"
      component={Dashboard.SystemParameterDashboard}
    />
    <Route
      exact
      path="/accountingSystem/paymentRequest"
      component={Dashboard.PaymentRequestDashboard}
    />

    <Route
      exact
      path="/accountingSystem/disbursement/chequesRealisation"
      component={Dashboard.DisbursementChequeRealisationDashboard}
    />
    <Route
      exact
      path="/accountingSystem/disbursement/contra"
      component={Dashboard.DisbursementContraDashboard}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubSupplier"
      component={Dashboard.DisbursementSubSupplierDashboard}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubContractor"
      component={Dashboard.DisbursementSubContractorDashboard}
    />
    <Route
      exact
      path="/accountingSystem/disbursementEmployeeAndOthers"
      component={Dashboard.DisbursementEmployeeOthersDashboard}
    />
    <Route
      exact
      path="/accountingSystem/journalSubContractor/"
      component={Dashboard.JournalSubContractorDashboard}
    />
    <Route
      exact
      path="/accountingSystem/journalOther/"
      component={Dashboard.JournalOtherDashboard}
    />

    <Route
      exact
      path="/accountingSystem/scrutinySubContractor"
      component={Dashboard.ScrutinySubContractorDashboard}
    />
    <Route
      exact
      path="/accountingSystem/scrutinyEmployeeeAndOthers"
      component={Dashboard.ScrutinyEmployeeOtherDashboard}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubSupplier"
      component={Dashboard.ScrutinySubSupplierDashboard}
    />

    <Route
      exact
      path="/accountingSystem/receiptCustomerInvoice/"
      component={Dashboard.ReceiptCustomerDashboard}
    />
    <Route
      exact
      path="/accountingSystem/receiptEmployeeOther/"
      component={Dashboard.ReceiptEmployeeOtherDashboard}
    />

    <Route
      exact
      path="/accountingSystem/journalSubSupplier"
      component={Dashboard.JournalSubSupplierDashboard}
    />
    {/* Create */}
    <Route
      exact
      path="/accountingSystem/systemParameter/create"
      component={Create.SystemParameterFormCreate}
    />
    <Route
      exact
      path="/accountingSystem/paymentRequest/create"
      component={Create.PaymentRequestFormCreate}
    />
    <Route
      exact
      path="/accountingSystem/disbursement/chequesRealisation/create"
      component={Create.DisbursementChequeRealisationCreate}
    />
    <Route
      exact
      path="/accountingSystem/disbursement/contra/create"
      component={Create.DisbursementContraCreate}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubSupplier/create"
      component={Create.DisbursementSubSupplierCreate}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubContractor/create"
      component={Create.DisbursementSubContractorCreate}
    />
    <Route
      exact
      path="/accountingSystem/disbursementEmployeeAndOthers/create"
      component={Create.DisbursementEmployeeOtherCreate}
    />
    <Route
      exact
      path="/accountingSystem/journalSubContractor/create"
      component={Create.JournalSubContractorCreate}
    />
    <Route
      exact
      path="/accountingSystem/journalOther/create"
      component={Create.JournalOtherCreate}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubContractor/create"
      component={Create.ScrutinySubContractorCreate}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubSupplier/create"
      component={Create.ScrutinySubSupplierCreate}
    />
    <Route
      exact
      path="/accountingSystem/scrutinyEmployeeeAndOthers/create"
      component={Create.ScrutinyOtherCreate}
    />
    <Route
      exact
      path="/accountingSystem/receiptCustomerInvoice/create"
      component={Create.ReceiptCustomerCreate}
    />
    <Route
      exact
      path="/accountingSystem/receiptEmployeeOther/create"
      component={Create.ReceiptEmployeeOtherCreate}
    />
    <Route
      exact
      path="/accountingSystem/journalSubSupplier/create"
      component={Create.JournalSubSupplierCreate}
    />

    {/*Edit */}
    <Route
      exact
      path="/accountingSystem/disbursementSubContractor/edit/:id"
      component={Edit.DisbursementSubContractorEdit}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubContractor/edit/:id"
      component={Edit.ScrutinySubContractorEdit}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubSupplier/edit/:id"
      component={Edit.DisbursementSubSupplierEdit}
    />
    <Route
      exact
      path="/accountingSystem/paymentRequest/edit/:id"
      component={Edit.PaymentRequestEdit}
    />
    <Route
      exact
      path="/accountingSystem/systemParameter/edit/:id"
      component={Edit.SystemParameterEdit}
    />
    <Route
      exact
      path="/accountingSystem/journalSubSupplier/edit/:id"
      component={Edit.JournalSubSupplierEdit}
    />
    <Route
      exact
      path="/accountingSystem/receiptEmployeeOther/edit/:id"
      component={Edit.ReceiptEmployeeOtherEdit}
    />
    <Route
      exact
      path="/accountingSystem/receiptCustomerInvoice/edit/:id"
      component={Edit.ReceiptCustomerEdit}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubSupplier/edit/:id"
      component={Edit.ScrutinySubSupplierEdit}
    />
    <Route
      exact
      path="/accountingSystem/scrutinyEmployeeeAndOthers/edit/:id"
      component={Edit.ScrutinyEmployeeOtherEdit}
    />
    <Route
      exact
      path="/accountingSystem/journalSubContractor/edit/:id"
      component={Edit.JournalSubContractorEdit}
    />
    <Route
      exact
      path="/accountingSystem/journalOther/edit/:id"
      component={Edit.JournalOtherEdit}
    />
    <Route
      exact
      path="/accountingSystem/disbursementEmployeeAndOthers/edit/:id"
      component={Edit.DisbursementEmployeeOthersEdit}
    />
    {/*View */}
    <Route
      exact
      path="/accountingSystem/journalSubContractor/view/:id"
      component={View.JournalSubContractorView}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubContractor/view/:id"
      component={View.DisbursementSubContractorView}
    />
    <Route
      exact
      path="/accountingSystem/scrutinySubContractor/view/:id"
      component={View.ScrutinySubContractorView}
    />
    <Route
      exact
      path="/accountingSystem/disbursementSubSupplier/view/:id"
      component={View.DisbursementSubSupplierView}
    />
    <Route
      exact
      path="/accountingSystem/paymentRequest/view/:id"
      component={View.PaymentRequestView}
    />
    <Route
      exact
      path="/accountingSystem/systemParameter/view/:id"
      component={View.SystemParameterView}
    />

    <Route
      exact
      path="/accountingSystem/scrutinyEmployeeeAndOthers/view/:id"
      component={View.ScrutinyEmployeeOtherView}
    />

    <Route
      exact
      path="/accountingSystem/scrutinySubSupplier/view/:id"
      component={View.ScrutinySubSupplierView}
    />

    <Route
      exact
      path="/accountingSystem/receiptEmployeeOther/view/:id"
      component={View.ReceiptEmployeeOtherView}
    />
    <Route
      exact
      path="/accountingSystem/disbursementEmployeeAndOthers/view/:id"
      component={View.DisbursementEmployeeOthersView}
    />
    <Route
      exact
      path="/accountingSystem/journalSubSupplier/view/:id"
      component={View.JournalSubSupplierView}
    />
    <Route
      exact
      path="/accountingSystem/receiptCustomerInvoice/view/:id"
      component={View.ReceiptCustomerView}
    />
    <Route
      exact
      path="/accountingSystem/journalOther/view/:id"
      component={View.JournalOtherView}
    />
  </Switch>
);

export default AccountingSystem;
