import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
  searchWarehouseReceiptById,
  approvalMaterialManagement,
} from "../../../redux/actions/materialSystemAction";
import { WareHouseReceiptTableView } from "../../../Tables/MaterialSystem/View/WareHouseReceiptTableView";
import { FileListArea } from "../../../components/formcomponents/control";
import useStyle from "./UseStyle";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const WarehouseReceiptView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(searchWarehouseReceiptById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.materialSystemRoot.SingleWarehouseReceipt) {
      setData(store.materialSystemRoot.SingleWarehouseReceipt);
    }
  }, [store.materialSystemRoot.SingleWarehouseReceipt]);
  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalMaterialManagement(
        "warehouseReceipt",
        match.params.id,
        history,
        data
      )
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const classes = useStyle();
  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name=" Warehouse Receipt Voucher  View"
        linkName1=" Warehouse Receipt Voucher  Dashboard"
        to1="/materialManagement/warehouseReceipt"
      />
      <Paper className={classes.Container}>
        <div className="">
          <ComapnyHeader />
          <h3 className={classes.Heading}>Warehouse Receipt Voucher</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>ProjectCode / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse:</h6>
              <p>{data.Warehouse}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Receipt Voucher No:</h6>
              <p>{data.GoodsReceiptVoucherNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Inspection Voucher No​​:</h6>
              <p>{data.GoodsInspectionVoucherNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Stock Type:</h6>
              <p>{data.GoodsStockType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor Code / Name:</h6>
              <p>{data.VendorCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>CustomerCode/Name:</h6>
              <p>{data.CustomerCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse Code / Name​:</h6>
              <p>{data.WarehouseCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery Challan No​:</h6>
              <p>{data.DeliveryChallanNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery Date:</h6>
              <p>{data.DeliveryDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>LR / RR / Consignment No:</h6>
              <p>{data.LRRRConsignmentNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>LR Date​:</h6>
              <p>{data.LRDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Date Of Receipt​:</h6>
              <p>{data.DateofReceipt}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Batch / Identification No​:</h6>
              <p>{data.BatchIdentificationNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Stored At​:</h6>
              <p>{data.StoredAt}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse Receipt Voucher Value​:</h6>
              <p>{data.WarehouseReceiptVoucherValue}</p>
            </Paper>
          </div>
          <h5 className={classes.Heading}>Material Detail</h5>
          <WareHouseReceiptTableView materials={data.materials} />
        </Box>

        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Warehouse Receipt"}
      />
    </div>
  );
};
