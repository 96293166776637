import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/SalesAccountingSystem/SalesOrderAmendmentForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allSalesOrder = [];
let allCompany = [];

export const SalesOrderAmendmentCreate = () => {
  const history = useHistory();
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [orderValue, setOrderValue] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const [uom, setUom] = useState(null);
  const [soTable, setSoTable] = useState([]);
  const [retentions, setRetentions] = useState([]);
  const [payments, setPayments] = useState([]);

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    await axios.post("/salesAccounting/salesOrderAmendment/", formValues);
    history.push("/salesAccounting/salesOrderAmendment/");
  };

  async function selectSalesOrder(salesOrder) {
    let detail = allSalesOrder.find((item) =>
      item.SalesOrderNo === salesOrder ? item : ""
    );
    if (detail) {
      try {
        const { data } = await axios.get(
          `/salesAccounting/salesOrder/${detail.id}`
        );
        let salesOrder;
        let payload = {
          OrderValue: data.OrderValue,
        };
        if (data.amendment) {
          salesOrder = data.amendment;
        } else salesOrder = data;

        setSoTable(salesOrder.materials);
        setRetentions(salesOrder.retentions);
        setPayments(salesOrder.payments);

        payload = {
          ...payload,
          LastAmendmentValue: salesOrder?.OrderValue,
          LastAmendmentNo: salesOrder?.SalesOrderAmendmentNo?.slice(13),
          LastAmendmentDate: salesOrder?.created_at,
          SalesOrderNo: salesOrder?.SalesOrderNo,
          ProjectCodeName: salesOrder?.ProjectCodeName,
          CustomerName: salesOrder?.CustomerName,
          CompanyName: salesOrder?.CompanyName,
          OrderReferenceNo: salesOrder?.OrderReferenceNo,
          OrderReferenceDate: salesOrder?.OrderReferenceDate,
          OrderType: salesOrder?.OrderType,
          AdvanceBG: salesOrder?.AdvanceBG,
          PerformanceBG: salesOrder?.PerformanceBG,
          Insurance: salesOrder?.Insurance,
          CustomerGSTNo: salesOrder?.CustomerGSTNo,
          CompanyGstNo: salesOrder?.CompanyGstNo,
          OrderCategory: salesOrder?.OrderCategory,
          LDClause: salesOrder?.LDClause,
          PriceVariationClause: salesOrder?.PriceVariationClause,
          ProjectStartDate: salesOrder?.ProjectStartDate,
          ProjectEndDate: salesOrder?.ProjectEndDate,
          RevisedDuration: Math.round(
            (new Date(salesOrder?.ProjectEndDate) -
              new Date(salesOrder?.ProjectStartDate)) /
              (1000 * 60 * 60 * 24 * 30)
          ).toString(),
        };
        setOrderValue(detail.ProjectOrderValue);
        setFormDetails({
          ...formDetails,
          ...payload,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const selectCompany = async (company) => {
    if (company) {
      let detail = allCompany.find((item) => item.CompanyName === company);
      try {
        const { data } = await axios.get(
          `/commonMaster/companyDetails/${detail.id}`
        );
        const gsts = data.Gsts.map((item) => item.GSTRegNo);
        setCompanyGsts(gsts);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("salesAccounting/salesOrder/");
        let filteredData = data.filter((item) => item.Status === "accepted");
        const mapped = filteredData.map((item) => item.SalesOrderNo);
        const newData = [
          ...new Map(
            mapped.map((item) => [item["ProjectCode"], item])
          ).values(),
        ];
        newData.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        setSalesOrderList(newData);
        allSalesOrder = filteredData;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    async function getUOM() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        setUom(data);
      } catch (err) {
        console.error(err);
      }
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        const mapped = data.map((item) => item.CompanyName);
        setCompanyList(mapped);
        allCompany = data;
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
    getUOM();
    getCompanyDetails();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Sales Order Amendment Create"
        linkName1="Sales Order Amendment Dashboard"
        to1="/salesAccounting/salesOrderAmendment"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Order Amendment
      </h3>
      <Form
        onSubmit={onSubmit}
        salesOrderList={salesOrderList}
        selectSalesOrder={selectSalesOrder}
        companyList={companyList}
        selectCompany={selectCompany}
        companyGsts={companyGsts}
        orderValue={orderValue}
        uom={uom}
        soTable={soTable}
        retentions={retentions}
        payments={payments}
        edit={false}
        initialValues={pick(
          formDetails,
          "SalesOrderNo",
          "ProjectCodeName",
          "CustomerName",
          "CompanyName",
          "OrderReferenceNo",
          "OrderReferenceDate",
          "OrderType",
          "Insurance",
          "CustomerGSTNo",
          "CompanyGstNo",
          "ProjectOrderValue",
          "LDClause",
          "PriceVariationClause",
          "LastAmendmentNo",
          "LastAmendmentDate",
          "LastAmendmentValue",
          "OrderValue",
          "ProjectStartDate",
          "ProjectEndDate",
          "RevisedDuration",
          "AdvanceBG"
        )}
      />
    </div>
  );
};
