import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";


const UnitOfMeasurement = (props) => {
    const [show, setShow] = React.useState(false);

    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="UOMCode"
            label="UOM Code"
            style={{ width: "42%", margin: "1rem" }}
          />
          <Field
            component={Control.RenderTextField}
            name="UOMDescription"
            label="UOM Description"
          />
          <Field
            component={Control.RenderTextField}
            name="AllowableDecimal"
            label="Allowable Decimal"
            type="number"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="UnitOfMeasurement"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "UOMCode",
    "UOMDescription",
    "AllowableDecimal",
    "Tag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "UnitOfMeasurement",
  validate,
  enableReinitialize: true,
})(UnitOfMeasurement);
