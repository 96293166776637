import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import EmployeeDetails from "../../../forms/CommonMaster/EmployeeDetails";
import { useHistory } from "react-router-dom";
import {
  editEmployeeDetails,
  searchEmployeeDetailsById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const EmployeeDetailsEdit = (props) => {
  const [data, setData] = useState({});
  const [companyDetail, setCompanyDetail] = useState([]);

  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchEmployeeDetailsById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleEmployeeDetails) {
      setData(store.SingleEmployeeDetails);
    }
  }, [store.SingleEmployeeDetails]);

  const onSubmit = (formValues) => {
    dispatch(editEmployeeDetails(formValues, props.match.params.id, history));
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let mapedData = data.map((item) => item.CompanyName);
        setCompanyDetail(mapedData);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);
  console.log("data", data);
  return (
    <div>
      <Breadcrumbs
        name="Employee Masters Edit"
        linkName1="Employe Deatils Dashboard"
        to1="/commonMaster/employeeDetails"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Employee Master
      </h3>
      <div className="line"></div>
      <EmployeeDetails
        initialValues={{...data, benefits: data?.benefits?.benefitCode}}
        companyDetail={companyDetail}
        gsts={data.Gsts}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
