import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Scrutiny/ScrutinySubContractorForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import { useSelector } from "react-redux";

let allProject = [];

export const ScrutinySubContractorEdit = (props) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [vendorNameList, setVendorNameList] = useState([]);
  const user = useSelector((store) => store.userRoot);

  // const [FromDate, setFromDate] = useState();
  // const [ToDate, setToDate] = useState();
  const [ProjectCodeName, setProjectCodeName] = useState();
  // const [VendorCodeName, setVendorCodeName] = useState();
  // const [populatedData, setPopulatedData] = useState();
  const [previousFiles, setPreviousFiles] = useState();

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/scrutinySubContractor/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/scrutinySubContractor/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/scrutinySubContractor/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/scrutinySubContractor/");

        setFormDetails(data);
        setPreviousFiles([...data.Files]);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectProjectbyRole = async (project) => {
    let { data } = await axios.get("/salesAccounting/projectCircular");
    // console.log("data", data);
    const item = data.find((item) => item.ProjectShortDescription === project);
    setFormDetails({
      ...formDetails,
      // ...headerState,
      ProjectCodeName: item.ProjectShortDescription,
      CustomerName: item.CustomerName,
    });
    // setProjectCodeName(item.ProjectShortDescription);
    // getData(FromDate, ToDate, project, VendorCodeName);
  };
  const selectProject = (project) => {
    const item = allProject.find(
      (item) => item.ProjectShortDescription === project
    );
    let detail = {
      ProjectCodeName: item.ProjectShortDescription,
      Currency: item.Currency,
      CustomerName: item.CustomerName,
    };
    setFormDetails({ ...formDetails, ...detail });
    // setProjectCodeName(item.ProjectShortDescription);
    // getData(FromDate, ToDate, project, VendorCodeName);
  };

  // const onVendorChange = (value) => {
  //   setVendorCodeName(value);
  //   getData(FromDate, ToDate, ProjectCodeName, value);
  // };

  // const onFromDateChange = (date) => {
  //   setFromDate({ ...formDetails, ...date });
  //   getData(date, ToDate, ProjectCodeName, VendorCodeName);
  // };

  // const onToDateChange = (date) => {
  //   setToDate({ ...formDetails, ...date });
  //   getData(FromDate, date, ProjectCodeName, VendorCodeName);
  // };

  // const getData = async (fromDate, toDate, project, vendor) => {
  //   if (fromDate && toDate && project && vendor)
  //     try {
  //       const request = {
  //         FromDate: fromDate,
  //         ToDate: toDate,
  //         ProjectCodeName: project,
  //         VendorCodeName: vendor,
  //       };
  //       console.log(request);
  //       const { data } = await axios.post(
  //         "/materialManagement/warehouseReceipt/getDataForSubSuppiler",
  //         request
  //       );
  //       console.log(data);
  //       if (data.length > 0) setPopulatedData(data);
  //       else alert("No Data found");
  //     } catch (err) {
  //       console.error(err);
  //     }
  // };

  useEffect(() => {
    async function fetchProject() {
      try {
        if (user.workingOn.Role === "accountAndAdminHead") {
          const { data } = await axios.post("users/getByRoleAndEmail", {
            email: user.user.email,
            Role: user.workingOn.Role,
          });
          // console.log("user=>>", user);
          // console.log("data===>", data);
          let PNlist = data.map((item) => item.ProjectName);
          // console.log("PNlist", PNlist);
          setProjectCodeName(PNlist);
          // setList(PNlist);
        } else {
          let { data } = await axios.get("/salesAccounting/projectCircular");
          const filtered = data.filter((item) => item.Status === "accepted");
          // console.log("filtered", filtered);
          allProject = filtered;
          let PNlist = filtered.map((item) => item.ProjectShortDescription);
          setList(PNlist);
        }
      } catch (error) {
        alert(error);
      }
    }

    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNlist = data.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }
    fetchProject();
    fetchVendor();
  }, [user]);

  return (
    <div>
      <Breadcrumbs
        name="Scrutiny Sub-Contractor Edit"
        linkName1="Scrutiny Sub-Contractor Dashboard"
        to1="/accountingSystem/scrutinySubContractor"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Scrutiny Sub-Contractor
      </h3>
      <Form
        onSubmit={onSubmit}
        selectProject={selectProject}
        list={list}
        vendorNameList={vendorNameList}
        // onFromDateChange={onFromDateChange}
        // onToDateChange={onToDateChange}
        // onVendorChange={onVendorChange}
        // populatedData={populatedData}
        initialValues={pick(
          formDetails,
          "ProjectCodeName",
          "Currency",
          "CostCentre",
          "CustomerName",
          "FinancialPeriod",
          "VendorCodeName"
          // "FromDate",
          // "ToDate"
        )}
        scrutinyEdit={formDetails.paymentDetails}
        previousFiles={previousFiles}
        ProjectCodeName={ProjectCodeName}
        selectProjectbyRole={selectProjectbyRole}
        user={user}
      />
    </div>
  );
};
