const initialState = {
  AllProjectName: [],
  AllActiveProjects: [],
  ProjectDetails: {},
  AllPurchaseRequestNumber: [],
  AllCutomerName: [],
  AllVendorName: [],
  AllCurrency: [],
  AllWareHouse: [],
  AllMaterialList: [],
  Page: 0,
};

const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_PROJECT_NAME": {
      return {
        ...state,
        AllProjectName: action.payload,
      };
    }
    case "SET_ALL_ACTIVE_PROJECTS": {
      return {
        ...state,
        AllActiveProjects: action.payload,
      };
    }
    case "SET_SINGLE_PROJECT_DETAIL": {
      return {
        ...state,
        ProjectDetails: action.payload,
      };
    }
    case "SET_ALL_PURCHASE_REQUEST_NUMBER": {
      return {
        ...state,
        AllPurchaseRequestNumber: action.payload,
      };
    }
    case "SET_ALL_VENDOR_NAME": {
      return {
        ...state,
        AllVendorName: action.payload,
      };
    }
    case "SET_ALL_CUSTOMER_NAME": {
      return {
        ...state,
        AllCutomerName: action.payload,
      };
    }
    case "SET_ALL_CURRENCY": {
      return {
        ...state,
        AllCurrency: action.payload,
      };
    }
    case "SET_ALL_WAREHOUSE": {
      return {
        ...state,
        AllWareHouse: action.payload,
      };
    }
    case "SET_ALL_MATERIAL_LIST": {
      return {
        ...state,
        AllMaterialList: action.payload,
      };
    }
    case "SET_CURRENT_PAGE": {
      return {
        ...state,
        Page: action.payload,
      };
    }
    default:
      return state;
  }
};

export default GeneralReducer;
