import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UnitOfMeasurement from "../../../forms/CommonMaster/UnitOfMeasurement";
import { unitOfMeasurement } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const UnitOfMeasurementCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (formValues) => {
    dispatch(unitOfMeasurement(formValues, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="UOM Create"
        linkName1="UOM Dashboard"
        to1="/commonMaster/unitOfMeasurement"
      />
      <h3 style={{ textAlign: "center" }}>Unit Of Measurement</h3>
      <UnitOfMeasurement onSubmit={onSubmit} />
    </div>
  );
};
