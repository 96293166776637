import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  editShift,
  getShiftById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import Shift from "../../../forms/CommonMaster/Shift/Shift";
import pick from "lodash/pick";

export const ShiftEdit = (props) => {
  const [data, setData] = useState();

  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (!match.params.id) return;
    const fetch = async () => {
      try {
        const data = await getShiftById(match.params.id);
        setData(data);
      } catch (error) {
        //
      }
    };
    fetch();
  }, [match.params.id]);

  const onSubmit = (formValues, history) => {
    editShift(formValues,
      props.match.params.id,
      history
    );
  };

  return (
    <div>
      <Breadcrumbs
        name="Shift Edit"
        linkName1="/commonMaster/shifts"
        to1="Shift Dashboard"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Shit Edit
      </h3>
      <div className="line"></div>
      <Shift
        initialValues={pick(
          data,
          "shift_code",
          "tag",
          "shift_in_Time",
          "shift_out_Time"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
