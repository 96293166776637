import axios from "../../components/apis/axios";

//APPROVAL

export const approvalCommonMaster =
  (link, id, history, data) => async (dispatch) => {
    try {
      const status = { status: data };
      await axios.put(`/commonMaster/${link}/approval/${id}`, status);
      history.push(`/commonMaster/${link}`);
    } catch (error) {
      alert(error);
    }
    console.log(data);
  };
//EmployeeDetails
export const employeeDetails = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/employeeDetails", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/employeeDetails");
    } catch (error) {
      alert(error);
    }
  };
};

export const editEmployeeDetails = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/employeeDetail/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/employeeDetails");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allEmployeeDetails = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/employeeDetail");
      dispatch({
        type: "SET_ALL_EMPLOYEE_DETAILS",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchEmployeeDetailsById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/employeeDetail/${Id}`);
      dispatch({
        type: "SET_SINGLE_EMPLOYEE_DETAILS",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//EmployeeDetails
//role
export const roleMaster = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/employeeRole", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/employeeRoles");
    } catch (error) {
      alert(error);
    }
  };
};

export const editRoleMaster = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/employeeRole/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/employeeRoles");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchRoleMasterById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/employeeRole/${Id}`);
      dispatch({
        type: "SET_SINGLE_ROLE_MASTER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//role
//Company Details start
export const companyDetails = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/companyDetails", formValues);
      //alert("Submit Successfull");
      history.push("/commonMaster/companyDetails");
    } catch (error) {
      alert(error);
    }
  };
};

export const editCompanyDetails = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/companyDetails/${Id}`, formValues);
      //alert("Updated Successfully");
      history.push("/commonMaster/companyDetails");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allCompanyDetails = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/companyDetails");
      dispatch({
        type: "SET_ALL_COMPANY_DETAILS",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchCompanyDetailsById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/companyDetails/${Id}`);
      dispatch({
        type: "SET_SINGLE_COMPANY_DETAILS",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//Company Details end
//machine class start
export const machineClass = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/machineClass", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/machineClass");
    } catch (error) {
      alert(error);
    }
  };
};

export const editMachineClass = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/machineClass/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/machineClass");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachineClass = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/machineClass");
      dispatch({
        type: "SET_ALL_MACHINE_CLASS",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMachineClassById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/machineClass/${Id}`);
      dispatch({
        type: "SET_SINGLE_MACHINE_CLASS",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//machine class end
//machine group start
export const machineGroup = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/machineGroup", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/machineGroup");
    } catch (error) {
      alert(error);
    }
  };
};

export const editMachineGroup = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/machineGroup/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/machineGroup");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachineGroup = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/machineGroup");
      dispatch({
        type: "SET_ALL_MACHINE_GROUP",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMachineGroupById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/machineGroup/${Id}`);
      dispatch({
        type: "SET_SINGLE_MACHINE_GROUP",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//machine group end
//machine start
export const machine = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/Machine", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/Machine");
    } catch (error) {
      alert(error);
    }
  };
};

export const editMachine = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/Machine/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/Machine");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachine = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/Machine");
      dispatch({
        type: "SET_ALL_MACHINE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMachineById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/Machine/${Id}`);
      dispatch({
        type: "SET_SINGLE_MACHINE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//machine end
//activity start
export const activity = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/Activity", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/Activity");
    } catch (error) {
      alert(error);
    }
  };
};

export const editActivity = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/Activity/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/Activity");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allActivity = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/Activity");
      dispatch({
        type: "SET_ALL_ACTIVITY",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchActivityById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/Activity/${Id}`);
      dispatch({
        type: "SET_SINGLE_ACTIVITY",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
//activity end
//account start
export const account = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/account", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/account");
    } catch (error) {
      alert(error);
    }
  };
};

export const editAccount = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/account/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/account");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allAccount = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/account");
      dispatch({
        type: "SET_ALL_ACCOUNT",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchAccountById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/account/${Id}`);
      dispatch({
        type: "SET_SINGLE_ACCOUNT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//account end
//majorSCItemGroup
export const majorSCItemGroup = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/MajorSCItemGroup", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/majorSCItemGroup");
    } catch (error) {
      alert(error);
    }
  };
};

export const editMajorSCItemGroup = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/MajorSCItemGroup/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/majorSCItemGroup");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMajorSCItemGroup = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/MajorSCItemGroup");
      dispatch({
        type: "SET_ALL_MAJOR_SC_ITEM_GROUP",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMajorSCItemGroupById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/MajorSCItemGroup/${Id}`);
      dispatch({
        type: "SET_SINGLE_MAJOR_SC_ITEM_GROUP",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
//majorSCItemGroup
//scItemCodeRequest
export const scItemCodeRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/scItemCodeRequest", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/scItemCodeRequest");
    } catch (error) {
      alert(error);
    }
  };
};

export const editSCItemCodeRequest = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/scItemCodeRequest/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/scItemCodeRequest");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allSCItemCodeRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/scItemCodeRequest");
      dispatch({
        type: "SET_ALL_SC_ITEM_CODE_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchSCItemCodeRequestById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/scItemCodeRequest/${Id}`);
      dispatch({
        type: "SET_SINGLE_SC_ITEM_CODE_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
//scItemCodeRequest
export const goodsReceiptType = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/goodsReceiptType", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/goodsReceiptType");
    } catch (error) {
      alert(error);
    }
  };
};

export const editGoodsReceiptType = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/goodsReceiptType/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/goodsReceiptType");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allGoodsReceiptType = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/goodsReceiptType");
      dispatch({
        type: "SET_ALL_GOODS_RECEIPT_TYPE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchGoodsReceiptTypeById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/goodsReceiptType/${Id}`);
      dispatch({
        type: "SET_SINGLE_GOODS_RECEIPT_TYPE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const vendorGSTLink = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/vendorGSTLink", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/vendorGSTLink");
    } catch (error) {
      alert(error);
    }
  };
};

export const editVendorGSTLink = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/vendorGSTLink/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/vendorGSTLink");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allVendorGSTLink = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/vendorGSTLink");
      dispatch({
        type: "SET_ALL_VENDOR_GST_LINK",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchVendorGSTLinkById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/vendorGSTLink/${Id}`);
      dispatch({
        type: "SET_SINGLE_VENDOR_GST_LINK",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const unitOfMeasurement = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/unitOfMeasurement", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/unitOfMeasurement");
    } catch (error) {
      alert(error);
    }
  };
};

export const editUnitOfMeasurement = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/unitOfMeasurement/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/unitOfMeasurement");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allUnitOfMeasurement = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/unitOfMeasurement");
      dispatch({
        type: "SET_ALL_UNIT_OF_MEASUREMENT",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchUnitOfMeasurementById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/unitOfMeasurement/${Id}`);
      dispatch({
        type: "SET_SINGLE_UNIT_OF_MEASUREMENT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const recoveryType = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/recoveryType", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/recoveryType");
    } catch (error) {
      alert(error);
    }
  };
};

export const editRecoveryType = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/recoveryType/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/recoveryType");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allRecoveryType = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/recoveryType");
      dispatch({
        type: "SET_ALL_RECOVERY_TYPE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchRecoveryTypeById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/recoveryType/${Id}`);
      dispatch({
        type: "SET_SINGLE_RECOVERY_TYPE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const invoiceType = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/invoiceType", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/invoiceType");
    } catch (error) {
      alert(error);
    }
  };
};

export const editInvoiceType = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/invoiceType/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/invoiceType");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allInvoiceType = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/invoiceType");
      dispatch({
        type: "SET_ALL_INVOICE_TYPE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchInvoiceTypeById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/invoiceType/${Id}`);
      dispatch({
        type: "SET_SINGLE_INVOICE_TYPE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const currency = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/currency", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/currency");
    } catch (error) {
      alert(error);
    }
  };
};

export const editCurrency = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/currency/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/currency");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allCurrency = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/currency");
      dispatch({
        type: "SET_ALL_CURRENCY",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchCurrencyById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/currency/${Id}`);
      dispatch({
        type: "SET_SINGLE_CURRENCY",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const wareHouse = (formValues, history, enqueueSnackbar) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/wareHouse", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/wareHouse");
    } catch (error) {
      console.log(error, "lllllllllllllllllllllllllllllllllllllllllllll");
      alert("data already existvs");
    }
  };
};

export const editWareHouse = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/wareHouse/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/wareHouse");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allWareHouse = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/wareHouse");
      dispatch({
        type: "SET_ALL_WAREHOUSE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchWareHouseById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/wareHouse/${Id}`);
      dispatch({
        type: "SET_SINGLE_WAREHOUSE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const businessUnit = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/businessUnit", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/businessUnit");
    } catch (error) {
      alert(error);
    }
  };
};

export const editBusinessUnit = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/businessUnit/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/businessUnit");
    } catch (err) {
      alert(err, "llllllllllllll");
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allBusinessUnit = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/businessUnit");
      dispatch({
        type: "SET_ALL_BUSINESSUNIT",
        payload: data,
      });
    } catch (err) {
      alert(err, "kkkkkkkkkkkkkkkkkkkk");
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchBusinessUnitById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/businessUnit/${Id}`);
      dispatch({
        type: "SET_SINGLE_BUSINESSUNIT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const vendor = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/vendor", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/vendor");
    } catch (error) {
      alert(error);
    }
  };
};

export const materialGroup = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/materialGroup", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/materialGroup");
    } catch (error) {
      alert(error);
    }
  };
};

export const material = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/material", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/material");
    } catch (error) {
      alert(error);
    }
  };
};

export const customer = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/customer", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/customer");
    } catch (error) {
      alert(error);
    }
  };
};

export const costCentre = (formValues, history, enqueueSnackbar) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/costCentre", formValues);
      alert("Submit Successfull");
      history.push("/commonMaster/costCentre");
    } catch (error) {
      alert(error);
    }
  };
};

export const bank = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/bank", formValues);
      //alert("Submit Successfull");
      history.push("/commonMaster/bank");
    } catch (error) {
      alert(error);
    }
  };
};

export const editBank = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/bank/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/bank");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allBank = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/bank");
      dispatch({
        type: "SET_ALL_BANK",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchBankById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/bank/${Id}`);
      dispatch({
        type: "SET_SINGLE_BANK",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editVendor = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/vendor/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/vendor");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allVendor = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/vendor");
      dispatch({
        type: "SET_ALL_VENDOR",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchVendorById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/vendor/${Id}`);
      dispatch({
        type: "SET_SINGLE_VENDOR",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editMaterialGroup = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/materialGroup/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/materialGroup");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMaterialGroup = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/materialGroup");
      dispatch({
        type: "SET_ALL_MATERIAL_GROUP",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMaterialGroupById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/materialGroup/${Id}`);
      dispatch({
        type: "SET_SINGLE_MATERIAL_GROUP",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editMaterial = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/material/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/material");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMaterial = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/material");
      dispatch({
        type: "SET_ALL_MATERIAL",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMaterialById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/material/${Id}`);
      dispatch({
        type: "SET_SINGLE_MATERIAL",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editCustomer = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/customer/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/customer");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allCustomer = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/customer");
      dispatch({
        type: "SET_ALL_CUSTOMER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchCustomerById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/customer/${Id}`);
      dispatch({
        type: "SET_SINGLE_CUSTOMER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editCostCentre = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/commonMaster/costCentre/${Id}`, formValues);
      //alert("Updated Successfully");
      history.push("/commonMaster/costCentre");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allCostCentre = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/commonMaster/costCentre");
      dispatch({
        type: "SET_ALL_COST_CENTRE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchCostCentreById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/commonMaster/costCentre/${Id}`);
      dispatch({
        type: "SET_SINGLE_COST_CENTRE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const createShift = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/shifts", formValues);
      //alert("Submit Successfull");
      history.push("/commonMaster/shifts");
    } catch (error) {
      alert(error);
    }
  };
};

export const createBenefit = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/commonMaster/benefits", formValues);
      //alert("Submit Successfull");
      history.push("/commonMaster/benefits");
    } catch (error) {
      alert(error);
    }
  };
};

export const getShiftById = async (Id) => {
    try {
      const { data } = await axios.get(`/commonMaster/shifts/${Id}`);
      return data;
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
};

export const getBenefitById = async (Id) => {
    try {
      const { data } = await axios.get(`/commonMaster/benefits/${Id}`);
      return data;
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
};

export const editShift = async (formValues, Id, history) => {
    try {
      await axios.put(`/commonMaster/shifts/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/commonMaster/shifts");
    } catch (err) {
      alert(err);
      console.log("Error in shifts", err);
    }
};

export const editBenefit = async (formValues, Id, history) => {
  try {
    await axios.put(`/commonMaster/benefits/${Id}`, formValues);
    history.push("/commonMaster/benefits");
  } catch (err) {
    alert(err);
    console.log("Error in benefits", err);
  }
};

export const getworkmenById = async (Id) => {
  try {
    const { data } = await axios.get(`/commonMaster/workmen/${Id}`);
    return data;
  } catch (err) {
    console.log("Error in Workmen", err);
  }
};

export const editWorkmen = async (formValues, Id, history) => {
  try {
    await axios.put(`/commonMaster/workmen/${Id}`, formValues);
    alert("Updated Successfully");
    history.push("/commonMaster/workmen");
  } catch (err) {
    alert(err);
    console.log("Error in workmen", err);
  }
};