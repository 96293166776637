import React from "react";
import { useSnackbar } from "notistack";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export const AutoCompleteInput = ({
  input,
  label,
  list,
  meta: { touched, invalid, error },
  disabled,
  ...custom
}) => {
  const { onChange, ...rest } = input;

  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }

  const getLabel = (option) => {
    if(typeof option === 'object') {
      return option?.label;
    }
    return option
  }

  return (
    <div {...custom}>
      <Autocomplete
        value={input || ""}
        options={list || []}
        getOptionLabel={getLabel}
        disabled={disabled}
        onChange={(event, newValue) => onChange(newValue)}
        getOptionSelected={(option, value) => {
          return option === value || [0];
        }}
        {...rest}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...rest}
              error={touched && invalid}
              helperText={touched && error}
              label={label}
              value={input}
            />
          );
        }}
      />
    </div>
  );
};
