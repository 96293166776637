import axios from "../../components/apis/axios";

//APPROVAL

export const approvalSalesAccounting =
  (link, id, history, data) => async (dispatch) => {
    try {
      await axios.put(`/salesAccounting/${link}/approval/${id}`, data);
      history.push(`/salesAccounting/${link}`);
    } catch (error) {
      alert(error);
    }
  };

export const projectCircular = (formvalues, history) => {
  return async (dispatch) => {
    console.log(formvalues);
    try {
      const { data } = await axios.post(
        "/salesAccounting/projectCircular",
        formvalues
      );

      alert(`Submitted Successfully, Project Code: ${data.ProjectCode}`);
      history.push("/salesAccounting/projectCircular");
    } catch (err) {
      console.log("Error in project Circular Action", err.message);
    }
  };
};
export const editprojectCircular = (formvalues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/salesAccounting/projectCircular/${Id}`, formvalues);

      alert("Updated Successfully");
      history.push(`/salesAccounting/projectCircular/view/${Id}`);
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allprojectCircularionvoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/salesAccounting/projectCircular");
      console.log(data);
      dispatch({
        type: "SET_ALL_PROJECT_CIRCULAR",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchprojectCircularionById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/salesAccounting/projectCircular/${id}`
      );
      dispatch({
        type: "SET_SINGLE_PROJECT_CIRCULAR",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
