import React from "react";
import { TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import "../helpers/form.style.css";

export const RenderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  max,
  min,
  ...custom
}) => {
  return (
    <TextField
      color="primary"
      className="text-field"
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
};
