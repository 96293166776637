import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SalesOrderStageTable from "../../Tables/SalesAcountingSystem/SalesOrder/SalesOrderStageTable";
import PaymentTermTable from "../../Tables/PaymentTerms/PaymentTermsTable";
import SalesOrderTable from "../../Tables/SalesAcountingSystem/SalesOrder/SalesOrderTable";
import CustomTabLayout from "../../components/formcomponents/CustomTabLayout";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";

const WorkmenOrderAmendment = (props) => {
  const [show, setShow] = useState(false);

  const [tableDataState, setTableDataState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
  });
  const [paymentTermsData, setPaymentTermsData] = useState({
    paymentData: null,
    paymentTermsErrorKind: "",
    paymentTermsError: false,
  });
  const [retentionData, setRetentionData] = useState({
    retentionData: null,
    retentionTermsErrorKind: "",
    retentionTermsError: false,
  });
  const [stageData, setStageData] = useState({
    stageData: null,
    stageTermsErrorKind: "",
    stageTermsError: false,
  });

   const [ldclauseData, setLdclauseData] = useState({
    ldclauseData: null,
    ldclauseTermsErrorKind: "",
    ldclauseTermsError: false,
  });
  const toggleModal = () => setShow(!show);
  const [stage, setStage] = useState(false);
   const [ldclause, setLdclause] = useState(true);
  const [tabDisabledArr, setTabDisabledArr] = useState([false, true]);

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const { initialValues } = props;

  useEffect(() => {
    if (initialValues.Stage && initialValues.Stage === "Yes") setStage(true);
  }, [initialValues.Stage]);
  useEffect(() => {
    if (initialValues.Ldclause && initialValues.Ldclause === "No") setLdclause(false);
  }, [initialValues.Ldclause]);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    let total = 0;
    let boqSet = new Set();
    tableDataState?.tableData?.forEach((item) => {
      boqSet.add(item.BOQNo);
      total += +item.NetAmount;
    });
    total = total.toFixed(2);
    let error = true;
    let errorMsg = "";
    if (
      props.initialValues.OrderType === "Rate Contract" ||
      +total === +props.orderValue
    )
      error = false;
    else {
      error = true;
      errorMsg = "Please match project order value";
    }
    
    // if (boqSet.size === tableDataState?.tableData?.length) error = false;
    // else {
    //   error = true;
    //   errorMsg = "BOQNo needs to be unique";
    // }
    if (!error){
      console.log({
        ...formValues,
        tableData: tableDataState.tableData,
        stageData: stageData.stageData,
        retentionData: retentionData.retentionData,
        paymentData: paymentTermsData.paymentData,
        ldclauseData: ldclauseData.ldclauseData,
      },'tabledata');
      props.onSubmit({
        ...formValues,
        tableData: tableDataState.tableData,
        stageData: stageData.stageData,
        retentionData: retentionData.retentionData,
        paymentData: paymentTermsData.paymentData,
        ldclauseData: ldclauseData.ldclauseData,
      });}
    else {
      console.log('ghj')
      alert(errorMsg);
    }
  };

  const tableData = (data) => {
    if (data.error) {
      setTableDataState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setTableDataState({
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const paymentTermsTable = (data) => {
    if (!data) {
      setPaymentTermsData({
        paymentTermsErrorKind: "table row are not fully field",
        paymentTermsError: true,
      });
    } else {
      setPaymentTermsData({
        paymentData: data,
        paymentTermsErrorKind: "",
        paymentTermsError: false,
      });
    }
  };

  const retentionTermsTable = (data) => {
    if (!data) {
      setRetentionData({
        retentionTermsErrorKind: "table row are not fully field",
        retentionTermsError: true,
      });
    } else {
      setRetentionData({
        retentionData: data,
        retentionTermsErrorKind: "",
        retentionTermsError: false,
      });
    }
  };

  const stageTermsTable = (data) => {
    if (!data) {
      setStageData({
        stageTermsErrorKind: "table row are not fully field",
        stageTermsError: true,
      });
    } else {
      setStageData({
        stageData: data,
        stageTermsErrorKind: "",
        stageTermsError: false,
      });
    }
  };
    const LdclauseTermsTable = (data) => {
    if (!data) {
      setLdclauseData({
       ldclauseTermsErrorKind: "table row are not fully field",
        ldclauseTermsError: true,
      });
    } else {
      setLdclauseData({
        ldclauseData: data,
       ldclauseTermsErrorKind: "",
        ldclauseTermsError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCode"
            label="Project Code / Name"
            list={props.list}
            className="text-field"
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
           <Field
            component={Control.RenderSelectField}
            name="WorkOrderType"
            label="Work Order Type"
          >
            <option value=""></option>
            <option value="Regular">Regular</option>
            <option value="Service">Service</option>
            <option value="Transport">Transport</option>
            <option value="Machine">Machine</option>
            <option value="Material-Other">Material-Other</option>
          </Field>
          <Field
            component={Control.RenderTextField}
            name="OurRefNo"
            label="Our Ref.no / Date"
          />
            <Field
            component={Control.RenderTextField}
            name="YourRefNo"
            label="Your Ref.no / Date"
          />
           <Field
            name="VendorCode"
            component={Control.AutoCompleteInput}
            list={props.VNList}
            onChange={(value) =>
              props.SelectVendorName && props.SelectVendorName(value)
            }
            className="text-field"
            label="Vendor Code / Name"
          />
          <Field
            name="VendorGSTNo"
            component={Control.AutoCompleteInput}
            list={props.VendorGsts}
            label="Vendor GST No"
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="FNCGSTNo"
            label="FNC GST No"
            disabled
          />
            <Field
            component={Control.RenderDateField}
            name="WorkPeriodFrom"
            label="Work period from"
          />
            <Field
            component={Control.RenderDateField}
            name="WorkPeriodTo"
            label="Work Period to"
          />
          <Field
            component={Control.RenderSelectField}
            name="MaterialIssue"
            label="Material Issue"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="MachineIssue"
            label="Machine Issue"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Field>
          <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Payments Terms (Mandatory)
            </h4>
            <PaymentTermTable
              paymentTermsTable={paymentTermsTable}
              payments={props.payments}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {paymentTermsData.paymentTermsError &&
                `${paymentTermsData.paymentTermsErrorKind}`}
            </p>
          </div>
          </div>
        
     
        <div className="field-container">
          <Field
            component={Control.RenderSelectField}
            name="OrderCategory"
            label="Order Category"
          >
            <option value={""}></option>
            <option value={"Labour"}>Labour</option>
            <option value={"Material"}>Material</option>
            <option value={"Combined"}>Combined</option>
          </Field>
        
          <Field
            component={Control.RenderTextField}
            name="Ldclause"
            label="LD Clause"
            
          >
            
          </Field>
              
          <Field
            component={Control.RenderTextField}
            name="PriceVariationClause"
            label="Price Variation Clause"
          />
          <Field
            component={Control.RenderSelectField}
            name="WorkOrderNature"
            label="Work Order Nature"
          >
            <option value={""}></option>
            <option value={"Running"}>Running</option>
            <option value={"First & Final"}>First & Final</option>
            <option value={"Combined"}>Combined</option>
          </Field>
       
            
          <Field
            component={Control.RenderSelectField}
            name="Stage"
            label="Stage (Y/N)"
            onChange={() => setStage(!stage)}
          >
            <option value={""}></option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Field>
     
     {!stage ? (
      
          
              <Field
                component={Control.RenderTextField}
                name="StagePercentage"
                label="Percent %"
                disabled
              />
         
          ) : (
            <>
              <div className="excel">
                <SalesOrderStageTable
                  stageTermsTable={stageTermsTable}
                  stage={props.stage}
                />
              </div>
              <div className="middle">
                <p className=" Mui-error excelError">
                  {stageData.stageTermsError &&
                    `${stageData.stageTermsErrorKind}`}
                </p>
              </div>
            </>
          )}
      

     
          
         
          {/* <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Retention Terms (Mandatory)
            </h4>
            <RetentionTermsTable
              retentions={props.retentions}
              retentionTermsTable={retentionTermsTable}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {retentionData.retentionTermsError &&
                `${retentionData.retentionTermsErrorKind}`}
            </p>
          </div> */}
            <CustomTabLayout
            labels={["Item", "Recovery"]}
            activeTabs={tabDisabledArr}
            tables={[
              <SalesOrderTable
              tableData={tableData}
              projectValue={props.projectValue}
              order={props.order}
              materials={props.materials}
              uom={props.uom}
              edit={props.edit}
              projectOrderValue={props.projectValue}
              disabled={false}
            />,
            <SalesOrderTable
            tableData={tableData}
            projectValue={props.projectValue}
            order={props.order}
            materials={props.materials}
            uom={props.uom}
            edit={props.edit}
            projectOrderValue={props.projectValue}
          />
            ]}
          />
        </div>
   
       
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Workmen Order Amendment"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "WorkmenOrderForm",
  validate,
  enableReinitialize: true,
})(WorkmenOrderAmendment);