import React, { useEffect, useState } from "react";
import Moment from "moment";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { readAllFromQueryParams } from "../../../utils/queryParams";

export const SalesInvoiceCertificationDashboard = () => {
  const [lists, setLists] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          "/salesAccounting/salesInvoiceCertification",
          {
            params: {
              status: "accepted",
              page: 1,
              ...readAllFromQueryParams(search),
            },
          }
        );
        const newData = [
          ...new Map(
            data.data.map((item) => [item["SalesInvoiceCertificationNo"], item])
          ).values(),
        ];
        newData.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        newData.forEach((element) => {
          element.SalesInvoiceCertificationNo =
            element.SalesInvoiceCertificationNo +
            "-" +
            element.ProjectShortDescription;
          let startDate = new Date(element.created_at).toLocaleString();
          let approvedDate = new Date(element.approved_at).toLocaleString();
          let endDate = new Date(element.updated_at).toLocaleString();
          element.updated_at = Moment(endDate).format("DD/MM/YYYY, h:mm a");
          element.created_at = Moment(startDate).format("DD/MM/YYYY, h:mm a");

          element.approved_at =
            element.approved_at &&
            Moment(approvedDate).format("DD/MM/YYYY, h:mm a");
        });

        setLists(newData);
        setPaginatedResults({ ...data, data: newData });
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        } else if (err.request) {
          alert("Network Error!", err);
        } else {
          // alert("Something went wrong. Try again later.");
        }
      }
    }
    fetch();
  }, [dispatch, history, search]);

  return (
    <>
      <BreadCrumbs name="Sales Invoice Certification DashBoard" />

      <Table
        paginatedResults={paginatedResults}
        DeleteLink="/salesAccounting/salesInvoiceCertification"
        lists={lists}
        tablecol1="SalesInvoiceCertificationNo"
        heading="Sales Invoice Certification"
        label1="Sales Invoice Certification No"
        link="/salesAccounting/salesInvoiceCertification/create"
        editLink="/salesAccounting/salesInvoiceCertification/edit"
        viewLink="/salesAccounting/salesInvoiceCertification/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </>
  );
};
