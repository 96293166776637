import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/MachineManagementSystem/LogSheetEntryForm";
import { logSheetEntry } from "../../../redux/actions/MachineManagementaction";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let code = [];

export const LogSheetEntryCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [MachineList, setMachineList] = useState([]);
  const [ActivityList, setActivityList] = useState(null);
  const [ActivityDetailList, setActivityDetailsList] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [machineReceipts, setMachineReceipts] = useState([]);

  const onSubmit = (formValues) => {
    dispatch(logSheetEntry(formValues, history));
  };

  const selectProjectName = async (projectName) => {
    const item = allProjects.find(
      (item) => item.ProjectCode === projectName?.split(" ")[0]
    );

    let machines = machineReceipts
      .filter((m) => m.ProjectCodeName === projectName)
      .map((item) => item.MachineCodeName);
    setMachineList(machines);

    if (item) {
      let payload = {
        ...formDetail,
        ProjectCodeName: projectName,
        CustomerName: item.CustomerName,
      };

      setFormDetail(payload);
    }
  };

  const selectMachineReceiptName = async (MachineCode) => {
    const mr = machineReceipts.find(
      (item) => item.MachineCodeName === MachineCode
    );
    console.log(mr, machineReceipts, MachineCode);

    if (!mr) return;
    const payload = {
      ...formDetail,
      // MachineCodeName: MachineCode,
      ShiftHour: mr.ShiftHours,
      StartMeterReading: mr.LastMeterReading,
      LogSheetDate: new Date(),
    };

    setFormDetail(payload);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data: machineReceipt } = await axios.get(
          `/machineManagement/machineReceipt`
        );
        const { data } = await axios.get("/salesAccounting/projectCircular");

        const machines = machineReceipt.map(
          (item) => item.ProjectCodeName.split(" ")[0]
        );
        const filteredData = data.filter(
          (item) =>
            item.Status === "accepted" && machines.includes(item.ProjectCode)
        );
        let projects = filteredData.map(
          (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
        );

        setAllProjects(filteredData);
        setMachineReceipts(machineReceipt);
        setProjectList(projects);
      } catch (err) {}
    }

    async function fetchActivity() {
      try {
        const { data } = await axios.get("/commonMaster/Activity");
        const mapped = data.map(
          (item) => item.ActivityCode + "-" + item.ActivityDescription
        );
        setActivityList(mapped);
        setActivityDetailsList(data);
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }

    fetch();
    fetchActivity();
  }, [history, dispatch]);

  return (
    <div>
      <BreadCrumbs
        name="Log Sheet Entry Create"
        linkName1="Log Sheet Entry Dashboard"
        to1="/machineManagement/logSheetEntry"
      />
      <h3 style={{ textAlign: "center" }}>Log Sheet Entry</h3>
      <div className="line"></div>
      <Form
        onSubmit={onSubmit}
        ProjectList={projectList}
        MachineList={MachineList}
        selectProjectName={selectProjectName}
        selectMachineReceiptName={selectMachineReceiptName}
        ActivityList={ActivityList}
        ActivityDetailList={ActivityDetailList}
        edit={false}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "MachineCodeName",
          "ShiftHour",
          "StartMeterReading",
          "LogSheetDate"
        )}
      />
    </div>
  );
};
