import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";

export const RenderDatePastField = ({
  label,
  input,
  initialValue,
  meta: { invalid, error },
  ...custom
}) => {
  const { onChange } = input;

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (initialValue) {
      setSelectedDate(initialValue);
    }
  }, [initialValue]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk
        variant="inline"
        format="d MMM yyyy"
        margin="normal"
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        error={invalid}
        helperText={error}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  );
};
