import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { LinearProgress } from "@material-ui/core";

const PurchaseRequestTable = ({
  materialsEditData,
  materialList,
  storeTableData,
  edit,
  props,
}) => {
  const [allowable, setAllowable] = useState([{ decimal: "" }]);
  const [inputFields, setInputFields] = useState();
  const [listOfMaterials, setListOfMaterials] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!edit) {
      const values = [
        {
          MaterialCode: "",
          MaterialDescription: "",
          HSNSACCode: "",
          UOM: "",
          Quantity: "0",
          SuggestedRate: "0",
          MaterialTotal: "0",
        },
      ];
      setInputFields(values);
      onChange(values);
    } else if (edit && materialsEditData) {
      setInputFields([...materialsEditData]);
      onChange([...materialsEditData]);
    }
    //eslint-disable-next-line
  }, [materialsEditData, edit]);

  useEffect(() => {
    if (materialList) {
      let tempList = materialList;
      if (edit && materialsEditData) {
        let temp = [];
        for (let i = 0; i < materialsEditData.length; i++) {
          const found = materialList.find(
            (item) => item.MaterialCode === materialsEditData[i].MaterialCode
          );
          temp.push({
            decimal: found?.AllowableDecimal,
          });
          tempList = tempList.filter((item) => item !== found);
        }
        setAllowable(temp);
      }
      setListOfMaterials([...tempList]);
      setLoading(false);
    }
  }, [materialList, materialsEditData, edit]);

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        No: 0,
        MaterialCode: "",
        MaterialDescription: "",
        HSNSACCode: "",
        UOM: "",
        Quantity: "0",
        SuggestedRate: "0",
        MaterialTotal: "0",
        id: "new",
      },
    ]);
  };

  const onItemSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.MaterialCode = values.MaterialCode;
        row.UOM = values.UOM;
        row.MaterialDescription =
          values.MaterialDescription +
          " - " +
          values.MaterialAdditionalDescription;
        const tempAllowable = [...allowable];
        tempAllowable[index] = { decimal: values.AllowableDecimal };
        setAllowable(tempAllowable);
        tdata[index] = row;
        setInputFields(tdata);
        onChange(tdata);
        const list = listOfMaterials;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setListOfMaterials([...list]);
      } else {
        const item = materialList.find(
          (item) => item.MaterialCode === row.MaterialCode
        );
        setListOfMaterials([...listOfMaterials, item]);
        row.MaterialCode = "";
        row.UOM = "";
        row.MaterialDescription = "";
        row.HSNSACCode = "";
        row.Quantity = 0;
        row.SuggestedRate = 0;
        row.MaterialTotal = 0;
      }
    } catch (err) {
      console.error("err");
    }
  };

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };
  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];

    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
    }
    const decimal = allowable[index].decimal;
    if (event.target.name === "Quantity") {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const updatevalues = (index) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row.MaterialTotal = (+row.Quantity * +row.SuggestedRate).toFixed(2);
    values[index] = row;
    setInputFields(values);
    onChange(values);
  };

  return (
    <>
      {!loading && inputFields ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">Sl. No.</th>
                  <th className="head-cell">Material Code</th>
                  <th className="head-cell">Material Description</th>
                  <th className="head-cell">HSN / SAC Code</th>
                  <th className="head-cell">UOM</th>
                  <th className="head-cell">Quantity</th>
                  <th className="head-cell">Suggested Rate</th>
                  <th className="head-cell">Material Total</th>
                </tr>
              </thead>
              <tbody>
                {inputFields.map((inputField, index) => (
                  <tr key={index}>
                    <td className="table-data1">{index + 1}</td>
                    <td className="table-data2">{inputField.MaterialCode}</td>
                    <td className="table-data2">
                      {listOfMaterials && inputField && (
                        <>
                          <Autocomplete
                            id="MaterialDescription"
                            options={listOfMaterials}
                            getOptionLabel={(item) => item.MaterialDescription}
                            style={{ width: "30rem" }}
                            onChange={(event, values) =>
                              onItemSelect(event, values, index)
                            }
                            renderInput={(params) => <TextField {...params} />}
                            defaultValue={materialList.find(
                              (item) =>
                                item.MaterialCode === inputField.MaterialCode
                            )}
                          />
                        </>
                      )}
                    </td>
                    <td className="table-data2">
                      <input
                        style={{ width: "8rem" }}
                        name="HSNSACCode"
                        value={inputField.HSNSACCode}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                    </td>
                    <td className="table-data2">{inputField.UOM}</td>
                    <td className="table-data2">
                      <input
                        style={{ width: "5rem" }}
                        name="Quantity"
                        value={inputField.Quantity}
                        type="number"
                        onChange={(event) => {
                          handleChangeInput(index, event);
                          updatevalues(index);
                        }}
                      />
                    </td>
                    <td className="table-data2">
                      <input
                        style={{ width: "5rem" }}
                        name="SuggestedRate"
                        type="number"
                        min="0"
                        step={0.01}
                        value={inputField.SuggestedRate}
                        onChange={(event) => {
                          handleChangeInput(index, event);
                          fixedDecimal(index, event, "SuggestedRate");
                          updatevalues(index);
                        }}
                      />
                    </td>
                    <td className="table-data4">{inputField.MaterialTotal}</td>
                    <td>
                      {index === 0 ? (
                        <IconButton>
                          <RemoveCircleIcon
                            className="width5"
                            color="disabled"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => handleRemoveFields(e, index)}
                        >
                          <RemoveCircleIcon
                            className="width5"
                            color="secondary"
                          />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="middle">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon />
              ADD Row
            </Button>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default PurchaseRequestTable;
