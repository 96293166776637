import React, { useState, useEffect } from "react";
export const SalesOrderLdclauseView = (props) => {
  const [data, setData] = useState([]);
  const { ldclause } = props;

  useEffect(() => {
    let values = [];
    if (ldclause) {
      values = [...ldclause];
    }
    setData(values);
  }, [ldclause]);

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo</th>
            <th className="head-cell">Frequency</th>
            <th className="head-cell">MinimumPercentage</th>
            <th className="head-cell">MaximumPercentage</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td  className="table-data1">{row.SerialNo}</td>
              <td  className="table-data3">{row.Frequency}</td>
              <td  className="table-data3">{row.MinimumPercentage}</td>
              <td  className="table-data4">{row.MaximumPercentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
