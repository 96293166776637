import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import RecoveryType from "../../../forms/CommonMaster/RecoveryType";
import { useHistory } from "react-router-dom";
import {
  editRecoveryType,
  searchRecoveryTypeById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const RecoveryTypeEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [account, setAccount] = useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchRecoveryTypeById(match.params.id));
  }, [match.params.id, dispatch]);  
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(`/commonMaster/recoveryType/${match.params.id}`
        );
        setData(data);
        data.RequiredOn = new Date(
          new Date(data.RequiredOn) + " UTC"
        ).toDateString();
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [store.userRoot, dispatch, history, match.params.id]);

    useEffect(() => {
      async function fetch() {
        try {
          const { data } = await axios.get("/commonMaster/account");
          let mapedData = data.map((item) => `${item.AccountCode}-${item.AccountDes}`);
          setAccount(mapedData);
        } catch (err) {
          alert(err);
          console.log(err);
        }
      }
      fetch();
    }, []);
    const onSubmit = (formValues) => {
    dispatch(editRecoveryType(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="Recovery Type Edit"
        linkName1="Recovery Type Dashboard"
        to1="/commonMaster/recoveryType"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Recovery Type
      </h3>
      <div className="line"></div>
      <RecoveryType
        initialValues={pick(
          data,
          "RecoveryTypeCode",
          "RecoveryType",
          "AccountCode",
          "Tags"
        )}
        account={account}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};