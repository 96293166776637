import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MaterialGroup from "../../../forms/CommonMaster/MaterialGroup";
import { useHistory } from "react-router-dom";
import {
  editMaterialGroup,
  searchMaterialGroupById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MaterialGroupEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMaterialGroupById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMaterialGroup) {
      setData(store.SingleMaterialGroup);
    }
  }, [store.SingleMaterialGroup]);

  const onSubmit = (formValues) => {
    dispatch(editMaterialGroup(formValues, props.match.params.id, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Material Group Edit"
        linkName1="Material Group Dashboard"
        to1="/commonMaster/materialGroup"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Material Group Edit
      </h3>
      <div className="line"></div>
      <MaterialGroup
        initialValues={pick(
          data,
          "MaterialGroupCode",
          "MaterialMajorGroupDescription",
          "MaterialMinorGroupDescription",
          "MaterialAdditionalDescription",
          "MaterialSubGroupDescription",
          "MaterialSubSubGroupDescription",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
