import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import ProjectAddRole from "../../Tables/AccessControl/ProjectAddRole";
import "../../components/helpers/form.style.css";
import { Button } from "@material-ui/core";
import BasicDialog from "../../components/Dialog/BasicDialog";
import CustomDataGrid from "../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";
import moment from "moment";

const AccessRequest = (props) => {
  const [state, setState] = useState({
    tableData: null,
    tableErrorKind: "",
    tableError: false,
  });
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const toggleModal = () => setShow(!show);

  const onSubmit = (formValues) => {
    console.log("from values", formValues);
    if (!state.tableData) {
      setState({ tableError: true });
    } else if (state.tableData) {
      props.onSubmit({
        ...formValues,
        working_on: state.tableData,
      });
    }
  };
  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const columns = [
    {
      field: "workinOn",
      headerName: "Role",
      flex: 1,
      valueGetter: (params) => params.row?.workinOn?.Role,
    },
    {
      field: "accessPermissions",
      headerName: "Perrmissions",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Changed at",
      flex: 1,
      valueGetter: (params) =>
        moment(params.row?.workinOn?.created_at).format("DD-MM-YYYY hh:mm A"),
    },
  ];

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          {props.edit === true ? (
            <>
              <Field
                component={Control.RenderTextField}
                name="empCode"
                label="Employee Code"
                disabled
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowHistory(true)}
                >
                  History
                </Button>
              </div>
            </>
          ) : (
            <Field
              component={Control.RenderSelectField}
              name="empCode"
              label="Employee Code"
              // className="text-field"
            >
              <option value="" disabled></option>
              {props.employeeList?.map((emp) => {
                return (
                  <option value={emp.EmployeeCode} key={emp.id}>
                    {emp.EmployeeCode +
                      " - " +
                      emp.FirstName +
                      " " +
                      emp.LastName}
                  </option>
                );
              })}
            </Field>

            // <AutoCompleteTableInput
            //   list={props.employeeList}
            //   tableIndex={0}
            //   fieldName={"EmployeeCodeName"}
            //   defaultValue={props.employeeCode}
            // />
          )}
        </div>
        <ProjectAddRole
          tableData={tableData}
          RoleList={props.roleList}
          workingEdit={props.working}
          Company={props.Company}
          isEdit={props.edit}
        />

        <div className="middle">
          <p className=" Mui-error excelError">
            {state.tableError && `${state.tableErrorKind}`}
          </p>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Access Request"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
      <BasicDialog
        title="Permissions History"
        content={
          <CustomDataGrid
            columns={columns}
            rows={props.accessHistory}
            hideActions
            hideFooter
            showFilter={false}
          />
        }
        setOpen={setShowHistory}
        open={showHistory}
        dialogActions={
          <Button variant="secondary" onClick={() => setShowHistory(false)}>
            Close
          </Button>
        }
      />
    </div>
  );
};

export default reduxForm({
  form: "AccessRequest",
  enableReinitialize: true,
})(AccessRequest);
