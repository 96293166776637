import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "../UseStyle";
import axios from "../../../../components/apis/axios";
import { userLogout } from "../../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalAccountingSystem } from "../../../../redux/actions/AccountingSystemAction";
import ApprovalAndRejectModal from "../../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../../components/formcomponents/control";
import ReceiptCustomerTableView from "../../../../Tables/AccountingSystem/View/ReceiptCustomerTableView";
import ComapnyHeader from "../../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import RoleList from "../../../../components/helpers/RoleList";

export const ReceiptCustomerView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/accountingSystem/receiptCustomerInvoice/${match.params.id}`
        );
        let data = response.data;
        data.created_at = new Date(data.created_at).toLocaleString();

        data.Date = new Date(new Date(data.Date) + " UTC").toDateString();

        console.log("receiptCustomerInvoice", data);
        setData(data); // setFiles(data.Files);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalAccountingSystem(
        "receiptCustomerInvoice",
        match.params.id,
        history,
        data,
        enqueueSnackbar
      )
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name="Payment Receipt - Customer Create"
        linkName1="Payment Receipt - Customer Dashboard"
        to1="/accountingSystem/receiptCustomerInvoice"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />

          <h3 className={classes.Heading}>Receipt Customer Invoice</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>ReceiptCustomerInvoiceNo:</h6>
                  <p>{data.ReceiptCustomerInvoiceNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Date:</h6>
                  <p>{data.Date}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>AtBank:</h6>
                  <p>{data.AtBank}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>ChequeNo:</h6>
                  <p>{data.ChequeNo}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>FinancialPeriod:</h6>
                  <p>{data.FinancialPeriod}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>NEFTRTGSIMPSNo:</h6>
                  <p>{data.NEFTRTGSIMPSNo}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Remarks:</h6>
                  <p>{data.Remarks}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>TotalReceiptValue:</h6>
                  <p>{data.TotalReceiptValue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>TotalReceiptValueManual:</h6>
                  <p>{data.TotalReceiptValueManual}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Cost Centre:</h6>
                  <p>{data.CostCentre}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Currency​​:</h6>
                  <p>{data.Currency}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer Name:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
              </div>
              <div className="excel">
                <h2>Invoice Details </h2>
                <ReceiptCustomerTableView receipt={data.invoiceDetails} />
              </div>
            </Box>
            <div className={classes.row}>
              {data.Files && (
                <Paper className={classes.paper}>
                  <h6>Files:</h6>
                  <FileListArea fileList={data.Files} />
                </Paper>
              )}
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
              <ApprovalAndRejectModal
                showModal={showModal}
                toggleModal={toggleModal}
                handleModalAction={handleModalAction}
                isApprove={isApprove}
                formName={"Receipt Customer Invoice"}
              />
            </div>
            {(post === RoleList.ADMIN.code ||
              post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
