import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import * as Control from "../formcomponents/control";
import axios from "../apis/axios";
import "../helpers/form.style.css"
const AccountingSystemComponents = ({ handleHeaderChange, CostShow }) => {
  const [costCentreList, setCostCentreList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    console.log('commonMaster/costCentre');
    const getData = async () => {
      const { data } = await axios.get("/commonMaster/costCentre");
      const filteredData = data.map((item) => item.CostCenterName);
      setCostCentreList(filteredData);
      const { data: currency } = await axios.get("/commonMaster/currency");
      let newData = currency.filter((item) => item.Tags === "Active");
      const currencyList = newData.map((item) => item.CurrencyName);
      setCurrencyList(currencyList);
    };
    getData();
  }, []);

  return (
    <div className="field-container">
      {CostShow && (
        <Field
          name="CostCentre"
          label="Cost Center"
          component={Control.AutoCompleteInput}
          list={costCentreList}
          onChange={(value) => {
            handleHeaderChange && handleHeaderChange({ CostCentre: value });
          }}
          className="text-field"
        />
      )}
      <Field
        name="FinancialPeriod"
        label="Financial Period"
        component={Control.RenderTextField}
        disabled
      />
      <Field
        name="Currency"
        label="Currency"
        component={Control.AutoCompleteInput}
        list={currencyList}
        onChange={(value) => {
          handleHeaderChange && handleHeaderChange({ Currency: value });
        }}
        className="text-field"
      />
    </div>
  );
};

export default AccountingSystemComponents;
