import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/CommonMaster/GSTTable";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const CompanyDetails = (props) => {
  const [state, setState] = useState({
    tableData: null,
    removedEl: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  const [show, setShow] = useState(false);

  const tableData = (data, removedEl) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        removedEl,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    if (!state.tableData) {
      setState({ tableError: true });
    } else if (state.tableData) {
      props.onSubmit({
        ...formValues,
        tableData: state.tableData,
        removedEl: state?.removedEl,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="CompanyName"
            label="Company Name"
          />
          <Field
            component={Control.RenderTextField}
            name="RegisteredOfficeAddress"
            label="Registered Office Address"
          />
          <Field
            component={Control.RenderTextField}
            name="CertificateOfIncorporationNo"
            label="Certificate Of Incorporation No"
          />
          <Field
            component={Control.RenderDateAllField}
            name="COIDate"
            label="COI Date "
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="ServiceTaxRegistrationNo"
            label="Service Tax Registration No"
          />
          <Field
            component={Control.RenderTextField}
            name="VATRegistrationNo"
            label="VAT Registration No"
          />
        </div>
        <div style={{ display: "flex" }} className="field-container">
          <Field
            component={Control.RenderTextField}
            name="PFRegistrationNo"
            label="PF Registration No"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="PFState"
            label="PF Registration State"
            list={props.stateList}
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="PFSubRegistrationNo"
            label="PF Sub Registration No"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="PFSubState"
            label="PF Sub Registration State"
            list={props.stateList}
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="ESIRegistrationNo"
            label="ESI Registration No"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="ESIState"
            label="ESI Registration State"
            list={props.stateList}
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="ESISubRegistrationNo"
            label="ESI Sub Registration No"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="ESISubState"
            label="ESI Sub Registration State"
            list={props.stateList}
            className="text-field"
          />
        </div>
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="PermanentAccountNumber"
            label="Permanent Account Number"
          />
          <Field component={Control.RenderTextField} name="MSME" label="MSME" />
          <Field
            component={Control.RenderTextField}
            name="MSMEUdyam"
            label="MSME Udyam"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
        <div className="">
          <Table
            tableData={tableData}
            Gsts={props.Gst}
            StateList={props.stateList}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Company Details"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "CompanyName",
    "RegisteredOfficeAddress",
    // "CertificateofIncorporationNo",
    // "ServiceTaxRegistrationNo",
    // "VATRegistrationNo",
    // "PFRegistrationNo",
    // "State",
    // "PFSubRegistrationNo",
    // "ESIRegistrationNo",
    // "ESISubRegistrationNo",
    // "PermanentAccountNumber",
    // "MSME",
    // "MSMEUdyam",
    // "GSTRegistrationNo",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "CompanyDetails",
  validate,
  enableReinitialize: true,
})(CompanyDetails);
