import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const SubSupplierTableView = ({ paymentDetails, columnHeader }) => {
  return (
    <>
      {paymentDetails ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">Purchase Order No</th>
                <th className="head-cell">Purchase Order Date</th>
                <th className="head-cell">Warehouse Receipt VoucherNo</th>
                <th className="head-cell">Warehouse Receipt Voucher Date</th>
                <th className="head-cell">Supplier Invoice No</th>
                <th className="head-cell">Supplier Invoice Date</th>
                <th className="head-cell">Amount</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {paymentDetails.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{row.SerialNo}</td>
                  <td className="table-data2">{row.PurchaseOrderNo}</td>
                  <td className="table-data2">
                    {format(new Date(row.PurchaseOrderDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">
                    {row.WarehouseReceiptVoucherNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(row.WarehouseReceiptVoucherDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{row.SupplierInvoiceNo}</td>
                  <td className="table-data2">
                    {format(new Date(row.SupplierInvoiceDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data4">{row[columnHeader[0]]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default SubSupplierTableView;
