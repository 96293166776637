import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import Table from "../../../Tables/AccountingSystem/JournalSubSupplierTable";
import * as Control from "../../../components/formcomponents/control";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import "../../../components/helpers/form.style.css";

const JournalSubSupplierForm = (props) => {
  const [state, setState] = useState({
    tableErrorKind: "",
    tableError: false,
    tableData: null,
  });
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  const onSubmit = (formValues) => {
    props.onSubmit({
      ...formValues,
      paymentDetails: state.tableData,
    });
  };

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />

        <div className="field-container">
          <Field
            name="ProjectCodeName"
            label="Project Code / Name"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="VendorCodeName"
            label="Vendor Code/Name"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.vendorNameList}
            onChange={(value) =>
              props.onVendorChange && props.onVendorChange(value)
            }
          />
          <Field
            name="FromDate"
            component={Control.RenderDateAllField}
            label="From Date"
            initialValue={props.initialValues.FromDate}
            onChange={(value) =>
              props.onFromDateChange && props.onFromDateChange(value)
            }
          />
          <Field
            name="ToDate"
            component={Control.RenderDateAllField}
            label="To Date"
            initialValue={props.initialValues.ToDate}
            onChange={(value) =>
              props.onToDateChange && props.onToDateChange(value)
            }
          />
        </div>
        <div className="excel">
          <Table
            tableData={tableData}
            populatedData={props.populatedData}
            header={props.tableHeader}
            journalEdit={props.journalEdit}
          />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {state.tableError ? `${state.tableErrorKind}` : ""}
          </p>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Journal Sub-Supplier"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
        ButtonName="Authorize"
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "ProjectCode",
    "CustomerName",
    "VendorCodeName",
    "FromDate",
    "ToDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};
export default reduxForm({
  form: "JournalSubSupplierForm",
  validate,
  enableReinitialize: true,
})(JournalSubSupplierForm);
