import React, { useState, useEffect } from "react";

export const SalesInvoiceRecoveryTableView = (props) => {
  const [data, setData] = useState([]);
  const { InvoiceRecovery } = props;

  useEffect(() => {
    let values = [];
    if (InvoiceRecovery) {
      values = [...InvoiceRecovery];
    }
    setData(values);
  }, [InvoiceRecovery]);

  return (
    <div>
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2" className="head-cell">
              RecoveryType
            </th>
            <th rowSpan="2" className="head-cell">
              RPercentage
            </th>
            <th rowSpan="2" className="head-cell">
              UptoLastBillAmt
            </th>
            <th rowSpan="2" className="head-cell">
              ThisBillAmt
            </th>
            <th rowSpan="2" className="head-cell">
              CummAmt
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{row.RecoveryType}</td>
              <td className="table-data2">{row.RPercentage}</td>
              <td className="table-data2">{row.UptoLastBillAmt}</td>
              <td className="table-data2">{row.ThisBillAmt}</td>
              <td className="table-data4">{row.CummAmt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
