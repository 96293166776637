import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { RenderTableDateField } from "../../components/formcomponents/control";
import LinearProgress from "@material-ui/core/LinearProgress";

const PaymentRequestTable = ({
  paymentDetails,
  paymentCreditDetails,
  paymentDebitDetails,
  accounts,
  payments,
  type,
  paymentCredit,
  paymentDebit,
}) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [inputFields, setInputFields] = useState([
    {
      SlNo: "",
      NatureOfExprensesOrAccounting: "",
      ActivityMachineDesc: "",
      BillDocumentNo: "",
      BillDocumentDate: "",
      BillDocumentAmount: "",
    },
  ]);

  useEffect(() => {
    if (accounts) {
      setAccount([...accounts]);
    }
    //eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    if (paymentDebit) {
      setLoading(true);
      setInputFields([...paymentDebit]);
      if (paymentDebitDetails) paymentDebitDetails(paymentDebit);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [paymentDebit]);

  useEffect(() => {
    if (payments) {
      setLoading(true);
      setInputFields([...payments]);
      if (paymentDetails) paymentDetails(payments);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [payments]);

  // console.log("paymentDebit", paymentDebit);

  useEffect(() => {
    if (paymentCredit) {
      setLoading(true);
      setInputFields([...paymentCredit]);
      if (paymentCreditDetails) paymentCreditDetails(paymentCredit);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [paymentCredit]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    try {
      paymentDetails(values);
      paymentCreditDetails(values);
      paymentDebitDetails(values);
    } catch (err) {
      console.log(err);
    }

    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SlNo: "",
        NatureOfExprensesOrAccounting: "",
        ActivityMachineDesc: "",
        BillDocumentNo: "",
        BillDocumentDate: "",
        BillDocumentAmount: "",
        id: "new",
      },
    ]);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    try {
      if (paymentDetails && values) {
        paymentDetails(values);
      } else {
        paymentDetails({
          error: true,
          errorKind: "some input field is empty",
        });
      }
    } catch (err) {
      console.log("err");
    }

    try {
      if (paymentDebitDetails && values && type === "debit") {
        paymentDebitDetails(values);
      } else {
        paymentDebitDetails({
          error: true,
          errorKind: "some input field is empty",
        });
      }
    } catch (err) {
      console.log(err);
    }

    try {
      if (paymentCreditDetails && values && type === "credit") {
        paymentCreditDetails(values);
      } else {
        paymentCreditDetails({
          error: true,
          errorKind: "some input field is empty",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onItemSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.NatureOfExprensesOrAccounting =
          values.AccountCode + " - " + values.AccountDes;
        row.ActivityMachineDesc = values.ActivityDes;
        tdata[index] = row;
        setInputFields(tdata);
        const list = account;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setAccount([...list]);
      } else {
        console.log("account", account);
        const item = accounts.find(
          (item) => item.AccountCode === row.NatureOfExprensesOrAccounting
        );
        setAccount([...account, item]);
        row.NatureOfExprensesOrAccounting = "";
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onDateChange = (index, value) => {
    const values = [...inputFields];
    values[index].BillDocumentDate = value;
    setInputFields(values);
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th rowSpan="2" className="head-cell">
                    Sl. No
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Nature of Expense / Accounting
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Description
                  </th>
                  <th colSpan="3" className="head-cell">
                    Bill / Document
                  </th>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Bill Document Date</th>
                  <th>Bill Document Amount</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {inputFields.map((inputField, index) => (
                  <tr key={index}>
                    <td className="table-data1">{index + 1}</td>
                    <td className="table-data2">
                      {account && (
                        <Autocomplete
                          id="NatureOfExprensesOrAccounting"
                          options={account}
                          getOptionLabel={(option) =>
                            option.AccountCode + " - " + option.AccountDes
                          }
                          onChange={(event, values) =>
                            onItemSelect(
                              event,
                              values,
                              index,
                              "NatureOfExprensesOrAccounting",
                              "AccountCode",
                              "AccountDes"
                            )
                          }
                          renderInput={(params) => (
                            <TextField {...params} style={{ width: "15rem" }} />
                          )}
                          defaultValue={account.find(
                            (x) =>
                              x.AccountCode + " - " + x.AccountDes ===
                              inputField.NatureOfExprensesOrAccounting
                          )}
                        />
                      )}
                    </td>
                    <td className="table-data2">
                      {inputField.ActivityMachineDesc}
                    </td>
                    <td className="table-data2">
                      <input
                        name="BillDocumentNo"
                        value={inputField.BillDocumentNo}
                        onChange={(event) => handleChangeInput(index, event)}
                        style={{ width: "3rem" }}
                      />
                    </td>
                    <td className="table-data3">
                      <RenderTableDateField
                        name="BillDocumentDate"
                        initialValue={inputField.BillDocumentDate}
                        onChange={(value) => onDateChange(index, value)}
                        style={{ width: "10rem" }}
                        disableType={""}
                      />
                    </td>
                    <td className="table-data4">
                      <input
                        type="number"
                        name="BillDocumentAmount"
                        value={inputField.BillDocumentAmount}
                        onChange={(event) => handleChangeInput(index, event)}
                        style={{ width: "9rem" }}
                      />
                    </td>
                    <td>
                      {index === 0 ? (
                        <IconButton>
                          <RemoveCircleIcon
                            className="width5"
                            color="disabled"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => handleRemoveFields(e, index)}
                        >
                          <RemoveCircleIcon
                            className="width5"
                            color="secondary"
                          />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="middle">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              ADD Row
            </Button>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default PaymentRequestTable;
