import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export const SalesInvoiceSummaryTableView = (props) => {
  const { InvoiceSummary } = props;
  console.log(InvoiceSummary);
  return (
    <>
      {InvoiceSummary ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th rowSpan="2" className="head-cell"></th>
                  <th rowSpan="2" className="head-cell">
                    Upto Last Bill
                  </th>
                  <th rowSpan="2" className="head-cell">
                    This Bill
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Cumm upto this Bill
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Total Claim Amount</th>
                  <td className="table-data1">
                    {InvoiceSummary.ClaimAmtUptoLastBill}
                  </td>
                  <td className="table-data3">
                    {InvoiceSummary.ClaimAmtThisBill}
                  </td>
                  <td className="table-data4">
                    {InvoiceSummary.ClaimAmtCummBill}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              margin: "1rem 0 1rem",
              flexDirection: "column",
            }}
          >
            <div>
              <span className="table-data1">CGST % : </span>
              <span className="table-data4">
                {InvoiceSummary.CGSTPercentage}
              </span>
            </div>
            <div>
              <span className="table-data1">SGST % : </span>
              <span className="table-data4">
                {InvoiceSummary.SGSTPercentage}
              </span>
            </div>
            <div>
              <span className="table-data1">IGST %: </span>{" "}
              <span className="table-data4">
                {InvoiceSummary.IGSTPercentage}
              </span>
            </div>
          </div>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th rowSpan="2"  className="head-cell"></th>
                  <th rowSpan="2"  className="head-cell">Upto Last Bill</th>
                  <th rowSpan="2"  className="head-cell">This Bill</th>
                  <th rowSpan="2"  className="head-cell">Cumm upto this Bill</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Net Claim Amount</th>
                  <td  className="table-data1">{InvoiceSummary.NetClaimAmtUptoLastBill}</td>
                  <td  className="table-data2">{InvoiceSummary.NetClaimAmtThisBill}</td>
                  <td  className="table-data4">{InvoiceSummary.NetClaimAmtCummBill}</td>
                </tr>
                <tr>
                  <th>Total Recovery Amount</th>
                  <td  className="table-data1">{InvoiceSummary.TotalRecoveryAmtUptoLastBill}</td>
                  <td  className="table-data2">{InvoiceSummary.TotalRecoveryAmtThisBill}</td>
                  <td  className="table-data4">{InvoiceSummary.TotalRecoveryAmtCummBill}</td>
                </tr>
                <tr>
                  <th>Gross Payable Amount</th>
                  <td  className="table-data1">{InvoiceSummary.TotalGrossAmtUptoLastBill}</td>
                  <td  className="table-data2">{InvoiceSummary.TotalGrossAmtThisBill}</td>
                  <td  className="table-data4">{InvoiceSummary.TotalGrossAmtCummBill}</td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem 0 1rem",
                flexDirection: "column",
              }}
            >
              <div>
                <span>Last Payment Upto Last Bill: </span>
                <span>{InvoiceSummary.LastPayment}</span>
              </div>
              <div>
                <span>Net Payable Amt: </span>
                <span>{InvoiceSummary.NetPayableAmt}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};
