import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import BusinessUnit from "../../../forms/CommonMaster/BusinessUnit";
import { useHistory } from "react-router-dom";
import axios from "../../../components/apis/axios";
import {
  editBusinessUnit,
  searchBusinessUnitById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const BusinessUnitEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchBusinessUnitById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleBusinessUnit) {
      setData(store.SingleBusinessUnit);
    }
  }, [store.SingleBusinessUnit]);

  const onSubmit = (formValues) => {
    dispatch(editBusinessUnit(formValues, props.match.params.id, history));
  };
  useEffect(() => {
    async function fetch() {

    const {data}= await axios.get("/commonMaster/companyDetails")
    let company = data.map((item) => item.CompanyName);
        setCompany(company);
        console.log(company);
    }
    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Business Unit Edit"
        linkName1="Buisness Unit Dashborad"
        to1="/commonMaster/businessUnit"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Business Unit
      </h3>
      <div className="line"></div>
      <BusinessUnit
        company={company}
        initialValues={pick(
          data,
          "CompanyName",
          "BusinessUnit",
          "BusinessDescription",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
