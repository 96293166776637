import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Account from "../../../forms/CommonMaster/Account";
import { account } from "../../../redux/actions/CommonMasterAction";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
let AllActivity = [];

export const AccountCreate = () => {
  const [activity, setActivity] = React.useState([]);
  const [formDetail, setFormDetail] = React.useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(account(formValues, history));
  };

  const SelectActivity = async (ActivityCode) => {
    const item = AllActivity.find((item) => item.ActivityCode === ActivityCode);
    console.log('all activity', AllActivity);
    console.log('item' , item);
    setFormDetail({
      ...formDetail,
      ActivityCode: item.ActivityCode,
      ActivityDes: item.ActivityDescription,
    });
  };
  
  React.useEffect(() => {
    async function activity() {
      try {
        const { data } = await axios.get("/commonMaster/Activity");

        let company = data.map((item) => item.ActivityCode);
        setActivity(company);
        AllActivity = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    activity();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Account Create"
        linkName1="Account Dashboard"
        to1="/commonMaster/account"
      />
      <FormHeading label="Account" />
      <Account
        initialValues={pick(formDetail, "ActivityCode", "ActivityDes")}
        onSubmit={onSubmit}
        activity={activity}
        SelectActivity={SelectActivity}
      />
    </div>
  );
};
