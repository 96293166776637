import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/MaterialSystem/GoodsReceiptVoucherTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
// import "./PurchaseOrderAmendmentForm.css";

const GoodsReceiptVoucherForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
    selection: "",
  });

  useEffect(() => {
    if (props.initialValues.GoodsReceiptType)
      setState({
        ...state,
        selection: props.initialValues.GoodsReceiptType,
      });
    // eslint-disable-next-line
  }, [props.initialValues.GoodsReceiptType]);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const onSelectGoodReceite = (value) => {
    setState({
      ...state,
      selection: value,
    });
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({
        ...state,
        tableError: true,
      });
    } else {
      console.log("submitting");
      props.onSubmit({ ...formValues, tableData: state.tableData });
    }
  };

  const storeTableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="GoodsReceiptType"
            component={Control.AutoCompleteInput}
            label="Goods Receipt Type"
            list={[
              "",
              "RECEIPT FROM VENDORS AGAINST PO​",
              "RECEIPT FROM VENDORS WITHOUT PO",
              "RECEIPT FROM SUB-CONTRACTOR",
              "RECEIPT FROM OTHER WAREHOUSE",
              "RECEIPT FROM OTHER  HEC GROUPS",
              "RECEIPT FROM CLIENTS - FREE BASIS",
              "RECEIPT FROM CLIENTS-CHARGEABLE BASIS​",
              "RECEIPT FROM CLIENTS - AGAINST LOAN​",
            ]}
            onChange={onSelectGoodReceite}
            className="text-field"
          />
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            list={props.PNList}
            onChange={(value) =>
              props.SelectProjectName &&
              props.SelectProjectName(value, state.selection)
            }
            className="text-field"
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          {state.selection !== "RECEIPT FROM VENDORS AGAINST PO​" && (
            <Field
              name="PurchaseRequestNo"
              component={Control.AutoCompleteInput}
              label="Purchase Request No"
              className="text-field"
              list={props.list}
              onChange={(value) =>
                props.selectPurchaseReq && props.selectPurchaseReq(value)
              }
            />
          )}
          {state.selection === "RECEIPT FROM VENDORS AGAINST PO​" && (
            <>
              <Field
                name="PurchaseOrderNo"
                component={Control.AutoCompleteInput}
                label="Purchase Order No​"
                className="text-field"
                list={props.POList}
                onChange={(value) =>
                  props.SelectPurchaseOrder && props.SelectPurchaseOrder(value)
                }
              />
              <Field
                name="PurchaseDate"
                component={Control.RenderDateAllField}
                label="Purchase Order Date"
                disabled
                initialValue={props.initialValues.PurchaseDate}
              />
              <Field
                name="PurchaseRequestNo"
                component={Control.RenderTextField}
                label="Purchase Request No"
                disabled
              />
            </>
          )}
          <Field
            name="PurchaseRequestDate"
            component={Control.RenderDateAllField}
            initialValue={props.initialValues.PurchaseRequestDate}
            label="Purchase Request Date"
            disabled
          />
          {state.selection === "RECEIPT FROM SUB-CONTRACTOR​" ? (
            <>
              <Field
                name="WorkOrderNo"
                component={Control.RenderTextField}
                label="Works Order No​"
              />
              <Field
                name="WorkOrderDate"
                component={Control.RenderDateField}
                initialValue={props.initialValues.WorkOrderDate}
                label="Works Order Date"
              />
            </>
          ) : state.selection === "RECEIPT FROM OTHER WAREHOUSE" ||
            state.selection === "RECEIPT FROM OTHER  HEC GROUPS​" ? (
            <>
              <Field
                name="WarehouseCodeName"
                component={Control.AutoCompleteInput}
                className="text-field"
                list={props.WHList}
                label="Warehouse Code / Name​"
              />
              <Field
                name="DeliveryChallanNo"
                component={Control.AutoCompleteInput}
                className="text-field"
                label="Delivery Challan No​"
                list={props.DClist}
                onChange={(value) =>
                  props.SelectDeliveryChallan &&
                  props.SelectDeliveryChallan(value)
                }
              />
              <Field
                name="DeliveryDate"
                component={Control.RenderDateField}
                initialValue={props.initialValues.DeliveryDate}
                label="Delivery Date"
              />
            </>
          ) : (
            (state.selection === "RECEIPT FROM CLIENTS - FREE BASIS" ||
              state.selection === "RECEIPT FROM CLIENTS - AGAINST LOAN​" ||
              state.selection === "RECEIPT FROM CLIENTS-CHARGEABLE BASIS​") && (
              <>
                <Field
                  name="SalesOrderNo"
                  component={Control.AutoCompleteInput}
                  className="text-field"
                  list={props.salesOrderList}
                  label="Sales Order No​"
                  onChange={(value) => {
                    props.selectSalesOrder && props.selectSalesOrder(value);
                  }}
                />
                <Field
                  name="SalesOrderDate"
                  component={Control.RenderDateAllField}
                  label="Sales Order Date​"
                  disabled
                />
              </>
            )
          )}
          {state.selection !== "RECEIPT FROM CLIENTS - FREE BASIS" && (
            <>
              <Field
                name="VendorCodeName"
                component={Control.AutoCompleteInput}
                className="text-field"
                label="Vendor Code / Name"
                list={props.VNList}
                onChange={(value) =>
                  props.SelectVendor && props.SelectVendor(value)
                }
              />
              <Field
                name="VendorGstNo"
                component={Control.AutoCompleteInput}
                className="text-field"
                label="Vendor GST"
                list={props.VendorGsts}
              />
            </>
          )}

          <Field
            name="LRRRConsignmentNo"
            component={Control.RenderTextField}
            label="LR / RR / Consignment No​​"
          />
          <Field
            name="LRDate"
            component={Control.RenderDatePastField}
            initialValue={props.initialValues.LRDate}
            label="LR Date​"
            className="text-field"
          />
          <Field
            name="DateofReceipt"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DateofReceipt}
            label="Date of Receipt​"
            className="text-field"
          />
          <Field
            name="BatchIdentificationNo"
            component={Control.RenderTextField}
            label="Batch / Identification No​"
          />
          <Field
            name="UnloadedAt"
            component={Control.RenderTextField}
            label="Unloaded At​"
          />
        </div>
        <div className="excel">
          <Table
            storeTableData={storeTableData}
            materials={props.materials}
            materialsQty={props.materialsQty}
          />
          <div className="middle">
            <p className="Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Goods Receipt Voucher"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "Warehouse",
    "Date",
    "DateofReceipt",
    "UnloadedAt",
    "GoodsReceiptType",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "GoodsReceiptVoucherForm",
  validate,
  enableReinitialize: true,
})(GoodsReceiptVoucherForm);
