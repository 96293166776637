/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalSalesAccounting } from "../../../redux/actions/SalesAcounts";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import styled from "styled-components";
import moment from "moment";

export const ProjectCircularView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/projectCircular/${match.params.id}`
        );
        let data = response.data;
        data.created_at = moment(data.created_at).format("DD/MM/YYYY");
        data.approved_at =
          data.approved_at && moment(data.approved_at).format("DD/MM/YYYY");

        data.Date = moment(data.Date).format("DD/MM/YYYY");
        data.ProjectStartDate = moment(data.ProjectStartDate).format(
          "DD/MM/YYYY"
        );

        data.OrderReferenceDate = moment(data.OrderReferenceDate).format(
          "DD/MM/YYYY"
        );
        data.ProjectEndDate = moment(data.ProjectEndDate).format("DD/MM/YYYY");

        setData(data); // setFiles(data.Files);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);
  var projectOrderValue = data?.ProjectOrderValue;
  var lastThree = projectOrderValue?.substring(projectOrderValue?.length - 3);
  var otherNumbers = projectOrderValue?.substring(
    0,
    projectOrderValue?.length - 3
  );
  if (otherNumbers != "") lastThree = "," + lastThree;
  var orderValue =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  const handleModalAction = (data) => {
    dispatch(
      approvalSalesAccounting("projectCircular", match.params.id, history, data)
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PrintLayout ref={componentRef}>
      <div className="hide-on-print">
        <BreadCrumbs
          name="Project Circular View"
          linkName1="Project Circular Dashboard"
          to1="/salesAccounting/projectCircular"
        />
      </div>
      <Paper className={classes.Container + " print-container"}>
        <div>
          <ComapnyHeader />

          <h3 className={classes.Heading}>Project Circular </h3>
          <div id="print-btn" className="text-right hide-on-print ">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6 className="hide-on-print">{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box
              border={1}
              borderRadius={16}
              className={classes.Box + "paper-layout"}
            >
              <div className={classes.boxInner + "print-grid print"}>
                <Grid container spacing={2}>
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Typography variant="h6">Project Details</Typography>
                  </Box>
                  <Grid item xs={6}>
                    <Box>
                      <Paper className={classes.paper}>
                        <h6>Project Code:</h6>
                        <p>
                          {data?.OriginalFormNo
                            ? data?.OriginalFormNo
                            : data?.ProjectCode}
                        </p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Project Status:</h6>
                        <p>{data.projectStatus}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Duration:</h6>
                        <p>{data.Duration}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Business Unit:</h6>
                        <p>{data.BusinessUnit}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>DirectSub:</h6>
                        <p>{data.DirectSub}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>InvoiceTo:</h6>
                        <p>{data.InvoiceTo}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Consignee Name:</h6>
                        <p>{data.ConsigneeName}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Cost Centre:</h6>
                        <p>{data.CostCentre}</p>
                      </Paper>
                      {data.CostCentreGst && (
                        <Paper className={classes.paper}>
                          <h6>Cost Centre GST:</h6>
                          <p>{data.CostCentreGst}</p>
                        </Paper>
                      )}
                      <Paper className={classes.paper}>
                        <h6>InvoiceAddress:</h6>
                        <p>{data.InvoiceAddress}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Delivery Address:</h6>
                        <p>{data.DeliveryAddress}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Project Long Name:</h6>
                        <p>{data.ProjectLongDescription}</p>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Paper className={classes.paper}>
                        <h6>Project Short Description:</h6>
                        <p>{data.ProjectShortDescription}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Currency​​:</h6>
                        <p>{data.Currency}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Customer Name:</h6>
                        <p>{data.CustomerName}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Customer GST:</h6>
                        <p>{data.CustomerGstNo}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Order Reference Date:</h6>
                        <p>{data.OrderReferenceDate}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Ware House:</h6>
                        <p>{data.WareHouse}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>WareHouseGst:</h6>
                        <p>{data.WareHouseGst}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Ware House(If Existing):</h6>
                        <p>{data.WareHouseIfExisting}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Order Reference No:</h6>
                        <p>{data.OrderReferenceNo}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Project Order Value:</h6>
                        <p>{orderValue}</p>
                      </Paper>

                      <Paper className={classes.paper}>
                        <h6>ProjectStartDate:</h6>
                        <p>{data.ProjectStartDate}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>ProjectEndDate:</h6>
                        <p>{data.ProjectEndDate}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>OrderType:</h6>
                        <p>{data.OrderType}</p>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.boxInner}>
                      <Paper className={classes.paper}>
                        <h6>Created on:</h6>
                        <p>{data.created_at ? data.created_at : "NA"}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Approved on:</h6>
                        <p>{data.approved_at ? data.approved_at : "NA"}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Created by:</h6>
                        <p>{data.created_by?.first_name || "NA"}</p>
                      </Paper>
                      <Paper className={classes.paper}>
                        <h6>Approved by:</h6>
                        <p>{data.approved_by?.first_name || "NA"}</p>
                      </Paper>

                      {data.OwnerIfSub && (
                        <Paper className={classes.paper}>
                          <h6>Owner If Sub:</h6>
                          <p>{data.OwnerIfSub}</p>
                        </Paper>
                      )}
                    </Box>
                  </Grid>
                </Grid> 
              </div>
            </Box>
            {data.Files && (
              <div className="hide-on-print">
                <Paper className={classes.paper}>
                  <h6>Files:</h6>
                  <FileListArea fileList={data.Files} />
                </Paper>
              </div>
            )}
            <div className={classes.row + " hide-on-print"}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
              {(post === RoleList.ADMIN.code ||
                post === RoleList.BU_HEAD.code) &&
                data.Status === "not_reviewed" && (
                  <div className={classes.ButtonGrp}>
                    <Button
                      variant="contained"
                      className={classes.approve}
                      onClick={() => {
                        setIsApprove(true);
                        toggleModal();
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.reject}
                      onClick={() => {
                        setIsApprove(false);
                        toggleModal();
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                )}
              <ApprovalAndRejectModal
                showModal={showModal}
                toggleModal={toggleModal}
                handleModalAction={handleModalAction}
                isApprove={isApprove}
                formName={"Project Circular"}
              />
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </PrintLayout>
  );
};

const PrintLayout = styled.div`
  @media print {


    .hide-on-print {
      display: none;
    }


    .print * {
      font-size: 12px !important;
      margin-top: 2px !important;
    }

    .print-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 2fr;
      justify-items: center;
      align-items: center
    }

    .paper-layout {
      border: none;
    }

    .print-container {
      margin: 2px !important;
      box-shadow: none;
      position: absolute;
      left:40px !important;
    }
    .print-container,
    .print-container:last-child {
    page-break-after: avoid;
  }
  }
`;
