import React, { useState, useEffect } from "react";

const DailyProgressMaterialTableView = ({ DailyProgressMaterialTableView }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (DailyProgressMaterialTableView) {
      setData([...DailyProgressMaterialTableView]);
    }
  }, [DailyProgressMaterialTableView]);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th rowSpan="2">SerialNo</th>
            <th rowSpan="2">MaterialCodeName</th>
            <th rowSpan="2">UOM</th>
            <th rowSpan="2">QuantityConsumed</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.SerialNo}</td>
              <td>{row.MaterialCodeName}</td>
              <td>{row.UOM}</td>
              <td>{row.QuantityConsumed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DailyProgressMaterialTableView;
