import React, { useEffect, useState } from "react";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { useHistory, useLocation } from "react-router-dom";
import { userLogout } from "../../../redux/actions/userAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import RoleList from "../../../components/helpers/RoleList";
import moment from "moment";
import { useCallback } from "react";
import { readAllFromQueryParams } from "../../../utils/queryParams";

export const PaymentRequestDashboard = () => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const button =
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  const fetch = useCallback(async () => {
    try {
      const { data } = await axios.get("/accountingSystem/paymentRequest", {
        params: {
          status: "Accepted",
          page: 1,
          ...readAllFromQueryParams(search),
        },
      });
      console.log(data);
      const newData = [
        ...new Map(
          data.data?.map((item) => [item["DeliveryChallanNo"], item])
        ).values(),
      ];
      console.log(newData);
      newData.forEach((element) => {
        element.created_at = moment(element.created_at).format(
          "DD/MM/YYYY, h:mm a"
        );
        element.approved_at =
          element.approved_at &&
          moment(element.approved_at).format("DD/MM/YYYY, h:mm a");
      });
      setLists(newData);
      setPaginatedResults({ ...data, data: newData });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    }
  }, [dispatch, history, search]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div>
      <Breadcrumbs name="Payment Request Dashboard" />
      <Table
        paginateResults={paginatedResults}
        total={lists.total}
        DeleteLink="/accountingSystem/paymentRequest"
        lists={lists}
        tablecol1="PaymentRequestNo"
        heading="Payment Request Dashboard"
        label1="Payment Request"
        link="/accountingSystem/paymentRequest/create"
        editLink="/accountingSystem/paymentRequest/edit"
        viewLink="/accountingSystem/paymentRequest/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </div>
  );
};
