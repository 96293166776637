import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import ReportsTable from "../../../components/AccountingSystemDisplay/ReportsComponent/ReportsTable";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchOptionProject from "../../../components/ReportsComponent/SearchOptionProject";

export const LiabilityStatement = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyle();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          "/AccountingSystem/reports/liability/"
        );
        let data = response.data;

        console.log(data);
        setLists(data);
        setIsLoading(data);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }

    fetch();
  }, [history, dispatch]);

  const handleProjectChange = async (value) => {
    const filteredProject = lists.filter(
      (item) => item.ProjectCodeName === value
    );
    setLists(filteredProject);
  };
  return (
    <>
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>Liability Statement</h3>
        <SearchOptionProject
          handleProjectChange={handleProjectChange}
          label="Filter Project"
        />
        {isLoading ? (
          <ReportsTable lists={lists} tablecol3="Credit" />
        ) : (
          <div className={classes.Container}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={200} />
          </div>
        )}
      </Paper>
    </>
  );
};
