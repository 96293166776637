import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const PaymentRequestTable = ({ paymentDetails }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let values = [];
    if (paymentDetails) {
      values = [...paymentDetails];
    }
    setData(values);
  }, [paymentDetails]);

  return (
    <>
      {paymentDetails ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th rowSpan="2" className="head-cell">
                  Sl. No
                </th>
                <th rowSpan="2" className="head-cell">
                  Nature of Expense / Accounting
                </th>
                <th rowSpan="2" className="head-cell">
                  Activity / Machine Description
                </th>
                <th colSpan="3" className="head-cell">
                  Bill / Document
                </th>
              </tr>
              <tr>
                <th>No</th>
                <th>Bill Document Date</th>
                <th>Bill Document Amount</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{row.SerialNo}</td>
                  <td className="table-data3">
                    {row.NatureOfExprensesOrAccounting}
                  </td>
                  <td className="table-data3">{row.ActivityMachineDesc}</td>
                  <td className="table-data3">{row.BillDocumentNo}</td>
                  <td className="table-data3">
                    {format(new Date(row.BillDocumentDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data4">{row.BillDocumentAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default PaymentRequestTable;
