import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Scrutiny/ScrutinySubContractorForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
// import { useSnackbar } from "notistack";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import { useSelector } from "react-redux";

let allProject = [];

export const ScrutinySubContractorCreate = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [vendorNameList, setVendorNameList] = useState([]);
  // const [FromDate, setFromDate] = useState();
  // const [ToDate, setToDate] = useState();
  const [ProjectCodeName, setProjectCodeName] = useState(null);
  // const [VendorCodeName, setVendorCodeName] = useState();
  // const [populatedData, setPopulatedData] = useState();
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });
  // const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((store) => store.userRoot);
  // console.log("user==>", user);
  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    try {
      console.log(JSON.stringify(formValues));
      const res = await axios.post(
        "accountingSystem/scrutinySubContractor/",
        formValues
      );
      console.log(res);
      history.push("/accountingSystem/scrutinySubContractor");
    } catch (error) {
      console.log("scrutinySubContractor==>", error);
    }
  };

  const selectProjectbyRole = async (project) => {
    let { data } = await axios.get("/salesAccounting/projectCircular");
    // console.log("data", data);
    const item = data.find((item) => item.ProjectShortDescription === project);
    setFormDetails({
      ...formDetails,
      ...headerState,
      ProjectCodeName: item.ProjectShortDescription,
      CustomerName: item.CustomerName,
    });
    // setProjectCodeName(item.ProjectShortDescription);
    // getData(FromDate, ToDate, project, VendorCodeName);
  };
  const selectProject = (project) => {
    const item = allProject.find(
      (item) => item.ProjectShortDescription === project
    );
    setFormDetails({
      ...formDetails,
      ...headerState,
      ProjectCodeName: item.ProjectShortDescription,
      CustomerName: item.CustomerName,
    });
    // setProjectCodeName(item.ProjectShortDescription);
    // getData(FromDate, ToDate, project, VendorCodeName);
  };

  // const onVendorChange = (value) => {
  //   // setVendorCodeName(value);
  //   // getData(FromDate, ToDate, ProjectCodeName, value);
  // };

  // const onFromDateChange = (date) => {
  //   setFromDate(date);
  //   getData(date, ToDate, ProjectCodeName, VendorCodeName);
  // };

  // const onToDateChange = (date) => {
  //   setToDate(date);
  //   getData(FromDate, date, ProjectCodeName, VendorCodeName);
  // };

  // const getData = async (fromDate, toDate, project, vendor) => {
  //   if (fromDate && toDate && project && vendor)
  //     try {
  //       const request = {
  //         FromDate: fromDate,
  //         ToDate: toDate,
  //         ProjectCodeName: project,
  //         VendorCodeName: vendor,
  //       };
  //       console.log(JSON.stringify(request));
  //       const { data } = await axios.post(
  //         "/materialManagement/shared/getDataForSubSuppiler",
  //         request
  //       );
  //       console.log(data);
  //       if (data.length > 0) setPopulatedData(data);
  //       else
  //         enqueueSnackbar("Oops No data found", {
  //           variant: "warning",
  //         });
  //     } catch (err) {
  //       console.error(err);
  //     }
  // };

  useEffect(() => {
    async function fetchProject() {
      try {
        if (user.workingOn.Role === "accountAndAdminHead") {
          const { data } = await axios.post("users/getByRoleAndEmail", {
            email: user.user.email,
            Role: user.workingOn.Role,
          });

          // console.log("data===>", data);
          let PNlist = data.map((item) => item.ProjectName);
          console.log("PNlist", PNlist);
          setProjectCodeName(PNlist);
          // setList(PNlist);
        } else {
          let { data } = await axios.get("/salesAccounting/projectCircular");
          const filtered = data.filter((item) => item.Status === "accepted");
          // console.log("filtered", filtered);
          allProject = filtered;
          let PNlist = filtered.map((item) => item.ProjectShortDescription);
          setList(PNlist);
        }
      } catch (error) {
        alert(error);
      }
    }

    async function getData() {
      try {
        setFormDetails({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }

    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNlist = data.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }
    fetchProject();
    getData();
    fetchVendor();
  }, [user]);
  // console.log("ProjectCodeName", ProjectCodeName);
  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };
  return (
    <div>
      <Breadcrumbs
        name="Scrutiny Sub-Contractor Create"
        linkName1="Scrutiny Sub-Contractor Dashboard"
        to1="/accountingSystem/scrutinySubContractor"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Scrutiny Sub-Contractor
      </h3>
      <Form
        onSubmit={onSubmit}
        selectProject={selectProject}
        list={list}
        vendorNameList={vendorNameList}
        // onFromDateChange={onFromDateChange}
        // onToDateChange={onToDateChange}
        // onVendorChange={onVendorChange}
        // populatedData={populatedData}
        handleHeaderChange={handleHeaderChange}
        initialValues={pick(
          formDetails,
          "ProjectCodeName",
          "Currency",
          "CostCentre",
          "CustomerName",
          "FinancialPeriod"
        )}
        ProjectCodeName={ProjectCodeName}
        selectProjectbyRole={selectProjectbyRole}
        user={user}
      />
    </div>
  );
};
