import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";

const WarehouseIndentVoucherFormTable = ({
  machineList,
  activityList,
  materialList,
  materials,
  tableData,
}) => {
  const [listOfMaterials, setListOfMaterials] = useState(null);
  const [inputFields, setInputFields] = useState([
    {
      No: 1,
      MaterialCode: "",
      MaterialDescription: "",
      UOM: "",
      ActivityMachineSelect: "",
      ActivityMachineDescription: "",
      IntentQty: "",
      id: "new",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    let values = [];
    if (materials) {
      for (let i = 0; i < materials.length; i++) {
        values = [
          ...values,
          {
            No: materials[i].SerialNo,
            MaterialCode: materials[i].MaterialCode,
            MaterialDescription: materials[i].MaterialDescription,
            UOM: materials[i].UOM,
            ActivityMachineSelect: materials[i].ActivityMachineSelect,
            ActivityMachineDescription: materials[i].ActivityMachineDescription,
            IntentQty: materials[i].IntentQty,
            id: materials[i].id,
          },
        ];
      }
      onChange(values);
      setInputFields(values);
    }
    //eslint-disable-next-line
  }, [materials]);

  useEffect(() => {
    if (materialList) {
      onChange(inputFields);
      setListOfMaterials([...materialList]);
    }
    //eslint-disable-next-line
  }, [materialList]);

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        No: 0,
        MaterialCode: "",
        MaterialDescription: "",
        UOM: "",
        ActivityMachineSelect: "",
        ActivityMachineDescription: "",
        IntentQty: "",
        id: "new",
      },
    ]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;

    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
    }
    setInputFields(values);
    onChange(values);
  };

  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleSelectInput = (index, event) => {
    const value = event.target.value;
    const values = [...inputFields];
    values[index][event.target.name] = value;
    setInputFields(values);
  };

  const onMaterialSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.MaterialCode = values.MaterialCode;
        row.UOM = values.UOM;
        row.MaterialDescription =
          values.MaterialDescription +
          " - " +
          values.MaterialAdditionalDescription;
        tdata[index] = row;
        setInputFields(tdata);
        const list = listOfMaterials;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setListOfMaterials([...list]);
      } else {
        const item = materialList.find(
          (item) => item.MaterialCode === row.MaterialCode
        );
        setListOfMaterials([...listOfMaterials, item]);
        row.MaterialCode = "";
        row.UOM = "";
        row.MaterialDescription = "";
      }
    } catch (err) {
      console.error("err");
    }
  };

  const onItemSelect = (event, values, index, field, findHere) => {
    let tdata = inputFields;
    try {
      if (tdata[index]) {
        console.log(tdata[index], values);
        tdata[index][field] = values[findHere];
        console.log(tdata[index]);
        if (findHere === "MachineCode") {
          tdata[index].ActivityMachineDescription =
            values.MachineCode + "-" + values.Machinedes;
        } else if (findHere === "ActivityDescription") {
          tdata[index].ActivityMachineDescription =
            values.ActivityCode + "-" + values.ActivityDescription;
        }
        console.log(tdata[index]);
        setInputFields([...tdata]);
      }
    } catch (err) {
      console.error(err);
    }
  };

    const fixedDecimal = (index, event, fieldName) => {
      const values = [...inputFields];
      const decimal = 2;

      if (event.target.name === fieldName) {
        let value = event.target.value;
        try {
          value = value.split(".");
          if (value[1].length > decimal) {
            value[1] = value[1].substring(0, decimal);
          }
          let no = `${value[0]}.${value[1]}`;
          values[index][event.target.name] = no;
        } catch (e) {
          console.error(e);
          values[index][event.target.name] = event.target.value;
        }
      } else values[index][event.target.name] = event.target.value;
      setInputFields(values);
      onChange(values);
    };
  
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">Sl. No.</th>
            <th className="head-cell">Material Code</th>
            <th className="head-cell">Material Description</th>
            <th className="head-cell">UOM</th>
            <th className="head-cell">Activity/Machine</th>
            <th className="head-cell">Activity/Machine Description</th>
            <th className="head-cell">Indent Qty</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">{inputField.MaterialCode}</td>
              <td className="table-data2">
                {listOfMaterials && (
                  <>
                    <Autocomplete
                      id="MaterialDescription"
                      options={listOfMaterials}
                      getOptionLabel={(item) =>
                        item.MaterialDescription +
                        " - " +
                        item.MaterialAdditionalDescription
                      }
                      style={{ width: "100%" }}
                      onChange={(event, values) =>
                        onMaterialSelect(event, values, index)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      defaultValue={listOfMaterials.find(
                        (item) =>
                          item.MaterialDescription +
                            " - " +
                            item.MaterialAdditionalDescription ===
                          inputField.MaterialDescription
                      )}
                    />
                  </>
                )}
              </td>
              <td className="table-data2">{inputField.UOM}</td>
              <td className="table-data2">
                <select
                  style={{ width: "8rem", height: "2rem" }}
                  name="ActivityMachineSelect"
                  onChange={(event) => handleSelectInput(index, event)}
                  value={inputField.ActivityMachineSelect}
                >
                  <option value={""}></option>
                  <option value={"activity"}>Activity</option>
                  <option value={"machine"}>Machine</option>
                </select>
              </td>
              <td className="table-data2">
                {inputField.ActivityMachineSelect === "activity"
                  ? activityList && (
                      <Autocomplete
                        id="Activity"
                        options={activityList}
                        getOptionLabel={(option) =>
                          option.ActivityCode + "-" + option.ActivityDescription
                        }
                        style={{ width: "15rem" }}
                        onChange={(event, values) =>
                          onItemSelect(
                            event,
                            values,
                            index,
                            "ActivityMachineDescription",
                            "ActivityDescription"
                          )
                        }
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={activityList.find(
                          (x) =>
                            x.ActivityCode + "-" + x.ActivityDescription ===
                            inputField.ActivityMachineDescription
                        )}
                      />
                    )
                  : machineList && (
                      <Autocomplete
                        id="Activity"
                        options={machineList}
                        getOptionLabel={(option) =>
                          option.MachineCode + "-" + option.Machinedes
                        }
                        style={{ width: "15rem" }}
                        onChange={(event, values) =>
                          onItemSelect(
                            event,
                            values,
                            index,
                            "ActivityMachineDescription",
                            "MachineCode"
                          )
                        }
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={machineList.find(
                          (x) =>
                            x.MachineCode + "-" + x.Machinedes ===
                            inputField.ActivityMachineDescription
                        )}
                      />
                    )}
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  name="IntentQty"
                  min="0"
                  step={0.01}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    fixedDecimal(index, event, "IntentQty");
                  }}
                  style={{ width: "6rem" }}
                  value={inputField.IntentQty}
                />
              </td>
              <td>
                {index === 0 ? (
                  <IconButton>
                    <RemoveCircleIcon className="width5" color="disabled" />
                  </IconButton>
                ) : (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default WarehouseIndentVoucherFormTable;
