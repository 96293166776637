import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineRepairRegister from "../../../forms/MachineManagementSystem/MachineRepairRegister";
import { useHistory } from "react-router-dom";
import {
  editMachineRepairRegister,
  searchMachineRepairRegisterById,
} from "../../../redux/actions/MachineManagementaction";

export const MachineRepairRegisterEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachineRepairRegisterById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachineRepairRegister) {
      setData(store.SingleMachineRepairRegister);
    }
  }, [store.SingleMachineRepairRegister]);

  const onSubmit = (formValues) => {
    dispatch(
      editMachineRepairRegister(formValues, props.match.params.id, history)
    );
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Repair Register Edit
      </h3>
      <div className="line"></div>
      <MachineRepairRegister
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "MachineCodeName",
          "RepairDateTime",
          "CommDateTime",
          "NatureOfDefects",
          "PartAssembly",
          "CauseOfBreakdown",
          "RepairBy",
          "RepairDetails",
          "VendorCodeName",
          "WorkOrderNo",
          "Date",
          "NextScheduleMaintenanceDate"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
