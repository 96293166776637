const initialState = {
  SingleBank: {},
  AllBank: [],
  SingleVendor: {},
  AllVendor: [],
  SingleMaterialGroup: {},
  AllMaterialGroup: [],
  SingleMaterial: {},
  AllMaterial: [],
  SingleCustomer: {},
  AllCustomer: [],
  SingleCostCentre: {},
  AllCostCentre: [],
  SingleGoodsReceiptType: {},
  AllGoodsReceiptType: [],
  SingleVendorGSTLink: {},
  AllVendorGSTLink: [],
  SingleUnitOfMeasurement: {},
  AllUnitOfMeasurement: [],
  SingleRecoveryType: {},
  AllRecoveryType: [],
  SingleInvoiceType: {},
  AllInvoiceType: [],
  SingleCurrency: {},
  AllCurrency: [],
  SingleWareHouse: {},
  AllWareHouse: [],
  SingleBusinessUnit: {},
  AllBusinessUnit: [],
  SingleCompanyDetails: {},
  AllCompanyDetails: [],
  SingleMachineClass: {},
  AllMachineClass: [],
  SingleMachineGroup: {},
  AllMachineGroup: [],
  SingleMachine: {},
  AllMachine: [],
  SingleActivity: {},
  AllActivity: [],
  SingleAccount: {},
  AllAccount: [],
  SingleMajorSCItemGroup: {},
  AllMajorSCItemGroup: [],
  SingleSCItemCodeRequest: {},
  AllSCItemCodeRequest: [],
  SingleRoleMaster: {},
  AllRoleMaster: [],
  SingleEmployeeDetails: {},
  AllEmployeeDetails: [],
};

const commonMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    //EmployeeDetails
    case "SET_SINGLE_EMPLOYEE_DETAILS": {
      return {
        ...state,
        SingleEmployeeDetails: action.payload,
      };
    }

    case "SET_ALL_EMPLOYEE_DETAILS": {
      return {
        ...state,
        AllEmployeeDetails: action.payload,
      };
    }

    //EmployeeDetails
    //role
    case "SET_SINGLE_ROLE_MASTER": {
      return {
        ...state,
        SingleRoleMaster: action.payload,
      };
    }

    case "SET_ALL_ROLE_MASTER": {
      return {
        ...state,
        AllRoleMaster: action.payload,
      };
    }
    //role
    //company Details
    case "SET_SINGLE_COMPANY_DETAILS": {
      return {
        ...state,
        SingleCompanyDetails: action.payload,
      };
    }

    case "SET_ALL_COMPANY_DETAILS": {
      return {
        ...state,
        AllCompanyDetails: action.payload,
      };
    }
    //company details
    //machine class
    case "SET_SINGLE_MACHINE_CLASS": {
      return {
        ...state,
        SingleMachineClass: action.payload,
      };
    }

    case "SET_ALL_MACHINE_CLASS": {
      return {
        ...state,
        AllMachineClass: action.payload,
      };
    }
    //machine class
    //Machine Group
    case "SET_SINGLE_MACHINE_GROUP ": {
      return {
        ...state,
        SingleMachineGroup: action.payload,
      };
    }

    case "SET_ALL_MACHINE_GROUP": {
      return {
        ...state,
        AllMachineGroup: action.payload,
      };
    }
    //Machine Group
    //Machine
    case "SET_SINGLE_MACHINE": {
      return {
        ...state,
        SingleMachine: action.payload,
      };
    }

    case "SET_ALL_MACHINE": {
      return {
        ...state,
        AllMachine: action.payload,
      };
    }
    //Machine
    //Activity
    case "SET_SINGLE_ACTIVITY": {
      return {
        ...state,
        SingleActivity: action.payload,
      };
    }

    case "SET_ALL_ACTIVITY": {
      return {
        ...state,
        AllActivity: action.payload,
      };
    }
    //Activity
    //account
    case "SET_ALL_ACCOUNT": {
      return {
        ...state,
        AllAccount: action.payload,
      };
    }
    case "SET_SINGLE_ACCOUNT": {
      return {
        ...state,
        SingleAccount: action.payload,
      };
    }
    //Account
    //Major SC Item Group
    case "SET_ALL_MAJOR_SC_ITEM_GROUP": {
      return {
        ...state,
        AllMajorSCItemGroup: action.payload,
      };
    }
    case "SET_SINGLE_MAJOR_SC_ITEM_GROUP": {
      return {
        ...state,
        SingleMajorSCItemGroup: action.payload,
      };
    }
    //Major SC Item Group
    //scItemCodeRequest
    case "SET_SINGLE_SC_ITEM_CODE_REQUEST": {
      return {
        ...state,
        SingleSCItemCodeRequest: action.payload,
      };
    }

    case "SET_ALL_SC_ITEM_CODE_REQUEST": {
      return {
        ...state,
        AllSCItemCodeRequest: action.payload,
      };
    }

    //scItemCodeRequest
    case "SET_SINGLE_BANK": {
      return {
        ...state,
        SingleBank: action.payload,
      };
    }

    case "SET_ALL_BANK": {
      return {
        ...state,
        AllBank: action.payload,
      };
    }

    case "SET_SINGLE_VENDOR": {
      return {
        ...state,
        SingleVendor: action.payload,
      };
    }
    case "SET_ALL_VENDOR": {
      return {
        ...state,
        AllVendor: action.payload,
      };
    }
    case "SET_SINGLE_MATERIAL_GROUP": {
      return {
        ...state,
        SingleMaterialGroup: action.payload,
      };
    }
    case "SET_ALL_MATERIAL_GROUP": {
      return {
        ...state,
        AllMaterialGroup: action.payload,
      };
    }

    case "SET_SINGLE_MATERIAL": {
      return {
        ...state,
        SingleMaterial: action.payload,
      };
    }

    case "SET_ALL_MATERIAL": {
      return {
        ...state,
        AllMaterial: action.payload,
      };
    }
    case "SET_SINGLE_CUSTOMER": {
      return {
        ...state,
        SingleCustomer: action.payload,
      };
    }
    case "SET_ALL_CUSTOMER": {
      return {
        ...state,
        AllCustomer: action.payload,
      };
    }
    case "SET_SINGLE_COST_CENTRE": {
      return {
        ...state,
        SingleCostCentre: action.payload,
      };
    }
    case "SET_ALL_COST_CENTRE": {
      return {
        ...state,
        AllCostCentre: action.payload,
      };
    }
    case "SET_SINGLE_GOODS_RECEIPT_TYPE": {
      return {
        ...state,
        SingleGoodsReceiptType: action.payload,
      };
    }
    case "SET_ALL_GOODS_RECEIPT_TYPE": {
      return {
        ...state,
        AllGoodsReceiptType: action.payload,
      };
    }
    case "SET_SINGLE_VENDOR_GST_LINK": {
      return {
        ...state,
        SingleVendorGSTLink: action.payload,
      };
    }
    case "SET_ALL_VENDOR_GST_LINK": {
      return {
        ...state,
        AllVendorGSTLink: action.payload,
      };
    }
    case "SET_SINGLE_UNIT_OF_MEASUREMENT": {
      return {
        ...state,
        SingleUnitOfMeasurement: action.payload,
      };
    }
    case "SET_ALL_UNIT_OF_MEASUREMENT": {
      return {
        ...state,
        AllUnitOfMeasurement: action.payload,
      };
    }
    case "SET_SINGLE_RECOVERY_TYPE": {
      return {
        ...state,
        SingleRecoveryType: action.payload,
      };
    }
    case "SET_ALL_RECOVERY_TYPE": {
      return {
        ...state,
        AllRecoveryType: action.payload,
      };
    }
    case "SET_SINGLE_INVOICE_TYPE": {
      return {
        ...state,
        SingleInvoiceType: action.payload,
      };
    }
    case "SET_ALL_INVOICE_TYPE": {
      return {
        ...state,
        AllInvoiceType: action.payload,
      };
    }
    case "SET_SINGLE_CURRENCY": {
      return {
        ...state,
        SingleCurrency: action.payload,
      };
    }

    case "SET_ALL_CURRENCY": {
      return {
        ...state,
        AllCurrency: action.payload,
      };
    }
    case "SET_SINGLE_WAREHOUSE": {
      return {
        ...state,
        SingleWareHouse: action.payload,
      };
    }

    case "SET_ALL_WAREHOUSE": {
      return {
        ...state,
        AllWareHouse: action.payload,
      };
    }
    case "SET_SINGLE_BUSINESSUNIT": {
      return {
        ...state,
        SingleBusinessUnit: action.payload,
      };
    }

    case "SET_ALL_BUSINESSUNIT": {
      return {
        ...state,
        AllBusinessUnit: action.payload,
      };
    }

    default:
      return state;
  }
};
export default commonMasterReducer;
