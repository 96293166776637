import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccessRequest from "../../../forms/AccessControl/AccessRequest";
import axios from "../../../components/apis/axios";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import pick from "lodash/pick";

export const AccessRequestEdit = (props) => {
  const [roleList, setRoleList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [workingOn, setWorkingOn] = useState(null);
  const [Company, setCompany] = useState([]);
  const [accessHistory, setAccessHistory] = useState([]);
  const { match } = props;

  const history = useHistory();

  const onSubmit = async (formValues) => {
    delete formValues.EmployeeCodeName;
    console.log({ formValues });
    await axios.put(
      `/users/editUserWithRoles/${props.match.params.id}`,
      formValues
    );
    history.push("/accessControl/accessRequest");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(`/users/v2/${match.params.id}`);
        await axios.get(`/users/v2/${match.params.id}`);
        let data = response.data;
        setFormDetail({
          EmployeeCodeName:
            data.EmployeeCode + "-" + data.first_name + " " + data.last_name,
        });
        setWorkingOn(data.working_on);
        console.log("users ", data);
      } catch (err) {
        console.error(err);
      }
    }
    async function fetchHistory() {
      try {
        const response = await axios.get(`/users/history/${match.params.id}`);
        setAccessHistory(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchHistory();
    fetch();
  }, [match.params]);

  useEffect(() => {
    async function fetchCompnay() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let newData = data.filter((item) => item.Tags == "Active");
        const filteredData = newData.map((item) => item.CompanyName);
        setCompany(filteredData);
      } catch (err) {}
    }
    async function fetchRole() {
      try {
        const list = await (await axios.get("/roles")).data;
        setRoleList(list);
      } catch (err) {}
    }
    fetchRole();
    fetchCompnay();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Access Control Edit"
        linkName1="Access Control Dashboard"
        to1="/accessControl/accessRequest"
      />

      <h3 style={{ textAlign: "center" }}>Access Request</h3>
      <AccessRequest
        onSubmit={onSubmit}
        roleList={roleList}
        Company={Company}
        initialValues={{
          ...pick(formDetail, "EmployeeCodeName"),
          accessPermissions: workingOn?.map((item) =>
            JSON.parse(item?.accessPermissions)
          ),
        }}
        working={workingOn}
        accessHistory={accessHistory}
        edit={true}
      />
    </div>
  );
};
