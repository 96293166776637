import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import * as Control from "../../../components/formcomponents/control";
import { searchHrDailyAttendanceById } from "../../../redux/actions/HRManagementActions";
import CustomDataGrid from "../../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";
import { Button } from "@material-ui/core";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import axios from "../../../components/apis/axios";

export const OvertimeEntryDashboard = (props) => {
  const [show, setShow] = React.useState(false);
  const [data, setData] = useState(null);
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const { match } = props;
  const gridRef = useRef(null);

  const toggleModal = () => setShow(!show);

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if (!match.params.id) return;
    
    fetch();
  }, [match.params.id]);

  const fetch = async () => {
    const data = await searchHrDailyAttendanceById(match.params.id);
    setData(data);
  };

  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
    },
    {
      field: "shiftCode",
      headerName: "Shift Code",
      flex: 1,
    },
    {
      field: "timeIn",
      headerName: "Time In",
      flex: 1,
    },
    {
      field: "timeOut",
      headerName: "TimeOut",
      editable: true,
      flex: 1,
      valueFormatter: ({ api, value }) => {
        if (!gridRef.current) {
          gridRef.current = api;
        }
        return value;
      },
    },
    {
      field: "normalHour",
      headerName: "Normal Hours",
      flex: 1,
    },
    {
      field: "totalHour",
      headerName: "Total Hours",
      flex: 1,
    },
    {
      field: "overtimeHour",
      headerName: "Overtime Hours",
      editable: true,
      flex: 1,
      valueFormatter: ({ api, value }) => {
        if (!gridRef.current) {
          gridRef.current = api;
        }
        return value;
      },
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 1,
    },
    {
      field: "machine",
      headerName: "Machine",
      flex: 1,
    },
  ];

  if (!data) return null;

  const handleSubmit = async (formValues) => {
    await axios.put(`/hr-management/daily-attendance/${match.params.id}`, {
      project: data?.project?.id,
      tableData: [...gridRef.current?.getRowModels()?.values()]
    });
    fetch()
  };

  return (
    <>
      <Breadcrumbs name="Overtime Entry Dashboard" />
      <CustomDataGrid
        columns={columns}
        rows={data.attendanceTable || []}
        hideActions
        showFilter={false}
        hideFooter
      />
      <SubmitModelPopup
        handleSubmitForm={handleSubmit}
        formTitle="Overtime Entry"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </>
  );
};
