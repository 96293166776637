import { IconButton, TextField } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import axios from "../../components/apis/axios";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CostcenterTableRow = ({
  inputField,
  inputFields,
  index,
  tableData,
  setInputFields,
  onChange,
}) => {
  const [StateList, setStateList] = useState([]);
  const [Addresslist, setaddressList] = useState([]);
  const [gstlist, setgstList] = useState([]);
  const fieldValues = useSelector((state) => state.form?.CostCentre?.values);

  useEffect(() => {
    if (!inputField) return;

    getState();
    getGsts(inputField.GSTState);
    getAddress(inputField.GSTState);
  }, [inputField]);

  const handleRemoveFields = async (e, index, id) => {
    e.preventDefault();
    await axios.delete(`users/workingOn/${id}`);
    const values = [...inputFields];
    console.log(values, "before");
    values.splice(index, 1);
    console.log(values, "after");
    onChange(values);
    setInputFields(values);
  };

  const getState = async (value) => {
    const { data } = await axios.get("/commonMaster/companyDetails/gsts", {
      params: {
        companyName: fieldValues?.CompanyName,
        state: value,
      },
    });

    let state = data.map((item) => item.GSTState);

    setStateList(state);
  };

  useEffect(() => {
    if (!fieldValues?.CompanyName) return;
    getState();
  }, [fieldValues?.CompanyName]);

  const getGsts = async (value) => {
    const { data } = await axios.get("/commonMaster/companyDetails/gsts", {
      params: {
        companyName: fieldValues?.CompanyName,
        state: value,
      },
    });
    let gsts = data.map((item) => item.GSTRegNo);
    setgstList(gsts);
    return gsts;
  };

  const getAddress = async (value) => {
    const { data } = await axios.get("/commonMaster/companyDetails/gsts", {
      params: {
        companyName: fieldValues?.CompanyName,
        state: value,
      },
    });
    let address = data.map((item) => item.GSTAddress);
    setaddressList(address);
    return address;
  };

  const onStateSelect = async (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      row.GSTState = value;
      setInputFields(tdata);
      const gsts = await getGsts(value);
      const address = await getAddress(value);
      row.GSTRegNo = gsts?.[0] || "";
      row.GSTAddress = address?.[0] || "";
      tdata[index] = row;
      tableData(tdata);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <td className="table-data1">{index + 1}</td>
      <td className="table-data2">
        {StateList && (
          <AutoCompleteTableInput
            list={StateList}
            inputChange={onStateSelect}
            tableIndex={index}
            fieldName={"GSTState"}
            value={inputField.GSTState || null}
          />
        )}
      </td>
      <td className="table-data3">
        {inputField?.GSTState && (
          <TextField
            disabled
            value={inputField?.GSTState ? gstlist?.[0] : null}
          />
        )}
      </td>
      <td className="table-data3">
        {inputField?.GSTState && (
          <TextField
            disabled
            value={inputField?.GSTState ? Addresslist?.[0] : null}
          />
        )}
      </td>

      {index === 0 ? (
        <IconButton>
          <RemoveCircleIcon className="width5" color="disabled" />
        </IconButton>
      ) : (
        <IconButton
          onClick={(e) => handleRemoveFields(e, index, inputField.id)}
        >
          <RemoveCircleIcon className="width5" color="secondary" />
        </IconButton>
      )}
    </>
  );
};

export default CostcenterTableRow;
