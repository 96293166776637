export const getCurrentFinancialYear = () => {
  let year = "";
  let today = new Date();
  if (today.getMonth() + 1 <= 3) {
    year = today.getFullYear() - 1 + "-" + today.getFullYear();
  } else {
    year = today.getFullYear() + "-" + (today.getFullYear() + 1);
  }
  return year;
};
