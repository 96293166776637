import React, { useEffect, useState } from "react";
import DeliveryChallanForm from "../../../forms/MaterialSystem/DeliveryChallanForm";
import "../style/materialSystem.css";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DeliveryChallan } from "../../../redux/actions/materialSystemAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allWI = [];
let allProject = [];

export const DeliveryChallanFormCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [purchaseRequestList, setPurchaseRequestList] = useState([]);
  const [warehouseIssueVoucherList, setWarehouseIssueVoucherList] = useState(
    []
  );
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [stocksDetails, setStocksDetails] = useState();
  const [material, setMaterial] = useState();

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(DeliveryChallan(formValues, history, enqueueSnackbar));
  };

  const selectSourceProject = async (project, formFields) => {
    try {
      let item = allProject.find(
        (item) => item.ProjectShortDescription === project
      );
      item = {
        SourceProject: project,
        FromWarehouse: item.WareHouse,
        SourceGSTNo: item.WareHouseGst,
      };
      setFormDetail({ ...formDetail, ...formFields, ...item });
      if (formFields.DeliveryChallanType === "Purchase Request No​") {
        const { data } = await axios.post(
          "/materialManagement/shared/getPurchaseRequest/",
          {
            requestedFrom: "DeliveryChallan",
            ProjectCodeName: project,
          }
        );
        const mapped = data.map((item) => item.PurchaseRequestNo);
        setPurchaseRequestList(mapped);
      } else {
        let { data } = await axios.post(
          "/materialManagement/warehouseIssue/getByProjectName",
          { ProjectCodeName: project }
        );
        allWI = data;
        let WIVlist = data.map((item) => item.WarehouseIssueVoucherNo);
        setWarehouseIssueVoucherList(WIVlist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const selectPurchaseReq = async (PurchaseRequestNo, formFields) => {
    let payload = await axios.get(
      `/materialManagement/purchaseRequest/getByPurchaseRequestNo/${PurchaseRequestNo}`
    );
    setMaterial(payload.data.materials);
    const materials = payload.data.materials;
    payload = {
      ...formDetail,
      PurchaseRequestNo: payload.data.PurchaseRequestNo,
      DestinationProject: payload.data.ProjectCodeName,
      ToWarehouse: payload.data.WarehouseName,
      DestinationGSTNo: payload.data.WarehouseGSTNo,
      Date: payload.data.create_date,
    };
    setFormDetail({
      ...payload,
      ...formFields,
    });
    try {
      const Code = materials.map((item) => item.MaterialCode);
      const req = {
        WareHouse: formDetail.FromWarehouse,
        Code,
      };
      const { data } = await axios.post(
        "/materialManagement/shared/getWarehouseStockRate",
        req
      );
      setStocksDetails(data);
    } catch (err) {
      console.error(err);
    }
  };

  const selectWarehouseIssue = async (WarehouseIssueVoucherNo, formFields) => {
    const item = allWI.find(
      (item) => item.WarehouseIssueVoucherNo === WarehouseIssueVoucherNo
    );

    if (item) {
      let payload = await axios.get(
        `/materialManagement/warehouseIssue/${item.id}`
      );
      const materials = payload.data.materials;
      setMaterial(payload.data.materials);
      console.log(payload.data);
      payload = {
        ...formDetail,
        WarehouseIssueVoucherNo: payload.data.WarehouseIssueVoucherNo,
        DestinationProject: payload.data.ProjectCodeName,
        ToWarehouse: payload.data.Warehouse,
        DestinationGSTNo: payload.data.WareHouseGSTNo,
        WarehouseIssueDate: payload.data.DateofIssue,
        GoodsIssueType: payload.data.GoodsIssueType,
        VendorCodeName: payload.data.VendorCodeName,
        CustomerCodeName: payload.data.CustomerCodeName,
        WorkOrderNo: payload.data.WorkOrderNo,
        SalesOrderNo: payload.data.WorkSalesNo,
      };
      setFormDetail({
        ...payload,
        ...formFields,
      });
      try {
        const Code = materials.map((item) => item.MaterialCode);
        const req = {
          WareHouse: formDetail.FromWarehouse,
          Code,
        };
        const { data } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          req
        );
        setStocksDetails(data);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetchProjects() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const filteredData = data.filter((item) => item.Status === "accepted");
        let projects = filteredData.map((item) => item.ProjectShortDescription);
        allProject = filteredData;
        setProjectList(projects);
      } catch (error) {
        alert(error);
      }
    }
    fetchProjects();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Delivery Challan Create"
        linkName1="Delivery Challan Dashboard"
        to1="/materialManagement/deliveryChallan"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Delivery Challan Form
      </h3>
      <div className="line"></div>
      <DeliveryChallanForm
        onSubmit={onSubmit}
        PRList={purchaseRequestList}
        WIVList={warehouseIssueVoucherList}
        ProjectList={projectList}
        stocksDetails={stocksDetails}
        selectPurchaseReq={selectPurchaseReq}
        selectWarehouseIssue={selectWarehouseIssue}
        selectSourceProject={selectSourceProject}
        initialValues={pick(
          formDetail,
          "DeliveryChallanType",
          "PurchaseRequestNo",
          "Date",
          "WarehouseIssueVoucherNo",
          "WarehouseIssueDate",
          "VendorCodeName",
          "CustomerCodeName",
          "GoodsIssueType",
          "WorkOrderNo",
          "SalesOrderNo",
          "GoodsStockType",
          "SourceProject",
          "FromWarehouse",
          "SourceGSTNo",
          "DestinationProject",
          "ToWarehouse",
          "DestinationGSTNo",
          "TransportWORequired",
          "TransportType",
          "LRRRConsignment",
          "LRDate",
          "Remarks",
          "DeliveryChallanValue"
        )}
        materialsTableCreate={material}
      />
    </div>
  );
};
