import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const MaterialGroup = (props) => {
     const [show, setShow] = React.useState(false);

     const onSubmit = (formValues) => {
       props.onSubmit(formValues);
     };

     const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="MaterialGroupCode"
            label="Material Group Code"
            style={{ width: "90%", margin: "1rem" }}
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialMajorGroupDescription"
            label="Material Major Group Description"
            style={{ width: "90%", margin: "1rem" }}
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialMinorGroupDescription"
            label="Material Minor Group Description"
            style={{ width: "90%", margin: "1rem" }}
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialSubGroupDescription"
            label="Material Sub Group Description"
            style={{ width: "90%", margin: "1rem" }}
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialSubSubGroupDescription"
            label="Material Sub Sub Group Description"
            style={{ width: "90%", margin: "1rem" }}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tag"
            label="Tag"
            className="text-field"
            list={[" ", "Material", "Machine", "Spare"]}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Status Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="MaterialGroup"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "MaterialGroupCode",
    "MaterialMajorGroupDescription",
    "Tag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MaterialGroup",
  validate,
  enableReinitialize: true,
})(MaterialGroup);
