import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "./main.css";
import { Button } from "@material-ui/core";

class MachineDeHireRequest extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              component={Control.RenderTextField}
              name="ProjectCodeName"
              label="Project code / Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="RequestType"
              label="Request Type"
              style={{ width: "28%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="MachineCategory"
              label="Machine Category"
              style={{ width: "28%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="Currency"
              label="Currency"
              style={{ width: "30%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="MachineCodeName"
              label="Machine Code / Name"
              style={{ width: "45%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              initialValue={this.props.initialValues.SurplusDate}
              name="SurplusDate"
              label="Surplus Date"
              style={{ width: "20%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              initialValue={this.props.initialValues.LastLogSheetDate}
              name="LastLogSheetDate"
              label="Last Log Sheet Date"
              style={{ width: "20%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="TimeRequiredtoRelease"
              label="Time Required To Release"
              style={{ width: "45%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="Remarks"
              label="Remarks"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div className="middle">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "RequestType",
    "MachineCategory",
    "Currency",
    "MachineCodeName",
    "SurplusDate",
    "LastLogSheetDate",
    "TimeRequiredtoRelease",
    "Remarks",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachineDeHireRequest",
  validate,
  enableReinitialize: true,
})(MachineDeHireRequest);
