import AutorenewIcon from "@material-ui/icons/Autorenew";
import RoleList from "../../helpers/RoleList";

export const ExecutionSystemNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.BU_HEAD.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.DIRECTOR.code,
  ],
  label: "Execution System",
  name: "ExecutionSystem",
  attrModule: "projectExecution",
  icon: <AutorenewIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/executionSystem/dailyProgressReport",
      label: "Daily Progress Report",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/executionSystem/hurdleRegister",
      label: "Hurdle Register",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
  ],
};
