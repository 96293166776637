import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import Divider from "@material-ui/core/Divider";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// import StarBorder from "@material-ui/icons/StarBorder";
import usePermissions from "../../utils/hooks/usePermissions";
import useAttributes from "../../utils/hooks/useAttributes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  NavLink: {
    textDecoration: "none",
  },
}));

const Sidebar = ({ nav, closeDrawer }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigations, setNavigations] = useState(null);
  const permissions = usePermissions({ module: nav?.name, nav });
  const attributes = useAttributes({ nav });

  const position = useSelector((store) => store.userRoot.workingOn.Role);
  const attrString = JSON.stringify(attributes);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (nav.moduleAccess.includes(position)) {
      const availableNavs = nav.navs.map((item) => {
        const subModule = item.to.split("/")?.[2];
        const parsedAttr = JSON.parse(attrString);
        const subPermissions = parsedAttr[`${nav.attrModule}_${subModule}`];

        if (
          parsedAttr.isAdmin ||
          (item.access.includes(position) && subPermissions?.Enabled)
        )
          return item;
        return null;
      });
      setNavigations(availableNavs);
    }
  }, [attrString, nav, position]);

  return (
    <>
      {nav?.moduleAccess.includes(position) && permissions?.Enabled && (
        <>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <ListItem button onClick={handleClick}>
              <ListItemIcon>{nav.icon}</ListItemIcon>
              <ListItemText primary={nav.label} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {navigations
                ? navigations.map((item, index) => (
                    <List component="div" disablePadding key={index}>
                      {item && (
                        <NavLink
                          to={item.to}
                          style={{ textDecoration: "none", color: "black" }}
                          onClick={closeDrawer}
                        >
                          <ListItem button className={classes.nested}>
                            <ListItemText primary={item.label} />
                          </ListItem>
                        </NavLink>
                      )}
                    </List>
                  ))
                : "loading"}
            </Collapse>
          </List>
          <Divider />
        </>
      )}
    </>
  );
};

export default Sidebar;
