import { useSelector } from "react-redux";
import RoleList from "../../components/helpers/RoleList";

// Function to find and return an object with key-value pairs that partially match the given string
function findMatchingProperties(obj, searchString) {
  const matchingProperties = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.includes(searchString)) {
      matchingProperties[key] = obj[key];
    }
  }
  return matchingProperties;
}

const useAttributes = (props) => {
  const store = useSelector((store) => store);
  const position = store?.userRoot?.workingOn?.Role;
  try {
    let moduleName = props.nav.attrModule;

    const attributes = store?.userRoot?.workingOn?.attributes;

    return position === RoleList.ADMIN.code
      ? {
          isAdmin: true,
          View: true,
          Modify: true,
          Cancel: true,
          Enabled: true,
          Approval: true,
          Add: true,
        }
      : findMatchingProperties(attributes, moduleName);
  } catch (error) {
    return null;
  }
};

export default useAttributes;
