import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton, TablePagination  } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useStyles from "../helpers/UseStyle";
import Paginator from "../Paginator/Paginator";
import { CircularProgress } from "@material-ui/core";

const AttendanceTable = (props) => {
  const classes = useStyles();
  const [lists, setLists] = useState([]);
 

  useEffect(() => {
    let lists = props.lists;
    lists = lists.reverse();
    setLists(lists);
    console.log(lists);
  }, [props.lists]);

  return (
    <>
      <div>
        {lists ? (
          <><TableContainer className={classes.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{props.label1}</TableCell>
                  <TableCell align="center">{props.label2}</TableCell>
                  <TableCell align="center">View Attendance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item[props.tablecol1]}
                    </TableCell>
                    <TableCell align="center">
                      {item[props.tablecol2]}
                    </TableCell>
                    <TableCell align="center">
                      {props.visibleIcon && (
                        <span>
                          <IconButton
                            onClick={() => props.handleOnViewClick(item.MachineCodeName)}
                          >
                            <VisibilityIcon color="primary" />
                          </IconButton>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper
            style={{
              width: "90%",
              margin: "80px auto",
              marginTop: "0",
            }}
          >
               <Paginator paginateResults={props.paginatedResults} />
            </Paper>
            </>

          
        ) : (
          <CircularProgress />
        )}
      </div>
    </>
  );
};

export default AttendanceTable;
