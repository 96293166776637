import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Vendor from "../../../forms/CommonMaster/Vendor";
import { vendor } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import StateList from "../../../components/helpers/StateList";

export const VendorCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [stateList, setStateList] = useState([]);

  const onSubmit = (formValues) => {
    dispatch(vendor(formValues, history));
  };

  useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Vendor Create"
        linkName1="Vendor Dashborad"
        to1="/commonMaster/vendor"
      />
      <h3 style={{ textAlign: "center" }}>Vendor</h3>
      <Vendor onSubmit={onSubmit} stateList={stateList} />
    </div>
  );
};
