import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import useCreateShiftStyles from "./Shift.styles";

const Shift = (props) => {
  const classes = useCreateShiftStyles();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const fieldValues = useSelector(state => state.form?.Shift?.values)

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  const handleShiftInChange = (val) => {
    dispatch(change('Shift', 'shift_out_Time', moment(val).add(9, 'hours').toISOString()))
  }

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="shift_code"
            label="Shift Code"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="tag"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
          <Field
            component={Control.RenderTimeField}
            initialValue={props?.initialValues?.shift_in_Time || null}
            name="shift_in_Time"
            label="Shift In Time"
            className={classes.dateField}
            onChange={handleShiftInChange}
            ampm={false}
          />
          <Field
            component={Control.RenderTimeField}
            initialValue={fieldValues?.shift_out_Time  || null}
            name="shift_out_Time"
            className={classes.dateField}
            label="Shift Out Time"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Shift"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = ["shift_code", "tag", "shift_in_Time", "shift_out_Time"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "Shift",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Shift);
