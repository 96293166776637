import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchUnitOfMeasurementById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const UnitOfMeasurmentView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchUnitOfMeasurementById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleUnitOfMeasurement) {
      setData(store.commonMasterSystemRoot.SingleUnitOfMeasurement);
    }
  }, [store.commonMasterSystemRoot.SingleUnitOfMeasurement]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("unitOfMeasurement", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <>
      <Breadcrumbs
        name="UOM View"
        linkName1="UOM Dashboard"
        to1="/commonMaster/unitOfMeasurement"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Unit Of Measurement</h3>
          <h6>{`Created by ${data.created_by
            ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
            : ""
            }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data.UOMCode && (
              <Paper className={classes.paper}>
                <h6>UOM Code:</h6>
                <p>{data.UOMCode}</p>
              </Paper>)}

            {data.UOMDescription && (
              <Paper className={classes.paper}>
                <h6>UOM Description:</h6>
                <p>{data.UOMDescription}</p>
              </Paper>)}

            {data.AllowableDecimal && (
              <Paper className={classes.paper}>
                <h6>Allowable Decimal:</h6>
                <p>{data.AllowableDecimal}</p>
              </Paper>)}

              {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
