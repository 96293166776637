import React, { useEffect, useState } from "react";
import Table from "../../../../components/FormDashBoard/FormDashboardPagination";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../components/apis/axios";
import { useHistory, useLocation } from "react-router-dom";
import { userLogout } from "../../../../redux/actions/userAction";
import RoleList from "../../../../components/helpers/RoleList";
import moment from "moment";
import { useCallback } from "react";
import { readAllFromQueryParams } from "../../../../utils/queryParams";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

export const ReceiptCustomerDashboard = () => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.CASHIER.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.CASHIER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code
      ? true
      : false;
  const fetch = useCallback(async () => {
    try {
      const { data } = await axios.get(
        "/accountingSystem/receiptCustomerInvoice",
        {
          params: {
            status: "Accepted",
            page: 1,
            ...readAllFromQueryParams(search),
          },
        }
      );
      console.log(data);
      const newData = [
        ...new Map(
          data.data?.map((item) => [item["ReceiptCustomerInvoiceNo"], item])
        ).values(),
      ];
      console.log(newData);
      newData.forEach((element) => {
        element.created_at = moment(element.created_at).format(
          "DD/MM/YYYY, h:mm a"
        );
        element.approved_at =
          element.approved_at &&
          moment(element.approved_at).format("DD/MM/YYYY, h:mm a");
      });
      setLists(newData);
      setPaginatedResults({ ...data, data: newData });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    }
  }, [dispatch, history, search]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div>
      <Breadcrumbs name="Receipt Customer Invoice Dashboard" />
      <Table
        paginateResults={paginatedResults}
        total={lists.total}
        lists={lists}
        DeleteLink="/accountingSystem/receiptCustomerInvoice/"
        tablecol1="ReceiptCustomerInvoiceNo"
        heading="Payment Receipt Customer DashBoard"
        label1="Payment Receipt Customer No"
        link="/accountingSystem/receiptCustomerInvoice/create"
        editLink="/accountingSystem/receiptCustomerInvoice/edit"
        viewLink="/accountingSystem/receiptCustomerInvoice/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </div>
  );
};
