import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allMachinePurchaseOrderAmendment } from "../../../redux/actions/MachineManagementaction";
import Table from "../../../components/FormDashBoard/FormDashBoard";
import RoleList from "../../../components/helpers/RoleList";

export const MachinePurchaseOrderAmendmentDash = (props) => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const button =
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  useEffect(() => {
    dispatch(allMachinePurchaseOrderAmendment());
  }, [dispatch]);
  useEffect(() => {
    if (
      store.machineMangementSystemRoot.AllMachinePurchaseOrderAmendment
        .length !== 0
    ) {
      setLists(
        store.machineMangementSystemRoot.AllMachinePurchaseOrderAmendment
      );
    }
  }, [store.machineMangementSystemRoot.AllMachinePurchaseOrderAmendment]);
  return (
    <Table
      DeleteLink="/machineManagement/machinePurchaseOrderAmendment"
      lists={lists}
      tablecol1="MachinePurchaseOrderAmendmentNo"
      heading="Machine Purchase Order Amendment"
      label1="Machine Purchase order Amendment No"
      link="/machineManagement/machinePurchaseOrderAmendment/create"
      editLink="/machineManagement/machinePurchaseOrderAmendment/edit"
      viewLink="/machineManagement/machinePurchaseOrderAmendment/view"
      button={button}
      visibleIcon={visibleIcon}
    />
  );
};
