import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";

const columns = [
  { id: "MaterialCode", label: "Material Code", minWidth: 80 },
  { id: "MaterialName", label: "Material Name", minWidth: 80 },
  {
    id: "PRValue",
    label: "Purchase Request",
    minWidth: 80,
    align: "center",
  },
  {
    id: "POValue",
    label: "Purchase Order",
    minWidth: 80,
    align: "center",
  },
  {
    id: "POAValue",
    label: "Purchase Order Amendment",
    minWidth: 80,
    align: "center",
  },
  {
    id: "GRValue",
    label: "Goods Receipt Voucher",
    minWidth: 80,
    align: "center",
  },
  {
    id: "GIValue",
    label: "Goods Inspection Voucher",
    minWidth: 80,
    align: "center",
  },
  {
    id: "WRValue",
    label: "Warehouse Receipt Voucher",
    minWidth: 80,
    align: "center",
  },
  {
    id: "WIValue",
    label: "Warehouse Issue Voucher",
    minWidth: 80,
    align: "center",
  },
  {
    id: "DCValue",
    label: "Delivery Challan",
    minWidth: 80,
    align: "center",
  },
];

const MaterialFlowTable = ({ data }) => {
  return (
    <>
      {data ? (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default MaterialFlowTable;
