import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

const denominations = {
  TwoThousand: 2000,
  OneThousand: 1000,
  FiveHundred: 500,
  TwoHundred: 200,
  OneHundred: 100,
  Fifty: 50,
  Twenty: 20,
  Ten: 10,
  Five: 5,
  Two: 2,
  One: 1,
};

const CurrencyTable = ({
  handleCurrencyChange,
  cashDetails,
  systemBalance,
}) => {
  const [notes, setNotes] = useState({
    TwoThousand: 0,
    OneThousand: 0,
    FiveHundred: 0,
    TwoHundred: 0,
    OneHundred: 0,
    Fifty: 0,
    Twenty: 0,
    Ten: 0,
    Five: 0,
    Two: 0,
    One: 0,
    TotalCash: 0,
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (cashDetails) {
      const notes = {
        TwoThousand: +cashDetails.TwoThousand,
        OneThousand: +cashDetails.OneThousand,
        FiveHundred: +cashDetails.FiveHundred,
        TwoHundred: +cashDetails.TwoHundred,
        OneHundred: +cashDetails.OneHundred,
        Fifty: +cashDetails.Fifty,
        Twenty: +cashDetails.Twenty,
        Ten: +cashDetails.Ten,
        Five: +cashDetails.Five,
        Two: +cashDetails.Two,
        One: +cashDetails.One,
        TotalCash: +cashDetails.TotalCash,
        id: +cashDetails.id,
      };
      setNotes({ ...notes });
      if (handleCurrencyChange) handleCurrencyChange(cashDetails);
    }
    // eslint-disable-next-line
  }, [cashDetails]);

  const checkNoteCount = (e) => {
    let tempNotes = notes;
    tempNotes[e.target.name] = +e.target.value;
    if (+systemBalance[e.target.name] >= +tempNotes[e.target.name]) {
      return true;
    }
    return false;
  };

  const calculate = (e) => {
    let countOk = true;
    if (systemBalance) countOk = checkNoteCount(e);
    if (countOk) {
      let tempNotes = notes;
      tempNotes[e.target.name] = +e.target.value;
      let sum = 0;
      for (const i in tempNotes) {
        if (i !== "TotalCash") sum = +sum + +denominations[i] * +tempNotes[i];
      }
      tempNotes.TotalCash = +sum;
      setNotes({ ...tempNotes });
      handleCurrencyChange(tempNotes);
    } else {
      enqueueSnackbar(
        `Enter ${denominations[e.target.name]} notes, less than or equal to ${
          systemBalance[e.target.name]
        }`,
        {
          variant: "info",
        }
      );
    }
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">2000</th>
            <th className="head-cell">1000</th>
            <th className="head-cell">500</th>
            <th className="head-cell">200</th>
            <th className="head-cell">100</th>
            <th className="head-cell">50</th>
            <th className="head-cell">20</th>
            <th className="head-cell">10</th>
            <th className="head-cell">5</th>
            <th className="head-cell">2</th>
            <th className="head-cell">1</th>
            <th className="head-cell">Total</th>
          </tr>
        </thead>
        <tbody className="table-body">
          <tr>
            <td className="table-data1">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="TwoThousand"
                value={notes.TwoThousand}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="OneThousand"
                value={notes.OneThousand}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="FiveHundred"
                value={notes.FiveHundred}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="TwoHundred"
                value={notes.TwoHundred}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="OneHundred"
                value={notes.OneHundred}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="Fifty"
                value={notes.Fifty}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="Twenty"
                value={notes.Twenty}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="Ten"
                value={notes.Ten}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="Five"
                value={notes.Five}
              />
            </td>
            <td className="table-data2">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="Two"
                value={notes.Two}
              />
            </td>
            <td className="table-data3">
              <input
                style={{ width: "60px" }}
                onChange={calculate}
                type="number"
                name="One"
                value={notes.One}
              />
            </td>
            <td className="table-data4">{notes.TotalCash}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CurrencyTable;
