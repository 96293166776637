import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const DisbursementSubSupplierTable = ({ paymentDetails }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let values = [];
    if (paymentDetails) {
      values = [...paymentDetails];
    }
    setData(values);
  }, [paymentDetails]);

  return (
    <div>
      {paymentDetails ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">PurchaseOrderNo</th>
                <th className="head-cell">PurchaseOrderDate</th>
                <th className="head-cell">WarehouseReceiptNo</th>
                <th className="head-cell">WarehouseReceiptDate</th>
                <th className="head-cell">SupplierInvoiceNo</th>
                <th className="head-cell">SupplierInvoiceDate</th>
                <th className="head-cell">PurchaseJournalVoucherNo</th>
                <th className="head-cell">PurchaseJournalVoucherDate</th>
                <th className="head-cell">NetDue</th>
                <th className="head-cell">DueDate</th>
                <th className="head-cell">PaidSoFar</th>
                <th className="head-cell">PayNow</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data2">
                    {format(
                      new Date(row.PurchaseJournalVoucherDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{row.WarehouseReceiptNo}</td>
                  <td className="table-data2">
                    {format(new Date(row.WarehouseReceiptDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{row.SupplierInvoiceNo}</td>
                  <td className="table-data2">{row.SupplierInvoiceDate}</td>
                  <td className="table-data2">
                    {row.PurchaseJournalVoucherNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(row.PurchaseJournalVoucherDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{row.NetDue}</td>
                  <td className="table-data2">
                    {format(new Date(row.DueDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{row.PaidSoFar}</td>
                  <td className="table-data2">{row.PayNow}</td>
                  <td className="table-data4">{row.PurchaseOrderNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default DisbursementSubSupplierTable;
