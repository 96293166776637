import React from "react";
import { useSnackbar } from "notistack";
import { RenderFromHelper } from "./control";
import { FormControl, Select, InputLabel } from "@material-ui/core";

export const RenderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  const { onChange, ...rest } = input;
  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }
  return (
    <FormControl
      error={touched && error}
      style={{ minWidth: "25%", margin: "1.2rem" }}
    >
      <InputLabel htmlFor="selectField" variant="outlined">
        {label}
      </InputLabel>
      <Select
        {...rest}
        {...custom}
        id="selectField"
        native
        onChange={(event) => onChange(event.target.value)}
      >
        {children}
      </Select>
      {RenderFromHelper({ touched, error })}
    </FormControl>
  );
};
