/* eslint-disable react-hooks/exhaustive-deps */
import LinearProgress from "@material-ui/core/LinearProgress";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import React, { useState, useEffect } from "react";
import CostcenterTableRow from "./CostCenterGSTRowTable";

const StoreGST = ({ tableData, gsts, Gst }) => {
  console.log(gsts, "kkkkkkkkkkkkkkkkkkkkkkkkmllml");
  const [inputFields, setInputFields] = useState(null);
  // const handleChangeInput = (index, event) => {
  //   const values = [...inputFields];
  //   values[index][event.target.name] = event.target.value;
  //   setInputFields(values);
  //   onChange(values);
  // };
  const handleChangeInput = (values) => {
    console.log({values})
    if (values) {
      setInputFields(values);
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };
  useEffect(() => {
    let values = [];
    if (gsts) {
      for (let i = 0; i < gsts.length; i++) {
        values = [
          ...values,
          {
            SerialNo: gsts[i].SerialNo,
            // id:gsts[i].id,
            GSTRegNo: gsts[i].GSTRegNo,
            GSTState: gsts[i].GSTState,
            GSTAddress: gsts[i].GSTAddress,
          },
        ];
      }
      setInputFields(values);
      onChange(values);
    }
  }, [gsts]);

  useEffect(() => {
    if (Gst) {
      setInputFields([...Gst]);
      onChange([...Gst]);
    }
  }, [Gst]);

  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    tableData(values);
    onChange(values);
    setInputFields(values);
  };

  return (
    <div>
      {inputFields ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">Sl No.</th>
                  <th>State</th>
                  <th>GST No.</th>
                  <th className="head-cell">Address</th>
                </tr>
              </thead>
              <tbody>
                {inputFields.map((inputField, index) => (
                  <tr key={index}>
                    <CostcenterTableRow
                      gsts={gsts}
                      inputField={inputField}
                      inputFields={inputFields}
                      index={index}
                      tableData={tableData}
                      setInputFields={setInputFields}
                      onChange={handleChangeInput}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default StoreGST;
