import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";


const LdclauseTable = ({ ldclauseTermsTable, ldclause }) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: 1,
      Frequency: "",
      MinimumPercentage: "",
      MaximumPercentage: "",
      id: "new",
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (ldclause) {
      setInputFields([...ldclause]);
      onChange([...ldclause]);
    }
    // eslint-disable-next-line
  }, [ldclause]);

  const onChange = (values) => {
    if (values) {
      ldclauseTermsTable(values);
    } else {
      ldclauseTermsTable({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    let sum = 0;
    console.log(values);
    for (let i = 0; i < values.length; i++) {
      sum = sum + +values[i].MaximumPercentage;
      if (sum > 100) {
        values[i].MaximumPercentage = 0;
        enqueueSnackbar("percentage cannot be greater than 100", {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    onChange(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        No: "",
        Frequency: "",
      MinimumPercentage: "",
    MaximumPercentage: "",
        id: "",
      },
    ]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">Frequency</th>
            <th className="head-cell">Minimum Percentange</th>
            <th className="head-cell">Maximum Percentage</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
                <input
                  type="text"
                  style={{ width: "10rem" }}
                  name="Frequency"
                  value={inputField.Frequency}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data3">
                <input
                  type="number"
                  style={{ width: "15rem" }}
                  name="MinimumPercentage"
                  value={inputField.MinimumPercentage}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  style={{ width: "15rem" }}
                  name="MaximumPercentage"
                  value={inputField.MaximumPercentage}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
         
              <td>
                {index >= 0 ? (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                ) : ""
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
        >
          <AddCircleIcon />
          ADD Row
        </Button>
      </div>
    </div>
  );
};
export default LdclauseTable;
