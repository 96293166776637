import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import useStyle from "../helpers/UseStyle";
import FilterByDate from "../ReportsComponent/FilterByDate";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { IconButton, TablePagination } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function WorkmenJoiningReportTable(props) {
  const [lists, setLists] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterSelected, setFilterSelected] = useState("");
  const classes = useStyle();

  useEffect(() => {
    let lists = props.lists;
    if (lists) {
      lists = lists.reverse();
      setLists(lists);
      console.log(lists);
    }
  }, [props.lists]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  return (
    <>
      <div className={classes.table}>
       
     
        <div>
          <TableContainer className={classes.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{props.label1}</TableCell>
                  <TableCell align="center">{props.label2}</TableCell>
                  <TableCell align="center">WorkmenDOJ</TableCell>
                  <TableCell align="center">Operation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <ListAltIcon color="primary" />
                      {item[props.tablecol1]}
                    </TableCell>
                    <TableCell align="center">{item.WorkmenName}</TableCell>
                    <TableCell align="center">
                      <UpdateIcon color="primary" />
                      {format(new Date(item.WorkmenDOJ), "d MMM yyyy")}
                    </TableCell>
                    <TableCell align="center">
                      {props.visibleIcon && (
                        <span>
                          <IconButton>
                            <Link to={`${props.viewLink}/${item.id}`}>
                              <VisibilityIcon color="primary" />
                            </Link>
                          </IconButton>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper
              style={{
                width: "90%",
                margin: "80px auto",
                marginTop: "0",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                color="primary"
                component="div"
                count={lists.length}
                rowsPerPage={rowsPerPage}
                page={page || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
        </div>
      </div>
    </>
  );
}