import React, { useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import PurchaseOrderTable from "../../Tables/MaterialSystem/PurchaseOrderTable";
import PaymentTermsTable from "../../Tables/PaymentTerms/PaymentTermsTable";
import * as Control from "../../components/formcomponents/control";
import { useSnackbar } from "notistack";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const PurchaseOrderForm = (props) => {
  const [state, setState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    totalpayment: null,
    paymentTermsTableErrorKind: "",
    paymentTermsTableError: false,
  });

  const [uploadedFiles, setUploadedFiles] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const storeTableData = (data) => {
    try {
      let total = 0;
      data.forEach((item) => {
        total += +item.ItemTotal;
      });
      props.dispatch(
        change("PurchaseOrderForm", "PurchaseOrderValue", (+total).toFixed(2))
      );
    } catch (err) {
      console.log(err);
    }

    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const paymentTermsTable = (data) => {
    //console.log("data",data);
    if (!data) {
      //alert("hello")
      // setState({
      //   ...state,
      //   paymentTermsTableErrorKind: "table row are not fully field",
      //   paymentTermsTableError: true,
      // });
    } else {
      setState({
        ...state,
        totalpayment: data,
        paymentTermsTableErrorKind: "",
        paymentTermsTableError: false,
      });
    }
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({ ...state, tableError: true });
    } else {
      formValues = {
        ...formValues,
        tableData: state.tableData,
      };
    }
    if (!state.totalpayment) {
      setState({ ...state, paymentTermsTableError: true });
    } else {
      if (state.totalpayment.length === 0) {
        enqueueSnackbar("Payment terms required", {
          variant: "error",
        });
        return;
      } else {
        formValues = {
          ...formValues,
          totalpayment: state.totalpayment,
        };
      }
    }
    if (!state.tableError && !state.paymentTermsTableError) {
      props.onSubmit(formValues);
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            list={props.projects}
            component={Control.AutoCompleteInput}
            label="Project Code / Name"
            className="text-field"
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            // disabled
          />
          <Field
            name="PurchaseRequestNo"
            component={Control.AutoCompleteInput}
            label="Purchase Request No"
            list={props.list}
            className="text-field"
            onChange={(value) =>
              props.selectPurchaseReq && props.selectPurchaseReq(value)
            }
          />
          <Field
            component={Control.RenderDateField}
            initialValue={props.initialValues.Date}
            name="Date"
            label="Date"
            // disabled
          />
          <Field
            name="VendorCodeName"
            component={Control.AutoCompleteInput}
            list={props.VNlist}
            onChange={(value) =>
              props.SelectVendorName && props.SelectVendorName(value)
            }
            className="text-field"
            label="Vendor Code / Name"
          />
          <Field
            name="VendorGSTNo"
            component={Control.AutoCompleteInput}
            list={props.VendorGsts}
            label="Vendor GST No"
            className="text-field"
          />
          <Field
            name="FNCGSTNo"
            component={Control.RenderTextField}
            // disabled
            label="FNC GST No"
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            // disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="DeliveryAt"
            label="Delivery At"
          >
            <option value={""}></option>
            <option value="Customer WH">Customer WH</option>
            <option value={"Own Premises​"}>Own Premises​</option>
            <option value={"Central WH​​"}>Central WH​​</option>
          </Field>
          <Field
            name="OurRefNoDate"
            component={Control.RenderTextField}
            label="OurRef. No / Date"
          />
          <Field
            name="YourRefNoDate"
            component={Control.RenderTextField}
            label="Your Ref. No / Date"
          />
          <Field
            component={Control.RenderSelectField}
            name="TransportWorkOrder"
            label="Transport Work Order​"
          >
            <option value={""}></option>
            <option value="Yes">Yes</option>
            <option value="No">No​</option>
          </Field>
          <Field
            name="DeliveryPeriodFrom"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DeliveryPeriodFrom}
            label="Delivery Period From"
            className="text-field"
          />
          <Field
            name="DeliveryPeriodTo"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DeliveryPeriodTo}
            label="Delivery Period To"
            className="text-field"
          />
          <Field
            component={Control.RenderSelectField}
            name="Inspection"
            label="Inspection"
          >
            <option value={""}></option>
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No​</option>
          </Field>
        </div>
        <div className="excel ">
          <PaymentTermsTable
            paymentTermsTable={paymentTermsTable}
            payments={props.payments}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.paymentTermsTableError &&
                `${state.paymentTermsTableErrorKind}`}
            </p>
          </div>
        </div>
        <div className="field-container">
          <Field
            name="Currency"
            component={Control.RenderTextField}
            label="Currency"
            style={{ width: "28%", margin: "1rem" }}
            // disabled
          />
          <Field
            name="LDClause"
            component={Control.RenderTextField}
            label="LD Clause"
          />
          <Field
            name="PriceVariationClause"
            component={Control.RenderTextField}
            label="Price Variation Clause"
          />
          <Field
            name="PurchaseOrderValue"
            component={Control.RenderTextField}
            label="Purchase Order Value"
            type="number"
            // disabled
          />
        </div>

        <div className="excel">
          <PurchaseOrderTable
            storeTableData={storeTableData}
            materialDataCreate={props.materialDataCreate}
            materialsEditData={props.materialsEditData}
            materialsQty={props.materialsQty}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Purchase Order"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "Date",
    "CustomerName",
    "ProjectCodeName",
    "PurchaseRequestNo",
    "TransportWorkOrder",
    "VendorCodeName",
    "Warehouse",
    "DeliveryAt",
    "DeliveryPeriodFrom",
    "DeliveryPeriodTo",
    "Inspection",
    "Currency",
    "PurchaseOrderValue",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};
export default reduxForm({
  form: "PurchaseOrderForm",
  validate,
  enableReinitialize: true,
})(PurchaseOrderForm);
