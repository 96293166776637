import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useState, useEffect } from "react";

const WarehouseReceiptVoucher = ({
  materialsTable,
  poMaterials,
  materialsEditData,
  storeTableData,
  isColumnsAvailable,
}) => {
  const [loading, setLoading] = useState(true);
  const [inputFields, setInputFields] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  // create setting data
  useEffect(() => {
    setLoading(true);
    let values = [];
    if (materialsTable && poMaterials.length > 0) {
      for (let i = 0; i < materialsTable.length; i++) {
        const amt = (
          +materialsTable[i].AcceptQuantity * +poMaterials[i].NetRate +
          +poMaterials[i].CGSTValue +
          +poMaterials[i].SGSTValue +
          +poMaterials[i].IGSTValue
        ).toFixed(2);
        values = [
          ...values,
          {
            MaterialCode: materialsTable[i].MaterialCode,
            MaterialDescription: materialsTable[i].MaterialDescription,
            HSNSACCode: materialsTable[i].HSNSACCode,
            UOM: materialsTable[i].UOM,
            ReceivedQuantity: materialsTable[i].ReceivedQuantity,
            AcceptQuantity: materialsTable[i].AcceptQuantity,
            NetRate: poMaterials[i].NetRate,
            CGSTPercentage: poMaterials[i].CGSTPercentage,
            CGST: poMaterials[i].CGSTValue,
            SGSTPercentage: poMaterials[i].SGSTPercentage,
            SGST: poMaterials[i].SGSTValue,
            IGSTPercentage: poMaterials[i].IGSTPercentage,
            IGST: poMaterials[i].IGSTValue,
            ItemAmount: amt,
          },
        ];
      }
      setIsDisabled(true);
    } else if (materialsTable) {
      for (let i = 0; i < materialsTable.length; i++) {
        values = [
          ...values,
          {
            MaterialCode: materialsTable[i].MaterialCode,
            MaterialDescription: materialsTable[i].MaterialDescription,
            HSNSACCode: materialsTable[i].HSNSACCode,
            UOM: materialsTable[i].UOM,
            ReceivedQuantity: materialsTable[i].ReceivedQuantity,
            AcceptQuantity: materialsTable[i].AcceptQuantity,
            NetRate: 0,
            CGSTPercentage: 0,
            CGST: 0,
            SGSTPercentage: 0,
            SGST: 0,
            IGSTPercentage: 0,
            IGST: 0,
            ItemAmount: 0,
          },
        ];
      }
    }
    setInputFields(values);
    setLoading(false);
    onChange(values);
    // eslint-disable-next-line
  }, [materialsTable, poMaterials]);

  // edit setting data
  useEffect(() => {
    setLoading(true);
    let values = [];
    if (materialsEditData) {
      for (let i = 0; i < materialsEditData.length; i++) {
        values = [
          ...values,
          {
            MaterialCode: materialsEditData[i].MaterialCode,
            MaterialDescription: materialsEditData[i].MaterialDescription,
            HSNSACCode: materialsEditData[i].HSNSACCode,
            UOM: materialsEditData[i].UOM,
            ReceivedQuantity: materialsEditData[i].ReceivedQuantity,
            AcceptQuantity: materialsEditData[i].AcceptQuantity,
            NetRate: materialsEditData[i].NetRate,
            CGSTPercentage: materialsEditData[i].CGSTPercentage,
            CGST: materialsEditData[i].CGST,
            SGSTPercentage: materialsEditData[i].SGSTPercentage,
            SGST: materialsEditData[i].SGST,
            IGSTPercentage: materialsEditData[i].IGSTPercentage,
            IGST: materialsEditData[i].IGST,
            ItemAmount: materialsEditData[i].ItemAmount,
            id: materialsEditData[i].id,
          },
        ];
      }
      setInputFields(values);
      onChange(values);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [materialsEditData]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const updateValues = (index) => {
    const values = [...inputFields];
    let row = inputFields[index];
    if (+row.CGSTPercentage > 0) {
      row.SGSTPercentage = row.CGSTPercentage;
      row.IGSTPercentage = 0;
    } else if (+row.IGSTPercentage > 0) {
      row.SGSTPercentage = 0;
      row.CGSTPercentage = 0;
    }
    let amount = (+row.AcceptQuantity * +row.NetRate).toFixed(2);
    let csgst = ((+row.CGSTPercentage / 100) * +amount).toFixed(2);
    let igst = ((+row.IGSTPercentage / 100) * +amount).toFixed(2);
    row.CGST = csgst;
    row.SGST = csgst;
    row.IGST = igst;
    row.ItemAmount = (+amount + +csgst * 2 + +igst).toFixed(2);
    values[index] = row;
    setInputFields(values);
    onChange(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  return (
    <>
   
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              {isColumnsAvailable ? (
                <>
                  <tr>
                    <th rowSpan="2" className="head-cell">
                      Sl. No.
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Material Code
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Material Description
                    </th>
                    <th rowSpan="2" className="head-cell">
                      HSN / SAC Code
                    </th>
                    <th rowSpan="2" className="head-cell">
                      UOM
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Received Quantity
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Accepted Quantity
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Net Rate
                    </th>
                    <th colSpan="2" className="head-cell">
                      C-GST
                    </th>
                    <th colSpan="2" className="head-cell">
                      S-GST
                    </th>
                    <th colSpan="2" className="head-cell">
                      I-GST
                    </th>
                    <th rowSpan="2" className="head-cell">
                      Item Amount
                    </th>
                  </tr>
                  <tr>
                    <th>%</th>
                    <th>Value</th>
                    <th>%</th>
                    <th>Value</th>
                    <th>%</th>
                    <th>Value</th>
                  </tr>
                </>
              ) : (
                <tr>
                  <th rowSpan="2" className="head-cell">
                    Sl. No.
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Material Code
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Material Description
                  </th>
                  <th rowSpan="2" className="head-cell">
                    HSN / SAC Code
                  </th>
                  <th rowSpan="2" className="head-cell">
                    UOM
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Received Quantity
                  </th>
                  <th rowSpan="2" className="head-cell">
                    Accepted Quantity
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data2">{inputField.MaterialCode}</td>
                  <td className="table-data2">
                    {inputField.MaterialDescription}
                  </td>
                  <td className="table-data2">{inputField.HSNSACCode}</td>
                  <td className="table-data2">{inputField.UOM}</td>
                  <td className="table-data2">{inputField.ReceivedQuantity}</td>
                  <td className="table-data2">{inputField.AcceptQuantity}</td>
                  {isColumnsAvailable && (
                    <>
                      <td className="table-data2">
                        <input
                          style={{ width: "5rem" }}
                          name="NetRate"
                          value={inputField.NetRate}
                          type="number"
                          min={0}
                          step={0.01}
                          onChange={(event) => {
                            handleChangeInput(index, event);
                            updateValues(index);
                          }}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="table-data2">
                        <input
                          style={{ width: "5rem" }}
                          name="CGSTPercentage"
                          value={inputField.CGSTPercentage}
                          type="number"
                          min={0}
                          step={0.01}
                          onChange={(event) => {
                            handleChangeInput(index, event);
                            updateValues(index);
                          }}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="table-data2">{inputField.CGST}</td>
                      <td className="table-data2">
                        <input
                          style={{ width: "5rem" }}
                          name="SGSTPercentage"
                          value={inputField.SGSTPercentage}
                          type="number"
                          min={0}
                          step={0.01}
                          onChange={(event) => {
                            handleChangeInput(index, event);
                            updateValues(index);
                          }}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="table-data2">{inputField.SGST}</td>
                      <td className="table-data2">
                        <input
                          style={{ width: "5rem" }}
                          name="IGSTPercentage"
                          value={inputField.IGSTPercentage}
                          type="number"
                          min={0}
                          step={0.01}
                          onChange={(event) => {
                            handleChangeInput(index, event);
                            updateValues(index);
                          }}
                          disabled={isDisabled}
                        />
                      </td>
                      <td className="table-data2">{inputField.IGST}</td>
                      <td className="table-data4">{inputField.ItemAmount}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
   
    </>
  );
};

export default WarehouseReceiptVoucher;
