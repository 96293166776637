import React, { useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/MaterialSystem/WarehouseReturnVoucherTable";
import "./PurchaseOrderAmendmentForm.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const WarehouseReturnVoucherForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });

  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.storedData) {
      setState({ ...state, tableError: true });
    } else {
      props.onSubmit({ ...formValues, tableData: state.storedData });
    }
  };

  const storeTableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      console.log("data", data);
      try {
        let total = 0;
        data.forEach((item) => {
          total += +item.ItemAmount;
        });
        props.dispatch(
          change(
            "WarehouseReturnVoucherForm",
            "WarehouseReturnVoucherValue",
            (+total).toFixed(2)
          )
        );
      } catch (err) {
        console.error("err");
      }

      setState({
        ...state,
        storedData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            list={props.ProjectList}
            className="text-field"
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          <Field
            name="WarehouseIssueVoucherNo"
            component={Control.AutoCompleteInput}
            list={props.WIlist}
            className="text-field"
            onChange={(value) =>
              props.selectWarehouseIssue && props.selectWarehouseIssue(value)
            }
            label="Warehouse Issue Voucher"
          />
          <Field
            name="WorkOrderNo"
            component={Control.RenderTextField}
            label="Work Order No​"
            disabled
          />
          <Field
            name="WorkSalesNo"
            component={Control.RenderTextField}
            label="Sales Order No​"
            disabled
          />
          <Field
            name="Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues.Date}
            label="Date"
            className="text-field"
            disabled
          />
          <Field
            name="DateofIssue"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DateofIssue}
            label="Date of Issue"
            className="text-field"
            disabled
          />
          <Field
            name="VendorCodeName"
            component={Control.RenderTextField}
            label="Vendor Code / Name"
            disabled
          />
          <Field
            name="CustomerCodeName"
            component={Control.RenderTextField}
            label="CustomerCode/Name"
            disabled
          />
          <Field
            name="DateofReturn"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DateofReturn}
            label="Date of Return​"
          />
          <Field
            name="Remarks"
            component={Control.RenderTextField}
            label="Remarks​​"
            disabled
          />
          <Field
            name="WarehouseReturnVoucherValue"
            component={Control.RenderTextField}
            label="Warehouse Return Voucher Value"
            type="number"
            min={0}
            step={0.01}
            disabled
          />
        </div>
        <div className="excel ">
          <Table
            storeTableData={storeTableData}
            materialsDataEdit={props.materialsDataEdit}
            materialsDataCreate={props.materialsDataCreate}
            stocksDetails={props.stocksDetails}
            issuedMaterials={props.issuedMaterials}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="WareHouse Return"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["WarehouseIssueVoucherType"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "WarehouseReturnVoucherForm",
  validate,
  enableReinitialize: true,
})(WarehouseReturnVoucherForm);
