import "date-fns";
import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { useSnackbar } from "notistack";

export const RenderTimeField = ({
  label,
  input,
  initialValue,
  meta: { invalid, error },
  ...custom
}) => {
  // The first commit of Material-UI
  const { onChange } = input;

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSelectedDate(initialValue);
    }
  }, [initialValue]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
    setTouched(true);
  };

  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id="time-picker"
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        error={touched && invalid}
        helperText={touched && error}
        {...custom}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
