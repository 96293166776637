import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from "@material-ui/core";


const ApprovalAndRejectModal = ({
  showModal,
  formName,
  isApprove,
  handleModalAction,
  toggleModal,
}) => {

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleMsgChange = (event) => {
    setMessage(event.target.value);
  };

  const onYesClicked = () => {
    const data = isApprove
      ? { comment: message, status: "Approved" }
      : { comment: message, status: "Rejected" };
    setIsLoading(true);
    handleModalAction(data);
  };
  return (
    <div>
      <Dialog open={showModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> {isApprove ? "Approve" : "Reject"} {formName}</DialogTitle>
        {
          !isLoading ? (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  name="Message"
                  label="Message"
                  type="text"
                  fullWidth
                  onChange={handleMsgChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleModal} color="secondary">
                  Cancel
                </Button>
                <Button onClick={onYesClicked} style={{ color: "green" }}>
                  {isApprove ? "Approve" : "Reject"}
                </Button>
              </DialogActions>
            </>
          ) : (
            <div>
              <DialogContent  align="center">
                <CircularProgress style={{ color: "red" }}/>
              </DialogContent>
            </div>
          )
        }
        
      </Dialog>
    </div>
  )
};

export default ApprovalAndRejectModal