import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { useSnackbar } from "notistack";
import "../../components/helpers/form.style.css";

const MachineReceipt = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [currProject, setCurrProject] = useState("");

  const onSubmit = (formValues) => {
    if (formValues.ProjectCodeName === formValues.LastProjectCodeName) {
      enqueueSnackbar(`Last project can't be same`, {
        variant: "error",
      });
      setShow(!show);
      return;
    }

    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const checkShiftHours = (value) => {
    if (value > 24) {
      props.dispatch(change("MachineReceipt", "ShiftHours", 0));
      enqueueSnackbar(`Shift Hour cannot be more than 24`, {
        variant: "warning",
      });
    }
  };

  const checkNegativeValue = (value, fieldName) => {
    if (value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
      props.dispatch(change("MachineReceipt", fieldName, 0));
    }
  };

  const validate = (value) => {
    checkNegativeValue(value, "ShiftHours");
    checkNegativeValue(value, "LastMeterReading");
    checkNegativeValue(value, "BookValue");
    checkNegativeValue(value, "MachineIHCRate");
    checkNegativeValue(value, "AcquisitionValue");
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project code / Name"
            list={props.ProjectList}
            className="text-field"
            onChange={(value) => {
              props.selectProjectName?.(value);
              setCurrProject(value);
            }}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="MachineCodeName"
            list={props.MachineList}
            label="Machine Code / Name"
            className="text-field"
            onChange={(value) => {
              props.selectMachineProjectName?.(value);
            }}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="LastProjectCodeName"
            label="Last Project Code / Name"
            list={props.ProjectList.filter((i) => i !== currProject)}
            className="text-field"
          />
          <Field
            component={Control.RenderDatePastField}
            name="DateofReceipt"
            label="Date of Receipt"
            initialValue={props.initialValues.DateofReceipt}
          />
          <Field
            component={Control.RenderDatePastField}
            name="DateofCommissioning"
            label="Date of Commissioning"
            initialValue={props.initialValues.DateofCommissioning}
          />
          <Field
            component={Control.RenderTextField}
            name="MachineIHCRate"
            label="Machine IHC Rate"
            type="number"
            validate={[validate]}
            min={0}
            step={0.01}
          />
          <Field
            component={Control.RenderTextField}
            name="PurchaseValue"
            label="Purchase Value"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="AcquisitionValue"
            label="Acquisition Value​"
            validate={[validate]}
            type="number"
            min={0}
            step={0.01}
          />
          <Field
            component={Control.RenderSelectField}
            name="MeterFlag"
            label="Meter Flag​"
          >
            <option value=""></option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Field>
          <Field
            component={Control.RenderTextField}
            name="BookValue"
            label="Book Value​"
            type="number"
            min={0}
            step={0.01}
            validate={[validate]}
          />
          <Field
            component={Control.RenderDatePastField}
            name="PurchaseDate"
            label="Purchase Date"
            className="text-field"
            initialValue={props.initialValues.PurchaseDate}
          />
          <Field
            component={Control.RenderDatePastField}
            name="LastAcqsDate"
            className="text-field"
            label="Last Acqs Date"
            initialValue={props.initialValues.LastAcqsDate}
          />
          <Field
            component={Control.RenderSelectField}
            name="MinorMajor"
            label="Minor / Major"
          >
            <option value=""></option>
            <option value="Minor">Minor</option>
            <option value="Major">Major</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="MeterType"
            label="Meter Type"
          >
            <option value=""></option>
            <option value="Kilometer">Kilometer</option>
            <option value="Meter">Meter</option>
            <option value="Hour">Hour</option>
          </Field>

          <Field
            component={Control.RenderTextField}
            name="ShiftHours"
            label="Shift Hours"
            type="number"
            min={0}
            validate={[checkShiftHours, validate]}
          />
          <Field
            component={Control.RenderTextField}
            name="LastMeterReading"
            label="Last Meter Reading"
            type="number"
            min={0}
            validate={[validate]}
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Machine Recepit"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "MachineCodeName",
    "DateofReceipt",
    "DateofCommissioning",
    "MachineIHCRate",
    "PurchaseValue",
    "AcquisitionValue",
    "MeterFlag",
    "BookValue",
    "PurchaseDate",
    "LastAcqsDate",
    "MinorMajor",
    "MeterType",
    "ShiftHours",
    "LastMeterReading",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachineReceipt",
  validate,
  enableReinitialize: true,
})(MachineReceipt);
