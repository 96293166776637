import React, { useState } from "react";
import { RenderTableDateField } from "../formcomponents/TableDateField";

const FilterByDate = ({ handleFilterByDate }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onFromDateChange = (value) => {
    setFromDate(value);
    if (toDate && value) handleFilterByDate(value, toDate);
  };

  const onToDateChange = (value) => {
    setToDate(value);
    if (fromDate && value) handleFilterByDate(fromDate, value);
  };

  return (
    <div>
      <RenderTableDateField
        label="From Date"
        initialValue={fromDate}
        onChange={onFromDateChange}
        style={{ marginRight: "1rem" }}
      />
      <RenderTableDateField
        label="To Date"
        initialValue={toDate}
        onChange={onToDateChange}
      />
    </div>
  );
};

export default FilterByDate;
