/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ProjectCircular from "../../../forms/SalesAccountingSystem/ProjectCircularForm";
import { projectCircular } from "../../../redux/actions/SalesAcounts";
import {
  allBusinessUnit,
  allCurrency,
  allCustomer,
  allWareHouse,
  allCostCentre,
} from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import pick from "lodash/pick";

let AllCurrency = [];
let AllCustomer = [];
let AllWareHouse = [];
let AllCostCentre = [];
let allCompany = [];
export const ProjectCircularCreate = () => {
  const dispatch = useDispatch();
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const user = useSelector((store) => store.userRoot);
  const history = useHistory();
  const [businessUnitlist, setBusinessUnitlist] = useState([]);
  const [currencylist, setCurrencylist] = useState([]);
  const [customerNamelist, setcustomerNamelist] = useState([]);
  const [wareHouselist, setWareHouselist] = useState([]);
  const [costCentrelist, setCostCentrelist] = useState([]);
  const [warehouseGsts, setWarehouseGsts] = useState([]);
  const [costCenterGsts, setCostCenterGsts] = useState([]);
  const [customerGsts, setCustomerGsts] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const [formDetail, setformDetail] = useState({});

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }

    dispatch(projectCircular(formValues, history));
  };

  useEffect(() => {
    dispatch(allBusinessUnit());
    dispatch(allCurrency());
    dispatch(allCustomer());
    dispatch(allWareHouse());
    dispatch(allCostCentre());
  }, [dispatch]);

  useEffect(() => {
    if (store.AllCostCentre) {
      AllCostCentre = store.AllCostCentre;
      let newData = AllCostCentre.filter((item) => item.Tags === "Active");
      let CCList = newData.map((item) => item.CostCenterName);
      setCostCentrelist(CCList);
    }
    if (store.AllCurrency) {
      AllCurrency = store.AllCurrency;
      let newData = AllCurrency.filter((item) => item.Tags == "Active");
      let CList = newData.map(
        (item) => item.CurrencyCode + "-" + item.CurrencyName
      );
      setCurrencylist(CList);
    }
    if (store.AllWareHouse) {
      AllWareHouse = store.AllWareHouse;
      let newData = AllWareHouse.filter((item) => item.Tags == "Active");
      let WHlist = newData.map((item) => item.WareHouseName);
      setWareHouselist(WHlist);
    }
    if (store.AllCustomer) {
      AllCustomer = store.AllCustomer;
      let newData = AllCustomer.filter((item) => item.Tags == "Active");
      let CNlist = newData.map((item) => item.CustomerName);
      setcustomerNamelist(CNlist);
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let newData = data.filter((item) => item.Tags == "Active");
        const filteredData = newData.map((item) => item.CompanyName);
        setCompanyList(filteredData);
        allCompany = data;
      } catch (err) {
        console.error(err);
      }
    }

    getCompanyDetails();
  }, [
    store.AllCostCentre,
    store.AllCurrency,
    store.AllCustomer,
    store.AllWareHouse,
    store.AllBusinessUnit,
  ]);

  useEffect(() => {
    setBusinessUnitlist(
      store.AllBusinessUnit.map(
        (i) => i.BusinessUnit + "-" + i.BusinessDescription
      )
    );
  }, [store.AllBusinessUnit]);

  const findGst = async (value, findFor) => {
    debugger;
    let id;
    if (findFor === "wareHouse")
      id = AllWareHouse.find((item) => item.WareHouseName === value);
    else if (findFor === "costCentre")
      id = AllCostCentre.find((item) => item.CostCenterName === value);
    else if (findFor === "customer")
      id = AllCustomer.find((item) => item.CustomerName === value);
    else if (findFor === "companyDetails")
      id = allCompany.find((item) => item.CompanyName === value);
    try {
      const payload = await axios.get(`commonMaster/${findFor}/${id.id}`);
      console.log(payload);
      const gsts = payload.data.Gsts;
      const gstRegNos = gsts.map((item) => item.GSTRegNo);
      if (findFor === "wareHouse") setWarehouseGsts(gstRegNos);
      else if (findFor === "costCentre") {
        setCostCenterGsts(gstRegNos);
        const payload = await axios.post(
          `/commonMaster/warehouse/getWarehouseByCostcenter`,
          {
            CostCenterName: id.CostCenterName,
          }
        );
        console.log(payload);
        let warehouse = payload.data.map((item) => item.WareHouseName);

        setWareHouselist(warehouse);
      } else if (findFor === "customer") setCustomerGsts(gstRegNos);
      else if (findFor === "companyDetails") {
        setCompanyGsts(gstRegNos);

        if (user.user.first_name === "admin") {
          const payload = await axios.post(
            `/commonMaster/businessUnit/getBusinessUnitByCompany`,
            {
              CompanyName: id.CompanyName,
            }
          );
          let businessUnit = payload.data.map(
            (item) => item.BusinessUnit + "-" + item.BusinessDescription
          );

          setBusinessUnitlist(businessUnit);
        } else {
          setformDetail({
            ...formDetail,
            CompanyName: user.user?.company,
            BusinessUnit: user.workingOn.BusinessUnit,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <BreadCrumbs
        name="Project Circular Create"
        linkName1="Project Circular Dashboard"
        to1="/salesAccounting/projectCircular"
      />

      <h3 style={{ textAlign: "center" }}>Project Circular</h3>
      <ProjectCircular
        CCList={costCentrelist}
        CList={currencylist}
        CNList={customerNamelist}
        BUList={businessUnitlist}
        WHList={wareHouselist}
        warehouseGsts={warehouseGsts}
        costCenterGsts={costCenterGsts}
        customerGsts={customerGsts}
        companyGsts={companyGsts}
        companyList={companyList}
        findGst={findGst}
        edit={false}
        onSubmit={onSubmit}
        initialValues={pick(formDetail, "BusinessUnit", "CompanyName")}
        user={user}
      />
    </div>
  );
};
