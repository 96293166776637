import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccessRequest from "../../../forms/AccessControl/AccessRequest";
import axios from "../../../components/apis/axios";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const AccessRequestCreate = () => {
  const [employeeCode, setEmployeeCode] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [Company, setCompany] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const history = useHistory();

  const onSubmit = async (formValues) => {
    console.log({ submit: formValues });
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }

    try {
      await axios.post("/users/role/create", formValues);
      history.push("/accessControl/accessRequest");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        // const { data } = await axios.get("/users/getNewUsers/new");

        // let employee = data.map(
        //   (item) =>
        //     item.EmployeeCode + "-" + item.FirstName + " " + item.LastName
        // );
        // setEmployeeCode(employee);
        console.log(data);
        setEmployeeList(data);
      } catch (err) {}
    }
    async function fetchRole() {
      try {
        const list = await (await axios.get("/roles")).data;
        setRoleList(list);
      } catch (err) {}
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let newData = data.filter((item) => item.Tags == "Active");
        const filteredData = newData.map((item) => item.CompanyName);
        setCompany(filteredData);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
    fetchRole();
    getCompanyDetails();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Access Control Create"
        linkName1="Access Control Dashboard"
        to1="/accessControl/accessRequest"
      />

      <h3 style={{ textAlign: "center" }}>Access Request</h3>
      <AccessRequest
        onSubmit={onSubmit}
        employeeCode={employeeCode}
        employeeList={employeeList}
        Company={Company}
        roleList={roleList}
        isEdit={false}
      />
    </div>
  );
};
