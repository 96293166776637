import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "./main.css";

const MachineRelease = (props) => {
  const [show, setShow] = useState(false);
  const [project, setProject] = useState();

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project code / Name"
            list={props.ProjectList}
            className="text-field"
            onChange={(p) => props.onProjectSelect(p)}
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            initialValue={props.initialValues.CustomerName}
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="MachineCodeName"
            list={props.MachineList}
            label="Machine Code / Name"
            className="text-field"
            onChange={(p) => props.onMachineSelect(p)}
          />
          <box className="text-field"></box>

          <Field
            component={Control.RenderDatePastField}
            name="ReleaseDate"
            label="Release Date"
            className="text-field"
            disableFuture
            // initialValue={props.initialValues.ReleaseDate}
            onChange={(p) => props.onReleaseDateChange(p)}
          />
          <Field
            component={Control.RenderDatePastField}
            name="LastLogSheetDate"
            label="Last Log Sheet Date"
            className="text-field"
            initialValue={props.initialValues.LastLogSheetDate}
            disabled
          />

          <Field
            component={Control.RenderTextField}
            name="TimeRequiredtoRelease"
            label="Time Required to Release (in Days)"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="LastLogSheetMeterReading"
            label="Last Log Sheet Meter Reading"
            type="text"
            className="text-field"
            initialValue={props.initialValues.LastLogSheetMeterReading}
            disabled
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Machine Recepit"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "MachineCodeName",
    "CustomerName",
    "ReleaseDate",
    "LastLogSheetMeterReading",
    "LastLogSheetDate",
    "TimeRequiredtoRelease",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachineRelease",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(MachineRelease);
