import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { DropzoneArea } from "material-ui-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import axios from "../../components/apis/axios";

export const UploaderV2 = ({
  previousFiles,
  storeUploadedFiles,
  meta: { touched, invalid, error },
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [previouslyUploaded, setPreviouslyUploaded] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    storeUploadedFiles(uploadedFiles);
  }, [uploadedFiles, storeUploadedFiles]);

  useEffect(() => {
    if (previousFiles) {
      setPreviouslyUploaded(previousFiles);
      setUploadedFiles(previousFiles);
    }
  }, [previousFiles]);

  const handleFilesUpload = async (file) => {
    try {
      if (file) {
        enqueueSnackbar("File Uploading", {
          variant: "info",
        });
        console.log("files", JSON.stringify(file));
        for (const item in file) {
          let formData = new FormData();
          console.log(file[item]);
          formData.append("file", file[item]);
          let response = await axios.post("/files/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response);
          setUploadedFiles([
            ...uploadedFiles,
            { uploaded: true, file, id: response.data.id },
          ]);
          enqueueSnackbar("File Uploaded Successfully", {
            variant: "success",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("File Upload Failed", {
        variant: "error",
      });
      console.log(err);
    }
  };

  const deleteFiles = (file) => {
    if (file) {
      const temp = { ...file };
      const files = [...uploadedFiles];
      const indexOfFile = files.indexOf(
        (item) => item.file.lastModified === temp.lastModified
      );
      files.splice(indexOfFile, 1);
      console.log(files);
      setUploadedFiles([...files]);
      enqueueSnackbar("File Removed", {
        variant: "info",
      });
    }
  };

  const removePreviousFiles = (file) => {
    const files = [...uploadedFiles];
    let index = files.indexOf((item) => item === file);
    files.splice(index, 1);
    setUploadedFiles([...files]);

    // removing from previous files
    const pFiles = [...previouslyUploaded];
    index = pFiles.find((item) => item === file);
    pFiles.splice(index, 1);
    setPreviouslyUploaded([...pFiles]);

    enqueueSnackbar("Previously uploaded file Removed", {
      variant: "info",
    });
  };

  return (
    <>
      <div>
        <DropzoneArea
          acceptedFiles={["image/*", ".docx", ".pdf", ".doc", ".zip"]}
          initialFiles={previousFiles}
          maxFileSize={100048576}
          error={touched && invalid}
          helperText={touched && error}
          onDrop={handleFilesUpload}
          onDelete={deleteFiles}
          showAlerts={false}
        />
      </div>
      <div>
        {previouslyUploaded && previouslyUploaded.length > 0 && (
          <>
            <h4>Previous Files</h4>
            {previouslyUploaded.map(
              (file, idx) =>
                file && (
                  <div style={{ height: "4rem" }} key={idx}>
                    <a href={file.path}>{file.name}</a>
                    <IconButton
                      onClick={() => {
                        removePreviousFiles(file);
                      }}
                    >
                      <DeleteIcon fontSize="large" />
                    </IconButton>
                  </div>
                )
            )}
          </>
        )}
      </div>
    </>
  );
};