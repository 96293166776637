import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkmenJoiningReportTable from "../../../components/WorkforceManagementDisplay/WorkmenJoiningReportTable";
import SearchOptionProject from "../../../components/ReportsComponent/SearchByProject";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import SearchOptionMachine from "../../../components/ReportsComponent/SearchOptionWorkmen";
import { filterAllWorkmenJoining } from "../../../redux/actions/ProjectWorkforceManagementAction";
import FilterByDate from "../../../components/ReportsComponent/FilterByDate";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

export const WorkMenJoiningReport = (props) => {
  const [lists, setLists] = useState([]);
  const [filters, setFilters] = useState({});

  const [filterSelected, setFilterSelected] = useState("");

  const classes = useStyle();
  const dispatch = useDispatch();

  const store = useSelector((store) => store);
  const workmenJoining = useSelector(
    (store) => store.ProjectWorkforceManagementRoot?.FilteredworkmenJoining
  );
  const projects = useSelector(
    (store) => store.SalesAcountRoot?.AllProjectCircular
  );
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === "siteProjectHead" ||
    store.userRoot.workingOn.Role === "financeFirst"
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === "siteAdminHead" ||
    store.userRoot.workingOn.Role === "siteProjectHead" ||
    store.userRoot.workingOn.Role === "projectCoOrdinator" ||
    store.userRoot.workingOn.Role === "financeFirst" ||
    store.userRoot.workingOn.Role === "financeSecond" ||
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === "companyHead" ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    dispatch(filterAllWorkmenJoining(filters));
  }, [filters]);
  console.log(filters);

  useEffect(() => {
    let lists = props.lists;
    if (lists) {
      lists = lists.reverse();
      setLists(lists);
      console.log(lists);
    }
  }, [props.lists]);

  //     const fetch = useCallback(async () => {
  //       try {
  //         let params = {};

  //         if (searchString) params.query = searchString;

  //         const response = await axios.get("/workforceManagement/reports/workmenJoining", {
  //           params,
  //         });
  //         let data = response.data;
  //         data.forEach((element) => {
  //           element.created_at = new Date(element.created_at).toLocaleString();
  //         });
  //         console.log(data)
  //         setLists(data);
  //       } catch (err) {
  //         if (err.response.status === 401) {
  //           dispatch(userLogout(history));
  //         }
  //       }
  //     }, [dispatch,  history, searchString]);
  //     useEffect(() => {
  //       fetch();
  //     }, [fetch, searchString]);

  // const onSearch = (value) => {
  //   setSearchString(value);
  // };
  const handleFilterByDate = (fromDate, toDate) => {
    let data = props.lists;
    const filtered = data?.filter(
      (item) =>
        new Date(item.WorkmenDOJ) >= new Date(fromDate) &&
        new Date(item.WorkmenDOJ) <= new Date(toDate)
    );
    setLists(filtered);
  };

  const handleFilterSelect = (event) => {
    setFilterSelected(event.target.value);
  };

  return (
    <>
      <BreadCrumbs
        name="Workmen Joining Reports"
        linkName1="All Workforce Management Reports"
        to1="/workforceManagement/othersReports"
      />
      <Paper className={classes.Container}>
        <h2 className="text-center">Workmen Joining Reports</h2>
        <div style={{ display: "flex" }} className={classes.row}>
          <SearchOptionProject
            handleProjectChange={(project) =>
              setFilters({ ...filters, project })
            }
          />
          <SearchOptionMachine
            handleWorkmenChange={(workmen) =>
              setFilters({ ...filters, workmen: workmen?.WorkmenName })
            }
          />
        </div>
        <div
          id="filter-select"
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl style={{ margin: "1rem" }}>
            <InputLabel>Filter</InputLabel>
            <Select
              name="filter"
              value={filterSelected}
              onChange={(event) => {
                handleFilterSelect(event);
              }}
              style={{ width: "10rem" }}
            >
              <MenuItem value={"byDate"}>By Date</MenuItem>
            </Select>
          </FormControl>
          {filterSelected === "byDate" ? (
            <FilterByDate handleFilterByDate={handleFilterByDate} />
          ) : (
            ""
          )}
        </div>

        <WorkmenJoiningReportTable
          lists={workmenJoining}
          tablecol1="WorkmenJoiningNo"
          heading="Workmen Joining Reports"
          label1="Workmen Joining No"
          label2="Workmen Name"
          viewLink="/workforceManagement/workmenJoining/view"
          button={button}
          visibleIcon={visibleIcon}
        />
      </Paper>
    </>
  );
};
