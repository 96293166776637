import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import ProjectAddRoleView from "../../../Tables/AccessControl/ProjectAddRoleView";

export const AccessRequestView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  // const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(`/users/${match.params.id}`);
        console.log("user ->", data);
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  // useEffect(() => {
  //   if (store.userRoot) {
  //     setPost(store.userRoot.workingOn.Role);
  //   }
  // }, [store.userRoot]);

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Access Control View"
        linkName1="Access Control Dashboard"
        to1="/accessControl/accessRequest"
      />
      <Paper className={classes.Container}>
        <ComapnyHeader />
        <div>
          <h3 className={classes.Heading}>Access Request</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by &&
            ` ${data.created_by.first_name} ${data.created_by.last_name}`
          }`}</h6>
          <h6 className="text-right">{`Created Date : ${
            data.created_by && ` ${data.created_at}`
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Employee Code:</h6>
              <p>{data.EmployeeCode}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Company:</h6>
              <p>{data.company}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Email:</h6>
              <p>{data.email}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>First name:</h6>
              <p>{data.first_name}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Last name:</h6>
              <p>{data.last_name}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Phone Number:</h6>
              <p>{data.phone_number}</p>
            </Paper>
          </div>
          <ProjectAddRoleView working={data.working_on} />
        </Box>
      </Paper>
    </div>
  );
};
