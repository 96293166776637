import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import HrDailyAttendance from "../../../forms/ProjectHRManagement/HrDailyAttendance/HrDailyAttendance";
import { searchHrDailyAttendanceById } from "../../../redux/actions/HRManagementActions";

export const HrDailyAttendancEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchHrDailyAttendanceById(match.params.id);
        setData(data);
    }

    fetch();
  }, [match.params.id]);

  const onSubmit = async (formValues) => {
    await axios.put(`/hr-management/daily-attendance/${match.params.id}`, formValues);
    history.push("/project-hr-management/daily-attendance");
  };

  return (
    <div>
      <Breadcrumbs
        name="Daily Attendance Edit"
        linkName1="Daily Attendance Dashboard"
        to1="/project-hr-management/daily-attendance"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Daily Attendance
      </h3>
      <div className="line"></div>
      <HrDailyAttendance
        initialValues={{
            ...data,
            CostCentre: data?.project?.CostCentre,
            CustomerName: data?.project?.CustomerName,
            project: data?.project?.ProjectCode,
            projectData: data?.project,
            date: data?.date,
            tableData: data?.attendanceTable
        }}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
