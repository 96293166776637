import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineGroup from "../../../forms/CommonMaster/MachineGroup";
import { useHistory } from "react-router-dom";
import {
  editMachineGroup,
  searchMachineGroupById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const MachineGroupEdit = (props) => {
  const [data, setData] = useState({});
  // const [formDetail, setFormDetail] = React.useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [machineClass, setMachineClass] = React.useState({});
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachineGroupById(match.params.id));
  }, [match.params.id, dispatch]);
  // useEffect(() => {
  //   if (store.SingleMachineGroup) {
  //     setData(store.SingleMachineGroup);
  //   }
  // }, [store.SingleMachineGroup]);

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/commonMaster/machineGroup/${match.params.id}`
        );
        setData(data);
        data.RequiredOn = new Date(
          new Date(data.RequiredOn) + " UTC"
        ).toDateString();
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [store.userRoot, dispatch, history, match.params.id]);

  const onSubmit = (formValues, history) => {
    dispatch(editMachineGroup(formValues, props.match.params.id, history));
  };

  React.useEffect(() => {
    async function MachineClass() {
      try {
        const { data } = await axios.get("/commonMaster/machineClass");
        let mapedData = data.map((item) => item.MachineClassCode);

        setMachineClass(mapedData);
      } catch (err) {
        alert(err);
      }
    }
    MachineClass();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Machine Group Edit"
        linkName1="Machine Group Dashboard"
        to1="/commonMaster/MachineGroup"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Group Edit
      </h3>
      <div className="line"></div>
      <MachineGroup
        machineClass={machineClass}
        initialValues={pick(
          data,
          "MachineGroupCode",
          "MachineGroupDes",
          "MachineGroupLongDes",
          "MachineClassCode",
          "MachineClassDes",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
