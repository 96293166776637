import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const Index = (props) => {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="email"
            label="Enter Your Email"
            type="email"
          />
          {props.success === false && (
            <div style={{ color: "red" }}>email not found !</div>
          )}
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Forgot Password"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = ["email"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "Index",
  validate,
  enableReinitialize: true,
})(Index);
