import React, { useState, useEffect } from "react";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";

const WarehouseReturnVoucher = ({
  materialsDataCreate,
  stocksDetails,
  materialsDataEdit,
  storeTableData,
  issuedMaterials,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      No: 0,
      MaterialCode: "",
      MaterialDescription: "",
      UOM: "",
      ActivityMachineSelect: "",
      ActivityMachineDescription: "",
      ReturnQty: 0,
      AcceptedQty: 0,
      NetRate: 0,
      ItemAmount: 0,
      id: "",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [materials, setMaterials] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let values = [];
    setLoading(true);
    if (materialsDataCreate && stocksDetails) {
      for (let i = 0; i < materialsDataCreate.length; i++) {
        values = [
          ...values,
          {
            No: materialsDataCreate[i].SerialNo,
            MaterialCode: materialsDataCreate[i].MaterialCode,
            MaterialDescription: materialsDataCreate[i].MaterialDescription,
            UOM: materialsDataCreate[i].UOM,
            ActivityMachineSelect: materialsDataCreate[i].ActivityMachineSelect,
            ActivityMachineDescription:
              materialsDataCreate[i].ActivityMachineDescription,
            ReturnQty: 0,
            AcceptedQty: 0,
            NetRate: stocksDetails[i].NetRate,
            ItemAmount: 0,
          },
        ];
      }
      setMaterials(materialsDataCreate);
      setLoading(false);
      setInputFields(values);
      onChange(values);
    }
    // eslint-disable-next-line
  }, [materialsDataCreate, stocksDetails]);

  useEffect(() => {
    if (materialsDataEdit) {
      setInputFields([...materialsDataEdit]);
      onChange(materialsDataEdit);
      setLoading(false);
      console.log("issuedMaterials", issuedMaterials);
      setMaterials(issuedMaterials);
    }
    // eslint-disable-next-line
  }, [materialsDataEdit, issuedMaterials]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({ error: true, errorKind: "some input field is empty" });
    }
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    console.log(index);
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    const input = event.target.value;
    const fieldName = event.target.name;
    let row = inputFields[index];
    const issueQty = materials.find(
      (item) => item.MaterialCode === row.MaterialCode
    ).ActualIssueQty;
    console.log(materials);
      if (event.target.value < 0) {
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
    if (+issueQty >= +input) {
      row[fieldName] = input;
      row.ItemAmount = (+row.AcceptedQty * +row.NetRate).toFixed(2);
      values[index] = row;
      if (fieldName === "AcceptedQty" && +row.AcceptedQty > row.ReturnQty) {
        enqueueSnackbar(
          `Accepted Quantitiy cannot be more than Return Quantity`,
          {
            variant: "error",
          }
        );
        return;
      }
      setInputFields(values);
      onChange(values);
    } else {
      enqueueSnackbar(`Quantity cannot be more than Issue Qty`, {
        variant: "error",
      });
      return;
    }
  };

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };
  return (
    <div className="tableContainer">
    
        <table>
          <thead className="table-head">
            <tr>
              <th className="head-cell">Sl. No.</th>
              <th className="head-cell">Material Code</th>
              <th className="head-cell">Material Description</th>
              <th className="head-cell">UOM</th>
              <th className="head-cell">Activity/Machine</th>
              <th className="head-cell">Activity/Machine Description</th>
              <th className="head-cell">Return Qty</th>
              <th className="head-cell">Accepted Qty</th>
              <th className="head-cell">Net Rate</th>
              <th className="head-cell">Item Amount</th>
            </tr>
          </thead>
          <tbody>
            {inputFields.map((inputField, index) => (
              <tr key={index}>
                <td className="table-data1">{index + 1}</td>
                <td className="table-data2">{inputField.MaterialCode}</td>
                <td className="table-data2">{inputField.MaterialDescription}</td>
                <td className="table-data2">{inputField.UOM}</td>
                <td className="table-data2">
                  {inputField.ActivityMachineSelect}
                </td>
                <td className="table-data2">
                  {inputField.ActivityMachineDescription}
                </td>
                <td className="table-data2">
                  <input
                    type="number"
                    name="ReturnQty"
                    value={inputField.ReturnQty}
                    min={0}
                    step={0.01}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      fixedDecimal(index, event, "ReturnQty");
                    }}
                    style={{ width: "5rem" }}
                  />
                </td>
                <td className="table-data2">
                  <input
                    name="AcceptedQty"
                    value={inputField.AcceptedQty}
                    type="number"
                    min={0}
                    step={0.01}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      fixedDecimal(index, event, "AcceptedQty");
                    }}
                    style={{ width: "5rem" }}
                  />
                </td>
                <td className="table-data2">{inputField.NetRate}</td>
                <td className="table-data4">{inputField.ItemAmount}</td>
                <td>
                  {index === 0 ? (
                    <IconButton>
                      <RemoveCircleIcon className="width5" color="disabled" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                      <RemoveCircleIcon className="width5" color="secondary" />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    
    </div>
  );
};

export default WarehouseReturnVoucher;
