import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";

const Account = (props) => {
  const [show, setShow] = useState(false);
  const [subAccountTypeList, setSubAccountTypeList] = useState([]);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  const handleAccountTypeChange = (value) => {
    let temp = [];
    if (value === "Equity") {
      temp = ["ShareHolder's Fund", "Reserve"];
    } else if (value === "Liabilities") {
      temp = ["Non-Current Liabilities", "Current Liabilities"];
    } else if (value === "Assets") {
      temp = ["Non-Current Assets", "Current Assets"];
    } else if (value === "Income") {
      temp = ["Revenue From Operation", "Other Income"];
    } else if (value === "Expenses") {
      temp = ["Stock", "Direct Expenses", "Indirect Expenses"];
    }
    setSubAccountTypeList(temp);
  };
  
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="AccountCode"
            label="Account Code"
          />
          <Field
            component={Control.RenderTextField}
            name="AccountDes"
            label="Account Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="AccountType"
            label="Account Type"
            className="text-field"
            list={[
              " ",
              "Equity",
              "Liabilities",
              "Assets",
              "Income",
              "Expenses",
            ]}
            onChange={(value) => handleAccountTypeChange(value)}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="AccountSubType"
            label="Sub Account Type"
            className="text-field"
            list={subAccountTypeList}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Ledger"
            label="Ledger"
            className="text-field"
            list={[
              " ",
              "Capital",
              "Cash & Bank",
              "Customer",
              "Personnel",
              "Revenue",
              "Subcontractor",
              "Supplier",
            ]}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="ActivityCode"
            label="Activity Code"
            list={props.activity}
            className="text-field"
            onChange={(value) =>
              props.SelectActivity && props.SelectActivity(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="ActivityDes"
            label="Activity Description"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="CostHead"
            label="Cost Head"
            className="text-field"
            list={[
              " ",
              "Machinery - Labour",
              "Machinery - Overhead",
              "Machinery - Material",
              "Works – Direct Labour ",
              "Works – SC Labour",
              "Works – Overhead",
            ]}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Account Tag"
            className="text-field"
            list={[" ", "Active", "Inactive"]}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Account"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "AccountDescription",
    "AccountType",
    "EquityAndLiabilities",
    "Assets",
    "Ledger",
    "AccountTag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "account",
  validate,
  enableReinitialize: true,
})(Account);