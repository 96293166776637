const initialState = {
  user: {},
  isAuthenticated: false,
  workingOn: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_AUTH":
      return {
        user: action.payload,
        isAuthenticated: true,
        workingOn: null,
      };
    case "SET_USER_WORKING_ON":
      return {
        ...state,
        workingOn: action.payload,
      };
    case "DELETE_USERS_DATA":
      return {
        user: {},
        isAuthenticated: false,
        workingOn: null,
      };
    default:
      return state;
  }
};

export default userReducer;
