import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  // searchRecoveryTypeById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const RecoveryTypeView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(`/commonMaster/recoveryType/${match.params.id}`
        );
        
        setData(data);
        data.RequiredOn = new Date(
          new Date(data.RequiredOn) + " UTC"
        ).toDateString();
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [store.userRoot, dispatch, history, match.params.id]);

  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("recoveryType", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <>
      <Breadcrumbs
        name="Recovery Type View"
        linkName1="Recovery Type Dashboard"
        to1="/commonMaster/recoveryType"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Recovery Type</h3>
          <h6>{`Created by ${data.created_by
            ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
            : ""
            }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.RecoveryTypeCode && (
              <Paper className={classes.paper}>
                <h6>Recovery Type Code:</h6>
                <p>{data.RecoveryTypeCode}</p>
              </Paper>
            )}

            {data.RecoveryType && (
              <Paper className={classes.paper}>
                <h6>Recovery Type:</h6>
                <p>{data.RecoveryType}</p>
              </Paper>
            )}

            {data.AccountCode && (
              <Paper className={classes.paper}>
                <h6>Acount Code:</h6>
                <p>{data.AccountCode}</p>
              </Paper>
            )}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};