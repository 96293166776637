import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import RoleList from "../../../components/helpers/RoleList";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import DataGrid from "../../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";
import moment from "moment";

export const TransferOrder = () => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/hr-management/transfer-order", {
        params: {
          page: 1,
          ...readAllFromQueryParams(search),
        },
      });
      const newData = data.data;
      setLists(newData);
      setPaginatedResults({ ...data, data: newData });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, history, search]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      valueFormatter: ({ value }) =>
        `${value?.employee.EmployeeCode} - ${value?.employee?.FirstName} ${value?.employee?.LastName}`,
    },
    {
      field: "toProject",
      headerName: "To Project",
      valueFormatter: ({ value }) =>
        `${value?.ProjectCode} - ${value?.ProjectShortDescription}`,
    },
    {
      field: "reportEmployee",
      headerName: "Repoting Employee",
      valueFormatter: ({ value }) =>
        `${value?.employee?.EmployeeCode} - ${value?.employee?.FirstName} ${value?.employee?.LastName}`,
    },
    {
      field: "reportingDate",
      headerName: "Reporting Date",
      valueFormatter: ({ value }) => moment(value).format("DD-MM-YYYY"),
    },
  ];

  return (
    <>
      <Breadcrumbs name="Transfer Order Dashboard" />
      <DataGrid
        loading={loading}
        columns={columns}
        rows={lists}
        heading="Transfer Order"
        fetch={fetch}
        paginateResults={paginatedResults}
        createLink="/project-hr-management/transfer-order/create"
        viewLink="/project-hr-management/transfer-order/view"
        editLink="/project-hr-management/transfer-order/edit"
        DeleteLink="/hr-management/transfer-order/delete"
      />
    </>
  );
};
