import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import { createBenefit } from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import Benefit from "../../../forms/CommonMaster/Benefits/Benefit";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../components/apis/axios";
import { getNumberFromString } from "../../../utils/common";

export const BenefitsCreate = () => {
  const [newBenefitCode, setNewBenefitCode] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(createBenefit(formValues, history));
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("/commonMaster/benefits");
        if (data.length > 0) {
          const lastBenefitCode = data[0]?.benefitCode;
          if (lastBenefitCode) {
            const bnenefitCodeNumber = getNumberFromString(lastBenefitCode);
            let newBenefitCodeValue = "";
            if (bnenefitCodeNumber?.toString()?.length === 1) {
              newBenefitCodeValue = `BNF0${bnenefitCodeNumber + 1}`;
            } else {
              newBenefitCodeValue = `BNF${bnenefitCodeNumber + 1}`;
            }
            setNewBenefitCode(newBenefitCodeValue);
          }
        } else {
          setNewBenefitCode("BNF01");
        }
      } catch (error) {
        //
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Benefits Create"
        linkName1="Benefits Dashboard"
        to1="/commonMaster/benefits"
      />
      <FormHeading label="Benefits" />
      <Benefit
        initialValues={{
          benefitCode: newBenefitCode,
        }}
        onSubmit={onSubmit}
      />
    </div>
  );
};
