import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ScrutinyEmployeeOtherForm from "../../../../forms/AccountingSystem/Scrutiny/ScrutinyEmployeeOtherForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let AllPaymentRequest = [];

export const ScrutinyEmployeeOtherEdit = (props) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [paymentDetails, setPaymentDetails] = useState(null);

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    const response = await axios.put(
      `accountingSystem/scrutinyEmployeeeAndOthers/${props.match.params.id}`,
      formValues
    );
    console.log(response);
    history.push("/accountingSystem/scrutinyEmployeeeAndOthers/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/scrutinyEmployeeeAndOthers/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/scrutinyEmployeeeAndOthers/");
        setformDetail(data);
        setPaymentDetails(data.paymentDetails);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectPaymentRequest = async (PaymentRequestNo) => {
    let detail = AllPaymentRequest.find(
      (item) => item.PaymentRequestNo === PaymentRequestNo
    );
    if (detail) {
      let { data } = await axios.get(
        `/accountingSystem/paymentRequest/${detail.id}`
      );
      let values = [];
      for (let i = 0; i < data.paymentDetails.length; i++) {
        if (i < paymentDetails.length)
          values = [
            ...values,
            {
              id: paymentDetails[i].id,
              SerialNo: data.paymentDetails[i].SerialNo,
              NatureOfExprensesOrAccounting:
                data.paymentDetails[i].NatureOfExprensesOrAccounting,
              ActivityMachineSelect:
                data.paymentDetails[i].ActivityMachineSelect,
              ActivityMachineDesc: data.paymentDetails[i].ActivityMachineDesc,
              BillDocumentNo: data.paymentDetails[i].BillDocumentNo,
              BillDocumentDate: data.paymentDetails[i].BillDocumentDate,
              BillDocumentAmount: data.paymentDetails[i].BillDocumentAmount,
            },
          ];
        else {
          values = [
            ...values,
            {
              id: "new",
              SerialNo: data.paymentDetails[i].SerialNo,
              NatureOfExprensesOrAccounting:
                data.paymentDetails[i].NatureOfExprensesOrAccounting,
              ActivityMachineSelect:
                data.paymentDetails[i].ActivityMachineSelect,
              ActivityMachineDesc: data.paymentDetails[i].ActivityMachineDesc,
              BillDocumentNo: data.paymentDetails[i].BillDocumentNo,
              BillDocumentDate: data.paymentDetails[i].BillDocumentDate,
              BillDocumentAmount: data.paymentDetails[i].BillDocumentAmount,
            },
          ];
        }
      }
      console.log(values);
      setPaymentDetails(values);
      data = {
        ...formDetail,
        PaymentRequestNo: data.PaymentRequestNo,
        CustomerName: data.CustomerName,
        FinancialPeriod: data.FinancialPeriod,
        Date: data.created_at,
        ProjectCodeName: data.ProjectCodeName,
        EmployeeCodeName: data.EmployeeCodeName,
        VendorCodeName: data.VendorCodeName,
        PaymentMode: data.PaymentMode,
        PaymentFor: data.PaymentFor,
        InFavourOf: data.InFavourOf,
        PAN: data.PAN,
        TotalPRValue: data.TotalPRValue,
        Remarks: data.Remarks,
      };
      setformDetail(data);
    }
  };

  useEffect(() => {
    async function getPaymentRequest() {
      try {
        const { data } = await axios.get("/accountingSystem/paymentRequest");
        AllPaymentRequest = data;
        let filteredData = AllPaymentRequest.filter(
          (item) => item.Status === "accepted"
        );
        let PRList = filteredData.map((item) => item.PaymentRequestNo);
        setList(PRList);
      } catch (err) {
        console.error("err", err);
      }
    }

    getPaymentRequest();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Scrutiny Employee and Others Edit"
        linkName1="Scrutiny Employee and Others Dashboard"
        to1="/accountingSystem/scrutinyEmployeeeAndOthers"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Scrutiny Employee and Others
      </h3>
      <ScrutinyEmployeeOtherForm
        list={list}
        selectPaymentRequest={selectPaymentRequest}
        paymentDetails={paymentDetails}
        initialValues={pick(
          formDetail,
          "PaymentRequestNo",
          "CustomerName",
          "CostCentre",
          "Currency",
          "Date",
          "FinancialPeriod",
          "ProjectCodeName",
          "PaymentMode",
          "PaymentFor",
          "InFavourOf",
          "PAN",
          "TotalPRValue",
          "Remarks",
          "VendorCodeName"
        )}
        onSubmit={onSubmit}
      />
    </div>
  );
};
