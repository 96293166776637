import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

const DeliveryChallanTable = ({
  storeTableData,
  materialsTableEdit,
  materialsTableCreate,
  stocksDetails,
}) => {
  const [inputFields, setInputFields] = useState();
  const { enqueueSnackbar } = useSnackbar();

  // edit
  useEffect(() => {
    if (materialsTableEdit) {
      setInputFields([...materialsTableEdit]);
      onChange(materialsTableEdit);
    }
    // eslint-disable-next-line
  }, [materialsTableEdit]);

  // create
  useEffect(() => {
    let values = [];
    if (materialsTableCreate && stocksDetails) {
      try {
        for (let i = 0; i < materialsTableCreate.length; i++) {
          values = [
            ...values,
            {
              SerialNo: materialsTableCreate[i].SerialNo,
              MaterialCode: materialsTableCreate[i].MaterialCode,
              MaterialDescription: materialsTableCreate[i].MaterialDescription,
              HSNSACCode: materialsTableCreate[i].HSNSACCode,
              UOM: materialsTableCreate[i].UOM,
              DcQuantity: 0,
              DcRate: stocksDetails[i].NetRate,
            },
          ];
        }
        setInputFields(values);
      } catch (err) {
        console.log(err);
      }
    } else if (materialsTableCreate) {
      for (let i = 0; i < materialsTableCreate.length; i++) {
        values = [
          ...values,
          {
            SerialNo: materialsTableCreate[i].SerialNo,
            MaterialCode: materialsTableCreate[i].MaterialCode,
            MaterialDescription: materialsTableCreate[i].MaterialDescription,
            HSNSACCode: materialsTableCreate[i].HSNSACCode,
            DcQuantity: 0,
            UOM: materialsTableCreate[i].UOM,
          },
        ];
      }
      setInputFields(values);
    }
    for (let i = 0; i < values.length; i++) {
      const row = values[i];
      try {
        if (materialsTableCreate[0].Quantity)
          row.DcQuantity = materialsTableCreate[i].Quantity;
        if (materialsTableCreate[0].ActualIssueQty)
          row.DcQuantity = materialsTableCreate[i].ActualIssueQty;
        row.ItemAmount = row.DcQuantity * row.DcRate;
        onChange(values);
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line
  }, [materialsTableCreate, stocksDetails]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({ error: true, errorKind: "some input field is empty" });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
    }
    setInputFields(values);
    onChange(values);
  };

  const updatevalues = (index) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row.ItemAmount = (+row.DcQuantity * +row.DcRate).toFixed(2);
    values[index] = row;
    setInputFields(values);
    onChange(values);
  };

  return (
    <div className="tableContainer">

        <table>
          <thead className="table-head">
            <tr>
              <th className="head-cell">Sl. No.</th>
              <th className="head-cell">Material Code</th>
              <th className="head-cell">Material Description</th>
              <th className="head-cell">HSN / SAC Code</th>
              <th className="head-cell">UOM</th>
              <th className="head-cell">DcQuantity</th>
              <th className="head-cell">DcRate</th>
              <th className="head-cell">Item Amount</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {inputFields?.map((inputField, index) => (
              <tr key={index}>
                <td className="table-data1">{inputField.SerialNo}</td>
                <td className="table-data3">{inputField.MaterialCode}</td>
                <td className="table-data3">
                  {inputField.MaterialDescription}
                </td>
                <td className="table-data3">{inputField.HSNSACCode}</td>
                <td className="table-data3">{inputField.UOM}</td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="DcQuantity"
                    value={inputField.DcQuantity}
                    type="number"
                    min="0"
                    step={0.01}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                    }}
                  />
                </td>
                <td className="table-data3">{inputField.DcRate}</td>
                <td className="table-data4">{inputField.ItemAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
   
    </div>
  );
};

export default DeliveryChallanTable;
