import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ServiceItemLink from "../../../forms/ProjectWorkforceManagement/ServiceItemLinkForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllScCode = [];
let AllActivity = [];
let AllMachine = [];


export const ServiceItemCreate = () => {
  const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [scList, setScList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [machineList, setMachineList] = useState([]);
  const history = useHistory();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "workforceManagement/serviceItem",
      formValues
    );
    console.log(response);
    history.push("/workforceManagement/serviceItem/");
  };

  const selectScCode = async (SCItemCode) => {

    const item = AllScCode.find(
      (item) => item.SCItemCode === SCItemCode?.split(" ")[0]
    );
    if(item){
      let payload ={
        ...formDetail,
        ServiceItemCode: SCItemCode,
        ServiceItemDescription: item.SCItemDescription,
        UOM: item.UOM,
      }
      setformDetail(payload);
    }
console.log(item)
    
  };
  const selectActivityCode = async (ActivityCode) => {

    const item = AllActivity.find(
      (item) => item.ActivityCode === ActivityCode?.split(" ")[0]
    );
    if(item){
      let payload ={
        ...formDetail,
        ActivityCode: ActivityCode,
        ActivityDescription: item.ActivityDescription,
        ActivityUOM: item.UOM,
      }
      setformDetail(payload);
    }
console.log(item)
    
  };
  const selectMachineCode = async (MachineCode) => {

    const item = AllMachine.find(
      (item) => item.MachineCode === MachineCode?.split(" ")[0]
    );
    if(item){
      let payload ={
        ...formDetail,
        MachineCode: MachineCode,
        MachineGroupDescription: item.MachineGdes,
      }
      setformDetail(payload);
    }
console.log(item)
    
  };
 

  useEffect(() => {
 

    async function getScCode() {
      try {
        let { data } = await axios.get("/commonMaster/SCItemCodeRequest");

        AllScCode = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let SCList = AllScCode.map((item) => item.SCItemCode);
        setScList(SCList);
      } catch (err) {
        alert(err);
      }
    }
    async function getActivityCode() {
      try {
        let { data } = await axios.get("/commonMaster/Activity");

        AllActivity = data;
        console.log(data);
        // const filtered = data.filter((item) => item.Status === "accepted");
        let ActivityList = AllActivity.map((item) => item.ActivityCode);
        setActivityList(ActivityList);
      } catch (err) {
        alert(err);
      }
    }
    async function getMachineCode() {
      try {
        let { data } = await axios.get("/commonMaster/machine");

        AllMachine = data;
        console.log(data);
        // const filtered = data.filter((item) => item.Status === "accepted");
        let MachineList = AllMachine.map((item) => `${item.MachineCode} - ${item.MachineGcode}`);
        setMachineList(MachineList);
      } catch (err) {
        alert(err);
      }
    }
 
    getScCode();
    getActivityCode();
    getMachineCode();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Service Item Link Create"
        linkName1="Service Item Link Dashboard"
        to1="/workforceManagement/serviceItem/"
      />
      <h3 style={{ textAlign: "center" }}>Service Item Link Create</h3>
      <ServiceItemLink
        onSubmit={onSubmit}
        list={list}
        scList={scList}
        activityList={activityList}
        machineList={machineList}
        selectScCode={selectScCode}
        selectMachineCode={selectMachineCode}
        selectActivityCode={selectActivityCode}
        initialValues={pick(
          formDetail,
          "ServiceItemCode",
          "ServiceItemDescription",
          "UOM",
          "ActivityCode",
          "ActivityDescription",
          "ActivityUOM",
          "MachineCode",
          "MachineGroupDescription",
        )}
      />
    </div>
  );
};
