import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Field, reduxForm } from "redux-form";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import * as Control from "../../components/formcomponents/control";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import LinearProgress from "@material-ui/core/LinearProgress";
import { STATE_LIST } from "../../utils/constants";

const CustomerGSTTable = (props) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: 1,
      GSTRegNo: "",
      GSTState: "",
      GSTAddress: "",
      id: "new",
    },
  ]);
  const [, setLoading] = useState(true);

  const [stateList, setStateList] = useState(STATE_LIST);
  const { tableData, Gsts, StateList } = props;

  useEffect(() => {
    if (StateList) {
      setLoading(true);
      setStateList([...StateList]);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [StateList]);

  const onChange = (values, removedEl) => {
    if (values) {
      tableData(values, removedEl);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };
  //edit
  useEffect(() => {
    let values = [];
    setLoading(true);
    if (Gsts) {
      for (let i = 0; i < Gsts.length; i++) {
        values = [
          ...values,
          {
            SerialNo: Gsts[i].SerialNo,
            GSTRegNo: Gsts[i].GSTRegNo,
            GSTState: Gsts[i].GSTState,
            GSTAddress: Gsts[i].GSTAddress,
            id: Gsts[i].id,
          },
        ];
      }

      setInputFields(values);
      setLoading(false);
      onChange(values);
    }
    // eslint-disable-next-line
  }, [Gsts]);

  const onItemSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];

    try {
      row.GSTState = value;
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: 0,
        GSTRegNo: "",
        GSTState: "",
        GSTAddress: "",
        id: "new",
      },
    ]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    const removed = values.splice(index, 1);
    props.tableData(values, removed);
    onChange(values, removed);
    setInputFields(values);
  };
  return (
    <>
      {inputFields ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">Sl No.</th>
                  <th>GST No.</th>
                  <th>State</th>
                  <th className="head-cell">Address</th>
                </tr>
              </thead>
              <tbody>
                {inputFields.map((inputField, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="table-data2">
                      <input
                        type="text"
                        className="input-FielD"
                        name="GSTRegNo"
                        value={inputField.GSTRegNo}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                    </td>
                    <td>
                      {stateList && (
                        <AutoCompleteTableInput
                          list={stateList}
                          inputChange={onItemSelect}
                          tableIndex={index}
                          name="GSTState"
                          value={inputField.GSTState?.name}
                        />
                      )}
                    </td>
                    <td className="table-data4">
                      <input
                        type="text"
                        className="input-FielD"
                        name="GSTAddress"
                        value={inputField.GSTAddress}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                    </td>
                    {index === 0 ? (
                      <IconButton>
                        <RemoveCircleIcon className="width5" color="disabled" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                        <RemoveCircleIcon
                          className="width5"
                          color="secondary"
                        />
                      </IconButton>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="alignAt-right">
              <Button
                onClick={(e) => handleAddFields(e)}
                variant="contained"
                color="primary"
              >
                <AddCircleIcon />
                ADD Row
              </Button>
            </div>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};
export default CustomerGSTTable;
