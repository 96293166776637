import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import moment from "moment";

const DailyAttendanceFormTable = ({
  tableData,
  machineList,
  activityList,
  shiftList,
  tables,
  workmenList,
}) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [machines, setMachines] = useState([]);
  const [shift, setShift] = useState([]);

  // create table
  useEffect(() => {
    if (workmenList) {
      setLoading(true);
      let values = [];
      for (let i = 0; i < workmenList.length; i++) {
        values = [
          ...values,
          {
            SerialNo: i + 1,
            TokenNo: workmenList[i].WorkmenCode,
            WorkmenName: workmenList[i].WorkmenName,
            Present: "yes",
            disabled: false,
            GangCode: "",
            ShiftCode: "",
            TimeIn: "",
            TimeOut: "",
            NormalHour: "",
            Overtime: "",
            ActivityMachineSelect: "",
            ActivityMachineDesc: "",
          },
        ];
      }
      setInputFields(values);
      if (tableData) tableData(values);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [workmenList]);

  // useEffect(() => {
  //   if (workmenList) {
  //     setLoading(true)
  //     setInputFields([...workmenList])
  //     if (tableData) tableData(workmenList)
  //     setLoading(false)
  //   }
  //   console.log(workmenList);
  //   // eslint-disable-next-line
  // }, [workmenList])

  // edit table population
  useEffect(() => {
    if (tables) {
      setInputFields([...tables]);
      if (tableData) tableData(tables);
    }
    // eslint-disable-next-line
  }, [tables]);

  useEffect(() => {
    if (activityList) {
      setActivities(activityList);
    }
  }, [activityList]);

  useEffect(() => {
    if (machineList) {
      setMachines(machineList);
    }
  }, [machineList]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    tableData(values);
  };

  const handleSelectInput = (index, event) => {
    const value = event.target.value;
    const values = [...inputFields];
    values[index][event.target.name] = value;
    if (event.target.name === "Present" && event.target.value === "no") {
      values[index].disabled = true;
    }
    setInputFields(values);
    tableData(values);
  };

  const onShiftSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        const shiftObj = shiftList.find(shift => shift?.shift_code === value);
        row['TimeIn'] = moment(shiftObj?.shift_in_Time).format("HH:mm:ss");
        row['TimeOut'] = moment(shiftObj?.shift_out_Time).format("HH:mm:ss");
        row[fieldName] = value;
      } else {
        row[fieldName] = "";
        row['TimeIn'] = "";
        row['TimeOut'] = "";
      }
      const timeIn = row.TimeIn.split(":");
      console.log(timeIn)
      const timeOut = row.TimeOut.split(":");
      let diff = 0;
      if (+timeIn[0] > +timeOut[0]) {
        const toMidNight = 24 - +timeIn[0];
        diff = +toMidNight + +timeOut[0];
      } else {
        diff = +timeOut[0] - +timeIn[0];
      }
      if (+diff > 8) {
        row.NormalHour = "8 hours";
        const OTHours = +diff - +8;
        row.Overtime = OTHours + " hours";
      } else {
        row.NormalHour = diff;
        row.Overtime = "0 hours";
      }
    } catch (err) {
      console.error(err);
    } finally {
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  const onActivitySelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        const list = activities;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        setActivities([...list]);
      } else {
        setActivities([...activities, row[fieldName]]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  const onMachineSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        tdata[index] = row;
        const list = machines;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        setActivities([...list]);
      } else {
        setActivities([...machines, row[fieldName]]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  // const calculateTime = (index) => {
  //   const values = [...inputFields];
  //   let row = values[index];
  //   try {
  //     const timeIn = row.TimeIn.split(":");
  //     console.log(timeIn)
  //     const timeOut = row.TimeOut.split(":");
  //     let diff = 0;
  //     if (+timeIn[0] > +timeOut[0]) {
  //       const toMidNight = 24 - +timeIn[0];
  //       diff = +toMidNight + +timeOut[0];
  //     } else {
  //       diff = +timeOut[0] - +timeIn[0];
  //     }
  //     if (+diff > 8) {
  //       row.NormalHour = "8 hours";
  //       const OTHours = +diff - +8;
  //       row.Overtime = OTHours + " hours";
  //     } else {
  //       row.NormalHour = diff;
  //       row.Overtime = "0 hours";
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     values[index] = row;
  //     setInputFields(values);
  //     tableData(values);
  //     console.log(tableData);
  //   }
  // };

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2" className="head-cell">
              SerialNo
            </th>
            <th rowSpan="2" className="head-cell">
              Token Number
            </th>
            <th rowSpan="2" className="head-cell">
              Workmen Name
            </th>
            <th rowSpan="2" className="head-cell">
              Present
            </th>
            <th rowSpan="2" className="head-cell">
              Gang Code
            </th>
            <th rowSpan="2" className="head-cell">
              Shift Code
            </th>
            <th rowSpan="2" className="head-cell">
              Time In
            </th>
            <th rowSpan="2" className="head-cell">
              Time Out
            </th>
            <th rowSpan="2" className="head-cell">
              Normal hour
            </th>
            <th rowSpan="2" className="head-cell">
              Overtime Hour
            </th>
            <th rowSpan="2" className="head-cell">
              Activity/Machine
            </th>
            <th rowSpan="2" className="head-cell">
              Activity/Machine Description
            </th>
          </tr>
        </thead>
        <tbody>
          {inputFields?.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data3">
                <input
                  type="text"
                  name="TokenNo"
                  value={inputField.TokenNo}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "5rem" }}
                />
              </td>
              <td className="table-data3">
                <input
                  type="text"
                  name="WorkmenName"
                  value={inputField.WorkmenName}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "5rem" }}
                />
              </td>
              <td className="table-data3">
                <select
                  style={{ width: "8rem", height: "2rem" }}
                  name="Present"
                  onChange={(event) => handleSelectInput(index, event)}
                  value={inputField.Present}
                >
                  <option value={"yes"}>Yes</option>
                  <option value={"no"}>No</option>
                </select>
              </td>
              <td className="table-data3">
                <input
                  type="text"
                  name="GangCode"
                  defaultValue={inputField.GangCode}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "8rem" }}
                  disabled={inputField.disabled}
                />
              </td>
              <td className="table-data3">
                {/* <input
                      type="text"
                      name="ShiftCode"
                      defaultValue={inputField.ShiftCode}
                      onChange={(event) => handleChangeInput(index, event)}
                      style={{ width: "8rem" }}
                      disabled={inputField.disabled}
                    /> */}
                <AutoCompleteTableInput
                  list={shiftList?.map((item) => item?.shift_code)}
                  inputChange={onShiftSelect}
                  tableIndex={index}
                  fieldName={"ShiftCode"}
                  value={inputField?.ShiftCode}
                  defaultValue={inputField?.ShiftCode}
                />
              </td>
              <td className="table-data3">
                <input
                  type="time"
                  name="TimeIn"
                  defaultValue={inputField.TimeIn}
                  value={inputField.TimeIn}
                  style={{ width: "6rem" }}
                  disabled={inputField.disabled}
                />
              </td>
              <td className="table-data3">
                <input
                  type="time"
                  name="TimeOut"
                  defaultValue={inputField.TimeOut}
                  value={inputField.TimeOut}
                  style={{ width: "6rem" }}
                  disabled={inputField.disabled}
                />
              </td>
              <td className="table-data3">{inputField.NormalHour}</td>
              <td className="table-data3">
              {inputField.Overtime}</td>
              <td className="table-data3">
                <select
                  style={{ width: "8rem", height: "2rem" }}
                  name="ActivityMachineSelect"
                  onChange={(event) => handleSelectInput(index, event)}
                  value={inputField.ActivityMachineSelect}
                  disabled={inputField.disabled}
                >
                  <option value={""}></option>
                  <option value={"activity"}>Activity</option>
                  <option value={"machine"}>Machine</option>
                </select>
              </td>
              <td className="table-data3">
                {inputField.ActivityMachineSelect === "activity"
                  ? activities && (
                      <AutoCompleteTableInput
                        list={activities}
                        inputChange={onActivitySelect}
                        tableIndex={index}
                        fieldName={"ActivityMachineDesc"}
                        defaultValue={inputField.ActivityMachineDesc}
                      />
                    )
                  : machines && (
                      <AutoCompleteTableInput
                        list={machines}
                        inputChange={onMachineSelect}
                        tableIndex={index}
                        fieldName={"ActivityMachineDesc"}
                        defaultValue={inputField.ActivityMachineDesc}
                      />
                    )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DailyAttendanceFormTable;
