import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import ReportsTable from "../../../components/AccountingSystemDisplay/ReportsComponent/ReportsTable";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import AdvanceStatementDetails from "../../../components/AccountingSystemDisplay/ReportsComponent/ReportAdvanceStatementDetails";
import SearchOptionProject from "../../../components/ReportsComponent/SearchOptionProject";

export const AdvanceStatement = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isList, setIsList] = useState(true);
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get("/accountingSystem/reports/advance/");
        let data = response.data;
        console.log(data);
        setLists(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }

    fetch();
  }, [history, dispatch]);

  const onViewDetailsClick = (item) => {
    const name =
      item.VendorCodeName || item.EmployeeCodeName || item.InFavourOf;
    setDetails({ Name: name, ProjectCodeName: item.ProjectCodeName });
    setIsList(false);
  };

  const handleProjectChange = async (value) => {
    const filteredProject = lists.filter(
      (item) => item.ProjectCodeName === value
    );
    setLists(filteredProject);
  };
  return (
    <>
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>Advance Statement</h3>
        <SearchOptionProject
          handleProjectChange={handleProjectChange}
          label="Filter Project"
        />
        {!isLoading && isList ? (
          <ReportsTable
            lists={lists}
            tablecol3="TotalAdvance"
            viewDetails={true}
            onViewDetailsClick={onViewDetailsClick}
          />
        ) : !isLoading && !isList ? (
          <AdvanceStatementDetails details={details} />
        ) : (
          <div className={classes.Container}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={200} />
          </div>
        )}
      </Paper>
    </>
  );
};
