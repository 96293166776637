import React, { useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import Table from "../../Tables/MaterialSystem/PurchaseRequestTable";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
// import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
// import Fab from "@material-ui/core/Fab";
// import { Link } from "react-router-dom";

const PurchaseRequestForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const storeTableData = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += +item.MaterialTotal;
    });
    props.dispatch(
      change("PurchaseRequestForm", "TotalPRValue", (+total).toFixed(2))
    );

    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        storedData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.storedData) {
      setState({ tableError: true });
    } else if (state.storedData) {
      props.onSubmit({
        ...formValues,
        tableData: state.storedData,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            name="Currency"
            label="Currency"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="WarehouseName"
            label="Warehouse Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="PurchaseCategory"
            label="Purchase Category"
          >
            <option value={""}></option>
            <option value={"ProjectSite"}>Project Site</option>
            <option value={"HeadOffice​"}>Head Office​</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="BilltoCustomer"
            label="Bill to Customer (For Project PR)​"
          >
            <option value={""}></option>
            <option value={"Yes"}>Yes</option>
            <option value={"No​"}>No​</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="DeliveryAt"
            label="Delivery At"
          >
            <option value={""}></option>
            <option value="Customer WH">Customer WH</option>
            <option value={"Own Premises​"}>Own Premises​</option>
            <option value={"Central WH​​"}>Central WH​​</option>
          </Field>
          <Field
            name="RequiredOn"
            component={Control.RenderDateField}
            label="Required On"
            initialValue={props.initialValues.RequiredOn}
          />
          <Field
            component={Control.RenderSelectField}
            name="PRType"
            label="PR Type"
          >
            <option value={""}></option>
            <option value="Customer WH">Project​</option>
            <option value={"Own Premises​"}>Revenue​​</option>
            <option value={"Central WH​​"}>Machine​</option>
            <option value={"Capital​​"}>Captial</option>
          </Field>
          <Field
            name="Remarks"
            component={Control.RenderTextField}
            label="Remarks"
            type="text"
          />
          <Field
            component={Control.RenderTextField}
            name="TotalPRValue"
            label="Total PR Value​"
            disabled
          />
        </div>
        <div className="excel">
          <Table
            storeTableData={storeTableData}
            materialsEditData={props.materialsEditData}
            materialList={props.materialList}
            edit={props.edit}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Purchase Request"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "Currency",
    "TotalPRValue",
    "BilltoCustomer",
    "ProjectCodeName",
    "Currency",
    "WarehouseName",
    "PurchaseCategory",
    "DeliveryAt",
    "RequiredOn",
    "PRType",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "PurchaseRequestForm",
  validate,
  enableReinitialize: true,
})(PurchaseRequestForm);
