import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
import moment from "moment";

const ServiceItem = (props) => {
  const [type, setType] = useState("");
  const [mStatus, setMStatus] = useState("");


  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    console.log(formValues)
    props.onSubmit(formValues)
  };

  // const validateAge = (input) => {
  //   if (input) {
  //     const years = formatDistanceStrict(input, new Date(), {
  //       unit: "year",
  //     }).split(" ")[0];
  //     console.log(years);
  //     if (+years <= 18) {
  //       return `Age should be more than 18`;
  //     }
  //   }
  //   return;
  // };



  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ServiceItemCode"
            label="Service Item Code"
            component={Control.AutoCompleteInput}
            list={props.scList}
            onChange={(value) =>
              props.selectScCode
                ? props.selectScCode(value)
                : () => {
                    return;
                  }
            }
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="ServiceItemDescription"
            label="Service Item Description"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            label="UOM"
            name="UOM"
            disabled
          ></Field>
          <Field
            component={Control.RenderSelectField}
            name="Link"
            label="Link"
            onChange={(value) => setType(value)}
          >
            <option value={""}></option>
            <option value={"Activity"}>Activity</option>
            <option value={"Machine"}>Machine</option>
          </Field>
          {type === "Activity" && (
            <>
              <Field
                component={Control.AutoCompleteInput}
                name="ActivityCode"
                list={props.activityList}
                label="Activity Code"
                className="text-field"
                onChange={(value) =>
                    props.selectActivityCode
                      ? props.selectActivityCode(value)
                      : () => {
                          return;
                        }
                  }
              />
              <Field
                component={Control.RenderTextField}
                name="ActivityDescription"
                label="Activity Description"
              />
               <Field
                component={Control.RenderTextField}
                name="ActivityUOM"
                label="Activity UOM"
              />
               <Field
                component={Control.RenderTextField}
                name="Percentage"
                label="%"
              />
                <Field
                component={Control.RenderTextField}
                name="ConversionFactor"
                label="Conversion Factor"
              />
                 <Field
                component={Control.RenderTextField}
                name="QuantityAdd"
                label="Quantity Add"
              />
            </>
          )}
           {type === "Machine" && (
            <>
              <Field
                component={Control.AutoCompleteInput}
                name="MachineCode"
                list={props.machineList}
                label="Machine Code"
                className="text-field"
                onChange={(value) =>
                    props.selectMachineCode
                      ? props.selectMachineCode(value)
                      : () => {
                          return;
                        }
                  }
              />
              <Field
                component={Control.RenderTextField}
                name="MachineGroupDescription"
                label="Machine Group Description"
              />
            </>
          )}
        <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["Active", "InActive"]}
            className="text-field"
          />
        
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Service Item"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
  "ServiceItemCode"
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "ServiceItem",
  validate,
  enableReinitialize: true,
})(ServiceItem);
