import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const ScrutinySubContractorTableView = ({tableData}) => {
  return (
    <div>
      {tableData ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">WorkOrderNo</th>
                <th className="head-cell">WorkOrderDate</th>
                <th className="head-cell">SubContractorBillNoFNC</th>
                <th className="head-cell">SubContractorBillDateFNC</th>
                <th className="head-cell">SubContractorBillNo</th>
                <th className="head-cell">SubContractorBillDate</th>
                <th className="head-cell">Amount</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{row.SerialNo}</td>
                  <td className="table-data2">{row.WorkOrderNo}</td>
                  <td className="table-data2">
                    {format(new Date(row.WorkOrderDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{row.SubContractorBillNoAuto}</td>
                  <td className="table-data2">
                    {format(
                      new Date(row.SubContractorBillDateAuto),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{row.SubContractorBillNoManual}</td>
                  <td className="table-data2">
                    {format(new Date(row.SubContractorBillDateManual), "d MMM yyyy")}
                  </td>
                  <td className="table-data4">{row.Amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default ScrutinySubContractorTableView;
