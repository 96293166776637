import axios from "../../components/apis/axios";
import { userLogout } from "./userAction";

export const approvalMaterialManagement =
  (link, id, history, data) => async (dispatch) => {
    axios
      .put(`/materialManagement/${link}/approval/${id}`, data)
      .then((res) => {
        console.log(res);
        history.push(`/materialManagement/${link}`);
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

export const DeleteSingle = (link, history) => {
  return async (dispatch) => {
    console.log(history);
    try {
      await axios.put(link);
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      history.push("/");
    }
  };
};

export const goodsInspectVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/goodsInspection", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/goodsInspection");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editgoodsInspect = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/goodsInspection/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/goodsInspection");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allgoodsinspectionvoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/goodsInspection");
      console.log(data);
      dispatch({
        type: "SET_ALL_GOODS_INSPECTION_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchGoodsinspectionById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/goodsInspection/${id}`
      );
      dispatch({
        type: "SET_SINGLE_GOODS_INSPECTION",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const goodsReceiptVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/goodsReceipt", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/goodsReceipt");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editgoodsReceipt = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/goodsReceipt/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/goodsReceipt");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allGoodsReceiptVoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/goodsReceipt");
      console.log(data);
      dispatch({
        type: "SET_ALL_GOODS_RECEIPT_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchGoodsReceiptById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/goodsReceipt/${id}`
      );
      console.log("goodsReceipt ->", data);
      dispatch({
        type: "SET_SINGLE_GOODS_RECEIPT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const warehouseReturnVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/warehouseReturn", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/WarehouseReturn");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editwarehouseReturn = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/warehouseReturn/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/WarehouseReturn");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allWarehouseReturnVoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/warehouseReturn");
      console.log(data);
      dispatch({
        type: "SET_ALL_WAREHOUSE_RETURN_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchWarehouseReturnById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/warehouseReturn/${id}`
      );
      dispatch({
        type: "SET_SINGLE_WAREHOUSE_RETURN",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
//warehouseindetvocher
export const warehouseIndentVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/warehouseIntent", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/warehouseIntent");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editWarehouseIndentVoucher = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/warehouseIntent/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/warehouseIntent");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allWarehouseIndentVoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/warehouseIntent");
      console.log(data);
      dispatch({
        type: "SET_ALL_WAREHOUSE_INDENT_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchWarehouseIndentVoucherById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/warehouseIntent/${id}`
      );
      dispatch({
        type: "SET_SINGLE_WAREHOUSE_INDENT_VOUCHER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

//warehouseindetvocher
export const warehouseReceiptVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/warehouseReceipt", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/warehouseReceipt");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editwarehouseReceipt = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/warehouseReceipt/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/warehouseReceipt");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allWarehouseReceiptVoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/warehouseReceipt");
      console.log(data);
      dispatch({
        type: "SET_ALL_WAREHOUSE_RECEIPT_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchWarehouseReceiptById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/warehouseReceipt/${id}`
      );
      dispatch({
        type: "SET_SINGLE_WAREHOUSE_RECEIPT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const WarehouseIssueVoucher = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/warehouseIssue", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/warehouseIssue");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const editWarehouseIssue = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/warehouseIssue/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/warehouseIssue");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allWarehouseIssueVoucher = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/warehouseIssue");
      console.log(data);
      dispatch({
        type: "SET_ALL_WAREHOUSE_ISSUE_VOUCHER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchWarehouseIssueById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/warehouseIssue/${id}`
      );
      dispatch({
        type: "SET_SINGLE_WAREHOUSE_ISSUE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const DeliveryChallan = (formData, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/deliveryChallan", formData);
      alert("Submitted Successfully");
      history.push("/materialManagement/deliveryChallan");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editDeliveryChallan = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/deliveryChallan/${Id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/deliveryChallan");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allDeliveryChallan = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/deliveryChallan");
      console.log(data);
      dispatch({
        type: "SET_ALL_DELIVERY_CHALLAN",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchDeliveryChallanById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/deliveryChallan/${id}`
      );
      dispatch({
        type: "SET_SINGLE_DELIVERY_CHALLAN",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const PhysicalVerification = (formvalues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/physicalVerification", formvalues);
      alert("Submitted Successfully");
      history.push("/materialManagement/physicalVerification");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const allPhysicalVerification = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/materialManagement/physicalVerification"
      );
      dispatch({
        type: "SET_ALL_PHYSICAL_VERIFICATION",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editPhysicalVerification = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/materialManagement/physicalVerification/${Id}`,
        formData
      );
      alert("Updated Successfully");
      history.push("/materialManagement/physicalVerification");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchPhysicalVerificationById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/physicalverification/${id}`
      );
      dispatch({
        type: "SET_SINGLE_PHYSICAL_VERIFICATION",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const purchaseRequest = (formData, history, enqueueSnackbar) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "/materialManagement/purchaseRequest",
        formData
      );
      console.log(response);
      enqueueSnackbar(
        `Purchase Request ${response.data.PurchaseRequestNo} created successfully`,
        {
          variant: "success",
        }
      );
      history.push("/materialManagement/purchaseRequest");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allPurchaseRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/purchaseRequest");
      dispatch({
        type: "SET_ALL_PURCHASE_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editPurchaseRequest = (formData, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/purchaseRequest/${Id}`, formData);
      history.push("/materialManagement/purchaseRequest");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchPurchaseRequestById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/purchaseRequest/${id}`
      );
      dispatch({
        type: "SET_SINGLE_PURCHASE_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const purchaseOrder = (formvalues, history) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "/materialManagement/purchaseOrder",
        formvalues
      );
      alert(
        `Purchase Order: ${response.data.PurchaseOrderNo} created successfully`
      );
      history.push("/materialManagement/purchaseOrder");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      alert(err);
      console.log("Error in userLogin Action", err.message);
    }
  };
};
export const allPurchaseOrder = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/materialManagement/purchaseOrder");
      console.log(data);
      dispatch({
        type: "SET_ALL_PURCHASE_ORDER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editPurchaseOrder = (formData, id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/materialManagement/purchaseOrder/${id}`, formData);
      alert("Updated Successfully");
      history.push("/materialManagement/purchaseOrder");
    } catch (err) {
      if (err.response.Status === 401) {
        dispatch(userLogout(history));
      } else if (err.response.Status === 422) {
        alert("server error!");
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchPurchaseOrderById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/purchaseOrder/${id}`
      );
      dispatch({
        type: "SET_SINGLE_PURCHASE_ORDER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allPurchaseOrderAmendment = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/materialManagement/purchaseOrderAmendment"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_PURCHASE_ORDER_AMENDMENT",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const purchaseOrderAmendment = (formData, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/materialManagement/purchaseOrderAmendment", formData);
      alert("Submitted Successfully");
      history.push("/materialManagement/purchaseOrderAmendment");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editPurchaseOrderAmendment = (formData, id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/materialManagement/purchaseOrderAmendment/${id}`,
        formData
      );
      alert("Updated Successfully");
      history.push("/materialManagement/purchaseOrderAmendment");
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchPurchaseOrderAmendmentById = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/materialManagement/purchaseOrderAmendment/${id}`
      );
      dispatch({
        type: "SET_SINGLE_PURCHASE_ORDER_AMENDMENT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
