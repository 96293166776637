import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const SCItemCodeRequest = (props) => {
     const [show, setShow] = React.useState(false);

     const onSubmit = (formValues) => {
       props.onSubmit(formValues);
     };

     const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          {/* <Field
                            component={Control.RenderTextField}
                            name="SCItemCode"
                            label="SC Item Code"
                          
                        /> */}
          <Field
            component={Control.AutoCompleteInput}
            name="MajorSCItemGroupCode"
            label="Major SC Item Group Code"
            list={props.majorSCItem}
            className="text-field"
            onChange={(value) =>
              props.SelectMajorSCItem && props.SelectMajorSCItem(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="MajorSCItemGroupDescription"
            label="Major SC Item Group Description"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="SCItemDescription"
            label="SC Item Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="UOM"
            label="UOM"
            list={props.UOM}
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Component"
            label="Component"
            className="text-field"
            list={[
              " ",
              "Labour",
              "Material",
              "Machine",
              "Overhead",
              "Combined",
            ]}
          />
          <Field
            component={Control.RenderTextField}
            name="ComponentPercentage"
            label="Component %"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="SCItemTag"
            className="text-field"
            list={[" ", "Active", "Inactive"]}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="scItemCodeRequest"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "SCItemCode",
    "MajorSCItemGroupCode",
    "MajorSCItemGroupDescription",
    "SCItemDescription",
    "UOM",
    "Component",
    "Component1",
    "SCItemTag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "scItemCodeRequest",
  validate,
  enableReinitialize: true,
})(SCItemCodeRequest);
