import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import MajorSCItemGroup from "../../../forms/CommonMaster/MajorSCItemGroup";
import { majorSCItemGroup } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const MajorScItemGroupCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [UOM, setUOM] = React.useState([]);

  const onSubmit = (formValues) => {
    dispatch(majorSCItemGroup(formValues, history));
  };
  React.useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        let unitOfMeasurement = data.map(
          (item) => item.UOMCode + "-" + item.UOMDescription
        );
        setUOM(unitOfMeasurement);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Major SC Item Group Create"
        linkName1="Major SC Item Group Dashboard"
        to1="/commonMaster/majorSCItemGroup"
      />
      <FormHeading label="Major SC Item Group" />
      <MajorSCItemGroup onSubmit={onSubmit} UOM={UOM} />
    </div>
  );
};
