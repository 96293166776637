import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineRepairRequest from "../../../forms/MachineManagementSystem/MachineRepairRequest";
import { useHistory } from "react-router-dom";
import {
  editMachineRepairRequest,
  searchMachineRepairRequestById,
} from "../../../redux/actions/MachineManagementaction";

export const MachineRepairRequestEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachineRepairRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachineRepairRequest) {
      setData(store.SingleMachineRepairRequest);
    }
  }, [store.SingleMachineRepairRequest]);

  const onSubmit = (formValues) => {
    dispatch(
      editMachineRepairRequest(formValues, props.match.params.id, history)
    );
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Repair Request Edit
      </h3>
      <div className="line"></div>
      <MachineRepairRequest
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "MachineCodeName",
          "RepairType",
          "RepairDateTime",
          "ExpectedCommDate",
          "NatureOfDefects",
          "Remarks"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
