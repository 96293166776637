import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Customer from "../../../forms/CommonMaster/Customer";
import { customer } from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import StateList from "../../../components/helpers/StateList";

export const CustomerCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [stateList, setStateList] = useState([]);

  const onSubmit = (formValues) => {
    dispatch(customer(formValues, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Customer Create"
        linkName1="Customer Dashboard"
        to1="/commonMaster/customer"
      />
      <FormHeading label="Customer" />
      <Customer onSubmit={onSubmit} stateList={stateList} />
    </div>
  );
};
