import { combineReducers } from "redux";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";
import materialSystemReducer from "./materialSystem";
import machineMangementSystemReducer from "./MachineMangement";
import commonMasterReducer from "./CommonMaster";
import SalesAcountReducer from "./SalesAcounts";
import GeneralReducer from "./GeneralReducer";
import ProjectWorkforceManagementReducer from "./ProjectWorkforceManagement";
import AccessControlReducer from "./AccessControl";
import { reducer as formReducer } from "redux-form";

export default combineReducers({
  userRoot: userReducer,
  errorRoot: errorReducer,
  materialSystemRoot: materialSystemReducer,
  machineMangementSystemRoot: machineMangementSystemReducer,
  commonMasterSystemRoot: commonMasterReducer,
  SalesAcountRoot: SalesAcountReducer,
  GeneralRoot: GeneralReducer,
  ProjectWorkforceManagementRoot: ProjectWorkforceManagementReducer,
  AccessControlRoot: AccessControlReducer,
  form: formReducer,
});
