import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachinePurchaseOrderAmendments from "../../../forms/MachineManagementSystem/MachinePurchaseOrderAmendments";
import { useHistory } from "react-router-dom";
import {
  editMachinePurchaseOrderAmendment,
  searchMachinePurchaseOrderAmendmentById,
} from "../../../redux/actions/MachineManagementaction";

export const MachinePurchaseOrderAmendmentsEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachinePurchaseOrderAmendmentById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachinePurchaseOrderAmendment) {
      setData(store.SingleMachinePurchaseOrderAmendment);
    }
  }, [store.SingleMachinePurchaseOrderAmendment]);
  const onSubmit = (formValues) => {
    dispatch(
      editMachinePurchaseOrderAmendment(
        formValues,
        props.match.params.id,
        history
      )
    );
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Purchase Order Amendments Edit
      </h3>
      <div className="line"></div>
      <MachinePurchaseOrderAmendments
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "MachinePurchaseOrderNo",
          "MachinePurchaseOrderAmendmentValue",
          "Date",
          "LastAmendmentNo",
          "LastAmendmentDate",
          "OurRefNoDate",
          "YourRefNoDate",
          "VendorCodeName",
          "VendorGSTNo",
          "FNCGSTNo",
          "Warehouse",
          "DeliveryAt",
          "DeliveryPeriodFrom",
          "DeliveryPeriodTo",
          "Inspection",
          "PaymentTerms",
          "Percentage",
          "Days",
          "Currency",
          "LDClause",
          "PriceVariationClause",
          "PurchaseOrderValue"
        )}
        materials={data.materials}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
