import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import ReceiptCustomerTable from "../../../Tables/AccountingSystem/ReceiptCustomerTable";
import * as Control from "../../../components/formcomponents/control";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import "../../../components/helpers/form.style.css";

const ReceiptCustomerForm = (props) => {
  const [state, setState] = useState({
    tableData: null,
    tableErrorKind: "",
    tableError: false,
  });
  const [customer, setCustomer] = useState();

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    console.log("success", formValues);
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }

    props.onSubmit({
      ...formValues,
      invoiceDetails: state.tableData,
    });
  };

  useEffect(() => {
    if (props.initialValues?.CustomerName)
      setCustomer(props.initialValues.CustomerName);
  }, [props.initialValues?.CustomerName]);

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      let total = 0;
      data.forEach((item) => {
        total += +item.ReceivedNow;
      });
      props.dispatch(
        change(
          "ReceiptCustomerForm",
          "TotalReceiptValueManual",
          (+total).toFixed(2)
        )
      );
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />
        <div className="field-container">
          <Field
            name="CustomerName"
            label="Customer Name"
            list={props.customers}
            component={Control.AutoCompleteInput}
            onChange={(value) => setCustomer(value)}
            className="text-field"
          />
          <Field
            name="Remarks"
            label="Remarks"
            component={Control.RenderTextField}
          />
        </div>
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
          Invoice Details
        </h4>
        <div className="excel">
          <ReceiptCustomerTable
            tableData={tableData}
            receipt={props.receipt}
            customer={customer}
          />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {state.tableError ? `${state.tableErrorKind}` : ""}
          </p>
        </div>
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
          Receipt Details
        </h4>
        <div className="field-container">
          <Field
            name="AtBank"
            label="At Bank"
            list={props.bankList}
            component={Control.AutoCompleteInput}
            className="text-field"
          />
          <Field
            name="TotalReceiptValueManual"
            label="Total Receipt Value"
            disabled
            component={Control.RenderTextField}
          />
          <Field
            name="ChequeNEFTRTGSIMPSNo"
            component={Control.RenderTextField}
            label="Cheque/NEFT/RTGS/IMPS No."
          />
          <Field
            name="Date"
            label="Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues?.Date}
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Receipt Customer"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "CustomerName",
    "AtBank",
    "TotalReceiptValue",
    "ChequeNEFTRTGSIMPSNo",
    "Date",
    "Narration",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "ReceiptCustomerForm",
  validate,
  enableReinitialize: true,
})(ReceiptCustomerForm);
