import React, { useState, useEffect, useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useStyle from "../helpers/UseStyle";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteModal from "../modals/DeleteModal";
import { DeleteSingle } from "../../redux/actions/materialSystemAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Skeleton from "@material-ui/lab/Skeleton";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { CommentOutlined } from "@material-ui/icons";
import { SelectDate } from "../DatePicker";
import { Select, Input as AntInput } from "antd";
import { CSVLink } from "react-csv";
import RoleList from "../helpers/RoleList";
import { ExportCSV } from "../Exportexcel/Exportexcel";
import { readAllFromQueryParams } from "../../utils/queryParams";
import Paginator from "../Paginator/Paginator";
import moment from "moment";
import useUpdateSearch from "../../utils/hooks/useUpdateSearch";
import usePermissions from "../../utils/hooks/usePermissions";
const { Search } = AntInput;

const { Option } = Select;

const statusfilter = ["Available", "released", "all"];

const FormDashboardPagination = (props) => {
  const classes = useStyle();
  const [lists, setLists] = useState([]);
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const history = useHistory();
  const { search } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const params = readAllFromQueryParams(search);
  const [updateSearch] = useUpdateSearch(search);
  const getPermissions = usePermissions();

  useEffect(() => {
    setLists(props.lists);
  }, [props.lists]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [displaySearchFilter, setDisplaySearchFilter] = useState(
    props.heading !== "none"
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDelete = (id) => {
    dispatch(DeleteSingle(`${props.DeleteLink}/delete/${id}`, history));
    toggleModal();
    const temp = [...lists];
    for (const item of temp) {
      if (item.id === id) {
        item.Status = "deleted";
      }
    }
    setLists(temp);
  };

  const onSearch = (query) => {
    updateSearch({
      query,
      page: 1,
    });
  };

  const handleStatus = (status) => {
    updateSearch({
      status,
      page: 1,
    });
  };

  const onDateChange = (dates) => {
    updateSearch({
      fromDate: dates?.from ? moment(dates?.from).toISOString() : "",
      toDate: dates?.to ? moment(dates?.to).toISOString() : "",
      page: 1,
    });
  };

  const StatusChecker = (status) => {
    if (status === "not_reviewed") {
      return <h5 className={classes.notReviewed}>Not Reviewed</h5>;
    } else if (status === "accepted") {
      return <h5 className={classes.approved}>Approved</h5>;
    } else if (status === "rejected") {
      return <h5 className={classes.rejected}>Rejected</h5>;
    } else if (status === "deleted") {
      return <h5 className={classes.deleted}>Deleted</h5>;
    }
    return;
  };
  const userMatch = (id) => {
    return (
      <>
        {!!getPermissions?.Modify && (
          <span>
            <IconButton>
              <Link to={`${props.editLink}/${id}`}>
                <EditIcon color="action" />
              </Link>
            </IconButton>
          </span>
        )}

        {!!getPermissions?.Cancel && (
          <>
            <span>
              <IconButton
                onClick={() => {
                  toggleModal();
                  setItemId(id);
                }}
              >
                <DeleteForeverIcon color="secondary" />
              </IconButton>
            </span>
            <DeleteModal
              showModal={showModal}
              toggleModal={toggleModal}
              onDelete={onDelete}
              itemId={itemId}
            />
          </>
        )}
      </>
    );
  };

  const columnHeadDetails = [
    {
      id: "Label1",
      label: `${props.label1}`,

      minWidth: 170,
      align: "center",
    },
    { id: "Label2", label: "Request by", minWidth: 150 },
    {
      id: "Label3",
      label: "Date",
      minWidth: 150,
      align: "center",
    },
    {
      id: "Label4",
      label: "Operation",
      minWidth: 170,
    },
    {
      id: "Label5",
      label: "Status",
      minWidth: 170,
    },
    {
      id: "Label6",
      label: "Approved By",
      minWidth: 170,
    },
    {
      id: "Label7",
      label: "Approved At",
      minWidth: 170,
    },
    {
      id: "Label8",
      label: "Comment",
      minWidth: 170,
    },
  ];

  const canEdit = useMemo(() => {
    let edit = false;
    if (store.userRoot?.workingOn?.BusinessUnit) {
      edit =
        store.userRoot?.workingOn?.BusinessUnit.split("-")?.[1] === "COMMON";
    }
    edit =
      store.userRoot?.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
      store.userRoot?.workingOn.Role === RoleList.SITE_INCHARGE.code;
    return edit;
  }, [store.userRoot]);

  const options = ["Active", "All", "Closed", "Inactive"];

  return (
    <div style={{ paddingTop: "0" }}>
      <TableContainer
        className={classes.table}
        component={Paper}
        style={{ maxHeight: "65vh", border: "none", paddingTop: "0" }}
      >
        <Box mt={2}>
          <h2 align="center">{props.heading}</h2>
        </Box>

        {(displaySearchFilter || props?.enableFilters?.length > 0) && (
          <Box
            display="flex"
            flexflow="row wrap"
            justifyContent="space-between"
            my={2}
            px={2}
          >
            {(displaySearchFilter ||
              props?.enableFilters?.includes("search")) && (
              <Box width="350px">
                <Search
                  placeholder="Search"
                  allowClear
                  defaultValue={params?.query}
                  onSearch={onSearch}
                />
              </Box>
            )}

            {(props.heading !== "Project Circular DashBoard" ||
              props?.enableFilters?.includes("Available")) && (
              <Box mx="1rem">
                <Select
                  defaultValue={params?.status || props.defaultStatusValue}
                  style={{
                    width: 120,
                  }}
                  onChange={handleStatus}
                >
                  {console.log(props.statusfilter)}
                  {props.statusfilter.map((option) => (
                    <Option value={option} key={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}

            {(displaySearchFilter ||
              props?.enableFilters?.includes("date")) && (
              <Box mx="1rem">
                <SelectDate
                  defaultValue={
                    params.fromDate && params.toDate
                      ? [moment(params.fromDate), moment(params.toDate)]
                      : []
                  }
                  setDates={onDateChange}
                />
              </Box>
            )}
            {props.additionalFilter}
            {(displaySearchFilter ||
              props?.enableFilters?.includes("export")) && (
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Export
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    {props.exportComponent ? (
                      props.exportComponent
                    ) : (
                      <ExportCSV
                        csvData={lists}
                        fileName={props.heading}
                        variant="text"
                      />
                    )}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {lists.length > 0 && (
                      <CSVLink
                        headers={Object.keys(lists[0]).map((key) => ({
                          label: key,
                          key,
                        }))}
                        data={lists}
                        filename={props.heading}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        CSV
                      </CSVLink>
                    )}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Box>
        )}

        {lists ? (
          <>
            <Table stickyHeader style={{ border: "none" }}>
              <TableHead>
                <TableRow>
                  {columnHeadDetails.map((column) => (
                    <TableCell
                      key={column.id}
                      // className={classes.columnHeader}
                      style={{ minWidth: `${column.minWidth}` }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {lists ? (
                  <>
                    {lists.map((row, index) => {
                      let date = row?.created_at
                        ? row.created_at
                        : row.updated_at;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell>
                            <Typography className={classes.align}>
                              <ListAltIcon color="primary" />
                              {row[props.tablecol1]}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <PermIdentityIcon color="primary" />
                              {`${row.created_by?.first_name} ${row.created_by?.last_name}`}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <UpdateIcon color="primary" />

                              {date}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {props.visibleIcon && !!getPermissions?.View && (
                              <span>
                                <IconButton>
                                  <Link to={`${props.viewLink}/${row?.id}`}>
                                    <VisibilityIcon color="primary" />
                                  </Link>
                                </IconButton>
                              </span>
                            )}
                            {userMatch(row.id)}
                          </TableCell>
                          <TableCell align="center">
                            {StatusChecker(row.Status)}
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <PermIdentityIcon color="primary" />
                              {row.approved_by
                                ? `${row.approved_by.first_name} ${row.approved_by.last_name}`
                                : "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <UpdateIcon color="primary" />
                              {row.approved_at ? row.approved_at : "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <CommentOutlined color="primary" />
                              {row?.Comment || "N/A"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <div className="container">
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton variant="rect" height={200} />
                  </div>
                )}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className="container">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={200} />
          </div>
        )}
      </TableContainer>
      <Paper
        style={{
          width: "90%",
          margin: "80px auto",
          marginTop: "0",
        }}
      >
        <Paginator paginateResults={props.paginateResults} />
      </Paper>
      {!!getPermissions?.Add && (
        <Link to={props.link}>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.button}
            style={{ outline: "none" }}
          >
            <AddCircleRoundedIcon
              variant="contained"
              className={classes.buttonIcon}
            />
          </Fab>
        </Link>
      )}
    </div>
  );
};

FormDashboardPagination.defaultProps = {
  statusfilter,
  defaultStatusValue: "Available",
};

export default FormDashboardPagination;
