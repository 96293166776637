import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";

const FilterByValue = ({ handleByValueFilter }) => {
  const [fromValue, setFromValue] = useState(0);
  const [toValue, setToValue] = useState(0);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        color="primary"
        label="From Value"
        placeholder="From Value"
        type="number"
        onChange={(event) => setFromValue(event.target.value)}
        style={{ margin: "1rem" }}
      />
      <TextField
        color="primary"
        label="To Value"
        placeholder="To Value"
        type="number"
        onChange={(event) => setToValue(event.target.value)}
        style={{ margin: "1rem" }}
      />
      <Button
        variant="outlined"
        onClick={() => handleByValueFilter(fromValue, toValue)}
      >
        Filter
      </Button>
    </div>
  );
};

export default FilterByValue;
