import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DisbursementEmployeeOthers from "../../../../forms/AccountingSystem/Disbursement/DisbursementEmployeeOthersForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let AllPaymentRequest = [];

export const DisbursementEmployeeOtherCreate = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState();
  const [formDetails, setFormDetails] = useState();
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });

  const onSubmit = async (formValues) => {
    for (let key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.post(
      "/accountingSystem/disbursementEmployeeAndOthers",
      formValues
    );
    history.push("/accountingSystem/disbursementEmployeeAndOthers");
  };

  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  const selectPaymentRequest = async (PaymentRequestNo) => {
    let detail = AllPaymentRequest.find((item) =>
      item.PaymentRequestNo === PaymentRequestNo ? item : ""
    );
    if (detail) {
      let { data } = await axios.get(
        `/accountingSystem/scrutinyEmployeeeAndOthers/${detail.id}`
      );
      setPaymentDetails(data.paymentDetails);
      let payload = {
        ...formDetails,
        ...headerState,
        PaymentRequestNo: data.PaymentRequestNo,
        CustomerName: data.CustomerName,
        FinancialPeriod: data.FinancialPeriod,
        PaymentRequestDate: data.created_at,
        ProjectCodeName: data.ProjectCodeName,
        PaymentMode: data.PaymentMode,
        PaymentFor: data.PaymentFor,
        InFavourOf: data.InFavourOf,
        VendorCodeName: data.VendorCodeName,
        EmployeeCodeName: data.EmployeeCodeName,
        PAN: data.PAN,
        TotalPRValue: data.TotalPRValue,
        Remarks: data.Remarks,
      };
      setFormDetails(payload);
    }
  };

  useEffect(() => {
    async function getPaymentRequest() {
      try {
        const { data } = await axios.get(
          "/accountingSystem/scrutinyEmployeeeAndOthers"
        );
        AllPaymentRequest = data;
        let filteredData = data.filter((item) => item.Status === "accepted");
        let PRList = filteredData.map((item) => item.PaymentRequestNo);
        setList(PRList);
      } catch (err) {
        console.error("err", err);
      }
    }

    getPaymentRequest();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        setFormDetails({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }
    getData();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Disbursement Employee and Other Create"
        linkName1="Disbursement Employee and Others Dashboard"
        to1="/accountingSystem/disbursementEmployeeAndOthers"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Disbursement Employee and Other
      </h3>
      <DisbursementEmployeeOthers
        list={list}
        selectPaymentRequest={selectPaymentRequest}
        paymentDetails={paymentDetails}
        handleHeaderChange={handleHeaderChange}
        headerState={headerState}
        initialValues={pick(
          formDetails,
          "CostCentre",
          "FinancialPeriod",
          "Currency",
          "PaymentRequestNo",
          "PaymentRequestDate",
          "PaymentMode",
          "PaymentFor",
          "VendorCodeName",
          "EmployeeCodeName",
          "InFavourOf",
          "PAN",
          "ProjectCodeName",
          "CustomerName",
          "TotalPRValue",
          "AdvanceAsPerBooks",
          "VendorCodeName",
          "AdvanceAsPerBooks"
        )}
        onSubmit={onSubmit}
      />
    </div>
  );
};
