import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchBankById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const BankView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchBankById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleBank) {
      setData(store.commonMasterSystemRoot.SingleBank);
    }
  }, [store.commonMasterSystemRoot.SingleBank]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("bank", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <>
      <Breadcrumbs
        name="Bank View"
        linkName1="/commonMaster/bank"
        to1="Bank Dashboard"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Bank</h3>
          <h6>{`Created by ${data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
            }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.BankCode && (
              <Paper className={classes.paper}>
                <h6>Bank Code:</h6>
                <p>{data.BankCode}</p>
              </Paper>)}

            {data.BankName && (
              <Paper className={classes.paper}>
                <h6>Bank Name:</h6>
                <p>{data.BankName}</p>
              </Paper>)}

            {data.BankAddress && (
              <Paper className={classes.paper}>
                <h6>Bank Address:</h6>
                <p>{data.BankAddress}</p>
              </Paper>)}

            {data.City && (
              <Paper className={classes.paper}>
                <h6>City:</h6>
                <p>{data.City}</p>
              </Paper>)}

            {data.State && (
              <Paper className={classes.paper}>
                <h6>State:</h6>
                <p>{data.State}</p>
              </Paper>)}

            {data.PIN && (
              <Paper className={classes.paper}>
                <h6>PIN:</h6>
                <p>{data.PIN}</p>
              </Paper>)}

            {data.AccountNo && (
              <Paper className={classes.paper}>
                <h6>Account No:</h6>
                <p>{data.AccountNo}</p>
              </Paper>)}

            {data.IFSC && (
              <Paper className={classes.paper}>
                <h6>IFSC:</h6>
                <p>{data.IFSC}</p>
              </Paper>)}

            {data.MICR && (
              <Paper className={classes.paper}>
                <h6>MICR​:</h6>
                <p>{data.MICR}</p>
              </Paper>)}

            {data.AccountType && (
              <Paper className={classes.paper}>
                <h6>Account Type:</h6>
                <p>{data.AccountType}</p>
              </Paper>)}

            {data.AuthorisedSignatory1 && (
              <Paper className={classes.paper}>
                <h6>Authorised Signatory 1:</h6>
                <p>{data.AuthorisedSignatory1}</p>
              </Paper>)}

            {data.ChequeStartingNo && (
              <Paper className={classes.paper}>
                <h6>Cheque Starting No:</h6>
                <p>{data.ChequeStartingNo}</p>
              </Paper>)}

            {data.AuthorisedSignatory2 && (
              <Paper className={classes.paper}>
                <h6>Authorised Signatory 2:</h6>
                <p>{data.AuthorisedSignatory2}</p>
              </Paper>)}
            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
