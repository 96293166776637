import React from "react";
import Route from "../PrivateRouter";
import * as Dashboard from "../../pages/SalesAcounts/Dashboard/export";
import * as Create from "../../pages/SalesAcounts/Create/export";
import * as View from "../../pages/SalesAcounts/View/export";
import * as Edit from "../../pages/SalesAcounts/Edit/export";
import * as Reports from "../../pages/SalesAcounts/Reports/export";
import { Switch } from "react-router-dom";

const SalesAccounting = (
  <Switch>
    <Route
      exact
      path="/salesAccounting/reports"
      component={Reports.ReportsList}
    />
    <Route
      exact
      path="/salesAccounting/reports/projectCircullar"
      component={Reports.ProjectCircularReport}
    />
    <Route
      exact
      path="/salesAccounting/reports/salesInvoice"
      component={Reports.SalesInvoiceReport}
    />

    {/* Dash */}
    <Route
      exact
      path="/salesAccounting/projectCircular"
      component={Dashboard.ProjectCircularDashBoard}
    />
    <Route
      exact
      path="/salesAccounting/salesOrder"
      component={Dashboard.SalesOrderDashboard}
    />
    <Route
      exact
      path="/salesAccounting/salesOrderAmendment"
      component={Dashboard.SalesOrderAmendmentDashboard}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoice"
      component={Dashboard.SalesInvoiceDashboard}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceReversal"
      component={Dashboard.SalesInvoiceReversal}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceCertification"
      component={Dashboard.SalesInvoiceCertificationDashboard}
    />
    {/* Create */}
    <Route
      exact
      path="/salesAccounting/projectCircular/create"
      component={Create.ProjectCircularCreate}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoice/create"
      component={Create.SalesInvoiceCreate}
    />
    <Route
      exact
      path="/salesAccounting/salesOrder/create"
      component={Create.SalesOrderCreate}
    />
    <Route
      exact
      path="/salesAccounting/salesOrderAmendment/create"
      component={Create.SalesOrderAmendmentCreate}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceReversal/create"
      component={Create.SalesInvoiceReversalCreate}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceCertification/create"
      component={Create.SalesInvoiceCertificationCreate}
    />
    {/* {View} */}
    <Route
      exact
      path="/salesAccounting/projectCircular/view/:id"
      component={View.ProjectCircularView}
    />
    <Route
      exact
      path="/salesAccounting/salesOrder/view/:id"
      component={View.SalesOrderView}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceReversal/view/:id"
      component={View.SalesInvoiceReversalView}
    />
    <Route
      exact
      path="/salesAccounting/salesOrderAmendment/view/:id"
      component={View.SalesOrderAmendmentView}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoice/view/:id"
      component={View.SalesInvoice}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceCertification/view/:id"
      component={View.SalesInvoiceCertification}
    />
    {/* {Edit} */}
    <Route
      exact
      path="/salesAccounting/projectCircular/edit/:id"
      component={Edit.ProjectCircularEdit}
    />
    <Route
      exact
      path="/salesAccounting/salesOrder/edit/:id"
      component={Edit.SalesOrderEdit}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoice/edit/:id"
      component={Edit.SalesInvoiceEdit}
    />
    <Route
      exact
      path="/salesAccounting/salesOrderAmendment/edit/:id"
      component={Edit.SalesOrderAmendmentEdit}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceReversal/edit/:id"
      component={Edit.SalesInvoiceReversalEdit}
    />
    <Route
      exact
      path="/salesAccounting/salesInvoiceCertification/edit/:id"
      component={Edit.SalesInvoiceCertificationEdit}
    />
  </Switch>
);

export default SalesAccounting;
