import React ,{useState} from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/CommonMaster/GSTTable";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Vendor = (props) => {
     const [state, setState] = useState({
       tableData: null,
       tableError: false,
       tableErrorKind: "",
       isSubmit: false,
       selection: "",
     });
     const [show, setShow] = useState(false);

     const tableData = (data) => {
      if (!data) {
         setState({
           ...state,
           tableErrorKind: "table row are not fully field",
           tableError: true,
         });
       } else {
         setState({
           ...state,
           tableData: data,
           tableErrorKind: "",
           tableError: false,
         });
       }
     };
     const toggleModal = () => setShow(!show);
     const onSubmit = (formValues) => {
       if (!state.tableData) {
        props.onSubmit({
          ...formValues,
        });
       } else if (state.tableData) {
         props.onSubmit({
           ...formValues,
           tableData: state.tableData,
         });
       }
     };

  const onSelect = (value) => {
    setState({
      ...state,
      selection: value,
    });
  };
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="VendorCode"
            label="Vendor Code"
          />
          <Field
            component={Control.RenderTextField}
            name="VendorName"
            label="Vendor Name"
          />
          <Field
            component={Control.RenderTextField}
            name="CorporateOffice"
            label="Corporate Office"
          />
          <Field
            component={Control.RenderTextField}
            name="RegisteredOffice"
            label="Registered Office"
          />
          <Field
            component={Control.RenderTextField}
            name="Phone"
            label="Phone​"
          />
          <Field
            component={Control.RenderTextField}
            name="Email"
            label="Email"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="GSTStatus"
            label="GST Status"
            className="text-field"
            list={["", "Registered", "Unregistered"]}
            onChange={onSelect}
          />
          <Field
            component={Control.RenderTextField}
            name="Website"
            label="Website"
          />
          <Field component={Control.RenderTextField} name="PAN" label="PAN" />
          <Field
            component={Control.RenderTextField}
            name="ServiceTaxRegNo"
            label="Service Tax Reg No"
          />
          <Field
            component={Control.RenderTextField}
            name="CINLLPIN"
            label="CIN / LLPIN"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tag"
            label="Vendor Tag"
            className="text-field"
            list={[" ", "Sub-contractor", "Sub-supplier"]}
          />
        </div>
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
          Vendor Bank Details
        </h4>
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="VendorBankName"
            label="Bank Name​"
          />
          <Field
            component={Control.RenderTextField}
            name="VendorIFSC"
            label="IFSC Code"
          />
          <Field
            component={Control.RenderTextField}
            name="VendorAccount"
            label="Account no"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            className="text-field"
            list={[" ", "Active", "Inactive"]}
          />
        </div>
        {state.selection === "Registered" && (
          <div className="">
            <Table
              tableData={tableData}
              gsts={props.gsts}
              StateList={props.stateList}
            />
            <div className="middle">
              <p className=" Mui-error excelError">
                {state.tableError ? `${state.tableErrorKind}` : ""}
              </p>
            </div>
          </div>
        )}
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Vendor"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "VendorCode",
    "VendorName",
    "Phone",
    "Email",
    "GSTRegNo",
    "Tag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "Vendor",
  validate,
  enableReinitialize: true,
})(Vendor);