import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReceiptEmployeeOtherForm from "../../../../forms/AccountingSystem/Receipt/ReceiptEmployeeOtherForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const ReceiptEmployeeOtherCreate = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [bankList, setBankList] = useState([]);

  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.post(
      "/accountingSystem/receiptEmployeeOther/",
      formValues
    );
    console.log(response);
    history.push("/accountingSystem/receiptEmployeeOther");
  };

  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );

      detail = {
        ...formDetail,
        ...headerState,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      setformDetail(detail);
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    async function getEmployees() {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        let newData = data.filter((item) => item.Tags === "Active");
        const mapped = newData.map((item) => item.EmployeeCode);
        setEmployeeList(mapped);
      } catch (err) {
        console.error(err);
      }
    }
    async function getCustomer() {
      try {
        const { data } = await axios.get("/commonMaster/customer");
        const mapped = data.map((item) => item.CustomerName);
        setCustomerList(mapped);
      } catch (err) {
        console.error(err);
      }
    }

    async function getVendor() {
      try {
        const { data } = await axios.get("/commonMaster/vendor");

        let newData = data.filter((item) => item.Tags === "Active");
        const mapped = newData.map((item) => item.VendorName);
        setVendorList(mapped);

        const { data: bank } = await axios.get("/commonMaster/bank");
        const banklist = bank.map(
          (item) => item.BankCode + " - " + item.BankName
        );
        setBankList(banklist);
      } catch (err) {
        console.error(err);
      }
    }
    async function getData() {
      try {
        setformDetail({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }
    getData();
    getVendor();
    getEmployees();
    fetchProject();
    getCustomer();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Payment Receipt - Employee and Other Create"
        linkName1="Payment Receipt - Employee and Other Dashboard"
        to1="/accountingSystem/receiptEmployeeOther"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Receipt - Employee and Other
      </h3>
      <ReceiptEmployeeOtherForm
        onSubmit={onSubmit}
        employeeList={employeeList}
        customerList={customerList}
        vendorList={vendorList}
        list={list}
        bankList={bankList}
        selectProjectName={selectProjectName}
        handleHeaderChange={handleHeaderChange}
        headerState={headerState}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "FinancialPeriod",
          "CostCentre"
        )}
      />
    </div>
  );
};
