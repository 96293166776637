import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { upperFirst } from "lodash";
import RoleList from "../../components/helpers/RoleList";
import { toTitleCase } from "../common";

const usePermissions = (props) => {
  const { pathname } = useLocation();
  const store = useSelector((store) => store);
  const position = store?.userRoot?.workingOn?.Role;
  try {
    let moduleName = props?.module || pathname?.split("/")?.[1];
    const accessPermissions = store?.userRoot?.workingOn?.accessPermissions;
    moduleName = upperFirst(toTitleCase(moduleName));
    return position === RoleList.ADMIN.code
      ? {
          View: true,
          Modify: true,
          Cancel: true,
          Enabled: true,
          Approval: true,
          Add: true,
        }
      : JSON.parse(accessPermissions)?.[moduleName];
  } catch (error) {
    return null;
  }
};

export default usePermissions;
