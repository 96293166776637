import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchMachineDeHireRequestById,
  approvalMachineManagement,
} from "../../../redux/actions/MachineManagementaction";
import useStyle from "./UseStyle";
import RoleList from "../../../components/helpers/RoleList";

export const MachineDeHireRequestView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchMachineDeHireRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.machineMangementSystemRoot.SingleMachineDeHireRequest) {
      setData(store.machineMangementSystemRoot.SingleMachineDeHireRequest);
    }
  }, [store.machineMangementSystemRoot.SingleMachineDeHireRequest]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalMachineManagement(
        "machineDeHireRequest",
        match.params.id,
        history,
        data
      )
    );
  };

  const acessButton = () => {
    if (
      post === RoleList.ADMIN.code ||
      post === RoleList.SITE_INCHARGE.code ||
      post === RoleList.BU_COMMERCIAL.code ||
      post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
      post === RoleList.BU_HEAD.code ||
      post === RoleList.DIRECTOR.code
    )
      return (
        <div className={classes.ButtonGrp}>
          <Button
            variant="contained"
            className={classes.approve}
            onClick={() => onApprove("Approved")}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            className={classes.reject}
            onClick={() => onApprove("Rejected")}
          >
            Reject
          </Button>
        </div>
      );
    return;
  };
  return (
    <>
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Machine De Hire Request</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Request Type:</h6>
              <p>{data.RequestType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Category:</h6>
              <p>{data.MachineCategory}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Currency:</h6>
              <p>{data.Currency}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Machine Code / Name:</h6>
              <p>{data.MachineCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Surplus Date:</h6>
              <p>{data.SurplusDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Last Log Sheet Date:</h6>
              <p>{data.LastLogSheetDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Time Required To Release:</h6>
              <p>{data.TimeRequiredToRelease}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Remarks​:</h6>
              <p>{data.Remarks}</p>
            </Paper>
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
