import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import axios from "../../../components/apis/axios";
import { editprojectCircular } from "../../../redux/actions/SalesAcounts";

import ProjectCircular from "../../../forms/SalesAccountingSystem/ProjectCircularForm";
import {
  allBusinessUnit,
  allCurrency,
  allCustomer,
  allWareHouse,
  allCostCentre,
} from "../../../redux/actions/CommonMasterAction";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllBusinessUnit = [];
let AllCurrency = [];
let AllCustomer = [];
let AllWareHouse = [];
let AllCostCentre = [];
let allCompany = [];

export const ProjectCircularEdit = (props) => {
  const [projectCircular, setProjectCircular] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const user = useSelector((store) => store.userRoot);

  const { match } = props;
  const [businessUnitlist, setBusinessUnitlist] = useState([]);
  const [currencylist, setCurrencylist] = useState([]);
  const [customerNamelist, setcustomerNamelist] = useState([]);
  const [wareHouselist, setWareHouselist] = useState([]);
  const [costCentrelist, setCostCentrelist] = useState([]);
  const [warehouseGsts, setWarehouseGsts] = useState([]);
  const [costCenterGsts, setCostCenterGsts] = useState([]);
  const [customerGsts, setCustomerGsts] = useState([]);
  const [previousFiles, setPreviousFiles] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const [formDetail, setformDetail] = useState({});

  useEffect(() => {
    dispatch(allBusinessUnit());
    dispatch(allCurrency());
    dispatch(allCustomer());
    dispatch(allWareHouse());
    dispatch(allCostCentre());
  }, [dispatch]);

  useEffect(() => {
    if (store.AllCostCentre) {
      AllCostCentre = store.AllCostCentre;
      let CCList = AllCostCentre.map((item) => item.CostCenterName);
      setCostCentrelist(CCList);
    }
    if (store.AllCurrency) {
      AllCurrency = store.AllCurrency;
      let CList = AllCurrency.map(
        (item) => item.CurrencyCode + "-" + item.CurrencyName
      );
      setCurrencylist(CList);
    }
    if (store.AllWareHouse) {
      AllWareHouse = store.AllWareHouse;

      let WHlist = AllWareHouse.map((item) => item.WareHouseName);
      setWareHouselist(WHlist);
    }
    if (store.AllBusinessUnit) {
      AllBusinessUnit = store.AllBusinessUnit;

      let BUlist = AllBusinessUnit.map(
        (item) => item.BusinessUnit + "-" + item.BusinessDescription
      );
      setBusinessUnitlist(BUlist);
    }
    if (store.AllCustomer) {
      AllCustomer = store.AllCustomer;
      let CNlist = AllCustomer.map(
        (item) => item.CustomerName + "-" + item.CustomerCode
      );
      setcustomerNamelist(CNlist);
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        const filteredData = data.map((item) => item.CompanyName);
        setCompanyList(filteredData);
        allCompany = data;
      } catch (err) {
        console.error(err);
      }
    }
    getCompanyDetails();
  }, [
    store.AllCostCentre,
    store.AllCurrency,
    store.AllCustomer,
    store.AllWareHouse,
    store.AllBusinessUnit,
  ]);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/projectCircular/${match.params.id}`
        );
        let data = response.data;
        setProjectCircular(data);
        setPreviousFiles([...data.Files]);
      } catch (err) {
        console.error(err);
      }
    }
    console.log(match.params);
    fetch();
  }, [match.params]);

  const findGst = async (value, findFor) => {
    let id;
    if (findFor === "wareHouse")
      id = AllWareHouse.find((item) => item.WareHouseName === value);
    else if (findFor === "costCentre")
      id = AllCostCentre.find((item) => item.CostCenterName === value);
    else if (findFor === "customer")
      id = AllCustomer.find(
        (item) => item.CustomerName + "-" + item.CustomerCode === value
      );
    else if (findFor === "companyDetails")
      id = allCompany.find((item) => item.CompanyName === value);
    try {
      const payload = await axios.get(`commonMaster/${findFor}/${id.id}`);
      const gsts = payload.data.Gsts;
      // console.log(gsts);
      const gstRegNos = gsts.map((item) => item.GSTRegNo);
      if (findFor === "wareHouse") setWarehouseGsts(gstRegNos);
      else if (findFor === "costCentre") setCostCenterGsts(gstRegNos);
      else if (findFor === "customer") setCustomerGsts(gstRegNos);
      else if (findFor === "companyDetails") {
        setCompanyGsts(gstRegNos);

        if (user.user.first_name === "admin") {
          const payload = await axios.post(
            `/commonMaster/businessUnit/getBusinessUnitByCompany`,
            {
              CompanyName: id.CompanyName,
            }
          );
          let businessUnit = payload.data.map(
            (item) => item.BusinessUnit + "-" + item.BusinessDescription
          );

          setBusinessUnitlist(businessUnit);
        } else {
          setformDetail({
            ...formDetail,
            CompanyName: user.user?.company,
            BusinessUnit: user.workingOn.BusinessUnit,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (formValues, history) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    dispatch(editprojectCircular(formValues, props.match.params.id, history));
  };

  return (
    <div>
      <BreadCrumbs
        name="Project Circular Edit"
        linkName1="Project Circular Dashboard"
        to1="/salesAccounting/projectCircular"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Project Circular Edit - {projectCircular.ProjectCode}
      </h3>
      <div className="line"></div>
      <ProjectCircular
        CCList={costCentrelist}
        CList={currencylist}
        CNList={customerNamelist}
        BUList={businessUnitlist}
        WHList={wareHouselist}
        warehouseGsts={warehouseGsts}
        costCenterGsts={costCenterGsts}
        customerGsts={customerGsts}
        companyGsts={companyGsts}
        companyList={companyList}
        findGst={findGst}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        initialValues={pick(
          projectCircular,
          "CompanyName",
          "CompanyGstNo",
          "CustomerGstNo",
          "BusinessUnit",
          "Currency",
          "DirectSub",
          "CustomerName",
          "ProjectOrderValue",
          "OrderReferenceNo",
          "OrderReferenceDate",
          "OrderType",
          "ProjectStartDate",
          "ProjectEndDate",
          "Duration",
          "ProjectShortDescription",
          "ProjectLongDescription",
          "InvoiceTo",
          "ConsigneeName",
          "InvoiceAddress",
          "DeliveryAddress",
          "CostCentre",
          "WareHouse",
          "WareHouseGst",
          "CostCentreGst",
          "CostCentreIfExisting",
          "WareHouseIfExisting",
          "OwnerIfSub",
          "OriginalFormNo",
          "projectStatus"
        )}
        user={user}
        edit={true}
        previousFiles={previousFiles}
      />
    </div>
  );
};
