import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MajorSCItemGroup from "../../../forms/CommonMaster/MajorSCItemGroup";
import { useHistory } from "react-router-dom";
import {
  editMajorSCItemGroup,
  searchMajorSCItemGroupById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const MajorSCItemGroupEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [UOM, setUOM] = React.useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchMajorSCItemGroupById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMajorSCItemGroup) {
      setData(store.SingleMajorSCItemGroup);
    }
  }, [store.SingleMajorSCItemGroup]);
  const onSubmit = (formValues, history) => {
    dispatch(editMajorSCItemGroup(formValues, props.match.params.id, history));
  };
  React.useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        console.log("data", data);
        let unitOfMeasurement = data.map(
          (item) => item.UOMCode + "-" + item.UOMDescription
        );
        setUOM(unitOfMeasurement);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Major SC Item Group Edit"
        linkName1="Major SC Item Group Dashboard"
        to1="/commonMaster/MajorSCItemGroup"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Major SC Item Group Edit
      </h3>
      <div className="line"></div>
      <MajorSCItemGroup
        UOM={UOM}
        initialValues={pick(
          data,
          "MajorSCItemGroupCodeDescription",
          "Status",
          "UOM",
          "GroupCategory",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
