import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Customer from "../../../forms/CommonMaster/Customer";
import { useHistory } from "react-router-dom";
import {
  editCustomer,
  searchCustomerById,
} from "../../../redux/actions/CommonMasterAction";
import StateList from "../../../components/helpers/StateList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CustomerEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    dispatch(searchCustomerById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleCustomer) {
      setData(store.SingleCustomer);
    }
    let state = StateList.map((item) => item.name);
    setStateList(state);
  }, [store.SingleCustomer]);

  const onSubmit = (formValues) => {
    dispatch(editCustomer(formValues, props.match.params.id, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Customer Edit"
        linkName1="Customer Dashboard"
        to1="/commonMaster/customer"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Customer Edit
      </h3>
      <div className="line"></div>
      <Customer
        initialValues={pick(
          data,
          "CustomerCode",
          "CustomerName",
          "CorporateOffice",
          "RegisteredOffice",
          "Phone​",
          "Email",
          "Website",
          "PAN",
          "GSTState",
          "ServiceTaxRegNo",
          "GSTRegNo",
          "CINLLPIN",
          "Tags"
        )}
        edit="true"
        gsts={data.Gsts}
        stateList={stateList}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
