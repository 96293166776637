import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../style/materialSystem.css";
import WarehouseReturnVoucherForm from "../../../forms/MaterialSystem/WarehouseReturnVoucherForm";
import { warehouseReturnVoucher } from "../../../redux/actions/materialSystemAction";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllWarehouseIssueVoucher = [];

export const WarehouseReturnVoucherFormCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [wareHouseIssueList, setWareHouseIssueList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [Warehouse, setWarehouse] = useState("");
  const [stocksDetails, setStocksDetails] = useState(null);
  const [materials, setMaterials] = useState(null);

  const onSubmit = (formValues) => {
    delete formValues["GoodsIssueType"];
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(warehouseReturnVoucher(formValues, history));
  };

  const selectProject = (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      console.log(detail);
      let payload = {
        ...formDetail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Warehouse: detail.WareHouse,
      };
      setWarehouse(detail.WareHouse);
      setFormDetail(payload);
      fetchWarehouseIssue(projectName);
    }
  };

  const selectWarehouseIssue = async (issueNo) => {
    const item = AllWarehouseIssueVoucher.find(
      (item) => item.WarehouseIssueVoucherNo === issueNo
    );
    if (item) {
      let { data } = await axios.get(
        `/materialManagement/warehouseIssue/${item.id}`
      );
      setMaterials(data.materials);
      console.log(data);
      try {
        const materialCodes = data.materials.map((item) => item.MaterialCode);
        const { data: stocks } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          {
            WareHouse: Warehouse,
            Code: materialCodes,
          }
        );
        setStocksDetails(stocks);
      } catch (err) {
        console.log(err);
      }
      data = {
        WarehouseIssueVoucherNo: issueNo,
        GoodsIssueType: data.GoodsIssueType,
        WorkSalesNo: data.WorkSalesNo,
        WorkOrderNo: data.WorkOrderNo,
        CustomerCodeName: data.CustomerCodeName,
        Date: data.Date,
        DateofIssue: data.DateofIssue,
        VendorCodeName: data.VendorCodeName,
        Remarks: data.Remarks,
      };
      setFormDetail({ ...formDetail, ...data });
    }
  };

  async function fetchWarehouseIssue(projectName) {
    try {
      let { data } = await axios.post(
        "/materialManagement/warehouseIssue/getByProjectName",
        {
          ProjectCodeName: projectName,
        }
      );
      AllWarehouseIssueVoucher = data;
      const mapped = data.map((item) => item.WarehouseIssueVoucherNo);
      setWareHouseIssueList(mapped);
    } catch (err) {
      console.error(err);
    }
  }

  async function fetchProject() {
    try {
      let { data } = await axios.get("/salesAccounting/projectCircular");
      const filtered = data.filter((item) => item.Status === "accepted");
      allProject = filtered;
      let PNlist = filtered.map((item) => item.ProjectShortDescription);
      setProjectList(PNlist);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Return Voucher  Create"
        linkName1=" Warehouse Return Voucher  Dashboard"
        to1="/materialManagement/WarehouseReturn"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Return Voucher Create
      </h3>
      <div className="line"></div>
      <WarehouseReturnVoucherForm
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProject={selectProject}
        WIlist={wareHouseIssueList}
        selectWarehouseIssue={selectWarehouseIssue}
        materialsDataCreate={materials}
        stocksDetails={stocksDetails}
        initialValues={pick(
          formDetail,
          "Warehouse",
          "ProjectCodeName",
          "CustomerName",
          "ProjectName",
          "WarehouseIssueVoucherNo",
          "GoodsIssueType",
          "WorkSalesNo",
          "WorkOrderNo",
          "Date",
          "CustomerCodeName",
          "DateofIssue",
          "VendorCodeName"
        )}
      />
    </div>
  );
};
