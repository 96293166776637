import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Bank from "../../../forms/CommonMaster/Bank";
import { bank } from "../../../redux/actions/CommonMasterAction";
import StateList from "../../../components/helpers/StateList";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const BankCreate = () => {
  const [stateList, setStateList] = React.useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(bank(formValues, history));
  };

  React.useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Bank Create"
        linkName1="Bank Dashboard"
        to1="/commonMaster/bank"
      />
      <FormHeading label="Bank" />
      <Bank onSubmit={onSubmit} stateList={stateList} />
    </div>
  );
};
