import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../helpers/UseStyle";
import { IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";

const ReportsTable = (props) => {
  const classes = useStyles();
  // const [filterSelected, setFilterSelected] = useState("");

  // const handleFilterSelect = (event) => {
  //   setFilterSelected(event.target.value);
  // };

  return (
    <>
      {/* <div
        id="filter-select"
        style={{
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControl style={{ margin: "1rem" }}>
          <InputLabel>Project For</InputLabel>
          <Select
            name="projectFor"
            value={filterSelected}
            onChange={(event) => {
              handleFilterSelect(event);
            }}
            style={{ width: "10rem" }}
          >
            <MenuItem value={props.lists.EmployeeCodeName}>Employee</MenuItem>
            <MenuItem value={"Vendor"}>Vendor</MenuItem>
            <MenuItem value={"Others"}>Others</MenuItem>
          </Select>
        </FormControl>
        {props.lists.map((item, index) => (
          <div key={index}>
            {filterSelected === item.EmployeeCodeName ? (
              <>{item.EmployeeCodeName}</>
            ) : filterSelected === item.VendorCodeName ? (
              "Employee"
            ) : (
              "Others"
            )}
          </div>
        ))}
      </div> */}
      <TableContainer className={classes.table} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Payment For</TableCell>
              <TableCell align="center">Project Name</TableCell>
              <TableCell align="center">Amount</TableCell>
              {props.viewDetails && (
                <TableCell align="center">Operation</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.lists.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {item.VendorCodeName ||
                    item.EmployeeCodeName ||
                    item.InFavourOf}
                </TableCell>
                <TableCell align="center">
                  {item.VendorCodeName
                    ? "Vendor"
                    : item.EmployeeCodeName
                    ? "Employee"
                    : "Others"}
                </TableCell>
                <TableCell align="center">{item.ProjectCodeName}</TableCell>
                <TableCell align="center">{item[props.tablecol3]}</TableCell>
                {props.viewDetails && (
                  <TableCell align="center">
                    <span>
                      <IconButton
                        onClick={() => props.onViewDetailsClick(item)}
                      >
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </span>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportsTable;
