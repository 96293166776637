import axios from "../../components/apis/axios";

export const setUserProjectAndPosition = (data) => {
  const { ProjectName, Role, BusinessUnit, Company } = data;
  if (Role) {
    axios.defaults.headers["position"] = Role || '';
    axios.defaults.headers["project"] = ProjectName || '';
    axios.defaults.headers["unit"] = BusinessUnit || '';
    axios.defaults.headers["company"] = Company || '';
  } else {
    delete axios.defaults.headers.common["position"];
    delete axios.defaults.headers.common["project"];
    delete axios.defaults.headers.common["unit"];
    delete axios.defaults.headers.common["company"];
  }
};
