import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Switch,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import { Field, change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { RenderCheckBoxField } from "./CheckBox";
import { RenderSwitchField } from "./Switch";
import RoleList from "../helpers/RoleList";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ margin: "1rem 0 0 1rem" }}>{children}</div>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  activeTabs: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "90%",
  },
}));

const AccessControlTabLayout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { labels, tables, activeTabs } = props;
  console.log({ props });
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const fieldValues = store?.form?.AccessRequest?.values;
  const position = props?.inputField?.Role;

  const BU_COMMERCIAL = position === RoleList.BU_COMMERCIAL.code;
  const BU_HEAD = position === RoleList.BU_HEAD.code;

  const fields = [
    {
      label: "Add",
      defaultChecked: BU_COMMERCIAL,
    },
    {
      label: "Modify",
      defaultChecked: BU_COMMERCIAL,
    },
    {
      label: "View",
      defaultChecked: true,
    },
    {
      label: "Cancel",
      defaultChecked: BU_COMMERCIAL,
    },
    {
      label: "Approval",
      defaultChecked: BU_HEAD,
    },
    {
      label: "Enabled",
      type: "switch",
    },
  ];

  useEffect(() => {
    let tdata = props.inputFields;
    let row = tdata[props.index];
    console.log({ row, tdata, fieldValues });

    try {
      if (fieldValues?.accessPermissions) {
        row.accessPermissions = fieldValues?.accessPermissions[props.index];
      }
      if (fieldValues?.attributes) {
        Object.entries(fieldValues?.attributes).forEach((attr) => {
          row.attributes[attr[0]] = { ...row.attributes[attr[0]], ...attr[1] };
        });
      }
      console.log({ after: row });
      tdata[props.index] = row;
    } catch (err) {
      console.error(err);
    } finally {
      props.setInputFields(tdata);
      props.tableData(tdata);
    }
  }, [JSON.stringify(fieldValues)]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const permissionsJson = fieldValues?.accessPermissions
    ? fieldValues?.accessPermissions[props?.index]
    : null;

  useEffect(() => {
    if (props?.inputField?.id === "new") {
      labels?.map((item) => {
        fields?.map((field) => {
          let isChecked = !!field?.defaultChecked;
          if (field?.label === "Enabled") {
            if (
              (item === "CommonMaster" || item === "ProjectHrManagement") &&
              position !== RoleList.ADMIN.code
            ) {
              isChecked = false;
            } else {
              isChecked = true;
            }
          }
          dispatch(
            change(
              "AccessRequest",
              `accessPermissions[${props.index}].${item}.${field?.label}`,
              isChecked
            )
          );
        });
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={labels[0]?.label} {...a11yProps(0)} />
          <Tab label={labels[1]?.label} {...a11yProps(1)} />
          <Tab label={labels[2]?.label} {...a11yProps(2)} />
          <Tab label={labels[3]?.label} {...a11yProps(3)} />
          <Tab label={labels[4]?.label} {...a11yProps(4)} />
          <Tab label={labels[5]?.label} {...a11yProps(5)} />
          <Tab label={labels[6]?.label} {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ maxHeight: "400px" }}
      >
        {labels?.map((item, labelIndex) => (
          <TabPanel
            key={labelIndex}
            value={value}
            index={labelIndex}
            dir={theme.direction}
          >
            <div className="newformcontaine">
              <form className="form-main">
                <Typography variant="h5">MODULE PERMISSIONS</Typography>
                <Divider />
                <div style={{ flexDirection: "row", display: "flex" }}>
                  {fields?.map((field, i) => (
                    <React.Fragment key={i}>
                      <Field
                        component={
                          field?.type === "switch"
                            ? RenderSwitchField
                            : RenderCheckBoxField
                        }
                        name={`accessPermissions[${props.index}].${item?.label}.${field?.label}`}
                        label={field?.label}
                        checked={
                          permissionsJson &&
                          permissionsJson?.[item?.label] &&
                          permissionsJson?.[item?.label]?.[field?.label] !==
                            undefined
                            ? permissionsJson?.[item?.label]?.[field?.label]
                            : null
                        }
                      />
                    </React.Fragment>
                  ))}
                </div>
              </form>
              {props.inputField.attributes &&
                Object.entries(props.inputField.attributes)
                  .filter((attr) => attr[0].split("_")[0] === item.value)
                  .map((attr, key) => (
                    <Box key={key}>
                      <Typography variant="h5">
                        {attr[0]
                          .split("_")[1]
                          ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                          .toUpperCase()}
                      </Typography>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {Object.entries(attr[1]).map((attrJson, index) => (
                          <Field
                            key={index}
                            component={RenderCheckBoxField}
                            name={`attributes[${attr[0]}].${attrJson[0]}`}
                            label={attrJson[0]}
                            defaultChecked={
                              attr ? attr[1]?.[attrJson[0]] : false
                            }
                          />
                        ))}
                      </Box>
                    </Box>
                  ))}
            </div>
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default AccessControlTabLayout;
