import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalSalesAccounting } from "../../../redux/actions/SalesAcounts";

import LinearProgress from "@material-ui/core/LinearProgress";

import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SalesInvoiceReversalView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesInvoiceReversal/${match.params.id}`
        );
        let data = response.data;
        console.log("salesInvoiceReversal", data);
        data.created_at = new Date(data.created_at).toLocaleString();
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalSalesAccounting(
        "salesInvoiceReversal",
        match.params.id,
        history,
        data
      )
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Sales Invoice Reversal View"
        linkName1="Sales Invoice Reversal Dashboard"
        to1="/salesAccounting/salesInvoiceReversal"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Sales Invoice Reversal </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                {data.OriginalFormNo && 
                <Paper className={classes.paper}>
                <h6>Oridinal Form No:</h6>
                <p>{data.OriginalFormNo}</p>
              </Paper>}
                <Paper className={classes.paper}>
                  <h6>Sales Invoice Reversal No:</h6>
                  <p>{data.SalesInvoiceReversalNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Project Code Name:</h6>
                  <p>{data.ProjectCodeName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer Name:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Invoice No:</h6>
                  <p>{data.InvoiceNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Invoice Date:</h6>
                  <p>{data.InvoiceDate}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Gross Invoice Value:</h6>
                  <p>{data.GrossInvoiceValue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Recovery Value:</h6>
                  <p>{data.RecoveryValue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Net Invoice Value:</h6>
                  <p>{data.NetInvoiceValue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Reverse Value:</h6>
                  <p>{data.ReverseValue}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Comment For Reversal:</h6>
                  <p>{data.CommentForReversal}</p>
                </Paper>
              </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>
            {(post === RoleList.ADMIN.code ||
              post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"Project Circular"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
