import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import SCItemCodeRequest from "../../../forms/CommonMaster/SCItemCodeRequest";
import { useHistory } from "react-router-dom";
import {
  editSCItemCodeRequest,
  searchSCItemCodeRequestById,
} from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SCItemCodeRequestEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [UOM, setUOM] = useState([]);
  const [majorSCItem, setMajorSCItem] = useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchSCItemCodeRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  console.log(store);
  useEffect(() => {
    if (store.SingleSCItemCodeRequest) {
      setData(store.SingleSCItemCodeRequest);
    }
  }, [store.SingleSCItemCodeRequest]);
  const onSubmit = (formValues, history) => {
    dispatch(editSCItemCodeRequest(formValues, props.match.params.id, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        console.log("data", data);
        let unitOfMeasurement = data.map(
          (item) => item.UOMCode + "-" + item.UOMDescription
        );
        setUOM(unitOfMeasurement);
      } catch (err) {}
    }
    async function MajorSCItem() {
      try {
        const { data } = await axios.get("/commonMaster/MajorSCItemGroup");
        // console.log("data", data);
        let mapedData = data.map((item) => item.MajorSCItemGroupCode);

        setMajorSCItem(mapedData);
        // AllMajorSCItem = data;
      } catch (e) {
        console.log(e);
      }
    }
    MajorSCItem();
    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="SC Item Code Request Edit"
        linkName1="SC Item Code Request Dashboard"
        to1="/commonMaster/SCItemCodeRequest"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        SC Item Code Request Edit
      </h3>
      <div className="line"></div>
      <SCItemCodeRequest
        majorSCItem={majorSCItem}
        UOM={UOM}
        initialValues={pick(
          data,
          "MajorSCItemGroupCode",
          "MajorSCItemGroupDescription",
          "SCItemDescription",
          "UOM",
          "Compnonent",
          "ComponentPercentage",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
