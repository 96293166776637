import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";

const WarehouseIssueVoucher = ({
  materials,
  materialsTable,
  stocksDetails,
  storeTableData,
}) => {
  const [loading, setLoading] = useState(true);
  const [inputFields, setInputFields] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let values = [];
    const materials = materialsTable;
    console.log(materials);
    if (materials) {
     
      for (let i = 0; i < materials.length; i++) {
        try {
          values = [
            ...values,
            {
              SerialNo: materials[i].SerialNo,
              MaterialCode: materials[i].MaterialCode,
              MaterialDescription: materials[i].MaterialDescription,
              UOM: materials[i].UOM,
              ActivityMachineSelect: materials[i].ActivityMachineSelect,
              ActivityMachineDescription:
                materials[i].ActivityMachineDescription,
              IntentQty: materials[i].IntentQty,
              NetRate: stocksDetails[i].NetRate,
            },
          ];
        } catch (err) {
          console.log(err);
        }
      }
      setInputFields(values);
      onChange(values);
 
    }
    // eslint-disable-next-line
  }, [materialsTable, stocksDetails]);

  useEffect(() => {
    let values = [];
    if (materials) {
   
      for (let i = 0; i < materials.length; i++) {
        values = [
          ...values,
          {
            SerialNo: materials[i].SerialNo,
            MaterialCode: materials[i].MaterialCode,
            MaterialDescription: materials[i].MaterialDescription,
            UOM: materials[i].UOM,
            ActivityMachineSelect: materials[i].ActivityMachineSelect,
            ActivityMachineDescription: materials[i].ActivityMachineDescription,
            IntentQty: materials[i].IntentQty,
            ActualIssueQty: materials[i].ActualIssueQty,
            NetRate: materials[i].NetRate,
            ItemAmount: materials[i].ItemAmount,
            id: materials[i].id,
          },
        ];
      }
      setInputFields(values);
      onChange(values);
   
    }
    // eslint-disable-next-line
  }, [materials]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    let row = values[index];
    const value = event.target.value;
    //console.log(value, stocksDetails[index].StockQuantity);
     if (event.target.value < 0) {
       enqueueSnackbar(`You can't enter Negative Number`, {
         variant: "warning",
       });
     }
    if (+value <= +stocksDetails[index].StockQuantity) {
      if (+value <= +row.IntentQty) {
        row[event.target.name] = value;
        row.ItemAmount = (+row.ActualIssueQty * +row.NetRate).toFixed(2);
        values[index] = row;
        setInputFields(values);
        onChange(values);
      } else {
        enqueueSnackbar(`Issue Quantity should be less than indent quantity`, {
          variant: "error",
        });
        return;
      }
    } else {
      enqueueSnackbar(
        `Issue Quantity should be less than ${stocksDetails[index].StockQuantity}`,
        {
          variant: "error",
        }
      );
      return;
    }
  };

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };
  return (
    <>
  
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No.</th>
                <th className="head-cell">Material Code</th>
                <th className="head-cell">Material Description</th>
                <th className="head-cell">UOM</th>
                <th className="head-cell">Activity/Machine</th>
                <th className="head-cell">Activity/Machine Description</th>
                <th className="head-cell">Indent Qty</th>
                <th className="head-cell">Actual Issue Qty</th>
                <th className="head-cell">Net Rate</th>
                <th className="head-cell">Item Amount</th>
              </tr>
            </thead>
            <tbody>
              {inputFields?.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">{inputField.SerialNo}</td>
                  <td className="table-data2">{inputField.MaterialCode}</td>
                  <td className="table-data2">
                    {inputField.MaterialDescription}
                  </td>
                  <td className="table-data2">{inputField.UOM}</td>
                  <td className="table-data2">
                    {inputField.ActivityMachineSelect}
                  </td>
                  <td className="table-data2">
                    {inputField.ActivityMachineDescription}
                  </td>
                  <td className="table-data2">{inputField.IntentQty}</td>
                  <td className="table-data2">
                    <input
                      name="ActualIssueQty"
                      value={inputField.ActualIssueQty}
                      type="number"
                      min="0"
                      step={0.01}
                      onChange={(event) => {
                        handleChangeInput(index, event);
                        fixedDecimal(index, event, "ActualIssueQty");
                      }}
                      style={{ width: "5rem" }}
                    />
                  </td>
                  <td className="table-data2">{inputField.NetRate}</td>
                  <td className="table-data4">{inputField.ItemAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
    </>
  );
};

export default WarehouseIssueVoucher;
