import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import { userLoginHelper, userLogout } from "./redux/actions/userAction";
import setAuthToken from "./redux/helper/setAuthToken";
import store from "./redux/store";
import Navbar from "./components/navbar/navbar";
import MaterialSystem from "./Routers/MaterialSystemRoutes/MaterialSystem";
import MachineManagementSystem from "./Routers/MachineMangementSystemRoutes/MachineMangementSystem";
import CommonMaster from "./Routers/CommonMaster/CommonMaster";
import ProjectHrManagementRoutes from "./Routers/ProjectHrManagementRoutes/ProjectHrManagementRoutes";
import SalesAccounting from "./Routers/SalesAccounting/SalesAccountingRouter";
import AccountingSystem from "./Routers/AccountingSystemRoutes/AccountingSystem";
import ProjectWorkforceManagement from "./Routers/ProjectWorkforceManagement/ProjectWorkforceManagement";
import AccessControl from "./Routers/AccessControlRoutes/AccessControl";
import ExecutionSystem from "./Routers/ExecutionSystem/ExecutionSystem";
import LoginRoutes from "./Routers/LoginRoutes/LoginRouter";
import ProfileRoute from "./Routers/Profile/ProfileRoute";
import clsx from "clsx";
import useStyle from "./components/navbar/UseStyle";
import "./components/helpers/Table.css";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

if (window.localStorage.userJwtToken) {
  setAuthToken(localStorage.userJwtToken);
  const decoded = jwt_decode(localStorage.userJwtToken);
  store.dispatch(userLoginHelper(decoded.user));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(userLogout());
    window.location.href = "/";
  }
}

function App() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyle();
  return (
    <SnackbarProvider>
      <div>
        <Router>
          <Navbar
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            open={open}
          />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            {LoginRoutes}
            {CommonMaster}
            {ProjectHrManagementRoutes}
            {MaterialSystem}
            {MachineManagementSystem}
            {SalesAccounting}
            {AccountingSystem}
            {ProjectWorkforceManagement}
            {AccessControl}
            {ExecutionSystem}
            {ProfileRoute}
          </main>
          <Switch>
            <Route path="/resetPassword" exact component={ForgotPassword} />
          </Switch>
        </Router>
      </div>
    </SnackbarProvider>
  );
}
export default App;
