import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Button from "@material-ui/core/Button";

const PurchaseOrderTable = (props) => {
  const [inputFields, setInputFields] = useState([
    {
      MachineCode: "",
      MachineDescription: "",
      HSNSACCode: "",
      UOM: "",
      Quantity: 0,
      Rate: 0,
      Discount: 0,
      NetRate: 0,
      NetAmount: 0,
      CGSTPercentage: 0,
      CGSTValue: 0,
      SGSTPercentage: 0,
      SGSTValue: 0,
      IGSTPercentage: 0,
      IGSTValue: 0,
      TotalGST: 0,
      MachineTotal: 0,
      id: "55",
    },
  ]);
  const { materials } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      for (let i = 0; i < materials.length; i++) {
        values = [
          ...values,
          {
            MachineCode: materials[i].MachineCode,
            MachineDescription: materials[i].MachineDescription,
            HSNSACCode: materials[i].HSNSACCode,
            UOM: materials[i].UOM,
            Quantity: materials[i].Quantity,
            Rate: materials[i].Rate,
            Discount: materials[i].Discount,
            NetRate: materials[i].NetRate,
            NetAmount: materials[i].NetAmount,
            CGSTPercentage: materials[i].CGSTPercentage,
            CGSTValue: materials[i].CGSTValue,
            SGSTPercentage: materials[i].SGSTPercentage,
            SGSTValue: materials[i].SGSTValue,
            IGSTPercentage: materials[i].IGSTPercentage,
            IGSTValue: materials[i].IGSTValue,
            TotalGST: materials[i].TotalGST,
            MachineTotal: materials[i].MachineTotal,
            id: materials[i].id,
          },
        ];
      }
      setInputFields(values);
    }
  }, [materials]);
  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        MachineCode: "",
        MachineDescription: "",
        HSNSACCode: "",
        UOM: "",
        Quantity: 0,
        Rate: 0,
        Discount: 0,
        NetRate: 0,
        NetAmount: 0,
        CGSTPercentage: 0,
        CGSTValue: 0,
        SGSTPercentage: 0,
        SGSTValue: 0,
        IGSTPercentage: 0,
        IGSTValue: 0,
        TotalGST: 0,
        MachineTotal: 0,
        id: "new",
      },
    ]);
  };
  const { tableData } = props;
  useEffect(() => {
    const onChange = () => {
      if (inputFields) {
        tableData(inputFields);
      } else {
        tableData({ error: true, errorKind: "some input field is empty" });
      }
    };
    onChange();
  }, [inputFields, tableData]);
  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };
  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const updatevalues = (index) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row.CGSTValue = ((+row.CGSTPercentage / 100) * +row.NetAmount).toFixed(3);
    row.IGSTValue = ((+row.IGSTPercentage / 100) * +row.NetAmount).toFixed(3);
    row.SGSTValue = ((+row.SGSTPercentage / 100) * +row.NetAmount).toFixed(3);
    row.TotalGST = (+row.IGSTValue + +row.CGSTValue + +row.SGSTValue).toFixed(
      3
    );
    row.MachineTotal = (+row.TotalGST + +row.NetAmount).toFixed(3);
    values[index] = row;
    setInputFields(values);
  };

  return (
    <div style={{ paddingTop: "60px" }}>
      <div className="displayGrid table-responsive-lg">
        <div className="scroller">
          <div className="displayFlex">
            <div className="width6">
              <span> Machine Code </span>
            </div>
            <div className="width15">
              <span> Machine Description </span>
            </div>
            <div className="width5">
              <span> HSN / SAC Code </span>
            </div>
            <div className="width5">
              <span> UOM </span>
            </div>
            <div className="width5">
              <span> Quantity </span>
            </div>
            <div className="width5">
              <span> Rate </span>
            </div>
            <div className="width6">
              <span>Discount</span>
            </div>
            <div className="width5">
              <span>Net Rate</span>
            </div>
            <div className="width5">
              <span>Net Amount</span>
            </div>
            <div className="width10 ">
              <div>
                <div className="labelborder">C-GST</div>
                <div className="split">
                  <div className="borderright">%</div>
                  <div className="width50">Value</div>
                </div>
              </div>
            </div>
            <div className="width10">
              <div>
                <div className="labelborder">S-GST</div>
                <div className="split">
                  <div className="borderright">%</div>
                  <div className="width50">Value</div>
                </div>
              </div>
            </div>
            <div className="width10">
              <div>
                <div className="labelborder">I-GST</div>
                <div className="split">
                  <div className="borderright">%</div>
                  <div className="width50">Value</div>
                </div>
              </div>
            </div>
            <div className="width6">
              <span>Total GST</span>
            </div>
            <div className="width5">
              <span>Machine Total</span>
            </div>
          </div>
          <div className="field">
            {inputFields.map((inputField, index) => (
              <div className="displayFlex" key={index}>
                <input
                  className="width6"
                  name="MachineCode"
                  value={inputField.MachineCode}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width15"
                  name="MachineDescription"
                  value={inputField.MachineDescription}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="HSNSACCode"
                  value={inputField.HSNSACCode}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="UOM"
                  value={inputField.UOM}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="Quantity"
                  type="number"
                  value={inputField.Quantity}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="Rate"
                  type="number"
                  value={inputField.Rate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width6"
                  name="Discount"
                  type="number"
                  value={inputField.Discount}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="NetRate"
                  type="number"
                  value={inputField.NetRate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="NetAmount"
                  type="number"
                  value={inputField.NetAmount}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updatevalues(index);
                  }}
                />
                <input
                  className="width5"
                  name="CGSTPercentage"
                  type="number"
                  value={inputField.CGSTPercentage}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updatevalues(index);
                  }}
                />
                <input
                  className="width5"
                  name="CGSTValue"
                  type="number"
                  value={inputField.CGSTValue}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="SGSTPercentage"
                  type="number"
                  value={inputField.SGSTPercentage}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updatevalues(index);
                  }}
                />
                <input
                  className="width5"
                  name="SGSTValue"
                  type="number"
                  value={inputField.SGSTValue}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="IGSTPercentage"
                  type="number"
                  value={inputField.IGSTPercentage}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updatevalues(index);
                  }}
                />
                <input
                  className="width5"
                  name="IGSTValue"
                  type="number"
                  value={inputField.IGSTValue}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width6"
                  name="TotalGST"
                  type="number"
                  value={inputField.TotalGST}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <input
                  className="width5"
                  name="ItemTotal"
                  type="number"
                  value={inputField.MachineTotal}
                  onChange={(event) => handleChangeInput(index, event)}
                />
                <RemoveCircleIcon
                  onClick={(e) => handleRemoveFields(e, index)}
                  color="secondary"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="buttonFlex">
          <Button
            onClick={(e) => handleAddFields(e)}
            variant="contained"
            color="primary"
          >
            <AddCircleIcon />
            ADD Row
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PurchaseOrderTable;
