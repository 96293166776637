import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";

const SearchOptionProject = ({ handleProjectChange, label }) => {
  const projects = useSelector(
    (store) => store.SalesAcountRoot.AllProjectCircular
  );
  const machineReceipt = useSelector(
    (store) => store.machineMangementSystemRoot?.AllMachineReceipt
  );

  const getOptions = (data) => {
    if (
      data === undefined ||
      machineReceipt === undefined ||
      machineReceipt?.length === 0
    )
      return projects;

    const machines = machineReceipt.map(
      (item) => item.ProjectCodeName.split(" ")[0]
    );
    const filteredData = data.filter(
      (item) =>
        item.Status === "accepted" && machines.includes(item.ProjectCode)
    );
    let filteredProject = filteredData.map(
      (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
    );

    return filteredProject;
  };

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={getOptions(projects)}
        onChange={(event, value) => handleProjectChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchOptionProject;
