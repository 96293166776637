/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Activity from "../../../forms/CommonMaster/Activity";
import { useHistory } from "react-router-dom";
import {
  editActivity,
  searchActivityById,
} from "../../../redux/actions/CommonMasterAction";
import { allUnitOfMeasurement } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const ActivityEdit = (props) => {
  let AllUOMs = [];
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [UOMCodeList, setUOMCodeList] = useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchActivityById(match.params.id));
  }, [match.params.id, dispatch]);
  console.log(store);
  useEffect(() => {
    if (store.SingleActivity) {
      setData(store.SingleActivity);
    }
  }, [store.SingleActivity]);
  const onSubmit = (formValues, history) => {
    dispatch(editActivity(formValues, props.match.params.id, history));
  };
  useEffect(() => {
    dispatch(allUnitOfMeasurement());
  }, [dispatch]);
  useEffect(() => {
    if (store.AllUnitOfMeasurement) {
      AllUOMs = store.AllUnitOfMeasurement;
      let UOMCodeList = AllUOMs.map((item) => item.UOMCode);
      setUOMCodeList(UOMCodeList);
    }
  }, [store.AllUnitOfMeasurement]);
  return (
    <div>
      <Breadcrumbs
        name="Activity Edit"
        linkName1="Activity Dashboard"
        to1="/commonMaster/Activity"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Activity Edit
      </h3>
      <div className="line"></div>
      <Activity
        UOMCodeList={UOMCodeList}
        initialValues={pick(
          data,
          "ActivityCode",
          "CostType",
          "MajorActivity",
          "ActivityDescription",
          "UOM",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
