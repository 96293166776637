import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useStyle from "../helpers/UseStyle";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteModal from "../modals/DeleteModal";
import { DeleteSingle } from "../../redux/actions/materialSystemAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Skeleton from "@material-ui/lab/Skeleton";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import { CSVLink } from "react-csv";
import { Select, Input as AntInput } from "antd";
import {
  allBusinessUnit,
  allCompanyDetails,
  allWareHouse,
} from "../../redux/actions/CommonMasterAction";
import { SelectDate } from "../DatePicker";
import { ExportCSV } from "../Exportexcel/Exportexcel";
import { readAllFromQueryParams } from "../../utils/queryParams";
import Paginator from "../Paginator/Paginator";
import moment from "moment";
import useUpdateSearch from "../../utils/hooks/useUpdateSearch";
import usePermissions from "../../utils/hooks/usePermissions";
const { Search } = AntInput;

const { Option } = Select;

const FormDashboardLessPagination = (props) => {
  const classes = useStyle();
  const [lists, setLists] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { search } = useLocation();
  const params = readAllFromQueryParams(search);
  const [updateSearch] = useUpdateSearch(search);
  const getPermissions = usePermissions();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [displaySearchFilter] = useState(props.heading !== "none");
  const onDelete = async (id) => {
    await dispatch(DeleteSingle(`${props.DeleteLink}/delete/${id}`, history));
    toggleModal();
    const temp = [...lists];
    for (const item of temp) {
      if (item.id === id) {
        item.Status = "deleted";
      }
    }
    dispatch(allWareHouse());
    dispatch(allCompanyDetails());
    dispatch(allBusinessUnit());
    setLists(temp);
  };

  const onSearch = (query) => {
    updateSearch({
      query,
      page: 1,
    });
  };

  const handleStatus = (status) => {
    updateSearch({
      status,
      page: 1,
    });
  };

  const onDateChange = (dates) => {
    updateSearch({
      fromDate: dates?.from ? moment(dates?.from).toISOString() : "",
      toDate: dates?.to ? moment(dates?.to).toISOString() : "",
      page: 1,
    });
  };

  useEffect(() => {
    let lists = props.lists;
    // lists = lists.reverse();

    if (lists.length) {
      switch (props.heading) {
        case "WareHouse":
          setLists(
            lists.map((list) => {
              const { Gsts, ...rest } = list;
              return {
                ...rest,
                GSTNo: Gsts?.GSTRegNo,
                State: Gsts?.GSTState,
                Address: Gsts?.GSTAddress,
              };
            })
          );
          break;

        default:
          setLists(lists);
      }
    } else {
      setLists(lists);
    }
  }, [props.lists]);

  const columnHeadDetails = [
    {
      id: "Label1",
      label: `${props.heading}`,
      minWidth: 170,
      align: "center",
    },
    { id: "Label2", label: "Request by", minWidth: 150 },
    {
      id: "Label3",
      label: "Date",
      minWidth: 150,
      align: "center",
    },
    {
      id: "Label4",
      label: "Operation",
      minWidth: 170,
    },
  ];

  const options = ["Active", "All", "Closed", "Inactive"];
  const statusfilter = ["accepted", "not_reviewed"];

  return (
    <div style={{ paddingTop: "0" }}>
      <TableContainer
        className={classes.table}
        component={Paper}
        style={{ maxHeight: 500, border: "none", paddingTop: "0" }}
      >
        <h2 style={{ marginTop: "5px" }} align="center">
          {props.heading}
        </h2>
        {(displaySearchFilter || props?.enableFilters?.length > 0) && (
          <Box
            display="flex"
            flexflow="row wrap"
            justifyContent="space-between"
            my={2}
            px={2}
          >
            {(displaySearchFilter ||
              props?.enableFilters?.includes("search")) && (
              <Box width="350px">
                <Search
                  placeholder="Search"
                  allowClear
                  defaultValue={params?.query}
                  onSearch={onSearch}
                />
              </Box>
            )}

            {(props.heading === "Project Circular DashBoard" ||
              props?.enableFilters?.includes("active")) && (
              <Box mx="1rem">
                <Select
                  defaultValue={params?.status || "Active"}
                  style={{
                    width: 120,
                  }}
                  onChange={(value) => handleStatus(value)}
                >
                  {options.map((option) => (
                    <Option value={option} key={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}
            {(props.heading !== "Project Circular DashBoard" ||
              props?.enableFilters?.includes("accepted")) && (
              <Box mx="1rem">
                <Select
                  defaultValue={params?.status || "accepted"}
                  style={{
                    width: 120,
                  }}
                  onChange={handleStatus}
                >
                  {statusfilter.map((option) => (
                    <Option value={option} key={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}

            {(displaySearchFilter ||
              props?.enableFilters?.includes("date")) && (
              <Box mx="1rem">
                <SelectDate
                  defaultValue={
                    params.fromDate && params.toDate
                      ? [moment(params.fromDate), moment(params.toDate)]
                      : []
                  }
                  setDates={onDateChange}
                />
              </Box>
            )}
            {(displaySearchFilter ||
              props?.enableFilters?.includes("export")) && (
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Export
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <ExportCSV
                      csvData={lists}
                      fileName={props.heading}
                      variant="text"
                      endpoint={"/salesAccounting/projectCircular"}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {lists.length > 0 && (
                      <CSVLink
                        headers={Object.keys(lists[0]).map((key) => ({
                          label: key,
                          key,
                        }))}
                        data={lists}
                        filename={props.heading}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        CSV
                      </CSVLink>
                    )}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Box>
        )}

        {lists ? (
          <>
            <Table stickyHeader style={{ border: "none" }}>
              <TableHead>
                <TableRow>
                  {columnHeadDetails.map((column) => (
                    <TableCell
                      key={column.id}
                      className={classes.columnHeader}
                      style={{ minWidth: `${column.minWidth}` }}
                      align="center"
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {lists ? (
                  <>
                    {lists.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">
                            <Typography className={classes.align}>
                              <ListAltIcon color="primary" />
                              {row[props.tablecol1]}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <PermIdentityIcon color="primary" />
                              {`${row?.created_by?.first_name}`}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.align}>
                              <UpdateIcon color="primary" />
                              {row?.updated_at
                                ? row.updated_at
                                : row.created_at}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <>
                              {!!getPermissions?.View && (
                                <span>
                                  <IconButton>
                                    <Link to={`${props.viewLink}/${row.id}`}>
                                      <VisibilityIcon color="primary" />
                                    </Link>
                                  </IconButton>
                                </span>
                              )}
                              {!!getPermissions?.Modify && (
                                <span>
                                  <IconButton>
                                    <Link to={`${props.editLink}/${row.id}`}>
                                      <EditIcon color="action" />
                                    </Link>
                                  </IconButton>
                                </span>
                              )}
                              {!!getPermissions?.Cancel && (
                                <span>
                                  <IconButton>
                                    <DeleteForeverIcon
                                      color="secondary"
                                      onClick={() => {
                                        toggleModal();
                                        setItemId(row.id);
                                      }}
                                    />
                                  </IconButton>
                                </span>
                              )}
                              <DeleteModal
                                showModal={showModal}
                                toggleModal={toggleModal}
                                onDelete={onDelete}
                                itemId={itemId}
                              />
                            </>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <div className="container">
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton variant="rect" height={200} />
                  </div>
                )}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className="container">
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={200} />
          </div>
        )}
      </TableContainer>
      <Paper
        style={{
          width: "90%",
          margin: "80px auto",
          marginTop: "0",
        }}
      >
        <Paginator paginateResults={props.paginateResults} />
      </Paper>
      {!!getPermissions?.Add && (
        <Link to={props.link}>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.button}
            style={{ outline: "none" }}
          >
            <AddCircleRoundedIcon
              variant="contained"
              className={classes.buttonIcon}
            />
          </Fab>
        </Link>
      )}
    </div>
  );
};

export default FormDashboardLessPagination;
