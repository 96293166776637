import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const ReceiptCustomerTableView = ({ receipt }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let values = [];
    if (receipt) {
      values = [...receipt];
    }
    setData(values);
  }, [receipt]);

  return (
    <>
      {receipt ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">Project Code Name</th>
                <th className="head-cell">Sales Order Date</th>
                <th className="head-cell">Sales Invoice No</th>
                <th className="head-cell">Sales Order No</th>
                <th className="head-cell">Sales Invoice Date</th>
                <th className="head-cell">Gross Invoice Amount</th>
                <th className="head-cell">Gross Certification Amount</th>
                <th className="head-cell">Recovery Amount</th>
                <th className="head-cell">Payable Amount</th>
                <th className="head-cell">Previously Received</th>
                <th className="head-cell">Received Now</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data3">{row.ProjectCodeName}</td>
                  <td className="table-data3">
                    {format(new Date(row.SalesOrderDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data3">{row.SalesOrderNo}</td>
                  <td className="table-data3">{row.SalesInvoiceNo}</td>
                  <td className="table-data3">
                    {format(new Date(row.SalesInvoiceDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data3">{row.GrossInvoiceAmount}</td>
                  <td className="table-data3">
                    {row.GrossCertificationAmount}
                  </td>
                  <td className="table-data3">{row.RecoveryAmount}</td>
                  <td className="table-data3">{row.PayableAmount}</td>
                  <td className="table-data3">{row.PreviouslyReceived}</td>
                  <td className="table-data4">{row.ReceivedNow}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ReceiptCustomerTableView;
