import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
import { userLogin } from "../../redux/actions/userAction";
// import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://innovaciotech.com/">
//         Innovacio Tech
//       </Link>
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }


const LoginForm = () => {
  const store = useSelector((store) => store);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(userLogin({ email, password }, history));
    setIsLoading(true);
  };

  useEffect(() => {
    if (store.errorRoot.loginErrors) {
      setErrors(store.errorRoot.loginErrors);
    }
  }, [store.errorRoot.loginErrors]);

  useEffect(() => {
    if (store.errorRoot.loginErrors || store.userRoot.isAuthenticated) {
      setIsLoading(false);
    }
  }, [store.errorRoot.loginErrors, store.userRoot.isAuthenticated]);

  return (
    <>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form className={classes.form} noValidate onSubmit={formSubmitHandler}>
        <div style={{ margin: "10px" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            value={email}
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div style={{ margin: "10px" }}>
          <OutlinedInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            id="password"
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button className={classes.forgotPassword} variant='text' component={Link} to='/resetPassword' color='inherit'>
              Forgot Password?
          </Button>
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
        <div className="middle">
          {isLoading && (
            <div
              className="spinner-border text-primary"
              role="status"
              style={{
                alignContent: "center",
                marginTop: "10px",
              }}
            >
              {/* <span className="sr-only">Loading...</span>
               */}
              <CircularProgress />
            </div>
          )}
          {!isLoading && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ outline: "none", alignContent: "center" }}
            >
              <Typography className={classes.font} style={{ margin: "25px" }}>
                Sign in
              </Typography>
            </Button>
          )}
        </div>

        <h6
          style={{
            color: "red",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          {errors.message ? `${errors.message} ` : ""}
        </h6>

        {/* <Box mt={5}>
          <Copyright />
        </Box> */}
      </form>
    </>
  );
};

export default LoginForm;
