import React, { useState, useCallback } from "react";
import { Field, reduxForm, change } from "redux-form";
import SalesOrderInvoiceTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesOrderInvoiceTable";
import SalesInvoiceRecoveryTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceRecoveryTable";
import SalesInvoiceSummary from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceSummaryTable";
import * as Control from "../../components/formcomponents/control";
import TabLayout from "../../components/formcomponents/TabLayout";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const SalesInvoiceCertification = (props) => {
  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (invoiceSavedData && recoverySavedData)
      props.onSubmit({
        ...formValues,
        InvoiceData: invoiceSavedData,
        RecoveryData: recoverySavedData,
        SummaryData: summaryData,
      });
    else alert("Table Error");
  };

  const [invoiceSavedData, setInvoiceSavedData] = useState(null);
  const [recoverySavedData, setRecoverySavedData] = useState(null);
  const [tabDisabledArr, setTabDisabledArr] = useState([false, true, true]);
  // const [tabDisabledArr, setTabDisabledArr] = useState([false, false, false]);
  const [summaryData, setSummaryData] = useState({});

  const saveInvoiceTable = useCallback((inputFields, totalInvoiceAmt) => {
    setInvoiceSavedData({ fields: inputFields, amt: totalInvoiceAmt });
    setTabDisabledArr([false, false, true]);
  }, []);

  const saveRecoveryTable = useCallback((inputFields, totalRecoveryAmt) => {
    setRecoverySavedData({ fields: inputFields, amt: totalRecoveryAmt });
    setTabDisabledArr([false, false, false]);
  }, []);

  const invoiceSummary = (summary) => {
    try {
      let total = summary.NetPayableAmt;
      props.dispatch(
        change(
          "SalesInvoiceCertification",
          "CertificationValue",
          (+total).toFixed(2)
        )
      );
      setSummaryData({
        ...summary,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="Project Code"
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          />
          <Field
            name="InvoiceNo"
            label="Invoice No"
            component={Control.AutoCompleteInput}
            list={props.invoiceList}
            onChange={(value) =>
              props.selectInvoice
                ? props.selectInvoice(value)
                : () => {
                    return;
                  }
            }
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="OriginalFormNo"
            label="Original Form No"
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />

          <Field
            name="InvoiceDate"
            label="Invoice Date"
            disabled
            component={Control.RenderDateField}
            initialValue={props.initialValues.InvoiceDate}
          />
          <Field
            name="CertificationDate"
            label="Certification Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues.CertificationDate}
            className="text-field"
          />
          <Field
            name="CertificationValue"
            label="Certification Value"
            disabled
            component={Control.RenderTextField}
          />
          <TabLayout
            labels={["Invoice", "Recovery", "Summary"]}
            activeTabs={tabDisabledArr}
            tables={[
              <SalesOrderInvoiceTable
                invoiceTable={props.invoiceTable}
                salesOrderTable={props.salesOrderTable}
                staged={props.staged}
                stageData={props.stageData}
                saveInvoiceTable={saveInvoiceTable}
                invoiceSavedData={invoiceSavedData}
                invoiceEditData={props.invoiceEditData}
                invoiceTotalAmt={props.invoiceTotalAmt}
                disabled={true}
              />,
              <SalesInvoiceRecoveryTable
                recoveryTypes={props.recoveryTypes}
                invoiceSavedData={invoiceSavedData}
                recoveryTable={props.recoveryTable}
                saveRecoveryTable={saveRecoveryTable}
                recoverySavedData={recoverySavedData}
                recoverTableEdit={props.recoverTableEdit}
                recoveryTotalAmt={props.recoveryTotalAmt}
              />,
              <SalesInvoiceSummary
                invoiceSavedData={invoiceSavedData}
                recoverySavedData={recoverySavedData}
                invoiceSummary={invoiceSummary}
                LastPaymentUptoLastBill={props.LastPaymentUptoLastBill}
                summaryTableEdit={props.summaryTableEdit}
              />,
            ]}
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Sales Invoice Certification"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "InvoiceNo",
    "CertificationValue",
    "CertificationDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "SalesInvoiceCertification",
  validate,
  enableReinitialize: true,
})(SalesInvoiceCertification);
