import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmployeeDetails from "../../../forms/CommonMaster/EmployeeDetails";
import axios from "../../../components/apis/axios";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { getNumberFromString } from "../../../utils/common";

export const EmployeeDetailsCreate = () => {
  const history = useHistory();
  const [companyDetail, setCompanyDetail] = useState([]);
  const [newEmployeeCode, setNewEmployeeCode] = useState("");

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    await axios.post("/commonMaster/employeeDetail", formValues);
    history.push("/commonMaster/employeeDetails");
  };

  useEffect(() => {
    async function fetch() {
      try {
        // let activeData = [];
        // let datas = "";
        const { data } = await axios.get("/commonMaster/companyDetails");
        let mapedData = data.map((item) => item.CompanyName);
        setCompanyDetail(mapedData);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        console.log()
        if (data.length > 0) {
          const lastEmployeeCode = data[0]?.EmployeeCode;
          if (lastEmployeeCode) {
            let bnenefitCodeNumber = getNumberFromString(lastEmployeeCode);
            let newEmployeeCodeValue = "";
            if (bnenefitCodeNumber?.toString()?.length === 1) {
              newEmployeeCodeValue = `EM00000${bnenefitCodeNumber + 1}`;
            } else {
              bnenefitCodeNumber = (bnenefitCodeNumber + 1).toString().padStart(6, 0);
              newEmployeeCodeValue = `EM${bnenefitCodeNumber}`;
            }
            setNewEmployeeCode(newEmployeeCodeValue);
          }
        } else {
          setNewEmployeeCode("EM000001");
        }
      } catch (error) {
        //
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Employee Master Create"
        linkName1="Employee Masters Dashboard"
        to1="/commonMaster/employeeDetails"
      />
      <FormHeading label="Employee Master" />
      <EmployeeDetails 
          initialValues={{
            EmployeeCode: newEmployeeCode,
          }} 
          onSubmit={onSubmit} 
          companyDetail={companyDetail} 
        />
    </div>
  );
};
