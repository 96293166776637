import { makeStyles } from "@material-ui/core";

const useCustomDataGridStyles = makeStyles((theme) => ({
  dataGridWrapper: {
    height: 500,
    maxHeight: 600,
    width: "100%",
  },
  columnHeader: {
    "& .MuiDataGrid-columnHeaderWrapper": {
      background: "#8383fa",
      color: "white",
      fontSize: "15px",
      fontWeight: "600",
      border: "none",
      borderRadius: "3px",
    },
  },
}));

export default useCustomDataGridStyles;
