import React from "react";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import store from "../../redux/store";
import { Button } from "@material-ui/core";
import axios from "../../components/apis/axios";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { userLogout } from "../../redux/actions/userAction";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import validator from "validator";

const Profile = () => {
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const dispatch = useDispatch();

  const history = useHistory();

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };
  const classes = useStyle();
  store.subscribe(() => {
    const updatedUser = store.getState().userRoot;
    setUser(updatedUser);
  }, []);

  React.useEffect(() => {
    const updatedUser = store.getState().userRoot;
    setUser(updatedUser);
    if (updatedUser.workingOn == null) history.replace("/");
  }, [dispatch, user, history]);

  const handleClickOpen = () => {
    // console.log("user-id", id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const response = await axios.post(
      `/users/changePassword/${user.user.id}/`,
      {
        password: password,
      }
    );
    console.log(response);
    dispatch(userLogout(history));
  };
  return (
    <div>
      {user && user.workingOn && (
        <Paper className={classes.Container}>
          <div className="">
            <h3 className={classes.Heading}>Profile</h3>
          </div>
          <Box border={1} borderRadius={16} className={classes.Box}>
            <div className={classes.boxInner}>
              <Paper className={classes.paper}>
                <h6>First Name:</h6>
                <p>{user.user.first_name}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Last Name:</h6>
                <p>{user.user.last_name}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Company Name:</h6>
                <p>{user.user.company}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Role:</h6>
                <p>{user.workingOn.Role}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Email:</h6>
                <p>{user.user.email}</p>
              </Paper>
              <Paper className={classes.paper}>
                <h6>Phone Number:</h6>
                <p>{user.user.phone_number}</p>
              </Paper>
            </div>
            <div className="middle">
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleClickOpen()}
              >
                Update Password
              </Button>
            </div>
          </Box>
        </Paper>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Password</DialogTitle>
        <DialogContent>
          <OutlinedInput
            variant="outlined"
            margin="normal"
            style={{ color: "black" }}
            autoFocus
            id="name"
            // label="New Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validate(e.target.value);
            }}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />

          {errorMessage ? (
            <span
              style={{
                fontWeight: "bold",
                color: "green",
              }}
            >
              Your Password is Strong
            </span>
          ) : (
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              Please follow the below format <br />
              Min-Length is 6 char <br />
              One Lowercase Char <br />
              One Uppercase Char <br />
              One Symbols Char <br />
            </span>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            style={{ backgroundColor: "green", color: "white" }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
