import React, { useState, useEffect } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "./PurchaseRequestForm.css";
import Table from "../../Tables/MaterialSystem/WarehouseIndentVoucherFormTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const WarehouseIndentVoucherForm = (props) => {
  const [state, setState] = useState({
    SOno: false,
    WOno: false,
    CN: false,
    VN: false,
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
    uploadedData: null,
  });

  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  useEffect(() => {
    if (props.initialValues.GoodsIssueType) {
      onSelectGoodReceite(props.initialValues.GoodsIssueType);
    }
    // eslint-disable-next-line
  }, [props.initialValues.GoodsIssueType]);

  const onSelectGoodReceite = (value) => {
    if (
      value === "ISSUE TO CUSTOMERS-CHARGEABLE BASIS" ||
      value === "ISSUE TO CUSTOMERS-FREE BASIS" ||
      value === "ISSUE TO CUSTOMERS-LOAN BASIS"
    ) {
      setState({
        ...state,
        SOno: false,
        WOno: true,
        CN: false,
        VN: true,
      });
      // props.fetchSalesOrder();
    } else if (
      value === "ISSUE TO SUB-CONTRACTORS-CHARGEABLE BASIS" ||
      value === "ISSUE TO SUB-CONTRACTORS-FREE BASIS" ||
      value === "ISSUE TO SUB-CONTRACTORS-LOAN BASIS"
    ) {
      setState({
        ...state,
        SOno: true,
        WOno: false,
        CN: true,
        VN: false,
      });
    } else if (value === "ISSUE TO PROJECT") {
      setState({
        ...state,
        SOno: true,
        WOno: true,
        CN: true,
        VN: true,
      });
    } else if (value === "") {
      setState({
        ...state,
        SOno: true,
        WOno: true,
        CN: true,
        VN: true,
      });
    } else {
      setState({
        ...state,
        SOno: true,
        WOno: true,
        CN: true,
        VN: true,
      });
    }
  };
  const tableData = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += +item.MaterialTotal;
    });
    props.dispatch(
      change("WarehouseIndentVoucherForm", "TotalPRValue", (+total).toFixed(2))
    );

    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({ ...state, tableError: true });
    } else if (state.tableData) {
      console.log("working");
      props.onSubmit({ ...formValues, tableData: state.tableData });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          <Field
            name="GoodsIssueType"
            component={Control.AutoCompleteInput}
            list={[
              "",
              "ISSUE TO CUSTOMERS-CHARGEABLE BASIS",
              "ISSUE TO CUSTOMERS-FREE BASIS",
              "ISSUE TO CUSTOMERS-LOAN BASIS",
              "ISSUE TO PROJECT",
              "ISSUE TO SUB-CONTRACTORS-CHARGEABLE BASIS",
              "ISSUE TO SUB-CONTRACTORS-FREE BASIS",
              "ISSUE TO SUB-CONTRACTORS-LOAN BASIS",
              "ISSUE TO SUB-CONTRACTORS-REJECTION",
              "ISSUE TO SUB-CONTRACTORS-REPAIR",
            ]}
            className="text-field"
            label="Goods Issue Type​"
            onChange={onSelectGoodReceite}
          />
          <Field
            name="SalesOrderNo"
            component={Control.AutoCompleteInput}
            list={props.salesOrderList}
            className="text-field"
            label="Sales Order No​"
            hidden={state.SOno}
            onChange={(value) =>
              props.selectSalesOrder && props.selectSalesOrder(value)
            }
          />
          <Field
            name="WorkOrderNo"
            component={Control.RenderTextField}
            label="Works Order No​"
            hidden={state.WOno}
          />
          {/* <Field
            name="WorkSalesDate"
            component={Control.RenderDateField}
            label="Work / Sales Date"
            hidden={state.WOno}
            initialValue={props.initialValues.WorkSalesDate}
          /> */}
          {(state.WOno || state.SOno) && (
            <Field
              name="WorkSalesDate"
              component={Control.RenderDateField}
              label="Work / Sales Date"
              initialValue={props.initialValues.WorkSalesDate}
            />
          )}
          <Field
            name="VendorCodeName"
            component={Control.AutoCompleteInput}
            className="text-field"
            label="Vendor Code / Name"
            list={props.VDList}
            hidden={state.VN}
            onChange={(values) =>
              props.SelectVendor && props.SelectVendor(values)
            }
          />
          <Field
            name="VendorGstNo"
            component={Control.AutoCompleteInput}
            className="text-field"
            label="Vendor GST"
            hidden={state.VN}
            list={props.vendorGst}
          />
          <Field
            name="Remarks"
            component={Control.RenderTextField}
            label="Remarks​​"
          />
        </div>
        <div className="excel">
          <Table
            tableData={tableData}
            machineList={props.machineList}
            activityList={props.activityList}
            materialList={props.materialList}
            materials={props.materials}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Warehouse Indent Voucher"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "Warehouse",
    "GoodsIssueType",
    "Date",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "WarehouseIndentVoucherForm",
  validate,
  enableReinitialize: true,
})(WarehouseIndentVoucherForm);
