import React from "react";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

export const SelectDate = ({ setDates, defaultValue }) => (
  <Space direction="vertical" size={12}>
    <RangePicker
      defaultValue={defaultValue}
      ranges={{
        Today: [moment(), moment()],
        "This week": [moment().startOf("week"), moment().endOf("week")],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      }}
      onChange={(dates, dateStrings) => {
        setDates(
          dates
            ? {
                from: new Date(dateStrings[0]),
                to: new Date(dateStrings[1]),
              }
            : null
        );
      }}
    />
  </Space>
);
