import React from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachineRelease from "../../../forms/MachineManagementSystem/MachineRelease";
import { machineRelease } from "../../../redux/actions/MachineManagementaction";
import axios from "../../../components/apis/axios";
import { useEffect } from "react";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { formValueSelector } from "redux-form";
import { useSnackbar } from "notistack";

export let MachineReleaseCreate = (props) => {
  const [projectCirculars, setProjectCirculars] = useState([]);
  const [MachineList, setMachineList] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [machineReceipts, setMachineReceipts] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formValues) => {
    dispatch(machineRelease(formValues, history));
  };

  const onProjectSelect = async (projectNameCode) => {
    const item = allProjects.find(
      (item) => item.ProjectCode === projectNameCode?.split(" ")[0]
    );

    let machines = machineReceipts
      .filter((m) => m.ProjectCodeName === projectNameCode)
      .map((item) => item.MachineCodeName);

    if (item) {
      let payload = {
        ...formValues,
        ProjectCodeName: projectNameCode,
        CustomerName: item.CustomerName,
      };

      setFormValues(payload);
    }
    setMachineList(machines);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data: machineReceipt } = await axios.get(
          `/machineManagement/machineReceipt`
        );
        const { data } = await axios.get("/salesAccounting/projectCircular");

        const machines = machineReceipt.map(
          (item) => item.ProjectCodeName.split(" ")[0]
        );
        const filteredData = data.filter(
          (item) =>
            item.Status === "accepted" && machines.includes(item.ProjectCode)
        );
        let projects = filteredData.map(
          (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
        );

        setProjectCirculars(projects);
        setMachineReceipts(machineReceipt);
        setAllProjects(filteredData);
      } catch (err) {}
    }
    fetch();
  }, []);

  const onMachineSelect = async (code) => {};

  const onReleaseDateChange = async (value) => {
    try {
      const { data } = await axios.post(
        "/machineManagement/logSheetEntry/getLogSheetReportsDetails",
        {
          ProjectCodeName: props.ProjectCodeName,
          MachineCodeName: props.MachineCodeName,
          ReleaseDate: value,
        }
      );

      const sortedData = data.sort(function (a, b) {
        return new Date(b.LogSheetDate) - new Date(a.LogSheetDate);
      });

      if (sortedData.length > 0) {
        setFormValues({
          ...formValues,
          LastLogSheetMeterReading: sortedData[0].EndMeterReading,
          LastLogSheetDate: sortedData[0].LogSheetDate,
        });
      } else {
        enqueueSnackbar(`No log sheet exist for selected release`, {
          variant: "error",
        });
      }
    } catch (error) {
      const errors = error?.response?.data?.errors;
      if (errors && errors?.length > 0) {
        errors.forEach((error) => {
          enqueueSnackbar(error?.message, {
            variant: "error",
          });
        });
      }
    }
  };

  return (
    <div>
      <BreadCrumbs
        name="Machine Release Create"
        linkName1="Machine Release Dashboard"
        to1="/machineManagement/machineRelease"
      />
      <h3 style={{ textAlign: "center" }}>Machine Release</h3>
      <MachineRelease
        onSubmit={onSubmit}
        onProjectSelect={onProjectSelect}
        onMachineSelect={onMachineSelect}
        onReleaseDateChange={onReleaseDateChange}
        MachineList={MachineList}
        ProjectList={projectCirculars}
        List={MachineList}
        initialValues={pick(
          formValues,
          "ProjectCodeName",
          "CustomerName",
          "LastLogSheetDate",
          "LastLogSheetMeterReading"
        )}
      />
    </div>
  );
};

// Decorate with connect to read form values
const selector = formValueSelector("MachineRelease");
MachineReleaseCreate = connect((state) => {
  const { MachineCodeName, ProjectCodeName } = selector(
    state,
    "MachineCodeName",
    "ProjectCodeName"
  );
  return {
    MachineCodeName,
    ProjectCodeName,
  };
})(MachineReleaseCreate);
