import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Journal/JournalOtherForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const JournalOtherCreate = () => {
  const [list, setList] = useState([]);
  const [voucherNoList, setVoucherNoList] = useState([]);
  const history = useHistory();
  const [accounts, setAccounts] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState();
  const [formDetail, setformDetail] = useState({});
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    await axios.post("/accountingSystem/journalOther", formValues);
    history.push("/accountingSystem/journalOther/");
  };

  const selectVoucher = async (voucherNo) => {
    try {
      let { data } = await axios.post(
        "/accountingSystem/paymentRequest/findByPaymentRequestNo",
        { PaymentRequestNo: voucherNo }
      );
      setPaymentDetails(data.paymentDetails);
    } catch (error) {}
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        if (detail)
          detail = {
            ...formDetail,
            ...headerState,
            ProjectCodeName: detail.ProjectShortDescription,
            CustomerName: detail.CustomerName,
            JournalOtherCostCentre: detail.CostCentre,
          };
      } catch (err) {
        console.error(err);
      }
      try {
        let { data } = await axios.post(
          "/accountingSystem/paymentRequest/findByProjectName/",
          { ProjectCodeName: projectName }
        );
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let tempVList = filtered.map((item) => item.PaymentRequestNo);
        setVoucherNoList(tempVList);
      } catch (error) {
        console.log(error);
      }
      setformDetail(detail);
    }
  };
  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    const fetch = async () => {
      try {
        const { data: accounts } = await axios.get("/commonMaster/account/");
        setAccounts(accounts);
        setformDetail({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
    fetchProject();
  }, []);

  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  return (
    <div>
      <Breadcrumbs
        name="Journal Others Create"
        linkName1="Journal Others Dashboard"
        to1="/accountingSystem/journalOther"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Journal Others
      </h3>
      <Form
        onSubmit={onSubmit}
        list={list}
        paymentDetails={paymentDetails}
        voucherNoList={voucherNoList}
        selectVoucher={selectVoucher}
        accounts={accounts}
        selectProjectName={selectProjectName}
        handleHeaderChange={handleHeaderChange}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "CostCentre",
          "FinancialPeriod",
          "PaymentRequestNo",
          "JournalOtherCostCentre"
        )}
      />
    </div>
  );
};
