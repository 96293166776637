import React, { useState, useEffect } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";

export const WareHouseIndentTableView = (props) => {
  const [data, setData] = useState([]);
  const { materials } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);
  return (
    <>
      <div className="tableContainer">
        <table>
          <thead className="table-head">
            <tr>
              <th className="head-cell">SerialNo</th>
              <th className="head-cell">MaterialCode</th>
              <th className="head-cell">MaterialDescription</th>
              <th className="head-cell">UOM</th>
              <th className="head-cell">Activity/Machine</th>

              <th className="head-cell">Activity/Machine Description</th>
              <th className="head-cell">IntentQty</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td className="table-data1">{row.SerialNo}</td>
                <td className="table-data2">{row.MaterialCode}</td>
                <td className="table-data2">{row.MaterialDescription}</td>
                <td className="table-data2">{row.UOM}</td>
                <td className="table-data2">{row.ActivityMachineSelect}</td>

                <td className="table-data2">{row.ActivityMachineDescription}</td>
                <td className="table-data4">{row.IntentQty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </>
  );
};
