import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";

const DailyProgressMachineTable = ({
  machineList,
  handleMachineChange,
  activityIndex,
  machineDetailsEdit,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      SlNo: "",
      MachineCodeName: "",
      UOM: "",
      QuantityUtilised: "",
    },
  ]);
  useEffect(() => {
    if (machineDetailsEdit && machineDetailsEdit.length > 0) {
      setInputFields([...machineDetailsEdit]);
    }
  }, [machineDetailsEdit]);
  const { enqueueSnackbar } = useSnackbar();

  console.log("machineList", machineList);
  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SlNo: "",
        MachineCodeName: "",
        UOM: "",
        QuantityUtilised: "",
        id: "new",
      },
    ]);
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    if (values) {
      handleMachineChange(values, activityIndex);
    } else {
      handleMachineChange({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const onItemSelect = (event, values, index, field, findHere) => {
    let tdata = [...inputFields];
    let row = tdata[index];
    try {
      if (tdata[index]) {
        console.log(values);
        row[field] = values[findHere];
        row.MachineCodeName =
          values.MachineCode +
          "-" +
          values.MachineGdes +
          "-" +
          values.Machinedes;
        row.UOM = "Hours";
      }
      tdata[index] = row;
      console.log(tdata);
      setInputFields([...tdata]);
      if (values) {
        handleMachineChange(values, activityIndex);
      } else {
        handleMachineChange({
          error: true,
          errorKind: "some input field is empty",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    if (values) {
      handleMachineChange(values);
    }
    setInputFields(values);
  };

  const handleCheckNegativeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    for (let i = 0; i < values.length; i++) {
      if (+values[i].QuantityUtilised < 0) {
        values[i].QuantityUtilised = 0;
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    // onChange(values);
  };
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">Machine Code Name</th>
            <th className="head-cell">UOM</th>
            <th className="head-cell">Quantity Utilised</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
                {machineList && (
                  <Autocomplete
                    id="Machine"
                    options={machineList}
                    getOptionLabel={(option) =>
                      option.MachineCode +
                      "-" +
                      option.MachineGdes +
                      "-" +
                      option.Machinedes
                    }
                    onChange={(event, values) =>
                      onItemSelect(
                        event,
                        values,
                        index,
                        "MachineCodeName",
                        "MachineCode",
                        "MachineGdes",
                        "Machinedes"
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                    defaultValue={machineList.find(
                      (x) =>
                        x.MachineCode +
                          "-" +
                          x.MachineGdes +
                          "-" +
                          x.Machinedes ===
                        inputField.MachineCodeName
                    )}
                  />
                )}
              </td>
              <td className="table-data2">{inputField.UOM}</td>
              <td className="table-data4">
                <input
                  type="number"
                  name="QuantityUtilised"
                  value={inputField.QuantityUtilised}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    handleCheckNegativeInput(index, event);
                  }}
                />
              </td>
              <td>
                {index === 0 ? (
                  <IconButton>
                    <RemoveCircleIcon className="width5" color="disabled" />
                  </IconButton>
                ) : (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default DailyProgressMachineTable;
