import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory } from "react-router-dom";

import SalesInvoiceReportTable from "../../../components/SalesAccountingDisplay/SalesInvoiceReportTable";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";

export const SalesInvoiceReport = () => {
  const [lists, setLists] = useState([]);
  const classes = useStyle();

  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((store) => store);
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === "siteProjectHead" ||
    store.userRoot.workingOn.Role === "financeFirst"
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === "siteAdminHead" ||
    store.userRoot.workingOn.Role === "siteProjectHead" ||
    store.userRoot.workingOn.Role === "projectCoOrdinator" ||
    store.userRoot.workingOn.Role === "financeFirst" ||
    store.userRoot.workingOn.Role === "financeSecond" ||
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === "companyHead" ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get("/salesAccounting/salesInvoice");
        let data = response.data;
        data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        data.forEach((element) => {
          element.created_at = new Date(element.created_at).toLocaleString();
        });
        let approvedData = data.filter((item) => item.Status === "accepted");
        console.log(approvedData);
        setLists(approvedData);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [history, dispatch]);

  return (
    <>
      <BreadCrumbs
        name="Sales Invoice Reports"
        linkName1="All Sales Accounting Reports"
        to1="/salesAccounting/reports"
      />
      <Paper className={classes.Container}>
        <SalesInvoiceReportTable
          lists={lists}
          tablecol1="SalesInvoiceNo"
          heading="Sales Invoice Reports"
          label1="Sales Invoice No"
          viewLink="/salesAccounting/salesInvoice/view"
          button={button}
          visibleIcon={visibleIcon}
        />
      </Paper>
    </>
  );
};
