import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Journal/JournalSubContractorForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import { useSnackbar } from "notistack";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const JournalSubContractorCreate = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetails, setFormDetails] = useState({});
  const [vendorNameList, setVendorNameList] = useState([]);
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [ProjectCodeName, setProjectCodeName] = useState();
  const [VendorCodeName, setVendorCodeName] = useState();
  const [populatedData, setPopulatedData] = useState();
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.post(
      "/accountingSystem/journalSubContractor/",
      formValues
    );
    console.log(response);
    history.push("/accountingSystem/journalSubContractor/");
  };
  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  const selectProject = (project) => {
    const item = allProject.find(
      (item) => item.ProjectShortDescription === project
    );
    setFormDetails({
      ...formDetails,
      ...headerState,
      ProjectCodeName: item.ProjectShortDescription,
      CustomerName: item.CustomerName,
    });
    setProjectCodeName(item.ProjectShortDescription);
    getData(FromDate, ToDate, project, VendorCodeName);
  };

  const onVendorChange = (value) => {
    setVendorCodeName(value);
    getData(FromDate, ToDate, ProjectCodeName, value);
  };

  const onFromDateChange = (date) => {
    setFromDate(date);
    getData(date, ToDate, ProjectCodeName, VendorCodeName);
  };

  const onToDateChange = (date) => {
    setToDate(date);
    getData(FromDate, date, ProjectCodeName, VendorCodeName);
  };

  const getData = async (fromDate, toDate, project, vendor) => {
    if (fromDate && toDate && project && vendor)
      try {
        const request = {
          FromDate: fromDate,
          ToDate: toDate,
          ProjectCodeName: project,
          VendorCodeName: vendor,
        };
        const { data } = await axios.post(
          "/accountingSystem/shared/getDataForJournalSubSupplier",
          request
        );
        console.log(data);
        if (data.length > 0) {
          setPopulatedData(data);
        } else
          enqueueSnackbar("Oops No data found", {
            variant: "warning",
          });
      } catch (err) {
        console.error(err);
      }
  };
  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }

    async function getData() {
      try {
        setFormDetails({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }

    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNlist = data.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }
    fetchProject();
    getData();
    fetchVendor();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name=" Journal Sub-Contractor Create"
        linkName1=" Journal Sub-Contractor Dashboard"
        to1="/accountingSystem/journalSubContractor"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Journal Sub-Contractor
      </h3>
      <Form
        onSubmit={onSubmit}
        selectProject={selectProject}
        list={list}
        vendorNameList={vendorNameList}
        onFromDateChange={onFromDateChange}
        onToDateChange={onToDateChange}
        onVendorChange={onVendorChange}
        handleHeaderChange={handleHeaderChange}
        populatedData={populatedData}
        initialValues={pick(
          formDetails,
          "ProjectCodeName",
          "Currency",
          "CostCentre",
          "CustomerName",
          "FinancialPeriod"
        )}
      />
    </div>
  );
};
