import React, { useEffect, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../components/apis/axios";
import TabLayout from "../../components/formcomponents/TabLayout";
import SalesOrderInvoiceTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesOrderInvoiceTable";
import SalesInvoiceRecoveryTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceRecoveryTable";
import SalesInvoiceSummary from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceSummaryTable";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));

const SubContractorBill = (props) => {
  const [show, setShow] = React.useState(false);
  const [tabDisabledArr, setTabDisabledArr] = useState([false, true, true]);
  const classes = useStyle();
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const fieldValues = useSelector(state => state.form?.serviceItem?.values);

  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    if (employees.length > 0 && formValues?.employee) {
      const employee = employees.find(
        (acc) => acc?.employee?.EmployeeCode === formValues?.EmployeeCode
      );
      if(employee) {
        formValues["employee"] = employee?.value;
      }
    }
    if (projects.length > 0 && formValues?.project) {
      const project = projects.find(
        (acc) => acc?.project?.ProjectCode === formValues?.ProjectCode
      );
      if(project) {
        formValues["project"] = project?.value;
      }
    }
    props.onSubmit({
      employee: formValues.employee,
      project: formValues?.project,
      wageType: formValues?.wageType,
      allowances: formValues?.allowances,
    });
  };
  console.log(fieldValues);
  const toggleModal = () => setShow(!show);

  // const validatePhoneNo = (value) =>
  //   value && !/^[0-9]$/.test(value) && value.length !== 10
  //     ? "Phone number invalid"
  //     : undefined;

  // const validateEmail = (value) =>
  //   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  //     ? "Invalid email address"
  //     : undefined;

  const [isValid, setIsValid] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (value) => {
    // const email = value;
    if (emailRegex.test(value)) {
      setIsValid(true);
      setMessage(`Great your "${value}" is valid 👍`);
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email! 👎");
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular", {
          params: {
            status: 'Active'
          }
        });
        setProjects(
          data
          ?.filter(item => item?.Status === "accepted")
          .map((project) => ({
            label: `${project.ProjectCode} - ${project.ProjectShortDescription}`,
            value: project.id,
            project
          }))
        );
      } catch (error) {
        alert(error);
      }
    }
    const fetchAccount = async () => {
      try {
        const {data} = await axios.get("/commonMaster/employeeDetail", {
          params: {
            status: "Accepted",
          },
        });
        setEmployees(
          data?.map((employee) => ({
            label: `${employee.FirstName} ${employee.LastName}`,
            value: employee.id,
            employee
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
    fetchProject();
  }, []);

  const handleChange = (value) => {
    if(value) {
      Object.entries(value?.employee || value?.project)
      .map(field => {
        let value = '';
        if(field[0] === 'benefits') {
          value = field[1]?.benefitCode;
        } else {
          value = field[1];
        }
        dispatch(change('EmployeeJoin', field[0], value))
      })
    }
  }

  const {
    RenderTextField,
    AutoCompleteInput,
    RenderDateField,
  } = Control;

  const inputsFields = [
    {
      component: AutoCompleteInput,
      name: "ProjectCode",
      label: "Project Code/Name",
      list: projects,
      onChange: handleChange
    },
    {
      component: RenderTextField,
      name: "CustomerName",
      label: "Customer Name",
      disabled: true
    },
    {
        component: RenderTextField,
        name: "WorkOrderNo",
        label: "Work Order No",
        disabled: true
      },
    {
      component: RenderDateField,
      name: "Date",
      label: "Date",
      disabled: true
    },
  
    {
      component: RenderTextField,
      name: "LastBillNo",
      label: "Last Bill No",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "LastAmendmentNo",
      label: "Last Amendment No",
      disabled: true
    },
    {
      component: RenderDateField,
      name: "LastAmendmentDate",
      label: "Last Amendment Date",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "LastAmendmentOrderValue",
      label: "Last Amendment Order Value",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "NatureOfBill",
      label: "Nature Of Bill",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "VendorGSTNo",
      label: "Vendor GST No",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "FNCGSTNo",
      label: "FNC GST No",
      disabled: true
    },
    {
      component: RenderTextField,
      name: "BillType",
      label: "Bill Type",
      disabled: true
    },
   
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        <Grid container spacing={2}>
          {inputsFields
            .filter((hiddenField) => !hiddenField.isHidden)
            .map((field, index) => (
              <Grid item xs={6} key={index}>
                <Field {...field} />
              </Grid>
            ))}
        </Grid>
        <div className="field-container">
        <TabLayout
            labels={["Invoice", "Recovery", "Summary"]}
            activeTabs={tabDisabledArr}
            tables={[
              <SalesOrderInvoiceTable
                invoiceTable={props.invoiceTable}
                salesOrderTable={props.salesOrderTable}
                staged={props.staged}
                stageData={props.stageData}
             
                invoiceEditData={props.invoiceEditData}
                materialQty={props.materialQty}
                invoiceTotalAmt={props.invoiceTotalAmt}
                disabled={false}
              />,
              <SalesInvoiceRecoveryTable
                recoveryTypes={props.recoveryTypes}
           
              
                recoverTableEdit={props.recoverTableEdit}
                recoveryTotalAmt={props.recoveryTotalAmt}
              />,
              <SalesInvoiceSummary
       
                gstPercentage={props.gstPercentage}
                LastPaymentUptoLastBill={props.LastPaymentUptoLastBill}
              />
            ]}
          />
          </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="subContractorBill"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};

  const requiredFields = [
    "ServiceItemCode",
  ];

  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "subContractorBill",
  validate,
  enableReinitialize: true,
})(SubContractorBill);
