import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const PhysicalVerificationForm = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    props.onSubmit({ ...formValues });
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            list={props.allPN}
            className="text-field"
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          <Field
            name="MaterialCodeName"
            component={Control.AutoCompleteInput}
            list={props.allMC}
            label="Material Code / Name"
            className="text-field"
            onChange={(value) =>
              props.selectMaterial && props.selectMaterial(value)
            }
          />
          <Field
            name="StockQuantity"
            component={Control.RenderTextField}
            label="Stock Quantity​"
            type="number"
            disabled
          />
          <Field
            name="Availability"
            component={Control.RenderTextField}
            label="Availability"
          />
          <Field
            name="VerificationDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.VerificationDate}
            label="Verification Date​"
          />
          <Field
            name="KeptAt"
            component={Control.AutoCompleteInput}
            list={props.allWH}
            className="text-field"
            label="Kept At"
          />
          <Field
            component={Control.RenderSelectField}
            name="Condition"
            label="Condition​"
          >
            <option value={""}></option>
            <option value={"Good"}>Good</option>
            <option value={"Bad"}>Bad</option>
          </Field>
          <Field
            name="Remarks"
            component={Control.RenderTextField}
            label="Remarks"
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Physical Verifiction"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "MaterialCodeName",
    "VerificationDate",
    "Availability",
    "KeptAt",
    "Condition",
    "Remarks",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "PhysicalVerificationForm",
  validate,
  enableReinitialize: true,
})(PhysicalVerificationForm);
