import React, { useState } from "react";
import axios from "../../components/apis/axios";
import useStyles from "./styles";
import Form from "../../forms/ForgotPassword";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [success, setSuccess] = useState("");
  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    try {
      const { data } = await axios.post("users/findbyemail", formValues);
      console.log("Success -->", data);
      if (data === true) {
        const { data } = await axios.post("/users/forgetPassword", formValues);
        console.log("match--->", data);
        enqueueSnackbar(
          `Email send ${data} !`,
          {
            variant: "success",
          }
        );
        history.push("/");
      }
      setSuccess(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.container}>
      <Form onSubmit={onSubmit} success={success} />
    </div>
  );
};

export default ForgotPassword;
