import axios from "axios";

console.log(process.env.REACT_APP_BACKEND_URL, "llllllllllllllllllllll");

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response errors here
    alert(`Response error: ${error.message}`);
    return Promise.reject(error);
  }
);

// Add a request interceptor
instance.interceptors.request.use(
  (config) => config,
  (error) => {
    // Handle request errors here
    alert(`Request error: ${error.message}`);
    return Promise.reject(error);
  }
);

export default instance;
