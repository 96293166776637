import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import useUpdateSearch from "../../../utils/hooks/useUpdateSearch";
import {
  Box,
  Button,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from "@material-ui/core";
import useStyle from "../../helpers/UseStyle";
import useCustomDataGridStyles from "./CustomDataGrid.styles";
import { Link, useLocation } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteModal from "../../modals/DeleteModal";
import { useState } from "react";
import { ExportCSV } from "../../Exportexcel/Exportexcel";
import { CSVLink } from "react-csv";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import moment from "moment";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { Input as AntInput } from "antd";
import { SelectDate } from "../../DatePicker";
import axios from "../../apis/axios";
import usePermissions from "../../../utils/hooks/usePermissions";
const { Search } = AntInput;

const CustomDataGrid = ({
  paginateResults,
  columns,
  rows,
  viewLink,
  editLink,
  DeleteLink,
  createLink,
  heading,
  fetch,
  exportComponent,
  hideActions = false,
  loading,
  showFilter = true,
  gridRef,
  ...props
}) => {
  const globalStyles = useStyle();
  const classes = useCustomDataGridStyles();
  const [itemId, setItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { search } = useLocation();
  const params = readAllFromQueryParams(search);

  const [updateSearch] = useUpdateSearch();

  const getPermissions = usePermissions();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDelete = async (id) => {
    if (DeleteLink) {
      await axios.delete(`${DeleteLink}/${id}`);
      toggleModal();
      fetch && fetch();
    } else {
      alert("cannot perfrom delete");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = (query) => {
    updateSearch({
      query,
      page: 1,
    });
  };

  // const handleStatus = (status) => {
  //   updateSearch({
  //     status,
  //     page: 1,
  //   });
  // };

  const onDateChange = (dates) => {
    updateSearch({
      fromDate: dates?.from ? moment(dates?.from).toISOString() : "",
      toDate: dates?.to ? moment(dates?.to).toISOString() : "",
      page: 1,
    });
  };

  const statusfilter = ["accepted", "not_reviewed"];

  if (columns?.length > 0) {
    // default actions
    columns.push({
      field: "action",
      headerName: "Action",
      width: 200,
      hide: hideActions,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {!!getPermissions?.View && (
              <span>
                <IconButton>
                  <Link to={`${viewLink}/${params.row.id}`}>
                    <VisibilityIcon color="primary" />
                  </Link>
                </IconButton>
              </span>
            )}
            {!!getPermissions?.Modify && (
              <span>
                <IconButton>
                  <Link to={`${editLink}/${params.row.id}`}>
                    <EditIcon color="action" />
                  </Link>
                </IconButton>
              </span>
            )}
            {!!getPermissions?.Cancel && (
              <span>
                <IconButton
                  onClick={() => {
                    toggleModal();
                    setItemId(params.row.id);
                  }}
                >
                  <DeleteForeverIcon color="secondary" />
                </IconButton>
              </span>
            )}
          </>
        );
      },
    });

    // default column property
    columns.forEach((col) => {
      col.headerAlign = "center";
      col.flex = 1;
    });
  }

  return (
    <div style={{ paddingTop: "0" }}>
      <Paper
        className={globalStyles.table}
        style={{ border: "none", padding: 10 }}
      >
        {showFilter && (
          <>
            <h2 style={{ marginTop: "5px" }} align="center">
              {heading}
            </h2>
            <Box
              display="flex"
              flexflow="row wrap"
              justifyContent="space-between"
              my={2}
              px={2}
            >
              <Box width="350px">
                <Search
                  placeholder="Search"
                  allowClear
                  defaultValue={params?.query}
                  onSearch={onSearch}
                />
              </Box>
              {/* <Box mx="1rem">
            <Select
              defaultValue={params?.status || "accepted"}
              style={{
                width: 120,
              }}
              onChange={handleStatus}
            >
              {statusfilter.map((option) => (
                <Option value={option} key={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Box> */}
              <Box mx="1rem">
                <SelectDate
                  defaultValue={
                    params.fromDate && params.toDate
                      ? [moment(params.fromDate), moment(params.toDate)]
                      : []
                  }
                  setDates={onDateChange}
                />
              </Box>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Export
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    {exportComponent ? (
                      exportComponent
                    ) : (
                      <ExportCSV
                        csvData={rows}
                        fileName={heading}
                        variant="text"
                      />
                    )}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {rows?.length > 0 && (
                      <CSVLink
                        headers={Object.keys(rows[0]).map((key) => ({
                          label: key,
                          key,
                        }))}
                        data={rows}
                        filename={heading}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        CSV
                      </CSVLink>
                    )}
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </>
        )}
        <div className={classes.dataGridWrapper}>
          <DataGrid
            ref={gridRef}
            rows={rows}
            loading={loading}
            rowCount={paginateResults?.total || 0}
            columns={columns}
            checkboxSelection={false}
            disableColumnMenu
            pagination
            paginationMode={paginateResults ? "server" : "client"}
            rowsPerPageOptions={[15, 30, 50, 100]}
            pageSize={paginateResults?.per_page || 15}
            page={paginateResults?.current_page - 1 || 0}
            onPageChange={(page) => {
              updateSearch({ page: page + 1 });
            }}
            onPageSizeChange={(per_page) =>
              updateSearch({
                page: 1,
                per_page,
              })
            }
            classes={{
              root: classes.columnHeader,
            }}
            {...props}
          />
        </div>
      </Paper>
      <DeleteModal
        showModal={showModal}
        toggleModal={toggleModal}
        onDelete={onDelete}
        itemId={itemId}
      />
      {!!getPermissions?.Add && (
        <Link to={createLink}>
          <Fab
            color="primary"
            aria-label="add"
            className={globalStyles.button}
            style={{ outline: "none" }}
          >
            <AddCircleRoundedIcon
              variant="contained"
              className={globalStyles.buttonIcon}
            />
          </Fab>
        </Link>
      )}
    </div>
  );
};

export default CustomDataGrid;
