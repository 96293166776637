import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const MachineGroup = (props) => {
  const [show, setShow] = React.useState(false);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="MachineGroupCode"
            label="Machine Group Code"
          />
          <Field
            component={Control.RenderTextField}
            name="MachineGroupDes"
            label="Machine Group Description"
          />
          <Field
            component={Control.RenderTextField}
            name="MachineGroupLongDes"
            label="Machine Group Long Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="MachineClassCode"
            label="Machine Class Code"
            list={props.machineClass}
            className="text-field"
            onChange={(value) =>
              props.SelectMachineClass && props.SelectMachineClass(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="MachineClassDes"
            label="Machine Class Description"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="machineGroup"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "MachineGroupCode",
    "MachineGroupDescription",
    "MachineGroupLongDescription",
    "MaterialClassCode",
    "MaterialClassDescription",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "machineGroup",
  validate,
  enableReinitialize: true,
})(MachineGroup);
