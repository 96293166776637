import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/SalesAccountingSystem/SalesInvoiceCertificationForm";
import axios from "../../../components/apis/axios";
import { useDispatch, useSelector } from "react-redux";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let allInvoices = [];

export const SalesInvoiceCertificationCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [recoveryTypes, setRecoveryTypes] = useState(null);
  const [list, setList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [invoiceEditData, setInvoiceEditData] = useState(null);
  const [recoverTableEdit, setRecoverTableEdit] = useState(null);
  const [invoiceTotalAmt, setInvoiceTotalAmt] = useState(0);
  const [recoveryTotalAmt, setRecoveryTotalAmt] = useState(0);
  const [summaryTableEdit, setSummaryTableEdit] = useState(null);

  const onSubmit = async (formValues) => {
     await axios.post("/salesAccounting/salesInvoiceCertification", formValues);
    history.push("/salesAccounting/salesInvoiceCertification");  
  };

  const selectInvoice = async (invoice) => {
    if (invoice) {
      let detail = allInvoices.find((item) => item.SalesInvoiceNo === invoice);
      try {
        const { data } = await axios.get(
          `/salesAccounting/salesInvoice/${detail.id}`
        );
        setInvoiceEditData(data.InvoiceDetails);
        setRecoverTableEdit(data.InvoiceRecovery);
        setInvoiceTotalAmt(data.TotalInvoiceAmt);
        setRecoveryTotalAmt(data.TotalRecoveryAmt);
        setSummaryTableEdit(data.InvoiceSummary);
        detail = {
          ...formDetail,
          InvoiceNo: detail.SalesInvoiceNo,
          InvoiceDate: detail.created_at,
        };
      } catch (err) {
        console.log(err);
      }
      setformDetail(detail);
    }
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
      item.ProjectCode + item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectCode + detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      try {
        const { data } = await axios.post(
          `salesAccounting/salesInvoice/getInvoices`,
          {
            ProjectCodeName: detail.ProjectShortDescription,
          }
        );
        allInvoices = data;
        const mapped = data.map((item) => item.SalesInvoiceNo);
        setInvoiceList(mapped);
      } catch (err) {
        console.log(err);
      }
      setformDetail(detail);
    }
  };

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);
  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      allProject = store.GeneralRoot.AllProjectName;
      let approvedList = allProject.filter(
        (item) => item.Status === "accepted"
      );
      const newData = [
        ...new Map(approvedList.map((item) => [item["ProjectCode"], item])).values(),
      ];
      let PNList = newData.map((item) => item.ProjectCode + item.ProjectShortDescription);
      setList(PNList);
    }
    const fetchRecoveryType = async () => {
      try {
        const { data } = await axios.get("/commonMaster/recoveryType/");
        setRecoveryTypes(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    };
    fetchRecoveryType();
  }, [store.GeneralRoot.AllProjectName]);

  return (
    <div>
      <BreadCrumbs
        name="Sales Invoice Certification Create"
        linkName1="Sales Invoice Certification Dashboard"
        to1="/salesAccounting/salesInvoiceCertification"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Invoice Certification
      </h3>
      <Form
        onSubmit={onSubmit}
        list={list}
        invoiceList={invoiceList}
        selectProjectName={selectProjectName}
        selectInvoice={selectInvoice}
        invoiceEditData={invoiceEditData}
        recoverTableEdit={recoverTableEdit}
        invoiceTotalAmt={invoiceTotalAmt}
        recoveryTotalAmt={recoveryTotalAmt}
        summaryTableEdit={summaryTableEdit}
        recoveryTypes={recoveryTypes}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "InvoiceDate",
          "InvoiceNo"
        )}
      />
    </div>
  );
};
