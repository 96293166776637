import React, { useState, useEffect } from "react";
import ServiceItemLink from "../../../forms/ProjectWorkforceManagement/ServiceItemLinkForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import {
  editServiceItem,
  getServiceItemById,
} from "../../../redux/actions/ProjectWorkforceManagementAction";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";




let AllScCode = [];
let AllActivity = [];
let AllMachine = [];

export const ServiceItemEdit = (props) => {
  const [data, setData] = useState();
  const [scList, setScList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [machineList, setMachineList] = useState([]);


  const { match } = props;


  
  useEffect(() => {
    if (!match.params.id) return;
    const fetch = async () => {
      try {
        const data = await getServiceItemById(match.params.id);
        setData(data);
      } catch (error) {
        //
      }
    };
    fetch();
  }, [match.params.id]);
  console.log("data", data);
  const onSubmit = (formValues, history) => {
    editServiceItem(formValues,
      props.match.params.id,
      history
    );
  };

  // const onSubmit = async (formValues) => {
  //   console.log(JSON.stringify(formValues));
  //   for (var key in formValues) {
  //     if (!formValues[key]) {
  //       delete formValues[key];
  //     }
  //   }
  //   const response = await axios.put(
  //     `/commonMaster/workmen/${props.match.params.id}`,
  //     formValues
  //   );
  //   console.log(response);
  //   history.push("/commonMaster/workmen");
  // };


  useEffect(() => {
  
      async function getScCode() {
        try {
          let { data } = await axios.get("/commonMaster/SCItemCodeRequest");
  
          AllScCode = data;
          // const filtered = data.filter((item) => item.Status === "accepted");
          let SCList = AllScCode.map((item) => item.SCItemCode);
          setScList(SCList);
        } catch (err) {
          alert(err);
        }
      }
      async function getActivityCode() {
        try {
          let { data } = await axios.get("/commonMaster/Activity");
  
          AllActivity = data;
          console.log(data);
          // const filtered = data.filter((item) => item.Status === "accepted");
          let ActivityList = AllActivity.map((item) => item.ActivityCode);
          setActivityList(ActivityList);
        } catch (err) {
          alert(err);
        }
      }
      async function getMachineCode() {
        try {
          let { data } = await axios.get("/commonMaster/machine");
  
          AllMachine = data;
          console.log(data);
          // const filtered = data.filter((item) => item.Status === "accepted");
          let MachineList = AllMachine.map((item) => `${item.MachineCode} - ${item.MachineGcode}`);
          setMachineList(MachineList);
        } catch (err) {
          alert(err);
        }
      }
   
      getScCode();
      getActivityCode();
      getMachineCode();

  
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Workmen Exit Edit"
        linkName1="Workmen Exit Dashboard"
        to1="/workforceManagement/workmenExit"
      />
      <h3 style={{ textAlign: "center" }}>Workmen Exit</h3>
      <ServiceItemLink
        onSubmit={onSubmit}
        scList={scList}
        activityList={activityList}
        machineList={machineList}
        initialValues={pick(
          data,
          "ServiceItemCode",
          "ServiceItemDescription",
          "UOM",
          "ActivityCode",
          "ActivityDescription",
          "ActivityUOM",
          "MachineCode",
          "MachineGroupDescription",
          "Tags"
        )}
        edit={true}
      />
    </div>
  );
};
