import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchEmployeeDetailsById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const EmployeeDetailsView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchEmployeeDetailsById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleEmployeeDetails) {
      setData(store.commonMasterSystemRoot.SingleEmployeeDetails);
    }
  }, [store.commonMasterSystemRoot.SingleEmployeeDetails]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("employee", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <>
      <Breadcrumbs
        name="Employee Master View"
        linkName1="Employe Deatils Dashboard"
        to1="/commonMaster/employeeDetails"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Employee Masters</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data.EmployeeCode && (
             <Paper className={classes.paper}>
              <h6>Employee Code:</h6>
              <p>{data.EmployeeCode}</p>
            </Paper>)}

            {data.EmployeeRoleDes && (
             <Paper className={classes.paper}>
              <h6>Employee RoleDes:</h6>
              <p>{data.EmployeeRoleDes}</p>
            </Paper>)}

            {data.Project && (
             <Paper className={classes.paper}>
              <h6>Project:</h6>
              <p>{data.Project}</p>
            </Paper>)}

            {data.Email && (
             <Paper className={classes.paper}>
              <h6>Email:</h6>
              <p>{data.Email}</p>
            </Paper>)}

            {data.EmployeeRole && (  
            <Paper className={classes.paper}>
              <h6>EmployeeRole:</h6>
              <p>{data.EmployeeRole}</p>
            </Paper>)}

            {data.FirstName && (
            <Paper className={classes.paper}>
              <h6>EmployeeName:</h6>
              <p>{data.FirstName} {data.LastName}</p>
            </Paper>)}

            {data.Company && (
            <Paper className={classes.paper}>
              <h6>Company:</h6>
              <p>{data.Company} </p>
            </Paper>)}

            {data.Phone && (
             <Paper className={classes.paper}>
              <h6>Phone:</h6>
              <p>{data.Phone}</p>
            </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
