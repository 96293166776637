import React, { useState, useEffect } from "react";
import SearchOption from "../../../components/ReportsComponent/SearchOptionProject";
import MaterialFlowTable from "../../../components/MaterialSystemDisplay/MaterialFlowTable";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MaterialFlow = () => {
  const classes = useStyle();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { data: materialFlow } = await axios.get(
        "materialManagement/materialFlow"
      );
      console.log("materialFlow ->", materialFlow);
      setData(materialFlow);
    };
    fetch();
  }, []);

  const handleProjectChange = async (value) => {
    const { data: materialFlow } = await axios.post(
      "materialManagement/materialFlow/findByProjectCodeName",
      {
        ProjectCodeName: value,
      }
    );
    console.log(materialFlow);
    setData(materialFlow);
  };

  return (
    <div>
      <Breadcrumbs
        name="Material Flow Reports Dashboard"
        linkName1="All Material Reports"
        to1="/materialManagement/othersReports"
      />
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>Material Flow</h3>
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: "10%" }} className="excel">
            <SearchOption handleProjectChange={handleProjectChange} />
          </div>
        </div>
        <div className="excel">
          <MaterialFlowTable data={data} />
        </div>
      </Paper>
    </div>
  );
};
