import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";

const BankTable = ({ BList, bankDetails, BankEdit, edit }) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(true);
  const [bank, setBank] = useState(null);
 const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    if (BankEdit) {
      setInputFields([...BankEdit]);
      if (bankDetails) bankDetails([...BankEdit]);
      setLoading(false);
    }
    if (!edit) {
      setInputFields([
        {
          BankName: "",
          Balance: "",
          id: "new",
        },
      ]);
    }
    // eslint-disable-next-line
  }, [BankEdit, edit]);

  useEffect(() => {
    if (BList) {
      setLoading(true);
      setBank([...BList]);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [BList]);

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        BankName: "",
        Balance: "",
        id: "new",
      },
    ]);
  };

  const onItemSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        tdata[index] = row;
        const list = bank;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        setBank([...list]);
      } else {
        setBank([...bank, row[fieldName]]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      setInputFields(tdata);
      bankDetails(tdata);
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    bankDetails(values);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    bankDetails(values);
    setInputFields(values);
  };
 const handleCheckNegativeInput = (index, event) => {
   const values = [...inputFields];
   values[index][event.target.name] = event.target.value;
   for (let i = 0; i < values.length; i++) {
     if (+values[i].Balance < 0) {
       values[i].Balance = 0;
       enqueueSnackbar(`You can't enter Negative Number`, {
         variant: "warning",
       });
     }
   }
   setInputFields(values);
   // onChange(values);
 };
  
  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    // onChange(values);
  };
  return (
    <>
      {!loading && inputFields ? (
        <>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">SerialNo.</th>
                  <th className="head-cell">BankName</th>
                  <th className="head-cell">Balance</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {inputFields.map((inputField, index) => (
                  <tr key={index}>
                    <td className="table-data1">{index + 1}</td>
                    <td className="table-data2">
                      {console.log("just", inputField.BankName, bank)}
                      {bank && (
                        <AutoCompleteTableInput
                          list={bank}
                          inputChange={onItemSelect}
                          tableIndex={index}
                          fieldName={"BankName"}
                          defaultValue={inputField.BankName}
                        />
                      )}
                    </td>
                    <td className="table-data4">
                      <input
                        type="number"
                        name="Balance"
                        value={inputField.Balance}
                        onChange={(event) => {
                          handleChangeInput(index, event);
                          handleCheckNegativeInput(index, event);
                           fixedDecimal(index, event, "Balance");
                        }}
                      />
                    </td>
                    <td>
                      {index === 0 ? (
                        <IconButton>
                          <RemoveCircleIcon
                            className="width5"
                            color="disabled"
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => handleRemoveFields(e, index)}
                        >
                          <RemoveCircleIcon
                            className="width5"
                            color="secondary"
                          />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="middle">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon />
              ADD Row
            </Button>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default BankTable;
