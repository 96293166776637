import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchCustomerById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import GSTTableView from "../../../Tables/CommonMaster/GSTTableView";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CustomerView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchCustomerById(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleCustomer) {
      setData(store.commonMasterSystemRoot.SingleCustomer);
    }
  }, [store.commonMasterSystemRoot.SingleCustomer]);

  const classes = useStyle();

  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("customer", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <>
      <Breadcrumbs
        name="Customer View"
        linkName1="Customer Dashboard"
        to1="/commonMaster/customer"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Customer</h3>
          <h6>{`Created by ${data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
            }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data.CustomerCode && (
              <Paper className={classes.paper}>
                <h6>Customer Code:</h6>
                <p>{data.CustomerCode}</p>
              </Paper>)}

            {data.CustomerName && (
              <Paper className={classes.paper}>
                <h6>Customer Name:</h6>
                <p>{data.CustomerName}</p>
              </Paper>)}

            {data.CorporateOffice && (
              <Paper className={classes.paper}>
                <h6>Corporate Office:</h6>
                <p>{data.CorporateOffice}</p>
              </Paper>)}

            {data.RegisteredOffice && (
              <Paper className={classes.paper}>
                <h6>Registered Office:</h6>
                <p>{data.RegisteredOffice}</p>
              </Paper>)}

            {data.Phone && (
              <Paper className={classes.paper}>
                <h6>Phone​:</h6>
                <p>{data.Phone}</p>
              </Paper>)}

            {data.Email && (
              <Paper className={classes.paper}>
                <h6>Email:</h6>
                <p>{data.Email}</p>
              </Paper>)}

            {data.Website && (
              <Paper className={classes.paper}>
                <h6>Website:</h6>
                <p>{data.Website}</p>
              </Paper>)}

            {data.PAN && (
              <Paper className={classes.paper}>
                <h6>PAN:</h6>
                <p>{data.PAN}</p>
              </Paper>)}

            {data.ServiceTaxRegNo && (
              <Paper className={classes.paper}>
                <h6>Service Tax Reg No:</h6>
                <p>{data.ServiceTaxRegNo}</p>
              </Paper>)}

            {data.CINLLPIN && (
              <Paper className={classes.paper}>
                <h6>CIN / LLPIN:</h6>
                <p>{data.CINLLPIN}</p>
              </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
          <div style={{ margin: "1rem" }}>
            <GSTTableView gsts={data.Gsts} />
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
