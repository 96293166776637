import React, { useState, useEffect } from "react";

export const SalesOrderInvoiceTableView = (props) => {
  const [data, setData] = useState([]);
  const { InvoiceDetails } = props;

  useEffect(() => {
    let values = [];
    if (InvoiceDetails) {
      values = [...InvoiceDetails];
    }
    setData(values);
  }, [InvoiceDetails]);

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2" className="head-cell">BOQNo</th>
            <th rowSpan="2" className="head-cell">Item Description</th>
            <th rowSpan="2" className="head-cell">HSN / SAC Code</th>
            <th rowSpan="2" className="head-cell">UOM</th>
            <th rowSpan="2" className="head-cell">Stage Code</th>
            <th rowSpan="2" className="head-cell">Stage Percentage</th>
            <th rowSpan="2" className="head-cell">Rate</th>
            <th rowSpan="2" className="head-cell">Upto Last Bill Qty</th>
            <th rowSpan="2" className="head-cell">This Bill Qty</th>
            <th rowSpan="2" className="head-cell">CummQty</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{row.BOQNo}</td>
              <td className="table-data3">{row.ItemDesc}</td>
              <td className="table-data3">{row.HSNSACCode}</td>
              <td className="table-data3">{row.UOM}</td>
              <td className="table-data3">{row.StageCode}</td>
              <td className="table-data3">{row.StagePercentage}</td>
              <td className="table-data3">{row.Rate}</td>
              <td className="table-data3">{row.UptoLastBillQty}</td>
              <td className="table-data3">{row.ThisBillQty}</td>
              <td className="table-data4">{row.CummQty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
