import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import { Button } from "@material-ui/core";
import "./main.css";

class MachineRepairRegister extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              component={Control.RenderTextField}
              name="ProjectCodeName"
              label="Project code / Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="CustomerName"
              label="Customer Name"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="MachineCodeName"
              label="Machine Code / Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              name="RepairDateTime"
              label="Repair Date and Time"
              style={{ width: "21%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              name="CommDateTime"
              label="Comm. Date Time"
              style={{ width: "21%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="NatureOfDefects"
              label="Nature of Defects"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="PartAssembly"
              label="Part / Assembly"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="CauseOfBreakdown"
              label="Cause of Breakdown"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderSelectField}
              name="RepairBy"
              label="Repair By"
            >
              <option value={""}></option>
              <option value={"Own"}>Own</option>
              <option value={"Agency​"}>Agency​</option>
              <option value={"Both"}>Both</option>
            </Field>
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="RepairDetails"
              label="Repair Details"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderTextField}
              name="VendorCodeName"
              label="Vendor Code / Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderTextField}
              name="WorkOrderNo"
              label="Work Order No"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              component={Control.RenderDateField}
              name="Date"
              label="Date"
              style={{ width: "20%", margin: "1rem" }}
            />
            <Field
              component={Control.RenderDateField}
              name="NextScheduleMaintenanceDate"
              label="Next Schedule Maintenance Date"
              style={{ width: "20%", margin: "1rem" }}
            />
          </div>
          <div className="middle">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "MachineCodeName",
    "RepairDateTime",
    "CommDateTime",
    "NatureOfDefects",
    "PartAssembly",
    "CauseOfBreakdown",
    "RepairBy",
    "RepairDetails",
    "VendorCodeName",
    "WorkOrderNo",
    "Date",
    "NextScheduleMaintenanceDate",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "MachineRepairRegister",
  validate,
  enableReinitialize: true,
})(MachineRepairRegister);
