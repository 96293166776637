import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useStyle from "./UseStyle";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { MachineHireReqTableView } from "../../../Tables/MachineManagementSystemTable/View/MachineHireReqViewTable";
import {
  searchMachinePurchaseRequestById,
  approvalMachineManagement,
} from "../../../redux/actions/MachineManagementaction";
import RoleList from "../../../components/helpers/RoleList";

export const MachinePurchaseRequestView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const history = useHistory();
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchMachinePurchaseRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.machineMangementSystemRoot.SingleMachinePurchaseRequest) {
      setData(store.machineMangementSystemRoot.SingleMachinePurchaseRequest);
    }
  }, [store.machineMangementSystemRoot.SingleMachinePurchaseRequest]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalMachineManagement(
        "machinePurchaseRequest",
        match.params.id,
        history,
        data
      )
    );
  };

  const acessButton = () => {
    if (
      post === RoleList.ADMIN.code ||
      post === RoleList.SITE_INCHARGE.code ||
      post === RoleList.BU_COMMERCIAL.code ||
      post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
      post === RoleList.BU_HEAD.code ||
      post === RoleList.DIRECTOR.code
    )
      return (
        <div className={classes.ButtonGrp}>
          <Button
            variant="contained"
            className={classes.approve}
            onClick={() => onApprove("Approved")}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            className={classes.reject}
            onClick={() => onApprove("Rejected")}
          >
            Reject
          </Button>
        </div>
      );
    return;
  };
  const column = {
    col1: "MachineCode",
    col2: "MachineDescription",
    col5: "Quantity",
    col6: "SuggestedRate",
    col7: "MachineTotal",
  };
  const columnHeading = {
    col1: "Machine Code",
    col2: "Machine Description",
    col5: "Quantity",
    col6: "Suggested Rate",
    col7: "Machine Total",
  };
  return (
    <div>
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Machine Purchase Request</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>PR Type:</h6>
              <p>{data.PRType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Category:</h6>
              <p>{data.MachineCategory}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Currency:</h6>
              <p>{data.Currency}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Required On:</h6>
              <p>{data.RequiredOn}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery At:</h6>
              <p>{data.DeliveryAt}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Total MPR Values:</h6>
              <p>{data.TotalMPRValue}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Remarks:</h6>
              <p>{data.Remarks}</p>
            </Paper>
          </div>
        </Box>
        <MachineHireReqTableView
          materials={data.materials}
          column={column}
          columnHeading={columnHeading}
        />
      </Paper>
      {acessButton()}
    </div>
  );
};
