import React, { useEffect, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));

const EmployeeJoinReport = (props) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyle();
  const [transferOrders, setTransferOrders] = useState([]);
  const [projects, setProjects] = useState([]);
  const fieldValues = useSelector((state) => state.form?.EmployeeJoin?.values);
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    const {
      reportingDate,
      expectedReleaseDate,
      projectData,
      transferOrderData,
    } = formValues;
    props.onSubmit({
      reportingDate,
      expectedReleaseDate,
      project: projectData?.id,
      transferOrder: transferOrderData?.id,
    });
  };

  const toggleModal = () => setShow(!show);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular", {
          params: {
            status: "Active",
          },
        });
        setProjects(
          data
            ?.filter((item) => item?.Status === "accepted")
            .map((project) => ({
              label: `${project.ProjectCode} - ${project.ProjectShortDescription}`,
              value: project.id,
              projectData: project,
            }))
        );
      } catch (error) {
        alert(error);
      }
    };
    const fetchAccount = async () => {
      try {
        const { data } = await axios.get("/hr-management/transfer-order");
        setTransferOrders(
          data?.map((data) => ({
            label: `${data.transferOrderCode}`,
            value: data.id,
            transferOrder: data,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
    fetchProject();
  }, []);

  const handleTransferOrder = (value) => {
    const data = [
      {
        field: "transferOrderDate",
        value: value?.transferOrder?.created_at
          ? moment(value?.transferOrder?.created_at).format("DD-MM-YYYY")
          : null,
      },
      {
        field: "employee",
        value: value?.transferOrder?.employee?.employee?.EmployeeCode,
      },
      {
        field: "functionalRole",
        value: value?.transferOrder?.functionalRole,
      },
      {
        field: "reportEmployee",
        value: value?.transferOrder?.reportEmployee?.employee?.EmployeeCode,
      },
      {
        field: "transferOrderData",
        value: value?.transferOrder,
      },
    ];
    data.forEach((item) => {
      dispatch(change("EmployeeJoinReport", item.field, item.value));
    });
  };

  const handleProjectChange = (value) => {
    dispatch(
      change("EmployeeJoinReport", "customerName", value?.projectData?.CustomerName)
    );
    dispatch(change("EmployeeJoinReport", "costCentre", value?.projectData?.CostCentre));
    dispatch(change("EmployeeJoinReport", "projectData", value?.projectData));
  };

  const { RenderTextField, AutoCompleteInput, RenderDateField } = Control;

  const inputsFields = [
    {
      component: AutoCompleteInput,
      name: "project",
      list: projects,
      label: "Project Code / Name",
      onChange: handleProjectChange,
    },
    {
      component: RenderTextField,
      name: "customerName",
      label: "Customer Name",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "costCentre",
      label: "Cost Centre",
      disabled: true,
    },
    {
      component: AutoCompleteInput,
      name: "transferOrder",
      list: transferOrders,
      label: "Transfer Order No.",
      onChange: handleTransferOrder,
    },
    {
      component: RenderTextField,
      name: "transferOrderDate",
      label: "Transfer Order Date",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "employee",
      label: "Employee Code/Name",
      disabled: true,
    },
    {
      component: RenderDateField,
      name: "reportingDate",
      label: "Reporting Date",
      initialValue: props?.initialValues?.reportingDate
    },
    {
      component: RenderTextField,
      name: "functionalRole",
      label: "Functional Role",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "reportEmployee",
      label: "Reporting to Employee",
      disabled: true,
    },
    {
      component: RenderDateField,
      name: "expectedReleaseDate",
      label: "Expected Release Date",
      initialValue: props?.initialValues?.expectedReleaseDate
    },
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        <Grid container spacing={2}>
          {inputsFields
            .filter((hiddenField) => !hiddenField.isHidden)
            .map((field, index) => (
              <Grid item xs={6} key={index}>
                <Field {...field} />
              </Grid>
            ))}
        </Grid>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Employee Join Report"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};

  const requiredFields = [
    "project",
    "transferOrder",
    "reportingDate",
    "expectedReleaseDate",
  ];

  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  if(moment(values?.expectedReleaseDate).isBefore(values?.reportingDate)) {
    err['expectedReleaseDate'] = 'Date should be greater than reporting' 
  }

  return err;
};

export default reduxForm({
  form: "EmployeeJoinReport",
  validate,
  enableReinitialize: true,
})(EmployeeJoinReport);
