import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
import moment from "moment";

const WorkmenJoining = (props) => {
  const [type, setType] = useState("");
  const [mStatus, setMStatus] = useState("");
  const [state, setState] = useState({
    table: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }

    props.onSubmit(formValues);
  };

  // const validateAge = (input) => {
  //   if (input) {
  //     const years = formatDistanceStrict(input, new Date(), {
  //       unit: "year",
  //     }).split(" ")[0];
  //     console.log(years);
  //     if (+years <= 18) {
  //       return `Age should be more than 18`;
  //     }
  //   }
  //   return;
  // };

  const checkNegativeValue = (value) =>
    value && +value <= 0 ? `You can't enter Negative Number` : undefined;

  const fixedDecimal = (value) => {
    const decimal = 2;
    if (value) {
      try {
        value = value.split(".");
        if (value[1]?.length > decimal) {
          return `Give input fixed upto 2 decimal`;
        }
      } catch (e) {
        console.error(e);
      }
    }
  };


  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCode"
            label="Project Code"
            component={Control.AutoCompleteInput}
            list={props.list}
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            label="Cost Centre"
            name="CostCentre"
            disabled
          ></Field>
          <Field
            component={Control.RenderSelectField}
            name="WorkmenType"
            label="Workmen Type"
            onChange={(value) => setType(value)}
          >
            <option value={""}></option>
            <option value={"Departmental"}>Departmental</option>
            <option value={"SubContractor"}>Sub-Contractor</option>
          </Field>
          {type === "SubContractor" && (
            <>
              <Field
                component={Control.AutoCompleteInput}
                name="VendorCode"
                list={props.VNList}
                label="Vendor Code / Name"
                className="text-field"
              />
              <Field
                component={Control.RenderTextField}
                name="WorkOrderNo"
                label="Work Order No"
              />
              <Field
                component={Control.RenderDateAllField}
                name="WorkOrderDate"
                label="Work Order Date"
                initialValue={props.initialValues.WorkOrderDate}
              />
            </>
          )}
         
          <Field
            component={Control.AutoCompleteInput}
            name="WorkmenName"
            label="Name of Workmen"
            list={props.workmenlist}
            className="text-field"
            onChange={(value) =>
              props.selectWorkmenName && props.selectWorkmenName(value)
            }
          />
             <Field
            component={Control.RenderTextField}
            name="WorkmenCode"
            label="Workmen Code"
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenReligion"
            label="Religion"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenFather"
            label="Father / Husband Name"
            disabled
          />
          <Field
            component={Control.RenderDatePastField}
            name="WorkmenDOB"
            label="Date of Birth"
            initialValue={props?.initialValues?.WorkmenDOB}
            maxDate = {moment().subtract(18, "year").format("d MMM yyyy")}
          />
          <Field
            component={Control.RenderSelectField}
            name="WorkmenGender"
            label="Gender"
            disabled
          >
            <option value={""}></option>
            <option value={"Male"}>Male</option>
            <option value={"Female"}>Female</option>
            <option value={"Other"}>Other</option>
          </Field>
        
          <Field
            component={Control.RenderTextField}
            name="WorkmenPresentAddress"
            label="Present Address"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenPermanentAddress"
            label="Permanent Address"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenEmail"
            type="email"
            label="Email ID"
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenPhone"
            type="phone"
            label="Emergency Contact Number"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenContact"
            type="phone"
            label="Mobile"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="WorkmenMarital"
            label="Marital Status"
            onChange={(value) => setMStatus(value)}
            className="text-field"
          
          >
            <option value={""}></option>
            <option value={"Married"}>Married</option>
            <option value={"Unmarried"}>Unmarried</option>
          </Field>
          {mStatus === "Married" && (
            <Field
              component={Control.RenderTextField}
              name="WorkmenChild"
              type="number"
              label="No of Children (If above selected Married)"
            />
          )}

          <Field
            component={Control.RenderTextField}
            name="WorkmenMotherTongue"
            label="Mother Tongue"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenIdentification"
            label="Identification Mark"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenHeight"
            label="Height in Centimetre"
            type="number"
            validate={[checkNegativeValue, fixedDecimal]}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenWeight"
            label="Workmen Weight"
            type="number"
            validate={[checkNegativeValue, fixedDecimal]}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenExpInYears"
            label="Past Experience In Years"
            type="number"
            validate={[checkNegativeValue]}
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenExpInMonths"
            label="Past Experience In Months"
            type="number"
            validate={[checkNegativeValue]}
          />
          <Field
            component={Control.RenderDatePastField}
            initialValue={props.initialValues.WorkmenDOJ}
            name="WorkmenDOJ"
            label="Date of Joining"
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenEducation"
            label="Educational Qualification"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            name="WorkmenVision"
            label="Vision"
            list={["Normal", "Spectacles"]}
            className="text-field"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenVisionLeft"
            label="Vision Left"
            validate={[checkNegativeValue, fixedDecimal]}
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenVisionRight"
            label="Vision Right"
            validate={[checkNegativeValue, fixedDecimal]}
          />
          <Field
            component={Control.RenderSelectField}
            name="WorkmenSkill"
            label="Skill Category"
            disabled
          >
            <option value={""}></option>
            <option value={"Highly Skilled"}>Highly Skilled</option>
            <option value={"Semi Skilled"}>Semi Skilled</option>
            <option value={"Skilled"}>Skilled</option>
            <option value={"Un Skilled"}>Un Skilled</option>
          </Field>
          <Field
            component={Control.RenderTextField}
            name="WorkmenCadre"
            label="Cadre"
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenPF"
            label="PF Account No"
            type="number"
            validate={[checkNegativeValue, fixedDecimal]}
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenESIC"
            label="ESIC"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenBankAccount"
            label="Bank Account No"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenBankName"
            label="Bank Name"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenBankIFSC"
            label="IFSC"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenAadhar"
            label="Aadhar No"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenPAN"
            label="PAN"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenWages"
            label="Wages"
            validate={[checkNegativeValue, fixedDecimal]}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenWageType"
            label="Wages Type"
            disabled
          >
            <option value={""}></option>
            <option value={"Daily"}>Daily</option>
            <option value={"Monthly"}>Monthly</option>
          </Field>
          <Field
            component={Control.RenderTextField}
            name="WorkmenOT"
            label="OT Type"
            disabled
          >
            <option value={""}></option>
            <option value={"Single"}>Single</option>
            <option value={"Double"}>Double</option>
            <option value={"OneandHalftime"}>One and Half time</option>
            <option value={"NotApplicable"}>Not Applicable</option>
          </Field>
          <Field
            component={Control.AutoCompleteInput}
            name="WorkmenAllowance"
            list={props.benefitlist}
            className="text-field"
            label="Allowance"
           
          />
          <Field
            component={Control.RenderTextField}
            name="WorkmenValue"
            label="Workmen Value"
        
          />
        </div>
        
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Workmen Join"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCode",
    "CustomerName",
    "CostCentre",
    "WorkmenType",
    "WorkmenName",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "WorkmenJoining",
  validate,
  enableReinitialize: true,
})(WorkmenJoining);
