import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

const GoodsInspectionVoucher = ({
  materialsTable,
  materials,
  storeTableData,
}) => {
  const [inputFields, setInputFields] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let values = [];
    if (materialsTable) {
      for (let i = 0; i < materialsTable.length; i++) {
        values = [
          ...values,
          {
            SerialNo: materialsTable[i].SerialNo,
            MaterialCode: materialsTable[i].MaterialCode,
            MaterialDescription: materialsTable[i].MaterialDescription,
            HSNSACCode: materialsTable[i].HSNSACCode,
            UOM: materialsTable[i].UOM,
            ReceivedQuantity: materialsTable[i].Quantity,
            RejectedQuantity: 0,
          },
        ];
      }
      setInputFields(values);
      onChange(values);
    }
    //eslint-disable-next-line
  }, [materialsTable]);

  useEffect(() => {
    if (materials) {
      setInputFields([...materials]);
      onChange([...materials]);
    }
    //eslint-disable-next-line
  }, [materials]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
    }
    if (event.target.name === "ReasonForRejection") {
      setInputFields(values);
      onChange(values);
    } else {
      let row = values[index];
      const qty = +row.ReceivedQuantity - +row.AcceptQuantity;
      if (qty < 0) {
        enqueueSnackbar(
          `Accepted Qunating cannot be greater than received quantity`,
          {
            variant: "error",
          }
        );
        return;
      } else {
        row.RejectedQuantity = parseFloat(qty).toFixed(2);
        values[index] = row;
        setInputFields(values);
        onChange(values);
      }
    }
  };

   const fixedDecimal = (index, event, fieldName) => {
     const values = [...inputFields];
     const decimal = 2;

     if (event.target.name === fieldName) {
       let value = event.target.value;
       try {
         value = value.split(".");
         if (value[1].length > decimal) {
           value[1] = value[1].substring(0, decimal);
         }
         let no = `${value[0]}.${value[1]}`;
         values[index][event.target.name] = no;
       } catch (e) {
         console.error(e);
         values[index][event.target.name] = event.target.value;
       }
     } else values[index][event.target.name] = event.target.value;
     setInputFields(values);
     onChange(values);
   };
  return (
    <>
  
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">No.</th>
                <th className="head-cell">Material Code</th>
                <th className="head-cell">Material Description</th>
                <th className="head-cell">HSN / SAC Code</th>
                <th className="head-cell">UOM</th>
                <th className="head-cell">Received Quantity</th>
                <th className="head-cell">Accepted Quantity</th>
                <th className="head-cell">Rejected Quantity</th>
                <th className="head-cell">Reason for Rejection</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {inputFields?.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">{inputField.SerialNo}</td>
                  <td className="table-data2">{inputField.MaterialCode}</td>
                  <td className="table-data2">
                    {inputField.MaterialDescription}
                  </td>
                  <td className="table-data2">{inputField.HSNSACCode}</td>
                  <td className="table-data2">{inputField.UOM}</td>
                  <td className="table-data2">{inputField.ReceivedQuantity}</td>
                  <td className="table-data2">
                    <input
                      style={{ width: "10rem" }}
                      name="AcceptQuantity"
                      value={inputField.AcceptQuantity}
                      type="number"
                      min={0}
                      step={0.01}
                      onChange={(event) => {
                        handleChangeInput(index, event);
                        fixedDecimal(index, event, "AcceptQuantity");
                      }}
                    />
                  </td>
                  <td className="table-data2">{inputField.RejectedQuantity}</td>
                  <td className="table-data4">
                    <input
                      style={{ width: "10rem" }}
                      name="ReasonForRejection"
                      value={inputField.ReasonForRejection}
                      onChange={(event) => handleChangeInput(index, event)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

    </>
  );
};

export default GoodsInspectionVoucher;
