import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";

const DailyProgressMaterialTable = ({
  handleMaterialChange,
  activityIndex,
  materialList,
  materialDetailsEdit,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: "",
      MaterialCodeName: "",
      UOM: "",
      QuantityConsumed: "0",
      id: "new",
    },
  ]);
  const [listOfMaterials, setListOfMaterials] = useState(null);
  const [allowable, setAllowable] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (materialList) {
      setListOfMaterials([...materialList]);

      if (materialDetailsEdit) {
        let temp = [];
        for (let i = 0; i < materialDetailsEdit.length; i++) {
          const item = materialList.find(
            (item) =>
              item.MaterialCode + "-" + item.MaterialDescription ===
              materialDetailsEdit[i].MaterialCodeName
          );
          temp.push({
            decimal: item.AllowableDecimal,
          });
        }
        setAllowable(temp);
      }
    }
    //eslint-disable-next-line
  }, [materialList, materialDetailsEdit]);
  
      useEffect(() => {
        if (materialDetailsEdit && materialDetailsEdit.length > 0) {
          setInputFields([...materialDetailsEdit]);
        }
      }, [materialDetailsEdit]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    if (values) {
      handleMaterialChange(values, activityIndex);
    }
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: "",
        MaterialCodeName: "",
        UOM: "",
        QuantityConsumed: "0",
        id: "new",
      },
    ]);
  };

  const onItemSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.MaterialCodeName =
          values.MaterialCode + "-" + values.MaterialDescription;
        row.UOM = values.UOM;

        const tempAllowable = [...allowable];
        tempAllowable[index] = { decimal: values.AllowableDecimal };
        setAllowable(tempAllowable);

        tdata[index] = row;
        setInputFields(tdata);

        const list = listOfMaterials;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setListOfMaterials([...list]);
      } else {
        const item = listOfMaterials.find(
          (item) => item.MaterialCode === row.MaterialCodeName
        );
        setListOfMaterials([...listOfMaterials, item]);
        row.MaterialCodeName = "";
        row.UOM = "";
        row.QuantityConsumed = 0;
      }
    } catch (err) {
      console.error(err);
    }
  };

  
  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row[event.target.name] = event.target.value;
    values[index] = row;
    // const decimal = allowable[index].decimal;
    if (event.target.name === "QuantityConsumed") {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > allowable[index].decimal) {
          value[1] = value[1].substring(0, allowable[index].decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;

    setInputFields(values);
    if (values) {
      handleMaterialChange(values, activityIndex);
    } else {
      handleMaterialChange({
        error: true,
        errorKind: "some input field is empty",
      });
    }
    console.log(values);
  };

  const handleCheckNegativeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    for (let i = 0; i < values.length; i++) {
      if (+values[i].QuantityConsumed < 0) {
        values[i].QuantityConsumed = 0;
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    // onChange(values);
  };
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">Material Code Name</th>
            <th className="head-cell">UOM</th>
            <th className="head-cell">Quantity Consumed</th>
          </tr>
        </thead>
        {inputFields.map((inputField, index) => (
          <tr>
            <td className="table-data1">{index + 1}</td>
            <td className="table-data2">
              {listOfMaterials && (
                <>
                  <Autocomplete
                    id="MaterialCodeName"
                    options={listOfMaterials}
                    getOptionLabel={(item) =>
                      item.MaterialCode + "-" + item.MaterialDescription
                    }
                    style={{ width: "100%" }}
                    onChange={(event, values) =>
                      onItemSelect(
                        event,
                        values,
                        index,
                        "MaterialCode",
                        "MaterialCodeName",
                        "MaterialDescription"
                      )
                    }
                    renderInput={(params) => <TextField {...params} />}
                    defaultValue={listOfMaterials.find(
                      (item) =>
                        item.MaterialCode + "-" + item.MaterialDescription ===
                        inputField.MaterialCodeName
                    )}
                  />
                </>
              )}
            </td>
            <td className="table-data2">{inputField.UOM}</td>
            <td className="table-data4">
              <input
                style={{ width: "8rem" }}
                name="QuantityConsumed"
                type="number"
                value={inputField.QuantityConsumed}
                onChange={(event) => {
                  handleCheckNegativeInput(index, event);
                  handleChangeInput(index, event);
                }}
              />
            </td>
            <td>
              {index === 0 ? (
                <IconButton>
                  <RemoveCircleIcon className="width5" color="disabled" />
                </IconButton>
              ) : (
                <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                  <RemoveCircleIcon className="width5" color="secondary" />
                </IconButton>
              )}
            </td>
          </tr>
        ))}
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
        >
          <AddCircleIcon />
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default DailyProgressMaterialTable;
