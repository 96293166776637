import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import JournalSubContractorTable from "../../../Tables/AccountingSystem/SubContractorTable";
import * as Control from "../../../components/formcomponents/control";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import "../../../components/helpers/form.style.css";

const JournalSubContractorForm = (props) => {
  const [state, setState] = useState({
    tableErrorKind: "",
    tableError: false,
    tableData: null,
  });
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  const onSubmit = (formValues) => {
    props.onSubmit({
      ...formValues,
      paymentDetails: state.tableData,
    });
  };

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            label="Project Code"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="VendorCodeName"
            label="Vendor Code/Name"
            className="text-field"
            component={Control.AutoCompleteInput}
            list={props.vendorNameList}
            onChange={(value) =>
              props.onVendorChange && props.onVendorChange(value)
            }
          />
          <Field
            name="FromDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.FromDate}
            onChange={(value) =>
              props.onFromDateChange && props.onFromDateChange(value)
            }
            label="From Date"
          />
          <Field
            name="ToDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.ToDate}
            onChange={(value) =>
              props.onToDateChange && props.onToDateChange(value)
            }
            label="To Date"
          />
          <div className="excel">
            <JournalSubContractorTable
              tableData={tableData}
              journal={props.journal}
              header={["Authorize"]}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Journal Sub-Contractor"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
        ButtonName="Authorize"
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCenter",
    "FinancialPeriod",
    "Currency",
    "ProjectCode",
    "CustomerName",
    "VendorCodeName",
    "FromDate",
    "ToDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "JournalSubContractorForm",
  validate,
  enableReinitialize: true,
})(JournalSubContractorForm);
