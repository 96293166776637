import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import Table from "../../../Tables/AccountingSystem/ChequeRealisationTable";
import * as Control from "../../../components/formcomponents/control";

class DisbursementChequeRealisationForm extends React.Component {
  constructor() {
    super();

    this.state = {
      isSubmit: false,
    };
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  tableData = (data) => {
    if (!data) {
      this.setState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      this.setState({ tableData: data, tableErrorKind: "", tableError: false });
    }
  };

  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              name="CostCenter"
              component={Control.RenderTextField}
              label="Cost Center"
              style={{ width: "45%", margin: "1rem" }}
            />
            <Field
              name="FinancialPeriod"
              component={Control.RenderTextField}
              label="Financial Period"
              style={{ width: "20%", margin: "1rem" }}
            />
            <Field
              name="Currency"
              label="Currency"
              component={Control.RenderTextField}
              style={{ width: "20%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="Bank"
              label="Bank"
              component={Control.RenderTextField}
              style={{ width: "45%", margin: "1rem" }}
            />
            <Field
              name="FromDate"
              label="From Date"
              component={Control.RenderDateField}
              initialValue={this.props.initialValues.RequiredOn}
              style={{ width: "15%", margin: "1rem" }}
            />
            <Field
              name="ToDate"
              label="To Date"
              initialValue={this.props.initialValues.ToDate}
              component={Control.RenderDateField}
              style={{ width: "15%", margin: "1rem" }}
            />
          </div>
          <div className="excel">
            <Table tableData={this.tableData} cheques={this.props.cheques} />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {this.state.tableError ? `${this.state.tableErrorKind}` : ""}
            </p>
          </div>
          <div className="middle">
            <Button
              type="submit"
              variant="contained"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                width: "20%",
              }}
            >
              Sumbit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCenter",
    "FinancialPeriod",
    "Currency",
    "Bank",
    "FromDate",
    "ToDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "DisbursementChequeRealisationForm",
  validate,
  enableReinitialize: true,
})(DisbursementChequeRealisationForm);
