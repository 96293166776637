import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import { searchTransferOrderById } from "../../../redux/actions/HRManagementActions";
import TransferOrder from "../../../forms/ProjectHRManagement/TransferOrder/TransferOrder";

export const TransferOrderEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchTransferOrderById(match.params.id);
        setData(data);
    }

    fetch();
  }, [match.params.id]);

  const onSubmit = async (formValues) => {
    await axios.put(`/hr-management/transfer-order/${match.params.id}`, formValues);
    history.push("/project-hr-management/transfer-order");
  };

  const getSelectedProject = (project) => {
    if(project) {
      return `${project.ProjectCode} - ${project.ProjectShortDescription}`;
    }
    return "";
  }

  const getSelectedEmployee = (employee) => {
    if(employee) {
      return `${employee.EmployeeCode} - ${employee.FirstName} ${employee.LastName}`;
    }
    return "";
  }

  return (
    <div>
      <Breadcrumbs
        name="Transfer Order Edit"
        linkName1="Transfer Order Dashboard"
        to1="/project-hr-management/transfer-order"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Transfer Order
      </h3>
      <div className="line"></div>
      <TransferOrder
        initialValues={{
            ...data,
            fromProject: data?.employee?.project?.ProjectCode,
            fromCostCentre: data?.employee?.project?.CostCentre,
            fromCustomerName: data?.employee?.project?.CustomerName,
            toCostCentre: data?.toProject?.CustomerName,
            toCustomerName: data?.toProject?.CustomerName,
            employeeData: data?.employee,
            reportEmployeeData: data?.reportEmployee,
            toProjectData: data?.toProject,
            employee: getSelectedEmployee(data?.employee?.employee), 
            toProject: getSelectedProject(data?.toProject),
            reportEmployee: getSelectedEmployee(data?.reportEmployee?.employee)
        }}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
