import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import CostcenterTableRow from "./CostCenterGSTRowTable";

const CustomerGSTTable = ({
  tableData,
  companyGsts,
  gsts,
  isEdit,
  costcenteredit,
  Gst
}) => {
  const [inputFields, setInputFields] = useState();
  
  useEffect(() => {
    if (!isEdit) {
      setInputFields([
        {
          SerialNo: 1,
          GSTRegNo: "",
          GSTState: "",
          GSTAddress: "",
          id: "",
        },
      ]);
    } else if (isEdit && costcenteredit) {
      setInputFields([...costcenteredit]);
      onChange([...costcenteredit]);
    }
    // eslint-disable-next-line
  }, [costcenteredit, isEdit]);

  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: 0,
        GSTRegNo: "",
        GSTState: "",
        GSTAddress: "",
        id: "new",
      },
    ]);
  };

 
  return (
    <>
    { inputFields ? (
      <div
        className="tableContainer"
        style={{ marginLeft: "2rem", width: "100%" }}
      >
        <table>
          <thead className="table-head">
            <tr>
              <th className="head-cell">Sl No.</th>
              <th className="head-cell">State</th>
              <th className="head-cell">GSTRegNo</th>
              <th className="head-cell">Address</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {inputFields?.map((inputField, index) => (
              <tr key={index}>
                <CostcenterTableRow
                  companyGsts={companyGsts}
                  gsts={gsts}
                  inputField={inputField}
                  inputFields={inputFields}
                  index={index}
                  tableData={tableData}
                  setInputFields={setInputFields}
                  onChange={onChange}
                />
              </tr>
            ))}
          </tbody>
        </table>
        <div className="alignAt-right">
          <Button
            onClick={(e) => handleAddFields(e)}
            variant="contained"
            color="primary"
          >
            <AddCircleIcon />
            ADD Row
          </Button>
        </div>
      </div>
    ) : (
      <LinearProgress />
    )}
  </>
  );
};
export default CustomerGSTTable;