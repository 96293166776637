import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import WorkmenOrderAmendment from "../../../forms/ProjectWorkforceManagement/WorkmenOrderAmendmentForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllVendor = [];
let AllCostCentre = [];


export const WorkmenOrderAmendmentCreate = () => {
  const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [costCentre, setCostCentrelist] = useState([]);
  const [benefitlist, setBenefitlist] = useState([]);
  const history = useHistory();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "workforceManagement/workmenJoining",
      formValues
    );
    console.log(response);
    history.push("/workforceManagement/workmenJoining/");
  };

  const selectProjectName = async (projectName) => {

    const item = allProject.find(
      (item) => item.ProjectCode === projectName?.split(" ")[0]
    );
    if(item){
      let payload ={
        ...formDetail,
        ProjectCode: projectName,
        CustomerName: item.CustomerName,
        CostCentre: item.CostCentre,
      }
      setformDetail(payload);
    }

    
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        console.log(data)
        const filtered = data.filter((item) =>  item?.Status === "accepted" && item?.projectStatus === "Active");
        allProject = filtered;
        let PNlist = filtered.map(
          (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
        );
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    async function getVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");

        AllVendor = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let VNList = AllVendor.map((item) => item.VendorName);
        setVendorList(VNList);
      } catch (err) {
        alert(err);
      }
    }
    async function getCostCentre() {
      try {
        let { data } = await axios.get("/commonMaster/costCentre");
        console.log(data);
        
        AllCostCentre = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let CCList = AllCostCentre.map((item) => item.CostCenterName);
        setCostCentrelist(CCList);
      } catch (err) {
        alert(err);
      }
    }
    async function getBenefits() {
      try {
        let { data } = await axios.get("/commonMaster/benefits");
        console.log(data);
        
        
        
        let benefitlist = data.map((item) =>item?.benefitCode + " - " + item?.benefitDescription);
        setBenefitlist(benefitlist);
        console.log(benefitlist);
      } catch (err) {
        alert(err);
      }
    }
    getBenefits();
    getCostCentre();
    getVendor();
    fetchProject();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Workmen Order Amendment Create"
        linkName1="Workmen Order Amendment Dashboard"
        to1="/workforceManagement/workmenOrderAmendment/"
      />
      <h3 style={{ textAlign: "center" }}>Workmen Order Amendment</h3>
      <WorkmenOrderAmendment
        onSubmit={onSubmit}
        list={list}
        benefitlist={benefitlist}
        VNList={vendorList}
        CCList={costCentre}
        selectProjectName={selectProjectName}
        initialValues={pick(
          formDetail,
          "ProjectCode",
          "CustomerName",
          "CostCentre"
        )}
      />
    </div>
  );
};
