import React, { useState, useEffect } from "react";
export const SalesOrderStagesView = (props) => {
  const [data, setData] = useState([]);
  const { stages } = props;

  useEffect(() => {
    let values = [];
    if (stages) {
      values = [...stages];
    }
    setData(values);
  }, [stages]);

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo</th>
            <th className="head-cell">Retention Terms</th>
            <th className="head-cell">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td  className="table-data1">{row.SerialNo}</td>
              <td  className="table-data3">{row.StageName}</td>
              <td  className="table-data4">{row.StagePercentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
