import React, { useState, useEffect } from "react";
import { Field, change, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SalesOrderStageTable from "../../Tables/SalesAcountingSystem/SalesOrder/SalesOrderStageTable";
import RetentionTermsTable from "../../Tables/SalesAcountingSystem/SalesOrder/RetentionTermsTable";
import PaymentTermTable from "../../Tables/PaymentTerms/PaymentTermsTable";
import SalesOrderTable from "../../Tables/SalesAcountingSystem/SalesOrder/SalesOrderTable";
import LdclauseTable from "../../Tables/SalesAcountingSystem/SalesOrder/LdclauseTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";
import { SelectDate } from "../../components/DatePicker";
import { InputLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import moment from "moment";

const SalesOrder = (props) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const [tableDataState, setTableDataState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
  });
  const [paymentTermsData, setPaymentTermsData] = useState({
    paymentData: null,
    paymentTermsErrorKind: "",
    paymentTermsError: false,
  });
  const [retentionData, setRetentionData] = useState({
    retentionData: null,
    retentionTermsErrorKind: "",
    retentionTermsError: false,
  });
  const [stageData, setStageData] = useState({
    stageData: null,
    stageTermsErrorKind: "",
    stageTermsError: false,
  });

  const [ldclauseData, setLdclauseData] = useState({
    ldclauseData: null,
    ldclauseTermsErrorKind: "",
    ldclauseTermsError: false,
  });
  const toggleModal = () => setShow(!show);
  const [stage, setStage] = useState(false);
  const [ldclause, setLdclause] = useState(true);
  const [insurance, setInsurance] = useState("");

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const { initialValues } = props;

  useEffect(() => {
    if (initialValues.Stage && initialValues.Stage === "Yes") setStage(true);
  }, [initialValues.Stage]);
  useEffect(() => {
    if (initialValues.Ldclause && initialValues.Ldclause === "No")
      setLdclause(false);
  }, [initialValues.Ldclause]);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    let total = 0;
    let boqSet = new Set();
    tableDataState?.tableData?.forEach((item) => {
      boqSet.add(item.BOQNo);
      total += +item.NetAmount;
    });
    total = total.toFixed(2);
    let error = true;
    let errorMsg = "";
    if (
      props.initialValues.OrderType === "Rate Contract" ||
      +total === +props.orderValue
    )
      error = false;
    else {
      error = true;
      errorMsg = "Please match project order value";
    }

    // if (boqSet.size === tableDataState?.tableData?.length) error = false;
    // else {
    //   error = true;
    //   errorMsg = "BOQNo needs to be unique";
    // }
    if (!error) {
      console.log(
        {
          ...formValues,
          tableData: tableDataState.tableData,
          stageData: stageData.stageData,
          retentionData: retentionData.retentionData,
          paymentData: paymentTermsData.paymentData,
          ldclauseData: ldclauseData.ldclauseData,
        },
        "tabledata"
      );
      props.onSubmit({
        ...formValues,
        tableData: tableDataState.tableData,
        stageData: stageData.stageData,
        retentionData: retentionData.retentionData,
        paymentData: paymentTermsData.paymentData,
        ldclauseData: ldclauseData.ldclauseData,
      });
    } else {
      console.log("ghj");
      alert(errorMsg);
    }
  };

  const tableData = (data) => {
    if (data.error) {
      setTableDataState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setTableDataState({
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const paymentTermsTable = (data) => {
    if (!data) {
      setPaymentTermsData({
        paymentTermsErrorKind: "table row are not fully field",
        paymentTermsError: true,
      });
    } else {
      setPaymentTermsData({
        paymentData: data,
        paymentTermsErrorKind: "",
        paymentTermsError: false,
      });
    }
  };

  const retentionTermsTable = (data) => {
    if (!data) {
      setRetentionData({
        retentionTermsErrorKind: "table row are not fully field",
        retentionTermsError: true,
      });
    } else {
      setRetentionData({
        retentionData: data,
        retentionTermsErrorKind: "",
        retentionTermsError: false,
      });
    }
  };

  const stageTermsTable = (data) => {
    if (!data) {
      setStageData({
        stageTermsErrorKind: "table row are not fully field",
        stageTermsError: true,
      });
    } else {
      setStageData({
        stageData: data,
        stageTermsErrorKind: "",
        stageTermsError: false,
      });
    }
  };
  const LdclauseTermsTable = (data) => {
    if (!data) {
      setLdclauseData({
        ldclauseTermsErrorKind: "table row are not fully field",
        ldclauseTermsError: true,
      });
    } else {
      setLdclauseData({
        ldclauseData: data,
        ldclauseTermsErrorKind: "",
        ldclauseTermsError: false,
      });
    }
  };

  const handleDefectLiability = (period) => {
    dispatch(change('SalesOrderForm', 'DefectLiabilityPeriod', period ? JSON.stringify(period) : ""));
  }

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project Code / Name"
            list={props.ProjectList}
            className="text-field"
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerGSTNo"
            label="Customer GST No"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderReferenceNo"
            label="Order Reference No​"
            disabled
          />
          <Field
            component={Control.RenderDateAllField}
            name="OrderReferenceDate"
            label="Order Reference Date"
            initialValue={props.initialValues.OrderReferenceDate}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderType"
            label="Order Type"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CompanyName"
            label="Company Name"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CompanyGstNo"
            label="Company GST No"
            disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="AdvanceBG"
            label="Bank Guarantee Type"
          >
            <option value=""></option>
            <option value="Advance">Advance</option>
            <option value="Performance">Performance</option>
            <option value="NotApplicable">Not Applicable</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="Insurance"
            label="Insurance"
            onChange={(value) => setInsurance(value)}
          >
            <option value=""></option>
            <option value="WorkmenCompensation">Workmen Compensation</option>
            <option value="Medical">Medical</option>
            <option value="Others">Others</option>
          </Field>
          {insurance === "Others" && (
            <Field
              component={Control.RenderTextField}
              name="InsuranceOthersText"
              label="Insurance Others"
            />
          )}
          <Field
            component={Control.RenderSelectField}
            name="Stage"
            label="Stage (Y/N)"
            onChange={() => setStage(!stage)}
          >
            <option value={""}></option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Field>
        </div>
        <div></div>
        <div>
          {!stage ? (
            <div className="field-container">
              <Field
                component={Control.RenderTextField}
                name="StagePercentage"
                label="Percent %"
                disabled
              />
            </div>
          ) : (
            <>
              <div className="excel">
                <SalesOrderStageTable
                  stageTermsTable={stageTermsTable}
                  stage={props.stage}
                />
              </div>
              <div className="middle">
                <p className=" Mui-error excelError">
                  {stageData.stageTermsError &&
                    `${stageData.stageTermsErrorKind}`}
                </p>
              </div>
            </>
          )}
          <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Payments Terms (Mandatory)
            </h4>
            <PaymentTermTable
              paymentTermsTable={paymentTermsTable}
              payments={props.payments}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {paymentTermsData.paymentTermsError &&
                `${paymentTermsData.paymentTermsErrorKind}`}
            </p>
          </div>
          <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Retention Terms (Mandatory)
            </h4>
            <RetentionTermsTable
              retentions={props.retentions}
              retentionTermsTable={retentionTermsTable}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {retentionData.retentionTermsError &&
                `${retentionData.retentionTermsErrorKind}`}
            </p>
          </div>
        </div>
        <div className="field-container">
          <div>
          <InputLabel>Defect Liability Period</InputLabel>
          <Field
            component={SelectDate}
            name="DefectLiabilityPeriod"
            setDates={handleDefectLiability}
          />
          </div>
        </div>
        <div className="field-container">
          <Field
            component={Control.RenderSelectField}
            name="OrderCategory"
            label="Order Category"
          >
            <option value={""}></option>
            <option value={"Labour"}>Labour</option>
            <option value={"Material"}>Material</option>
            <option value={"Combined"}>Combined</option>
          </Field>

          <Field
            component={Control.RenderSelectField}
            name="LDClause"
            label="LD Clause (Y/N)"
            onChange={() => setLdclause(!ldclause)}
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Field>
          {!ldclause ? (
            <>
              <div className="excel">
                <LdclauseTable
                  ldclauseTermsTable={LdclauseTermsTable}
                  ldclause={props.ldclause}
                />
              </div>
              <div className="middle">
                <p className=" Mui-error excelError">
                  {ldclauseData.ldclauseTermsError &&
                    `${ldclauseData.ldclauseTermsErrorKind}`}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          <Field
            component={Control.RenderTextField}
            name="PriceVariationClause"
            label="Price Variation Clause"
          />

          <Field
            component={Control.RenderTextField}
            name="OrderValue"
            label="Project Order Value"
            type="number"
            InputProps={{ inputProps: { min: 0, step: 0.01 } }}
            disabled
          />
        </div>
        <div className="excel">
          <h4 style={{ display: "inline-block", marginLeft: "1rem" }}>
            Mandatory
          </h4>
          <SalesOrderTable
            tableData={tableData}
            projectValue={props.projectValue}
            order={props.order}
            materials={props.materials}
            uom={props.uom}
            edit={props.edit}
            projectOrderValue={props.projectValue}
          />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {tableDataState.tableError && `${tableDataState.tableErrorKind}`}
          </p>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Sales Order"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "OrderReferenceNo",
    "Date",
    "OrderType",
    "AdvanceBG",
    "Insurance",
    "CustomerGSTNo",
    "CompanyName",
    "FNCGSTNo",
    "Stage",
    "OrderCategory",
    "DefectLiabilityPeriod",
    "ProjectOrderValue",
    "LDClause",
    "PriceVariationClause",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "SalesOrderForm",
  validate,
  enableReinitialize: true,
})(SalesOrder);
