import React from "react";
import { useSnackbar } from "notistack";
import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

export const RenderSwitchField = ({
  label,
  input,
  meta: { touched, invalid, error },
  value,
  ...custom
}) => {
  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }

  return (
    <FormControl
      error={touched && error}
      style={{ minWidth: "10%", margin: "1.2rem" }}
    >
      <FormControlLabel
        control={
          <Switch
            id="Switch"
            color="primary"
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
            value={value}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
