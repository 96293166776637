import React,{useState} from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Bank = (props) => {
  const [show, setShow] = useState(false);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="BankCode"
            label="Bank Code"
          />
          <Field
            component={Control.RenderTextField}
            name="BankName"
            label="Bank Name"
          />
          <Field
            component={Control.RenderTextField}
            name="BankAddress"
            label="Bank Address"
          />
          <Field component={Control.RenderTextField} name="City" label="City" />
          <Field
            component={Control.AutoCompleteInput}
            name="State"
            label="State​"
            list={props.stateList}
            className="text-field"
          />
          <Field component={Control.RenderTextField} name="PIN" label="PIN" />
          <Field
            component={Control.RenderTextField}
            name="AccountNo"
            label="Account No"
          />
          <Field component={Control.RenderTextField} name="IFSC" label="IFSC" />
          <Field
            component={Control.RenderTextField}
            name="MICR"
            label="MICR"
            className="text-fields"
          />
          <Field
            component={Control.RenderTextField}
            name="AccountType"
            label="Account Type"
          />
          <Field
            component={Control.RenderTextField}
            name="AuthorisedSignatory1"
            label="Authorised Signatory 1"
          />
          <Field
            component={Control.RenderTextField}
            name="ChequeStartingNo"
            label="Cheque Starting No"
          />
          <Field
            component={Control.RenderTextField}
            name="AuthorisedSignatory2"
            label="Authorised Signatory 2"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Bank"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "BankCode",
    "BankName",
    "BankAddress",
    "City",
    "State",
    "PIN",
    "AccountNo",
    "IFSC",
    "MICR",
    "AccountType",
    "AuthorisedSignatory1",
    "ChequeStartingNo",
    "AuthorisedSignatory2",
    "Tag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "Bank",
  validate,
  enableReinitialize: true,
})(Bank);
