import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRouter = ({ component: Component, ...rest }) => {
  const user = useSelector((store) => store.userRoot);
  return (
    <Route
      {...rest}
      component={(props) =>
        user.workingOn != null ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
export default PrivateRouter;
