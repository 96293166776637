import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchCompanyDetailsById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import GSTTableView from "../../../Tables/CommonMaster/GSTTableView";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CompanyDetailsView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchCompanyDetailsById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleCompanyDetails) {
      setData(store.commonMasterSystemRoot.SingleCompanyDetails);
    }
  }, [store.commonMasterSystemRoot.SingleCompanyDetails]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("companyDetails", match.params.id, history, data)
    );
  };
  console.log("data",data)
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };
  return (
    <div>
      <Breadcrumbs
        name="Company Details View"
        linkName1="Company Details Dashboard"
        to1="/commonMaster/companyDetails"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Company Details</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.CompanyName && (
            <Paper className={classes.paper}>
              <h6>Company Name:</h6>
              <p>{data.CompanyName}</p>
            </Paper>)}

            {data.RegisteredOfficeAddress && (
            <Paper className={classes.paper}>
              <h6>Registered Office Address:</h6>
              <p>{data.RegisteredOfficeAddress}</p>
            </Paper>)}

            {data.CertificateOfIncorporationNo && (
            <Paper className={classes.paper}>
              <h6>Certificate Of Incorporation No:</h6>
              <p>{data.CertificateOfIncorporationNo}</p>
            </Paper>)}

            {data.COIDate && (
            <Paper className={classes.paper}>
              <h6>COI Date:</h6>
              <p>{data.COIDate}</p>
            </Paper>)}

             {data.ServiceTaxRegistrationNo && (
             <Paper className={classes.paper}>
              <h6>Service Tax Registration No:</h6>
              <p>{data.ServiceTaxRegistrationNo}</p>
            </Paper>)}

            {data.VATRegistrationNo && (
            <Paper className={classes.paper}>
              <h6>VAT Registration No:</h6>
              <p>{data.VATRegistrationNo}</p>
            </Paper>)}

            {data.PFRegistrationNo && (
            <Paper className={classes.paper}>
              <h6>PF Registration No:</h6>
              <p>{data.PFRegistrationNo}</p>
            </Paper>)}

            {data.PFState && (
            <Paper className={classes.paper}>
             <h6>State:</h6>
             <p>{data.PFState}</p>
            </Paper>)}

            {data.PFSubRegistrationNo && (
            <Paper className={classes.paper}>
              <h6>PF Sub Registration No:</h6>
              <p>{data.PFSubRegistrationNo}</p>
            </Paper>)}

            {data.PFSubState && (
            <Paper className={classes.paper}>
              <h6>State</h6>
              <p>{data.PFSubState}</p>
            </Paper>)}

            {data.ESIRegistrationNo && (
            <Paper className={classes.paper}>
              <h6>ESI Registration No:</h6>
              <p>{data.ESIRegistrationNo}</p>
            </Paper>)}

            {data.ESIState && (
             <Paper className={classes.paper}>
              <h6>State:</h6>
              <p>{data.ESIState}</p>
            </Paper>)}

            {data.ESISubRegistrationNo && (
            <Paper className={classes.paper}>
              <h6>ESI Sub Registration No:</h6>
              <p>{data.ESISubRegistrationNo}</p>
            </Paper>)}

            {data.ESISubState && (
            <Paper className={classes.paper}>
              <h6>State:</h6>
              <p>{data.ESISubState}</p>
            </Paper>)}

            {data.PermanentAccountNumber && (
            <Paper className={classes.paper}>
              <h6>Permanent Account Number:</h6>
              <p>{data.PermanentAccountNumber}</p>
            </Paper>)}

            {data.MSME && (
            <Paper className={classes.paper}>
              <h6>MSME:</h6>
              <p>{data.MSME}</p>
            </Paper>)}

            {data.MSMEUdyam && (  
            <Paper className={classes.paper}>
              <h6>MSME Udyam:</h6>
              <p>{data.MSMEUdyam}</p>
            </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};
              
          </div>
          <div style={{ margin: "1rem" }}>
            <GSTTableView gsts={data.Gsts} />
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </div>
  );
};
