import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import axios from "../../../components/apis/axios";
import SalesInvoiceReversalForm from "../../../forms/SalesAccountingSystem/SalesInvoiceReversalForm";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let allInvoices = [];

export const SalesInvoiceReversalEdit = (props) => {
  const [salesInvoiceReversal, setSalesInvoiceReversal] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [list, setList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [certificationValue, setCertificationValue] = useState(0);
  const [previousFiles, setPreviousFiles] = useState();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesInvoiceReversal/${props.match.params.id}`
        );
        let data = response.data;
        console.log("salesInvoiceReversal", data);
        setSalesInvoiceReversal(data);
        setPreviousFiles([...data.Files]);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id]);

  const selectInvoice = (invoice) => {
    console.log(allInvoices);
    let detail = allInvoices.find(
      (item) => item.SalesInvoiceCertificationNo === invoice
    );

    if (detail) {
      setCertificationValue(+detail.CertificationValue);
      detail = {
        ...salesInvoiceReversal,
        InvoiceDate: detail.created_at,
        GrossInvoiceValue: +detail.InvoiceSummary.TotalGrossAmtThisBill,
        RecoveryValue: +detail.InvoiceSummary.TotalRecoveryAmtThisBill,
        NetInvoiceValue: +detail.InvoiceSummary.ClaimAmtThisBill,
      };
      setSalesInvoiceReversal(detail);
    }
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      try {
        const { data } = await axios.post(
          `salesAccounting/salesInvoiceCertification/getInvoices`,
          {
            ProjectCodeName: detail.ProjectShortDescription,
          }
        );
        allInvoices = data;
        const mapped = data.map((item) => item.SalesInvoiceCertificationNo);
        setInvoiceList(mapped);
      } catch (err) {
        console.log(err);
      }
      setSalesInvoiceReversal(detail);
    }
  };

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);
  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      allProject = store.GeneralRoot.AllProjectName;
      let approvedList = allProject.filter(
        (item) => item.Status === "accepted"
      );
      console.log(approvedList);
      let PNList = approvedList.map((item) => item.ProjectShortDescription);
      setList(PNList);
    }
  }, [store.GeneralRoot.AllProjectName]);

  const onSubmit = async (formValues) => {
    const response = await axios.put(
      `/salesAccounting/salesInvoiceReversal/${props.match.params.id}`,
      formValues
    );
    console.log(response);
    history.push("/salesAccounting/salesInvoiceReversal/");
  };

  return (
    <div>
      <BreadCrumbs
        name="Sales Invoice Reversal Edit"
        linkName1="Sales Invoice Reversal Dashboard"
        to1="/salesAccounting/salesInvoiceReversal"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Invoice Reversal Edit
      </h3>
      <div className="line"></div>
      <SalesInvoiceReversalForm
        list={list}
        invoiceList={invoiceList}
        selectProjectName={selectProjectName}
        selectInvoice={selectInvoice}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        initialValues={pick(
          salesInvoiceReversal,
          "OriginalFormNo",
          "ProjectCodeName",
          "CustomerName",
          "InvoiceNo",
          "CommentForReversal",
          "ReverseValue",
          "InvoiceDate",
          "GrossInvoiceValue",
          "RecoveryValue",
          "NetInvoiceValue"
        )}
        edit={true}
        certificationValue={certificationValue}
        previousFiles={previousFiles}
      />
    </div>
  );
};
