import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SystemParameter from "../../../forms/AccountingSystem/SystemParameter";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SystemParameterEdit = (props) => {
  const history = useHistory();
  const [banklist, setBanklist] = useState();
  const [systemParameter, setSystemParameter] = useState({});
  const { match } = props;

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `/accountingSystem/systemParameter/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/systemParameter/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/systemParameter/${match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/systemParameter/");

        setSystemParameter(data);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [match.params, history]);

  useEffect(() => {
    async function getData() {
      try {
        const { data: bank } = await axios.get("/commonMaster/bank");
        setBanklist(bank);
      } catch (err) {
        console.error(err);
      }
    }
    getData();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="System Parameter Edit"
        linkName1="System Parameter Dashboard"
        to1="/accountingSystem/systemParameter"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        System Parameter Request
      </h3>
      <SystemParameter
        BList={banklist}
        BankEdit={systemParameter.BankDetails}
        onSubmit={onSubmit}
        initialValues={pick(
          systemParameter,
          "CostCentre",
          "Currency",
          "JournalVoucher",
          "PaymentRequest",
          "PaymentVoucher",
          "PurchaseJournalVoucher",
          "ReceiptVoucher",
          "ScrutinyVoucher",
          "ServiceJournalVoucher",
          "ContraEntry",
          "FinancialPeriod"
        )}
        cashDetails={systemParameter.CashDetails}
        edit={true}
      />
    </div>
  );
};
