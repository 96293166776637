import PeopleIcon from "@material-ui/icons/People";
import RoleList from "../../helpers/RoleList";

export const projectHrManagementNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.BU_HEAD.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.CASHIER.code,
    RoleList.DIRECTOR.code,
    RoleList.SITE_STORE_KEEPER.code,
  ],
  label: "Project HR Management",
  name: "ProjectHrManagement",
  attrModule: "projectHrManagement",
  icon: <PeopleIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/project-hr-management/employee-join",
      label: "Employee Join",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/project-hr-management/transfer-order",
      label: "Transfer Order",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/project-hr-management/employee-join-report",
      label: "Employee Join Report",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/project-hr-management/employee-release",
      label: "Release Order",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/project-hr-management/daily-attendance",
      label: "Daily Attendance",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    // {
    //   to: "/project-hr-management/overtime-entry",
    //   label: "Over Time Entry",
    //   access: [
    //   RoleList.ADMIN.code,
    //   RoleList.BU_HEAD.code,
    //   RoleList.SITE_SUPERVISOR.code,
    //   RoleList.SITE_INCHARGE.code,
    //   RoleList.BU_COMMERCIAL.code,
    //   RoleList.COMMERCIAL_ASSISTANT.code,
    //   RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    //   RoleList.CASHIER.code,
    //   RoleList.DIRECTOR.code,
    //   RoleList.SITE_STORE_KEEPER.code,
    // ],
    // },
  ],
};
