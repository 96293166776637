  import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DailyProgressReport from "../../../forms/ExecutionSystem/DailyProgressReport";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];

export const DailyProgressReportCreate = () => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [activityList, setActivityList] = useState(null);
  const [uom, setUom] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [scItemList, setScItemList] = useState(null);
  const [machineList, setMachineList] = useState(null);
  const [materialList, setMaterialList] = useState(null);

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "/executionSystem/dailyProgressReport",
      formValues
    );
    console.log(response);
    history.push("/executionSystem/dailyProgressReport");
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProjects.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        detail = {
          ...formDetail,
          ProjectCodeName: detail.ProjectShortDescription,
          CustomerName: detail.CustomerName,
        };
        const { data } = await axios.post(
          `commonMaster/machine/findByProjectName/`,
          {
            currentlyAt: projectName,
          }
        );
        let machines = data.map((item) => item.machineID);
        setMachineList(machines);
        setFormDetail(detail);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        console.log("projectCircular", data);
        let filteredData = data.filter((item) => item.Status === "accepted");
        let mapedData = filteredData.map(
          (item) => item.ProjectShortDescription
        );
        setProjectList(mapedData);
        allProjects = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    const fetchActivity = async () => {
      try {
        const { data: activities } = await axios.get("/commonMaster/activity/");
        setActivityList(activities);
        const { data: uom } = await axios.get(
          "/commonMaster/unitOfMeasurement/"
        );
        setUom(uom);
        const { data: vendor } = await axios.get("/commonMaster/vendor/");
        console.log("vendor- >", vendor);
        setVendorList(vendor);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchSCItem = async () => {
      try {
        const { data } = await axios.get("/commonMaster/SCItemCodeRequest");
        setScItemList(data);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchMaterial = async () => {
      const { data } = await axios.get(`/commonMaster/material`);
      setMaterialList(data);
    };

    fetchSCItem();
    fetchActivity();
    fetchMaterial();
    fetch();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Daily Progress Report Create"
        linkName1="Daily Progress Report Dashboard"
        to1="/executionSystem/dailyProgressReport"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Daily Progress Report
      </h3>
      <DailyProgressReport
        onSubmit={onSubmit}
        activityList={activityList}
        uom={uom}
        vendorList={vendorList}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        machineList={machineList}
        scItemList={scItemList}
        materialList={materialList}
        initialValues={pick(formDetail, "CustomerName", "ProjectCodeName")}
      />
    </div>
  );
};
