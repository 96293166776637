import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { useSnackbar } from "notistack";
import "../../components/helpers/form.style.css";

const SalesInvoiceReversal = (props) => {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const { enqueueSnackbar } = useSnackbar();

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }

    props.onSubmit(formValues);
  };

  const validateReverseValue = (event) => {
    const value = event.target.value;
    console.log(props.certificationValue);
    if (+props.certificationValue > 0 && +value > +props.certificationValue) {
      enqueueSnackbar(
        `Reversal Value Amount entered is more than Certificated Amount. Enter Amount less than ${props.certificationValue}`,
        {
          variant: "info",
        }
      );
      props.dispatch(change("SalesInvoiceReversal", "ReverseValue", 0));
    }
    return;
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="Project Code"
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          />
          <Field
            component={Control.RenderTextField}
            name="OriginalFormNo"
            label="Original Form No"
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="InvoiceNo"
            label="Invoice No"
            component={Control.AutoCompleteInput}
            list={props.invoiceList}
            onChange={(value) =>
              props.selectInvoice
                ? props.selectInvoice(value)
                : () => {
                    return;
                  }
            }
            className="text-field"
          />
          <Field
            name="InvoiceDate"
            label="Invoice Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues.InvoiceDate}
            disabled
          />
          <Field
            name="GrossInvoiceValue"
            label="Gross Invoice Value"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="RecoveryValue"
            label="Recovery Value"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="NetInvoiceValue"
            label="Net Invoice Value"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="ReverseValue"
            label="Reverse Value"
            component={Control.RenderTextField}
            type={"number"}
            onChange={validateReverseValue}
          />
          <Field
            name="CommentForReversal"
            label="Comment For Reversal"
            component={Control.RenderTextField}
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Sales Invoice Reversal"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCenter",
    "FinancialPeriod",
    "Currency",
    "Bank",
    "FromDate",
    "ToDate",
    "ReverseValue",
    "CommentForReversal",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "SalesInvoiceReversal",
  validate,
  enableReinitialize: true,
})(SalesInvoiceReversal);
