import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachinePurchaseOrderAmendments from "../../../forms/MachineManagementSystem/MachinePurchaseOrderAmendments";
import { machinePurchaseOrderAmendments } from "../../../redux/actions/MachineManagementaction";

export const MachinePurchaseOrderAmendmentsCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machinePurchaseOrderAmendments(formValues, history));
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3 style={{ textAlign: "center" }}>Machine Purchase Order Amendments</h3>
      <MachinePurchaseOrderAmendments onSubmit={onSubmit} />
    </div>
  );
};
