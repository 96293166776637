import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory } from "react-router-dom";
import useStyle from "./UseStyle";
import Paper from "@material-ui/core/Paper";
import DailyAttendenceReportTable from "../../../components/WorkforceManagementDisplay/DailyAttendenceReportTable";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const DailyAttendenceReport = () => {
  const [list, setList] = useState([]);
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((store) => store);
  const visibleIcon =
    store.userRoot.workingOn.Role === "siteAdminHead" ||
    store.userRoot.workingOn.Role === "siteProjectHead" ||
    store.userRoot.workingOn.Role === "projectCoOrdinator" ||
    store.userRoot.workingOn.Role === "financeFirst" ||
    store.userRoot.workingOn.Role === "financeSecond" ||
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === "companyHead" ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          "/workforceManagement/reports/dailyAttendance"
        );
        setList(data);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [history, dispatch]);

  return (
    <>
      <BreadCrumbs
        name="Daily Attendence Reports"
        linkName1="All Workforce Management Reports"
        to1="/workforceManagement/othersReports"
      />

      <Paper className={classes.Container}>
        <h2 className="text-center">Daily Attendence Report</h2>
        <DailyAttendenceReportTable list={list} visibleIcon={visibleIcon} />
      </Paper>
    </>
  );
};
