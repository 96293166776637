const initialState = {
  SingleWorkmenJoining: {},
  AllWorkmenJoining: [],
  SingleDaliyAttendance: {},
  AllDaliyAttendance: [],
  FilteredworkmenJoining:[],
};

const ProjectWorkforceManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SINGLE_WORKMEN_JOINING": {
      return {
        ...state,
        SingleWorkmenJoining: action.payload,
      };
    }
    case "SET_All_WORKMEN_JOINING": {
      return {
        ...state,
        AllWorkmenJoining: action.payload,
      };
    }
    case "SET_FILTERED_WORKMEN_JOINING": {
      return {
        ...state,
        FilteredworkmenJoining: action.payload,
      };
    }

    case "SET_SINGLE_DAILY_ATTENDANCE": {
      return {
        ...state,
        SingleDaliyAttendance: action.payload,
      };
    }
    case "SET_All_DAILY_ATTENDANCE": {
      return {
        ...state,
        AllDaliyAttendance: action.payload,
      };
    }

    default:
      return state;
  }
};
export default ProjectWorkforceManagementReducer;