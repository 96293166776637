import BuildIcon from "@material-ui/icons/Build";
import RoleList from "../../helpers/RoleList";

export const WorkforceManagementNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_STORE_KEEPER.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.BU_HEAD.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.DIRECTOR.code,
  ],
  label: "Workforce Management",
  name: "WorkforceManagement",
  attrModule: "projectWorkforceManagement",
  icon: <BuildIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/workforceManagement/workmenJoining",
      label: "Workmen Joining",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/workforceManagement/dailyAttendence",
      label: "Daily Attendance",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    // {
    //   to: "/workforceManagement/workmenOrder",
    //   label: "Work Order",
    //   access: [
    //     RoleList.ADMIN.code,
    //     RoleList.SITE_SUPERVISOR.code,
    //     RoleList.SITE_INCHARGE.code,
    //     RoleList.SITE_STORE_KEEPER.code,
    //     RoleList.BU_COMMERCIAL.code,
    //     RoleList.BU_HEAD.code,
    //     RoleList.COMMERCIAL_ASSISTANT.code,
    //     RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    //     RoleList.DIRECTOR.code,
    //   ],
    // },
    // {
    //   to: "/workforceManagement/workmenOrderAmendment",
    //   label: "Work Order Amendment",
    //   access: [
    //     RoleList.ADMIN.code,
    //     RoleList.SITE_SUPERVISOR.code,
    //     RoleList.SITE_INCHARGE.code,
    //     RoleList.SITE_STORE_KEEPER.code,
    //     RoleList.BU_COMMERCIAL.code,
    //     RoleList.BU_HEAD.code,
    //     RoleList.COMMERCIAL_ASSISTANT.code,
    //     RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    //     RoleList.DIRECTOR.code,
    //   ],
    // },
    // {
    //   to: "/workforceManagement/subContractor-bill",
    //   label: "Sub Contractor - Bill",
    //   access: [
    //     RoleList.ADMIN.code,
    //     RoleList.SITE_SUPERVISOR.code,
    //     RoleList.SITE_INCHARGE.code,
    //     RoleList.SITE_STORE_KEEPER.code,
    //     RoleList.BU_COMMERCIAL.code,
    //     RoleList.BU_HEAD.code,
    //     RoleList.COMMERCIAL_ASSISTANT.code,
    //     RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    //     RoleList.DIRECTOR.code,
    //   ],
    // },
    // {
    //   to: "/workforceManagement/attendenceRegularisation",
    //   label: "Attendence Regularisation",
    //   access: [
    //     RoleList.ADMIN.code,
    //     RoleList.SITE_SUPERVISOR.code,
    //     RoleList.SITE_INCHARGE.code,
    //     RoleList.SITE_STORE_KEEPER.code,
    //     RoleList.BU_COMMERCIAL.code,
    //     RoleList.BU_HEAD.code,
    //     RoleList.COMMERCIAL_ASSISTANT.code,
    //     RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    //     RoleList.DIRECTOR.code,
    //   ],
    // },
    {
      to: "/workforceManagement/ServiceItem",
      label: "Service Item - Activity / Machine Link",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/workforceManagement/WorkmenExit",
      label: "Workmen Exit",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/workforceManagement/othersReports",
      label: "Reports",
      access: [RoleList.ADMIN.code],
    },
  ],
};
