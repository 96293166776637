import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
export const SalesOrderAmendmentTable = (props) => {
  const [data, setData] = useState([]);
  const { materials } = props;

  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2" className="head-cell">
              Sl. No
            </th>
            <th rowSpan="2" className="head-cell">
              BOQ No
            </th>
            <th rowSpan="2" className="head-cell">
              Heading BOQ No
            </th>
            <th rowSpan="2" className="head-cell">
              Item Description
            </th>
            <th rowSpan="2" className="head-cell">
              UOM
            </th>
            <th rowSpan="2" className="head-cell">
              HSN / SAC Code
            </th>
            <th rowSpan="2" className="head-cell">
              Quantity
            </th>
            <th rowSpan="2" className="head-cell">
              Rate
            </th>
            <th rowSpan="2" className="head-cell">
              Discount
            </th>
            <th rowSpan="2" className="head-cell">
              Net Rate
            </th>
            <th rowSpan="2" className="head-cell">
              Net Amount
            </th>
            <th colSpan="2">C-GST</th>
            <th colSpan="2">S-GST</th>
            <th colSpan="2">I-GST</th>
            <th rowSpan="2" className="head-cell">
              Total GST
            </th>
            <th rowSpan="2" className="head-cell">
              Item Total
            </th>
          </tr>
          <tr>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data3">{row.BOQNo}</td>
              <td className="table-data3">{row.HeadingBOQNo}</td>
              <td className="table-data3">{row.ItemDescription}</td>
              <td className="table-data3">{row.UOM}</td>
              <td className="table-data3">{row.HSNSACCode}</td>
              <td className="table-data3">{row.Quantity}</td>
              <td className="table-data3">{row.Rate}</td>
              <td className="table-data3">{row.Discount}</td>
              <td className="table-data3">{row.NetRate}</td>
              <td className="table-data3">{row.NetAmount}</td>
              <td className="table-data3">{row.CGSTPercentage}</td>
              <td className="table-data3">{row.CGSTValue}</td>
              <td className="table-data3">{row.SGSTPercentage}</td>
              <td className="table-data3">{row.SGSTValue}</td>
              <td className="table-data3">{row.IGSTPercentage}</td>
              <td className="table-data3">{row.IGSTValue}</td>
              <td className="table-data3">{row.TotalGST}</td>
              <td className="table-data4">{row.ItemTotal}</td>
            </tr>
          ))}
        </tbody>
        {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
      </table>
    </div>
  );
};
