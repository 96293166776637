import React, { useEffect, useState } from "react";
import Table from "../../../../components/FormDashBoard/FormDashboardUpdate";
import { useSelector } from "react-redux";
import axios from "../../../../components/apis/axios";
import RoleList from "../../../../components/helpers/RoleList";

export const DisbursementChequeRealisationDashboard = () => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.CASHIER.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.CASHIER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`accountingSystem/disbursementContra`);
      setLists(data);
    };
    fetch();
  }, []);

  return (
    <div>
      <Table
        lists={lists}
        DeleteLink="/accountingSystem/disbursement/chequesRealisation"
        tablecol1="ChequesRealisationNo"
        heading="Disbursement Cheques Realisation DashBoard"
        label1="ChequesRealisation No"
        link="/accountingSystem/disbursement/chequesRealisation/create"
        editLink="/accountingSystem/disbursement/chequesRealisation/edit"
        viewLink="/accountingSystem/disbursement/chequesRealisation/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </div>
  );
};
