import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";

const DisbursementTable = (props) => {
  const { disbursement } = props;
  const [inputFields, setInputFields] = useState([
    {
      SlNo: "",
      WorkOrderNo: "",
      WorkOrderDate: "",
      SCBNo: "",
      SCBDate: "",
      SubContractorInvoiceNo: "",
      SubContractorInvoiceDate: "",
      SubContractorJournalNo: "",
      SubContractorJournalDate: "",
      NetDue: "",
      DueDate: "",
      PaidSoFar: "",
      PayNow: "",
      id: "",
    },
  ]);

  useEffect(() => {
    let values = [];
    if (disbursement) {
      for (let i = 0; i < disbursement.length; i++) {
        values = [
          ...values,
          {
            SlNo: disbursement[i].SerialNo,
            WorkOrderNo: disbursement[i].WorkOrderNo,
            WorkOrderDate: disbursement[i].WorkOrderDate,
            SCBNo: disbursement[i].SCBNo,
            SCBDate: disbursement[i].SCBDate,
            SubContractorInvoiceNo: disbursement[i].SubContractorInvoiceNo,
            SubContractorInvoiceDate: disbursement[i].SubContractorInvoiceDate,
            SubContractorJournalNo: disbursement[i].SubContractorJournalNo,
            SubContractorJournalDate: disbursement[i].SubContractorJournalDate,
            NetDue: disbursement[i].NetDue,
            DueDate: disbursement[i].DueDate,
            PaidSoFar: disbursement[i].PaidSoFar,
            PayNow: disbursement[i].PayNow,
            id: disbursement[i].id,
          },
        ];
      }
      setInputFields(values);
    }
  }, [disbursement]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    props.tableData(values);
    onChange(values);
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SlNo: "",
        WorkOrderNo: "",
        WorkOrderDate: "",
        SCBNo: "",
        SCBDate: "",
        SubContractorInvoiceNo: "",
        SubContractorInvoiceDate: "",
        SubContractorJournalNo: "",
        SubContractorJournalDate: "",
        NetDue: "",
        DueDate: "",
        PaidSoFar: "",
        PayNow: "",
        id: "",
      },
    ]);
  };

  const { tableData } = props;
  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SlNo</th>
            <th className="head-cell">Work Order No</th>
            <th className="head-cell">Work Order Date</th>
            <th className="head-cell">SCB No</th>
            <th className="head-cell">SCB Date</th>
            <th className="head-cell">Sub Contractor Invoice No</th>
            <th className="head-cell">Sub Contractor Invoice Date</th>
            <th className="head-cell">Sub Contractor Journal Invoice No</th>
            <th className="head-cell">Sub Contractor Journal Date</th>
            <th className="head-cell">Net Date</th>
            <th className="head-cell">Due Date</th>
            <th className="head-cell">Paid So Far</th>
            <th className="head-cell">Pay Now</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">
                <input
                  type="text"
                  name="SlNo"
                  defaultValue={index + 1}
                  disabled
                  onChange={(event) => handleChangeInput(index, event)}
                 
                />
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  name="WorkOrderNo"
                  value={inputField.WorkOrderNo}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="WorkOrderDate"
                  value={inputField.WorkOrderDate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  name="SCBNo"
                  value={inputField.SCBNo}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="SCBDate"
                  value={inputField.SCBDate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  name="SubContractorInvoiceNo"
                  value={inputField.SubContractorInvoiceNo}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="SubContractorInvoiceDate"
                  value={inputField.SubContractorInvoiceDate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="SubContractorJournalNo"
                  value={inputField.SubContractorJournalNo}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="SubContractorJournalDate"
                  value={inputField.SubContractorJournalDate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="NetDue"
                  value={inputField.NetDue}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="DueDate"
                  value={inputField.DueDate}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  name="PaidSoFar"
                  value={inputField.PaidSoFar}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  name="PayNow"
                  value={inputField.PayNow}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td>
                {index === 0 ? (
                  <IconButton>
                    <RemoveCircleIcon className="width5" color="disabled" />
                  </IconButton>
                ) : (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default DisbursementTable;
