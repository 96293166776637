import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachineGroup from "../../../forms/CommonMaster/MachineGroup";
import { machineGroup } from "../../../redux/actions/CommonMasterAction";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";

let AllMachineClass = [];
export const MachineGroupCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [machineClass, setMachineClass] = React.useState({});
  const [formDetail, setFormDetail] = React.useState({});

  const onSubmit = (formValues) => {
    dispatch(machineGroup(formValues, history));
  };

  const SelectMachineClass = async (MachineClassCode) => {
    const item = AllMachineClass.find(
      (item) => item.MachineClassCode === MachineClassCode
    );
    setFormDetail({
      ...formDetail,
      MachineClassCode: item.MachineClassCode,
      MachineClassDes: item.MaterialClassDes,
    });
  };
  React.useEffect(() => {
    async function MachineClass() {
      try {
        const { data } = await axios.get("/commonMaster/machineClass");
        let mapedData = data.map((item) => item.MachineClassCode);

        setMachineClass(mapedData);
        AllMachineClass = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    MachineClass();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Machine Group Create"
        linkName1="Machine Group Dashboard"
        to1="/commonMaster/machineGroup"
      />
      <FormHeading label="Machine Group" />
      <MachineGroup
        onSubmit={onSubmit}
        machineClass={machineClass}
        SelectMachineClass={SelectMachineClass}
        initialValues={pick(formDetail, "MachineClassCode", "MachineClassDes")}
      />
    </div>
  );
};
