import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import WareHouse from "../../../forms/CommonMaster/WareHouse";
import { useHistory } from "react-router-dom";
import {
  editWareHouse,
  searchWareHouseById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const WareHouseEdit = (props) => {
  const [data, setData] = useState({});
  const [companyDetail, setCompanyDetail] = useState([]);
  const [costCentreList, setcostCentreList] = useState([]);
  const [gst, setGst] = useState(null);
  const [costCenterGST, setCostCenterGST] = useState([]);
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();

  const { match } = props;
  useEffect(() => {
    dispatch(searchWareHouseById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleWareHouse) {
      setData(store.SingleWareHouse);
      setGst(store.SingleWareHouse.Gsts);
    }
  }, [store.SingleWareHouse]);

  const onSubmit = (formValues) => {
    dispatch(editWareHouse(formValues, props.match.params.id, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        console.log(data);
        let mapedData = data.map((item) => item.CompanyName);
        setCompanyDetail(mapedData);
      } catch (err) {
        alert(err);
        console.log(err);
      }
     
    }
    async function fetch2 () {
      try {
        const { data } = await axios.get('/commonMaster/costCentre')
        console.log("company data::", data)
        let company = data.map(item => item.CostCenterName);
        console.log("++++++", company)
        setcostCentreList(company)
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }
    fetch();
    fetch2()
  }, []);
  const findCostCenter = async (value) => {
    try {
      const { data } = await axios.post(
        `commonMaster/costCentre/getByCompanyName/name/`,
        {
          CompanyName: value,
        }
      );
      console.log("payload", data);
      let mapedData = data.map((item) => item.CostCenterName);
      setcostCentreList(mapedData);
    } catch (err) {
      console.log(err);
    }
  };
  const findCostCenterGST = async (value) => {
    let id;
    try {
      const { data } = await axios.post(
        `commonMaster/costCentre/getByCostCenter/name/`,
        {
          CostCenterName: value,
        }
      );
      console.log("data", data);
      id = data.find((item) => item.CostCenterName === value);
      const payload = await axios.get(`commonMaster/costCentre/${id.id}`);
      // console.log("payload", payload);
      const gsts = payload.data.Gsts;
      setCostCenterGST(gsts);
      // console.log(gsts);
      // setCostCenterGST(gst);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("gst", gst);
  return (
    <div>
      <Breadcrumbs
        name="Ware house Edit"
        linkName1="Warehouse Dashboard"
        to1="/commonMaster/wareHouse"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        WareHouse
      </h3>
      <div className="line"></div>
      <WareHouse
        CClist={costCentreList}
        companyDetail={companyDetail}
        initialValues={pick(
          data,
          "ProjectName",
          "CostCenterName",
          "WareHouseName",
          "ProjectSiteAddress",
          "GSTRegNo",
          "Tags",
          "CompanyName"
        )}
        Gst={gst}
        findCostCenter={findCostCenter}
        findCostCenterGST={findCostCenterGST}
        costCenterGST={costCenterGST}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};