import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalSalesAccounting } from "../../../redux/actions/SalesAcounts";
import LinearProgress from "@material-ui/core/LinearProgress";

import { SalesOrderInvoiceTableView } from "../../../Tables/SalesAcountingSystem/view/salesInvoice/SalesOrderInvoiceTableView";
import { SalesInvoiceSummaryTableView } from "../../../Tables/SalesAcountingSystem/view/salesInvoice/SalesInvoiceSummaryTableView";
import { SalesInvoiceRecoveryTableView } from "../../../Tables/SalesAcountingSystem/view/salesInvoice/SalesInvoiceRecoveryTableView";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SalesInvoice = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();

  const { match } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesInvoice/${match.params.id}`
        );
        let data = response.data;
        console.log("salesInvoice ->", data);
        data.SalesOrderDate = new Date(
          new Date(data.SalesOrderDate) + " UTC"
        ).toDateString();

        data.created_at = new Date(data.created_at).toLocaleString();
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalSalesAccounting("salesInvoice", match.params.id, history, data)
    );
  };

  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Sales Invoice  View"
        linkName1="Sales Invoice  Dashboard"
        to1="/salesAccounting/salesInvoice"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Sales Invoice</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                {data.OriginalFormNo && 
                <Paper className={classes.paper}>
                <h6>Oridinal Form No:</h6>
                <p>{data.OriginalFormNo}</p>
              </Paper>}
                <Paper className={classes.paper}>
                  <h6>Sales Invoice No:</h6>
                  <p>{data.SalesInvoiceNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Project Code / Name:</h6>
                  <p>{data.ProjectCodeName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer Name:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer GST:</h6>
                  <p>{data.CustomerGSTNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>SalesOrderNo:</h6>
                  <p>{data.SalesOrderNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>SalesOrderDate:</h6>
                  <p>{data.SalesOrderDate}</p>
                </Paper>
                {data.LastInvoiceNo && (
                  <Paper className={classes.paper}>
                    <h6>LastInvoiceNo:</h6>
                    <p>{data.LastInvoiceNo}</p>
                  </Paper>
                )}
                {data.LastAmendmentNo && (
                  <Paper className={classes.paper}>
                    <h6>LastAmendmentNo:</h6>
                    <p>{data.LastAmendmentNo}</p>
                  </Paper>
                )}
                {data.LastAmendmentDate && (
                  <Paper className={classes.paper}>
                    <h6>LastAmendmentDate:</h6>
                    <p>{data.LastAmendmentDate}</p>
                  </Paper>
                )}
                <Paper className={classes.paper}>
                  <h6>OrderValue:</h6>
                  <p>{data.OrderValue}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>FNCGSTNo:</h6>
                  <p>{data.FNCGSTNo}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>NatureOfInvoice:</h6>
                  <p>{data.NatureOfInvoice}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>InvoiceCategory :</h6>
                  <p>{data.InvoiceCategory}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>TotalInvoiceAmt :</h6>
                  <p>{data.TotalInvoiceAmt}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>TotalRecoveryAmt:</h6>
                  <p>{data.TotalRecoveryAmt}</p>
                </Paper>
              </div>
              <br />
              <div style={{ paddingLeft: "10%" }}>
                <h3>InvoiceDetails</h3>
                <SalesOrderInvoiceTableView
                  InvoiceDetails={data.InvoiceDetails}
                />

                <h3>InvoiceRecovery</h3>
                <SalesInvoiceRecoveryTableView
                  InvoiceRecovery={data.InvoiceRecovery}
                />
                {console.log(data.InvoiceSummary)}
                <h3>InvoiceSummary</h3>
                <SalesInvoiceSummaryTableView
                  InvoiceSummary={data.InvoiceSummary}
                />
              </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>
            {(post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"Project Circular"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
