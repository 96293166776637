import React, { useState, useEffect } from "react";
// import LinearProgress from "@material-ui/core/LinearProgress";
const LogSheetEntryTableView = (props) => {
  const [data, setData] = useState([]);
  const { tableData } = props;

  useEffect(() => {
    let values = [];
    if (tableData) {
      values = [...tableData];
    }
    setData(values);
  }, [tableData]);

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo</th>
            <th className="head-cell">ActivityCodeName</th>
            <th className="head-cell">WorkingHour</th>
            <th className="head-cell">WorkDoneQty</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{row.SerialNo}</td>
              <td className="table-data2">{row.ActivityCodeName}</td>
              <td className="table-data2">{row.WorkingHour}</td>
              <td className="table-data4">{row.WorkDoneQty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LogSheetEntryTableView;
