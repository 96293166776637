import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";

const GoodsReceiptType = (props) => {
    const [show, setShow] = React.useState(false);

    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="ReceiptCode"
            label="ReceiCodept "
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="ReceiptDescription"
            label="Receipt Description"
            className="text-field"
            multiline
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="GoodsReceiptType"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = ["ReceiptCode", "ReceiptDescription"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "GoodsReceiptType",
  validate,
  enableReinitialize: true,
})(GoodsReceiptType);
