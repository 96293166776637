import React, { useEffect, useMemo, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, TextField } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import CustomDataGrid from "../../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";
import { Autocomplete } from "@material-ui/lab";
import { useRef } from "react";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
  columnHeader: {
    "& .MuiDataGrid-columnHeaderWrapper": {
      background: "#8383fa",
      color: "white",
      fontSize: "15px",
      fontWeight: "600",
      border: "none",
      borderRadius: "3px",
    },
  },
}));

const OvertimeEntry = (props) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyle();
  const [projects, setProjects] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [activity, setActivity] = useState([]);
  const [machines, setMachines] = useState([]);
  const fieldValues = useSelector(
    (state) => state.form?.OvertimeEntry?.values
  );
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();

  const onSubmit = (formValues) => {
    console.log({ formValues });
    props.onSubmit({
      project: formValues?.projectData?.id,
      tableData: [...gridRef.current?.getRowModels()?.values()],
    });
  };

  const toggleModal = () => setShow(!show);

  useEffect(() => {
    if (props?.initialValues?.tableData?.length > 0) {
      setRowData(props?.initialValues?.tableData);
    }
  }, [props?.initialValues?.tableData]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular", {
          params: {
            status: "Active",
          },
        });
        setProjects(
          data
            ?.filter((item) => item?.Status === "accepted")
            .map((project) => ({
              label: `${project.ProjectCode} - ${project.ProjectShortDescription}`,
              value: project.id,
              project,
            }))
        );
      } catch (error) {
        alert(error);
      }
    };
    const fetchShifts = async () => {
      try {
        let { data } = await axios.get("/commonMaster/shifts");
        setShifts(
          data.map((shift) => ({
            label: shift?.shift_code,
            value: shift?.shift_code,
            shiftData: shift,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };
    const fetchActivity = async () => {
      try {
        let { data } = await axios.get("/commonMaster/Activity");
        setActivity(
          data.map((activity) => ({
            label: `${activity?.ActivityCode} - ${activity?.ActivityDescription}`,
            value: activity?.id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchProject();
    fetchShifts();
    fetchActivity();
  }, []);

  useEffect(() => {
    if (props?.initialValues?.projectData) {
      const project = props?.initialValues?.projectData;
      fetchMachine(
        `${project?.ProjectCode} - ${project?.ProjectShortDescription}`
      );
    }
  }, [props?.initialValues?.projectData]);

  const fetchMachine = async (project) => {
    const { data: machines } = await axios.get(
      "/machineManagement/machineReceipt",
      {
        params: {
          project,
        },
      }
    );
    setMachines(machines.map((machine) => machine?.MachineCodeName));
  };

  const handleChange = async (value) => {
    dispatch(change("HrDailyAttendance", "projectData", value?.project));
    dispatch(
      change("HrDailyAttendance", "CostCentre", value?.project?.CostCentre)
    );
    dispatch(
      change("HrDailyAttendance", "CustomerName", value?.project?.CustomerName)
    );
    if (value) {
      try {
        const { data } = await axios.get("/hr-management/employee-join", {
          params: {
            project: value?.value,
          },
        });
        setRowData(
          data.map(({ employee }) => ({
            id: employee.id,
            employee: `${employee.EmployeeCode} - ${employee.FirstName} ${employee.LastName}`,
          }))
        );

        await fetchMachine(
          `${value?.project?.ProjectCode} - ${value?.project?.ProjectShortDescription}`
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const { RenderTextField, AutoCompleteInput } = Control;

  const inputsFields = [
    {
      component: AutoCompleteInput,
      name: "project",
      label: "Project Code / Name",
      list: projects,
      onChange: handleChange,
    },
    {
      component: RenderTextField,
      name: "CustomerName",
      label: "Customer Name",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "CostCentre",
      label: "Cost Centre",
      disabled: true,
    },
  ];

  const handleShiftCode = (value, params) => {
    const timeIn = moment(value?.shiftData?.shift_in_Time);
    const timeOut = moment(value?.shiftData?.shift_out_Time);

    const duration = moment.duration(timeOut.diff(timeIn));
    const totalHour = duration.asHours();
    const normalHour = duration.asHours() - 1;
    const overtimeHour = normalHour > 8 ? normalHour - 8 : 0;

    gridRef?.current.updateRows([
      {
        id: params.id,
        shiftCode: value?.label,
        timeIn: value ? timeIn.format("hh:mm") : null,
        timeOut: value ? timeOut.format("hh:mm") : null,
        totalHour,
        normalHour,
        overtimeHour,
      },
    ]);
  };

  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
    },
    {
      field: "shiftCode",
      headerName: "Shift Code",
      flex: 1,
      renderCell: (params) => {
        if (!gridRef.current) gridRef.current = params.api;
        return (
          <Autocomplete
            fullWidth
            options={shifts}
            value={params?.value}
            getOptionLabel={(option) => option?.label || option}
            getOptionSelected={(option, curr) =>
              option?.label ? option?.label === curr : option === curr
            }
            onChange={(_, value) => handleShiftCode(value, params)}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
    },
    {
      field: "timeIn",
      headerName: "Time In",
      flex: 1,
    },
    {
      field: "timeOut",
      headerName: "TimeOut",
      editable: true,
      flex: 1,
      valueFormatter: ({ api, value }) => {
        if (!gridRef.current) {
          gridRef.current = api;
        }
        return value;
      },
    },
    {
      field: "normalHour",
      headerName: "Normal Hours",
      flex: 1,
    },
    {
      field: "totalHour",
      headerName: "Total Hours",
      flex: 1,
    },
    {
      field: "overtimeHour",
      headerName: "Overtime Hours",
      flex: 1,
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 1,
      renderCell: (params) => {
        return (
          <Autocomplete
            fullWidth
            options={activity}
            value={params?.value}
            getOptionLabel={(option) => option?.label || option}
            getOptionSelected={(option, curr) =>
              option?.label ? option?.label === curr : option === curr
            }
            onChange={(_, value) => {
              gridRef.current?.updateRows([
                { id: params.id, activity: value?.label },
              ]);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
    },
    {
      field: "machine",
      headerName: "Machine",
      flex: 1,
      renderCell: (params) => {
        if (!gridRef.current) gridRef.current = params.api;
        return (
          <Autocomplete
            fullWidth
            options={machines}
            value={params?.value}
            onChange={(_, value) => {
              gridRef.current?.updateRows([{ id: params.id, machine: value }]);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
    },
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        <Grid container spacing={2}>
          {inputsFields
            .filter((hiddenField) => !hiddenField.isHidden)
            .map((field, index) => (
              <Grid item xs={6} key={index}>
                <Field {...field} />
              </Grid>
            ))}
        </Grid>
        <CustomDataGrid
          columns={columns}
          rows={rowData}
          hideActions
          showFilter={false}
          hideFooter
        />
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Employee Overtime entry"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};

  const requiredFields = [];

  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "OvertimeEntry",
  validate,
  enableReinitialize: true,
})(OvertimeEntry);
