import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import "../style/materialSystem.css";
import WarehouseIndentVoucherForm from "../../../forms/MaterialSystem/WarehouseIndentVoucherForm";
import {
  editWarehouseIndentVoucher,
  searchWarehouseIndentVoucherById,
} from "../../../redux/actions/materialSystemAction";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

import {
  allCustomer,
  allVendor,
} from "../../../redux/actions/CommonMasterAction";

let data = [];
let AllCustomer = [];
let AllVendor = [];

export const WarehouseIndentVoucherEdit = (props) => {
  const [Alldata, setAllData] = useState({});
  const store = useSelector((store) => store);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const [customerNamelist, setcustomerNamelist] = useState([]);
  const [vendorNamelist, setvendorNamelist] = useState([]);
  const [list, setList] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [machineList, setMachineList] = useState(null);
  const [activityList, setActivityList] = useState(null);
  const [materialList, setMaterialList] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [vendorGst, setVendorGst] = useState([]);

  useEffect(() => {
    dispatch(searchWarehouseIndentVoucherById(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (store.materialSystemRoot.SingleWarehouseIndentVoucher) {
      setAllData(store.materialSystemRoot.SingleWarehouseIndentVoucher);
      setMaterials(
        store.materialSystemRoot.SingleWarehouseIndentVoucher.materials
      );
      setPreviousFiles(
        store.materialSystemRoot.SingleWarehouseIndentVoucher.Files
      );
    }
  }, [store.materialSystemRoot.SingleWarehouseIndentVoucher]);

  const onSubmit = (formValues) => {
    delete formValues.TotalPRValue;
    delete formValues.WarehouseIntentVoucherNo;
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    console.log("values", formValues);
    dispatch(
      editWarehouseIndentVoucher(formValues, props.match.params.id, history)
    );
  };

  const SelectVendor = async (VendorName) => {
    const item = AllVendor.find((item) => item.VendorName === VendorName);
    console.log("AllVendor ", AllVendor);

    if (item) {
      let payload = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gst = payload.data.Gsts;
      const gstRegNos = gst.map((item) => item.GSTRegNo);
      setVendorGst(gstRegNos);
      setProjectDetail({
        ...projectDetail,
        VendorCodeName: payload.data.VendorName,
      });
    }
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = data.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      detail = { ...detail, Warehouse: detail.WareHouse };
      setProjectDetail(detail);
    }
    const { data: machineData } = await axios.post(
      `commonMaster/machine/findByProjectName/`,
      {
        currentlyAt: projectDetail.ProjectCodeName,
      }
    );
    let machines = machineData.map((item) => item.machineID);

    setMachineList(machines);
  };

  useEffect(() => {
    dispatch(allCustomer());
    dispatch(allVendor());
  }, [dispatch]);

  useEffect(() => {
    if (store.commonMasterSystemRoot.AllCustomer) {
      AllCustomer = store.commonMasterSystemRoot.AllCustomer;
      let CNlist = AllCustomer.map((item) => item.CustomerName);
      setcustomerNamelist(CNlist);
    }
    if (store.commonMasterSystemRoot.AllVendor) {
      AllVendor = store.commonMasterSystemRoot.AllVendor;
      let VDList = AllVendor.map((item) => item.VendorName);
      setvendorNamelist(VDList);
    }
  }, [
    store.commonMasterSystemRoot.AllCustomer,
    store.commonMasterSystemRoot.AllVendor,
  ]);

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);

  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      data = store.GeneralRoot.AllProjectName;
      let PNList = data.map((item) => item.ProjectShortDescription);
      setList(PNList);
    }
    const fetch = async () => {
      try {
        const { data: activities } = await axios.get("/commonMaster/activity/");
        setActivityList(activities);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [store.GeneralRoot.AllProjectName]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`/commonMaster/material`);
      setMaterialList(data);
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Indent Voucher  Create"
        linkName1=" Warehouse Indent Voucher  Dashboard"
        to1="/materialManagement/warehouseIntent"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Indent Voucher Edit
      </h3>
      <div className="line"></div>
      <WarehouseIndentVoucherForm
        list={list}
        CNList={customerNamelist}
        VDList={vendorNamelist}
        selectProjectName={selectProjectName}
        initialValues={pick(
          Alldata,
          projectDetail,
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "GoodsIssueType",
          "WorkSalesNo",
          "WorkOrderNo",
          "WorkSalesDate",
          "CustomerCodeName",
          "VendorCodeName",
          "Remarks",
          "WarehouseIntentVoucherNo"
        )}
        materialList={materialList}
        activityList={activityList}
        machineList={machineList}
        previousFiles={previousFiles}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        materials={materials}
        SelectVendor={SelectVendor}
        vendorGst={vendorGst}
      />
    </div>
  );
};
