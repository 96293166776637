import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchMachineRepairRequestById,
  approvalMachineManagement,
} from "../../../redux/actions/MachineManagementaction";
import useStyle from "./UseStyle";
import RoleList from "../../../components/helpers/RoleList";

export const MachineRepairRequestView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchMachineRepairRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.machineMangementSystemRoot.SingleMachineRepairRequest) {
      setData(store.machineMangementSystemRoot.SingleMachineRepairRequest);
    }
  }, [store.machineMangementSystemRoot.SingleMachineRepairRequest]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalMachineManagement(
        "machineRepairRequest",
        match.params.id,
        history,
        data
      )
    );
  };

  const acessButton = () => {
    if (post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code)
      return (
        <div className={classes.ButtonGrp}>
          <Button
            variant="contained"
            className={classes.approve}
            onClick={() => onApprove("Approved")}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            className={classes.reject}
            onClick={() => onApprove("Rejected")}
          >
            Reject
          </Button>
        </div>
      );
    return;
  };
  return (
    <>
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Machine Repair Request</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Code / Name:</h6>
              <p>{data.MachineCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Repair Type:</h6>
              <p>{data.RepairType}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Repair Date and Time:</h6>
              <p>{data.RepairDateTime}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Expected Comm. Date:</h6>
              <p>{data.ExpectedCommDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Nature Of Defects:</h6>
              <p>{data.NatureOfDefects}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Remarks:</h6>
              <p>{data.Remarks}</p>
            </Paper>
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
