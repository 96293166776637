import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../apis/axios";
import { useSelector } from "react-redux";

const SearchOptionMachine = ({ handleWorkmenChange }) => {
  const [workmen, setWorkmen] = useState([]);

  useEffect(() => {
    const fetchWorkmen = async () => {
      const { data } = await axios.get(
        "/workforceManagement/reports/workmenJoining"
      );
      setWorkmen(data);
      console.log("Workmen", data);
    };

    fetchWorkmen();
  }, []);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={workmen}
        getOptionLabel={(item) => item?.WorkmenName}
        onChange={(event, value) => handleWorkmenChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter Workmen"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchOptionMachine;