import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import "../style/materialSystem.css";
import {
  editWarehouseIssue,
  searchWarehouseIssueById,
} from "../../../redux/actions/materialSystemAction";
import WarehouseIssueVoucherForm from "../../../forms/MaterialSystem/WarehouseIssueVoucherForm";

import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllWarehouseIndentVoucher = [];

export const WarehouseIssueVoucherFormEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.materialSystemRoot);
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [wareHouseIndentList, setWareHouseIndentList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [Warehouse, setWarehouse] = useState("");
  const [stocksDetails, setStocksDetails] = useState([]);
  const [materials, setMaterials] = useState(null);
  const dispatch = useDispatch();
  const [previousFiles, setPreviousFiles] = useState([]);

  const { match } = props;

  useEffect(() => {
    dispatch(searchWarehouseIssueById(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (store.SingleWarehouseIssue) {
      setData(store.SingleWarehouseIssue);
      setPreviousFiles(store.SingleWarehouseIssue.Files);
      const getRate = async () => {
        const listOfMaterials = store.SingleWarehouseIssue.materials;
        try {
          const materialCodes = listOfMaterials.map(
            (item) => item.MaterialCode
          );
          const req = {
            WareHouse: store.SingleWarehouseIssue.Warehouse,
            Code: materialCodes,
          };
          const { data } = await axios.post(
            "/materialManagement/shared/getWarehouseStockRate",
            req
          );
          console.log(data, req);
          setStocksDetails(data);
        } catch (err) {
          console.log(err);
        }
      };
      getRate();
    }
  }, [store.SingleWarehouseIssue]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editWarehouseIssue(formValues, props.match.params.id, history));
  };

  const selectProject = (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      console.log(detail);
      let payload = {
        ...formDetail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Warehouse: detail.WareHouse,
        WareHouseGSTNo: detail.WareHouseGst,
      };
      setWarehouse(detail.WareHouse);
      setFormDetail(payload);
      fetchWarehouseIndent(projectName);
    }
  };

  const selectWarehouseIndent = async (WarehouseIntentVoucherNo) => {
    let detail = AllWarehouseIndentVoucher.find(
      (item) => item.WarehouseIntentVoucherNo === WarehouseIntentVoucherNo
    );

    if (detail) {
      let payload = await axios.get(
        `/materialManagement/warehouseIntent/${detail.id}`
      );

      setMaterials(payload.data.materials);

      try {
        const materials = payload.data.materials;
        const materialCodes = materials.map((item) => item.MaterialCode);
        const req = {
          WareHouse: Warehouse,
          Code: materialCodes,
        };
        const { data } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          req
        );
        console.log(data, req);
        setStocksDetails(data);
      } catch (err) {
        console.log(err);
      }

      payload = {
        ...formDetail,
        WarehouseIntentVoucherNo: WarehouseIntentVoucherNo,
        GoodsIssueType: payload.data.GoodsIssueType,
        WorkSalesNo: payload.data.WorkSalesNo,
        WorkOrderNo: payload.data.WorkOrderNo,
        Date: payload.data.Date,
        CustomerCodeName: payload.data.CustomerCodeName,
        DateofIssue: payload.data.IssueDate,
        VendorCodeName: payload.data.VendorCodeName,
        Remarks: payload.data.Remarks,
      };

      setFormDetail(payload);
    }
  };

  async function fetchProject() {
    try {
      let { data } = await axios.get("/salesAccounting/projectCircular");
      const filtered = data.filter((item) => item.Status === "accepted");
      allProject = filtered;
      let PNlist = filtered.map((item) => item.ProjectShortDescription);
      setProjectList(PNlist);
    } catch (error) {
      alert(error);
    }
  }

  async function fetchWarehouseIndent(ProjectCodeName) {
    try {
      let { data } = await axios.post(
        `/materialManagement/warehouseIntent/getByProjectCodeName`,
        {
          ProjectCodeName,
        }
      );
      AllWarehouseIndentVoucher = data;
      let WIlist = data.map((item) => item.WarehouseIntentVoucherNo);
      setWareHouseIndentList(WIlist);
    } catch (error) {
      alert("Failed to fetch", error);
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Issue Voucher  Edit"
        linkName1=" Warehouse Issue Voucher  Dashboard"
        to1="/materialManagement/warehouseIssue"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Issue Voucher Form Edit
      </h3>
      <div className="line"></div>
      <WarehouseIssueVoucherForm
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "GoodsIssueType",
          "GoodsStockType",
          "WorkSalesOrderNo",
          "VendorCodeName",
          "WorkSalesDate",
          "Remarks",
          "WarehouseIssueVoucherValue",
          "WarehouseIntentVoucherNo",
          "WareHouseGSTNo"
        )}
        ProjectList={projectList}
        selectProject={selectProject}
        WIlist={wareHouseIndentList}
        selectWarehouseIndent={selectWarehouseIndent}
        materials={data.materials}
        materialsTable={materials}
        stocksDetails={stocksDetails}
        previousFiles={previousFiles}
        onSubmit={onSubmit}
      />
    </div>
  );
};
