import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { approvalCommonMaster } from "../../../redux/actions/CommonMasterAction";
import useStyle from "../../CommonMaster/View/UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { searchEmployeeReleaseById } from "../../../redux/actions/HRManagementActions";
import moment from "moment";

export const EmployeeReleaseView = (props) => {
  const [data, setData] = useState(null);
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if (!match.params.id) return;
    const fetch = async () => {
      const data = await searchEmployeeReleaseById(match.params.id);
      setData(data);
    };
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("employee", match.params.id, history, data)
    );
  };

  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };

  if(!data) return null;

  return (
    <>
      <Breadcrumbs
        name="Employee Release View"
        linkName1="Employee Release Dashboard"
        to1="/project-hr-management/employee-release"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Employee Release</h3>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data?.employeeReleaseCode && (
              <Paper className={classes.paper}>
                <h6>Employee Release Code:</h6>
                <p>{data?.employeeReleaseCode}</p>
              </Paper>
            )}
            {data?.releaseDate && (
              <Paper className={classes.paper}>
                <h6>Release Date</h6>
                <p>{moment(data?.releaseDate).format("DD-MM-YYYY")}</p>
              </Paper>
            )}
            {data?.project && (
              <Paper className={classes.paper}>
                <h6>Project:</h6>
                <p>{`${data?.project.ProjectCode} - ${data?.project.ProjectShortDescription}`}</p>
              </Paper>
            )}
            {data?.employee?.employee?.FirstName && (
              <Paper className={classes.paper}>
                <h6>EmployeeName:</h6>
                <p>
                  {`${data?.employee.employee?.EmployeeCode} - 
                  ${data?.employee.employee?.FirstName} ${data?.employee.employee?.LastName}`}
                </p>
              </Paper>
            )}
          </div>
        </Box>
      </Paper>
      {/* TODO: Implement approval functionality */}
      {/* {acessButton()} */}
    </>
  );
};
