import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import * as Create from "../../pages/ProjectWorkforceManagement/Create/export";
import * as Dash from "../../pages/ProjectWorkforceManagement/DashBoard/export";
import * as View from "../../pages/ProjectWorkforceManagement/View/export";
import * as Edit from "../../pages/ProjectWorkforceManagement/Edit/export";
import * as Reports from "../../pages/ProjectWorkforceManagement/Reports/export";

const ProjectWorkforceManagement = (
  <Switch>
    <Route
      exact
      path="/workforceManagement/othersReports"
      component={Reports.ReportsList}
    />
    <Route
      exact
      path="/workforceManagement/reports/workmenJoining"
      component={Reports.WorkMenJoiningReport}
    />
    <Route
      exact
      path="/workforceManagement/reports/dailyAttendence"
      component={Reports.DailyAttendenceReport}
    />

    <Route
      exact
      path="/workforceManagement/workmenJoining"
      component={Dash.WorkmenJoining}
    />
    <Route
      exact
      path="/workforceManagement/workmenJoining/view/:id"
      component={View.WorkmenJoiningView}
    />
    <Route
      exact
      path="/workforceManagement/workmenJoining/create"
      component={Create.WorkmenJoiningCreate}
    />
    <Route
      exact
      path="/workforceManagement/workmenJoining/edit/:id"
      component={Edit.WorkmenJoiningEdit}
    />
    {/*dailyAttendance */}
    <Route
      exact
      path="/workforceManagement/dailyAttendence"
      component={Dash.DaliyAttendance}
    />
    <Route
      exact
      path="/workforceManagement/dailyAttendence/view/:id"
      component={View.DailyAttendanceView}
    />
    <Route
      exact
      path="/workforceManagement/dailyAttendence/create"
      component={Create.DailyAttendanceCreate}
    />
    <Route
      exact
      path="/workforceManagement/dailyAttendence/edit/:id"
      component={Edit.DailyAttendanceEdit}
    />
     {/*WorkmenOrder */}
     <Route
      exact
      path="/workforceManagement/workmenOrder"
      component={Dash.WorkmenOrder}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrder/view/:id"
      component={View.WorkmenOrderView}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrder/create"
      component={Create.WorkmenOrderCreate}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrder/edit/:id"
      component={Edit.WorkmenOrderEdit}
    />
     {/*WorkmenOrderAmendment */}
     <Route
      exact
      path="/workforceManagement/workmenOrderAmendment"
      component={Dash.WorkmenOrderAmendment}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrderAmendment/view/:id"
      component={View.WorkmenOrderAmendmentView}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrderAmendment/create"
      component={Create.WorkmenOrderAmendmentCreate}
    />
    <Route
      exact
      path="/workforceManagement/workmenOrderAmendment/edit/:id"
      component={Edit.WorkmenOrderAmendmentEdit}
    />
     {/*Attendence Regularisation */}
     <Route
      exact
      path="/workforceManagement/attendenceRegularisation"
      component={Dash.AttendenceRegularisation}
    />
    <Route
      exact
      path="/workforceManagement/attendenceRegularisation/view/:id"
      component={View.AttendenceRegularisationView}
    />
    <Route
      exact
      path="/workforceManagement/attendenceRegularisation/create"
      component={Create.AttendenceRegularisationCreate}
    />
    <Route
      exact
      path="/workforceManagement/attendenceRegularisation/edit/:id"
      component={Edit.AttendenceRegularisationEdit}
    />
     {/*SubContractorBill */}
     <Route
      exact
      path="/workforceManagement/subContractor-Bill"
      component={Dash.SubContractorBill}
    />
    <Route
      exact
      path="/workforceManagement/subContractor-Bill/view/:id"
      component={View.SubContractorBillView}
    />
    <Route
      exact
      path="/workforceManagement/subContractor-Bill/create"
      component={Create.SubContractorBillCreate}
    />
    <Route
      exact
      path="/workforceManagement/subContractor-Bill/edit/:id"
      component={Edit.SubContractorBillEdit}
    />
      {/*Service item */}
      <Route
      exact
      path="/workforceManagement/ServiceItem"
      component={Dash.ServiceItem}
    />
    <Route
      exact
      path="/workforceManagement/ServiceItem/view/:id"
      component={View.ServiceItemView}
    />
    <Route
      exact
      path="/workforceManagement/ServiceItem/create"
      component={Create.ServiceItemCreate}
    />
    <Route
      exact
      path="/workforceManagement/ServiceItem/edit/:id"
      component={Edit.ServiceItemEdit}
    />
    {/*Workmen exit */}
    <Route
      exact
      path="/workforceManagement/workmenExit"
      component={Dash.WorkmenExit}
    />
    <Route
      exact
      path="/workforceManagement/workmenExit/view/:id"
      component={View.WorkmenExitView}
    />
    <Route
      exact
      path="/workforceManagement/workmenExit/create"
      component={Create.WorkmenExitCreate}
    />
    <Route
      exact
      path="/workforceManagement/workmenExit/edit/:id"
      component={Edit.WorkmenExitEdit}
    />
      {/*Over Time Entry */}
      <Route
      exact
      path="/workforceManagement/overTimeEntry/:id"
      component={Dash.OvertimeEntryDashboard}
    />
    <Route
      exact
      path="/workforceManagement/overTimeEntry/create"
      component={Create.OverTimeEntryCreate}
    />

  </Switch>
);
export default ProjectWorkforceManagement;
