import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const DisbursementTableSubContractorView = ({ tableData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let values = [];
    if (tableData) {
      values = [...tableData];
    }
    setData(values);
  }, [tableData]);

  return (
    <div>
      {tableData ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">WorkOrderNo</th>
                <th className="head-cell">WorkOrderDate</th>
                <th className="head-cell">SCBNo</th>
                <th className="head-cell">SCBDate</th>
                <th className="head-cell">SubContractorInvoiceNo</th>
                <th className="head-cell">SubContractorInvoiceDate</th>
                <th className="head-cell">SubContractorJournalNo</th>
                <th className="head-cell">SubContractorJournalDate</th>
                <th className="head-cell">NetDue</th>
                <th className="head-cell">DueDate</th>
                <th className="head-cell">PaidSoFar</th>
                <th className="head-cell">PayNow</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data2">{row.WorkOrderNo}</td>
                  <td className="table-data2">
                    {format(new Date(row.WorkOrderDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">
                    {format(new Date(row.SCBDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{row.SubContractorInvoiceNo}</td>
                  <td className="table-data2">
                    {row.SubContractorInvoiceDate}
                  </td>
                  <td className="table-data2"></td>
                  <td className="table-data2">
                    {row.PurchaseJournalVoucherNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(row.SubContractorJournalDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{row.NetDue}</td>
                  <td className="table-data2">
                    {format(new Date(row.DueDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{row.PaidSoFar}</td>
                  <td className="table-data4">{row.PayNow}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default DisbursementTableSubContractorView;
