import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton, TablePagination } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import useStyle from "../helpers/UseStyle";
import { CircularProgress } from "@material-ui/core";
import Paginator from "../Paginator/Paginator";
import useSalesAccountingDisplayStyles from "./salesAccountingDisplay.styles";

export default function ProjectCircularReportTable(props) {
  const [lists, setLists] = useState([]);
  const classes = useStyle();
  const styles = useSalesAccountingDisplayStyles();

  useEffect(() => {
    let lists = props.lists;
    lists = lists.reverse();
    setLists(lists);
  }, [props.lists]);

  const StatusChecker = (status) => {
    if (status === "accepted") {
      return <h5 className={classes.approved}>Approved</h5>;
    }
    return;
  };

  return (
    <div className={classes.table}>
      <h2 className="text-center">{props.heading}</h2>
      <div>
        {lists ? (
          <>
            <TableContainer
              className={`${classes.table} ${styles.tableContainer}`}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{props.label1}</TableCell>
                    <TableCell align="center">{props.label2}</TableCell>
                    <TableCell align="center">{props.label3}</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Operation</TableCell>
                    <TableCell align="center">
                      {lists.Status && "Status"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lists.map((list, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <ListAltIcon color="primary" />
                        {list[props.tablecol1]}
                      </TableCell>
                      <TableCell align="center">
                        {list[props.tablecol2]}
                      </TableCell>
                      <TableCell align="center">
                        {list[props.tablecol3]}
                      </TableCell>

                      <TableCell align="center">
                        <UpdateIcon color="primary" /> {list.created_at}
                      </TableCell>
                      <TableCell align="center">
                        {props.visibleIcon && (
                          <span>
                            <IconButton>
                              <Link to={`${props.viewLink}/${list.id}`}>
                                <VisibilityIcon color="primary" />
                              </Link>
                            </IconButton>
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {StatusChecker(list.Status)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Paper
              style={{
                width: "90%",
                margin: "80px auto",
                marginTop: "0",
              }}
            >
              <Paginator paginateResults={props.paginatedResults} />
            </Paper>
          </>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}
