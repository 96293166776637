import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReceiptEmployeeOtherForm from "../../../../forms/AccountingSystem/Receipt/ReceiptEmployeeOtherForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const ReceiptEmployeeOtherEdit = (props) => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [previousFiles, setPreviousFiles] = useState();

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/receiptEmployeeOther/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/receiptEmployeeOther/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/receiptEmployeeOther/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/receiptEmployeeOther/");

        setPreviousFiles([...data.Files]);
        setformDetail(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );

      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Currency: detail.Currency,
      };
      setformDetail({ ...formDetail, ...detail });
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }
    async function getEmployees() {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        const mapped = data.map((item) => item.EmployeeCode);
        setEmployeeList(mapped);
        console.log(mapped);
      } catch (err) {
        console.error(err);
      }
    }
    async function getCustomer() {
      try {
        const { data } = await axios.get("/commonMaster/customer");
        const mapped = data.map((item) => item.CustomerName);
        setCustomerList(mapped);
        console.log(mapped);
      } catch (err) {
        console.error(err);
      }
    }

    async function getVendor() {
      try {
        const { data } = await axios.get("/commonMaster/vendor");

        const mapped = data.map((item) => item.VendorName);
        setVendorList(mapped);
        console.log(mapped);
      } catch (err) {
        console.error(err);
      }
    }

    getVendor();
    getEmployees();
    fetchProject();
    getCustomer();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Payment Receipt - Employee and Other View"
        linkName1="Payment Receipt - Employee and Other Dashboard"
        to1="/accountingSystem/receiptEmployeeOther"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Receipt - Employee and Other
      </h3>
      <ReceiptEmployeeOtherForm
        onSubmit={onSubmit}
        employeeList={employeeList}
        customerList={customerList}
        vendorList={vendorList}
        list={list}
        selectProjectName={selectProjectName}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "AtBank",
          "CostCentre",
          "CustomerCodeName",
          "Date",
          "EmployeeCodeName",
          "FinancialPeriod",
          "ChequeNEFTRTGSIMPSNo",
          "NarrationCash",
          "PAN",
          "ReceiptFor",
          "ReceiptMode",
          "ReceivedFrom",
          "Remarks",
          "TotalReceiptValue",
          "VendorCodeName",
          "EmployeeCodeName"
        )}
        cashDetails={formDetail.CashDetails}
        previousFiles={previousFiles}
      />
    </div>
  );
};
