import React, { useEffect, useState } from "react";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import RoleList from "../../../components/helpers/RoleList";
import moment from "moment";
import { useCallback } from "react";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const WarehouseIssueVoucherDashBoard = () => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  const fetch = useCallback(async () => {
    try {
      const { data } = await axios.get("/materialManagement/warehouseIssue", {
        params: {
          status: "Accepted",
          page: 1,
          ...readAllFromQueryParams(search),
        },
      });
      console.log(data);
      const newData = [
        ...new Map(
          data.data?.map((item) => [item["WarehouseIssueVoucherNo"], item])
        ).values(),
      ];
      console.log(newData);
      newData.forEach((element) => {
        element.created_at = moment(element.created_at).format(
          "DD/MM/YYYY, h:mm a"
        );
        element.approved_at =
          element.approved_at &&
          moment(element.approved_at).format("DD/MM/YYYY, h:mm a");
      });
      setLists(newData);
      setPaginatedResults({ ...data, data: newData });
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
    }
  }, [dispatch, history, search]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Breadcrumbs name="Warehouse Issue Voucher DashBoard" />
      <Table
        paginateResults={paginatedResults}
        total={lists.total}
        lists={lists}
        DeleteLink="/materialManagement/warehouseIssue"
        tablecol1="WarehouseIssueVoucherNo"
        heading="Warehouse Issue Voucher DashBoard"
        label1="Warehouse Issue Voucher No"
        link="/materialManagement/warehouseIssue/create"
        editLink="/materialManagement/warehouseIssue/edit"
        viewLink="/materialManagement/warehouseIssue/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </>
  );
};
