import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyle from "./UseStyle";
import Paper from "@material-ui/core/Paper";
import MachineReportTable from "../../../components/SalesAccountingDisplay/ProjectCircularReportTable";
import SearchOptionMachine from "../../../components/ReportsComponent/SearchOptionMachine";
import SearchOptionProject from "../../../components/ReportsComponent/SearchOptionProject";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import SearchOptionCustomer from "../../../components/ReportsComponent/SearchOptionCustomer";
import FilterByDate from "../../../components/ReportsComponent/FilterByDate";
import { filterAllMachineReceipt } from "../../../redux/actions/MachineManagementaction";

export const MachineReceiptVoucher = () => {
  const [filters, setFilters] = useState({});
  const [filterSelected, setFilterSelected] = useState("");
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const store = useSelector((store) => store);
  const machineReceipt = useSelector(
    (store) => store.machineMangementSystemRoot?.FilteredMachineReceipt
  );
  const projects = useSelector(
    (store) => store.SalesAcountRoot?.AllProjectCircular
  );
  const classes = useStyle();
  const dispatch = useDispatch();

  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;

  useEffect(() => {
    dispatch(filterAllMachineReceipt(filters));
  }, [filters]);

  const handleCustomerChange = async (value) => {
    const machines = machineReceipt.map(
      (item) => item.ProjectCodeName.split(" ")[0]
    );
    const filtered = projects.find(
      (item) =>
        item.Status === "accepted" &&
        machines.includes(item.ProjectCode) &&
        item?.CustomerName.includes(value)
    );

    if (value) {
      setFilters({
        ...filters,
        customer: `${filtered?.ProjectCode || ""} - ${
          filtered?.ProjectShortDescription || ""
        }`,
      });
    } else {
      delete filters?.customer;
      setFilters({ ...filters });
    }
  };

  const handleFilterSelect = (event) => {
    setFilterSelected(event.target.value);
    delete filters?.fromDate;
    delete filters?.toDate;
    delete filters?.customer;
    setFilters({ ...filters });
  };

  return (
    <>
      <BreadCrumbs
        name="Machine Receipt Reports"
        linkName1="All Machine Man. Reports"
        to1="/machineManagement/reports"
      />
      <Paper className={classes.Container}>
        <h2 className="text-center">Machine Receipt Reports</h2>
        <div style={{ display: "flex" }} className={classes.row}>
          <SearchOptionProject
            handleProjectChange={(project) =>
              setFilters({ ...filters, project })
            }
            label="Filter Project"
          />
          <SearchOptionMachine
            handleMachineChange={(machine) =>
              setFilters({ ...filters, machine })
            }
          />
        </div>
        <div
          id="filter-select"
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl style={{ margin: "1rem" }}>
            <InputLabel>Filter</InputLabel>
            <Select
              name="filter"
              value={filterSelected}
              onChange={(event) => {
                handleFilterSelect(event);
              }}
              style={{ width: "10rem" }}
            >
              <MenuItem value={"byCustomer"}>By Customer</MenuItem>
              <MenuItem value={"byDate"}>By Date</MenuItem>
            </Select>
          </FormControl>
          {filterSelected === "byCustomer" ? (
            <SearchOptionCustomer handleCustomerChange={handleCustomerChange} />
          ) : filterSelected === "byDate" ? (
            <FilterByDate
              handleFilterByDate={(fromDate, toDate) =>
                setFilters({ ...filters, fromDate, toDate })
              }
            />
          ) : (
            ""
          )}
        </div>
        <MachineReportTable
         paginatedResults={paginatedResults}
          lists={machineReceipt}
          heading=""
          tablecol1="MachineReceiptNo"
          tablecol2="ProjectCodeName"
          tablecol3="MachineCodeName"
          label1="Machine Receipt No"
          label2="Project Name"
          label3="Machine Name"
          viewLink="/machineManagement/machineReceipt/view"
          visibleIcon={visibleIcon}
        />
      </Paper>
    </>
  );
};
