import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import pick from "lodash/pick";
import CompanyDetails from "../../../forms/CommonMaster/CompanyDetails";
import { useHistory } from "react-router-dom";
import {
  editCompanyDetails,
  searchCompanyDetailsById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import StateList from "../../../components/helpers/StateList";

export const CompanyDetailsEdit = (props) => {
  const [data, setData] = useState({});
  const [gst, setGst] = useState(null);
  const [stateList, setStateList] = useState([]);
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchCompanyDetailsById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleCompanyDetails) {
      console.log(store.SingleCompanyDetails, "llllllllllllll");
      let a = store.SingleCompanyDetails.COIDate;
      // setData({...store.SingleCompanyDetails,COIDate:moment(a).format("DD MMM yyyy")});
      setData(store.SingleCompanyDetails);
      setGst(store.SingleCompanyDetails.Gsts);
    }
  }, [store.SingleCompanyDetails]);

  const onSubmit = (formValues, history) => {
    console.log("form values", formValues);
    dispatch(editCompanyDetails(formValues, props.match.params.id, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Company Details Edit"
        linkName1="Company Details Dashboard"
        to1="/commonMaster/companyDetails"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Company Details Edit
      </h3>
      <div className="line"></div>
      <CompanyDetails
        initialValues={pick(
          data,
          "CompanyName",
          "RegisteredOfficeAddress",
          "CertificateOfIncorporationNo",
          "COIDate",
          "ServiceTaxRegistrationNo",
          "VATRegistrationNo",
          "PFRegistrationNo",
          "State",
          "PFSubRegistrationNo",
          "ESIRegistrationNo",
          "ESISubRegistrationNo",
          "PermanentAccountNumber",
          "MSME",
          "MSMEUdyam",
          "GSTRegistrationNo",
          "Tags"
        )}
        Gst={gst}
        stateList={stateList}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
