import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { DeleteSingle } from "../../redux/actions/materialSystemAction";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, IconButton } from "@material-ui/core";

import ListAltIcon from "@material-ui/icons/ListAlt";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import DeleteModal from "../modals/DeleteModal";
import useStyle from "../helpers/UseStyle";
import usePermissions from "../../utils/hooks/usePermissions";

export default function BasicTable(props) {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const history = useHistory();
  const [lists, setLists] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const getPermissions = usePermissions();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onDelete = (id) => {
    dispatch(DeleteSingle(`${props.DeleteLink}/delete/${id}`, history));
    toggleModal();
    const temp = [...lists];

    for (const item of temp) {
      if (item.id === id) {
        console.log(item);
        item.Status = "deleted";
      }
    }

    setLists(temp);
  };

  useEffect(() => {
    let lists = props.lists;
    lists = lists.reverse();
    setLists(lists);
  }, [props.lists]);

  const classes = useStyle();

  const userMatch = (id) => {
    return (
      <>
        {!!getPermissions?.Modify && (
          <span>
            <IconButton>
              <Link to={`${props.editLink}/${id}`}>
                <EditIcon color="action" />
              </Link>
            </IconButton>
          </span>
        )}
        {!!getPermissions?.Cancel && (
          <span>
            <IconButton>
              <DeleteForeverIcon
                color="secondary"
                onClick={() => {
                  toggleModal();
                  setItemId(id);
                }}
              />
            </IconButton>
          </span>
        )}

        <DeleteModal
          showModal={showModal}
          toggleModal={toggleModal}
          onDelete={onDelete}
          itemId={itemId}
        />
      </>
    );
  };

  const StatusChecker = (status) => {
    if (status === "not_reviewed") {
      return <h5 className={classes.notReviewed}>Not Reviewed</h5>;
    } else if (status === "accepted") {
      return <h5 className={classes.approved}>Approved</h5>;
    } else if (status === "rejected") {
      return <h5 className={classes.rejected}>Rejected</h5>;
    } else if (status === "deleted") {
      return <h5 className={classes.deleted}>Deleted</h5>;
    }
    return;
  };

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <h2 className="text-center">{props.heading}</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{props.label1}</TableCell>
              <TableCell align="center">Request by</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Operation</TableCell>
              <TableCell align="center">{lists.Status && "Status"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lists.map((list, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <ListAltIcon color="primary" />
                  {list[props.tablecol1]}
                </TableCell>
                <TableCell align="center">
                  <PermIdentityIcon color="primary" />
                  {list.created_by.first_name}
                </TableCell>
                <TableCell align="center">
                  <UpdateIcon color="primary" />
                  {list?.updated_at ? list.updated_at : list.created_at}
                </TableCell>
                <TableCell align="center">
                  {props.visibleIcon && !!getPermissions?.View && (
                    <span>
                      <IconButton>
                        <Link to={`${props.viewLink}/${list.id}`}>
                          <VisibilityIcon color="primary" />
                        </Link>
                      </IconButton>
                    </span>
                  )}
                  {list.created_by.id === store.userRoot.user.id &&
                    (list.Status === "not_reviewed" ||
                      list.Status === "rejected") &&
                    userMatch(list.id)}
                </TableCell>
                <TableCell align="center">
                  {StatusChecker(list.Status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!getPermissions?.Add && (
        <Link to={props.link}>
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={
              <AddCircleRoundedIcon
                variant="contained"
                className={classes.buttonIcon}
              />
            }
          >
            ADD
          </Button>
        </Link>
      )}
    </>
  );
}
