import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";

const DailyProgressSCItemTable = ({
  scItemList,
  activityIndex,
  handleSCItemChange,
  scItemEditDetails,
  WorkDoneQuantity,
  uom,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: "",
      SCItemCodeName: "",
      UOM: "",
      QuantityWorkDone: "",
      id: "new",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [scItem, setScItem] = useState(null);
  const [allowable, setAllowable] = useState([
    {
      decimal: "",
    }
  ]);

  // console.log("WorkDoneQuantity", WorkDoneQuantity);
  useEffect(() => {
    if (scItemList) {
      setScItem([...scItemList]);
    }
    //eslint-disable-next-line
  }, [scItemList]);

  // for edit
  useEffect(() => {
    let values = [];
    if (scItemEditDetails && scItemEditDetails.length > 0) {
      for (let i = 0; i < scItemEditDetails.length; i++) {
        values = [
          ...values,
          {
            SCItemCodeName: scItemEditDetails[i].SCItemCodeName,
            UOM: scItemEditDetails[i].UOM,
            QuantityWorkDone: scItemEditDetails[i].QuantityWorkDone,
            id: scItemEditDetails[i].id,
          },
        ];
      }
      setInputFields(values);
    }
  }, [scItemEditDetails]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    if (values) {
      handleSCItemChange(values, activityIndex);
    }
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: "",
        SCItemCodeName: "",
        UOM: "",
        QuantityWorkDone: "",
        id: "new",
      },
    ]);
  };

  const onItemSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.SCItemCodeName = values.SCItemCode + "-" + values.SCItemDescription;
        row.UOM = values.UOM;

        // const item = uom.find((item) => item.UOMCode === row.UOM);
        const tempAllowable = [...allowable];
        tempAllowable[index] = { decimal: values.AllowableDecimal };
        setAllowable(tempAllowable);

        tdata[index] = row;
        setInputFields(tdata);

        const list = scItem;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setScItem([...list]);
      } else {
        console.log("scItem", scItem);
        const item = scItem.find(
          (item) => item.SCItemCode === row.SCItemCodeName
        );

        setScItem([...scItem, item]);
        row.SCItemCodeName = "";
        row.UOM = "";
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row[event.target.name] = event.target.value;
    values[index] = row;
    // const decimal = allowable[index].decimal;
    if (event.target.name === "QuantityWorkDone") {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > allowable[index].decimal) {
          value[1] = value[1].substring(0, allowable[index].decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;

    setInputFields(values);
    if (values) {
      handleSCItemChange(values, activityIndex);
    } else {
      handleSCItemChange({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };
  
  const handleRowCalInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
      if (+values[i].QuantityWorkDone < 0) {
        values[i].QuantityWorkDone = 0;
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
      sum = sum + +values[i].QuantityWorkDone;
      if (sum > WorkDoneQuantity) {
        values[i].QuantityWorkDone = 0;
        enqueueSnackbar(
          "Quantity Work Done cannot be greater than Work Done Quantity",
          {
            variant: "warning",
          }
        );
      }
    }
    setInputFields(values);
    // onChange(values);
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">SC Item Code Name</th>
            <th className="head-cell">UOM</th>
            <th className="head-cell">Quantity Work Done</th>
          </tr>
        </thead>
        {inputFields.map((inputField, index) => (
          <tr>
            <td className="table-data1">{index + 1}</td>
            <td className="table-data2">
              {scItem && (
                <Autocomplete
                  id="scItem"
                  options={scItem}
                  getOptionLabel={(option) =>
                    option.SCItemCode + "-" + option.SCItemDescription
                  }
                  onChange={(event, values) =>
                    onItemSelect(
                      event,
                      values,
                      index,
                      "SCItemCodeName",
                      "SCItemDescription"
                    )
                  }
                  renderInput={(params) => <TextField {...params} />}
                  defaultValue={scItem.find(
                    (x) =>
                      x.SCItemCode + "-" + x.SCItemDescription ===
                      inputField.SCItemCodeName
                  )}
                />
              )}
            </td>
            <td className="table-data2">{inputField.UOM}</td>
            <td className="table-data4">
              <input
                name="QuantityWorkDone"
                type="number"
                value={inputField.QuantityWorkDone}
                onChange={(event) => {
                  handleChangeInput(index, event);
                  handleRowCalInput(index, event);
                }}
              />
            </td>
            <td>
              {index === 0 ? (
                <IconButton>
                  <RemoveCircleIcon className="width5" color="disabled" />
                </IconButton>
              ) : (
                <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                  <RemoveCircleIcon className="width5" color="secondary" />
                </IconButton>
              )}
            </td>
          </tr>
        ))}
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
        >
          <AddCircleIcon />
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default DailyProgressSCItemTable;
