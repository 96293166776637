import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReceiptCustomerForm from "../../../../forms/AccountingSystem/Receipt/ReceiptCustomerForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

export const ReceiptCustomerEdit = (props) => {
  const history = useHistory();
  const [costCentres, setCostCentres] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [receiptinvoice, setReceiptInvoice] = useState({});
  const [previousFiles, setPreviousFiles] = useState();

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/receiptCustomerInvoice/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/receiptCustomerInvoice/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/receiptCustomerInvoice/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/receiptCustomerInvoice/");

        setPreviousFiles([...data.Files]);
        setReceiptInvoice(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const fetchCostcentre = async () => {
    const { data } = await axios.get("/commonMaster/costCentre/");
    const mapped = data.map((item) => item.CostCenterName);
    setCostCentres(mapped);
  };

  const fetchCustomer = async () => {
    const { data } = await axios.get("/commonMaster/customer/");
    const mapped = data.map((item) => item.CustomerName);
    setCustomers(mapped);
    const { data: bank } = await axios.get("/commonMaster/bank");
    const banklist = bank.map((item) => item.BankCode + " - " + item.BankName);
    console.log(bank);
    setBankList(banklist);
  };

  useEffect(() => {
    try {
      fetchCostcentre();
      fetchCustomer();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Payment Receipt - Customer Edit"
        linkName1="Payment Receipt - Customer Dashboard"
        to1="/accountingSystem/receiptCustomerInvoice"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Receipt - Customer
      </h3>
      <ReceiptCustomerForm
        onSubmit={onSubmit}
        costCentres={costCentres}
        customers={customers}
        bankList={bankList}
        initialValues={pick(
          receiptinvoice,
          "Currency",
          "AtBank",
          "CostCentre",
          "CustomerName",
          "Date",
          "FinancialPeriod",
          "ChequeNEFTRTGSIMPSNo",
          "Remarks",
          "TotalReceiptValueManual"
        )}
        receipt={receiptinvoice.invoiceDetails}
        previousFiles={previousFiles}
      />
    </div>
  );
};
