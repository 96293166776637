import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import PaymentRequestTable from "../../Tables/AccountingSystem/PaymentRequestTable";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import AccountingSystemFormHeader from "../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import "../../components/helpers/form.style.css";

const PaymentRequestForm = (props) => {
  const [selection, setSelection] = useState("employee");
  const [state, setState] = useState({});
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  useEffect(() => {
    if (props.initialValues?.PaymentFor) {
      setSelection(props.initialValues?.PaymentFor);
    }
  }, [props.initialValues?.PaymentFor]);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.paymentDetails) {
      setState({ ...state, tableError: true });
    } else if (state.paymentDetails) {
      props.onSubmit({
        ...formValues,
        paymentDetails: state.paymentDetails,
      });
    }
  };

  const onSelectGoodReceite = (value) => {
    setSelection(value);
  };

  const paymentDetails = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += +item.BillDocumentAmount;
    });
    props.dispatch(
      change("paymentRequest", "TotalPRValue", (+total).toFixed(2))
    );
    if (!data) {
      setState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        paymentDetails: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="CostCentre"
            label="Cost Center"
            component={Control.AutoCompleteInput}
            list={props.costCentreList}
            onChange={(value) => {
              props.SelectProjectFromCostCentre &&
                props.SelectProjectFromCostCentre(value);
            }}
            className="text-field"
          />
        </div>
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={false}
        />
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            label="Project Code"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="PaymentMode"
            label="Payment Mode"
          >
            <option value={""}></option>
            <option value={"Cash"}>Cash</option>
            <option value={"Bank"}>Bank</option>
          </Field>

          <Field
            name="PaymentFor"
            label="Payment For"
            onChange={onSelectGoodReceite}
            component={Control.AutoCompleteInput}
            list={["employee", "vendor", "others"]}
            className="text-field"
          />
          {selection === "employee" ? (
            <Field
              name="EmployeeCodeName"
              label="Employee Name"
              component={Control.AutoCompleteInput}
              list={props.employeeList}
              className="text-field"
            />
          ) : selection === "vendor" ? (
            <Field
              name="VendorCodeName"
              label="Vendor Code Name"
              component={Control.AutoCompleteInput}
              list={props.vendorList}
              className="text-field"
            />
          ) : (
            <>
              <Field
                name="InFavourOf"
                label="In Favour of"
                component={Control.RenderTextField}
              />
              <Field
                name="PAN"
                label="PAN"
                component={Control.RenderTextField}
              />
            </>
          )}
          <Field
            name="Remarks"
            label="Remarks"
            component={Control.RenderTextField}
          />
          <Field
            name="TotalPRValue"
            label="Total Payment Request Value"
            type="number"
            component={Control.RenderTextField}
            disabled
          />
        </div>
        <div className="excel">
          <PaymentRequestTable
            paymentDetails={paymentDetails}
            accounts={props.accounts}
            payments={props.payment}
          />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {state.tableError ? `${state.tableErrorKind}` : ""}
          </p>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Payment Request"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCenter",
    "PaymentMode",
    "ProjectCode",
    "PaymentFor",
    "CustomerName",
    "CostCentre",
    "Currency",
    "FinancialPeriod",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "paymentRequest",
  validate,
  enableReinitialize: true,
})(PaymentRequestForm);
