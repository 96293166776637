import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import Material from "../../../forms/CommonMaster/Material";
import {
  material,
  searchMaterialGroupById,
  allMaterialGroup,
} from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllMaterialGroups = [];
let AllUOMs = [];
export const MaterialCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((store) => store);
  const [list, setList] = useState([]);
  const [UOMList, setUOMList] = useState([{}]);
  const [formDetail, setFormDetail] = useState({});

  const onSubmit = (formValues) => {
    console.log(JSON.stringify(formValues));
    dispatch(material(formValues, history));
  };

  const selectMaterialGroup = (materialGroupCode) => {
    if (materialGroupCode) {
      const item = AllMaterialGroups.find(
        (item) => item.MaterialGroupCode === materialGroupCode
      );

      if (item) dispatch(searchMaterialGroupById(item.id));
      setFormDetail({
        ...formDetail,
        MaterialGroupDescription: item.MaterialMajorGroupDescription,
      });
    }
  };

  useEffect(() => {
    dispatch(allMaterialGroup());
  }, [dispatch]);

  useEffect(() => {
    if (store.commonMasterSystemRoot.AllMaterialGroup.length !== 0) {
      AllMaterialGroups = store.commonMasterSystemRoot.AllMaterialGroup;
      let MGList = AllMaterialGroups.map(
        (item) =>
          item.MaterialGroupCode +
          "-" +
          item.MaterialMajorGroupDescription +
          "-" +
          item.MaterialMinorGroupDescription +
          "-" +
          item.MaterialSubGroupDescription +
          "-" +
          item.MaterialSubSubGroupDescription
      );
      setList(MGList);
    }
  }, [store.commonMasterSystemRoot.AllMaterialGroup]);
  useEffect(() => {
    async function fetchCustomer() {
      try {
        let { data } = await axios.get("/commonMaster/unitOfMeasurement");
        AllUOMs = data;
        let UOMlist = AllUOMs.map(
          (item) => item.UOMCode + "-" + item.UOMDescription
        );
        setUOMList(UOMlist);
      } catch (error) {
        alert(error);
      }
    }

    fetchCustomer();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Material Create"
        linkName1="MAterial Dashboard"
        to1="/commonMaster/material"
      />
      <h3 style={{ textAlign: "center" }}>Material</h3>
      <Material
        list={list}
        UOMlist={UOMList}
        onSubmit={onSubmit}
        selectMaterialGroup={selectMaterialGroup}
        initialValues={pick(formDetail, "MaterialGroupDescription")}
      />
    </div>
  );
};
