import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Currency from "../../../forms/CommonMaster/Currency";
import { currency } from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CurrencyCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = (formValues) => {
    dispatch(currency(formValues, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Currency Create"
        linkName1="Currency Dashboard"
        to1="/commonMaster/costCentre"
      />
      <FormHeading label="Currency" />
      <Currency onSubmit={onSubmit} />
    </div>
  );
};
