import React, { useEffect } from "react";
import useStyle from "../helpers/UseStyle";
import Logo from "../assets/fnLogo.png";
import HECLogo from "../assets/logo_white.png";
import store from "../../redux/store";

const CompanyHeader = () => {
  const [user, setUser] = React.useState(null);

  useEffect(() => {
const users = store.getState().userRoot;
setUser(users);
console.log(users);
  },[])
  const classes = useStyle();
  return (
<div>
  {user && user?.workingOn?.Company === "HINDUSTHAN ENGINEERING CORPORATION" ? (
      <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={HECLogo}
          alt={HECLogo}
          className={classes.Heading}
          style={{ height: "auto", width: "200px", paddingBottom: "0", backgroundColor:"black" }}
        />
        <div>
          <h4 style={{ textAlign: "center", fontSize: "18px" }}>
            Head Office : 2ND FLOOR, 5, PARK ISLAND, DR BIRESH GUHA STREET, PARK
            CIRCUS, Kolkata, West Bengal, 700017
          </h4>
        </div>
      </div>
    </div>
    ) : (
      <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src={Logo}
          alt={Logo}
          className={classes.Heading}
          style={{ height: "auto", width: "500px", paddingBottom: "0" }}
        />
        <div>
          <h4 style={{ textAlign: "center", fontSize: "18px" }}>
            Head Office : 2ND FLOOR, 5, PARK ISLAND, DR BIRESH GUHA STREET, PARK
            CIRCUS, Kolkata, West Bengal, 700017
          </h4>
        </div>
      </div>
    </div>
    )}
   </div>
  );
};

export default CompanyHeader;
