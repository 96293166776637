import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DailyAttendance from "../../../forms/ProjectWorkforceManagement/DailyAttendanceForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const DailyAttendanceEdit = (props) => {
  const history = useHistory();
  const [costCentrelist, setCostCentrelist] = useState([]);
  const [dailyAttendence, setDailyAttendence] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [machineList, setMachineList] = useState(null);
  const [activityList, setActivityList] = useState(null);
  const [list, setList] = useState([]);
  const [shiftlist,setShiftlist] = useState([]);
  const { match } = props;
  const [previousFiles, setPreviousFiles] = useState();
  const [shiftCode, setShiftCode] = useState(null);
  const [workmenList, setWorkmenList] = useState(null);
  const [projectName, setProjectName] = useState();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.put(
      `/workforceManagement/dailyAttendence/${props.match.params.id}`,
      formValues
    );
    alert("Updated Successfully");
    console.log(response);
    history.push("/workforceManagement/dailyAttendence/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/workforceManagement/dailyAttendence/${match.params.id}`
        );
        let data = response.data;
        setDailyAttendence(data);
        setPreviousFiles([...data.Files]);
        console.log("dailyAttendence", data);
      } catch (err) {
        console.error(err);
      }
    }
    console.log(match.params);
    fetch();
  }, [match.params]);

  const selectProjectName = async (projectName) => {
    setProjectName(projectName);
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.post(
          `commonMaster/machine/findByProjectName/`,
          {
            currentlyAt: projectName,
          }
        );
        let machines = data.map((item) => item.machineID);
        setMachineList(machines);
        // console.log(machines);
      } catch (err) {
        console.error(err);
      }
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Date: detail.created_at,
      };
      setDailyAttendence({ ...dailyAttendence, ...detail });
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }

    async function getCostCenterDetails() {
      try {
        const { data } = await axios.get("/commonMaster/costCentre");
        const filteredData = data.map((item) => item.CostCenterName);
        setCostCentrelist(filteredData);
        // console.log("filteredData ->", data);
      } catch (err) {
        console.error(err);
      }
    }
    async function getVendorAndActivity() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNList = data.map((item) => item.VendorName);
        setVendorList(VNList);
        const { data: activities } = await axios.get("/commonMaster/activity/");
        const mapped = activities.map(
          (item) => item.ActivityCode + " - " + item.ActivityDescription
        );
        setActivityList(mapped);
      } catch (err) {
        alert(err);
      }
    }
    async function getShiftCode() {
      try {
        const { data } = await axios.get("/commonMaster/shifts");
        setShiftlist(data);
      } catch (err) {
        console.error(err);
      }
    }
    async function getWorkMen() {
      try {
        let { data } = await axios.post(
          "/workforceManagement/shared/getAllWorkmenInProject",
          {
            ProjectCodeName: projectName ? projectName : "ProjectCodeName",
          }
        );
        setWorkmenList(data);
      } catch (err) {
        alert(err);
      }
    }
    getShiftCode();
    getWorkMen();
    getVendorAndActivity();
    fetchProject();
    getCostCenterDetails();
  }, [machineList, projectName]);

  return (
    <div>
      <BreadCrumbs
        name="Daily Attendance Edit"
        linkName1="Daily Attendance Dashboard"
        to1="/workforceManagement/dailyAttendence/"
      />
      <h3 style={{ textAlign: "center" }}>Daily Attendance</h3>
      <DailyAttendance
        CCList={costCentrelist}
        list={list}
        VNList={vendorList}
        shiftlist={shiftlist}
        selectProjectName={selectProjectName}
        onSubmit={onSubmit}
        shiftCode={shiftCode}
        initialValues={pick(
          dailyAttendence,
          "ProjectCodeName",
          "CustomerName",
          "Date",
          "CostCentre",
          "VendorCode",
          "WorkOrderNo",
          "WorkmenType",
          "tableData"
        )}
        edit={true}
        tables={dailyAttendence.tables}
        previousFiles={previousFiles}
        machineList={machineList}
        activityList={activityList}
        workmenList={workmenList}
      />
    </div>
  );
};
