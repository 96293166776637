import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { approvalCommonMaster } from "../../../redux/actions/CommonMasterAction";
import useStyle from "../../CommonMaster/View/UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { searchTransferOrderById } from "../../../redux/actions/HRManagementActions";
import moment from "moment";

export const TransferOrderView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if (!match.params.id) return;
    const fetch = async () => {
      const data = await searchTransferOrderById(match.params.id);
      setData(data);
    };
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("employee", match.params.id, history, data)
    );
  };

  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };

  return (
    <>
      <Breadcrumbs
        name="Transfer Order View"
        linkName1="Transfer Order Dashboard"
        to1="/project-hr-management/transfer-order"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Transfer Order</h3>
          <h6>{`Created by ${
            data?.employee?.employee?.created_by
              ? ` ${data?.employee?.employee?.created_by.first_name} ${data?.employee?.employee?.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data?.employee?.employee?.EmployeeCode && (
              <Paper className={classes.paper}>
                <h6>Employee Code:</h6>
                <p>{data?.employee?.employee?.EmployeeCode}</p>
              </Paper>
            )}
            {data?.employee?.employee?.EmployeeRoleDes && (
              <Paper className={classes.paper}>
                <h6>Employee RoleDes:</h6>
                <p>{data?.employee?.employee?.EmployeeRoleDes}</p>
              </Paper>
            )}
            {data?.employee?.employee?.Project && (
              <Paper className={classes.paper}>
                <h6>Project:</h6>
                <p>{data?.employee?.employee?.Project}</p>
              </Paper>
            )}
            {data?.employee?.employee?.Email && (
              <Paper className={classes.paper}>
                <h6>Email:</h6>
                <p>{data?.employee?.employee?.Email}</p>
              </Paper>
            )}
            {data?.employee?.employee?.EmployeeRole && (
              <Paper className={classes.paper}>
                <h6>EmployeeRole:</h6>
                <p>{data?.employee?.employee?.EmployeeRole}</p>
              </Paper>
            )}
            {data?.employee?.employee?.FirstName && (
              <Paper className={classes.paper}>
                <h6>EmployeeName:</h6>
                <p>
                  {data?.employee?.employee?.FirstName}{" "}
                  {data?.employee?.employee?.LastName}
                </p>
              </Paper>
            )}
            {data?.employee?.employee?.Company && (
              <Paper className={classes.paper}>
                <h6>Company:</h6>
                <p>{data?.employee?.employee?.Company} </p>
              </Paper>
            )}
            {data?.employee?.employee?.Phone && (
              <Paper className={classes.paper}>
                <h6>Phone:</h6>
                <p>{data?.employee?.employee?.Phone}</p>
              </Paper>
            )}
            {data?.reportEmployee?.employee?.EmployeeCode && (
              <Paper className={classes.paper}>
                <h6>Reporting Employee Code:</h6>
                <p>{data?.reportEmployee?.employee?.EmployeeCode}</p>
              </Paper>
            )}
            {data?.reportingDate && (
              <Paper className={classes.paper}>
                <h6>Reporting Date: </h6>
                <p>{moment(data?.reportingDate).format("DD-MM-YYYY")}</p>
              </Paper>
            )}
            ;
          </div>
        </Box>
      </Paper>
      {/* TODO: Implement approval functionality */}
      {/* {acessButton()} */}
    </>
  );
};
