import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";


const RetentionTermsTable = ({ retentionTermsTable, retentions }) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: 1,
      RetentionTerms: "",
      Percentage: "",
      Days: "",
      id: "new",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (retentions) {
      setInputFields([...retentions]);
      onChange([...retentions]);
    }
    // eslint-disable-next-line
  }, [retentions]);

  const onChange = (values) => {
    if (values) {
      retentionTermsTable(values);
    } else {
      retentionTermsTable({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
      sum = sum + +values[i]?.Percentage;
      if (sum > 100) {
        values[i].Percentage = 0;
        enqueueSnackbar("Payment percentage cannot be greater than 100", {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    onChange(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        RetentionTerms: "",
        Percentage: "",
        Days: "",
        id: "",
      },
    ]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">Retention Terms</th>
            <th className="head-cell">Percentage</th>
            <th className="head-cell">Days</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
                <input
                  type="text"
                  style={{ width: "15rem" }}
                  name="RetentionTerms"
                  value={inputField.RetentionTerms}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  style={{ width: "10rem" }}
                  name="Percentage"
                  value={inputField.Percentage}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  style={{ width: "10rem" }}
                  name="Days"
                  value={inputField.Days}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td>
                {index >= 0 ? (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                ) : ""
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
        >
          <AddCircleIcon />
          ADD Row
        </Button>
      </div>
    </div>
  );
};
export default RetentionTermsTable;
