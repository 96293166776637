import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { getBenefitById } from "../../../redux/actions/CommonMasterAction";

export const BenefitView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        try {
            const data = await getBenefitById(match.params.id);
            setData(data);
        } catch (error) {
            // 
        }
    }
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  return (
    <>
      <Breadcrumbs
        name="Benefit View"
        linkName1="/commonMaster/benefits"
        to1="Benefits Dashboard"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Benefit</h3>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.benefitCode && (
              <Paper className={classes.paper}>
                <h6>Benefit Code:</h6>
                <p>{data.benefitCode}</p>
              </Paper>)}

              {data.benefitDescription && (
              <Paper className={classes.paper}>
                <h6>Benefit Description:</h6>
                <p>{data.benefitDescription}</p>
              </Paper>)}

              {data.basicWage && (
              <Paper className={classes.paper}>
                <h6>BasicWage:</h6>
                <p>{data.basicWage}%</p>
              </Paper>)}

              {data.applicableFor && (
              <Paper className={classes.paper}>
                <h6>Applicable For:</h6>
                <p>{data.applicableFor}</p>
              </Paper>)}

              {data.tag && (
              <Paper className={classes.paper}>
                <h6>Tag:</h6>
                <p>{data.tag}</p>
              </Paper>)}
          </div>
        </Box>
      </Paper>
    </>
  );
};
