import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";

export const RenderDateField = ({
  label,
  input,
  initialValue,
  meta: { invalid, error },
  ...custom
}) => {
  const { onChange } = input;

  const [selectedDate, setSelectedDate] = useState(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSelectedDate(initialValue);
    }
  }, [initialValue]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
    setTouched(true);
  };

  const { enqueueSnackbar } = useSnackbar();
  if (touched && error) {
    enqueueSnackbar(`${label} ${error}`, {
      variant: "error",
    });
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk
        variant="inline"
        format="d MMM yyyy"
        margin="normal"
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        error={touched && invalid}
        helperText={touched && error}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  );
};
