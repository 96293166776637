import React, { useState } from "react";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import Table from "../../Tables/MachineManagementSystemTable/LogSheetEntryActivityHours";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { useSnackbar } from "notistack";
import { connect, useDispatch } from "react-redux";

const checkFields = [
  "WorkingHour",
  "BreakDownHour",
  "MarchingHour",
  "IdleHour",
];

const validateForm = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "MachineCodeName",
    "ShiftHour",
    "LogSheetDate",
    "StartMeterReading",
    "EndMeterReading",
    "WorkingHour",
    "BreakDownHour",
    "MarchingHour",
    "IdleHour",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      errors[requiredField] = "Required";
    }
  });

  // shift hours validation
  checkFields.push("ShiftHour");
  checkFields.forEach((field) => {
    if (values[field]?.length > 2) {
      errors[field] = "Must be 2 characters or less";
    } else if (values[field]?.length > 0 && +values[field] > 24) {
      errors[field] = `${field} can not be greater than 24`;
    }
  });

  return errors;
};

let LogSheetEntry = (props) => {
  const { ShiftHour } = props;
  const [state, setState] = useState({
    tableData: null,
    tableErrorKind: "",
    tableError: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const validateShiftHour = (value) => {
    const hours =
      +value.WorkingHour +
      +value.BreakDownHour +
      +value.MarchingHour +
      +value.IdleHour;
    if (hours > value.ShiftHour) {
      enqueueSnackbar(
        `Remainder:Shift hour = WorkingHour+ Breakdown Hour+ IdealHour+ MarchingHour`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    const isFormValid = validate(formValues);
    if (isFormValid) {
      if (!state.tableData) {
        console.log("check");
        setState({ ...state, tableError: true });
      } else if (state.tableData) {
        let hours = 0;
        state.tableData.forEach((data) => {
          hours += +data.WorkingHour;
        });
        var flag = 0;
        if (validateShiftHour(formValues)) {
          flag = 1;
        }
        if (+hours === +formValues.WorkingHour && flag == 1) {
          console.log("check2");
          props.onSubmit({ ...formValues, tableData: state.tableData });
        } else {
          enqueueSnackbar(
            "Working Hours in the table does not match with the above Working Hours",
            {
              variant: "error",
            }
          );
        }
      }
    }
  };

  const validate = (values) => {
    const hours =
      +values.WorkingHour +
      +values.BreakDownHour +
      +values.MarchingHour +
      +values.IdleHour;
    if (hours !== +values.ShiftHour) {
      enqueueSnackbar(`Total Hours can't be greater than shift hours`, {
        variant: "error",
      });
      return false;
    }
    if (+values.EndMeterReading < +values.StartMeterReading) {
      enqueueSnackbar(
        `Start Meter Reading must be more than ${+values.StartMeterReading}`,
        {
          variant: "warning",
        }
      );
      return false;
    }

    checkFields.forEach((field) => {
      if (values[field] < 0) {
        enqueueSnackbar(`You can't enter Negative Number in ${field}`, {
          variant: "warning",
        });
        return false;
      }
    });

    return true;
  };

  // const handleHours = (event, newVal, preVal, name) => {
  //   checkFields
  //     .filter((value) => value !== "ShiftHour")
  //     .forEach((field) => {
  //       if (newVal === ShiftHour) {
  //         if (name !== field) {
  //           dispatch(change("LogSheetEntry", field, 0));
  //         }
  //       }
  //     });
  // };

  // const checkNegativeValue = (value, fieldName) => {
  //   if (value < 0) {
  //     enqueueSnackbar(`You can't enter Negative Number`, {
  //       variant: "warning",
  //     });
  //     props.dispatch(change("LogSheetEntry", fieldName, 0));
  //   }
  // };

  // const validateHour = (value) => {
  //   if (value) {
  //     checkNegativeValue(value, "WorkingHour");
  //     checkNegativeValue(value, "BreakDownHour");
  //     checkNegativeValue(value, "MarchingHour");
  //     checkNegativeValue(value, "IdleHour");
  //   }
  // };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project Code / Name"
            className="text-field"
            list={props.ProjectList}
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            disabled
          />
          <Field
            component={Control.AutoCompleteInput}
            list={props.MachineList}
            name="MachineCodeName"
            label="Machine Code / Name"
            className="text-field"
            onChange={(value) => props.selectMachineReceiptName?.(value)}
          />
          <Field
            component={Control.RenderTextField}
            name="ShiftHour"
            label="Shift Hour"
            type="number"
          />
          <Field
            component={Control.RenderDateField}
            name="LogSheetDate"
            label="Log Sheet Date"
            initialValue={props.initialValues.LogSheetDate}
          />
          <Field
            component={Control.RenderTextField}
            name="StartMeterReading"
            label="Start Meter Reading"
            type="number"
            min={0}
          />
          <Field
            component={Control.RenderTextField}
            name="EndMeterReading"
            label="End Meter Reading"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="WorkingHour"
            label="Working Hour"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="BreakDownHour"
            label="Break Down Hour"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="MarchingHour"
            label="Marching Hour"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="IdleHour"
            label="Idle Hour"
            type="number"
          />
          <Field
            component={Control.RenderTextField}
            name="remarks"
            label="Remarks"
          />
        </div>

        <div className="excel">
          <Table
            tableData={tableData}
            tableEditFields={props.tableEditFields}
            ActivityList={props.ActivityList}
            ActivityDetailList={props.ActivityDetailList}
            isEdit={props.isEdit}
            fetchingActivities={props.fetchingActivities}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Log Sheet Entry"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

// Decorate with connect to read form values
const selector = formValueSelector("LogSheetEntry"); // <-- same as form name
LogSheetEntry = connect((state) => {
  // can select values individually
  const ShiftHour = selector(state, "ShiftHour");
  return {
    ShiftHour,
  };
})(LogSheetEntry);

export default reduxForm({
  form: "LogSheetEntry",
  validate: validateForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LogSheetEntry);
