import WidgetsIcon from "@material-ui/icons/Widgets";
import RoleList from "../../helpers/RoleList";

export const MaterialSystemNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_STORE_KEEPER.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.BU_HEAD.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.DIRECTOR.code,
  ],
  label: "Material Management",
  name: "MaterialManagement",
  attrModule: "projectMaterial",
  icon: <WidgetsIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/materialManagement/purchaseRequest",
      label: "Purchase Request",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/purchaseOrder",
      label: "Purchase Order",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/purchaseOrderAmendment",
      label: "Purchase Order Amendment",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/goodsReceipt",
      label: "Goods Receipt Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/goodsInspection",
      label: "Goods Inspection Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/warehouseReceipt",
      label: "Warehouse Receipt Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/warehouseIntent",
      label: "Warehouse Indent Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/warehouseIssue",
      label: "Warehouse Issue Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/WarehouseReturn",
      label: "Warehouse Return Voucher",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },

    {
      to: "/materialManagement/deliveryChallan",
      label: "Delivery Challan",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/physicalVerification",
      label: "Physical Verification",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/materialManagement/othersReports",
      label: "Others Reports",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
  ],
};
