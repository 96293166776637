import { Switch } from "react-router-dom";
import React from "react";
import Route from "../PrivateRouter";
import * as View from "../../pages/MaterialSystem/View/exportMSView";
import * as Dashboard from "../../pages/MaterialSystem/Dashboard/export";
import * as Create from "../../pages/MaterialSystem/Create/export";
import * as Edit from "../../pages/MaterialSystem/Edit/export";
import * as Reports from "../../pages/MaterialSystem/Reports/export";

const materialSystemRoutes = (
  <Switch>
    {/* Dash */}
    <Route
      exact
      path="/materialManagement/othersReports"
      component={Reports.ReportsList}
    />

    <Route
      exact
      path="/materialManagement/othersReports/materialFlow"
      component={Reports.MaterialFlow}
    />

    <Route
      exact
      path="/materialManagement/othersReports/stockStatement"
      component={Reports.StockStatement}
    />

    <Route
      exact
      path="/materialManagement/purchaseRequest"
      component={Dashboard.PurchaseRequestDashBoard}
    />
    <Route
      exact
      path="/materialManagement/goodsInspection"
      component={Dashboard.GoodsInspectionVoucherDashBoard}
    />
    <Route
      exact
      path="/materialManagement/warehouseIssue"
      component={Dashboard.WarehouseIssueVoucherDashBoard}
    />
    <Route
      exact
      path="/materialManagement/warehouseReceipt"
      component={Dashboard.WarehouseReceiptVoucherDashBoard}
    />
    <Route
      exact
      path="/materialManagement/WarehouseReturn"
      component={Dashboard.WarehouseReturnVoucherDashBoard}
    />
    <Route
      exact
      path="/materialManagement/physicalVerification"
      component={Dashboard.PhysicalVerificationDashBoard}
    />
    <Route
      exact
      path="/materialManagement/goodsReceipt"
      component={Dashboard.GoodsReceiptVoucherDashBoard}
    />
    <Route
      exact
      path="/materialManagement/deliveryChallan"
      component={Dashboard.DeliveryChallanDashBoard}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrderAmendment"
      component={Dashboard.PurchaseOrderAmendmentDashBoard}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrder"
      component={Dashboard.PurchaseOrderDashBoard}
    />
    <Route
      exact
      path="/materialManagement/warehouseIntent"
      component={Dashboard.WarehouseIndentVoucherDash}
    />

    {/* Dash end */}
    {/* create */}
    <Route
      exact
      path="/materialManagement/purchaseOrder/create"
      component={Create.PurchaseOrderFormCreate}
    />
    <Route
      exact
      path="/materialManagement/physicalVerification/create"
      component={Create.PhysicalVerificationFormCreate}
    />
    <Route
      exact
      path="/materialManagement/warehouseIssue/create"
      component={Create.WarehouseIssueVoucherFormCreate}
    />
    <Route
      exact
      path="/materialManagement/WarehouseReturn/create"
      component={Create.WarehouseReturnVoucherFormCreate}
    />
    <Route
      exact
      path="/materialManagement/purchaseRequest/create"
      component={Create.PurchaseRequestFormCreate}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrderAmendment/create"
      component={Create.PurchaseOrderAmendmentCreate}
    />
    <Route
      exact
      path="/materialManagement/deliveryChallan/create"
      component={Create.DeliveryChallanFormCreate}
    />
    <Route
      exact
      path="/materialManagement/goodsReceipt/create"
      component={Create.GoodsReceiptVoucherFormCreate}
    />
    <Route
      exact
      path="/materialManagement/goodsInspection/create"
      component={Create.GoodsInspectionVoucherFormCreate}
    />
    <Route
      exact
      path="/materialManagement/warehouseReceipt/create"
      component={Create.WarehouseReceiptVoucherformCreate}
    />
    <Route
      exact
      path="/materialManagement/warehouseIntent/create"
      component={Create.WarehouseIndentVoucherCreate}
    />
    {/* Create End */}
    <Route
      exact
      path="/materialManagement/warehouseIntent/edit/:id"
      component={Edit.WarehouseIndentVoucherEdit}
    />
    <Route
      exact
      path="/materialManagement/warehouseIntent/view/:id"
      component={View.WarehouseIndentVoucherView}
    />

    <Route
      exact
      path="/materialManagement/warehouseReceipt/edit/:id"
      component={Edit.WarehouseReceiptVoucherformEdit}
    />
    <Route
      exact
      path="/materialManagement/warehouseReceipt/view/:id"
      component={View.WarehouseReceiptView}
    />
    <Route
      exact
      path="/materialManagement/warehouseIssue/edit/:id"
      component={Edit.WarehouseIssueVoucherFormEdit}
    />
    <Route
      exact
      path="/materialManagement/warehouseIssue/view/:id"
      component={View.WarehouseIssueView}
    />
    <Route
      exact
      path="/materialManagement/physicalVerification/edit/:id"
      component={Edit.PhysicalVerificationFormEdit}
    />
    <Route
      exact
      path="/materialManagement/physicalVerification/view/:id"
      component={View.PhysicalVerificationView}
    />
    <Route
      exact
      path="/materialManagement/WarehouseReturn/edit/:id"
      component={Edit.WarehouseReturnVoucherFormEdit}
    />
    <Route
      exact
      path="/materialManagement/WarehouseReturn/view/:id"
      component={View.WarehouseReturnVoucherView}
    />
    <Route
      exact
      path="/materialManagement/goodsInspection/edit/:id"
      component={Edit.GoodsInspectionVoucherFormEdit}
    />
    <Route
      exact
      path="/materialManagement/goodsInspection/view/:id"
      component={View.GoodsInspectionView}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrder/edit/:purchaseOrderId"
      component={Edit.PurchaseOrderFromEdit}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrder/view/:id"
      component={View.PurchaseOrderView}
    />
    <Route
      exact
      path="/materialManagement/deliveryChallan/edit/:id"
      component={Edit.DeliveryChallanFormEdit}
    />
    <Route
      exact
      path="/materialManagement/deliveryChallan/view/:id"
      component={View.DeliveryChallanView}
    />
    <Route
      exact
      path="/materialManagement/goodsReceipt/edit/:id"
      component={Edit.GoodsReceiptVoucherFormEdit}
    />
    <Route
      exact
      path="/materialManagement/goodsReceipt/view/:id"
      component={View.GoodsReceiptVoucherView}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrderAmendment/edit/:id"
      component={Edit.PurchaseOrderAmendmentEdit}
    />
    <Route
      exact
      path="/materialManagement/purchaseOrderAmendment/view/:id"
      component={View.PurchaseOrderAmendmentView}
    />
    <Route
      exact
      path="/materialManagement/purchaseRequest/edit/:purchaseRequestId"
      component={Edit.PurchaseRequestFormEdit}
    />
    <Route
      exact
      path="/materialManagement/purchaseRequest/view/:id"
      component={View.PurchaseRequestView}
    />
  </Switch>
);

export default materialSystemRoutes;
