import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import LogSheetEntry from "../../../forms/MachineManagementSystem/LogSheetEntryForm";
import { useHistory } from "react-router-dom";
import axios from "../../../components/apis/axios";
import {
  editLogSheetEntry,
  searchLogSheetEntryById,
} from "../../../redux/actions/MachineManagementaction";
import { userLogout } from "../../../redux/actions/userAction";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];
let code = [];

export const LogSheetEntryEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const [projectList, setProjectList] = useState([]);
  const [MachineList, setMachineList] = useState([]);
  const [ActivityList, setActivityList] = useState([]);
  const [ActivityDetailList, setActivityDetailsList] = useState(null);
  const [previousFiles, setPreviousFiles] = useState();
  const [fetchingActivities, setFetchingActivities] = useState(false);

  useEffect(() => {
    dispatch(searchLogSheetEntryById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleLogSheetEntry) {
      const data = store.SingleLogSheetEntry;
      setData(data);
      setPreviousFiles(data.Files);
    }
    console.log("SingleLogSheetEntry ->", store.SingleLogSheetEntry);
  }, [store.SingleLogSheetEntry]);

  const selectProjectName = async (projectName) => {
    const item = allProjects.find(
      (item) => item.ProjectShortDescription === projectName
    );

    try {
      const payload = await axios.post(
        `commonMaster/machine/findByProjectName/`,
        {
          currentlyAt: item.ProjectShortDescription,
        }
      );
      code = payload.data;
      console.log("payload ->", payload);
      let machines = payload.data.map(
        (item) => item.machineID.MachineCode + "-" + item.machineID.MaterialCdes
      );
      setMachineList(machines);
    } catch (err) {
      console.error(err);
    }

    if (item) {
      console.log(item);
      let payload = {
        ...data,
        ProjectCodeName: item.ProjectShortDescription,
        CustomerName: item.CustomerName,
      };

      setData({ ...data, ...payload });
    }
  };
  const selectMachineReceiptName = async (MachineCode) => {
    console.log(data.ProjectCodeName, MachineCode);
    let payload = await axios.post(
      `/machineManagement/machineReceipt/machineReceiptDetails/`,
      {
        ProjectCodeName: data.ProjectCodeName,
        MachineCodeName: MachineCode,
      }
    );
    let codeReading = code.find(
      (item) =>
        item.machineID.MachineCode + "-" + item.machineID.MaterialCdes ===
        MachineCode
    );
    console.log("codeReading -> ", codeReading);

    payload = {
      ...data,
      MachineCodeName: MachineCode,
      ShiftHour: payload.data.ShiftHours,
      StartMeterReading: codeReading.MeterReading,
    };

    setData({ ...data, ...payload });
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const filteredData = data.filter((item) => item.Status === "accepted");
        allProjects = filteredData;
        let projects = filteredData.map((item) => item.ProjectShortDescription);
        setProjectList(projects);
      } catch (err) {}
    }
    async function fetchActivity() {
      try {
        setFetchingActivities(true);
        const { data } = await axios.get("/commonMaster/Activity");
        const mapped = data.map(
          (item) => item.ActivityCode + "-" + item.ActivityDescription
        );
        setActivityList(mapped);
        setActivityDetailsList(data);
      } catch (err) {
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      } finally {
        setFetchingActivities(false);
      }
    }
    fetch();
    fetchActivity();
  }, [history, dispatch]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editLogSheetEntry(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <BreadCrumbs
        name="Log Sheet Entry Edit"
        linkName1="Log Sheet Entry Dashboard"
        to1="/machineManagement/logSheetEntry"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Log Sheet Entry Edit
      </h3>
      <div className="line"></div>
      <LogSheetEntry
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "MachineCodeName",
          "ShiftHour",
          "LogSheetDate",
          "StartMeterReading",
          "EndMeterReading",
          "WorkingHour",
          "BreakDownHour",
          "MarchingHour",
          "IdleHour",
          "remarks"
        )}
        tableEditFields={data.ActivityHours}
        isEdit={true}
        ProjectList={projectList}
        MachineList={MachineList}
        selectProjectName={selectProjectName}
        selectMachineReceiptName={selectMachineReceiptName}
        ActivityList={ActivityList}
        ActivityDetailList={ActivityDetailList}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        previousFiles={previousFiles}
        fetchingActivities={fetchingActivities}
      />
    </div>
  );
};
