import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { approvalMaterialManagement } from "../../../redux/actions/materialSystemAction";
import { PurchaseRequestTable } from "../../../Tables/MaterialSystem/View/PurchaseRequestTableView";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { FileListArea } from "../../../components/formcomponents/control";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { Button } from "@material-ui/core";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const PurchaseRequestView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/materialManagement/purchaseRequest/${match.params.id}`
        );
        setData(data);
        console.log("data", data);

        data.RequiredOn = new Date(
          new Date(data.RequiredOn) + " UTC"
        ).toDateString();

        if (store.userRoot) {
          setPost(store.userRoot.workingOn.Role);
        }
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [store.userRoot, dispatch, history, match.params.id]);

  const handleModalAction = (data) => {
    dispatch(
      approvalMaterialManagement(
        "purchaseRequest",
        match.params.id,
        history,
        data
      )
    );
  };

  const classes = useStyle();
  const column = {
    col1: "MaterialCode",
    col2: "MaterialDescription",
    col5: "Quantity",
    col6: "SuggestedRate",
    col7: "MaterialTotal",
  };
  const columnHeading = {
    col1: "Material Code",
    col2: "Material Description",
    col5: "Quantity",
    col6: "Suggested Rate",
    col7: "Material Total",
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name=" Purchase Request  View"
        linkName1=" Purchase Request  Dashboard"
        to1="/materialManagement/purchaseRequest"
      />
      <Paper className={classes.Container}>
        <ComapnyHeader />

        <div>
          <h3 className={classes.Heading}>Purchase Request </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Purchase Request No:</h6>
              <p>{data.PurchaseRequestNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>ProjectCode / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Currency:</h6>
              <p>{data.Currency}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse Name:</h6>
              <p>{data.WarehouseName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase Category:</h6>
              <p>{data.PurchaseCategory}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Bill to Customer (For Project PR)​​:</h6>
              <p>{data.BilltoCustomer}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery At:</h6>
              <p>{data.DeliveryAt}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Required On:</h6>
              <p>{data.RequiredOn}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>PR Type:</h6>
              <p>{data.PRType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Remarks:</h6>
              <p>{data.Remarks}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Total PR Value:</h6>
              <p>{data.TotalPRValue}</p>
            </Paper>
          </div>
          <div>
            <h5 className={classes.Heading}>Material Detail</h5>
            <PurchaseRequestTable
              materials={data.materials}
              column={column}
              columnHeading={columnHeading}
            />
          </div>
        </Box>

        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.BU_HEAD.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_COMMERCIAL.code ||
        post === RoleList.DIRECTOR.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Purchase Request"}
      />
    </div>
  );
};
