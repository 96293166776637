const initialState = {
  SingleProjectCircular: {},
  AllProjectCircular: []
};

const SalesAcountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_PROJECT_CIRCULAR": {
      return {
        ...state,
        AllProjectCircular: action.payload
      };
    }
    case "SET_SINGLE_PROJECT_CIRCULAR": {
      return {
        ...state,
        SingleProjectCircular: action.payload
      };
    }
    default:
      return state;
  }
};

export default SalesAcountReducer;