import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import { searchEmployeeReleaseById } from "../../../redux/actions/HRManagementActions";
import EmployeeRelease from "../../../forms/ProjectHRManagement/EmployeeRelease/EmployeeRelease";

export const EmployeeReleaseEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchEmployeeReleaseById(match.params.id);
        setData(data);
    }

    fetch();
  }, [match.params.id]);

  const onSubmit = async (formValues) => {
    await axios.put(`/hr-management/employee-release/${match.params.id}`, formValues);
    history.push("/project-hr-management/employee-release");
  };

  const getSelectedProject = (project) => {
    if(project) {
      return `${project.ProjectCode} - ${project.ProjectShortDescription}`;
    }
    return "";
  }

  const getSelectedEmployee = (employee) => {
    if(employee) {
      return `${employee.EmployeeCode} - ${employee.FirstName} ${employee.LastName}`;
    }
    return "";
  }

  return (
    <div>
      <Breadcrumbs
        name="Employee Release Edit"
        linkName1="Employee Release Dashboard"
        to1="/project-hr-management/employee-release"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Employee Release
      </h3>
      <div className="line"></div>
      <EmployeeRelease
        initialValues={{
            ...data,
            costCentre: data?.project?.CostCentre,
            customerName: data?.project?.CustomerName,
            projectData: data?.project,
            employeeData: data?.employee,
            employee: getSelectedEmployee(data?.employee?.employee), 
            project: getSelectedProject(data?.project),
        }}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
