import { useHistory } from "react-router-dom";
import { buildQueryString } from "../queryParams";

const useUpdateSearch = () => {
  const history = useHistory();

  const updateSearch = (search) => {
    history.replace({
      search: buildQueryString(search, history.location.search),
    });
    return search;
  };

  return [updateSearch];
};

export default useUpdateSearch;
