import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import { RenderTableDateField } from "../../components/formcomponents/control";

const ScrutinySubContractorTable = (props) => {
  const [inputFields, setInputFields] = useState([
    {
      SlNo: 0,
      WorkOrderNo: "",
      WorkOrderDate: null,
      SubContractorBillNoAuto: "",
      SubContractorBillDateAuto: null,
      SubContractorBillNoManual: "",
      SubContractorBillDateManual: null,
      Amount: "",
      id: "",
    },
  ]);

  const { tableData } = props;
  // useEffect(() => {
  //   let values = [];
  //   if (scrutiny) {
  //     for (let i = 0; i < scrutiny.length; i++) {
  //       values = [
  //         ...values,
  //         {
  //           SlNo: scrutiny[i].SerialNo,
  //           WorkOrderNo: scrutiny[i].WorkOrderNo,
  //           WorkOrderDate: scrutiny[i].WorkOrderDate,
  //           SubContractorBillNoFNC: scrutiny[i].SubContractorBillNoFNC,
  //           SubContractorBillDateFNC: scrutiny[i].SubContractorBillDateFNC,
  //           SubContractorBillNo: scrutiny[i].SubContractorBillNo,
  //           SubContractorBillDate: scrutiny[i].SubContractorBillDate,
  //           Amount: scrutiny[i].Amount,
  //           Scrutinise: scrutiny[i].Scrutinise,
  //           id: scrutiny[i].id,
  //         },
  //       ];
  //     }
  //     setInputFields(values);
  //   }
  // }, [scrutiny]);

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SlNo: 0,
        WorkOrderNo: "",
        WorkOrderDate: null,
        SubContractorBillNoAuto: "",
        SubContractorBillDateAuto: null,
        SubContractorBillNoManual: "",
        SubContractorBillDateManual: null,
        Amount: "",
        id: "",
      }
    ]);
  };
  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    props.tableData(values);
    setInputFields(values);
  };

  const onChange = () => {
    if (inputFields && tableData) {
      tableData(inputFields);
    } else {
      tableData({ error: true, errorKind: "some input field is empty" });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = inputFields;
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange();
  };
  const onDateChangeWorkOrder = (index, value) => {
    const values = [...inputFields];
    values[index].WorkOrderDate = value;
    // values[index].SubContractorBillDate = value;
    setInputFields(values);
  };
  const onDateChangeSubContractorBillDate = (index, value) => {
    const values = [...inputFields];
    //  values[index].WorkOrderDate = value;
    values[index].SubContractorBillDateManual = value;
    setInputFields(values);
  };

   const onDateChangeSubContractorBillDateAuto = (index, value) => {
    const values = [...inputFields];
    //  values[index].WorkOrderDate = value;
    values[index].SubContractorBillDateAuto = value;
    setInputFields(values);
  };
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">Sl. No</th>
            <th className="head-cell">Work Order No</th>
            <th className="head-cell">Work Order Date</th>
            <th className="head-cell">
              Sub Contractor Bill No (FNC Generated)
            </th>
            <th className="head-cell">
              Sub Contractor Bill Date (FNC Generated)
            </th>
            <th className="head-cell">
              Sub Contractor Bill No (Vendor Generated)
            </th>
            <th className="head-cell">
              Sub Contractor Bill Date (Vendor Generated)
            </th>
            <th className="head-cell">Amount</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">
                <input
                  type="text"
                  name="SlNo"
                  defaultValue={index + 1}
                  disabled
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "3rem" }}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="WorkOrderNo"
                  value={inputField.WorkOrderNo}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "15rem" }}
                />
              </td>
              <td className="table-data2">
                <RenderTableDateField
                  name="WorkOrderDate"
                  initialValue={inputField.WorkOrderDate}
                  onChange={(event) => onDateChangeWorkOrder(index, event)}
                  style={{ width: "15rem" }}
                  disableType={""}
                />
              </td>
              <td className="table-data2">
                   <input
                  type="text"
                  name="SubContractorBillNoAuto"
                  value={inputField.SubContractorBillNoAuto}
                  style={{ width: "15rem" }}
                />
              </td>
              <td className="table-data2">
               
                <RenderTableDateField
                  type="text"
                  name="SubContractorBillNoAuto"
                   initialValue={inputField.SubContractorBillDateAuto}
                  onChange={(event) =>
                    onDateChangeSubContractorBillDateAuto(index, event)
                  }
                  style={{ width: "15rem" }}
                  disableType={""}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="SubContractorBillNoManual"
                  value={inputField.SubContractorBillNoManual}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "15rem" }}
                />
              </td>
              <td className="table-data2">
                <RenderTableDateField
                  name="SubContractorBillDateManual"
                  initialValue={inputField.SubContractorBillDateManual}
                  onChange={(event) =>
                    onDateChangeSubContractorBillDate(index, event)
                  }
                  style={{ width: "15rem" }}
                  disableType={""}
                />
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  name="Amount"
                  value={inputField.Amount}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "6rem" }}
                />
              </td>
              {/* <td className="table-data4">
                <input
                  type="text"
                  name="Scrutinise"
                  value={inputField.Scrutinise}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "6rem" }}
                />
              </td> */}
              <td>
                {index === 0 ? (
                  <IconButton>
                    <RemoveCircleIcon className="width5" color="disabled" />
                  </IconButton>
                ) : (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default ScrutinySubContractorTable;
