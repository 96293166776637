import React, { useState, useEffect } from "react";

const ProjectAddRoleView = (props) => {
  const [data, setData] = useState([]);
  const { working } = props;

  useEffect(() => {
    let values = [];
    if (working) {
      values = [...working];
    }
    setData(values);
  }, [working]);

  return (
    <div className="tablecontainer">
      <table>
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Role</th>
            <th>Business Unit</th>
            <th>Company Name</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.ProjectName}</td>
              <td>{row.Role}</td>
              <td>{row.BusinessUnit}</td>
              <td>{row.Company}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ProjectAddRoleView;
