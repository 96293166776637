import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export const AutoCompleteTableInput = ({
  list,
  tableIndex,
  inputChange,
  fieldName,
  value,
  defaultValue,
  ...custom
}) => {
  const onChange = (event, value) => {
    inputChange(value, tableIndex, fieldName);
  };

  return (
    <div {...custom}>
      <Autocomplete
        options={list}
        getOptionLabel={(item) => item}
        onChange={(event, value) => onChange(event, value)}
        renderInput={(params) => (
          <TextField style={{ width: "20rem" }} {...params} />
        )}
        getOptionSelected={(option, value) => {
          return option === value || [0];
        }}
        value={value}
        defaultValue={defaultValue}
      />
    </div>
  );
};
