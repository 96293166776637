import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SalesInvoiceReversal from "../../../forms/SalesAccountingSystem/SalesInvoiceReversalForm";
import axios from "../../../components/apis/axios";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let allInvoices = [];

export const SalesInvoiceReversalCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [list, setList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [certificationValue, setCertificationValue] = useState(0);

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "/salesAccounting/salesInvoiceReversal",
      formValues
    );
    console.log(response);
    history.push("/salesAccounting/salesInvoiceReversal");
  };

  const selectInvoice = (invoice) => {
    console.log(allInvoices);
    let detail = allInvoices.find(
      (item) => item.SalesInvoiceCertificationNo === invoice
    );

    if (detail) {
      setCertificationValue(+detail.CertificationValue);
      detail = {
        ...formDetail,
        InvoiceDate: detail.created_at,
        GrossInvoiceValue: +detail.InvoiceSummary.TotalGrossAmtThisBill,
        RecoveryValue: +detail.InvoiceSummary.TotalRecoveryAmtThisBill,
        NetInvoiceValue: +detail.InvoiceSummary.ClaimAmtThisBill,
      };
      setformDetail(detail);
    }
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectCode + ' - ' + item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      try {
        const { data } = await axios.post(
          `salesAccounting/salesInvoiceCertification/getInvoices`,
          {
            ProjectCodeName: detail.ProjectShortDescription,
          }
        );
        allInvoices = data;
        const newData = [
          ...new Map(data.map((item) => [item["ProjectCode"], item])).values(),
        ];
        const mapped = newData.map((item) => item.SalesInvoiceCertificationNo);
        setInvoiceList(mapped);
      } catch (err) {
        console.log(err);
      }
      setformDetail(detail);
    }
  };

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);
  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      allProject = store.GeneralRoot.AllProjectName;
      const newData = [
        ...new Map(allProject.map((item) => [item["ProjectCode"], item])).values(),
      ];
      let approvedList = newData.filter(
        (item) => item.Status === "accepted"
      );
      console.log(approvedList);
      let PNList = approvedList.map((item) =>item.ProjectCode + ' - ' + item.ProjectShortDescription);
      setList(PNList);
    }
  }, [store.GeneralRoot.AllProjectName]);

  return (
    <div>
      <BreadCrumbs
        name="Sales Invoice Reversal Create"
        linkName1="Sales Invoice Reversal Dashboard"
        to1="/salesAccounting/salesInvoiceReversal"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Invoice Reversal
      </h3>
      <SalesInvoiceReversal
        onSubmit={onSubmit}
        list={list}
        invoiceList={invoiceList}
        selectProjectName={selectProjectName}
        selectInvoice={selectInvoice}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "NetInvoiceValue",
          "RecoveryValue",
          "GrossInvoiceValue",
          "InvoiceDate",
          "InvoiceNo"
        )}
        certificationValue={certificationValue}
        edit={false}
      />
    </div>
  );
};
