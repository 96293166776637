import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SCItemCodeRequest from "../../../forms/CommonMaster/SCItemCodeRequest";
import { scItemCodeRequest } from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import pick from "lodash/pick";

let AllMajorSCItem = [];
export const SCItemCodeRequestCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [UOM, setUOM] = useState([]);
  const [majorSCItem, setMajorSCItem] = useState([]);
  const [formDetail, setFormDetail] = React.useState({});

  const onSubmit = (formValues) => {
    dispatch(scItemCodeRequest(formValues, history));
  };

  const SelectMajorSCItem = async (MajorSCItemGroupCode) => {
    const item = AllMajorSCItem.find(
      (item) => item.MajorSCItemGroupCode === MajorSCItemGroupCode
    );
    setFormDetail({
      ...formDetail,
      MajorSCItemGroupCode: item.MajorSCItemGroupCode,
      MajorSCItemGroupDescription: item.MajorSCItemGroupCodeDescription,
    });
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        let unitOfMeasurement = data.map(
          (item) => item.UOMCode + "-" + item.UOMDescription
        );
        setUOM(unitOfMeasurement);
      } catch (err) {}
    }
    async function MajorSCItem() {
      try {
        const { data } = await axios.get("/commonMaster/MajorSCItemGroup");
        let mapedData = data.map((item) => item.MajorSCItemGroupCode);

        setMajorSCItem(mapedData);
        AllMajorSCItem = data;
      } catch (e) {
        console.log(e);
      }
    }
    MajorSCItem();
    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="SC Item Create"
        linkName1="SC Item Dashboard"
        to1="/commonMaster/scItemCodeRequest"
      />
      <h3 style={{ textAlign: "center" }}>SC Item Code Request</h3>
      <SCItemCodeRequest
        onSubmit={onSubmit}
        UOM={UOM}
        majorSCItem={majorSCItem}
        SelectMajorSCItem={SelectMajorSCItem}
        initialValues={pick(
          formDetail,
          "MajorSCItemGroupCode",
          "MajorSCItemGroupDescription"
        )}
      />
    </div>
  );
};
