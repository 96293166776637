import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MaterialGroup from "../../../forms/CommonMaster/MaterialGroup";
import { materialGroup } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MaterialGroupCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(materialGroup(formValues, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Material Group Create"
        linkName1="Material Group Dashboard"
        to1="/commonMaster/materialGroup"
      />
      <h3 style={{ textAlign: "center" }}>Material Group</h3>
      <MaterialGroup onSubmit={onSubmit} />
    </div>
  );
};
