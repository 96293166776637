import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HurdleReport from "../../../forms/ExecutionSystem/HurdleReport";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];
export const HurdleReportCreate = () => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "/executionSystem/hurdleRegister",
      formValues
    );
    console.log(response);
    history.push("/executionSystem/hurdleRegister");
  };
  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProjects.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail.id}`
        );

        detail = {
          ...formDetail,
          ProjectCodeName: data.ProjectShortDescription,
          CustomerName: data.CustomerName,
        };

        setFormDetail(detail);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        console.log(data);
        let filteredData = data.filter((item) => item.Status === "accepted");
        let mapedData = filteredData.map(
          (item) => item.ProjectShortDescription
        );
        setProjectList(mapedData);
        allProjects = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Hurdle Register Create"
        linkName1="Hurdle Register Dashboard"
        to1="/executionSystem/hurdleRegister"
      />

      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Hurdle Register
      </h3>
      <HurdleReport
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        initialValues={pick(formDetail, "CustomerName", "ProjectCodeName")}
      />
    </div>
  );
};
