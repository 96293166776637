import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../apis/axios";
const SearchOptionCustomer = ({ handleCustomerChange }) => {
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    const fetchCutomer = async () => {
      const { data } = await axios.get("/commonMaster/customer");
      setCustomer(data);
    };
    fetchCutomer();
  }, []);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={customer}
        getOptionLabel={(item) => item.CustomerName}
        onChange={(event, values) => handleCustomerChange(values?.CustomerName)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Customer"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchOptionCustomer;
