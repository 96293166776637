import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "../../../components/apis/axios";
import { ExportCSV } from "../../../components/Exportexcel/Exportexcel";
import { readAllFromQueryParams } from "../../../utils/queryParams";

const ExportProjectCircular = ({ fileName }) => {
  const { search } = useLocation();
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    const params = readAllFromQueryParams(search);
    delete params.page;
    axios
      .get("/salesAccounting/projectCircular", {
        params,
      })
      .then((res) => {
        let response = [
          ...new Map(
            res.data.map((item) => [item["ProjectCode"], item])
          ).values(),
        ];
        response = response.map((row, index) => ({
          "SI. No.": index + 1,
          "Business Unit": row.BusinessUnit,
          "Direct/Sub": row.DirectSub,
          "Project Code": row.ProjectCode,
          "Project Name": row.ProjectLongDescription,
          "Project Address": row.DeliveryAddress,
          "Project Short Name": row.ProjectShortDescription,
          "Customer Name": row.CustomerName,
          "Project Order No": row.OrderReferenceNo,
          Date: row.OrderReferenceDate,
          Currency: row.Currency,
          "Project Amount": row.ProjectOrderValue,
          "Project Start Date": moment(row.created_at).format("DD-MM-YYYY"),
          "Project End Date": moment(row.ProjectEndDate).format("DD-MM-YYYY"),
          "Project Duration": row.Duration,
          "Customer GST No.": row.CustomerGstNo,
          "Company GST No.": row.CompanyGstNo,
          "Project Status": row.projectStatus,
        }));

        setExportData(response);
      })
      .catch((err) => console.log(err));
  }, [search]);

  return <ExportCSV csvData={exportData} fileName={fileName} variant="text" />;
};

export default ExportProjectCircular;
