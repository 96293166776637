import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import {
  EmployeeJoinDashboard,
  EmployeeJoinReportDashboard,
  EmployeeReleaseDashboard,
  HrDailyAttendanceDashboard,
  OvertimeEntryDashboard,
  TransferOrder,
} from "../../pages/ProjectHrManagement/DashBoard";
import {
  EmployeeJoinCreate,
  EmployeeJoinReportCreate,
  EmployeeReleaseCreate,
  HrDailyAttendanceCreate,
  TransferOrderCreate,
} from "../../pages/ProjectHrManagement/Create";
import {
  EmployeeJoinEdit,
  EmployeeJoinReportEdit,
  EmployeeReleaseEdit,
  HrDailyAttendancEdit,
  OvertimeEntryEdit,
  TransferOrderEdit,
} from "../../pages/ProjectHrManagement/Edit";
import {
  EmployeeJoinReportView,
  EmployeeJoinView,
  EmployeeReleaseView,
  HrDailyAttendanceView,
  TransferOrderView,
} from "../../pages/ProjectHrManagement/View";
import { OvertimeEntryCreate } from "../../pages/ProjectHrManagement/Create/OvertimeEntryCreate";
import { OvertimeEntryView } from "../../pages/ProjectHrManagement/View/OvertimeEntryView";

const routes = [
  {
    path: "/project-hr-management/employee-join",
    component: EmployeeJoinDashboard,
  },
  {
    path: "/project-hr-management/employee-join/create",
    component: EmployeeJoinCreate,
  },
  {
    path: "/project-hr-management/employee-join/edit/:id",
    component: EmployeeJoinEdit,
  },
  {
    path: "/project-hr-management/employee-join/view/:id",
    component: EmployeeJoinView,
  },
  {
    path: "/project-hr-management/daily-attendance",
    component: HrDailyAttendanceDashboard,
  },
  {
    path: "/project-hr-management/daily-attendance/create",
    component: HrDailyAttendanceCreate,
  },
  {
    path: "/project-hr-management/daily-attendance/edit/:id",
    component: HrDailyAttendancEdit,
  },
  {
    path: "/project-hr-management/daily-attendance/view/:id",
    component: HrDailyAttendanceView,
  },
  {
    path: "/project-hr-management/transfer-order",
    component: TransferOrder,
  },
  {
    path: "/project-hr-management/transfer-order/create",
    component: TransferOrderCreate,
  },
  {
    path: "/project-hr-management/transfer-order/edit/:id",
    component: TransferOrderEdit,
  },
  {
    path: "/project-hr-management/transfer-order/view/:id",
    component: TransferOrderView,
  },
  {
    path: "/project-hr-management/employee-join-report",
    component: EmployeeJoinReportDashboard,
  },
  {
    path: "/project-hr-management/employee-join-report/create",
    component: EmployeeJoinReportCreate,
  },
  {
    path: "/project-hr-management/employee-join-report/edit/:id",
    component: EmployeeJoinReportEdit,
  },
  {
    path: "/project-hr-management/employee-join-report/view/:id",
    component: EmployeeJoinReportView,
  },
  {
    path: "/project-hr-management/employee-release",
    component: EmployeeReleaseDashboard,
  },
  {
    path: "/project-hr-management/employee-release/create",
    component: EmployeeReleaseCreate,
  },
  {
    path: "/project-hr-management/employee-release/edit/:id",
    component: EmployeeReleaseEdit,
  },
  {
    path: "/project-hr-management/employee-release/view/:id",
    component: EmployeeReleaseView,
  },
  {
    path: "/project-hr-management/overtime-entry/:id",
    component: OvertimeEntryDashboard,
  },
  // {
  //   path: "/project-hr-management/overtime-entry/create",
  //   component: OvertimeEntryCreate,
  // },
  // {
  //   path: "/project-hr-management/overtime-entry/edit/:id",
  //   component: OvertimeEntryEdit,
  // },
  // {
  //   path: "/project-hr-management/overtime-entry/view/:id",
  //   component: OvertimeEntryView,
  // },
];

const ProjectHrManagementRoutes = (
  <Switch>
    {routes.map((route, i) => (
      <Route key={i} exact {...route} />
    ))}
  </Switch>
);

export default ProjectHrManagementRoutes;
