import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import { useHistory } from "react-router-dom";
import { approvalMachineManagement } from "../../../redux/actions/MachineManagementaction";

import LogSheetEntryTableView from "../../../Tables/MachineManagementSystemTable/View/LogSheetEntryTableView";
import RoleList from "../../../components/helpers/RoleList";

import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const LogSheetEntryView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/machineManagement/logSheetEntry/${match.params.id}`
        );
        let data = response.data;
        console.log("data-->", data);
        data.LogSheetDate = new Date(
          new Date(data.LogSheetDate) + " UTC"
        ).toDateString();

        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  const handleModalAction = (data) => {
    dispatch(
      approvalMachineManagement("logSheetEntry", match.params.id, history, data)
    );
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const classes = useStyle();
  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Log Sheet Entry View"
        linkName1="Log Sheet Entry Dashboard"
        to1="/machineManagement/logSheetEntry"
      />
      <Paper className={classes.Container} component={Paper}>
        <div className="">
          <ComapnyHeader />
          <h3 className={classes.Heading}>Log Sheet Entry</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Code / Name:</h6>
              <p>{data.MachineCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Shift Hour:</h6>
              <p>{data.ShiftHour}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Log Sheet Data:</h6>
              <p>{data.LogSheetDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Start Meter Reading:</h6>
              <p>{data.StartMeterReading}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>End Meter Reading:</h6>
              <p>{data.EndMeterReading}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Working Hour:</h6>
              <p>{data.WorkingHour}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Break Down Hour​:</h6>
              <p>{data.BreakDownHour}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Marching Hour:</h6>
              <p>{data.MarchingHour}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Idle Hour:</h6>
              <p>{data.IdleHour}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Remarks:</h6>
              <p>{data.remarks}</p>
            </Paper>
          </div>
          <div style={{ paddingLeft: "10%" }}>
            <LogSheetEntryTableView tableData={data.ActivityHours} />
          </div>
          {data.Files && (
            <Paper className={classes.paper}>
              <h6>Files:</h6>
              <FileListArea fileList={data.Files} />
            </Paper>
          )}
          <div className={classes.row}>
            {data.Comment && (
              <Paper className={classes.paper}>
                <h6 style={{ color: "red" }}>Comment:</h6>
                <p>{data.Comment}</p>
              </Paper>
            )}
          </div>
        </Box>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code) &&
        (data.Status === "not_reviewed" || data.Status === "rejected") && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Log sheet entry"}
      />
    </div>
  );
};
