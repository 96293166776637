import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const BusinessUnit = (props) => {
  const [show, setShow] = useState(false);


  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="CompanyName"
            label="Company Name"
            list={props.company}
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="BusinessUnit"
            label="Business Unit"
          />
          <Field
            component={Control.RenderTextField}
            name="BusinessDescription"
            label="Business Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Business unit"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "BusinessUnit",
    "BusinessDescription",
    "CompanyName",
    "Tag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "BusinessUnit",
  validate,
  enableReinitialize: true,
})(BusinessUnit);
