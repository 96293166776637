import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import { searchEmployeeJoinReportById } from "../../../redux/actions/HRManagementActions";
import EmployeeJoinReport from "../../../forms/ProjectHRManagement/EmployeeJoinReport/EmployeeJoinReport";
import moment from "moment";

export const EmployeeJoinReportEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchEmployeeJoinReportById(match.params.id);
        setData(data);
    }

    fetch();
  }, [match.params.id]);

  const onSubmit = async (formValues) => {
    await axios.put(`/hr-management/employee-join-report/${match.params.id}`, formValues);
    history.push("/project-hr-management/employee-join-report");
  };

  const getSelectedProject = (project) => {
    if(project) {
      return `${project.ProjectCode} - ${project.ProjectShortDescription}`;
    }
    return "";
  }

  const getTransferOrder = (transferOrder) => {
    if(transferOrder) {
      return transferOrder.transferOrderCode;
    }
    return "";
  }

  const getSelectedEmployee = (employee) => {
    if(employee) {
      return `${employee.EmployeeCode} - ${employee.FirstName} ${employee.LastName}`;
    }
    return "";
  }

  return (
    <div>
      <Breadcrumbs
        name="Employee Join Edit"
        linkName1="Employee Join Dashboard"
        to1="/project-hr-management/transfer-order"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Employee Join
      </h3>
      <div className="line"></div>
      <EmployeeJoinReport
        initialValues={{
            ...data,
            functionalRole: data?.transferOrder?.functionalRole,
            employee: getSelectedEmployee(data?.transferOrder?.employee?.employee),
            reportEmployee: getSelectedEmployee(data?.transferOrder?.reportEmployee?.employee),
            transferOrderDate: moment(data?.transferOrder?.created_at).format("DD-MM-YYYY"),
            costCentre: data?.project?.CostCentre,
            customerName: data?.project?.CustomerName,
            project: getSelectedProject(data?.project),
            transferOrder: getTransferOrder(data?.transferOrder),
            projectData: data?.project,
            transferOrderData: data?.transferOrder
        }}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
