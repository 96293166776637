import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export const GoodsInspectionTableView = props => {
  const [data, setData] = useState([]);
  const { materials } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);
  return (
    <>
      <div className="tableContainer">
        <table>
          <thead className="table-head">
            <tr>
              <th align="center" className="head-cell">
                SerialNo
              </th>
              <th align="center" className="head-cell">
                MaterialCode
              </th>
              <th align="center" className="head-cell">
                MaterialDescription
              </th>
              <th align="center" className="head-cell">
                HSNSACCode
              </th>
              <th align="center" className="head-cell">
                UOM
              </th>
              <th align="center" className="head-cell">
                Received QTY
              </th>
              <th align="center" className="head-cell">
                Accept QTY
              </th>
              <th align="center" className="head-cell">
                Rejected QTY
              </th>
              <th align="center" className="head-cell">
                Reason for Rejection
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {data.map((row, index) => (
              <tr key={index}>
                <td align="center"  className="table-data1">{row.SerialNo}</td>
                <td align="center"  className="table-data2">{row.MaterialCode}</td>
                <td align="center"  className="table-data2">{row.MaterialDescription}</td>
                <td align="center"  className="table-data2">{row.HSNSACCode}</td>
                <td align="center"  className="table-data2">{row.UOM}</td>
                <td align="center"  className="table-data2">{row.ReceivedQuantity}</td>
                <td align="center"  className="table-data2">{row.AcceptQuantity}</td>
                <td align="center"  className="table-data2">{row.RejectedQuantity}</td>
                <td align="center"  className="table-data4">{row.ReasonForRejection}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </>
  );
};
