import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachinePhysicalVerification from "../../../forms/MachineManagementSystem/MachinePhysicalVerification";
import { machinePhysicalVerification } from "../../../redux/actions/MachineManagementaction";

export const MachinePhysicalVerificationCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machinePhysicalVerification(formValues, history));
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3 style={{ textAlign: "center" }}>Machine Physical Verification</h3>
      <MachinePhysicalVerification onSubmit={onSubmit} />
    </div>
  );
};
