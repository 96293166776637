import React, { useState, useEffect } from "react";
import Table from "../../Tables/AccountingSystem/CurrencyTable";
import { Field, change } from "redux-form";
import * as Control from "../formcomponents/control";
import axios from "../apis/axios";
import { useSnackbar } from "notistack";
import "../helpers/form.style.css";

const DisbursementFormComponent = ({
  handleCurrencyChange,
  initialValues,
  headerState,
  paymentDetails,
  props,
  formName,
}) => {
  const [selection, setSelection] = useState("bank");
  const [isAdvance, setIsAdvance] = useState(false);
  const [advance, setAdvance] = useState(0);
  const [adjustAdvance, setAdjustAdvance] = useState(false);
  const [systemBalance, setSystemBalance] = useState(null);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);
  const [bankList, setBankList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const onAdvanceAdjustChange = (value) => {
    if (value === "Yes") setAdjustAdvance(true);
  };

  const calculateAdvanceAfterAdjustment = (disbursedAmt) => {
    if (isAdvance) {
      let currentAdvance = advance;
      if (adjustAdvance) currentAdvance = +currentAdvance - +disbursedAmt;
      else currentAdvance = +currentAdvance + +disbursedAmt;
      props.dispatch(
        change(formName, "AdvanceAfterAdjustment", String(currentAdvance))
      );
    }
  };

  const handleCurrencyChangeMiddleWare = (data) => {
    handleCurrencyChange(data);
    calculateAdvanceAfterAdjustment(data.TotalCash);
  };

  const onPaymentModeChange = (value) => {
    setSelection(value);

    if (value === "cash" && systemBalance) {
      props.dispatch(
        change(formName, "AvailableCashBalance", systemBalance?.TotalCash)
      );
    }
  };

  const onBankSelect = (value) => {
    const { BankDetails } = systemBalance;
    const bank = BankDetails.find((item) => item.BankName === value);
    setSelectedBankDetails(bank);
    props.dispatch(change(formName, "AvailableBankBalance", bank?.Balance));
  };

  const validateDisbursedValue = (value) => {
    if (selectedBankDetails) {
      // check disbursed amt to be less than bank balance
      if (+selectedBankDetails.Balance >= +value) {
        calculateAdvanceAfterAdjustment(value);
      } else {
        enqueueSnackbar(
          `Disbursed Amount entered is more than selected Bank Balance. Enter Amount less than ${selectedBankDetails.Balance}`,
          {
            variant: "info",
          }
        );
        props.dispatch(change(formName, "DisbursedValue", 0));
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (headerState?.CostCentre !== "") {
        const {
          data: balance,
        } = await axios.post(
          "accountingSystem/shared/getSystemParamBalanceByCostCentre",
          { CostCentre: headerState?.CostCentre }
        );
        setSystemBalance(balance);
        const { BankDetails } = balance;
        const mapped = BankDetails.map((item) => item.BankName);
        setBankList(mapped);

        if (props?.initialValues?.FromBank) {
          const bank = BankDetails.find(
            (item) => item.BankName === props?.initialValues?.FromBank
          );
          setSelectedBankDetails(bank);
        }
      }
    };
    getData();
  }, [headerState?.CostCentre, props?.initialValues?.FromBank]);

  useEffect(() => {
    if (paymentDetails) {
      const checkIfRequestIsAdvance = async () => {
        const natureOfExpense = paymentDetails[0].NatureOfExprensesOrAccounting;
        let pattern = /ADVANCE/gi;
        const result = natureOfExpense.match(pattern);
        if (result) {
          setIsAdvance(true);
          // getting account details
          try {
            let response;
            if (props.initialValues.PaymentFor === "employee") {
              response = await axios.post(
                "/accountingSystem/shared/getEmployeeAccountDetials",
                {
                  EmployeeCodeName: props.initialValues.EmployeeCodeName,
                }
              );
            } else if (props.initialValues.PaymentFor === "vendor") {
              response = await axios.post(
                "/accountingSystem/shared/getVendorAccountDetials",
                {
                  VendorCodeName: props.initialValues.VendorCodeName,
                }
              );
            } else if (props.initialValues.PaymentFor === "others") {
              response = await axios.post(
                "/accountingSystem/shared/getOthersAccountDetials",
                {
                  PAN: props.initialValues.PAN,
                }
              );
            }
            props.dispatch(
              change(formName, "AdvanceAsPerBooks", response?.data.Credit)
            );
          } catch (err) {
            console.error(err);
          }
        } else setIsAdvance(false);
      };
      checkIfRequestIsAdvance();
    }
  }, [paymentDetails, props, formName]);

  return (
    <>
      <div className="field-container">
        {isAdvance && (
          <>
            <Field
              name="AdvanceAsPerBooks"
              label="Advance as per Books"
              component={Control.RenderTextField}
              disabled
              hidden={!isAdvance}
              onChange={(event) => setAdvance(event.target.value)}
            />
            {advance > 0 && (
              <Field
                name="AdvanceAdjusted"
                component={Control.AutoCompleteInput}
                label="Advance Adjusted"
                className="text-field"
                list={["", "Yes", "no"]}
                onChange={onAdvanceAdjustChange}
              />
            )}
          </>
        )}
        <Field
          name="IfPaymentMode"
          component={Control.AutoCompleteInput}
          label="Payment Mode"
          className="text-field"
          list={["", "bank", "cash"]}
          onChange={onPaymentModeChange}
        />
        {selection === "bank" ? (
          <>
            <Field
              name="FromBank"
              label="From Bank"
              list={bankList}
              className="text-field"
              component={Control.AutoCompleteInput}
              onChange={onBankSelect}
            />
            <Field
              name="AvailableBankBalance"
              label="Available Balance"
              component={Control.RenderTextField}
              disabled
            />
            <Field
              name="DisbursedValue"
              label="Disbursed Value"
              component={Control.RenderTextField}
              validate={[validateDisbursedValue]}
            />
            <Field
              name="Cheque_NEFT_RTGS_IMPS_No"
              component={Control.RenderTextField}
              label="Cheque/NEFT/RTGS/IMPS No."
            />
            <Field
              name="Date"
              label="Date"
              component={Control.RenderDateField}
              initialValue={initialValues?.Date}
            />
          </>
        ) : (
          <>
            <Field
              name="AvailableCashBalance"
              label="Available Balance"
              component={Control.RenderTextField}
              disabled
            />
            <div className="middle">
              <div>
                <Table
                  handleCurrencyChange={handleCurrencyChangeMiddleWare}
                  systemBalance={systemBalance}
                />
              </div>
            </div>
            <Field
              name="NarrationCash"
              label="Narration"
              component={Control.RenderTextField}
            />
          </>
        )}
        {isAdvance && (
          <Field
            name="AdvanceAfterAdjustment"
            label="Advance After Adjustment"
            component={Control.RenderTextField}
            disabled
          />
        )}
      </div>
    </>
  );
};

export default DisbursementFormComponent;
