import React from "react";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useEffect } from "react";
import { createTransferOrder } from "../../../redux/actions/HRManagementActions";
import TransferOrder from "../../../forms/ProjectHRManagement/TransferOrder/TransferOrder";

export const TransferOrderCreate = () => {
  const history = useHistory();

  const onSubmit = (formValues) => {
    console.log({formValues});
    createTransferOrder(formValues, history);
  };

  useEffect(() => {
    // 
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Transfer Order Create"
        linkName1="Transfer Order Dashboard"
        to1="/project-hr-management/transfer-order"
      />
      <FormHeading label="Transfer Order" />
      <TransferOrder
        onSubmit={onSubmit}
      />
    </div>
  );
};
