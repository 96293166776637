import axios from "../../components/apis/axios";

export const approvalAccountingSystem = (
  link,
  id,
  history,
  req,
  enqueueSnackbar
) => async (dispatch) => {
  try {
    
    const { data } = await axios.put(
      `/accountingSystem/${link}/approval/${id}`,
      req
    );
    console.log(JSON.stringify("RES",req));

    if (data.success === true) {
      enqueueSnackbar(data.msg, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(data.msg, {
        variant: "warning",
      });
    }

    console.log(data);
    history.push(`/accountingSystem/${link}`);
  } catch (error) {
    alert(error);
  }
};

//CREATE
export const paymentRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineReceipt", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineReceipt");
    } catch (error) {
      alert(error);
    }
  };
};
