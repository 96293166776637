import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import GrainIcon from "@material-ui/icons/Grain";
import Paper from "@material-ui/core/Paper";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    color: "black",
    textDecoration: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  table: {
    width: "90%",
    margin: "30px auto",
    paddingBottom: "0",
    display: "flex",
    justifyContent: "space-between",
  },
  backBtn: {
    marginRight: 15,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default function IconBreadcrumbs({
  name,
  linkName1,
  to1,
  linkName2,
  to2,
  showBackButton,
}) {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div style={{ marginTop: "8rem" }}>
      <Paper className={classes.table}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ padding: "10px" }}
          maxItems={2}
        >
          <Link color="inherit" to="/dashboard" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Dashboard
          </Link>
          {linkName2 && (
            <Link color="inherit" to={to2} className={classes.link}>
              <WhatshotIcon className={classes.icon} />
              {linkName2}
            </Link>
          )}
          {linkName1 && (
            <Link color="inherit" to={to1} className={classes.link}>
              <WhatshotIcon className={classes.icon} />
              {linkName1}
            </Link>
          )}
          <Typography color="textPrimary" className={classes.link}>
            <GrainIcon className={classes.icon} />
            {name}
          </Typography>
        </Breadcrumbs>
        {(showBackButton || !!to1) && (
          <span onClick={handleBack} className={classes.backBtn}>
            <ArrowBack />
          </span>
        )}
      </Paper>
    </div>
  );
}
