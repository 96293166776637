import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Account from "../../../forms/CommonMaster/Account";
import { useHistory } from "react-router-dom";
import {
  editAccount,
  searchAccountById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

// let AllActivity = [];

export const AccountEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [activity, setActivity] = React.useState([]);
  const { match } = props;
  useEffect(() => {
    dispatch(searchAccountById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleAccount) {
      setData(store.SingleAccount);
    }
  }, [store.SingleAccount]);
  const onSubmit = (formValues, history) => {
    dispatch(editAccount(formValues, props.match.params.id, history));
  };
  React.useEffect(() => {
    async function activity() {
      try {
        const { data } = await axios.get("/commonMaster/Activity");
        let mapedData = data.map((item) => item.ActivityCode);
        console.log(data);
        setActivity(mapedData);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    activity();
  }, []);
  console.log('account data-->',data);
  return (
    <div>
      <Breadcrumbs
        name="Account Edit"
        linkName1="Account Dashboard"
        to1="/commonMaster/Account"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Account Edit
      </h3>
      <div className="line"></div>
      <Account
        activity={activity}
        initialValues={pick(
          data,
          "AccountCode",
          "AccountDes",
          "AccountType",
          "OtherIncome",
          "AccountSubType",
          "Ledger",
          "ActivityCode",
          "ActivityDes",
          "CostHead",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
