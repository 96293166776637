import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Receipt/ReceiptCustomerForm";
import axios from "../../../../components/apis/axios";
import { getCurrentFinancialYear } from "../../../../components/helpers/FinancialPeriod";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

export const ReceiptCustomerCreate = () => {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [formDetail, setformDetail] = useState({});

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "/accountingSystem/receiptCustomerInvoice",
      formValues
    );
    console.log(response);
    history.push("/accountingSystem/receiptCustomerInvoice/");
  };

  async function getData() {
    try {
      const { data: customer } = await axios.get("/commonMaster/customer/");
      const mapped = customer.map((item) => item.CustomerName);
      setCustomers(mapped);
      const { data: bank } = await axios.get("/commonMaster/bank");
      const banklist = bank.map(
        (item) => item.BankCode + " - " + item.BankName
      );
      setBankList(banklist);
      setformDetail({
        FinancialPeriod: getCurrentFinancialYear(),
      });
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    try {
      getData();
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Payment Receipt - Customer Create"
        linkName1="Payment Receipt - Customer Dashboard"
        to1="/accountingSystem/receiptCustomerInvoice"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Receipt - Customer
      </h3>
      <Form
        onSubmit={onSubmit}
        customers={customers}
        bankList={bankList}
        initialValues={pick(formDetail, "FinancialPeriod")}
      />
    </div>
  );
};
