import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchMachineReceiptById,
  approvalMachineManagement,
} from "../../../redux/actions/MachineManagementaction";
import useStyle from "./UseStyle";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MachineReceiptView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchMachineReceiptById(match.params.id));
  }, [match.params.id, dispatch]);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/machineManagement/machineReceipt/${match.params.id}`
        );
        let data = response.data;
        console.log(data);
        data.PurchaseDate = new Date(
          new Date(data.PurchaseDate) + " UTC"
        ).toDateString();
        data.DateofCommissioning = new Date(
          new Date(data.DateofCommissioning) + " UTC"
        ).toDateString();
        data.LastAcqsDate = new Date(
          new Date(data.LastAcqsDate) + " UTC"
        ).toDateString();
        data.DateofReceipt = new Date(
          new Date(data.DateofReceipt) + " UTC"
        ).toDateString();

        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  const classes = useStyle();

  const handleModalAction = (data) => {
    dispatch(
      approvalMachineManagement(
        "machineReceipt",
        match.params.id,
        history,
        data
      )
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Machine Receipt View"
        linkName1="Machine Receipt Dashboard"
        to1="/machineManagement/machineReceipt"
      />
      <Paper className={classes.Container}>
        <div className="">
          <ComapnyHeader />
          <h3 className={classes.Heading}>Machine Receipt</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Code / Name:</h6>
              <p>{data.MachineCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Last Project Code / Name:</h6>
              <p>{data.LastProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Date of Receipt:</h6>
              <p>{data.DateofReceipt}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Date of Commissioning:</h6>
              <p>{data.DateofCommissioning}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine IHC Rate:</h6>
              <p>{data.MachineIHCRate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase Value:</h6>
              <p>{data.PurchaseValue}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Acquisition Value:</h6>
              <p>{data.AcquisitionValue}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Meter Flag​:</h6>
              <p>{data.MeterFlag}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Book Value:</h6>
              <p>{data.BookValue}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase Date:</h6>
              <p>{data.PurchaseDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Last Acqs Date:</h6>
              <p>{data.LastAcqsDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Minor / Major:</h6>
              <p>{data.MinorMajor}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Meter Type​:</h6>
              <p>{data.MeterType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Shift Hours:</h6>
              <p>{data.ShiftHours}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Last Meter Reading​:</h6>
              <p>{data.LastMeterReading}</p>
            </Paper>
          </div>
          <div>
            {data.Files && data.Files.length > 0 && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
          </div>
          <div className={classes.row}>
            {data.Comment && (
              <Paper className={classes.paper}>
                <h6 style={{ color: "red" }}>Comment:</h6>
                <p>{data.Comment}</p>
              </Paper>
            )}
          </div>
        </Box>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}

      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"machine Receipt"}
      />
    </div>
  );
};
