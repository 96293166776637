import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "../UseStyle";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import axios from "../../../../components/apis/axios";
import { userLogout } from "../../../../redux/actions/userAction";
import { approvalAccountingSystem } from "../../../../redux/actions/AccountingSystemAction";
import Table from "../../../../Tables/AccountingSystem/View/ScrutinySubContractorTableView";
import ApprovalAndRejectModal from "../../../../components/modals/ApprovalAndRejectModal";
import ComapnyHeader from "../../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import RoleList from "../../../../components/helpers/RoleList";

export const JournalSubContractorView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/journalSubContractor/${match.params.id}`
        );
        console.log(data);
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [match.params, dispatch, history]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalAccountingSystem(
        "journalSubContractor",
        match.params.id,
        history,
        data,
        enqueueSnackbar
      )
    );
  };
  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name="Journal Sub-Contractor View"
        linkName1="Journal Sub-Contractor Dashboard"
        to1="/accountingSystem/journalSubContractor"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}> Journal SubSupplier </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by &&
              ` ${data.created_by.first_name} ${data.created_by.last_name}`
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>journalSubContractorNo:</h6>
                  <p>{data.journalSubContractorNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>CostCentre:</h6>
                  <p>{data.CostCentre}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>FinancialPeriod:</h6>
                  <p>{data.FinancialPeriod}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Currency:</h6>
                  <p>{data.Currency}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>ProjectCodeName:</h6>
                  <p>{data.ProjectCodeName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>CustomerName:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>From Date:</h6>
                  <p>{format(new Date(data.FromDate), "d MMM yyyy")}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>To Date:</h6>
                  <p>{format(new Date(data.ToDate), "d MMM yyyy")}</p>
                </Paper>
              </div>
              <div style={{ paddingLeft: "10%" }}>
                {data.tableData && (
                  <>
                    <h4>Payment Details</h4>
                    <Table tableData={data.tableData} />
                  </>
                )}
              </div>
            </Box>
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>
            {(post === RoleList.ADMIN.code ||
              post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"journal Sub-Contractor"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
