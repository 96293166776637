import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export const SalesOrderTableView = (props) => {
  const [data, setData] = useState([]);
  const { materials } = props;

  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th rowSpan="2">Sl. No</th>
            <th rowSpan="2">BOQ No</th>
            <th rowSpan="2">Heading BOQ No</th>
            <th rowSpan="2">Item Description</th>
            <th rowSpan="2">UOM</th>
            <th rowSpan="2">HSN / SAC Code</th>
            <th rowSpan="2">Quantity</th>
            <th rowSpan="2">Rate</th>
            <th rowSpan="2">Discount</th>
            <th rowSpan="2">Net Rate</th>
            <th rowSpan="2">Net Amount</th>
            <th colSpan="2">C-GST</th>
            <th colSpan="2">S-GST</th>
            <th colSpan="2">I-GST</th>
            <th rowSpan="2">Total GST</th>
            <th rowSpan="2">Item Total</th>
          </tr>
          <tr>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
            <th>%</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">{row.BOQNo}</td>
              <td className="table-data2">{row.HeadingBOQNo}</td>
              <td className="table-data2">{row.ItemDescription}</td>
              <td className="table-data2">{row.UOM}</td>
              <td className="table-data2">{row.HSNSACCode}</td>
              <td className="table-data2">{row.Quantity}</td>
              <td className="table-data2">{row.Rate}</td>
              <td className="table-data2">{row.Discount}</td>
              <td className="table-data2">{row.NetRate}</td>
              <td className="table-data2">{row.NetAmount}</td>
              <td className="table-data2">{row.CGSTPercentage}</td>
              <td className="table-data2">{row.CGSTValue}</td>
              <td className="table-data2">{row.SGSTPercentage}</td>
              <td className="table-data2">{row.SGSTValue}</td>
              <td className="table-data2">{row.IGSTPercentage}</td>
              <td className="table-data2">{row.IGSTValue}</td>
              <td className="table-data2">{row.TotalGST}</td>
              <td className="table-data4">{row.ItemTotal}</td>
            </tr>
          ))}
        </tbody>
        {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
      </table>
    </div>
  );
};
