import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Activity from "../../../forms/CommonMaster/Activity";
import { activity } from "../../../redux/actions/CommonMasterAction";
import { allUnitOfMeasurement } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllUOMs = [];
export const ActivityCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [UOMCodeList, setUOMCodeList] = useState([]);
  const store = useSelector((store) => store.commonMasterSystemRoot);

  const onSubmit = (formValues) => {
    dispatch(activity(formValues, history));
  };

  useEffect(() => {
    dispatch(allUnitOfMeasurement());
  }, [dispatch]);
  useEffect(() => {
    if (store.AllUnitOfMeasurement) {
      AllUOMs = store.AllUnitOfMeasurement;
      let company = AllUOMs.map((item) => item.UOMCode);
      setUOMCodeList(company);
    }
  }, [store.AllUnitOfMeasurement]);

  return (
    <div>
      <Breadcrumbs
        name="Activity Create"
        linkName1="Activity Dashboard"
        to1="/commonMaster/Activity"
      />
      <FormHeading label="Activity" />
      <Activity UOMCodeList={UOMCodeList} onSubmit={onSubmit} />
    </div>
  );
};
