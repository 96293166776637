import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SyatemParameter from "../../../forms/AccountingSystem/SystemParameter";
import axios from "../../../components/apis/axios";
import { getCurrentFinancialYear } from "../../../components/helpers/FinancialPeriod";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SystemParameterFormCreate = () => {
  const history = useHistory();
  const [banklist, setBanklist] = useState();
  const [formDetails, setFormDetails] = useState(null);
  const [headerState, setHeaderState] = useState({
    CostCentre: "",
    Currency: "",
  });

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    try {
      await axios.post("/accountingSystem/systemParameter", formValues);
      history.push("/accountingSystem/systemParameter");
    } catch (err) {
      console.log(err);
    }
  };

  const handleHeaderChange = (value) => {
    setHeaderState({ ...headerState, ...value });
  };

  useEffect(() => {
    async function getData() {
      try {
        const { data: bank } = await axios.get("/commonMaster/bank");
        const mapped = bank.map((item) => item.BankCode + "-" + item.BankName);
        setBanklist(mapped);
        setFormDetails({
          FinancialPeriod: getCurrentFinancialYear(),
        });
      } catch (err) {
        console.error(err);
      }
    }
    getData();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="System Parameter Create"
        linkName1="System Parameter Dashboard"
        to1="/accountingSystem/systemParameter"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        System Parameter
      </h3>
      <SyatemParameter
        BList={banklist}
        onSubmit={onSubmit}
        handleHeaderChange={handleHeaderChange}
        initialValues={pick(formDetails, "FinancialPeriod")}
      />
    </div>
  );
};
