import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { CircularProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SubmitModelPopup = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
      } catch (err) {}
    }

    fetch();
  });

  return (
    <div>
      <div className="middle">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={props.onFormClick}
        >
          {props.ButtonName ? <>{props.ButtonName}</> : "Submit"}
        </Button>
      </div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {props.formTitle}
        </DialogTitle>
        {isLoading ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are sure you want to submit it?
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: "10px", padding: "2px" }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  color: "white",
                  background: "#FF4741",
                  outline: "none",
                }}
                onClick={props.onFormClick}
              >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                type="submit"
                style={{
                  borderRadius: "15px",
                  color: "white",
                  fontWeight: "600",
                  background: "#02BD3D",
                  marginLeft: "10px",
                  outline: "none",
                }}
                onClick={() => {
                  props.handleSubmitForm();
                  props.onClose();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </>
        ) : (
          <div>
            <DialogContent align="center">
              <CircularProgress style={{ color: "red" }} />
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SubmitModelPopup;
