import React from "react";
import GSTTableRowView from "./GSTTableRowView";

const GSTTableView = ({ gsts, company }) => {
  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">GST No.</th>
            <th className="head-cell">State</th>
            <th className="head-cell">Address</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {gsts !== undefined &&
            gsts.map((gst, index) => (
              <React.Fragment key={index}>
                <GSTTableRowView gst={gst} company={company} />
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default GSTTableView;
