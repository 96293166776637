import BallotIcon from "@material-ui/icons/Ballot";
import RoleList from "../../helpers/RoleList";

export const MachineManagementSystemNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_STORE_KEEPER.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.BU_HEAD.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.DIRECTOR.code,
  ],
  label: "Machine Management",
  name: "MachineManagement",
  attrModule: "machineManagement",
  icon: <BallotIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/machineManagement/machineReceipt",
      label: "Machine Receipt",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/machineManagement/logSheetEntry",
      label: "Log Sheet Entry",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/machineManagement/reports",
      label: "Reports",
      access: [RoleList.ADMIN.code],
    },
    {
      to: "/machineManagement/machineRelease",
      label: "Machine Release",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_STORE_KEEPER.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.BU_HEAD.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
      ],
    },
  ],
};
