import React, { useState, useEffect } from "react";
import Table from "../../Tables/AccountingSystem/CurrencyTable";
import { Field } from "redux-form";
import * as Control from "../formcomponents/control";
import axios from "../apis/axios";
// import { useSnackbar } from "notistack";
import "../helpers/form.style.css";

const ReceiptEmployeeOtherFormComponent = ({
  mode,
  handleCurrencyChange,
  props,
  initialValue,
  headerState,
}) => {
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (headerState?.CostCentre !== "") {
        const { data: balance } = await axios.post(
          "accountingSystem/shared/getSystemParamBalanceByCostCentre",
          { CostCentre: headerState?.CostCentre }
        );
        const { BankDetails } = balance;
        const mapped = BankDetails.map((item) => item.BankName);
        setBankList(mapped);
      }
    };
    getData();
  }, [headerState?.CostCentre, props?.initialValues?.FromBank]);

  return (
    <div className="field-container">
      {mode === "Bank" ? (
        <>
          <Field
            name="AtBank"
            label="At Bank"
            component={Control.AutoCompleteInput}
            list={bankList}
            className="text-field"
          />
          <Field
            name="TotalReceiptValue"
            label="Total Receipt Value"
            component={Control.RenderTextField}
          />
          <Field
            name="ChequeNEFTRTGSIMPSNo"
            component={Control.RenderTextField}
            label="Cheque/NEFT/RTGS/IMPS No."
          />
          <Field
            name="Date"
            label="Date"
            component={Control.RenderDateField}
            initialValue={initialValue?.Date}
          />
        </>
      ) : (
        <>
          <div className="middle">
            <div>
              <Table
                handleCurrencyChange={handleCurrencyChange}
                cashDetails={props.cashDetails}
              />
              <div className="middle">
                <p className=" Mui-error excelError"></p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReceiptEmployeeOtherFormComponent;
