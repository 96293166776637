/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import RetentionTermsTable from "../../Tables/SalesAcountingSystem/SalesOrder/RetentionTermsTable";
import PaymentTermTable from "../../Tables/PaymentTerms/PaymentTermsTable";
import SalesOrderTable from "../../Tables/SalesAcountingSystem/SalesOrder/SalesOrderTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const SalesOrderAmendmentForm = (props) => {
  //file upload
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [tableDataState, setTableDataState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
  });
  const [paymentTermsData, setPaymentTermsData] = useState({
    paymentData: null,
    paymentTermsErrorKind: "",
    paymentTermsError: false,
  });
  const [retentionData, setRetentionData] = useState({
    retentionData: null,
    retentionTermsErrorKind: "",
    retentionTermsError: false,
  });

  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const [insurance, setInsurance] = useState("");

  const toggleModal = () => setShow(!show);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    props.onSubmit({
      ...formValues,
      tableData: tableDataState.tableData,
      retentionData: retentionData.retentionData,
      paymentData: paymentTermsData.paymentData,
    });
  };

  const tableData = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += +item.NetAmount;
    });
    props.dispatch(
      change("SalesOrderAmendmentForm", "OrderValue", (+total).toFixed(2))
    );
    if (!data) {
      setTableDataState({
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setTableDataState({
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const paymentTermsTable = (data) => {
    if (!data) {
      setPaymentTermsData({
        paymentTermsErrorKind: "table row are not fully field",
        paymentTermsError: true,
      });
    } else {
      setPaymentTermsData({
        paymentData: data,
        paymentTermsErrorKind: "",
        paymentTermsError: false,
      });
    }
  };

  const retentionTermsTable = (data) => {
    if (!data) {
      setRetentionData({
        retentionTermsErrorKind: "table row are not fully field",
        retentionTermsError: true,
      });
    } else {
      setRetentionData({
        retentionData: data,
        retentionTermsErrorKind: "",
        retentionTermsError: false,
      });
    }
  };

  const onStartDateChange = (value) => {
    console.log("date updated");
    setStartDate(new Date(value));
  };

  const calculateDuration = (value) => {
    const endDate = new Date(value);
    let timeSpan = (endDate - startDate) / (1000 * 60 * 60 * 24 * 30);
    let months = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24 * 30));
    let days = Math.round((timeSpan - months) * 30);
    if (months < 0) {
      alert("Invalid Date");
      return;
    }
    if (months > 0 && days > 0) {
      let netTime = `${months} months and ${days} days`;
      props.dispatch(
        change("SalesOrderAmendmentForm", "RevisedDuration", netTime)
      );
    } else if (months > 0) {
      let netTime = `${months} months`;
      props.dispatch(
        change("SalesOrderAmendmentForm", "RevisedDuration", netTime)
      );
    } else {
      let netTime = `${days} days`;
      props.dispatch(
        change("SalesOrderAmendmentForm", "RevisedDuration", netTime)
      );
    }
    if (months == 1) {
      let netTime = `${months} month and ${days} days`;
      props.dispatch(
        change("SalesOrderAmendmentForm", "RevisedDuration", netTime)
      );
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="SalesOrderNo"
            label="Sales Order No"
            list={props.salesOrderList}
            className="text-field"
            onChange={(value) =>
              props.selectSalesOrder && props.selectSalesOrder(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectCodeName"
            label="Project Code / Name"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerGSTNo"
            label="Customer GST No"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderReferenceNo"
            label="Order Reference No​"
            // disabled
          />
          <Field
            component={Control.RenderDateAllField}
            name="OrderReferenceDate"
            initialValue={props.initialValues.OrderReferenceDate}
            label="Order Reference Date"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderType"
            label="Order Type"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="LastAmendmentNo"
            label="Last Amendment No"
            // disabled
          />
          <Field
            component={Control.RenderDateField}
            initialValue={props.initialValues.LastAmendmentDate}
            name="LastAmendmentDate"
            label="Last Amendment Date"
          />
          <Field
            component={Control.RenderTextField}
            name="LastAmendmentValue"
            label="Last Amendment Order Value"
            type="text"
            InputProps={{ inputProps: { min: 0, step: 0.01 } }}
            // disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="AdvanceBG"
            label="Bank Guarantee Type"
          >
            <option value=""></option>
            <option value="Advance">Advance</option>
            <option value="Performance">Performance</option>
            <option value="NotApplicable">Not Applicable</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="Insurance"
            label="Insurance"
            onChange={(value) => setInsurance(value)}
          >
            <option value=""></option>
            <option value="WorkmenCompensation">Workmen Compensation</option>
            <option value="Medical">Medical</option>
            <option value="Others">Others</option>
          </Field>
          {insurance === "Others" && (
            <Field
              component={Control.RenderTextField}
              name="InsuranceOthersText"
              label="Insurance Others"
            />
          )}
          <Field
            component={Control.RenderTextField}
            name="CompanyName"
            label="Company Name"
            // disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CompanyGstNo"
            label="Company GST No"
            // disabled
          />
        </div>
        <div>
          <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Payments Terms
            </h4>
            <PaymentTermTable
              paymentTermsTable={paymentTermsTable}
              payments={props.payments}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {paymentTermsData.paymentTermsError &&
                `${paymentTermsData.paymentTermsErrorKind}`}
            </p>
          </div>
          <div className="excel">
            <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
              Retention Terms
            </h4>
            <RetentionTermsTable
              retentionTermsTable={retentionTermsTable}
              retentions={props.retentions}
            />
          </div>
          <div className="middle">
            <p className=" Mui-error excelError">
              {retentionData.retentionTermsError &&
                `${retentionData.retentionTermsErrorKind}`}
            </p>
          </div>
        </div>
        <div className="field-container">
          <Field
            component={Control.RenderDateAllField}
            initialValue={props.initialValues.ProjectStartDate}
            name="ProjectStartDate"
            label="Project Start Date"
            className="text-field"
            onChange={(event) => {
              onStartDateChange(event);
            }}
          />
          <Field
            component={Control.RenderDateAllField}
            name="ProjectEndDate"
            initialValue={props.initialValues.ProjectEndDate}
            label="Project Revised End Date"
            className="text-field"
            onChange={(event) => {
              calculateDuration(event);
            }}
          />
          <Field
            component={Control.RenderTextField}
            name="RevisedDuration"
            label="Revised Duration"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="LDClause"
            label="LD Clause"
          />
          <Field
            component={Control.RenderTextField}
            name="PriceVariationClause"
            label="Price Variation Clause"
          />
          <Field
            component={Control.RenderTextField}
            name="OrderValue"
            label="Revised Order Value Incl Amendment"
            type="number"
            InputProps={{ inputProps: { min: 0, step: 0.01 } }}
            disabled
          />
        </div>
        <div className="excel">
          <SalesOrderTable
            tableData={tableData}
            order={props.soTable}
            uom={props.uom}
            orderType={props.initialValues.OrderType}
          />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {tableDataState.tableError && `${tableDataState.tableErrorKind}`}
          </p>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Sales Order Amendment"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "OrderReferenceNo",
    "Date",
    "OrderType",
    "Insurance",
    "CustomerGSTNo",
    "CompanyName",
    "FNCGSTNo",
    "Stage",
    "OrderCategory",
    "DefectLiabilityPeriod",
    "ProjectOrderValue",
    "LDClause",
    "PriceVariationClause",
    "ProjectEndDate",
    "RevisedDuration",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "SalesOrderAmendmentForm",
  validate,
  enableReinitialize: true,
})(SalesOrderAmendmentForm);
