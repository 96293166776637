import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/SalesAccountingSystem/SalesInvoiceForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];

export const SalesInvoiceCreate = () => {
  const history = useHistory();
  const [formDetail, setFormDetail] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [invoiceTable, setInvoiceTable] = useState(null);
  const [recoveryTable, setRecoveryTable] = useState(null);
  const [salesOrderTable, setSalesOrderTable] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [staged, setStaged] = useState("");
  const [recoveryTypes, setRecoveryTypes] = useState(null);
  const [orderValue, setOrderValue] = useState(0);
  const [materialQty, setMaterialQty] = useState();
  const [gstPercentage, setGstPercentage] = useState({
    SGST: 0,
    CGST: 0,
    IGST: 0,
  });

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    await axios.post("/salesAccounting/salesInvoice/", formValues);
    history.push("/salesAccounting/salesInvoice/");
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      const project = allProjects.find((item) =>
        item.ProjectCode + ' - ' + item.ProjectShortDescription === projectName ? item : ""
      );
      let detail = {
        ProjectCodeName: projectName,
        CustomerName: project.CustomerName,
      };
      try {
        const { data } = await axios.post(
          `/salesAccounting/salesOrder/getByProjectNameWrtQty/`,
          {
            ProjectCodeName: projectName,
          }
        );
        if (typeof data.result === "string");
        else {
          const stage = data.result.Stage;
          if (stage === "Yes") {
            setStageData(data.result.stages);
          }
          setStaged(stage);
          let salesOrder;
          if (data.result.amendment) {
            salesOrder = data.result.amendment;
            detail = {
              ...detail,
              CustomerName: project.CustomerName,
              LastAmendmentValue: salesOrder.OrderValue,
              LastAmendmentNo: salesOrder.SalesOrderAmendmentNo.slice(13),
              LastAmendmentDate: salesOrder.created_at,
            };
          } else salesOrder = data.result;

          detail = {
            ...detail,
            OrderReferenceDate: salesOrder?.OrderReferenceDate,
            SalesOrderNo: salesOrder?.SalesOrderNo,
            OrderValue: salesOrder?.OrderValue,
            SalesOrderDate: salesOrder?.created_at,
            CompanyGstNo: salesOrder?.CompanyGstNo,
            CustomerGSTNo: salesOrder?.CustomerGSTNo,
          };
          setGstPercentage({
            SGST: salesOrder.materials[0].SGSTPercentage,
            CGST: salesOrder.materials[0].CGSTPercentage,
            IGST: salesOrder.materials[0].IGSTPercentage,
          });
          setOrderValue(salesOrder.OrderValue);
          const { data: invoice } = await axios.get(
            `salesAccounting/salesInvoice/lastInvoice/${salesOrder.SalesOrderNo}`
          );
          if (invoice) {
            detail = {
              ...formDetail,
              ...detail,
              LastInvoiceNo: invoice.SalesInvoiceNo.slice(9),
            };
            setInvoiceTable(invoice.InvoiceDetails);
            setRecoveryTable(invoice.InvoiceRecovery);
          } else {
            setSalesOrderTable(salesOrder.materials);
          }
          const { data: qty } = await axios.post(
            "salesAccounting/salesOrder/findQuantity",
            {
              SalesOrderNo: salesOrder.SalesOrderNo,
            }
          );
          setMaterialQty(qty);
        }
        setFormDetail(detail);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const newData = [
          ...new Map(data.map((item) => [item["ProjectCode"], item])).values(),
        ];
        let filteredData = newData.filter((item) => item.Status === "accepted");
        let mappedData = filteredData.map(
          (item) => item.ProjectCode + ' - ' + item.ProjectShortDescription
        );
        setProjectList(mappedData);
        allProjects = data;
      } catch (err) {
        console.log(err);
      }
    };
    const fetchRecoveryType = async () => {
      try {
        const { data } = await axios.get("/commonMaster/recoveryType/");
        setRecoveryTypes(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    };
    fetch();
    fetchRecoveryType();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Sales Invoice  Create"
        linkName1="Sales Invoice  Dashboard"
        to1="/salesAccounting/salesInvoice"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Invoice
      </h3>
      <Form
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        invoiceTable={invoiceTable}
        recoveryTable={recoveryTable}
        salesOrderTable={salesOrderTable}
        staged={staged}
        stageData={stageData}
        recoveryTypes={recoveryTypes}
        orderValue={orderValue}
        materialQty={materialQty}
        gstPercentage={gstPercentage}
        initialValues={pick(
          formDetail,
          "CustomerName",
          "ProjectCodeName",
          "SalesOrderDate",
          "SalesOrderNo",
          "SalesOrderNo",
          "OrderValue",
          "CompanyGstNo",
          "CustomerGSTNo",
          "LastAmendmentNo",
          "LastInvoiceNo",
          "LastAmendmentDate",
          "OrderReferenceDate"
        )}
      />
    </div>
  );
};
