import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachineRepairRegister from "../../../forms/MachineManagementSystem/MachineRepairRegister";
import { machineRepairRegister } from "../../../redux/actions/MachineManagementaction";

export const MachineRepairRegisterCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machineRepairRegister(formValues, history));
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3 style={{ textAlign: "center" }}>Machine Repair Register</h3>
      <MachineRepairRegister onSubmit={onSubmit} />
    </div>
  );
};

