import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

const PurchaseOrderTable = ({
  materialDataCreate,
  storeTableData,
  materialsEditData,
  materialsQty,
  isAmendment,
}) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (materialsEditData) {
      setLoading(true);
      setInputFields([...materialsEditData]);
      onChange([...materialsEditData]);
    }
    setLoading(false);
    //eslint-disable-next-line
  }, [materialsEditData]);

  // console.log(inputFields);

  useEffect(() => {
    let values = [];
    if (materialDataCreate) {
      for (let i = 0; i < materialDataCreate.length; i++) {
        values = [
          ...values,
          {
            MaterialCode: materialDataCreate[i].MaterialCode,
            MaterialDescription: materialDataCreate[i].MaterialDescription,
            HSNSACCode: materialDataCreate[i].HSNSACCode,
            UOM: materialDataCreate[i].UOM,
            Quantity: materialDataCreate[i].Quantity,
            SuggestedRate: materialDataCreate[i].SuggestedRate,
            Discount: 0,
            NetRate: materialDataCreate[i].SuggestedRate,
            NetAmount:
              +materialDataCreate[i].SuggestedRate *
              +materialDataCreate[i].Quantity,
            CGSTPercentage: 0,
            CGSTValue: 0,
            SGSTPercentage: 0,
            SGSTValue: 0,
            IGSTPercentage: 0,
            IGSTValue: 0,
            TotalGST: 0,
            ItemTotal:
              +materialDataCreate[i].SuggestedRate *
              +materialDataCreate[i].Quantity,
            id: "new",
          },
        ];
      }
      setLoading(false);
      setInputFields(values);
      onChange(values);
    }
    //eslint-disable-next-line
  }, [materialDataCreate]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    const row = values[index];
    if (event.target.value < 0) {
      enqueueSnackbar(`You can't enter Negative Number`, {
        variant: "warning",
      });
    }
    if (event.target.name === "Quantity" && !isAmendment) {
      const item = materialsQty.find(
        (item) => item.MaterialCode === row.MaterialCode
      );
      if (+item.CurrentQuentity < +event.target.value) {
        enqueueSnackbar(
          `Quantity can not be more than ${item.CurrentQuentity}`,
          {
            variant: "warning",
          }
        );
        return;
      }
    } else if (event.target.name === "Quantity" && isAmendment) {
      const item = materialsQty.find(
        (item) => item.MaterialCode === row.MaterialCode
      );
      if (+item.RequestedQuentity < +event.target.value) {
        enqueueSnackbar(
          `Quantity can not be more than ${item.RequestedQuentity}`,
          {
            variant: "warning",
          }
        );
        return;
      }
    }
    row[event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const updatevalues = (index) => {
    const values = [...inputFields];
    let row = inputFields[index];
    row.NetRate = (
      +row.SuggestedRate -
      +row.SuggestedRate * (+row.Discount / 100)
    ).toFixed(2);
    row.NetAmount = (+row.Quantity * +row.NetRate).toFixed(2);
    if (+row.CGSTPercentage > 0) {
      row.SGSTPercentage = row.CGSTPercentage;
      row.IGSTPercentage = 0;
    } else if (+row.IGSTPercentage > 0) {
      row.SGSTPercentage = 0;
      row.CGSTPercentage = 0;
    }
    row.CGSTValue = ((+row.CGSTPercentage / 100) * +row.NetAmount).toFixed(2);
    row.IGSTValue = ((+row.IGSTPercentage / 100) * +row.NetAmount).toFixed(2);
    row.SGSTValue = ((+row.SGSTPercentage / 100) * +row.NetAmount).toFixed(2);
    row.TotalGST = (+row.IGSTValue + +row.CGSTValue + +row.SGSTValue).toFixed(
      2
    );
    row.ItemTotal = (+row.TotalGST + +row.NetAmount).toFixed(2);
    values[index] = row;
    setInputFields(values);
    onChange(values);
  };

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  return (
    <div className="tableContainer">
      {!loading ? (
        <table>
          <thead className="table-head">
            <tr>
              <th rowSpan="2" className="head-cell">
                Material Code
              </th>
              <th rowSpan="2" className="head-cell">
                Material Description
              </th>
              <th rowSpan="2" className="head-cell">
                HSN / SAC Code
              </th>
              <th rowSpan="2" className="head-cell">
                UOM
              </th>
              <th rowSpan="2" className="head-cell">
                Quantity
              </th>
              <th rowSpan="2" className="head-cell">
                Rate
              </th>
              <th rowSpan="2" className="head-cell">
                Discount
              </th>
              <th rowSpan="2" className="head-cell">
                Net Rate
              </th>
              <th rowSpan="2" className="head-cell">
                Net Amount
              </th>
              <th colSpan="2">C-GST</th>
              <th colSpan="2">S-GST</th>
              <th colSpan="2">I-GST</th>
              <th rowSpan="2" className="head-cell">
                Total GST
              </th>
              <th rowSpan="2" className="head-cell">
                Item Total
              </th>
            </tr>
            <tr>
              <th>%</th>
              <th>Value</th>
              <th>%</th>
              <th>Value</th>
              <th>%</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {inputFields?.map((inputField, index) => (
              <tr key={index}>
                <td className="table-data1">{inputField.MaterialCode}</td>
                <td className="table-data3">
                  {inputField.MaterialDescription}
                </td>
                <td className="table-data3">{inputField.HSNSACCode}</td>
                <td className="table-data3">{inputField.UOM}</td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="Quantity"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.Quantity}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                      fixedDecimal(index, event, "Quantity");
                    }}
                  />
                </td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="SuggestedRate"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.SuggestedRate}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                      fixedDecimal(index, event, "SuggestedRate");
                    }}
                  />
                </td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="Discount"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.Discount}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                      fixedDecimal(index, event, "Discount");
                    }}
                  />
                </td>
                <td className="table-data3">{inputField.NetRate}</td>
                <td className="table-data3">{inputField.NetAmount}</td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="CGSTPercentage"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.CGSTPercentage}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                    }}
                  />
                </td>
                <td className="table-data3">{inputField.CGSTValue}</td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="SGSTPercentage"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.SGSTPercentage}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                    }}
                  />
                </td>
                <td className="table-data3">{inputField.SGSTValue}</td>
                <td className="table-data3">
                  <input
                    style={{ width: "5rem" }}
                    name="IGSTPercentage"
                    type="number"
                    min={0}
                    step={0.01}
                    value={inputField.IGSTPercentage}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      updatevalues(index);
                    }}
                  />
                </td>
                <td className="table-data3">{inputField.IGSTValue}</td>
                <td className="table-data3">{inputField.TotalGST}</td>
                <td className="table-data4">{inputField.ItemTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <LinearProgress variant="indeterminate" />
        </>
      )}
    </div>
  );
};

export default PurchaseOrderTable;
