import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    editBenefit,
    getBenefitById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import pick from "lodash/pick";
import Benefit from "../../../forms/CommonMaster/Benefits/Benefit";

export const BenefitEdit = (props) => {
    const [data, setData] = useState();

    const history = useHistory();
    const { match } = props;

    useEffect(() => {
        if (!match.params.id) return;
        const fetch = async () => {
            try {
                const data = await getBenefitById(match.params.id);
                setData(data);
            } catch (error) {
                //
            }
        };
        fetch();
    }, [match.params.id]);

    const onSubmit = (formValues, history) => {
        editBenefit(formValues,
            props.match.params.id,
            history
        );
    };

    return (
        <div>
            <Breadcrumbs
                name="Benefit Edit"
                to1="/commonMaster/benefits"
                linkName1="Benefits Dashboard"
            />
            <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
                Benefit Edit
            </h3>
            <div className="line"></div>
            <Benefit
                initialValues={{account: data?.account.AccountCode, ...pick(
                    data,
                    "benefitCode",
                    "benefitDescription",
                    "accountDescription",
                    "tag",
                    "applicableFor",
                    "basicWage"
                )}}
                onSubmit={(formValues) => onSubmit(formValues, history)}
            />
        </div>
    );
};
