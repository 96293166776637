import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PurchaseOrderAmendmentForm from "../../../forms/MaterialSystem/PurchaseOrderAmendmentForm";
import "../style/materialSystem.css";
import { purchaseOrderAmendment } from "../../../redux/actions/materialSystemAction";
import pick from "lodash/pick";
import { allPurchaseOrder } from "../../../redux/actions/materialSystemAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let AllVendorData = [];
let totalpayments = [];
let AllProjectName = [];
let AllPurchaseOrder = [];

export const PurchaseOrderAmendmentCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectNameList, setprojectNameList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [vendorNameList, setVendorNameList] = useState([]);
  const [vendorGst, setvendorGst] = useState([]);
  const [POList, setPOList] = useState(null);
  const [totalPayments, setTotalPayments] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [materialsQty, setMaterialsQty] = useState();

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(purchaseOrderAmendment(formValues, history));
  };

  const selectPurchaseOrderNo = async (PurchaseOrderNo) => {
    const item = AllPurchaseOrder.find(
      (item) => item.PurchaseOrderNo === PurchaseOrderNo
    );
    const { data } = await axios.get(
      `/materialManagement/purchaseOrder/${item.id}`
    );
    console.log(data);
    setMaterials(data.materials);
    setTotalPayments(data.totalpayments);

    if (data.amendment) {
      setFormDetail({
        ...data,
        ...formDetail,
        LastAmendmentNo: data.amendment.PurchaseOrderAmendmentNo,
        LastAmendmentDate: data.amendment.created_at,
      });
    } else {
      setFormDetail({
        ...formDetail,
        ...data,
        LastAmendmentNo: "",
        LastAmendmentDate: "",
      });
    }

    try {
      const { data: qty } = await axios.get(
        `/materialManagement/shared/getPurchaseRequestQty/${data.PurchaseRequestNo}`
      );
      setMaterialsQty(qty.materials);
    } catch (err) {
      console.error(err);
    }
  };

  async function fetchVendor() {
    try {
      let resp = await axios.get("/commonMaster/vendor"); 
      AllVendorData = resp.data;
      const newData = AllVendorData.filter(item => item.Tags === "Active");
      let VNlist = newData.map((item) => item.VendorName);
      setVendorNameList(VNlist);
    } catch (error) {
      alert(error);
    }
  }


  const SelectVendorName = async (VendorName) => {
    const item = AllVendorData.find((item) => item.VendorName === VendorName);
    if (item) {
      let payload = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gst = payload.data.Gsts;
      const gstRegNos = gst.map((item) => item.GSTRegNo);
      setvendorGst(gstRegNos);
      // console.log("gstRegNos ->",gstRegNos)
      payload = {
        ...formDetail,
        VendorName: VendorName,
        VendorGSTNo: item.GSTRegNo,
      };
      setFormDetail(payload);
    }
  };

  async function fetchProject() {
    try {
      let { data } = await axios.get("/salesAccounting/projectCircular");

      const filtered = data.filter((item) => item.Status === "accepted");
      AllProjectName = filtered;
      let PNlist = filtered.map((item) => item.ProjectShortDescription);
      setprojectNameList(PNlist);
    } catch (error) {
      alert(error);
    }
  }

  const SelectProjectName = async (ProjectShortDescription) => {
    console.log(ProjectShortDescription,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
    console.log(AllProjectName,"AllProjectNameAllProjectName");
    const item = AllProjectName.find(
      (item) => item.ProjectShortDescription === ProjectShortDescription
    );

    if (item) {
      const {
        data,
      } = await axios.post(
        `/materialManagement/purchaseOrder/getByProjectName`,
        { ProjectCodeName: ProjectShortDescription }
      );
      const filtered = data.filter((item) => item.Status === "accepted");
      AllPurchaseOrder = filtered;
      const mapped = filtered.map((item) => item.PurchaseOrderNo);
      setPOList(mapped);
      setFormDetail({
        ...formDetail,
        ProjectCodeName: item.ProjectShortDescription,
        CustomerName: item.CustomerName,
      });
    }
  };

  useEffect(() => {
    dispatch(allPurchaseOrder());
  }, [dispatch]);

  useEffect(() => {
    fetchVendor();
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Purchase Order Amendment Create"
        linkName1=" Purchase Order Amendment Dashboard"
        to1="/materialManagement/purchaseOrderAmendment"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Purchase Order Amendment
      </h3>
      <PurchaseOrderAmendmentForm
        onSubmit={onSubmit}
        PNList={projectNameList}
        SelectProjectName={SelectProjectName}
        selectPurchaseOrderNo={selectPurchaseOrderNo}
        VNlist={vendorNameList}
        SelectVendorName={SelectVendorName}
        materialsTable={materials}
        paymentTermsTable={totalpayments}
        payments={totalPayments}
        VendorGsts={vendorGst}
        POList={POList}
        materialsQty={materialsQty}
        initialValues={pick(
          formDetail,
          "PurchaseOrderNo",
          "PurchaseRequestNo",
          "Date",
          "CustomerName",
          "YourRefNoDate",
          "ProjectCodeName",
          "OurRefNoDate",
          "VendorCodeName",
          "VendorGSTNo",
          "FNCGSTNo",
          "Warehouse",
          "LastAmendmentNo",
          "LastAmendmentDate",
          "DeliveryAt",
          "DeliveryPeriodFrom",
          "DeliveryPeriodTo",
          "Inspection",
          "Currency",
          "LDClause",
          "PriceVariationClause",
          "PurchaseOrderValue"
        )}
        materialsEditData={materials}
        isAmendment={true}
      />
    </div>
  );
};
