import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import useBasicDialogStyles from "./BasicDialog.styles";

export default function BasicDialog({
  content,
  title,
  setOpen,
  open = false,
  dialogActions,
}) {
  const classes = useBasicDialogStyles();

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.dialogHeader}>
          {title}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </div>
  );
}
