import React, { useEffect, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));

const TransferOrder = (props) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyle();
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const fieldValues = useSelector((state) => state.form?.EmployeeJoin?.values);
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    const {
      functionalRole,
      reportingDate,
      wef,
      employeeData,
      toProjectData,
      reportEmployeeData,
    } = formValues;
    props.onSubmit({
      wef,
      functionalRole,
      reportingDate,
      employee: employeeData?.id,
      toProject: toProjectData?.id,
      reportEmployee: reportEmployeeData?.id,
    });
  };

  const toggleModal = () => setShow(!show);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular", {
          params: {
            status: "Active",
          },
        });
        setProjects(
          data
            ?.filter((item) => item?.Status === "accepted")
            .map((project) => ({
              label: `${project.ProjectCode} - ${project.ProjectShortDescription}`,
              value: project.id,
              project,
            }))
        );
      } catch (error) {
        alert(error);
      }
    };
    const fetchAccount = async () => {
      try {
        const { data } = await axios.get("/hr-management/employee-join", {
          params: {
            status: "Accepted",
          },
        });
        setEmployees(
          data?.map((data) => ({
            label: `${data.employee.EmployeeCode} - ${data.employee.FirstName} ${data.employee.LastName}`,
            value: data.employee.id,
            employee: data,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
    fetchProject();
  }, []);

  const handleChange = (value) => {
    if (value?.employee) {
      dispatch(change("TransferOrder", "employeeData", value?.employee));
      dispatch(
        change(
          "TransferOrder",
          "fromProject",
          value?.employee?.project?.ProjectCode
        )
      );
      dispatch(
        change(
          "TransferOrder",
          "fromCustomerName",
          value?.employee?.project?.CustomerName
        )
      );
      dispatch(
        change(
          "TransferOrder",
          "fromCostCentre",
          value?.employee?.project?.CostCentre
        )
      );
    }
  };

  const handleToProjectChange = (value) => {
    const { project } = value;
    dispatch(change("TransferOrder", "toProjectData", project));
    dispatch(change("TransferOrder", "toCustomerName", project?.CustomerName));
    dispatch(change("TransferOrder", "toCostCentre", project?.CostCentre));
  };

  const reportEmployeeChange = (value) => {
    if (value?.employee) {
      dispatch(change("TransferOrder", "reportEmployeeData", value?.employee));
    }
  };

  const {
    RenderTextField,
    AutoCompleteInput,
    RenderDateField,
  } = Control;

  const inputsFieldgroups = [
    {
      label: "Transfer From",
      inputsFields: [
        {
          component: AutoCompleteInput,
          name: "employee",
          list: employees,
          label: "Employee Code/Name",
          onChange: handleChange,
        },
        {
          component: RenderTextField,
          name: "fromProject",
          label: "Project Code / Name",
          disabled: true,
        },
        {
          component: RenderTextField,
          name: "fromCustomerName",
          label: "Customer Name",
          disabled: true,
        },
        {
          component: RenderTextField,
          name: "fromCostCentre",
          label: "Cost Centre",
          disabled: true,
        },
        {
          component: RenderTextField,
          name: "wef",
          label: "W.E.F",
        },
      ],
    },
    {
      label: "Transfer To",
      inputsFields: [
        {
          component: AutoCompleteInput,
          name: "reportEmployee",
          list: employees,
          label: "Reporting to Employee Code/Name",
          onChange: reportEmployeeChange,
        },
        {
          component: AutoCompleteInput,
          name: "toProject",
          label: "Project Code / Name",
          list: projects,
          onChange: handleToProjectChange,
        },
        {
          component: RenderTextField,
          name: "toCustomerName",
          label: "Customer Name",
          disabled: true,
        },
        {
          component: RenderTextField,
          name: "toCostCentre",
          label: "Cost Centre",
          disabled: true,
        },
        {
          component: RenderDateField,
          name: "reportingDate",
          label: "Expected Reporting Date",
          disablePast: true,
          initialValue: props?.initialValues?.reportingDate
        },
        {
          component: RenderTextField,
          name: "functionalRole",
          label: "Funtional Role",
        },
      ],
    },
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        {inputsFieldgroups.map((group, index) => (
          <Box sx={{ marginBottom: 20 }} key={index}>
            <h2>{group.label}</h2>
            <Grid container spacing={2}>
              {group.inputsFields.map((field, i) => (
                <Grid item xs={6} key={i}>
                  <Field {...field} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Employee Transfer Order"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};

  const requiredFields = ["employee", "toProject", "reportEmployee", "reportingDate"];

  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "TransferOrder",
  validate,
  enableReinitialize: true,
})(TransferOrder);
