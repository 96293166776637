import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  searchSCItemCodeRequestById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const SCItemCodeRequestView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    dispatch(searchSCItemCodeRequestById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleSCItemCodeRequest) {
      setData(store.commonMasterSystemRoot.SingleSCItemCodeRequest);
    }
  }, [store.commonMasterSystemRoot.SingleSCItemCodeRequest]);

  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("scItem", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
  };
  return (
    <>
      <Breadcrumbs
        name="SC Item View"
        linkName1="SC Item Dashboard"
        to1="/commonMaster/scItemCodeRequest"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>SC Item Code</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data.MajorSCItemGroupCode && (
            <Paper className={classes.paper}>
              <h6>MajorSCItemGroupCode:</h6>
              <p>{data.MajorSCItemGroupCode}</p>
            </Paper>)}

            {data.MajorSCItemGroupDescription && (
            <Paper className={classes.paper}>
              <h6>MajorSCItemGroupDescription:</h6>
              <p>{data.MajorSCItemGroupDescription}</p>
            </Paper>)}

            {data.SCItemDescription && (
            <Paper className={classes.paper}>
              <h6>SCItemDescription:</h6>
              <p>{data.SCItemDescription}</p>
            </Paper>)}

            {data.UOM && (
            <Paper className={classes.paper}>
              <h6>UOM:</h6>
              <p>{data.UOM}</p>
            </Paper>)}

            {data.Component && (
             <Paper className={classes.paper}>
              <h6>Component:</h6>
              <p>{data.Component}</p>
            </Paper>)}

            {data.ComponentPercentage && (
            <Paper className={classes.paper}>
              <h6>ComponentPercentage:</h6>
              <p>{data.ComponentPercentage}</p>
            </Paper>)}
            
            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};

export default SCItemCodeRequestView;
