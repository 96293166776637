import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center'
    },
});

const FormHeading = ({ label }) => {

    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Typography variant="h5" style={{ fontWeight: "600" }}>
          {label}
        </Typography>
      </div>
    );
};

export default FormHeading;



