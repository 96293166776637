/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckIcon from "@material-ui/icons/Check";

const SalesOrderRecoveryTable = ({
  recoveryTypes,
  invoiceSavedData,
  recoveryTable,
  recoverySavedData,
  saveRecoveryTable,
  recoverTableEdit,
  recoveryTotalAmt,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      RecoveryType: "",
      RPercentage: 0,
      UptoLastBillAmt: 0,
      ThisBillAmt: 0,
      CummAmt: 0,
    },
  ]);

  const [totalAmt, setTotalAmt] = useState(0);
  const [thisBillInvoiceAmt, setThisBillInvoiceAmt] = useState(0);
  const [loading, setLoading] = useState(true);

  // edit
  useEffect(() => {
    let values = [];
    if (recoverTableEdit) {
      for (let i = 0; i < recoverTableEdit.length; i++) {
        values = [
          ...values,
          {
            RecoveryType: recoverTableEdit[i].RecoveryType,
            RPercentage: +recoverTableEdit[i].RPercentage,
            UptoLastBillAmt: +recoverTableEdit[i].UptoLastBillAmt,
            ThisBillAmt: (+recoverTableEdit[i].ThisBillAmt).toFixed(2),
            CummAmt: +recoverTableEdit[i].CummAmt,
            id: recoverTableEdit[i].id,
          },
        ];
      }
      setTotalAmt(recoveryTotalAmt);
      setInputFields(values);
      setLoading(false);
    }
  }, [recoverTableEdit, recoveryTotalAmt]);

  //create
  useEffect(() => {
    let values = [];
    let amt = 0;
    setLoading(true);
    console.log(invoiceSavedData);
    if (invoiceSavedData && invoiceSavedData.fields.length > 0) {
      for (let i = 0; i < invoiceSavedData.fields.length; i++) {
        amt +=
          +invoiceSavedData.fields[i].ThisBillQty *
          +invoiceSavedData.fields[i].Rate;
      }
      setThisBillInvoiceAmt(amt.toFixed(2));
    }

    let totalRecoveryAmt = 0;
    if (recoveryTable) {
      for (let i = 0; i < recoveryTable.length; i++) {
        values = [
          ...values,
          {
            RecoveryType: recoveryTable[i].RecoveryType,
            RPercentage: +recoveryTable[i].RPercentage,
            ThisBillAmt: (+recoveryTable[i].RPercentage * 0.01 * +amt).toFixed(
              2
            ),
            UptoLastBillAmt: +recoveryTable[i].CummAmt,
          },
        ];
        values[i].CummAmt = +values[i].UptoLastBillAmt + +values[i].ThisBillAmt;
        totalRecoveryAmt += values[i].CummAmt;
      }
      setInputFields(values);
      setTotalAmt(totalRecoveryAmt);
    }
    setLoading(false);
  }, [recoveryTable, invoiceSavedData]);

  // saved
  useEffect(() => {
    setLoading(true);
    if (recoverySavedData) {
      setInputFields(recoverySavedData.fields);
      setTotalAmt(recoverySavedData.amt);
    }
    setLoading(false);
  }, [recoverySavedData]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const onItemSelect = (event, values, index) => {
    try {
      const arr = [...inputFields];
      let row = inputFields[index];
      row.RecoveryType = values.RecoveryType;
      arr[index] = row;
      setInputFields(arr);
    } catch (err) {
      console.log(err);
    }
  };

  const updateValues = (index) => {
    const values = [...inputFields];
    let row = values[index];
    if (invoiceSavedData && +row.RPercentage > 0) {
      row.ThisBillAmt = (
        (+row.RPercentage / 100) *
        +thisBillInvoiceAmt
      ).toFixed(2);
      row.CummAmt = (+row.ThisBillAmt + +row.UptoLastBillAmt).toFixed(2);
    } else if (invoiceSavedData) {
      row.CummAmt = (+row.ThisBillAmt + +row.UptoLastBillAmt).toFixed(2);
    }
    values[index] = row;
    setInputFields(values);
    let amt = 0;
    for (let i = 0; i < values.length; i++) {
      amt = (+amt + +values[i].CummAmt).toFixed(2);
    }
    setTotalAmt(amt);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        RecoveryType: "",
        RPercentage: 0,
        UptoLastBillAmt: 0,
        ThisBillAmt: "",
        CummAmt: "",
        showSelectField: true,
        id: "",
      },
    ]);
  };

  let newData = recoveryTypes.filter((item) => item.Tags == "Active");

  return (
    <div style={{ display: "grid", gridTemplateColumns: "10fr 1fr" }}>
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">Sl. No</th>
            <th className="head-cell">Recovery Description</th>
            <th className="head-cell">Recovery Percentage</th>
            <th className="head-cell">Upto Last Bill Amount</th>
            <th className="head-cell">This Bill Amount</th>
            <th className="head-cell">Cumm upto This Bill Amount</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
                {newData && !loading ? (
                  <Autocomplete
                    options={newData}
                    getOptionLabel={(option) => option.RecoveryType}
                    style={{ width: "10rem" }}
                    onChange={(event, values) =>
                      onItemSelect(event, values, index)
                    }
                    renderInput={(params) => <TextField {...params} />}
                    defaultValue={newData.find(
                      (item) => item.RecoveryType === inputField.RecoveryType
                    )}
                  />
                ) : (
                  <span>{inputField.RecoveryType}</span>
                )}
              </td>
              <td className="table-data2">
                <input
                  type="number"
                  name="RPercentage"
                  value={inputField.RPercentage}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updateValues(index);
                  }}
                  style={{ width: "5rem" }}
                />
              </td>
              <td className="table-data2">{inputField.UptoLastBillAmt}</td>
              <td className="table-data2">
                <input
                  type="number"
                  name="ThisBillAmt"
                  value={inputField.ThisBillAmt}
                  onChange={(event) => {
                    handleChangeInput(index, event);
                    updateValues(index);
                  }}
                  style={{ width: "8rem" }}
                />
              </td>
              <td className="table-data4">{inputField.CummAmt}</td>
              <td>
                {index === 0 ? (
                  <RemoveCircleIcon className="width5" color="disabled" />
                ) : (
                  <RemoveCircleIcon
                    className="width5"
                    color="secondary"
                    onClick={(e) => handleRemoveFields(e, index)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ border: "none" }}></td>
            <td style={{ border: "none" }}></td>
            <td className="table-data1">Total Amount</td>
            <td className="table-data4">{totalAmt}</td>
            <td style={{ border: "none" }}></td>
            <td style={{ borderLeft: "none" }}></td>
          </tr>
        </tfoot>
      </table>
      <div style={{ gridColumn: "2 / 3", margin: "auto" }}>
        <IconButton
          color="primary"
          onClick={() => saveRecoveryTable(inputFields, totalAmt)}
        >
          <CheckIcon />
        </IconButton>
      </div>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default SalesOrderRecoveryTable;
