import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles({
  ul: {
    padding: "0",
    display: "flex",
    position: "absolute",
    zIndex: "5",
    width: "30%",
    flexDirection: "column",
    "& div": {
      listStyle: "none",
      cursor: "pointer",
      border: "0.4 solid #000",
    },
  },
});

let qdata = "";
export const AutoTableInput = (props) => {
  const { allItem, selectItem, queryItem, name, ...other } = props;
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [list, setList] = useState([]);
  const [sendData, setSendData] = useState("");
  const [onSelect, setOnSelect] = useState(false);

  useEffect(() => {
    if (queryItem && qdata !== sendData) {
      qdata = sendData;
      queryItem(sendData);
    }
  }, [sendData, queryItem]);

  // eslint-disable-next-line
  const memoizedCallback = useCallback(
    debounce((nextValue) => setSendData(nextValue), 1000),
    []
  );
  useEffect(() => {
    if (value && allItem && !onSelect) setList(allItem);
  }, [list, allItem, onSelect, value]);
  const { eValue } = props;
  useEffect(() => {
    setValue(eValue);
  }, [eValue]);
  const handleInputChange = (e) => {
    const newvalue = e.target.value;
    setOnSelect(false);
    setValue(newvalue);
    memoizedCallback(newvalue);
  };

  const searchForIt = (e) => {
    const searchByName = e.target.textContent;
    props.onChange(e);
    if (selectItem) selectItem(searchByName);
    setValue(searchByName);
    setOnSelect(true);
    setList([]);
  };
  return (
    <div {...other}>
      <input
        value={value}
        onChange={handleInputChange}
        name={name}
        autoComplete="off"
        style={{ width: "100%", height: "2rem" }}
      />
      <ul className={classes.ul}>
        {list.map((item, index) => (
          <div onClick={(item) => searchForIt(item)} key={index}>
            <Paper elevation={2}>
              <li>{item}</li>
            </Paper>
          </div>
        ))}
      </ul>
    </div>
  );
};
