import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../style/materialSystem.css";
import WarehouseIndentVoucherForm from "../../../forms/MaterialSystem/WarehouseIndentVoucherForm";
import { warehouseIndentVoucher } from "../../../redux/actions/materialSystemAction";
import pick from "lodash/pick";
import { allProjectName } from "../../../redux/actions/GeneralAction";
import {
  allCustomer,
  allVendor,
} from "../../../redux/actions/CommonMasterAction";

import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let data = [];
let AllVendor = [];
let AllSales = [];

export const WarehouseIndentVoucherCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [customerNamelist, setcustomerNamelist] = useState([]);
  const [vendorNamelist, setvendorNamelist] = useState([]);
  const [list, setList] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [machineList, setMachineList] = useState(null);
  const [activityList, setActivityList] = useState(null);
  const [materialList, setMaterialList] = useState(null);
  const [vendorGst, setVendorGst] = useState([]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    delete formValues.TotalPRValue;
    console.log(JSON.stringify(formValues));
    dispatch(warehouseIndentVoucher(formValues, history));
  };

  const selectProjectName = (projectName) => {
    if (projectName) {
      let detail = data.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ...detail,
        ProjectCodeName: projectName,
        Warehouse: detail.WareHouse,
      };
      setProjectDetail(detail);
      fetchSalesOrder(projectName);
    }
  };

  const SelectVendor = async (VendorName) => {
    const item = AllVendor.find((item) => item.VendorName === VendorName);
    console.log("AllVendor ", AllVendor);

    if (item) {
      let payload = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gst = payload.data.Gsts;
      const gstRegNos = gst.map((item) => item.GSTRegNo);
      setVendorGst(gstRegNos);
      setProjectDetail({
        ...projectDetail,
        VendorCodeName: payload.data.VendorName,
      });
    }
  };

  const selectSalesOrder = (salesNo) => {
    const item = AllSales.find((item) => item.SalesOrderNo === salesNo);
    if (item) {
      console.log(item);
      console.log(projectDetail);
      setProjectDetail({
        ...projectDetail,
        SalesOrderNo: item.SalesOrderNo,
        WorkSalesDate: item.created_at,
      });
    }
  };

  const fetchSalesOrder = async (projectName) => {
    try {
      const { data } = await axios.post(
        "/salesAccounting/salesOrder/getByProjectName",
        {
          ProjectCodeName: projectName,
        }
      );
      AllSales = data;
      const mapped = data.map((item) => item.SalesOrderNo);
      setSalesOrderList(mapped);
      const { data: machineData } = await axios.post(
        `commonMaster/machine/findByProjectName/`,
        {
          currentlyAt: projectName,
        }
      );
      let machines = machineData.map((item) => item.machineID);
      setMachineList(machines);
      console.log(machines);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(allCustomer());
    dispatch(allVendor());
  }, [dispatch]);

  useEffect(() => {
    if (store.commonMasterSystemRoot.AllCustomer) {
      const AllCustomer = store.commonMasterSystemRoot.AllCustomer;
      let CNlist = AllCustomer.map((item) => item.CustomerName);
      setcustomerNamelist(CNlist);
    }
    if (store.commonMasterSystemRoot.AllVendor) {
      AllVendor = store.commonMasterSystemRoot.AllVendor;
      let VDList = AllVendor.map((item) => item.VendorName);
      setvendorNamelist(VDList);
    }
  }, [
    store.commonMasterSystemRoot.AllCustomer,
    store.commonMasterSystemRoot.AllVendor,
  ]);

  useEffect(() => {
    dispatch(allProjectName());
  }, [dispatch]);

  useEffect(() => {
    if (store.GeneralRoot.AllProjectName) {
      data = store.GeneralRoot.AllProjectName;
      let PNList = data.map((item) => item.ProjectShortDescription);
      setList(PNList);
    }
    const fetch = async () => {
      try {
        const { data: activities } = await axios.get("/commonMaster/activity/");
        setActivityList(activities);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [store.GeneralRoot.AllProjectName]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`/commonMaster/material`);
      setMaterialList(data);
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Indent Voucher  Create"
        linkName1=" Warehouse Indent Voucher  Dashboard"
        to1="/materialManagement/warehouseIntent"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Indent Voucher Form
      </h3>
      <div className="line"></div>
      <WarehouseIndentVoucherForm
        list={list}
        onSubmit={onSubmit}
        CNList={customerNamelist}
        VDList={vendorNamelist}
        selectProjectName={selectProjectName}
        initialValues={pick(
          projectDetail,
          "CustomerName",
          "ProjectCodeName",
          "SalesOrderNo",
          "Warehouse",
          "WorkSalesDate",
          "VendorCodeName"
        )}
        salesOrderList={salesOrderList}
        materialList={materialList}
        activityList={activityList}
        machineList={machineList}
        SelectVendor={SelectVendor}
        vendorGst={vendorGst}
        selectSalesOrder={selectSalesOrder}
      />
    </div>
  );
};
