import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachinePurchaseOrder from "../../../forms/MachineManagementSystem/MachinePurchaseOrder";
import { useHistory } from "react-router-dom";
import {
  editMachinePurchaseOrder,
  searchMachinePurchaseOrderById,
} from "../../../redux/actions/MachineManagementaction";

export const MachinePurchaseOrderEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachinePurchaseOrderById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachinePurchaseOrder) {
      setData(store.SingleMachinePurchaseOrder);
    }
  }, [store.SingleMachinePurchaseOrder]);
  const onSubmit = (formValues) => {
    dispatch(
      editMachinePurchaseOrder(formValues, props.match.params.id, history)
    );
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Purchase Order Edit
      </h3>
      <div className="line"></div>
      <MachinePurchaseOrder
        initialValues={pick(
          data,
          "Date",
          "CustomerName",
          "ProjectCodeName",
          "PurchaseRequestNo",
          "TransportWorkOrderNo",
          "OurRefNoDate",
          "YourRefNoDate",
          "VendorCodeName",
          "VendorGSTNo",
          "FNCGSTNo",
          "Warehouse",
          "DeliveryAt",
          "DeliveryPeriodFrom",
          "DeliveryPeriodTo",
          "Inspection",
          "PaymentTerms",
          "Percentage",
          "Days",
          "Currency",
          "LDClause",
          "PriceVariationClause",
          "MachinePurchaseOrderValue"
        )}
        materials={data.materials}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
