import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../style/materialSystem.css";
import WarehouseReceiptVoucherform from "../../../forms/MaterialSystem/WarehouseReceiptVoucherForm";
import { warehouseReceiptVoucher } from "../../../redux/actions/materialSystemAction";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allGIV = [];

export const WarehouseReceiptVoucherformCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [poMaterials, setPoMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [deliveryChallanList, setDeliveryChallanList] = useState([]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(warehouseReceiptVoucher(formValues, history));
  };

  const onSelectGoodsInspection = async (GoodsInspectionVoucherNo) => {
    const item = allGIV.find(
      (item) => item.GoodsInspectionVoucherNo === GoodsInspectionVoucherNo
    );
    try {
      if (item) {
        const { data } = await axios.get(
          `/materialManagement/goodsInspection/${item.id}`
        );
        console.log(data);
        if (data.GoodsReceiptType === "RECEIPT FROM VENDORS AGAINST PO​") {
          const { data: order } = await axios.get(
            `/materialManagement/purchaseOrder/findByOrderNo/${data.PurchaseOrderNo}`
          );
          if (order.amendment) setPoMaterials(order.amendment.materials);
          else setPoMaterials(order.materials);
        }
        setMaterials(data.materials);
        setFormDetail({
          ...data,
        });
        const { data: challan } = await axios.post(
          `/materialManagement/warehouseReceipt/getUnusedDeliveryChallan`,
          { ProjectCodeName: data.ProjectCodeName }
        );
        const mapped = challan.map((item) => item.DeliveryChallanNo);
        setDeliveryChallanList(mapped);
      }
    } catch (error) {
      alert("Server Error!");
      console.log(error);
    }
  };

  const onSelectProject = async (value) => {
    try {
      const { data } = await axios.post(
        "/materialManagement/warehouseReceipt/details/",
        { ProjectCodeName: value }
      );
      const mapped = data.map((item) => item.GoodsInspectionVoucherNo);
      allGIV = data;
      setList(mapped);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProject = async () => {
    try {
      const { data } = await axios.get("/salesAccounting/projectCircular/");
      let accepted = data.filter((item) => item.Status === "accepted");
      const mapped = accepted.map((item) => item.ProjectShortDescription);
      setProjectList(mapped);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Receipt Voucher  Create"
        linkName1=" Warehouse Receipt Voucher  Dashboard"
        to1="/materialManagement/warehouseReceipt"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Receipt Voucher form
      </h3>
      <div className="line"></div>
      <WarehouseReceiptVoucherform
        list={list}
        onSelectGoodsInspection={onSelectGoodsInspection}
        onSubmit={onSubmit}
        onSelectProject={onSelectProject}
        projectList={projectList}
        deliveryChallanList={deliveryChallanList}
        initialValues={pick(
          formDetail,
          "GoodsInspectionVoucherNo",
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "StoredAt",
          "VendorCodeName",
          "VendorGstNo",
          "CustomerCodeName",
          "WarehouseCodeName",
          "DeliveryChallanNo",
          "DeliveryDate",
          "LRRRConsignmentNo",
          "LRDate",
          "DateofReceipt",
          "BatchIdentificationNo",
          "GoodsReceiptVoucherNo",
          "GoodsStockType",
          "WarehouseReceiptVoucherValue",
          "PurchaseDate",
          "PurchaseOrderNo",
          "GoodsReceiptType"
        )}
        materialsTable={materials}
        poMaterials={poMaterials}
      />
    </div>
  );
};
