import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";

const GoodsReceiptVoucher = ({ storeTableData, materials, materialsQty }) => {
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [inputFields, setInputFields] = useState([
    {
      No: "",
      MaterialCode: "",
      MaterialDescription: "",
      HSNSACCode: "",
      UOM: "",
      Quantity: 0,
      Remarks: "",
      id: "",
    },
  ]);
  useEffect(() => {
    let values = [];
    setLoading(true);
    if (materials) {
      for (let i = 0; i < materials.length; i++) {
        values = [
          ...values,
          {
            No: materials[i].SerialNo,
            MaterialCode: materials[i].MaterialCode,
            MaterialDescription: materials[i].MaterialDescription,
            HSNSACCode: materials[i].HSNSACCode,
            UOM: materials[i].UOM,
            Quantity: materials[i].Quantity,
            Remarks: materials[i].Remarks,
            id: materials[i].id,
          },
        ];
      }
      setInputFields(values);
      setLoading(false);
      onChange(values);
    }
    // eslint-disable-next-line
  }, [materials]);

  const onChange = (values) => {
    if (values) {
      storeTableData(values);
    } else {
      storeTableData({ error: true, errorKind: "some input field is empty" });
    }
  };

  const handleChangeInput = (index, event) => {
    try {
      const values = [...inputFields];
      const row = values[index];
      let value = event.target.value;
      if (event.target.value < 0) {
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
      const item = materials.find(
        (item) => item.MaterialCode === row.MaterialCode
      );
      if (+item.Quantity < +event.target.value) {
        enqueueSnackbar(
          `Quantity can not be more than ${item.Quantity}`,
          {
            variant: "warning",
          }
        );
        return;
      } else {
        row[event.target.name] = value;
        values[index] = row;
        setInputFields(values);
        onChange(values);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemarksInput = (index, event) => {
    const values = [...inputFields];
    const row = values[index];
    row[event.target.name] = event.target.value;
    values[index] = row;
    setInputFields(values);
    onChange(values);
  };

  const fixedDecimal = (index, event, fieldName) => {
    const values = [...inputFields];
    const decimal = 2;

    if (event.target.name === fieldName) {
      let value = event.target.value;
      try {
        value = value.split(".");
        if (value[1].length > decimal) {
          value[1] = value[1].substring(0, decimal);
        }
        let no = `${value[0]}.${value[1]}`;
        values[index][event.target.name] = no;
      } catch (e) {
        console.error(e);
        values[index][event.target.name] = event.target.value;
      }
    } else values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };
  return (
    <div className="tableContainer">
    
        <table>
          <thead className="table-head">
            <tr>
              <th className="head-cell">No.</th>
              <th className="head-cell">Material Code</th>
              <th className="head-cell">Material Description</th>
              <th className="head-cell">HSN / SAC Code</th>
              <th className="head-cell">UOM</th>
              <th className="head-cell">Quantity</th>
              <th className="head-cell">Remarks</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {inputFields.map((inputField, index) => (
              <tr key={index}>
                <td className="table-data1">{inputField.No}</td>
                <td className="table-data2">{inputField.MaterialCode}</td>
                <td className="table-data2">
                  {inputField.MaterialDescription}
                </td>
                <td className="table-data2">{inputField.HSNSACCode}</td>
                <td className="table-data2">{inputField.UOM}</td>
                <td className="table-data2">
                  <input
                    style={{ width: "10rem" }}
                    name="Quantity"
                    value={inputField.Quantity}
                    type="number"
                    min={0}
                    step={0.01}
                    onChange={(event) => {
                      handleChangeInput(index, event);
                      fixedDecimal(index, event, "Quantity");
                    }}
                  />
                </td>
                <td className="table-data4">
                  <input
                    style={{ width: "10rem" }}
                    name="Remarks"
                    value={inputField.Remarks}
                    onChange={(event) => handleRemarksInput(index, event)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
 
    </div>
  );
};

export default GoodsReceiptVoucher;
