import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RoleList from "../../helpers/RoleList";

export const AccountingSystemNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.BU_HEAD.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.CASHIER.code,
    RoleList.DIRECTOR.code,
    RoleList.SITE_STORE_KEEPER.code,
  ],
  label: "Accounting System",
  name: "AccountingSystem",
  attrModule: "projectAccounting",
  icon: <AccountBalanceIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/accountingSystem/systemParameter",
      label: "System Parameter",
      access: [RoleList.ADMIN.code],
    },
    {
      to: "/accountingSystem/paymentRequest/",
      label: "Payment Request",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/accountingSystem/receiptEmployeeOther/",
      label: "Payment Receipt Employee and Others",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/accountingSystem/receiptCustomerInvoice/",
      label: "Payment Receipt Customer",
      access: [
        RoleList.ADMIN.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/accountingSystem/scrutinySubSupplier",
      label: "Scrutiny Sub-Supplier",
      access: [
        RoleList.ADMIN.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/scrutinySubContractor",
      label: "Scrutiny Sub-Contractor",
      access: [
        RoleList.ADMIN.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,

        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/scrutinyEmployeeeAndOthers",
      label: "Scrutiny Employee and Others",
      access: [
        RoleList.ADMIN.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/journalSubSupplier/",
      label: "Journal Sub-Supplier",
      access: [RoleList.ADMIN.code, RoleList.CASHIER.code],
    },
    {
      to: "/accountingSystem/journalSubContractor/",
      label: "Journal Sub-Contractor",
      access: [
        RoleList.ADMIN.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
      ],
    },
    {
      to: "/accountingSystem/journalOther/",
      label: "Journal Others",
      access: [
        RoleList.ADMIN.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
      ],
    },
    {
      to: "/accountingSystem/disbursementSubSupplier/",
      label: "Disbursement Sub-Supplier",
      access: [
        RoleList.ADMIN.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/disbursementSubContractor",
      label: "Disbursement Sub-Contractor",
      access: [
        RoleList.ADMIN.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/disbursementEmployeeAndOthers",
      label: "Disbursement Employee and Other",
      access: [
        RoleList.ADMIN.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
      ],
    },
    {
      to: "/accountingSystem/accountingSystemReports",
      label: "Reports",
      access: [RoleList.ADMIN.code],
    },
  ],
};
