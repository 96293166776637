import React, { useState, useEffect } from "react";
import { change, Field, reduxForm } from "redux-form";
import Table from "../../Tables/MaterialSystem/DeliveryChallanTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import * as Control from "../../components/formcomponents/control";
import "./PurchaseOrderAmendmentForm.css";
import "../../components/helpers/form.style.css";

const DeliveryChallanForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
    selection: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [formFields, setFormFields] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const storeTableData = (data) => {
    let total = 0;
    if (data) {
      data.forEach((item) => {
        total += +item.ItemAmount;
      });
      props.dispatch(
        change(
          "DeliveryChallanForm",
          "DeliveryChallanValue",
          (+total).toFixed(2)
        )
      );
    }

    setFormFields({
      ...formFields,
      DeliveryChallanValue: total,
    });

    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        storedData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  useEffect(() => {
    if (props.type)
      setState({
        storedData: null,
        tableError: false,
        tableErrorKind: "",
        isSubmit: false,
        selection: props.type,
      });
  }, [props.type]);

  const onFormFieldChange = (value, name) => {
    try {
      if (typeof value == "string" || typeof value == "number")
        setFormFields({
          ...formFields,
          [name]: value,
        });
      else {
        setFormFields({
          ...formFields,
          [name]: value.target.value,
        });
      }

      if (name === "PurchaseRequestNo" && props.selectPurchaseReq)
        props.selectPurchaseReq(value, formFields);
      else if (name === "WarehouseIssueVoucherNo" && props.selectWarehouseIssue)
        props.selectWarehouseIssue(value, formFields);
      else if (name === "DeliveryChallanType")
        setState({ ...state, selection: value });
      else if (name === "SourceProject" && props.selectSourceProject)
        props.selectSourceProject(value, formFields);
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.storedData) {
      setState({ ...state, tableError: true });
    } else {
      console.log("submitting");
      props.onSubmit({ ...formValues, tableData: state.storedData });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="DeliveryChallanType"
            component={Control.RenderSelectField}
            label="Select Delivery Challan Type"
            onChange={(value) =>
              onFormFieldChange(value, "DeliveryChallanType")
            }
          >
            <option value=""></option>
            <option value="Purchase Request No​">Purchase Request No</option>
            <option value="Warehouse Issue Voucher No">
              Warehouse Issue Voucher No
            </option>
          </Field>
          <Field
            name="SourceProject"
            component={Control.AutoCompleteInput}
            label="Source Project"
            list={props.ProjectList}
            className="text-field"
            onChange={(value) => onFormFieldChange(value, "SourceProject")}
          />
          <Field
            name="FromWarehouse"
            component={Control.RenderTextField}
            label="From Warehouse"
            disabled
          />
          <Field
            name="SourceGSTNo"
            component={Control.RenderTextField}
            label="Source GST No​"
            onChange={(value) => onFormFieldChange(value, "SourceGSTNo")}
            disabled
          />
          {state.selection === "Purchase Request No​" ? (
            <Field
              name="PurchaseRequestNo"
              component={Control.AutoCompleteInput}
              label="Purchase Request No​"
              list={props.PRList}
              onChange={(value) =>
                onFormFieldChange(value, "PurchaseRequestNo")
              }
            />
          ) : (
            <Field
              name="WarehouseIssueVoucherNo"
              component={Control.AutoCompleteInput}
              label="Warehouse Issue Voucher No​​"
              list={props.WIVList}
              className="text-field"
              onChange={(value) =>
                onFormFieldChange(value, "WarehouseIssueVoucherNo")
              }
            />
          )}
          <Field
            name="DestinationProject"
            component={Control.RenderTextField}
            label="Destination Project​"
            disabled
            onChange={(value) => onFormFieldChange(value, "DestinationProject")}
          />
          <Field
            name="ToWarehouse"
            component={Control.RenderTextField}
            label="To Warehouse"
            disabled
            onChange={(value) => onFormFieldChange(value, "ToWarehouse")}
          />
          <Field
            name="DestinationGSTNo"
            component={Control.RenderTextField}
            label="Destination GST No"
            disabled
            onChange={(value) => onFormFieldChange(value, "DestinationGSTNo")}
          />
          {state.selection === "Purchase Request No​" ? (
            <>
              <Field
                name="Date"
                component={Control.RenderDateField}
                initialValue={props.initialValues.Date}
                label="Date"
                disabled
                onChange={(value) =>
                  onFormFieldChange(value.toLocaleDateString(), "Date")
                }
              />
            </>
          ) : (
            <>
              <Field
                name="WarehouseIssueDate"
                component={Control.RenderDateField}
                initialValue={props.initialValues.WarehouseIssueDate}
                label="Warehouse Issue Date​"
                disabled
                onChange={(value) =>
                  onFormFieldChange(
                    value.toLocaleDateString(),
                    "WarehouseIssueDate"
                  )
                }
              />
              <Field
                name="VendorCodeName"
                component={Control.RenderTextField}
                label="Vendor Code / Name"
                disabled
                onChange={(value) => onFormFieldChange(value, "VendorCodeName")}
              />
              <Field
                name="CustomerCodeName"
                component={Control.RenderTextField}
                label="CustomerCode/Name"
                disabled
                onChange={(value) =>
                  onFormFieldChange(value, "CustomerCodeName")
                }
              />
              <Field
                name="GoodsIssueType"
                component={Control.RenderTextField}
                label="Goods Issue Type "
                disabled
                onChange={(value) => onFormFieldChange(value, "GoodsIssueType")}
              />
              <Field
                name="WorkOrderNo"
                component={Control.RenderTextField}
                label="Work Order No"
                disabled
                onChange={(value) => onFormFieldChange(value, "WorkOrderNo")}
              />
              <Field
                name="SalesOrderNo"
                component={Control.RenderTextField}
                label="Sales Order No"
                disabled
                onChange={(value) => onFormFieldChange(value, "SalesOrderNo")}
              />
            </>
          )}
          <Field
            name="GoodsStockType"
            component={Control.RenderSelectField}
            label="Goods Stock Type"
            onChange={(value) => onFormFieldChange(value, "GoodsStockType")}
          >
            <option value=""></option>
            <option value="REGULAR STOCK-CAPITAL">REGULAR STOCK-CAPITAL</option>
            <option value="REGULAR STOCK-REVENUE">REGULAR STOCK-REVENUE</option>
            <option value="TRANSIT STOCK">TRANSIT STOCK</option>
            <option value="DIRECT SUPPLY">DIRECT SUPPLY</option>
            <option value="SCRAP STOCK">SCRAP STOCK</option>
            <option value="MAINTENANCE STOCK">MAINTENANCE STOCK</option>
            <option value="CLIENT'S  STOCK NIL VALUE">
              CLIENT'S STOCK NIL VALUE
            </option>
            <option value="CLIENTS STOCK WITH RATE">
              CLIENTS STOCK WITH RATE
            </option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="TransportWORequired"
            label="Transport WO Required​"
          >
            <option value=""></option>
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No​</option>
          </Field>
          <Field
            name="TransportType"
            component={Control.RenderSelectField}
            label="Transport Type"
          >
            <option value=""></option>
            <option value="By Road">By Road</option>
            <option value="By Rail">By Rail</option>
            <option value="By Air">By Air</option>
            <option value="By Courier">By Courier</option>
          </Field>
          <Field
            name="LRRRConsignment"
            component={Control.RenderTextField}
            label="LR / RR / Consignment No"
          />
          <Field
            name="LRDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.LRDate}
            label="LR Date"
          />
          <Field
            name="Remarks"
            component={Control.RenderTextField}
            label="Remarks"
          />
          <Field
            type="number"
            name="DeliveryChallanValue"
            component={Control.RenderTextField}
            label="Delivery Challan Value"
            disabled
          />
        </div>
        <div className="excel">
          <Table
            storeTableData={storeTableData}
            materialsTableEdit={props.materialsTableEdit}
            materialsTableCreate={props.materialsTableCreate}
            stocksDetails={props.stocksDetails}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Delivery Challan"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "DeliveryChallanType",
    "GoodsStockType",
    "LRRRConsignment",
    "DeliveryChallanValue",
    "Remarks",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "DeliveryChallanForm",
  validate,
  enableReinitialize: true,
})(DeliveryChallanForm);
