import React, { useEffect, useState } from "react";
import Moment from "moment";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { readAllFromQueryParams } from "../../../utils/queryParams";

export const SalesOrderAmendmentDashboard = () => {
  const [lists, setLists] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          "/salesAccounting/salesOrderAmendment",
          {
            params: {
              status: "not_reviewed",
              page: 1,
              ...readAllFromQueryParams(search),
            },
          }
        );
        const newData = [
          ...new Map(
            data.data.map((item) => [item["SalesOrderAmendmentNo"], item])
          ).values(),
        ];
        newData.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        newData.forEach((element) => {
          element.SalesOrderAmendmentNo =
            element.SalesOrderAmendmentNo + "-" + element.ProjectCodeName;
          let startDate = new Date(element.created_at).toLocaleString();
          let approvedDate = new Date(element.approved_at).toLocaleString();
          let endDate = new Date(element.updated_at).toLocaleString();
          element.updated_at = Moment(endDate).format("DD/MM/YYYY, h:mm a");
          element.created_at = Moment(startDate).format("DD/MM/YYYY, h:mm a");

          element.approved_at =
            element.approved_at &&
            Moment(approvedDate).format("DD/MM/YYYY, h:mm:ss a");
        });

        setLists(newData);
        setPaginatedResults({ ...data, data: newData });
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [dispatch, history, search]);

  return (
    <>
      <BreadCrumbs name="Sales Order Amendment DashBoard" />

      <Table
        paginateResults={paginatedResults}
        DeleteLink="/salesAccounting/salesOrderAmendment"
        lists={lists}
        tablecol1="SalesOrderAmendmentNo"
        heading="Sales Order Amendment DashBoard"
        label1="Sales Order No"
        link="/salesAccounting/salesOrderAmendment/create"
        editLink="/salesAccounting/salesOrderAmendment/edit"
        viewLink="/salesAccounting/salesOrderAmendment/view"
        button={button}
        visibleIcon={visibleIcon}
        statusfilter={["all", "accepted", "not_reviewed"]}
        defaultStatusValue="not_reviewed"
        enableFilters={["Available"]}
      />
    </>
  );
};
