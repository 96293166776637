import React, { useState, useEffect } from "react";
// import LinearProgress from "@material-ui/core/LinearProgress";
export const SalesOrderPaymentDataTableView = (props) => {
  const [data, setData] = useState([]);
  const { payments } = props;

  useEffect(() => {
    let values = [];
    if (payments) {
      values = [...payments];
    }
    setData(values);
  }, [payments]);

  return (
    <div className="tablecontainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo</th>
            <th className="head-cell">PaymentTerms</th>
            <th className="head-cell">Percentage</th>
            <th className="head-cell">Days</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="table-data1">{row.SerialNo}</td>
              <td className="table-data3">{row.PaymentTerms}</td>
              <td className="table-data3">{row.Percentage}</td>
              <td className="table-data4">{row.Days}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
