import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  MonthView,
} from "@devexpress/dx-react-scheduler-material-ui";
import useStyles from "../helpers/UseStyle";
import Skeleton from "@material-ui/lab/Skeleton";

const Calender = ({ details, Content }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const classes = useStyles();

  const currentDateChange = (currentDate) => {
    setCurrentDate(currentDate);
  };

  return (
    <>
      <Paper>
        {details ? (
          <>
            <Scheduler data={details} height={660}>
              <ViewState
                currentDate={currentDate}
                onCurrentDateChange={currentDateChange}
              />

              <MonthView />
              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <Appointments />
              <AppointmentTooltip showCloseButton contentComponent={Content} />
            </Scheduler>
          </>
        ) : (
          <div className={classes.Container}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={600} />
          </div>
        )}
      </Paper>
    </>
  );
};
export default Calender;
