import React,{useState} from "react";
import { Field, reduxForm } from "redux-form";
import Table from "../../Tables/CommonMaster/GSTTable";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Customer = (props) => {
    const [state, setState] = useState({
      tableData: null,
      tableError: false,
      tableErrorKind: "",
      isSubmit: false,
    });
    const [show, setShow] = useState(false);

    const tableData = (data) => {
      console.log(data,"TTTTTTTTTTTTTTTT");
      if (!data) {
        setState({
          ...state,
          tableErrorKind: "table row are not fully field",
          tableError: true,
        });
      } else {
        setState({
          ...state,
          tableData: data,
          tableErrorKind: "",
          tableError: false,
        });
      }
    };
    const toggleModal = () => setShow(!show);
    const onSubmit = (formValues) => {
      if (!state.tableData) {
        setState({ tableError: true });
      } else if (state.tableData) {
        props.onSubmit({
          ...formValues,
          tableData: state.tableData,
        });
      }
    };

  return (
    <div>
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="CustomerCode"
            label="Customer Code"
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
          />
          <Field
            component={Control.RenderTextField}
            name="CorporateOffice"
            label="Corporate Office"
          />
          <Field
            component={Control.RenderTextField}
            name="RegisteredOffice"
            label="Registered Office"
          />
          <Field
            component={Control.RenderTextField}
            name="Phone"
            label="Phone"
          />
          <Field
            component={Control.RenderTextField}
            name="Email"
            label="Email"
          />
          <Field
            component={Control.RenderTextField}
            name="Website"
            label="Website"
          />
          <Field component={Control.RenderTextField} name="PAN" label="PAN" />
          <Field
            component={Control.RenderTextField}
            name="ServiceTaxRegNo"
            label="Service Tax Reg No"
          />
          <Field
            component={Control.RenderTextField}
            name="CINLLPIN"
            label="CIN / LLPIN"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
        <div className="">
          <Table
            tableData={tableData}
            Gsts={props.gsts}
            edit={props.edit}
            StateList={props.stateList}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Customer"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "CustomerCode",
    "CustomerName",
    "Phone",
    "Email",
    "GSTRegNo",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "Customer",
  validate,
  enableReinitialize: true,
})(Customer);
