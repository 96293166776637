import React, { useState, useEffect } from "react";
import StockTable from "../../../components/MaterialSystemDisplay/StockTable";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const StockStatement = () => {
  const classes = useStyle();
  const [stocks, setStocks] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(
        "/materialManagement/shared/reports/allStocks"
      );
      setStocks(data);
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Stock Statement Dashboard"
        linkName1="All Material Reports"
        to1="/materialManagement/othersReports"
      />
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>Stock Statement</h3>
        {/* <div style={{ display: "flex" }}>
          <FilterOption />
          <div style={{ marginLeft: "10%" }}>
            <SearchOption />
          </div>
          <div style={{ marginLeft: "15%", display: "flex" }}>
            <FromDatePicker /> &nbsp;&nbsp;
            <ToDatePicker />
          </div>
        </div> */}
        <StockTable stocks={stocks} />
      </Paper>
    </div>
  );
};
