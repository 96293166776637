import axios from "../../components/apis/axios";
import { userLogout } from "./userAction";

// approval /: id

export const approvalMachineManagement =
  (link, id, history, data) => async (dispatch) => {
    console.log(JSON.stringify(data));
    axios
      .put(`/machineManagement/${link}/approval/${id}`, data)
      .then((res) => {
        console.log(res);
        history.push(`/machineManagement/${link}`);
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

export const DeleteSingle = (link, history) => {
  return async (dispatch) => {
    console.log(history);
    try {
      await axios.put(link);
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(userLogout(history));
      }
      history.push("/");
    }
  };
};

// DashBoardAction
export const allMachinePurchaseRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machinePurchaseRequest"
      );
      dispatch({
        type: "SET_ALL_MACHINE_PURCHASE_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editMachinePurchaseRequest = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machinePurchaseRequest/${Id}`,
        formValues
      );
      alert("Updated Successfully");
      history.push("/machineManagement/machinePurchaseRequest");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachinePurchaseRequestById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machinePurchaseRequest/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_PURCHASE_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allMachinePurchaseOrder = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machinePurchaseOrder"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_PURCHASE_ORDER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const editMachinePurchaseOrder = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machinePurchaseOrder/${Id}`,
        formValues
      );
      alert("Updated Successfully");
      history.push("/machineManagement/machinePurchaseOrder");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachinePurchaseOrderById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machinePurchaseOrder/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_PURCHASE_ORDER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachinePurchaseOrderAmendment = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machinePurchaseOrderAmendment"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_PURCHASE_ORDER_AMENDMENT",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editMachinePurchaseOrderAmendment = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machinePurchaseOrderAmendment/${Id}`,
        formValues
      );
      alert("Updated Successfully");
      history.push("/machineManagement/machinePurchaseOrderAmendment");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachinePurchaseOrderAmendmentById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machinePurchaseOrderAmendment/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_PURCHASE_ORDER_AMENDMENT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachineReceipt = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/machineManagement/machineReceipt");
      dispatch({
        type: "SET_ALL_MACHINE_RECEIPT",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in machineReceipt", err);
    }
  };
};

export const filterAllMachineReceipt = (params) => {
  return async (dispatch) => {
    try {
      const { data: machineReceipt } = await axios.get(
        "/machineManagement/machineReceipt",
        {
          params,
        }
      );
      machineReceipt.forEach((element) => {
        element.created_at = new Date(element.created_at).toDateString();
      });
      dispatch({
        type: "SET_FILTERED_MACHINE_RECEIPT",
        payload: machineReceipt,
      });
    } catch (err) {
      alert(err);
      console.log("Error in machineReceipt", err);
    }
  };
};

export const allMinorMachineLink = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("machineManagement/minorMachineLink");
      console.log(data);
      dispatch({
        type: "SET_ALL_MINOR_MACHINE_LINK",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editMinorMachineLink = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/machineManagement/minorMachineLink/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/machineManagement/minorMachineLink");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMinorMachineLinkById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/minorMachineLink/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MINOR_MACHINE_LINK",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allLogSheetEntry = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("machineManagement/logSheetEntry");
      console.log(data);
      dispatch({
        type: "SET_ALL_LOG_SHEET_ENTRY",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editLogSheetEntry = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/machineManagement/logSheetEntry/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/machineManagement/logSheetEntry");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchLogSheetEntryById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/logSheetEntry/${Id}`
      );

      dispatch({
        type: "SET_SINGLE_LOG_SHEET_ENTRY",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allMachineRepairRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machineRepairRequest"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_REPAIR_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachineRepairRegister = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machineRepairRegister"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_REPAIR_REGISTER",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachinePhysicalVerification = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machinePhysicalVerification"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_PHYSICAL_VERIFICATION",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allMachineRelease = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("machineManagement/machineRelease");
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_RELEASE",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const allMachineHireRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("machineManagement/machineHireRequest");
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_HIRE_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const editMachineHireRequest = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machineHireRequest/${Id}`,
        formValues
      );
      alert("Updated Successfully");
      history.push("/machineManagement/machineHireRequest");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachineHireRequestById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineHireRequest/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_HIRE_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const allMachineDeHireRequest = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "machineManagement/machineDeHireRequest"
      );
      console.log(data);
      dispatch({
        type: "SET_ALL_MACHINE_DE_HIRE_REQUEST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
//   Create
export const machineRepairRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineRepairRequest", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineRepairRequest");
    } catch (error) {
      alert(error);
    }
  };
};
export const editMachineRepairRequest = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machineRepairRequest/${Id}`,
        formValues
      );
      alert("Updated Successfull");
      history.push("/machineManagement/machineRepairRequest");
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachineRepairRequestById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineRepairRequest/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_REPAIR_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const machineRepairRegister = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineRepairRegister", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineRepairRegister");
    } catch (error) {
      alert(error);
    }
  };
};
export const editMachineRepairRegister = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machineRepairRegister/${Id}`,
        formValues
      );
      alert("Updated Successfull");
      history.push("/machineManagement/machineRepairRegister");
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachineRepairRegisterById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineRepairRegister/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_REPAIR_REGISTER",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const machinePurchaseRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machinePurchaseRequest", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machinePurchaseRequest");
    } catch (error) {
      alert(error);
    }
  };
};

export const machinePurchaseOrder = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machinePurchaseOrder", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machinePurchaseOrder");
    } catch (error) {
      alert(error);
    }
  };
};

export const machinePurchaseOrderAmendments = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post(
        "/machineManagement/machinePurchaseOrderAmendment",
        formValues
      );
      alert("Submit Successfull");
      history.push("/machineManagement/machinePurchaseOrderAmendment");
    } catch (error) {
      alert(error);
    }
  };
};

export const machinePhysicalVerification = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post(
        "/machineManagement/machinePhysicalVerification",
        formValues
      );
      alert("Submit Successfull");
      history.push("/machineManagement/machinePhysicalVerification");
    } catch (error) {
      alert(error);
    }
  };
};
export const editMachinePhysicalVerification = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machinePhysicalVerification/${Id}`,
        formValues
      );
      alert("Updated Successfull");
      history.push("/machineManagement/machinePhysicalVerification");
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachinePhysicalVerificationById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machinePhysicalVerification/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_PHYSICAL_VERIFICATION",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const machineHireRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineHireRequest", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineHireRequest");
    } catch (error) {
      alert(error);
    }
  };
};

export const logSheetEntry = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/logSheetEntry", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/logSheetEntry");
    } catch (error) {
      alert(error);
    }
  };
};

export const minorMachineLink = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/minorMachineLink", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/minorMachineLink");
    } catch (error) {
      alert(error);
    }
  };
};
export const machineReceipt = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineReceipt", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineReceipt");
    } catch (error) {
      alert(error);
    }
  };
};
//Edit
export const editMachineReceipt = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/machineManagement/machineReceipt/${Id}`, formValues);
      alert("Updated Successfully");
      history.push("/machineManagement/machineReceipt");
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachineReceiptById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineReceipt/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_RECEIPT",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const machineDeHireRequest = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineDeHireRequest", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineDeHireRequest");
    } catch (error) {
      alert(error);
    }
  };
};

export const editMachineDeHireRequest = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(
        `/machineManagement/machineDeHireRequest/${Id}`,
        formValues
      );
      alert("Updated Successfull");
      history.push("/machineManagement/machineDeHireRequest");
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};

export const searchMachineDeHireRequestById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineDeHireRequest/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_DE_HIRE_REQUEST",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const machineRelease = (formValues, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/machineManagement/machineRelease", formValues);
      alert("Submit Successfull");
      history.push("/machineManagement/machineRelease");
    } catch (error) {
      alert(error);
    }
  };
};
export const editMachineRelease = (formValues, Id, history) => {
  return async (dispatch) => {
    try {
      await axios.put(`/machineManagement/machineRelease/${Id}`, formValues);
      alert("Updated Successfull");
      history.push("/machineManagement/machineRelease");
    } catch (err) {
      alert(err);
      console.log("Error in purchaseRequest", err);
    }
  };
};
export const searchMachineReleaseById = (Id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/machineManagement/machineRelease/${Id}`
      );
      dispatch({
        type: "SET_SINGLE_MACHINE_RELEASE",
        payload: data,
      });
    } catch (err) {
      console.log("Error in purchaseRequest", err);
    }
  };
};
