import React from "react";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { createEmployeeRelease } from "../../../redux/actions/HRManagementActions";
import EmployeeRelease from "../../../forms/ProjectHRManagement/EmployeeRelease/EmployeeRelease";

export const EmployeeReleaseCreate = () => {
  const history = useHistory();

  const onSubmit = (formValues) => {
    createEmployeeRelease(formValues, history);
  };

  return (
    <div>
      <Breadcrumbs
        name="Employee Release Create"
        linkName1="Employee Release Dashboard"
        to1="/project-hr-management/employee-release"
      />
      <FormHeading label="Employee Release" />
      <EmployeeRelease
        onSubmit={onSubmit}
      />
    </div>
  );
};
