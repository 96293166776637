import React from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "../PublicRouter";
import LoginPage from "../../pages/LoginPage";

const LoginRoutes = (
  <Switch>
    <PublicRoute exact path="/" component={LoginPage} />
  </Switch>
);

export default LoginRoutes;
