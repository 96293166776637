import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import RoleList from "../../helpers/RoleList";

export const CommonMasterNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.BU_HEAD.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.CASHIER.code,
    RoleList.DIRECTOR.code,
    RoleList.SITE_STORE_KEEPER.code,
  ],
  label: "Common Master",
  name: "CommonMaster",
  attrModule: "commonMaster",
  icon: <RecordVoiceOverIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/commonMaster/companyDetails",
      label: "Company Details",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/businessUnit",
      label: "Business Unit",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/customer",
      label: "Customer",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/employeeDetails",
      label: "Employee Masters",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/costCentre",
      label: "Cost Centre",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/wareHouse",
      label: "WareHouse",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/currency",
      label: "Currency",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/invoiceType",
      label: "Invoice Type",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/recoveryType",
      label: "Recovery Type",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/unitOfMeasurement",
      label: "Unit Of Measurement",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/bank",
      label: "Bank",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/account",
      label: "Account",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/activity",
      label: "Activity",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/materialGroup",
      label: "Material Group",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/material",
      label: "Material",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/machine",
      label: "Machine",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/machineClass",
      label: "Machine Class",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/machineGroup",
      label: "Machine Group",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/vendor",
      label: "Vendor",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/goodsReceiptType",
      label: "Goods Receipt Type",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/majorSCItemGroup",
      label: "Major SC Item Group",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/scItemCodeRequest",
      label: "SC Item Code Request",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/shifts",
      label: "Shift Master",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/benefits",
      label: "Benefits Master",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/commonMaster/workmen",
      label: "Workmen Master",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
  ],
};
