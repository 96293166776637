import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Currency from "../../../forms/CommonMaster/Currency";
import { useHistory } from "react-router-dom";
import {
  editCurrency,
  searchCurrencyById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CurrencyEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchCurrencyById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleCurrency) {
      setData(store.SingleCurrency);
    }
  }, [store.SingleCurrency]);

  const onSubmit = (formValues) => {
    dispatch(editCurrency(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="Currency Edit"
        linkName1="Currency Dasboard"
        to1="/commonMaster/currency"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Currency
      </h3>
      <div className="line"></div>
      <Currency
        initialValues={pick(data, "CurrencyCode", "CurrencyName", "Tags")}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
