import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import GoodsReceiptType from "../../../forms/CommonMaster/GoodsReceiptType";
import { goodsReceiptType } from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const GoodsReceiptTypeCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(goodsReceiptType(formValues, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Good Recepit Create"
        linkName1="Good Recepit Dashboard"
        to1="/commonMaster/goodsReceiptType"
      />
      <FormHeading label="Good Receipt" />
      <GoodsReceiptType onSubmit={onSubmit} />
    </div>
  );
};
