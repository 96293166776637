import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalAccountingSystem } from "../../../redux/actions/AccountingSystemAction";
import Table from "../../../Tables/AccountingSystem/View/BankTableView";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import NotesTable from "../../../Tables/AccountingSystem/View/NotesTable";
import { useSnackbar } from "notistack";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import RoleList from "../../../components/helpers/RoleList";

export const SystemParameterView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/systemParameter/${match.params.id}`
        );
        console.log(data);
        data.created_at = new Date(data.created_at).toLocaleString();
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalAccountingSystem(
        "systemParameter",
        match.params.id,
        history,
        data,
        enqueueSnackbar
      )
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name="System Parameter View"
        linkName1="System Parameter Dashboard"
        to1="/accountingSystem/systemParameter"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>System Parameter </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by &&
              ` ${data.created_by.first_name} ${data.created_by.last_name}`
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>System Parameter No:</h6>
                  <p>{data.SystemParameterNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Cost Centre:</h6>
                  <p>{data.CostCentre}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Financial Period:</h6>
                  <p>{data.FinancialPeriod}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Currency:</h6>
                  <p>{data.Currency}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Payment Request:</h6>
                  <p>{data.PaymentRequest}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Receipt Voucher:</h6>
                  <p>{data.ReceiptVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Scrutiny Voucher:</h6>
                  <p>{data.ScrutinyVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Purchase Journal Voucher:</h6>
                  <p>{data.PurchaseJournalVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Service Journal Voucher:</h6>
                  <p>{data.ServiceJournalVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Journal Voucher:</h6>
                  <p>{data.JournalVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Payment Voucher:</h6>
                  <p>{data.PaymentVoucher}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Contra Entry:</h6>
                  <p>{data.ContraEntry}</p>
                </Paper>
              </div>
              <div style={{ paddingLeft: "10%" }}>
                <NotesTable CashDetails={data.CashDetails} />
                <Table BankDetails={data.BankDetails} />
              </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}

            {data.Comment && (
              <Paper className={classes.paper}>
                <h6 style={{ color: "red" }}>Comment:</h6>
                <p>{data.Comment}</p>
              </Paper>
            )}
            {(post === RoleList.ADMIN.code ||
              post === RoleList.BU_HEAD.code ||
              post === "companyHead" ||
              post === "projectCoOrdinator" ||
              post === "financeSecond") &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"System Parameter"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};