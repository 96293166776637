import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: "50px",
      width: "150px",
      alignItems: "center",
      borderRadius: "50px",
      outline: "none",
    },
    font: {
      fontSize: "18px",
      fontWeight: 600,
      textAlign: "center",
    },
    forgotPassword: {
        textTransform: 'capitalize',
        '&:hover': {
            color: 'orange',
        },
    },
}))