import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachineHireRequest from "../../../forms/MachineManagementSystem/MachineHireRequest";
import { machineHireRequest } from "../../../redux/actions/MachineManagementaction";

export const MachineHireRequestCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machineHireRequest(formValues, history));
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3 style={{ textAlign: "center" }}>Machine Hire Request</h3>
      <MachineHireRequest onSubmit={onSubmit} />
    </div>
  );
};

