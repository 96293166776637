import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { IconButton } from "@material-ui/core";
import useStyle from "../helpers/UseStyle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DailyAttendanceDetails from "./DailyAttendanceDetails";

export default function DailyAttendenceReportTable(props) {
  const [isList, setIsList] = useState(true);
  const [details, setDetails] = useState(null);
  const classes = useStyle();

  const handleDetailsButtonClick = (name) => {
    setIsList(false);
    setDetails(name);
  };

  return (
    <div>
      {isList ? (
        <TableContainer className={classes.table} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>WorkMan Name</TableCell>
                <TableCell>Project Code Name</TableCell>
                <TableCell>No Of Days Present</TableCell>
                <TableCell>No Of Days Absent</TableCell>
                <TableCell>Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <ListAltIcon color="primary" />
                    {item.WorkmenName}
                  </TableCell>
                  <TableCell align="center">{item.ProjectCodeName}</TableCell>
                  <TableCell align="center">{item.Present}</TableCell>
                  <TableCell align="center">{item.Absent}</TableCell>
                  <TableCell align="center">
                    {props.visibleIcon && (
                      <span>
                        <IconButton
                          onClick={() =>
                            handleDetailsButtonClick(item.WorkmenName)
                          }
                        >
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <DailyAttendanceDetails details={details} />
      )}
    </div>
  );
}
