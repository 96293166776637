import React from "react";
import { Button, TablePagination } from "@material-ui/core";
import useUpdateSearch from "../../utils/hooks/useUpdateSearch";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const Paginator = ({ paginateResults }) => {
  const [updateSearch] = useUpdateSearch();

  const handleChangeRowsPerPage = (event) => {
    updateSearch({
      page: 1,
      per_page: +event.target.value,
    });
  };

  return (
    <TablePagination
      rowsPerPageOptions={[15, 30, 50, 100]}
      color="primary"
      component="div"
      count={paginateResults?.total || 0}
      rowsPerPage={paginateResults?.per_page || 15}
      page={paginateResults?.current_page - 1 || 0}
      ActionsComponent={() => (
        <>
          <Button
            onClick={() => updateSearch({ page: paginateResults?.prev_page })}
            disabled={!paginateResults?.prev_page}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            onClick={() => updateSearch({ page: paginateResults?.next_page })}
            disabled={!paginateResults?.next_page}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </>
      )}
      onPageChange={() => {}}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Paginator;
