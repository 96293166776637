import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "../../../../forms/AccountingSystem/Journal/JournalOtherForm";
import axios from "../../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const JournalOtherEdit = (props) => {
  const [list, setList] = useState([]);
  const [voucherNoList, setVoucherNoList] = useState([]);
  const history = useHistory();
  const [accounts, setAccounts] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState();
  const [costCentreList, setCostCentreList] = useState([]);
  const [formDetail, setformDetail] = useState({});

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/journalOther/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/journalOther/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/journalOther/${props.match.params.id}`
        );
        console.log("data", data);
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/journalOther/");

        setformDetail(data);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectVoucher = async (voucherNo) => {
    try {
      let { data } = await axios.post(
        "/accountingSystem/paymentRequest/findByPaymentRequestNo",
        { PaymentRequestNo: voucherNo }
      );
      setPaymentDetails(data.paymentDetails);
    } catch (error) {}
  };

  const selectProjectName = async (projectName, centre) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        if (detail)
          detail = {
            ...detail,
            JournalOtherCostCentre: centre,
            ProjectCodeName: detail.ProjectShortDescription,
            CustomerName: detail.CustomerName,
            Currency: detail.Currency,
            CostCentre: detail.CostCentre,
          };
      } catch (err) {
        console.error(err);
      }
      try {
        let { data } = await axios.post(
          "/accountingSystem/paymentRequest/findByProjectName/",
          { ProjectCodeName: detail.ProjectShortDescription }
        );
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let tempVList = filtered.map((item) => item.PaymentRequestNo);
        setVoucherNoList({ tempVList });
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
      setformDetail({ ...formDetail, ...detail });
    }
  };
  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
      const fetch = async () => {
        try {
          const { data: accounts } = await axios.get("/commonMaster/account/");
          setAccounts(accounts);
          const { data: centres } = await axios.get(
            "/commonMaster/costCentre/"
          );
          const mapped = centres.map((item) => item.CostCenterName);
          setCostCentreList(mapped);
        } catch (err) {
          console.error(err);
        }
      };
      fetch();
    }
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Journal Others Edit"
        linkName1="Journal Others Dashboard"
        to1="/accountingSystem/journalOther"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Journal Others
      </h3>
      <Form
        onSubmit={onSubmit}
        list={list}
        paymentDetails={paymentDetails}
        voucherNoList={voucherNoList}
        selectVoucher={selectVoucher}
        accounts={accounts}
        selectProjectName={selectProjectName}
        costCentreList={costCentreList}
        initialValues={pick(
          formDetail,
          "JournalOtherCostCentre",
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "CostCentre",
          "PaymentRequestNo",
          "VoucherNo",
          "FinancialPeriod"
        )}
        paymentCredit={formDetail.credits}
        paymentDebit={formDetail.debits}
      />
    </div>
  );
};
