import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  // searchMachineGroupById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const MachineGroupView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/commonMaster/machineGroup/${match.params.id}`
        );
        setData(data);
        data.RequiredOn = new Date(
          new Date(data.RequiredOn) + " UTC"
        ).toDateString();
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [store.userRoot, dispatch, history, match.params.id]);

  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("activity", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
  };
  return (
    <>
      <Breadcrumbs
        name="Machine Group View"
        linkName1="Machine Group Dashboard"
        to1="/commonMaster/machineGroup"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Machine Group View</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.MachineGroupCode && (
              <Paper className={classes.paper}>
                <h6>Machine Group Code:</h6>
                <p>{data.MachineGroupCode}</p>
              </Paper>
            )}
            {data.MachineGroupDes && (
              <Paper className={classes.paper}>
                <h6>Machine Group Description:</h6>
                <p>{data.MachineGroupDes}</p>
              </Paper>
            )}
            {data.MachineGroupLongDes && (
              <Paper className={classes.paper}>
                <h6>Machine Group Long Description:</h6>
                <p>{data.MachineGroupLongDes}</p>
              </Paper>
            )}
            {data.MachineClassCode && (
              <Paper className={classes.paper}>
                <h6>Machine Class Code:</h6>
                <p>{data.MachineClassCode}</p>
              </Paper>
            )}
            {data.MachineClassDes && (
              <Paper className={classes.paper}>
                <h6>Machine Class Description:</h6>
                <p>{data.MachineClassDes}</p>
              </Paper>
            )}
            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>
            )}
            ;
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
