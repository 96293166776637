import axios from "../../components/apis/axios";

export const allProjectName = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/salesAccounting/projectCircular");
      dispatch({
        type: "SET_ALL_PROJECT_NAME",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };
};

export const allActiveProjects = () => {
  return async (dispatch) => {
    try {
      const { data: machineReceipt } = await axios.get(
        `/machineManagement/machineReceipt`
      );
      const { data } = await axios.get("/salesAccounting/projectCircular");

      const machines = machineReceipt.map(
        (item) => item.ProjectCodeName.split(" ")[0]
      );
      const filteredData = data.filter(
        (item) =>
          item.Status === "accepted" && machines.includes(item.ProjectCode)
      );
      let projects = filteredData.map(
        (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
      );
      dispatch({
        type: "SET_ALL_PROJECT_NAME",
        payload: data,
      });
      dispatch({
        type: "SET_ALL_ACTIVE_PROJECTS",
        payload: projects,
      });
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };
};

export const allCustomerName = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/store/customerName");
      dispatch({
        type: "SET_ALL_CUSTOMER_NAME",
        payload: data,
      });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };
};

export const allVendorName = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/store/vendorName");
      dispatch({
        type: "SET_ALL_VENDOR_NAME",
        payload: data,
      });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };
};

export const allCurrency = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/store/currency");
      dispatch({
        type: "SET_ALL_CURRENCY",
        payload: data,
      });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };
};

export const allWareHouse = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/store/WareHouse");
      dispatch({
        type: "SET_ALL_WAREHOUSE",
        payload: data,
      });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };
};
export const allPurchaseRequestNumber = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/store/purchaseRequest");
      dispatch({
        type: "SET_ALL_PURCHASE_REQUEST_NUMBER",
        payload: data,
      });
    } catch (err) {
      alert(err.message);
      console.log(err);
    }
  };
};

export const getProjectNameDetails = (projectName) => {
  return async (dispatch) => {
    try {
      const data = { projectName };
      const { details } = await axios.post("/projectName", data);
      dispatch({
        type: "SET_SINGLE_PROJECT_DETAIL",
        payload: details,
      });
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };
};

export const getMaterialList = (materialName) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/store/material", null, {
        params: { q: materialName },
      });

      dispatch({
        type: "SET_ALL_MATERIAL_LIST",
        payload: data,
      });
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };
};

export const setCurrentPage = (page) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: page,
    });
  };
};
