import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import WarehouseReturnVoucherForm from "../../../forms/MaterialSystem/WarehouseReturnVoucherForm";
import { editwarehouseReturn } from "../../../redux/actions/materialSystemAction";
import "../style/materialSystem.css";

import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];
let AllWarehouseIssueVoucher = [];

export const WarehouseReturnVoucherFormEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.materialSystemRoot);
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [wareHouseIssueList, setWareHouseIssueList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [Warehouse, setWarehouse] = useState("");
  const [stocksDetails, setStocksDetails] = useState(null);
  const [materials, setMaterials] = useState(null);
  const [issuedMaterials, setIssuedMaterials] = useState(null);
  const [previousFiles, setPreviousFiles] = useState([]);

  const { match } = props;

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(
        `/materialManagement/warehouseReturn/${match.params.id}`
      );
      if (data) {
        console.log(data);
        setData({
          ...data,
        });
        setMaterials(data.materials);
        setPreviousFiles([...data.Files]);

        try {
          const { data: issue } = await axios.get(
            `/materialManagement/warehouseIssue/getByWarehouseIssueNo/${data.WarehouseIssueVoucherNo}`
          );

          setIssuedMaterials(issue.materials);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetch();
  }, [store.SingleWarehouseReturn, match.params.id]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editwarehouseReturn(formValues, props.match.params.id, history));
  };

  const selectProject = (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      console.log(detail);
      let payload = {
        ...formDetail,
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
        Warehouse: detail.WareHouse,
      };
      setWarehouse(detail.WareHouse);
      setFormDetail(payload);
      fetchWarehouseIssue(projectName);
    }
  };

  const selectWarehouseIssue = async (issueNo) => {
    const item = AllWarehouseIssueVoucher.find(
      (item) => item.WarehouseIssueVoucherNo === issueNo
    );
    if (item) {
      let { data } = await axios.get(
        `/materialManagement/warehouseIssue/${item.id}`
      );
      setMaterials(data.materials);
      console.log(data);
      try {
        const materialCodes = data.materials.map((item) => item.MaterialCode);
        const { data: stocks } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          {
            WareHouse: Warehouse,
            Code: materialCodes,
          }
        );
        setStocksDetails(stocks);
      } catch (err) {
        console.log(err);
      }
      data = {
        WarehouseIssueVoucher: issueNo,
        GoodsIssueType: data.GoodsIssueType,
        WorkSalesNo: data.WorkSalesNo,
        WorkOrderNo: data.WorkOrderNo,
        CustomerCodeName: data.CustomerCodeName,
        Date: data.Date,
        DateofIssue: data.DateofIssue,
        VendorCodeName: data.VendorCodeName,
        Remarks: data.Remarks,
      };
      setFormDetail({ ...formDetail, ...data });
    }
  };

  async function fetchWarehouseIssue(projectName) {
    try {
      let { data } = await axios.post(
        "/materialManagement/warehouseIssue/getByProjectName",
        {
          ProjectCodeName: projectName,
        }
      );
      AllWarehouseIssueVoucher = data;
      const mapped = data.map((item) => item.WarehouseIssueVoucherNo);
      setWareHouseIssueList(mapped);
    } catch (err) {
      console.error(err);
    }
  }

  async function fetchProject() {
    try {
      let { data } = await axios.get("/salesAccounting/projectCircular");
      const filtered = data.filter((item) => item.Status === "accepted");
      allProject = filtered;
      let PNlist = filtered.map((item) => item.ProjectShortDescription);
      setProjectList(PNlist);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Return Voucher  Edit"
        linkName1=" Warehouse Return Voucher  Dashboard"
        to1="/materialManagement/WarehouseReturn"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Return Voucher Form Edit
      </h3>
      <div className="line"></div>
      <WarehouseReturnVoucherForm
        initialValues={pick(
          data,
          "DateofReturn",
          "Remarks",
          "WarehouseReturnVoucherValue",
          "Warehouse",
          "ProjectCodeName",
          "CustomerName",
          "ProjectName",
          "WarehouseIssueVoucherNo",
          "GoodsIssueType",
          "WorkSalesNo",
          "WorkOrderNo",
          "Date",
          "CustomerCodeName",
          "DateofIssue",
          "VendorCodeName"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        ProjectList={projectList}
        selectProject={selectProject}
        WIlist={wareHouseIssueList}
        selectWarehouseIssue={selectWarehouseIssue}
        materialsDataEdit={materials}
        stocksDetails={stocksDetails}
        previousFiles={previousFiles}
        issuedMaterials={issuedMaterials}
      />
    </div>
  );
};
