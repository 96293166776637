import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RecoveryType from "../../../forms/CommonMaster/RecoveryType";
import { recoveryType } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";

export const RecoveryTypeCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [account, setAccount] = useState([]);
  const onSubmit = (formValues) => {
    dispatch(recoveryType(formValues, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/account");
        let company = data.map((item) =>`${item.AccountCode}-${item.AccountDes}`);
        setAccount(company);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Recovery Type Create"
        linkName1="Recovery Type Dashboard"
        to1="/commonMaster/recoveryType"
      />
      <h3 style={{ textAlign: "center" }}>Recovery Type</h3>
      <RecoveryType onSubmit={onSubmit} account={account} />
    </div>
  );
};
