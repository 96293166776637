import React, { useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import Table from "../../Tables/MaterialSystem/PurchaseOrderTable";
import "./PurchaseOrderAmendmentForm.css";
import * as Control from "../../components/formcomponents/control";
import PaymentTermsTable from "../../Tables/PaymentTerms/PaymentTermsTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const PurchaseOrderAmendmentForm = (props) => {
  const [state, setState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    totalpayments: null,
  });
  const [storedPaymentDetails, setStoredPaymentDetails] = useState({
    paymentTermsTableErrorKind: "",
    paymentTermsTableError: false,
    tableData: null,
    tableError: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const storeTableData = (data) => {
    try {
      let total = 0;
      data.forEach((item) => {
        total += +item.ItemTotal;
      });
      console.log(total);
      props.dispatch(
        change(
          "PurchaseOrderAmendmentForm",
          "PurchaseOrderValue",
          (+total).toFixed(2)
        )
      );
    } catch (err) {
      console.log(err);
    }
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  const paymentTermsTable = (data) => {
    if (!data) {
      setStoredPaymentDetails({
        ...state,
        paymentTermsTableErrorKind: "table row are not fully field",
        paymentTermsTableError: true,
      });
    } else {
      setStoredPaymentDetails({
        ...state,
        tableData: data,
        paymentTermsTableErrorKind: "",
        paymentTermsTableError: false,
      });
    }
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({ ...state, tableError: true });
    } else {
      console.log({ ...formValues, tableData: state.tableData });
      props.onSubmit({
        ...formValues,
        tableData: state.tableData,
        totalpayments: storedPaymentDetails.tableData,
      });
    }
  };

  return (
    <>
      <div className="newformcontainer" AutoCompleteInput="off">
        <form className="form-main">
          <div className="field-container">
            <Field
              name="ProjectCodeName"
              component={Control.AutoCompleteInput}
              label="Project Code / Name"
              className="text-field"
              list={props.PNList}
              onChange={(value) =>
                props.SelectProjectName && props.SelectProjectName(value)
              }
            />
            <Field
              name="CustomerName"
              component={Control.RenderTextField}
              label="Customer Name"
              disabled
            />
            <Field
              name="PurchaseOrderNo"
              component={Control.AutoCompleteInput}
              list={props.POList}
              className="text-field"
              onChange={(value) =>
                props.selectPurchaseOrderNo &&
                props.selectPurchaseOrderNo(value)
              }
              label="Purchase Order No"
            />
            <Field
              name="Date"
              component={Control.RenderDateField}
              initialValue={props.initialValues.Date}
              label="Purchase Order Date"
              // disabled
            />
            <Field
              name="PurchaseRequestNo"
              component={Control.RenderTextField}
              label="Purchase Request No"
              // disabled
            />
            <Field
              name="LastAmendmentNo"
              component={Control.RenderTextField}
              label="Last Amendment No"
              // disabled
            />
            <Field
              name="LastAmendmentDate"
              component={Control.RenderDateField}
              initialValue={props.initialValues.LastAmendmentDate}
              label="Last Amendment Date"
              disabled
            />
            <Field
              name="OurRefNoDate"
              component={Control.RenderTextField}
              label="OurRef. No / Date"
            />
            <Field
              name="YourRefNoDate"
              component={Control.RenderTextField}
              label="Your Ref. No / Date"
            />
            <Field
              name="VendorCodeName"
              component={Control.AutoCompleteInput}
              list={props.VNlist}
              className="text-field"
              onChange={(value) =>
                props.SelectVendorName && props.SelectVendorName(value)
              }
              label="Vendor Code / Name"
            />
            <Field
              name="VendorGSTNo"
              component={Control.AutoCompleteInput}
              list={props.VendorGsts}
              className="text-field"
              label="Vendor GST No"
            />
            <Field
              name="Warehouse"
              component={Control.RenderTextField}
              label="Warehouse"
              disabled
            />
            <Field
              name="FNCGSTNo"
              component={Control.RenderTextField}
              label="Warehouse GST No"
              // disabled
            />
            <Field
              component={Control.RenderSelectField}
              name="DeliveryAt"
              label="Delivery At"
            >
              <option value={""}></option>
              <option value={"Customer WH"}>Customer WH</option>
              <option value={"Own Premises​"}>Own Premises​</option>
              <option value={"Central WH​​"}>Central WH​​</option>
            </Field>
            <Field
              name="DeliveryPeriodFrom"
              component={Control.RenderDateField}
              className="text-field"
              initialValue={props.initialValues.DeliveryPeriodFrom}
              label="Delivery Period From"
            />
            <Field
              name="DeliveryPeriodTo"
              component={Control.RenderDateField}
              className="text-field"
              initialValue={props.initialValues.DeliveryPeriodTo}
              label="Delivery Period To"
            />
            <Field
              component={Control.RenderSelectField}
              name="Inspection"
              label="Inspection"
            >
              <option value={""}></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No​</option>
            </Field>
          </div>
          <div className="excel">
            <PaymentTermsTable
              paymentTermsTable={paymentTermsTable}
              payments={props.payments}
            />
            <div className="middle">
              <p className=" Mui-error excelError">
                {state.paymentTermsTableError &&
                  `${state.paymentTermsTableErrorKind}`}
              </p>
            </div>
          </div>
          <div className="field-container">
            <Field
              name="Currency"
              component={Control.RenderTextField}
              label="Currency"
              // disabled//
            />
            <Field
              name="LDClause"
              component={Control.RenderTextField}
              label="LD Clause"
            />
            <Field
              name="PriceVariationClause"
              component={Control.RenderTextField}
              label="Price Variation Clause"
            />
            <Field
              name="PurchaseOrderValue"
              component={Control.RenderTextField}
              label="Revised Purchase Order Value Incl. Amendment"
              type="number"
              // disabled
            />
          </div>
          <div className="excel">
            <Table
              storeTableData={storeTableData}
              materialsEditData={props.materialsEditData}
              materialsQty={props.materialsQty}
              isAmendment={props.isAmendment}
            />
            <div className="middle">
              <p className=" Mui-error excelError">
                {state.tableError && `${state.tableErrorKind}`}
              </p>
            </div>
          </div>
          <div>
            <Field
              component={Control.UploaderV2}
              name="UploadFiles"
              label="Upload Files​"
              storeUploadedFiles={storeUploadedFiles}
              previousFiles={props.previousFiles}
            />
          </div>
        </form>
        <SubmitModelPopup
          handleSubmitForm={props.handleSubmit(onSubmit)}
          formTitle="Purchase Order Amendment"
          onFormClick={toggleModal}
          open={show}
          onClose={toggleModal}
          closeAfterTransition
        />
      </div>
    </>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "PurchaseOrderNo",
    "PurchaseOrderAmendmentValue",
    "Date",
    "VendorCodeName",
    "VendorGSTNo",
    "FNCGSTNo",
    "Warehouse",
    "DeliveryAt",
    "DeliveryPeriodFrom",
    "DeliveryPeriodTo",
    "Inspection",
    "PaymentTerms",
    "Percentage",
    "Days",
    "Currency",
    "PurchaseOrderValue",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "PurchaseOrderAmendmentForm",
  validate,
  enableReinitialize: true,
})(PurchaseOrderAmendmentForm);
