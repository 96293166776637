import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import { useHistory } from "react-router-dom";
import GoodsReceiptVoucherForm from "../../../forms/MaterialSystem/GoodsReceiptVoucherForm";
import {
  editgoodsReceipt,
  searchGoodsReceiptById,
} from "../../../redux/actions/materialSystemAction";
import "../style/materialSystem.css";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

import axios from "../../../components/apis/axios";

let AllProjectName = [];
let AllDeliveryChallan = [];
let AllVendor = [];
let AllSalesOrder = [];

export const GoodsReceiptVoucherFormEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.materialSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [projectNameList, setprojectNameList] = useState([]);
  const [deliveryChallan, setdeliveryChallan] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [vendorGst, setvendorGst] = useState([]);
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [materials, setMaterials] = useState();
  const [materialsQty, setMaterialsQty] = useState();

  const { match } = props;

  useEffect(() => {
    dispatch(searchGoodsReceiptById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    const getData = async () => {
      if (store.SingleGoodsReceipt) {
        const res = store.SingleGoodsReceipt;
        setData(res);
        setPreviousFiles(store.SingleGoodsReceipt.Files);
        try {
          let url = "";
          if (res.PurchaseRequestNo === null)
            url = `/materialManagement/shared/getPurchaseOrderQty/${res.PurchaseOrderNo}`;
          else
            url = `/materialManagement/shared/getPurchaseRequestQty/${res.PurchaseRequestNo}`;
          const { data: qty } = await axios.get(url);
          setMaterials(res.materials);
          setMaterialsQty(qty.materials);
        } catch (err) {
          console.error(err);
        }
      }
    };
    getData();
  }, [store.SingleGoodsReceipt]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editgoodsReceipt(formValues, props.match.params.id, history));
  };

  const selectPurchaseReq = async (PurchaseRequestNo) => {
    if (PurchaseRequestNo) {
      let tempMaterials;
      try {
        let payload = await axios.get(
          `/materialManagement/purchaseRequest/getByPurchaseRequestNo/${PurchaseRequestNo}`
        );
        tempMaterials = payload.data.materials;
        console.log(payload.data.create_date);
        payload = {
          ...formDetail,
          PurchaseRequestNo: PurchaseRequestNo,
          PurchaseDate: payload.data.created_at,
        };
        setFormDetail(payload);

        const { data } = await axios.get(
          `/materialManagement/shared/getPurchaseRequestQty/${PurchaseRequestNo}`
        );
        for (let i = 0; i < tempMaterials.length; i++) {
          for (let j = 0; j < data.materials.length; j++) {
            if (
              tempMaterials[i].MaterialCode === data.materials[j].MaterialCode
            )
              tempMaterials[i].Quantity = data.materials[j].CurrentQuentity;
          }
        }
        setMaterials(tempMaterials);
        setMaterialsQty(data.materials);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const SelectProjectName = async (ProjectShortDescription, selection) => {
    const item = AllProjectName.find(
      (item) => item.ProjectShortDescription === ProjectShortDescription
    );

    if (item) {
      setFormDetail({
        ...formDetail,
        GoodsReceiptType: selection,
        ProjectCodeName: item.ProjectShortDescription,
        CustomerName: item.CustomerName,
        Warehouse: item.WareHouse,
      });
    }
    try {
      if (selection === "RECEIPT FROM VENDORS AGAINST PO​") {
        let { data } = await axios.post(
          "/materialManagement/shared/getPurchaseOrderByProjectCodeName",
          {
            ProjectCodeName: ProjectShortDescription,
          }
        );
        let POlist = data.map((item) => item.PurchaseOrderNo);
        setPurchaseOrderList(POlist);
      } else {
        const { data: pr } = await axios.post(
          "/materialManagement/shared/getPurchaseRequest/",
          {
            requestedFrom: "GoodsReceipt",
            ProjectCodeName: ProjectShortDescription,
          }
        );
        let mapped = pr.map((item) => item.PurchaseRequestNo);
        setList(mapped);
        const { data } = await axios.post(
          "/salesAccounting/salesOrder/getByProjectName",
          {
            ProjectCodeName: ProjectShortDescription,
          }
        );
        AllSalesOrder = data;
        mapped = data.map((item) => item.SalesOrderNo);
        setSalesOrderList(mapped);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const SelectPurchaseOrder = async (PurchaseOrderNo) => {
    if (PurchaseOrderNo) {
      try {
        let tempMaterials;
        let payload = await axios.get(
          `/materialManagement/purchaseOrder/getByPurchaseOrderNo/${PurchaseOrderNo}`
        );
        console.log("purchaseOrder ->", payload);
        let po = null;
        if (payload.data.amendment) {
          po = payload.data.amendment;
        } else {
          po = payload.data;
        }
        tempMaterials = po.materials;
        payload = {
          ...formDetail,
          PurchaseOrderNo: PurchaseOrderNo,
          VendorCodeName: po.VendorCodeName,
          VendorGstNo: po.VendorGSTNo,
          PurchaseDate: po.created_at,
          PurchaseRequestNo: po.PurchaseRequestNo,
          PurchaseRequestDate: po.Date,
        };
        setFormDetail(payload);

        const { data } = await axios.get(
          `/materialManagement/shared/getPurchaseOrderQty/${PurchaseOrderNo}`
        );
        console.log(data.materials, tempMaterials);
        for (let i = 0; i < tempMaterials.length; i++) {
          for (let j = 0; j < data.materials.length; j++) {
            if (
              tempMaterials[i].MaterialCode === data.materials[j].MaterialCode
            )
              tempMaterials[i].Quantity = data.materials[j].CurrentQuentity;
          }
        }
        setMaterials(tempMaterials);
        setMaterialsQty(data.materials);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const selectSalesOrder = (salesOrder) => {
    let item = AllSalesOrder.find((item) => item.SalesOrderNo === salesOrder);
    if (item) {
      console.log(item);
      item = {
        SalesOrderNo: salesOrder,
        SalesOrderDate: item.created_at,
      };
      setFormDetail({ ...formDetail, ...item });
    }
  };

  const SelectDeliveryChallan = async (DeliveryChallanNo) => {
    const item = AllDeliveryChallan.find(
      (item) => item.DeliveryChallanNo === DeliveryChallanNo
    );
    console.log(item);

    if (item) {
      let payload = await axios.get(
        `/materialManagement/deliveryChallan/${item.id}`
      );
      console.log(payload);
      setMaterials(payload.data.materials);
      payload = {
        ...formDetail,
        DeliveryChallanNo: DeliveryChallanNo,
      };
      setFormDetail(payload);
    }
  };

  const SelectVendor = async (VendorName) => {
    const item = AllVendor.find((item) => item.VendorName === VendorName);
    console.log("AllVendor ", AllVendor);

    if (item) {
      let payload = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gst = payload.data.Gsts;
      const gstRegNos = gst.map((item) => item.GSTRegNo);
      setvendorGst(gstRegNos);
      payload = {
        ...formDetail,
        VendorCodeName: payload.data.VendorName,
      };
      setFormDetail(payload);
    }
  };

  useEffect(() => {
    async function getVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        AllVendor = data;
        let VNList = AllVendor.map((item) => item.VendorName);
        setVendorList(VNList);
      } catch (err) {
        alert(err);
      }
    }
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        AllProjectName = filtered;
        let PNlist = filtered.map((item) => item.ProjectShortDescription);
        setprojectNameList(PNlist);
      } catch (error) {
        alert(error);
      }
    }

    async function fetchDeliveryChallan() {
      try {
        let { data } = await axios.get("/materialManagement/deliveryChallan");
        console.log(data);
        AllDeliveryChallan = data;
        let DCAccepted = AllDeliveryChallan.filter(
          (item) => item.Status === "accepted"
        );
        let DClist = DCAccepted.map((item) => item.DeliveryChallanNo);
        setdeliveryChallan(DClist);
      } catch (error) {
        alert(error);
      }
    }
    getVendor();
    fetchProject();
    fetchDeliveryChallan();
  }, [dispatch, history]);

  return (
    <div>
      <Breadcrumbs
        name="Goods Receipt Voucher Edit"
        linkName1="Goods Receipt Voucher Dashboard"
        to1="/materialManagement/goodsReceipt"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Goods Receipt Voucher Edit
      </h3>
      <div className="line"></div>
      <GoodsReceiptVoucherForm
        initialValues={pick(
          data,
          "GoodsReceiptType",
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "PurchaseOrderNo",
          "PurchaseDate",
          "PurchaseRequestNo",
          "PurchaseRequestDate",
          "WorkOrderNo",
          "WorkOrderDate",
          "WarehouseCodeName",
          "DeliveryChallanNo",
          "DeliveryDate",
          "SalesOrderNo",
          "SalesOrderDate",
          "VendorCodeName",
          "VendorGstNo",
          "CustomerCodeName",
          "LRRRConsignmentNo",
          "LRDate",
          "DateofReceipt",
          "BatchIdentificationNo",
          "UnloadedAt"
        )}
        list={list}
        PNList={projectNameList}
        SelectProjectName={SelectProjectName}
        VNList={vendorList}
        POList={purchaseOrderList}
        SelectPurchaseOrder={SelectPurchaseOrder}
        selectPurchaseReq={selectPurchaseReq}
        SelectDeliveryChallan={SelectDeliveryChallan}
        DClist={deliveryChallan}
        VendorGsts={vendorGst}
        SelectVendor={SelectVendor}
        salesOrderList={salesOrderList}
        selectSalesOrder={selectSalesOrder}
        previousFiles={previousFiles}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        materials={materials}
        materialsQty={materialsQty}
      />
    </div>
  );
};
