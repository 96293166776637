/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
import { useSelector } from "react-redux";
import { useRef } from "react";

const ProjectCircular = (props) => {
  const store = useSelector((store) => store);
  const [startDate, setStartDate] = useState(new Date());
  const ref = useRef(false);

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  useEffect(() => {
    if (
      props?.edit &&
      ref.current === false &&
      Object.keys(props.initialValues).length > 0
    ) {
      props.findGst(
        props.initialValues?.CompanyName?.toUpperCase(),
        "companyDetails"
      );
      props.findGst(props.initialValues?.CustomerName, "customer");
      props.findGst(props.initialValues?.CostCentre, "costCentre");
      props.findGst(props.initialValues?.WareHouse, "wareHouse");
      ref.current = true;
    }
  }, [props?.edit, props.initialValues]);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }

    props.onSubmit(formValues);
  };

  let endDate = new Date();

  const updateValues = (value) => {
    endDate = new Date(value);
    console.log(startDate);
    let timeSpan = (endDate - startDate) / (1000 * 60 * 60 * 24 * 30);
    let months = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24 * 30));
    let days = Math.round((timeSpan - months) * 30) + 1;

    if (months > 0 && days > 0) {
      let netTime = `${months} months and ${days} days`;
      props.dispatch(change("ProjectCircular", "Duration", netTime));
    } else if (months > 0) {
      let netTime = `${months} months`;
      props.dispatch(change("ProjectCircular", "Duration", netTime));
    } else {
      let netTime = `${days} days`;
      props.dispatch(change("ProjectCircular", "Duration", netTime));
    }
    if (months == 1) {
      let netTime = `${months} month and ${days} days`;
      props.dispatch(
        change("SalesOrderAmendmentForm", "RevisedDuration", netTime)
      );
    }
  };

  let compList = props.companyList;
  let isProjectCommon = false;
  if (store.userRoot?.workingOn?.BusinessUnit) {
    isProjectCommon =
      store.userRoot?.workingOn?.BusinessUnit.split("-")?.[1] === "COMMON";

    if (
      (isProjectCommon || store.userRoot.workingOn.Role === "buCommercial") &&
      store.userRoot.workingOn.Company
    ) {
      compList = props.companyList.filter((c) => {
        return c === store.userRoot.workingOn.Company.split("-")[0];
      });
    }
  }

  const buList = useMemo(() => {
    let list = [];
    if (props.user.user.first_name === "admin" || isProjectCommon) {
      list = props.BUList;
    } else {
      list = [store.userRoot?.workingOn.BusinessUnit];
    }

    return list;
  }, [props]);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="CompanyName"
            label="Company Name"
            list={compList}
            className="text-field"
            onChange={(value) => {
              console.log(value);
              props.findGst(value, "companyDetails");
            }}
          />

          <Field
            component={Control.RenderSelectField}
            name="projectStatus"
            label="Project Status"
          >
            <option value=""></option>
            <option value={"Active"}>Active</option>
            <option value={"Inactive"}>Inactive</option>
            <option value={"Closed"}>Closed</option>
          </Field>

          <Field
            component={Control.AutoCompleteInput}
            name="CompanyGstNo"
            list={props.companyGsts}
            className="text-field"
            label="Company GST No"
          />

          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="BusinessUnit"
            list={buList}
            label="Business Unit"
          />

          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="Currency"
            list={props.CList}
            label="Currency"
          />
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="CustomerName"
            list={props.CNList}
            label="Customer Name"
            onChange={(value) => props.findGst(value, "customer")}
          />
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="CustomerGstNo"
            list={props?.customerGsts}
            label="Customer GST"
          />
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="CostCentre"
            label="Cost Centre"
            onChange={(value) => props.findGst(value, "costCentre")}
            list={props.CCList}
          />
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="CostCentreGst"
            label="Cost Centre GST"
            list={props.costCenterGsts}
          />
          {/* <Field
            component={Control.RenderTextField}
            name="CostCentreIfExisting"
            label="Cost Centre(If Existing)"
          /> */}
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="WareHouse"
            label="Ware House"
            list={props.WHList}
            onChange={(value) => props.findGst(value, "wareHouse")}
          />
          <Field
            component={Control.AutoCompleteInput}
            className="text-field"
            name="WareHouseGst"
            label="Ware House GST"
            list={props.warehouseGsts}
          />
          {/* <Field
            component={Control.RenderTextField}
            name="WareHouseIfExisting"
            label="Ware House(If Existing)"
          /> */}
          <Field
            component={Control.AutoCompleteInput}
            name="DirectSub"
            label="Direct / Sub"
            list={["Direct", "Sub"]}
            className="text-field"
          ></Field>
          <Field
            component={Control.RenderTextField}
            name="OwnerIfSub"
            label="Owner (If Sub)"
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectOrderValue"
            type="number"
            label="Project Order Value"
            InputProps={{ inputProps: { min: 0.0, step: 0.01 } }}
          />
          <Field
            component={Control.RenderTextField}
            name="OrderReferenceNo"
            label="Order Reference No​"
          />
          <Field
            component={Control.RenderDatePastField}
            name="OrderReferenceDate"
            label="Order Reference Date"
            initialValue={props.initialValues?.OrderReferenceDate}
          />
          <Field
            component={Control.RenderSelectField}
            name="OrderType"
            label="Order Type"
          >
            <option value={""}></option>
            <option value={"Lump Sum"}>Lump Sum</option>
            <option value={"Unit Rate"}>Unit Rate</option>
            <option value={"Rate Contract"}>Rate Contract</option>
          </Field>
          <Field
            component={Control.RenderTextField}
            name="TenderRefNoIfAny"
            label="Tender Ref No.(If Any)"
          />
          <Field
            component={Control.RenderDatePastField}
            className="text-field"
            name="ProjectStartDate"
            label="Project Start Date"
            initialValue={props.initialValues?.ProjectStartDate}
            onChange={(date) => {
              setStartDate(date);
            }}
          />

          <Field
            component={Control.RenderDateField}
            className="text-field"
            name="ProjectEndDate"
            label="Project End Date"
            initialValue={props.initialValues?.ProjectEndDate}
            onChange={(event) => {
              updateValues(event);
            }}
          />
          <Field
            component={Control.RenderTextField}
            name="Duration"
            label="Duration"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectShortDescription"
            label="Project Short Description"
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectLongDescription"
            label="Project Long Description"
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Invoice To"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="ConsigneeName"
            label="Consignee Name"
          />
          <Field
            component={Control.RenderTextField}
            name="InvoiceAddress"
            label="Invoice Address"
          />
          <Field
            component={Control.RenderTextField}
            name="DeliveryAddress"
            label="Delivery Address"
          />
        </div>
        <Field
          component={Control.UploaderV2}
          name="UploadFiles"
          label="Upload Files​"
          storeUploadedFiles={storeUploadedFiles}
          previousFiles={props.previousFiles}
        />
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Project Circular"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "BusinessUnit",
    "Currency",
    "DirectSub",
    "CustomerName",
    "ProjectOrderValue",
    "OrderReferenceNo",
    "Date",
    "OrderType",
    "ProjectStartDate",
    "ProjectEndDate",
    "Duration",
    "OrderReferenceDate",
    "ProjectShortDescription",
    "ProjectLongDescription",
    "InvoiceTo",
    "ConsigneeName",
    "InvoiceAddress",
    "DeliveryAddress",
    "CostCentre",
    "WareHouse",
    "WareHouseGst",
    "CostCentreGst",
    "projectStatus",
    "CustomerGstNo",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });
  return err;
};
export default reduxForm({
  form: "ProjectCircular",
  validate,
  enableReinitialize: true,
})(ProjectCircular);
