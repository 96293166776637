import React, { useState, useEffect } from "react";
import { AutoCompleteTableInput } from "../../../components/formcomponents/AutoCompleteTableInput";


const WorkmenOrderRecoveryTable = ({
  tableData,
  machineList,
  activityList,
  tables,
  workmenList,
}) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: 1,
      RecoveryType: "",
      RecoveryDescription: 0,
       AccountCode: "",
      Activity: "",
      RecoveryPercentage: "",
      id: "new",
    },
  ]);
  const [activities, setActivities] = useState([]);
  const [machines, setMachines] = useState([]);


  // create table


  // useEffect(() => {
  //   if (workmenList) {
  //     setLoading(true)
  //     setInputFields([...workmenList])
  //     if (tableData) tableData(workmenList)
  //     setLoading(false)
  //   }
  //   console.log(workmenList);
  //   // eslint-disable-next-line
  // }, [workmenList])

  // edit table population
  useEffect(() => {
    if (tables) {
      setInputFields([...tables]);
      if (tableData) tableData(tables);
    }
    // eslint-disable-next-line
  }, [tables]);

  useEffect(() => {
    if (activityList) {
      setActivities(activityList);
    }
  }, [activityList]);

  useEffect(() => {
    if (machineList) {
      setMachines(machineList);
    }
  }, [machineList]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    tableData(values);
  };

  const handleSelectInput = (index, event) => {
    const value = event.target.value;
    const values = [...inputFields];
    values[index][event.target.name] = value;
    if (event.target.name === "Present" && event.target.value === "no") {
      values[index].disabled = true;
    }
    setInputFields(values);
    tableData(values);
  };


  const onActivitySelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        const list = activities;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        setActivities([...list]);
      } else {
        setActivities([...activities, row[fieldName]]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  const onMachineSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        tdata[index] = row;
        const list = machines;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        setActivities([...list]);
      } else {
        setActivities([...machines, row[fieldName]]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  const calculateTime = (index) => {
    const values = [...inputFields];
    let row = values[index];
    try {
      const timeIn = row.TimeIn.split(":");
      const timeOut = row.TimeOut.split(":");
      let diff = 0;
      if (+timeIn[0] > +timeOut[0]) {
        const toMidNight = 24 - +timeIn[0];
        diff = +toMidNight + +timeOut[0];
      } else {
        diff = +timeOut[0] - +timeIn[0];
      }
      if (+diff > 8) {
        row.NormalHour = "8 hours";
        const OTHours = +diff - +8;
        row.Overtime = OTHours + " hours";
      } else {
        row.NormalHour = diff;
        row.Overtime = "0 hours";
      }
    } catch (err) {
      console.error(err);
    } finally {
      values[index] = row;
      setInputFields(values);
      tableData(values);
    }
  };

  return (
   
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="tablecontainer">
      
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">
              SerialNo
            </th>
            <th className="head-cell">
             Recovery Type
            </th>
            <th className="head-cell">
             Recovery Description
            </th>
            <th className="head-cell">
              Account Code
            </th>
            <th className="head-cell">
             Activity / Machine
            </th>
            <th className="head-cell">
              Recovery Percentage
            </th>
          </tr>
        </thead>
        <tbody className="table-body">
          {inputFields?.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
              <input
                  type="text"
                  name="recoveryType"
                  value={inputField.RecoveryType}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "5rem" }}
                />
              {/* <AutoCompleteTableInput
                  // list={shiftList?.map((item) => item?.shift_code)}
                  // inputChange={onShiftSelect}
                  tableIndex={index}
                  fieldName={"RecoveryType"}
                  value={inputField?.RecoveryType}
                  defaultValue={inputField?.RecoveryType}
                /> */}
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="recoveryDescription"
                  value={inputField.RecoveryDescription}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "5rem" }}
                />
              </td>
              <td className="table-data2">
              <input
                  type="text"
                  name="AccountCode"
                  value={inputField.AccountCode}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "5rem" }}
                />
              </td>
              <td className="table-data2">
                {/* <input
                      type="text"
                      name="ShiftCode"
                      defaultValue={inputField.ShiftCode}
                      onChange={(event) => handleChangeInput(index, event)}
                      style={{ width: "8rem" }}
                      disabled={inputField.disabled}
                    /> */}
                     <select
                  style={{ width: "8rem", height: "2rem" }}
                  name="ActivityMachineSelect"
                  onChange={(event) => handleSelectInput(index, event)}
                  value={inputField.ActivityMachineSelect}
                  disabled={inputField.disabled}
                >
                  <option value={""}></option>
                  <option value={"activity"}>Activity</option>
                  <option value={"machine"}>Machine</option>
                </select>
              
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="RecoveryPercentage"
                  defaultValue={inputField.RecoveryPercentage}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "8rem" }}
                  disabled={inputField.disabled}
                />
              </td>
          
              {/* <td className="table-data2">
               
              </td>
              <td className="table-data2">
                {inputField.ActivityMachineSelect === "activity"
                  ? activities && (
                      <AutoCompleteTableInput
                        list={activities}
                        inputChange={onActivitySelect}
                        tableIndex={index}
                        fieldName={"ActivityMachineDesc"}
                        defaultValue={inputField.ActivityMachineDesc}
                      />
                    )
                  : machines && (
                      <AutoCompleteTableInput
                        list={machines}
                        inputChange={onMachineSelect}
                        tableIndex={index}
                        fieldName={"ActivityMachineDesc"}
                        defaultValue={inputField.ActivityMachineDesc}
                      />
                    )}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
  );
};

export default WorkmenOrderRecoveryTable;
