import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../../components/formcomponents/control";
// import Table from "../../../Tables/AccountingSystem/CurrencyTable";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import ReceiptEmployeeOtherFormComponent from "../../../components/AccountingSystemDisplay/ReceiptEmployeeOtherFormComponent";
import "../../../components/helpers/form.style.css";

const ReceiptEmployeeOtherForm = (props) => {
  const [state, setState] = useState({
    isSubmit: false,
    mode: "Cash",
    receiptFor: "employee",
  });

  const [totalNotes, setTotalNotes] = useState(null);
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show); //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }

    props.onSubmit({
      ...formValues,
      cashDetails: totalNotes,
    });
  };

  const handleCurrencyChange = (data) => {
    setTotalNotes(data);
  };

  const onReceiptModeChange = (value) => {
    setState({ ...state, mode: value });
  };

  const onReceiptForChange = (value) => {
    setState({ ...state, receiptFor: value });
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />

        <div className="field-container">
          <Field
            name="ProjectCodeName"
            label="Project Code"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.list}
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="ReceiptMode"
            label="Receipt Mode"
            onChange={onReceiptModeChange}
            component={Control.AutoCompleteInput}
            list={["", "Cash", "Bank"]}
            className="text-field"
          />
          <Field
            name="ReceiptFor"
            label="Receipt From"
            onChange={onReceiptForChange}
            component={Control.AutoCompleteInput}
            list={["", "employee", "customer", "vendor", "others"]}
            className="text-field"
          />
        
          {state.receiptFor === "employee" ? (
            
            <Field
              name="EmployeeCodeName"
              label="Employee Code Name"
              component={Control.AutoCompleteInput}
              list={props.employeeList}
              className="text-field"
            />
          ) : state.receiptFor === "customer" ? (
            <Field
              name="CustomerCodeName"
              label="Customer Code Name"
              component={Control.AutoCompleteInput}
              list={props.customerList}
              className="text-field"
            />
          ) : state.receiptFor === "vendor" ? (
            <Field
              name="VendorCodeName"
              label="Vendor Code Name"
              component={Control.AutoCompleteInput}
              list={props.vendorList}
              className="text-field"
            />
          ) : (
            <>
              <Field
                name="ReceivedFrom"
                label="Received From"
                component={Control.RenderTextField}
              />
              <Field
                name="PAN"
                label="PAN"
                component={Control.RenderTextField}
              />
            </>
          )}
          <Field
            name="Remarks"
            label="Remarks"
            component={Control.RenderTextField}
          />
        </div>
        <h4 style={{ display: "inline-block", marginLeft: "5rem" }}>
          Receipt Details
        </h4>
        <ReceiptEmployeeOtherFormComponent
          mode={state.mode}
          handleCurrencyChange={handleCurrencyChange}
          props={props}
          initialValue={props.initialValues}
          headerState={props.headerState}
        />
        <div>
          <Field
            name="NarrationCash"
            label="Narration"
            component={Control.RenderTextField}
          />
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Payment Receipt and others"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "PaymentMode",
    "EmployeeName",
    "ProjectCode",
    "AtBank",
    "TotalReceiptValue",
    "ChequeNEFTRTGSIMPSNo",
    "Date",
    "Narration",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "ReceiptEmployeeOtherForm",
  validate,
  enableReinitialize: true,
})(ReceiptEmployeeOtherForm);
