import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import InvoiceType from "../../../forms/CommonMaster/InvoiceType";
import { useHistory } from "react-router-dom";
import {
  editInvoiceType,
  searchInvoiceTypeById,
} from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const InvoiceTypeEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const [account, setAccount] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchInvoiceTypeById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleInvoiceType) {
      setData(store.SingleInvoiceType);
    }
  }, [store.SingleInvoiceType]);

    useEffect(() => {
      async function fetch() {
        try {
          const { data } = await axios.get("/commonMaster/account");
          console.log(data);
          let mapedData = data.map((item) => `${item.AccountCode}-${item.AccountDes}`);
          setAccount(mapedData);
        } catch (err) {
          alert(err);
          console.log(err);
        }
      }
      fetch();
    }, []);
  
  const onSubmit = (formValues) => {
    dispatch(editInvoiceType(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="Invoice Type Edit"
        linkName1="Invoice Type Dashboard"
        to1="/commonMaster/invoiceType"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Invoice Type
      </h3>
      <div className="line"></div>
      <InvoiceType
        initialValues={pick(
          data,
          "InvoiceTypeCode",
          "InvoiceType",
          "AccountCode",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        account={account}
      />
    </div>
  );
};
