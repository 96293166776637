import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../../components/apis/axios";
import LinearProgress from "@material-ui/core/LinearProgress";
// import { format } from "date-fns";

const ReceiptCustomerTable = ({ receipt, tableData, customer }) => {
  const [projects, setProjects] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      SlNo: "",
      ProjectCodeName: "",
      SalesOrderNo: "",
      SalesOrderDate: "",
      SalesInvoiceNo: "",
      SalesInvoiceDate: "",
      GrossInvoiceAmount: "",
      GrossCertificationAmount: "",
      RecoveryAmount: "",
      PayableAmount: "",
      PreviouslyReceived: 0,
      ReceivedNow: 0,
      id: "",
    },
  ]);
  const [loading, setLoading] = useState(false);

  //find projects by customer
  useEffect(() => {
    if (customer) fetchProjects();
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    if (receipt) {
      setLoading(true);
      setInputFields([...receipt]);
      if (tableData) tableData(receipt);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [receipt]);

  console.log("customer", customer);
  const fetchProjects = async () => {
    try {
      if (customer) {
        const { data } = await axios.post(
          `/salesAccounting/salesInvoice/getDataForReceiptByCustomer`,
          {
            CustomerName: customer,
          }
        );
        setProjects(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let values = [];
    if (receipt) {
      setLoading(true);
      for (let i = 0; i < receipt.length; i++) {
        values[i] = receipt[i];
        if (receipt[i].ProjectCodeName) {
          getSalesInvoice(values[i], values, i);
        }
      }
      setInputFields(values);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [receipt]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange();
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SlNo: "",
        ProjectCodeName: "",
        SalesOrderNo: "",
        SalesOrderDate: "",
        SalesInvoiceNo: "",
        SalesInvoiceDate: "",
        GrossInvoiceAmount: "",
        GrossCertificationAmount: "",
        RecoveryAmount: "",
        PayableAmount: "",
        PreviouslyReceived: 0,
        ReceivedNow: 0,
        id: "new",
      },
    ]);
  };

  const onChange = () => {
    if (inputFields && tableData) {
      tableData(inputFields);
    } else {
      tableData({ error: true, errorKind: "some input field is empty" });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = inputFields;
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange();
  };

  const onProjectSelect = async (event, values, index) => {
    const arr = [...inputFields];
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.ProjectCodeName = values.ProjectCodeName;
        getSalesInvoice(row, arr, index);
        tdata[index] = row;
        const list = projects;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setProjects([...list]);
      } else {
        const item = projects.find(
          (item) => item.ProjectCodeName === row.ProjectCodeName
        );
        // console.log("bank", item);
        setProjects([...projects, item]);
        row.ProjectCodeName = "";
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSalesInvoice = async (row, arr, index) => {
    try {
      const { data: salesOrder } = await axios.post(
        "/salesAccounting/salesInvoice/getDataForReceiptByProject",
        {
          ProjectCodeName: row.ProjectCodeName,
        }
      );
      console.log(salesOrder);
      row.SalesOrderNo = salesOrder.SalesOrderNo;
      row.SalesOrderDate = new Date(salesOrder.SalesOrderDate)
        .toISOString()
        .split("T")[0];
      arr[index] = row;
      setInputFields(arr);
      const temp = invoices;
      temp[index] = salesOrder.ReceiptCustomerSub;
      setInvoices([...temp]);
    } catch (e) {
      console.log(e);
    }
  };

  const onInvoiceSelect = async (event, values, index) => {
    const arr = [...inputFields];
    try {
      let row = inputFields[index];
      row.SalesInvoiceNo = values.SalesInvoiceNo;
      row.SalesInvoiceDate = new Date(values.SalesInvoiceDate)
        .toISOString()
        .split("T")[0];
      console.log(values);
      row.GrossInvoiceAmount = values.GrossInvoiceAmt;
      row.GrossCertificationAmount = values.GrossInvoiceCertAmt;
      row.RecoveryAmount = values.RecoveryAmt;
      row.PayableAmount = values.PayableAmt;
      row.PreviouslyReceived = values.PreviouslyReceivedAmt;
      arr[index] = row;
      setInputFields(arr);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {!loading ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl. No</th>
                <th className="head-cell">Project Code / Name</th>
                <th className="head-cell">Sales Order No.</th>
                <th className="head-cell">Date</th>
                <th className="head-cell">Sales Invoice No.</th>
                <th className="head-cell">Date</th>
                <th className="head-cell">Gross Invoice Amount</th>
                <th className="head-cell">Gross Certified Amount</th>
                <th className="head-cell">Recovery Amount</th>
                <th className="head-cell">Payable Amount</th>
                <th className="head-cell">Previously Received</th>
                <th className="head-cell">Received Now</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data2">
                    {projects && (
                      <Autocomplete
                        id="ProjectCodeName"
                        options={projects}
                        getOptionLabel={(option) => option.ProjectCodeName}
                        style={{ width: "15rem" }}
                        onChange={(event, values) =>
                          onProjectSelect(event, values, index)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={projects.find(
                          (item) =>
                            item.ProjectCodeName === inputField.ProjectCodeName
                        )}
                      />
                    )}
                  </td>
                  <td className="table-data2">{inputField.SalesOrderNo}</td>
                  <td className="table-data2">
                    {/* {format(new Date(inputField.SalesOrderDate), "d MMM yyyy")} */}
                    {inputField.SalesOrderDate}
                  </td>
                  <td className="table-data2">
                    {invoices[index] && (
                      <Autocomplete
                        id="SalesInvoiceNo"
                        options={invoices[index]}
                        getOptionLabel={(option) => option.SalesInvoiceNo}
                        style={{ width: "15rem" }}
                        onChange={(event, values) =>
                          onInvoiceSelect(event, values, index)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        defaultValue={invoices[index].find(
                          (item) =>
                            item.SalesInvoiceNo === inputField.SalesInvoiceNo
                        )}
                      />
                    )}
                  </td>
                  <td className="table-data2">
                    {/* {format(
                      new Date(inputField.SalesInvoiceDate),
                      "d MMM yyyy"
                    )} */}
                    {inputField.SalesInvoiceDate}
                  </td>
                  <td className="table-data2">
                    {inputField.GrossInvoiceAmount}
                  </td>
                  <td className="table-data2">
                    {inputField.GrossCertificationAmount}
                  </td>
                  <td className="table-data2">{inputField.RecoveryAmount}</td>
                  <td className="table-data2">{inputField.PayableAmount}</td>
                  <td className="table-data2">
                    {inputField.PreviouslyReceived}
                  </td>
                  <td className="table-data4">
                    <input
                      type="number"
                      name="ReceivedNow"
                      value={inputField.ReceivedNow}
                      onChange={(event) => handleChangeInput(index, event)}
                      style={{ width: "5rem" }}
                    />
                  </td>
                  <td>
                    {index === 0 ? (
                      <IconButton>
                        <RemoveCircleIcon className="width5" color="disabled" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                        <RemoveCircleIcon
                          className="width5"
                          color="secondary"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="middle">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              ADD Row
            </Button>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

export default ReceiptCustomerTable;
