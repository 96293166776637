import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MachineRepairRequest from "../../../forms/MachineManagementSystem/MachineRepairRequest";
import { machineRepairRequest } from "../../../redux/actions/MachineManagementaction";

export const MachineRepairRequestCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machineRepairRequest(formValues, history));
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3 style={{ textAlign: "center" }}>Machine Repair Request</h3>
      <MachineRepairRequest onSubmit={onSubmit} />
    </div>
  );
}
