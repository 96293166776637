import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchVendorById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import GSTTableView from "../../../Tables/CommonMaster/GSTTableView";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const VendorView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchVendorById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleVendor) {
      setData(store.commonMasterSystemRoot.SingleVendor);
    }
  }, [store.commonMasterSystemRoot.SingleVendor]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("vendor", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
  };
  return (
    <>
      <Breadcrumbs
        name="Vendor View"
        linkName1="Vendor Dashborad"
        to1="/commonMaster/vendor"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Vendor</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data.VendorCode && (
            <Paper className={classes.paper}>
              <h6>Vendor Code:</h6>
              <p>{data.VendorCode}</p>
            </Paper>)}

            {data.VendorName && (
            <Paper className={classes.paper}>
              <h6>Vendor Name:</h6>
              <p>{data.VendorName}</p>
            </Paper>)}

            {data.CorporateOffice && (
            <Paper className={classes.paper}>
              <h6>Corporate Office:</h6>
              <p>{data.CorporateOffice}</p>
            </Paper>)}

            {data.RegisteredOffice && (
            <Paper className={classes.paper}>
              <h6>Registered Office:</h6>
              <p>{data.RegisteredOffice}</p>
            </Paper>)}

            {data.Phone && (
            <Paper className={classes.paper}>
              <h6>Phone​:</h6>
              <p>{data.Phone}</p>
            </Paper>)}

            {data.Email && (
            <Paper className={classes.paper}>
              <h6>Email:</h6>
              <p>{data.Email}</p>
            </Paper>)}

            {data.Website && (
            <Paper className={classes.paper}>
              <h6>Website:</h6>
              <p>{data.Website}</p>
            </Paper>)}

            {data.PAN && (
            <Paper className={classes.paper}>
              <h6>PAN:</h6>
              <p>{data.PAN}</p>
            </Paper>)}

            {data.ServiceTaxRegNo && (
            <Paper className={classes.paper}>
              <h6>Service Tax Reg No:</h6>
              <p>{data.ServiceTaxRegNo}</p>
            </Paper>)}

            {data.CINLLPIN && (
            <Paper className={classes.paper}>
              <h6>CIN / LLPIN:</h6>
              <p>{data.CINLLPIN}</p>
            </Paper>)}

            {data.ChequeStartingNo && (
            <Paper className={classes.paper}>
              <h6>Cheque Starting No:</h6>
              <p>{data.ChequeStartingNo}</p>
            </Paper>)}

            {data.Tag && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tag}</p>
              </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)}

            {data.VendorBankName && (
            <Paper className={classes.paper}>
              <h6>Bank Name:</h6>
              <p>{data.VendorBankName}</p>
            </Paper>)}

            {data.VendorIFSC && (
            <Paper className={classes.paper}>
              <h6>IFSC:</h6>
              <p>{data.VendorIFSC}</p>
            </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
          <div style={{ margin: "1rem" }}>
            <GSTTableView gsts={data.Gsts} />
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
