import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BusinessUnit from "../../../forms/CommonMaster/BusinessUnit";
import { businessUnit } from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const BusinessUnitCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [company, setCompany] = useState([]);

  const onSubmit = (formValues) => {
    dispatch(businessUnit(formValues, history));
    console.log(JSON.stringify(formValues));
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let company = data.map((item) => item.CompanyName);
        setCompany(company);
      } catch (err) {}
    }

    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Business unit Create"
        linkName1="Business unit Dashboard"
        to1="/commonMaster/businessUnit"
      />
      <FormHeading label="Business Unit" />
      <BusinessUnit onSubmit={onSubmit} company={company} />
    </div>
  );
};
