import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "../../CommonMaster/View/UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { searchEmployeeJoinById } from "../../../redux/actions/HRManagementActions";

export const EmployeeJoinView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchEmployeeJoinById(match.params.id);
        setData(data);
    }
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("employee", match.params.id, history, data)
    );
  };

  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button>
      </div>
    );
    // return;
  };

  return (
    <>
      <Breadcrumbs
        name="Employee Join View"
        linkName1="Employee Join Dashboard"
        to1="/project-hr-management/employee-join"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Employee Join</h3>
          <h6>{`Created by ${
            data?.employee?.created_by
              ? ` ${data?.employee?.created_by.first_name} ${data?.employee?.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>

            {data?.employee?.EmployeeCode && (
             <Paper className={classes.paper}>
              <h6>Employee Code:</h6>
              <p>{data?.employee?.EmployeeCode}</p>
            </Paper>)}

            {data?.employee?.EmployeeRoleDes && (
             <Paper className={classes.paper}>
              <h6>Employee RoleDes:</h6>
              <p>{data?.employee?.EmployeeRoleDes}</p>
            </Paper>)}

            {data?.employee?.Project && (
             <Paper className={classes.paper}>
              <h6>Project:</h6>
              <p>{data?.employee?.Project}</p>
            </Paper>)}

            {data?.employee?.Email && (
             <Paper className={classes.paper}>
              <h6>Email:</h6>
              <p>{data?.employee?.Email}</p>
            </Paper>)}

            {data?.employee?.EmployeeRole && (  
            <Paper className={classes.paper}>
              <h6>EmployeeRole:</h6>
              <p>{data?.employee?.EmployeeRole}</p>
            </Paper>)}

            {data?.employee?.FirstName && (
            <Paper className={classes.paper}>
              <h6>EmployeeName:</h6>
              <p>{data?.employee?.FirstName} {data?.employee?.LastName}</p>
            </Paper>)}

            {data?.employee?.Company && (
            <Paper className={classes.paper}>
              <h6>Company:</h6>
              <p>{data?.employee?.Company} </p>
            </Paper>)}

            {data?.employee?.Phone && (
             <Paper className={classes.paper}>
              <h6>Phone:</h6>
              <p>{data?.employee?.Phone}</p>
            </Paper>)}

            {data?.employee?.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data?.employee?.Tags}</p>
              </Paper>)};

          </div>
        </Box>
      </Paper>
      {/* TODO: Implement approval functionality */}
      {/* {acessButton()} */}
    </>
  );
};
