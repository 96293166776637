import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CostCentre from "../../../forms/CommonMaster/CostCentre";
import { costCentre } from "../../../redux/actions/CommonMasterAction";
import StateList from "../../../components/helpers/StateList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import { useSnackbar } from "notistack";

let allCompany = [];

export const CostCentreCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [gsts, setgsts] = useState([]);
  const [companyDetail, setCompanyDetail] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formValues) => {
    dispatch(costCentre(formValues, history, enqueueSnackbar));
  };

  useEffect(() => {

    async function customer() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        let mapedData = data.map((item) => item.CompanyName);

        setCompanyDetail(mapedData);
        allCompany = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    async function gsts() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails/gsts");
        console.log(data);
        allCompany = data;
        setgsts(allCompany)
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    gsts();
    customer();
  }, []);

  const findGst = async (value) => {
    let id;
    try {
      id = allCompany.find((item) => item.CompanyName === value);
      const payload = await axios.get(`commonMaster/companyDetails/${id.id}`);
      const gsts = payload.data.Gsts;
      setCompanyGsts(gsts);
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
    <div>
      <Breadcrumbs
        name="Cost Center Create"
        linkName1="Cost Center Dashboard"
        to1="/commonMaster/costCentre"
      />
      <h3 style={{ textAlign: "center" }}>Cost Centre</h3>
      <CostCentre
        onSubmit={onSubmit}
        companyDetail={companyDetail}
        findGst={findGst}
        gsts={gsts}
        companyGsts={companyGsts}
        isEdit={false}
      />
    </div>
  );
};