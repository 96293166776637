import axios from "../../components/apis/axios";

export const createEmployeeJoin = async (formValues, history) => {
    try {
    await axios.post("/hr-management/employee-join", formValues);
    history.push("/project-hr-management/employee-join");
    } catch (error) {
    alert(error);
    }
};

export const createHrDailyAttendance = async (formValues, history) => {
    try {
    await axios.post("/hr-management/daily-attendance", formValues);
    history.push("/project-hr-management/daily-attendance");
    } catch (error) {
        alert(error);
    }
};

export const createTransferOrder = async (formValues, history) => {
    try {
    await axios.post("/hr-management/transfer-order", formValues);
    history.push("/project-hr-management/transfer-order");
    } catch (error) {
    alert(error);
    }
};

export const createEmployeeRelease = async (formValues, history) => {
    try {
    await axios.post("/hr-management/employee-release", formValues);
    history.push("/project-hr-management/employee-release");
    } catch (error) {
    alert(error);
    }
};

export const createEmployeeJoinReport = async (formValues, history) => {
    try {
    await axios.post("/hr-management/employee-join-report", formValues);
    history.push("/project-hr-management/employee-join-report");
    } catch (error) {
    alert(error);
    }
};


export const searchEmployeeJoinById = async (Id) => {
    try {
        const { data } = await axios.get(`/hr-management/employee-join/${Id}`);
        return data;
    } catch (err) {
        console.log("Error in purchaseRequest", err);
    }
};

export const searchHrDailyAttendanceById = async (Id) => {
    try {
        const { data } = await axios.get(`/hr-management/daily-attendance/${Id}`);
        return data;
    } catch (err) {
        console.log("Error in daily attendance", err);
    }
};


export const searchTransferOrderById = async (Id) => {
    try {
        const { data } = await axios.get(`/hr-management/transfer-order/${Id}`);
        return data;
    } catch (err) {
        console.log("Error in transfer order", err);
    }
};

export const searchEmployeeReleaseById = async (Id) => {
    try {
        const { data } = await axios.get(`/hr-management/employee-release/${Id}`);
        return data;
    } catch (err) {
        console.log("Error in employee release", err);
    }
};


export const searchEmployeeJoinReportById = async (Id) => {
    try {
        const { data } = await axios.get(`/hr-management/employee-join-report/${Id}`);
        return data;
    } catch (err) {
        console.log("Error in transfer order", err);
    }
};
