import React from "react";
import BankTableView from "../../../Tables/AccountingSystem/View/BankTableView";
import NotesTable from "../../../Tables/AccountingSystem/View/NotesTable";

const ReportBalanceDetails = ({ details }) => {
  return (
    <div>
      <BankTableView BankDetails={details.BankDetails} />
      <NotesTable CashDetails={details} />
    </div>
  );
};

export default ReportBalanceDetails;
