import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
// import { Button } from "@material-ui/core";
import * as Control from "../../../components/formcomponents/control";
import PaymentDetails from "../../../Tables/AccountingSystem/View/PaymentRequestDetailsTableView";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import DisbursementFormComponent from "../../../components/AccountingSystemDisplay/DisbursementFormComponent";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";
import "../../../components/helpers/form.style.css";

const DisbursementEmployeeOthersForm = (props) => {
  const [selection, setSelection] = useState("");
  const [show, setShow] = useState(false);

  const [totalNotes, setTotalNotes] = useState(null);

  const toggleModal = () => setShow(!show);

  const handleCurrencyChange = (data) => {
    setTotalNotes(data);
  };

  const onSubmit = (formValues) => {
    props.onSubmit({
      ...formValues,
      paymentDetails: props.paymentDetails,
      cashDetails: totalNotes,
    });
  };

  useEffect(() => {
    if (props.initialValues?.PaymentFor) {
      setSelection(props.initialValues?.PaymentFor);
    }
  }, [props.initialValues?.PaymentFor]);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />
        <div className="field-container">
          <Field
            name="PaymentRequestNo"
            label="Payment Requst No"
            className="text-field"
            component={Control.AutoCompleteInput}
            list={props.list}
            onChange={(value) =>
              props.selectPaymentRequest && props.selectPaymentRequest(value)
            }
          />
          <Field
            name="PaymentRequestDate"
            component={Control.RenderDateAllField}
            label="Payment Request Date"
            initialValue={props.initialValues.PaymentRequestDate}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="PaymentMode"
            label="Payment Mode"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="PaymentFor"
            label="Payment for"
            disabled
          />
          {selection === "employee" ? (
            <Field
              name="EmployeeCodeName"
              label="Employee Name"
              component={Control.RenderTextField}
              disabled
            />
          ) : selection === "vendor" ? (
            <Field
              name="VendorCodeName"
              label="Vendor Code Name"
              component={Control.RenderTextField}
              disabled
            />
          ) : (
            <>
              <Field
                name="InFavourOf"
                label="In Favour of"
                component={Control.RenderTextField}
                disabled
              />
              <Field
                name="PAN"
                label="PAN"
                component={Control.RenderTextField}
                disabled
              />
            </>
          )}
          <Field
            name="ProjectCodeName"
            label="Project Code"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="CustomerName"
            label="Customer Name"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="TotalPRValue"
            label="Total PR Value"
            component={Control.RenderTextField}
            disabled
          />
        </div>

        <div className="excel">
          <PaymentDetails paymentDetails={props.paymentDetails} />
        </div>

        <DisbursementFormComponent
          handleCurrencyChange={handleCurrencyChange}
          initialValues={props.initialValues}
          headerState={props.headerState}
          paymentDetails={props.paymentDetails}
          props={props}
          formName={"DisbursementEmployeeOthersForm"}
        />
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Disbursement Employee Others"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCentre",
    "Currency",
    "FinancialPeriod",
    "PaymentRequestNo",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "DisbursementEmployeeOthersForm",
  validate,
  enableReinitialize: true,
})(DisbursementEmployeeOthersForm);
