import React, {  useState, useEffect } from 'react';
import axios from '../../components/apis/axios';

const GSTTableRowView = ({ gst, company }) => {
  const [gstNo, setGst] = useState(null);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    console.log({ gst })
    const getGsts = async (value) => {
      const { data } = await axios.get("/commonMaster/companyDetails/gsts", {
        params: {
          companyName: company,
          state: value,
        },
      });
      console.log(data);

      let gsts = data.map((item) => item.GSTRegNo);

      setGst(gsts?.[0] || null);
    };

    const getAddress = async (value) => {
      const { data } = await axios.get("/commonMaster/companyDetails/gsts", {
        params: {
          companyName: company,
          state: value,
        },
      });
      let address = data.map((item) => item.GSTAddress);
      setAddress(address?.[0] || null);
    };

    if (gst?.GSTState) {
      getGsts(gst.GSTState);
      getAddress(gst.GSTState);
    }
  }, [gst]);

  return (
    <tr>
      <td className="table-data1">{gst?.SerialNo}</td>
      <td className="table-data2">{gstNo}</td>
      <td className="table-data2">{gst?.GSTState}</td>
      <td className="table-data4">{address}</td>
    </tr>
  );
};

export default GSTTableRowView;
