import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

import "../../components/helpers/form.style.css";

const InvoiceType = (props) => {
    const [show, setShow] = React.useState(false);

    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="InvoiceTypeCode"
            label="Invoice Type Code"
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            name="InvoiceType"
            label="Invoice Type"
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="AccountCode"
            list={props.account}
            label="Account Code"
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="InvoiceType"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = ["InvoiceTypeCode", "InvoiceType", "AcountCode"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "InvoiceType",
  validate,
  enableReinitialize: true,
})(InvoiceType);
