const RoleList = {
  ADMIN: {
    code: "admin",
    label: "Admin",
  },
  BU_HEAD: {
    code: "buHead",
    label: "Business Unit Head",
  },
  SITE_SUPERVISOR: {
    code: "siteSupervisor",
    label: "Site Supervisor",
  },
  SITE_INCHARGE: {
    code: "siteIncharge",
    label: "Site Incharge",
  },
  BU_COMMERCIAL: {
    code: "buCommercial",
    label: "Business Unit Commercial",
  },
  COMMERCIAL_ASSISTANT: {
    code: "commercialAssistant",
    label: "Commercial Assistant",
  },
  ACCOUNT_AND_ADMIN_HEAD: {
    code: "accountAndAdminHead",
    label: "Account And Admin Head",
  },
  CASHIER: {
    code: "cashier",
    label: "Cashier",
  },
  DIRECTOR: {
    code: "director",
    label: "Director",
  },
  SITE_STORE_KEEPER: {
    code: "siteStoreKeeper",
    label: "Site Store Keeper",
  },
};

export default RoleList;
