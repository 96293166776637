import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
import { useSnackbar } from "notistack";

const HurdleReport = (props) => {
  const [show, setShow] = useState(false);

  const onSubmit = (formValues) => {
    if (formValues.EndTime < formValues.StartTime) {
      enqueueSnackbar(`End Time cannot be earlier than Start Time`, {
        variant: "warning",
      });
      setShow(!show);
      return;
    }

    props.onSubmit(formValues);
  };
  const { enqueueSnackbar } = useSnackbar();

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            list={props.ProjectList}
            label="Project Code Name"
            className="text-field"
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            disabled
            label="Customer Name"
          />
          <Field
            component={Control.RenderDatePastField}
            initialValue={props.initialValues.ReportingDate}
            name="ReportingDate"
            className="text-field"
            label="Reporting Date"
          />
          <Field
            component={Control.RenderTimeField}
            name="StartTime"
            className="text-field"
            label="Start Time"
            initialValue={props.initialValues.StartTime}
          />
          <Field
            component={Control.RenderTimeField}
            name="EndTime"
            className="text-field"
            label="End Time"
            initialValue={props.initialValues.EndTime}
          />
          <Field component={Control.RenderTextField} name="Area" label="Area" />
          <Field
            component={Control.RenderTextField}
            name="Reason"
            label="Reason"
          />
        </div>

        {/* <p>End Time</p>
        <Field component={Control.RenderTextField} name="EndTime" type="time" /> */}
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Hurdle Register "
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "ReportingDate",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });
  return err;
};

export default reduxForm({
  form: "HurdleReport",
  validate,
  enableReinitialize: true,
})(HurdleReport);
