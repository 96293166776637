import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import SearchOptionProject from "../../../components/ReportsComponent/SearchOptionProject";
import AttendanceTable from "../../../components/AttendanceTable-Calender/AttendanceTable";
import Skeleton from "@material-ui/lab/Skeleton";
import { LogCalender } from "./LogCalender";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let code = [];

export const LogSheetReport = (props) => {
  const classes = useStyle();
  const store = useSelector((store) => store);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectProject, setSelectProject] = useState();

  const [MachineList, setMachineList] = useState([]);
  const [isList, setIsList] = useState(true);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;

    useEffect(() => {
      selectProjectName();
    },[]);
  const selectProjectName = async (projectName) => {
    try {
      setSelectProject(projectName);
      const payload = await axios.post(
        `machineManagement/logSheetEntry/getLogSheetReportsData/`,
        {
          ProjectCodeName: projectName || null,
        }
      );
      code = payload.data;
      console.log(code);
      setMachineList(code);
      setIsLoading(true);
    } catch (err) {
      console.error(err);
    }
  };


  const handleOnViewClick = (machine) => {
    setIsList(false);
    setSelectedMachine(machine);
  };
  return (
    <>
      <BreadCrumbs
        name="Logsheet Receipt Reports"
        linkName1="All Machine Man. Reports"
        to1="/machineManagement/reports"
      />
    
        <Paper className={classes.Container}>
        <div>
              <h3 align="center">LogSheetReport</h3>

              <SearchOptionProject
                handleProjectChange={selectProjectName}
                label="Search Project"
              />
           </div>
        
              <AttendanceTable
                paginatedResults={paginatedResults}
                lists={MachineList}
                tablecol1="MachineCodeName"
                tablecol2="LogSheetCount"
                label1="Machine Name"
                label2="No. Attendance"
                viewLink="/machineManagement/reports/logsheetReport/AttendanceView"
                visibleIcon={visibleIcon}
                ProjectCodeName={selectProject}
                handleOnViewClick={handleOnViewClick}
              />
       
       
        </Paper>
    
    </>
  );
};
