import React, { useState, useEffect } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import Table from "../../Tables/MaterialSystem/WarehouseReceiptVoucherTable";
import "./PurchaseOrderAmendmentForm.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { AllOptionList } from "../../components/helpers/WarehouseStockTypeOptions";

const WarehouseReceiptVoucherForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
  });
  const [optionList, setOptionList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [show, setShow] = useState(false);
  const [isColumnsAvailable, setIsColumnsAvailable] = useState(true);

  useEffect(() => {
    if (props.initialValues.GoodsReceiptType) {
      const data = AllOptionList.find(
        (item) => item.type === props.initialValues.GoodsReceiptType
      );
      if (
        props.initialValues.GoodsReceiptType ===
          "RECEIPT FROM CLIENTS - FREE BASIS" ||
        props.initialValues.GoodsReceiptType ===
          "RECEIPT FROM CLIENTS - AGAINST LOAN"
      )
        setIsColumnsAvailable(false);
      if (data) setOptionList(data.options);
    }
  }, [props.initialValues.GoodsReceiptType]);

  // updating the voucher value field
  // dont move this out as it will not work
  // this is a hacky approach
  // time wasted on this 5 hrs
  useEffect(() => {
    try {
      let total = 0;
      state?.storedData?.forEach((item) => {
        total += +item?.ItemAmount;
      });
      total = total.toFixed(2);
      console.log(total);
      props.dispatch(
        change(
          "WarehouseReceiptVoucherForm",
          "WarehouseReceiptVoucherValue",
          total
        )
      );
    } catch (err) {
      console.log(err);
    }
  });

  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.storedData) {
      setState({
        ...state,
        tableError: true,
      });
    } else {
      props.onSubmit({ ...formValues, tableData: state.storedData });
    }
  };

  const storeTableData = (data) => {
    if (!data) {
      setState({
        storedData: null,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        storedData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            list={props.projectList}
            onChange={(value) =>
              props.onSelectProject && props.onSelectProject(value)
            }
            label="Project Code Name​"
            className="text-field"
          />
          <Field
            name="GoodsInspectionVoucherNo"
            component={Control.AutoCompleteInput}
            list={props.list}
            className="text-field"
            onChange={(value) =>
              props.onSelectGoodsInspection &&
              props.onSelectGoodsInspection(value)
            }
            label="Goods Inspection Voucher No​"
          />
          <Field
            name="GoodsReceiptType"
            component={Control.RenderTextField}
            label="Goods Receipt Type"
            disabled
          />
          <Field
            name="GoodsStockType"
            component={Control.RenderSelectField}
            label="Goods Stock Type"
          >
            {optionList.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
          <Field
            name="StoredAt"
            component={Control.RenderTextField}
            label="Stored At​"
          />
          <Field
            name="GoodsReceiptVoucherNo"
            label="Goods Receipt Voucher No"
            component={Control.RenderTextField}
            disabled
          />
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          <Field
            name="VendorCodeName"
            component={Control.RenderTextField}
            label="Vendor Code / Name"
            disabled
          />
          <Field
            name="VendorGstNo"
            component={Control.RenderTextField}
            label="Vendor GST No"
            disabled
          />
          <Field
            name="CustomerCodeName"
            component={Control.RenderTextField}
            label="CustomerCode/Name"
            disabled
          />
          <Field
            name="WarehouseCodeName"
            component={Control.RenderTextField}
            label="Warehouse Code / Name​"
            disabled
          />
          <Field
            name="LRRRConsignmentNo"
            component={Control.RenderTextField}
            label="LR / RR / Consignment No​​"
            disabled
          />
          <Field
            name="LRDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.LRDate}
            label="LR Date​"
            disabled
          />
          <Field
            name="DateofReceipt"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DateofReceipt}
            label="Date of Receipt​"
            disabled
          />
          <Field
            name="BatchIdentificationNo"
            component={Control.RenderTextField}
            label="Batch / Identification No​"
            disabled
          />
          {props?.initialValues.GoodsReceiptType !==
            "RECEIPT FROM OTHER WAREHOUSE" &&
          props?.initialValues.GoodsReceiptType !==
            "RECEIPT FROM OTHER HEC GROUPS" ? (
            <Field
              name="DeliveryChallanNo"
              label="Delivery Challan No​"
              component={Control.AutoCompleteInput}
              className="text-field"
              list={props.deliveryChallanList}
            />
          ) : (
            <Field
              component={Control.RenderTextField}
              name="DeliveryChallanNo"
              label="Delivery Challan No​"
              disabled
            />
          )}

          <Field
            name="DeliveryDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DeliveryDate}
            label="Delivery Date"
          />
          <Field
            name="WarehouseReceiptVoucherValue"
            component={Control.RenderTextField}
            label="Warehouse Receipt Voucher Value​"
            disabled

            // hidden={!isColumnsAvailable}
          />
        </div>
        <div className="excel">
          <Table
            storeTableData={storeTableData}
            materialsEditData={props.materialsEditData}
            materialsTable={props.materialsTable}
            poMaterials={props.poMaterials}
            isColumnsAvailable={isColumnsAvailable}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError && `${state.tableErrorKind}`}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="WareHouse Receipt"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "StoredAt",
    "GoodsReceiptVoucherNo",
    "GoodsInspectionVoucherNo",
    "GoodsStockType",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "WarehouseReceiptVoucherForm",
  validate,
  enableReinitialize: true,
})(WarehouseReceiptVoucherForm);
