import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "../UseStyle";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import axios from "../../../../components/apis/axios";
import { userLogout } from "../../../../redux/actions/userAction";
import { approvalAccountingSystem } from "../../../../redux/actions/AccountingSystemAction";
import Table from "../../../../Tables/AccountingSystem/View/PaymentRequestDetailsTableView";
import ApprovalAndRejectModal from "../../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../../components/formcomponents/control";
import ComapnyHeader from "../../../../components/CompanyHeader/CompanyHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from "notistack";
import Breadcrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import RoleList from "../../../../components/helpers/RoleList";

export const ScrutinyEmployeeOtherView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { match } = props;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/accountingSystem/scrutinyEmployeeeAndOthers/${match.params.id}`
        );
        let data = response.data;
        data.created_at = new Date(data.created_at).toLocaleString();
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [match.params, dispatch, history]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalAccountingSystem(
        "scrutinyEmployeeeAndOthers",
        match.params.id,
        history,
        data,
        enqueueSnackbar
      )
    );
  };

  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name="Scrutiny Employee and Others View"
        linkName1="Scrutiny Employee and Others Dashboard"
        to1="/accountingSystem/scrutinyEmployeeeAndOthers"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Scrutiny Employee and Others </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>
        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>PaymentRequestNo:</h6>
                  <p>{data.PaymentRequestNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>CostCentre:</h6>
                  <p>{data.CostCentre}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>FinancialPeriod:</h6>
                  <p>{data.FinancialPeriod}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Currency:</h6>
                  <p>{data.Currency}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>PaymentMode:</h6>
                  <p>{data.PaymentMode}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>PaymentFor:</h6>
                  <p>{data.PaymentFor}</p>
                </Paper>
                {data.InFavourOf && (
                  <Paper className={classes.paper}>
                    <h6>InFavourOf:</h6>
                    <p>{data.InFavourOf}</p>
                  </Paper>
                )}
                {data.PAN && (
                  <Paper className={classes.paper}>
                    <h6>PAN:</h6>
                    <p>{data.PAN}</p>
                  </Paper>
                )}
                <Paper className={classes.paper}>
                  <h6>ProjectCodeName:</h6>
                  <p>{data.ProjectCodeName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>CustomerName:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Remarks:</h6>
                  <p>{data.Remarks}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>TotalPRValue:</h6>
                  <p>{data.TotalPRValue}</p>
                </Paper>
              </div>
              <div style={{ paddingLeft: "10%" }}>
                <h4>Payment Details</h4>
                <Table paymentDetails={data.paymentDetails} />
              </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>
            {(post === RoleList.ADMIN.code ||
              post === RoleList.BU_HEAD.code ||
              post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
              post === RoleList.DIRECTOR.code
              
            ) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"Scrutiny Employee and Others"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
