import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyle from "./UseStyle";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const ReportsList = () => {
  const classes = useStyle();
  return (
    <>
      <BreadCrumbs name="All Sales Accounting Reports" />
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>All Reports</h3>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <Link to="/salesAccounting/reports/projectCircullar">
                Project Circular
              </Link>
            </Paper>
            <Paper className={classes.paper}>
              <Link to="/salesAccounting/reports/salesInvoice">
                Sales Invoice
              </Link>
            </Paper>
          </div>
        </Box>
      </Paper>
    </>
  );
};
