import React, { useState, useEffect }  from "react";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

export const RenderDateRangePicker = ({ 
  
    
    label,
    input,
    initialValue,
    meta: { invalid, error },
    ...custom
   }) => {
    const { onChange, ...rest } = input;

    const [selectedDate, setSelectedDate] = useState(null);
   
    console.log(initialValue,input,label,"dddddddddddddddddddddddddddddd");
    useEffect(() => {
      if (initialValue) {
        setSelectedDate(initialValue);
      }
    }, [initialValue]);
  
  
  
   
  
    const handleDateChange = (dates,dateStrings) => {
      console.log(dates,"KKKKKKKKKKKKlLLLLLLLLLLLLLLLLLLLLLll");
      setSelectedDate(
        dates
          ? {
              from: new Date(dateStrings[0]),
              to: new Date(dateStrings[1]),
            }
          : null
      );
      onChange(dates);
      // setTouched(true);
    };
    
   return  (

  <Space direction="vertical" size={12}>
        <p>Defect Liability Period : </p>
    <RangePicker
      label={label}
      customStyles={{dateInput:{borderWidth: 0}}}
      value={selectedDate}
      ranges={{
        Today: [moment(), moment()],
        "This week": [moment().startOf("week"), moment().endOf("week")],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      }}
      onChange={handleDateChange}
      {...custom}
      {...rest}
      {...input}
    />
  </Space>
);
    }
