import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import BankTable from "../../../components/AccountingSystemDisplay/ReportsComponent/ReportBankTable";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchOptionCostCentre from "../../../components/ReportsComponent/SearchOptionCostCentre";
import BalanceDetails from "../../../components/AccountingSystemDisplay/ReportsComponent/ReportBalanceDetails";

export const CashAndBankBalance = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isList, setIsList] = useState(true);
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get("/AccountingSystem/reports/balance");
        let data = response.data;
        console.log(data);
        setLists(data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [history, dispatch]);

  const handleCostCentreChange = async (value) => {
    const filteredCostCentre = lists.filter(
      (item) => item.CostCentre === value
    );
    setLists(filteredCostCentre);
  };

  const onViewDetailsClick = (item) => {
    setIsList(false);
    setDetails(item);
  };

  return (
    <>
      <Paper className={classes.Container}>
        <h3 className={classes.Heading}>Cash / Bank Balance</h3>
        {isList && (
          <SearchOptionCostCentre
            handleCostCentreChange={handleCostCentreChange}
          />
        )}
        {!isLoading && isList ? (
          <BankTable lists={lists} onViewDetailsClick={onViewDetailsClick} />
        ) : !isLoading && !isList ? (
          <BalanceDetails details={details} />
        ) : (
          <div className={classes.Container}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton variant="rect" height={200} />
          </div>
        )}
      </Paper>
    </>
  );
};
