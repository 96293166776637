import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import WareHouse from '../../../forms/CommonMaster/WareHouse'
import { wareHouse } from '../../../redux/actions/CommonMasterAction'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import {toastr} from 'react-redux-toastr'


import { allCostCentre } from '../../../redux/actions/CommonMasterAction'
import axios from '../../../components/apis/axios'

export const WareHouseCreate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [costCentreList, setcostCentreList] = useState([])
  const [companyDetail, setCompanyDetail] = useState([])
  const [costCenterGST, setCostCenterGST] = useState([])
  const onSubmit = formValues => {
    console.log(JSON.stringify(formValues))
    dispatch(wareHouse(formValues, history))
  }
  useEffect(() => {
    dispatch(allCostCentre())
  }, [dispatch])

  useEffect(() => {
    async function customer () {
      try {
        const { data } = await axios.get('/commonMaster/companyDetails')
        console.log(data)
        let mapedData = data.map(item => item.CompanyName)
        setCompanyDetail(mapedData)
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }
    customer()
  }, [])

  useEffect(() => {
    async function fetch () {
      try {
        const { data } = await axios.get('/commonMaster/companyDetails')
        
        let company = data.map(item => item.CompanyName)
        setCompanyDetail(company)
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    fetch()
  }, [])

  useEffect(() => {
    async function fetch () {
      try {
        const { data } = await axios.get('/commonMaster/costCentre')
        console.log("company data::", data)
        let company = data.map(item => item.CostCenterName);
        console.log("++++++", company)
        setcostCentreList(company)
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    fetch()
  }, [])

  const findCostCenter = async value => {
  
    try {
      const { data } = await axios.post(
        `commonMaster/costCentre/getByCompanyName/name/`,
        {
          CompanyName: value
        }
      )
      let company = data.map(item => item?.CostCenterName)
      // setcostCentreList(company)
      console.log(company, 'hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
    } catch (err) {
      console.log(err)
    }
  }

  const findCostCenterGST = async value => {
    console.log(value, 'sdfsaddfsa')
    let id
    try {
      const { data } = await axios.post(
        `commonMaster/costCentre/getByCostCenter/name/`,
        {
          CostCenterName: value
        }
      )
      console.log('data', data)
      id = data.find(item => item.CostCenterName === value)
      const payload = await axios.get(`commonMaster/costCentre/${id.id}`)
      const gsts = payload.data.Gsts
      console.log(gsts,"klsllllllllllllllllllllllllllllss");
      setCostCenterGST(gsts)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      
      <Breadcrumbs
        name='Ware house Create'
        linkName1='Warehouse Dashboard'
        to1='/commonMaster/wareHouse'
      />
      <h3 style={{ textAlign: 'center' }}>WareHouse</h3>
      <WareHouse
        onSubmit={onSubmit}
        CClist={costCentreList}
        companyDetail={companyDetail}
        findCostCenter={findCostCenter}
        findCostCenterGST={findCostCenterGST}
        costCenterGST={costCenterGST}
      />
      <div className='float-right'>
        <button
          onClick={() => toastr.success('The title', 'error')}
          type="button">Toastr Success</button>
      </div>
    </div>
  )
}
