import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PaymentRequestForm from "../../../forms/AccountingSystem/PaymentRequestForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const PaymentRequestEdit = (props) => {
  const history = useHistory();
  const [previousFiles, setPreviousFiles] = useState();
  // const [list, setList] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [accounts, setAccounts] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [costCentreList, setCostCentreList] = useState([]);

  const onSubmit = async (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    await axios.put(
      `accountingSystem/paymentRequest/${props.match.params.id}`,
      formValues
    );
    history.push("/accountingSystem/paymentRequest/");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/accountingSystem/paymentRequest/${props.match.params.id}`
        );
        if (data.Status === "accepted" || data.Status === "deleted")
          history.push("/accountingSystem/paymentRequest/");

        setformDetail(data);
        setPreviousFiles([...data.Files]);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id, history]);

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      detail = {
        ProjectCodeName: detail.ProjectShortDescription,
        CustomerName: detail.CustomerName,
      };
      setformDetail({ ...formDetail, ...detail });
    }
  };

  // const SelectProjectFromCostCentre = async (CostCenterCode) => {
  //   if (CostCenterCode) {
  //     try {
  //       const { data } = await axios.post(
  //         `/salesAccounting/projectCircular/costCentre/Name`,
  //         { CostCentre: CostCenterCode }
  //       );
  //       console.log("fetch projject==>", data);
  //       const filtered = data.filter((item) => item.Status === "accepted");
  //       allProject = filtered;
  //       let PNlist = filtered.map((item) => item.ProjectShortDescription);
  //       setList(PNlist);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  useEffect(() => {
    async function getEmployees() {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail");
        const mapped = data.map((item) => item.EmployeeName);
        setEmployeeList(mapped);
      } catch (err) {
        console.error(err);
      }
    }
    const fetch = async () => {
      try {
        const { data: accounts } = await axios.get("/commonMaster/account/");
        setAccounts(accounts);
      } catch (err) {
        console.error(err);
      }
    };
    async function getVendor() {
      try {
        const { data } = await axios.get("/commonMaster/vendor");
        const mapped = data.map((item) => item.VendorName);
        setVendorList(mapped);
      } catch (err) {
        console.error(err);
      }
    }
    async function costFetch() {
      const { data } = await axios.get("/commonMaster/costCentre");
      const filteredData = data.map((item) => item.CostCenterName);
      setCostCentreList(filteredData);
    }
    costFetch();
    getVendor();
    fetch();
    getEmployees();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Payment Request Create"
        linkName1="Payment Request Dashboard"
        to1="/accountingSystem/paymentRequest"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Payment Request
      </h3>
      <PaymentRequestForm
        onSubmit={onSubmit}
        // list={list}
        selectProjectName={selectProjectName}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Currency",
          "EmployeeCodeName",
          "VendorCodeName",
          "PaymentMode",
          "PaymentFor",
          "Remarks",
          "TotalPRValue",
          "CostCentre",
          "PAN",
          "InFavourOf",
          "FinancialPeriod"
        )}
        employeeList={employeeList}
        payment={formDetail.paymentDetails}
        accounts={accounts}
        vendorList={vendorList}
        previousFiles={previousFiles}
        costCentreList={costCentreList}
      />
    </div>
  );
};
