import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import { Button } from "@material-ui/core";
import "./main.css";

class MachinePurchaseOrderAmendmentForm extends React.Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  render() {
    return (
      <div className="formcontainer">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div>
            <Field
              name="ProjectCodeName"
              component={Control.RenderTextField}
              label="ProjectCode / Name"
              style={{ width: "90%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="CustomerName"
              component={Control.RenderTextField}
              label="Customer Name"
              style={{ width: "44%", margin: "1rem" }}
            />
            <Field
              name="MachineCodeName"
              component={Control.RenderTextField}
              label="Machine Code/Name"
              style={{ width: "44%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="Date"
              component={Control.RenderDateField}
              label="Date"
              style={{ width: "20%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="ActivityCodeDescription1"
              component={Control.RenderTextField}
              label="Activity Code/Description"
              style={{ width: "80%", margin: "1rem" }}
            />
            <Field
              name="Percentage1"
              component={Control.RenderTextField}
              label="Percentage"
              type="number"
              style={{ width: "8%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="ActivityCodeDescription2"
              component={Control.RenderTextField}
              label="Activity Code/Description"
              style={{ width: "80%", margin: "1rem" }}
            />
            <Field
              name="Percentage2"
              component={Control.RenderTextField}
              label="Percentage"
              type="number"
              style={{ width: "8%", margin: "1rem" }}
            />
          </div>
          <div>
            <Field
              name="ActivityCodeDescription3"
              component={Control.RenderTextField}
              label="Activity Code/Description"
              style={{ width: "80%", margin: "1rem" }}
            />
            <Field
              name="Percentage3"
              component={Control.RenderTextField}
              label="Percentage"
              type="number"
              style={{ width: "8%", margin: "1rem" }}
            />
          </div>
          <div className="middle">
            <Button
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#17a2b8",
                borderColor: "#17a2b8",
                border: "1px solid transparent",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                borderRadius: "0.25rem",
                width: "20%",
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "ActivityCodeDescription1",
    "ActivityCodeDescription2",
    "ActivityCodeDescription3",
    "Percentage1",
    "Percentage2",
    "Percentage3",
    "Date",
    "MachineCodeName",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "MachinePurchaseOrderAmendmentForm",
  validate,
  enableReinitialize: true,
})(MachinePurchaseOrderAmendmentForm);
