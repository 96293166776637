import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Bank from "../../../forms/CommonMaster/Bank";
import { useHistory } from "react-router-dom";
import {
  editBank,
  searchBankById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

import StateList from "../../../components/helpers/StateList";

export const BankEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const [stateList, setStateList] = React.useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchBankById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleBank) {
      setData(store.SingleBank);
    }
  }, [store.SingleBank]);

  const onSubmit = (formValues, history) => {
    dispatch(editBank(formValues, props.match.params.id, history));
  };

  React.useEffect(() => {
    async function fetch() {
      try {
        let state = StateList.map((item) => item.name);
        setStateList(state);
      } catch (err) {}
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Bank Edit"
        linkName1="/commonMaster/bank"
        to1="Bank Dashboard"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Bank Edit
      </h3>
      <div className="line"></div>
      <Bank
        stateList={stateList}
        initialValues={pick(
          data,
          "BankCode",
          "BankName",
          "BankAddress",
          "City",
          "State",
          "PIN",
          "AccountNo",
          "IFSC",
          "MICR",
          "AccountType",
          "AuthorisedSignatory1",
          "ChequeStartingNo",
          "AuthorisedSignatory2",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
