import React from "react";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import HrDailyAttendance from "../../../forms/ProjectHRManagement/HrDailyAttendance/HrDailyAttendance";
import { createHrDailyAttendance } from "../../../redux/actions/HRManagementActions";

export const HrDailyAttendanceCreate = () => {
  const history = useHistory();

  const onSubmit = (formValues) => {
    createHrDailyAttendance(formValues, history);
  };

  return (
    <div>
      <Breadcrumbs
        name="Daily Attendance Create"
        linkName1="Daily Attendance Dashboard"
        to1="/project-hr-management/daily-attendance"
      />
      <FormHeading label="Daily Attendance" />
      <HrDailyAttendance
        onSubmit={onSubmit}
      />
    </div>
  );
};
