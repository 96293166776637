import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import axios from "../apis/axios";

const SearchByProject = ({ handleProjectChange }) => {
  // const [workmen, setWorkmen] = useState([]);

  // useEffect(() => {
  //   const fetchWorkmen = async () => {
  //     const { data } = await axios.get("/workforceManagement/reports/workmenJoining");
  //     setWorkmen(data);
  //     console.log("Workmen", data);
  //   };
  //   fetchWorkmen();
  // }, []);
  const projects = useSelector(
    (store) => store.SalesAcountRoot.AllProjectCircular
  );
  const workmenJoining = useSelector(
    (store) => store.ProjectWorkforceManagementRoot?.FilteredworkmenJoining
  );

  const getOptions = (data) => {
    if (
      data === undefined ||
      workmenJoining === undefined ||
      workmenJoining?.length === 0
    )
      return projects;
 
    console.log(workmenJoining);

    const workmen = workmenJoining.map(
      (item) => item.ProjectCode.split(" ")[0]
    );
    const filteredData = data.filter(
      (item) => item.Status === "accepted" && workmen.includes(item.ProjectCode)
    );
    let filteredProject = filteredData.map(
      (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
    );

    return filteredProject;
  };

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={getOptions(projects)}
        onChange={(event, value) => handleProjectChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter Project"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchByProject;