import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";


const MachineClass = (props) => {
    const [show, setShow] = React.useState(false);

    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="MachineClassCode"
            label="Machine Class Code"
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialClassDes"
            label="Material Class Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="machineClass"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = ["MachineClassCode", "MaterialClassDescription"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "machineClass",
  validate,
  enableReinitialize: true,
})(MachineClass);
