import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import MachineClass from "../../../forms/CommonMaster/MachineClass";
import { machineClass } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MachineClassCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machineClass(formValues, history));
  };

  return (
    <div>
      <Breadcrumbs
        name="Machine Class Create"
        linkName1="Machine Class Dashboard"
        to1="/commonMaster/machineClass"
      />
      <FormHeading label="Machine Class" />

      <MachineClass onSubmit={onSubmit} />
    </div>
  );
};
