import React, { useState, useEffect } from "react";


import LinearProgress from "@material-ui/core/LinearProgress";

export const MachineHireReqTableView = props => {
  const [data, setData] = useState([]);
  const { materials } = props;
  const { column } = props;
  const { columnHeading } = props;
  useEffect(() => {
    let values = [];
    if (materials) {
      values = [...materials];
    }
    setData(values);
  }, [materials]);
  return (
    <>
      <div className="tableContainer">
        <table>
          <thead className="table-head">
            <tr>
              <th align="center" className="head-cell">Sl.No</th>
              <th align="center" className="head-cell">{columnHeading ? columnHeading.col1 : ""}</th>
              <th align="center" className="head-cell">{columnHeading ? columnHeading.col2 : ""}</th>
              <th align="center" className="head-cell">HSN/SAC Code</th>
              <th align="center" className="head-cell">UOM</th>
              <th align="center" className="head-cell">{columnHeading ? columnHeading.col5 : ""}</th>
              <th align="center" className="head-cell">{columnHeading ? columnHeading.col6 : ""}</th>
              <th align="center" className="head-cell">{columnHeading ? columnHeading.col7 : ""}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td align="center" className="table-data1">{row.SerialNo}</td>
                <td align="center" className="table-data2">{column && row ? row[column.col1] : ""}</td>
                <td align="center" className="table-data2">{column && row ? row[column.col2] : ""}</td>
                <td align="center" className="table-data2">{row.HSNSACCode}</td>
                <td align="center" className="table-data2">{row.UOM}</td>
                <td align="center" className="table-data2">{column && row ? row[column.col5] : ""}</td>
                <td align="center" className="table-data2">{column && row ? row[column.col6] : ""}</td>
                <td align="center" className="table-data4">{column && row ? row[column.col7] : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.length !== 0 ? "" : <LinearProgress variant="indeterminate" />}
    </>
  );
};
