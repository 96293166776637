import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RoleList from "../../helpers/RoleList";

export const SalesAccountingSystemNav = {
  moduleAccess: [
    RoleList.ADMIN.code,
    RoleList.BU_HEAD.code,
    RoleList.SITE_SUPERVISOR.code,
    RoleList.SITE_INCHARGE.code,
    RoleList.BU_COMMERCIAL.code,
    RoleList.COMMERCIAL_ASSISTANT.code,
    RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
    RoleList.CASHIER.code,
    RoleList.DIRECTOR.code,
    RoleList.SITE_STORE_KEEPER.code,
  ],
  label: "Sales Accounting System",
  name: "SalesAccounting",
  attrModule: "salesAccounting",
  icon: <AccountBalanceIcon style={{ fontSize: "25px", color: "blue" }} />,
  navs: [
    {
      to: "/salesAccounting/projectCircular",
      label: "Project Circular",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/salesOrder",
      label: "Sales Order",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/salesOrderAmendment",
      label: "Sales Order Amendment",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.BU_COMMERCIAL.code,
        RoleList.COMMERCIAL_ASSISTANT.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.CASHIER.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/salesInvoice",
      label: "Sales Invoice",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/salesInvoiceCertification",
      label: "Sales Invoice Certification",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/salesInvoiceReversal",
      label: "Sales Invoice Reversal",
      access: [
        RoleList.ADMIN.code,
        RoleList.BU_HEAD.code,
        RoleList.SITE_SUPERVISOR.code,
        RoleList.SITE_INCHARGE.code,
        RoleList.ACCOUNT_AND_ADMIN_HEAD.code,
        RoleList.DIRECTOR.code,
        RoleList.SITE_STORE_KEEPER.code,
      ],
    },
    {
      to: "/salesAccounting/reports",
      label: "Reports",
      access: [RoleList.ADMIN.code],
    },
  ],
};
