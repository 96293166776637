import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchMachineRepairRegisterById,
  approvalMachineManagement,
} from "../../../redux/actions/MachineManagementaction";
import useStyle from "./UseStyle";
import RoleList from "../../../components/helpers/RoleList";

export const MachineRepairRegisterView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchMachineRepairRegisterById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.machineMangementSystemRoot.SingleMachineRepairRegister) {
      setData(store.machineMangementSystemRoot.SingleMachineRepairRegister);
    }
  }, [store.machineMangementSystemRoot.SingleMachineRepairRegister]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalMachineManagement(
        "machineRepairRegister",
        match.params.id,
        history,
        data
      )
    );
  };
  const acessButton = () => {
    if (post === RoleList.ADMIN.code || 
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code)
      return (
        <div className={classes.ButtonGrp}>
          <Button
            variant="contained"
            className={classes.approve}
            onClick={() => onApprove("Approved")}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            className={classes.reject}
            onClick={() => onApprove("Rejected")}
          >
            Reject
          </Button>
        </div>
      );
    return;
  };
  return (
    <>
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Machine Repair Register</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Project code / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Machine Code / Name:</h6>
              <p>{data.MachineCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Repair Date and Time:</h6>
              <p>{data.RepairDateTime}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Comm. Date Time:</h6>
              <p>{data.CommDateTime}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Nature of Defects:</h6>
              <p>{data.NatureOfDefects}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Part / Assembly:</h6>
              <p>{data.PartAssembly}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Cause of Breakdown:</h6>
              <p>{data.CauseOfBreakdown}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Repair By:</h6>
              <p>{data.RepairBy}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Repair Details:</h6>
              <p>{data.RepairDetails}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor Code / Name:</h6>
              <p>{data.VendorCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Work Order No:</h6>
              <p>{data.WorkOrderNo}</p>
            </Paper>
          </div>
          <div className={classes.row}>
            <Paper className={classes.paper}>
              <h6>Date:</h6>
              <p>{data.Date}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Next Schedule Maintenance Date:</h6>
              <p>{data.NextScheduleMaintenanceDate}</p>
            </Paper>
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
