import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../style/materialSystem.css";
import PurchaseRequestForm from "../../../forms/MaterialSystem/PurchaseRequestForm";
import { purchaseRequest } from "../../../redux/actions/materialSystemAction";
import pick from "lodash/pick";
import { allActiveProjects } from "../../../redux/actions/GeneralAction";
import axios from "../../../components/apis/axios";
import { useSnackbar } from "notistack";
// import RoleList from "../../../components/helpers/RoleList";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const PurchaseRequestFormCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const [materialList, setMaterialList] = useState(null);
  const [projectDetail, setProjectDetail] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(purchaseRequest(formValues, history, enqueueSnackbar));
  };

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = store.GeneralRoot.AllProjectName.find((item) =>
        projectName.includes(item.ProjectCode)
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail?.id}`
        );
        detail = {
          ...detail,
          ProjectCodeName: projectName,
          Currency: data.Currency,
          WarehouseName: data.WareHouse,
          WareHouseGst: data.WareHouseGst,
        };
        setProjectDetail(detail);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    dispatch(allActiveProjects());
  }, [dispatch]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`/commonMaster/material`);
      let newData = data.filter((item) => item.Tags === "Active");
      console.log("data1", data);
      console.log("data2", newData);
      setMaterialList(newData);
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Purchase Request  Create"
        linkName1=" Purchase Request  Dashboard"
        to1="/materialManagement/purchaseRequest"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Purchase Request
      </h3>
      <div className="line"></div>
      <PurchaseRequestForm
        list={store.GeneralRoot?.AllActiveProjects}
        onSubmit={onSubmit}
        selectProjectName={selectProjectName}
        materialList={materialList}
        edit={false}
        initialValues={pick(
          projectDetail,
          "ProjectCodeName",
          "Currency",
          "WarehouseName",
          "WareHouseGst"
        )}
      />
    </div>
  );
};
