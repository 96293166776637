import {
  SET_GOODS_INSPECTION_VOUCHER,
  SET_GOODS_RECEIPT_VOUCHER,
  SET_PURCHASE_ORDER_AMENDMENT,
  SET_PURCHASE_ORDER,
  SET_PURCHASE_REQUEST,
} from "../actions/actionTypes";
const initialState = {
  GoodsInspectionVoucher: {},
  GoodsReceiptVoucher: {},
  PurchaseOrderAmendment: {},
  PurchaseOrder: {},
  PurchseRequest: {},
  WarehouseReceiptVoucher: {},
  SinglePurchaseRequest: {},
  SinglePurchaseOrder: {},
  SinglePurchaseAmendment: {},
  SingleGoodsInspection: {},
  SingleGoodsReceipt: {},
  SingleWarehouseReturn: {},
  SingleWarehouseReceipt: {},
  SingleWarehouseIssue: {},
  SingleDeliveryChallan: {},
  SinglePhysicalVerification: {},
  AllPurchaseRequest: [],
  AllPurchaseOrder: [],
  AllPurchaseAmendment: [],
  AllDeliveryChallan: [],
  AllPhysicalVerification: [],
  AllGoodsInspectionVoucher: [],
  AllGoodsReceiptVoucher: [],
  AllWarehouseIssueVoucher: [],
  AllWarehouseReceiptVoucher: [],
  AllWarehouseReturnVoucher: [],
  SingleWarehouseIndentVoucher: {},
  AllWarehouseIndentVoucher: [],
};

const materialSystemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GOODS_INSPECTION_VOUCHER: {
      return {
        ...state,
        GoodsInspectionVoucher: action.payload,
      };
    }
    case SET_GOODS_RECEIPT_VOUCHER: {
      return {
        ...state,
        GoodsReceiptVoucher: action.payload,
      };
    }
    case SET_PURCHASE_ORDER_AMENDMENT: {
      return {
        ...state,
        PurchaseOrderAmendment: action.payload,
      };
    }
    case SET_PURCHASE_ORDER: {
      return {
        ...state,
        PurchaseOrder: action.payload,
      };
    }
    case SET_PURCHASE_REQUEST: {
      return {
        ...state,
        PurchseRequest: action.payload,
      };
    }
    case "WAREHOUSE_RECEIPT_VOUCHER": {
      return {
        ...state,
        WarehouseReceiptVoucher: action.payload,
      };
    }
    case "SET_SINGLE_PHYSICAL_VERIFICATION": {
      return {
        ...state,
        SinglePhysicalVerification: action.payload,
      };
    }
    case "SET_SINGLE_DELIVERY_CHALLAN": {
      return {
        ...state,
        SingleDeliveryChallan: action.payload,
      };
    }
    case "SET_SINGLE_WAREHOUSE_ISSUE": {
      return {
        ...state,
        SingleWarehouseIssue: action.payload,
      };
    }
    case "SET_SINGLE_WAREHOUSE_RECEIPT": {
      return {
        ...state,
        SingleWarehouseReceipt: action.payload,
      };
    }
    case "SET_SINGLE_WAREHOUSE_RETURN": {
      return {
        ...state,
        SingleWarehouseReturn: action.payload,
      };
    }
    case "SET_SINGLE_GOODS_RECEIPT": {
      return {
        ...state,
        SingleGoodsReceipt: action.payload,
      };
    }
    case "SET_SINGLE_GOODS_INSPECTION": {
      return {
        ...state,
        SingleGoodsInspection: action.payload,
      };
    }
    case "SET_SINGLE_PURCHASE_REQUEST": {
      return {
        ...state,
        SinglePurchaseRequest: action.payload,
      };
    }
    case "SET_SINGLE_PURCHASE_ORDER": {
      return {
        ...state,
        SinglePurchaseOrder: action.payload,
      };
    }
    case "SET_SINGLE_PURCHASE_ORDER_AMENDMENT": {
      return {
        ...state,
        SinglePurchaseAmendment: action.payload,
      };
    }
    //WAREHOUSE_INDENT_VOUCHER
    case "SET_SINGLE_WAREHOUSE_INDENT_VOUCHER": {
      return {
        ...state,
        SingleWarehouseIndentVoucher: action.payload,
      };
    }
    case "SET_ALL_WAREHOUSE_INDENT_VOUCHER": {
      return {
        ...state,
        AllWarehouseIndentVoucher: action.payload,
      };
    }
    //WAREHOUSE_INDENT_VOUCHER
    case "SET_ALL_PURCHASE_REQUEST": {
      return {
        ...state,
        AllPurchaseRequest: action.payload,
      };
    }

    case "SET_ALL_PHYSICAL_VERIFICATION": {
      return {
        ...state,
        AllPhysicalVerification: action.payload,
      };
    }
    case "SET_ALL_PURCHASE_ORDER": {
      return {
        ...state,
        AllPurchaseOrder: action.payload,
      };
    }
    case "SET_ALL_DELIVERY_CHALLAN": {
      return {
        ...state,
        AllDeliveryChallan: action.payload,
      };
    }
    case "SET_ALL_PURCHASE_ORDER_AMENDMENT": {
      return {
        ...state,
        AllPurchaseAmendment: action.payload,
      };
    }
    case "SET_ALL_GOODS_INSPECTION_VOUCHER": {
      return {
        ...state,
        AllGoodsInspectionVoucher: action.payload,
      };
    }
    case "SET_ALL_GOODS_RECEIPT_VOUCHER": {
      return {
        ...state,
        AllGoodsReceiptVoucher: action.payload,
      };
    }
    case "SET_ALL_WAREHOUSE_ISSUE_VOUCHER": {
      return {
        ...state,
        AllWarehouseIssueVoucher: action.payload,
      };
    }
    case "SET_ALL_WAREHOUSE_RECEIPT_VOUCHER": {
      return {
        ...state,
        AllWarehouseReceiptVoucher: action.payload,
      };
    }
    case "SET_ALL_WAREHOUSE_RETURN_VOUCHER": {
      return {
        ...state,
        AllWarehouseReturnVoucher: action.payload,
      };
    }
    default:
      return state;
  }
};

export default materialSystemReducer;
