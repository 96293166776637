import React, { useEffect, useState } from "react";
import Calender from "../../components/AttendanceTable-Calender/Calender";
import useStyle from "../helpers/UseStyle";
import Paper from "@material-ui/core/Paper";
import axios from "../../components/apis/axios";

import Grid from "@material-ui/core/Grid";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
const DailyAttendanceDetails = ({ details }) => {
  const classes = useStyle();
  const [workmenDetails, setWorkmenDetails] = useState(null);

  useEffect(() => {
    async function fetch() {
      try {
        const req = {
          WorkmenName: details,
        };
        const { data } = await axios.post(
          `/workforceManagement/reports/dailyAttendance/`,
          req
        );

        if (data) {
          let values = [];
          for (let index = 0; index < data.length; index++) {
            let date = new Date(data[index].AttendanceDate)
              .toISOString()
              .split("T")[0]
              .split("-");
            // console.log(date, new Date(date[0], date[1], date[1], 0, 0, 0));
            values = [
              ...values,
              {
                title: data[index].ProjectCodeName,
                startDate: new Date(date[0], date[1], date[1], 0, 0, 0),
                endDate: new Date(date[0], date[1], date[1], 23, 0, 0),
                NormalHour: data[index].NormalHour,
                Overtime: data[index].Overtime,
                Present:data[index].Present,
              },
            ];
          }
          setWorkmenDetails(values);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [details]);
 
   console.log("workmenDetails", workmenDetails);
    
   const Content = ({ appointmentData, ...restProps }) => (
     <AppointmentTooltip.Content
       {...restProps}
       appointmentData={workmenDetails}
     >
       <Grid container alignItems="center">
         <Grid item xs={2} align="center">
           <DesktopMacIcon />
         </Grid>
         <Grid item xs={10}>
           <span>
             <b>Project Name</b> : {appointmentData.title}
           </span>
         </Grid>
         <Grid item xs={2} align="center">
           <QueryBuilderIcon />
         </Grid>
         <Grid item xs={10}>
           <span>
             <b>Working Hour</b> : {appointmentData.NormalHour}
           </span>
         </Grid>
         <Grid item xs={2} align="center">
           <QueryBuilderIcon />
         </Grid>
         <Grid item xs={10}>
           <span>
             <b>Working Hour</b> : {appointmentData.Overtime}
           </span>
         </Grid>
         <Grid item xs={2} align="center">
           <QueryBuilderIcon />
         </Grid>
         <Grid item xs={10}>
           <span>
             <b>Present</b> :
             {
               appointmentData.Present === true ? ("Present") : ("Absent")
             
             }
           </span>
         </Grid>
       </Grid>
     </AppointmentTooltip.Content>
   );

  return (
    <Paper className={classes.Container}>
      <h2 className="text-center">Attendance View</h2>
      <Calender details={workmenDetails} Content={Content} />
    </Paper>
  );
};

export default DailyAttendanceDetails;
