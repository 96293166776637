import React from "react";

import * as XLSX from "xlsx";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { readAllFromQueryParams } from "../../utils/queryParams";
import moment from "moment";

export const ExportCSV = ({ csvData, fileName, variant, title, loading }) => {
  const { search } = useLocation();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    const params = readAllFromQueryParams(search);

    let value = csvData[0];
    let columns = [];

    const getColWidth = (value) => {
      if (value && typeof value === "string") {
        return value?.length < 12 ? 17 : value?.length + 5;
      }
      return 17;
    };

    for (let i in value) {
      columns.push({
        width: getColWidth(value[i]),
      });
    }

    var ws = XLSX.utils.aoa_to_sheet([
      ["Company Name", ""],
      [],
      [title ?? `List of ${fileName}`],
      [
        "",
        "Filters:",
        "Searched For:",
        params.query ?? "",
        " ",
        "Status:",
        params.status ?? "",
        "",
        "Report Date:",
        moment().format("DD-MM-YYYY"),
      ],
      [
        " ",
        " ",
        "Period Date From:",
        params?.fromDate ? moment(params.fromDate).format("DD-MM-YYYY") : "",
        "",
        "Period Date to:",
        params?.toDate ? moment(params.toDate).format("DD-MM-YYYY") : "",
      ],
      [],
    ]);

    /* add row objects to sheet starting from cell A6 */
    XLSX.utils.sheet_add_json(
      ws,
      [...csvData, { "SI. No.": `Total=${csvData.length}` }],
      {
        origin: "A7",
      }
    );

    /* generate workbook and add worksheet */
    var wb = XLSX.utils.book_new();
    ws["!cols"] = columns;
    ws["!merges"] = [XLSX.utils.decode_range("A3:AN3")];

    wb["!cols"] = columns;
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, fileName + fileExtension);
  };

  return (
    <>
      {variant ? (
        <span onClick={(e) => exportToCSV(csvData, fileName)}>Excel</span>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "99%",
            margin: "5px auto",
          }}
        >
          <Button
            style={{ background: "grey", color: "#ffffff", fontWeight: "600" }}
            onClick={(e) => exportToCSV(csvData, fileName)}
            disabled={loading}
          >
            {loading ? "Exporting..." : "Export"}
          </Button>
        </div>
      )}
    </>
  );
};
