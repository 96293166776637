import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "../../components/apis/axios";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));

const EmployeeDetails = (props) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyle();
  const [benefits, setBenefits] = useState([]);
  const fieldValues = useSelector(state => state.form?.EmployeeDetails?.values)

  const onSubmit = (formValues) => {
    if (benefits.length > 0 && formValues?.benefits) {
      const benefit = benefits.find(
        (acc) => acc.label === formValues?.benefits
      );
      if(benefit) {
        formValues["benefits"] = benefit?.value;
      }
    }
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  // const validatePhoneNo = (value) =>
  //   value && !/^[0-9]$/.test(value) && value.length !== 10
  //     ? "Phone number invalid"
  //     : undefined;

  // const validateEmail = (value) =>
  //   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  //     ? "Invalid email address"
  //     : undefined;

  const [isValid, setIsValid] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (value) => {
    // const email = value;
    if (emailRegex.test(value)) {
      setIsValid(true);
      setMessage(`Great your "${value}" is valid 👍`);
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email! 👎");
    }
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const { data } = await axios.get("/commonMaster/benefits");
        setBenefits(
          data?.map((account) => ({
            label: account.benefitCode,
            value: account.id,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
  }, []);

  const {
    RenderTextField,
    AutoCompleteInput,
    RenderDatePastField,
    RenderMonthField,
    RenderDateField,
  } = Control;

  const inputsFields = [
    {
      component: RenderTextField,
      name: "EmployeeCode",
      label: "Employee Code",
      disabled: !!props?.initialValues?.EmployeeCode,
    },
    {
      component: RenderTextField,
      name: "fatherOrHusband",
      label: "Father / Husband Name",
    },
    {
      component: AutoCompleteInput,
      name: "employeeType",
      list: ["Permanent", "Contract"],
      label: "Employee Type",
    },
    {
      component: RenderDatePastField,
      name: "dateOfBirth",
      label: "Date Of Birth ( 18+ )",
      initialValue: fieldValues?.dateOfBirth || null,
      maxDate: moment().subtract(18, "year").format("d MMM yyyy"),
    },
    {
      component: RenderTextField,
      name: "FirstName",
      label: "Employee First Name",
    },
    {
      component: RenderTextField,
      name: "LastName",
      label: "Employee Last Name",
    },
    {
      component: RenderTextField,
      name: "Email",
      label: "Email",
      validate: [validateEmail],
      helperText: message,
    },
    {
      component: AutoCompleteInput,
      name: "gender",
      list: ["Male", "Female", "Other"],
      label: "Gender",
    },
    {
      component: RenderTextField,
      name: "religion",
      label: "Religion",
    },
    {
      component: RenderTextField,
      name: "motherTongue",
      label: "Mother Tongue",
    },
    {
      component: RenderTextField,
      name: "presentAddress",
      label: "Present Address",
    },
    {
      component: RenderTextField,
      name: "permanentAddress",
      label: "Permanent Address",
    },
    {
      component: RenderTextField,
      name: "Phone",
      label: "Phone",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: RenderTextField,
      name: "emergencyContactNumber",
      label: "Emergency Contact Number",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: AutoCompleteInput,
      name: "maritalStatus",
      list: ["Married", "Unmarried"],
      label: "Marital Status",
    },
    {
      component: RenderTextField,
      name: "noOfChildren",
      label: "No of Children",
      isHidden: fieldValues?.maritalStatus !== 'Married',
    },
    {
      component: RenderTextField,
      name: "identificationMark",
      label: "Identification Mark",
    },
    {
      component: RenderTextField,
      name: "height",
      label: "Height (In cent.)",
    },
    {
      component: RenderTextField,
      name: "weight",
      label: "Weight (In KG)",
    },
    {
      component: AutoCompleteInput,
      name: "vision",
      list: ["Normal", "Specs"],
      label: "Vision",
    },
    {
      component: RenderTextField,
      name: "leftEye",
      label: "Left Eye",
      isHidden: fieldValues?.vision !== 'Specs',
    },
    {
      component: RenderTextField,
      name: "rightEye",
      label: "Right Eye",
      isHidden: fieldValues?.vision !== 'Specs',
    },
    {
      component: RenderTextField,
      name: "educationalQualification",
      label: "Educational Qualification",
    },
    {
      component: RenderTextField,
      name: "pastExperience",
      label: "Past Experience",
    },
    {
      component: RenderDateField,
      name: "dateOfJoining",
      initialValue: fieldValues?.dateOfJoining || null,
      label: "Date of Joining",
    },
    {
      component: RenderDateField,
      name: "dateOfExit",
      initialValue: fieldValues?.dateOfExit || null,
      label: "Date of Exit",
    },
    {
      component: RenderTextField,
      name: "designation",
      label: "Designation",
    },
    {
      component: RenderTextField,
      name: "roleDescription",
      label: "Role Description",
    },
    {
      component: RenderTextField,
      name: "pFAccountNo",
      label: "PF Account No",
    },
    {
      component: RenderTextField,
      name: "universalAccountNo",
      label: "Universal Account No",
    },
    {
      component: RenderTextField,
      name: "eSIC",
      label: "ESIC",
    },
    {
      component: RenderTextField,
      name: "bankAccountNo",
      label: "Bank Account No",
    },
    {
      component: RenderTextField,
      name: "bankName",
      label: "Bank Name",
    },
    {
      component: RenderTextField,
      name: "IFSC",
      label: "IFSC",
    },
    {
      component: RenderTextField,
      name: "aadhar",
      label: "Aadhar",
    },
    {
      component: RenderTextField,
      name: "PAN",
      label: "PAN",
    },
    {
      component: RenderTextField,
      name: "wages",
      label: "Wages ( In Rupees )",
    },
    {
      component: AutoCompleteInput,
      name: "oTType",
      label: "OT Type",
      list: ["Single", "Double", "One and Half time", "Not Applicable"],
    },
    {
      component: AutoCompleteInput,
      name: "benefits",
      label: "Benefits",
      list: benefits,
    },
    {
      component: AutoCompleteInput,
      name: "Company",
      list: props.companyDetail,
      label: "Company",
    },
    {
      component: AutoCompleteInput,
      name: "Tags",
      label: "Tag",
      list: ["", "Active", "Inactive"],
    },
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        <Grid container spacing={2}>
          {inputsFields
            .filter((hiddenField) => !hiddenField.isHidden)
            .map((field, index) => (
              <Grid item xs={6} key={index}>
                <Field {...field} />
              </Grid>
            ))}
        </Grid>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Employee Masters"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "Tag",
    "Company",
    "Email",
    "Phone",
    "LastName",
    "FirstName",
    "EmployeeCode",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  if(values?.dateOfJoining && values?.dateOfExit) {
    const dateOfJoining = moment(values?.dateOfJoining);
    const dateOfExit = moment(values?.dateOfExit)
    if(dateOfExit.isSameOrBefore(dateOfJoining)) {
      err['dateOfExit'] = 'Date of exit should be greater than joining';
    }
  }

  return err;
};

export default reduxForm({
  form: "EmployeeDetails",
  validate,
  enableReinitialize: true,
})(EmployeeDetails);
