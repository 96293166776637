import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  searchCostCentreById,
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "./UseStyle";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@material-ui/core/TextField";
import GSTTableView from "../../../Tables/CommonMaster/GSTTableView";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const CostCentreView = (props) => {
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  //approval and reject
  const [ApproveModal, setApproveModal] = React.useState(false);
  const [RejectModal, setRejectModal] = React.useState(false);
  const handleOpensetApproveModal = () => {
    setApproveModal(true);
  };

  const handleClosesetApproveModal = () => {
    setApproveModal(false);
  };
  const handleOpenRejectModal = () => {
    setRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setRejectModal(false);
  };

  //approval and reject

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchCostCentreById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.commonMasterSystemRoot.SingleCostCentre) {
      setData(store.commonMasterSystemRoot.SingleCostCentre);
      console.log("data", store.commonMasterSystemRoot.SingleCostCentre);
    }
  }, [store.commonMasterSystemRoot.SingleCostCentre]);
  const classes = useStyle();
  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("costCentre", match.params.id, history, data)
    );
  };
  const acessButton = () => {
    if (
      (post === RoleList.ADMIN.code ||
        post === RoleList.BU_HEAD.code ||
        post === "companyHead") &&
      data.Status === "not_reviewed"
    )
      return (
        <div className={classes.ButtonGrp}>
          <div>
            <div>
              <Button
                variant="contained"
                className={classes.approve}
                onClick={handleOpensetApproveModal}
              >
                Approve
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={ApproveModal}
                onClose={handleOpensetApproveModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <div className={classes.paper}>
                  <h2 id="transition-modal-title">Cost Center Approval</h2>
                  <p id="transition-modal-description">
                    Are you sure with that?
                  </p>

                  <TextField
                    name="OptionalMessage"
                    label="Message(Optional)"
                    style={{ width: "90%", margin: "1rem" }}
                  />
                  <div className="middle">
                    <div className="row">
                      <Button
                        variant="contained"
                        className={classes.yesApprove}
                        onClick={() => onApprove("Approved")}
                      >
                        yes
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        className={classes.NoApprove}
                        onClick={handleClosesetApproveModal}
                      >
                        cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <div>
              <Button
                variant="contained"
                className={classes.reject}
                onClick={handleOpenRejectModal}
              >
                Reject
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={RejectModal}
                onClose={handleCloseRejectModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <div className={classes.paper}>
                  <h2 id="transition-modal-title">Cost Center Rejection</h2>
                  <p id="transition-modal-description">
                    Are you sure with that?
                  </p>

                  <TextField
                    name="Message"
                    label="Message"
                    style={{ width: "90%", margin: "1rem" }}
                  />
                  <div className="middle">
                    <div className="row">
                      <Button
                        variant="contained"
                        className={classes.yesApprove}
                        onClick={() => onApprove("Rejected")}
                      >
                        yes
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        className={classes.NoApprove}
                        onClick={handleCloseRejectModal}
                      >
                        cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      );
    return;
  };
  return (
    <>
      <Breadcrumbs
        name="Cost Center View"
        linkName1="Cost Center Dashbord"
        to1="/commonMaster/costCentre"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Cost Centre</h3>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            
            {data.CompanyName && (
            <Paper className={classes.paper}>
              <h6>Company Name:</h6>
              <p>{data.CompanyName}</p>
            </Paper>)}

            {data.CostCenterName && (
            <Paper className={classes.paper}>
              <h6>Cost Centre Name:</h6>
              <p>{data.CostCenterName}</p>
            </Paper>)}

            {data.ProjectSiteAddress && (
            <Paper className={classes.paper}>
              <h6>Project Site Address:</h6>
              <p>{data.ProjectSiteAddress}</p>
            </Paper>)}

            {data.Tags && (
              <Paper className={classes.paper}>
                <h6>Tags: </h6>
                <p>{data.Tags}</p>
              </Paper>)};

          </div>
          <div style={{ margin: "1rem" }}>
            <GSTTableView gsts={data.Gsts} company={data.CompanyName}/>
          </div>
        </Box>
      </Paper>
      {acessButton()}
    </>
  );
};
