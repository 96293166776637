import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import EmployeeJoin from "../../../forms/ProjectHRManagement/EmployeeJoin";
import { searchEmployeeJoinById } from "../../../redux/actions/HRManagementActions";

export const EmployeeJoinEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchEmployeeJoinById(match.params.id);
        setData(data);
    }

    fetch();
  }, [match.params.id]);

  const onSubmit = async (formValues) => {
    await axios.put(`/hr-management/employee-join/${match.params.id}`, formValues);
    history.push("/project-hr-management/employee-join");
  };

  return (
    <div>
      <Breadcrumbs
        name="Employee Join Edit"
        linkName1="Employee Join Dashboard"
        to1="/project-hr-management/employee-join"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Employee Join
      </h3>
      <div className="line"></div>
      <EmployeeJoin
        initialValues={{
            ...data, 
            ...data?.employee,
            ...data?.project,
            employee: data?.employee?.EmployeeCode, 
            project: data?.project?.ProjectCode,
            benefits: data?.employee?.benefits?.benefitCode,
            projectBenefits:  data?.projectBenefits,
            projectJoiningDate:  data?.projectJoiningDate
        }}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
