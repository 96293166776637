import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import GoodsInspectionVoucherForm from "../../../forms/MaterialSystem/GoodsInspectionVoucherForm";
import {
  editgoodsInspect,
  searchGoodsinspectionById,
} from "../../../redux/actions/materialSystemAction";
import "../style/materialSystem.css";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

import axios from "../../../components/apis/axios";
import "../style/materialSystem.css";

export const GoodsInspectionVoucherFormEdit = (props) => {
  const store = useSelector((store) => store.materialSystemRoot);
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [materials, setMaterials] = useState(null);
  const [previousFiles, setPreviousFiles] = useState([]);

  const { match } = props;

  useEffect(() => {
    dispatch(searchGoodsinspectionById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleGoodsInspection) {
      setData(store.SingleGoodsInspection);
      setMaterials(store.SingleGoodsInspection.materials);
      setPreviousFiles(store.SingleGoodsInspection.Files);
    }
  }, [store.SingleGoodsInspection]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    dispatch(editgoodsInspect(formValues, props.match.params.id, history));
  };

  const selectGoodRec = async (GoodsReceiptNo) => {
    const item = data.find(
      (item) => item.GoodsReceiptVoucherNo === GoodsReceiptNo
    );
    if (item) {
      let { data } = await axios.get(
        `/materialManagement/goodsReceipt/${item.id}`
      );
      setMaterials(data.materials);
      setFormDetail({
        ...data,
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`/materialManagement/goodsInspection/`);
      const filtered = data.map((item) => item.Status === "accepted");
      const mapped = filtered.map((item) => item.GoodsReceiptNo);
      setList(mapped);
    };

    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Goods Inspection Voucher Edit"
        linkName1="Goods Inspection Voucher Dashboard"
        to1="/materialManagement/goodsInspection"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Goods Inspection Voucher Edit
      </h3>
      <div className="line"></div>
      <GoodsInspectionVoucherForm
        initialValues={pick(
          data,
          formDetail,
          "GoodsReceiptType",
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "PurchaseSalesOrderNo",
          "PurchaseDate",
          "PurchaseRequestNo",
          "Date",
          "VendorCodeName",
          "CustomerCodeName",
          "WarehouseCodeName",
          "DeliveryChallanNo",
          "DeliveryDate",
          "LRRRConsignmentNo",
          "LRDate",
          "DateofReceipt",
          "BatchIdentificationNo",
          "UnloadedAt",
          "GoodsReceiptVoucherNo"
        )}
        list={list}
        onSubmit={onSubmit}
        selectGoodRec={selectGoodRec}
        materials={materials}
        previousFiles={previousFiles}
      />
    </div>
  );
};
