import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Workmen from "../../../forms/CommonMaster/Workmen";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import {
  editWorkmen,
  getworkmenById,
} from "../../../redux/actions/CommonMasterAction";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { getNumberFromString } from "../../../utils/common";


let AllVendor = [];
let AllCostCentre = [];

export const WorkmenEdit = (props) => {
  const [data, setData] = useState();
  const [benefitlist, setBenefitlist] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [costCentre, setCostCentrelist] = useState([]);
  const [newWorkmenCode, setNewWorkmenCode] = useState("");
  const history = useHistory();

  const { match } = props;


  
  useEffect(() => {
    if (!match.params.id) return;
    const fetch = async () => {
      try {
        const data = await getworkmenById(match.params.id);
        setData(data);
      } catch (error) {
        //
      }
    };
    fetch();
  }, [match.params.id]);
  console.log("data", data);
  const onSubmit = (formValues, history) => {
    editWorkmen(formValues,
      props.match.params.id,
      history
    );
  };

  // const onSubmit = async (formValues) => {
  //   console.log(JSON.stringify(formValues));
  //   for (var key in formValues) {
  //     if (!formValues[key]) {
  //       delete formValues[key];
  //     }
  //   }
  //   const response = await axios.put(
  //     `/commonMaster/workmen/${props.match.params.id}`,
  //     formValues
  //   );
  //   console.log(response);
  //   history.push("/commonMaster/workmen");
  // };


  useEffect(() => {
  
    async function getVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");

        AllVendor = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let VNList = AllVendor.map((item) => item.VendorName);
        console.log("AllVendor ->", AllVendor);
        setVendorList(VNList);
      } catch (err) {
        alert(err);
      }
    }
    async function getCostCentre() {
      try {
        let { data } = await axios.get("/commonMaster/costCentre");
        console.log(data);

        AllCostCentre = data;
        // const filtered = data.filter((item) => item.Status === "accepted");
        let CCList = AllCostCentre.map((item) => item.CostCenterName);
        console.log("AllCostCentre ->", AllCostCentre);
        setCostCentrelist(CCList);
      } catch (err) {
        alert(err);
      }
    }
    async function getBenefits() {
        try {
          let { data } = await axios.get("/commonMaster/benefits");
          console.log(data);
          
          
          
          let benefitlist = data.map((item) =>item?.benefitCode + " - " + item?.benefitDescription);
          setBenefitlist(benefitlist);
          console.log(benefitlist);
        } catch (err) {
          alert(err);
        }
      }
    getBenefits();
    getCostCentre();
    getVendor();
  
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Workmen Edit"
        linkName1="Workmen Dashboard"
        to1="/commonMaster/Workmen/"
      />
      <h3 style={{ textAlign: "center" }}>Workmen</h3>
      <Workmen
        onSubmit={onSubmit}
        benefitlist={benefitlist}
        VNList={vendorList}
        CCList={costCentre}
        initialValues={pick(
          data,
          "WorkmenName",
          "WorkmenCode",
          "WorkmenReligion",
          "WorkmenFather",
          "WorkmenGender",
          "WorkmenMotherTongue",
          "WorkmenPresentAddress",
          "WorkmenPermanentAddress",
          "WorkmenContact",
          "WorkmenPhone",
          "WorkmenMarital",
          "WorkmenChild",
          "WorkmenIdentification",
          "WorkmenHeight",
          "WorkmenWeight",
          "WorkmenVision",
          "WorkmenVisionLeft",
          "WorkmenDesignation",
          "WorkmenVisionRight",
          "WorkmenEducation",
          "WorkmenExpInYears",
          "WorkmenExpInMonths",
          "WorkmenSkill",
          "WorkmenPF",
          "WorkmenUniAccNo",
          "WorkmenESIC",
          "WorkmenBankAccount",
          "WorkmenBankIFSC",
          "WorkmenBankName",
          "WorkmenAadhar",
          "WorkmenPAN",
          "WorkmenDOB",
          "WorkmenDOJ",
          "WorkmenDOE",
          "WorkmenWages",
          "WorkmenWageType",
          "WorkmenOT",
          "WorkmenBenefits",
          "WorkmenType"
        )} 
      
        edit={true}
      />
    </div>
  );
};
