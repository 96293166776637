import React, { useEffect, useState } from "react";
// import Table from "../../../components/FormDashBoard/FormDashboardUpdate";
import Table from "../../../components/FormDashBoard/FormDashboardPagination";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useHistory, useLocation } from "react-router-dom";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { readAllFromQueryParams } from "../../../utils/queryParams";
import moment from "moment";

export const SubContractorBill = (props) => {
  const [lists, setLists] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const button =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;

  useEffect(() => {
    async function fetch() {
      try {
        const { data: response } = await axios.get(
          "/workforceManagement/workmenJoining",
          {
            params: {
              page: 1,
              ...readAllFromQueryParams(search),
            },
          }
        );
        let data = response.data;

        data.forEach((element) => {
          element.WorkmenJoiningNo = ` ${element.WorkmenJoiningNo}`;
          element.ProjectCode = `${element.ProjectCode} `;

          element.created_at = new Date(element.created_at).toLocaleString();
          element.approved_at =
            element.approved_at &&
            moment(element.approved_at).format("DD/MM/YYYY, h:mm a");
        });

        setLists(data);
        setPaginatedResults({ ...response, data: data });
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [dispatch, history, search]);

  return (
    <>
      <BreadCrumbs name="Sub-Contractor Bill Dashboard" />

      <Table
        paginateResults={paginatedResults}
        DeleteLink="/workforceManagement/subContractor-Bill"
        lists={lists}
        tablecol1="ProjectCode"
        heading="Sub-Contractor Bill"
        label1="WorkmenJoiningNo"
        link="/workforceManagement/subContractor-Bill/create"
        editLink="/workforceManagement/subContractor-Bill/edit"
        viewLink="/workforceManagement/subContractor-Bill/view"
        button={button}
        visibleIcon={visibleIcon}
      />
    </>
  );
};
