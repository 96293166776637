import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import InvoiceType from "../../../forms/CommonMaster/InvoiceType";
import { invoiceType } from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const InvoiceTypeCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [account, setAccount] = useState([]);
  const onSubmit = (formValues) => {
    dispatch(invoiceType(formValues, history));
  };
  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/commonMaster/account");
        let company = data.map((item) => `${item.AccountCode}-${item.AccountDes}`);
        setAccount(company);
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Invoice Type Create"
        linkName1="Invoice Type Dashboard"
        to1="/commonMaster/invoiceType"
      />
      <FormHeading label="Invoice Type" />
      <InvoiceType onSubmit={onSubmit} account={account} />
    </div>
  );
};
