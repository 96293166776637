import { makeStyles } from "@material-ui/core";

const useBasicDialogStyles = makeStyles(() => ({
  dialogHeader: {
    "& h2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  dialog: {
    minWidth: 830,
  },
}));

export default useBasicDialogStyles;
