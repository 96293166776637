import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/actions/userAction";
import axios from "../../../components/apis/axios";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import useStyle from "./UseStyle";
import ProjectCircularReportTable from "../../../components/SalesAccountingDisplay/ProjectCircularReportTable";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { readAllFromQueryParams } from "../../../utils/queryParams";

export const ProjectCircularReport = () => {
  const [lists, setLists] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState({
    data: [],
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const { search } = useLocation();

  const store = useSelector((store) => store);

  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.ADMIN.code ? true : false;

  useEffect(() => {
    async function fetch() {
      try {
        const { data: res } = await axios.get(
          "/salesAccounting/projectCircular",
          {
            params: {
              page: 1,
              ...readAllFromQueryParams(search),
            },
          }
        );
        setLists(res.data);
        setPaginatedResults({ ...res, data: res.data });
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          dispatch(userLogout(history));
        }
      }
    }
    fetch();
  }, [history, dispatch, search]);

  return (
    <>
      <BreadCrumbs
        name="Project Circular Reports"
        linkName1="All Sales Accounting Reports"
        to1="/salesAccounting/reports"
      />

      <Paper className={classes.Container}>
        <h2 className="text-center">Project Circular Reports</h2>
        <ProjectCircularReportTable
          paginatedResults={paginatedResults}
          lists={lists}
          tablecol1="ProjectCode"
          tablecol2="ProjectShortDescription"
          tablecol3="CustomerName"
          label1="Project Code"
          label2="Project Name"
          label3="Customer Name"
          viewLink="/salesAccounting/projectCircular/view"
          visibleIcon={visibleIcon}
        />
      </Paper>
    </>
  );
};
