import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineClass from "../../../forms/CommonMaster/MachineClass";
import { useHistory } from "react-router-dom";
import {
  editMachineClass,
  searchMachineClassById,
} from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MachineClassEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMachineClassById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachineClass) {
      setData(store.SingleMachineClass);
    }
  }, [store.SingleMachineClass]);
  const onSubmit = (formValues, history) => {
    dispatch(editMachineClass(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="Machine Class Edit"
        linkName1="Machine Class Dashboard"
        to1="/commonMaster/MachineClass"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine class Edit
      </h3>
      <div className="line"></div>
      <MachineClass
        initialValues={pick(
          data,
          "MachineClassCode",
          "MaterialClassDes",
          "Tags"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
