import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

const BankTableView = ({ BankDetails }) => {
  return (
    <>
      {BankDetails ? (
        <div>
          <h4 className="table-header">Bank Details</h4>
          <div className="tableContainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">Sl. No</th>
                  <th className="head-cell">BankName</th>
                  <th className="head-cell">Balance</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {BankDetails.map((row, index) => (
                  <tr key={index}>
                    <td className="table-data1">{index + 1}</td>
                    <td className="table-data3">{row.BankName}</td>
                    <td className="table-data4">{row.Balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default BankTableView;
