import { IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { useState } from "react";
import axios from "../../components/apis/axios";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useEffect } from "react";
import AccessControlTabLayout from "../../components/formcomponents/AccessControlTabLayout";

const ProjectTableRow = ({
  Company,
  RoleList,
  inputField,
  inputFields,
  index,
  tableData,
  setInputFields,
  onChange,
}) => {
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!inputField) return;
    console.log({ inputField });
    getBusinessUnits(inputField.Company);
    getProjects(inputField.Company);
    getBusinessUnits(inputField.Company);
  }, [inputField]);

  const handleRemoveFields = async (e, index, id) => {
    e.preventDefault();
    await axios.delete(`users/workingOn/${id}`);
    const values = [...inputFields];
    console.log(values, "before");
    values.splice(index, 1);
    console.log(values, "after");
    onChange(values);
    setInputFields(values);
  };

  const getBusinessUnits = async (value) => {
    const { data } = await axios.get("/commonMaster/businessUnit", {
      params: {
        query: value,
      },
    });

    let business = data.map(
      (item) => item.BusinessUnit + "-" + item.BusinessDescription
    );

    setBusinessUnitList(business);
  };

  const getProjects = async (value) => {
    const { data } = await axios.get("/salesAccounting/projectCircular", {
      params: {
        query: value,
      },
    });
    let projects = data.map((item) => item.ProjectShortDescription);
    setProjectList(projects);
  };

  const onRoleSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      row.Role = values?.name || null;
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onUnitSelect = async (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      row.BusinessUnit = value;
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
      getProjects(value);
    } catch (err) {
      console.error(err);
    }
  };

  const onCompanySelect = async (value, index, fieldName) => {
    console.log(value);
    let tdata = inputFields;
    let row = tdata[index];
    try {
      row.Company = value;
      tdata[index] = row;

      getBusinessUnits(value);
      getProjects(value);
      setInputFields(tdata);
      tableData(tdata);
    } catch (err) {
      console.error(err);
    }
  };

  const onProjectSelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      row.ProjectName = value;
      tdata[index] = row;
    } catch (err) {
      console.error(err);
    } finally {
      setInputFields(tdata);
      tableData(tdata);
    }
    console.log(tableData);
  };

  return (
    <>
      <td className="table-data1">{index + 1}</td>
      <td className="table-data2">
        {Company && (
          <AutoCompleteTableInput
            list={Company}
            inputChange={onCompanySelect}
            tableIndex={index}
            fieldName={"Company"}
            value={inputField.Company || null}
          />
        )}
      </td>
      <td className="table-data3">
        {businessUnitList && (
          <AutoCompleteTableInput
            list={businessUnitList}
            inputChange={onUnitSelect}
            tableIndex={index}
            fieldName={"BusinessUnit"}
            value={inputField.BusinessUnit || null}
          />
        )}
      </td>
      <td className="table-data3">
        {RoleList && (
          <Autocomplete
            id="Role"
            options={RoleList}
            getOptionLabel={(item) => item.name}
            onChange={(event, values) => onRoleSelect(event, values, index)}
            renderInput={(params) => <TextField {...params} />}
            value={
              RoleList.find(
                (item) =>
                  item.name.toLowerCase() === inputField.Role.toLowerCase()
              ) || null
            }
          />
        )}
      </td>
      <td className="table-data4">
        {projectList && (
          <AutoCompleteTableInput
            list={projectList}
            inputChange={onProjectSelect}
            tableIndex={index}
            fieldName={"ProjectName"}
            value={inputField.ProjectName || null}
          />
        )}
      </td>

      <div style={{ display: "flex", marginLeft: "2rem" }}>
        <Button
          onClick={handleOpen}
          variant="contained"
          color="primary"
          disabled={!inputField?.Role}
        >
          Manage Permissions
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="middle">
            <AccessControlTabLayout
              labels={[
                { label: "CommonMaster", value: "commonMaster" },
                { label: "SalesAccounting", value: "salesAccounting" },
                { label: "MachineManagement", value: "machineManagement" },
                { label: "MaterialManagement", value: "projectMaterial" },
                {
                  label: "WorkforceManagement",
                  value: "projectWorkforceManagement",
                },
                { label: "AccountingSystem", value: "projectAccounting" },
                { label: "ProjectHrManagement", value: "projectHrManagement" },
              ]}
              index={index}
              inputField={inputField}
              inputFields={inputFields}
              setInputFields={setInputFields}
              tableData={tableData}
            />
          </div>
        </Modal>
      </div>

      {index === 0 ? (
        <IconButton>
          <RemoveCircleIcon className="width5" color="disabled" />
        </IconButton>
      ) : (
        <IconButton
          onClick={(e) => handleRemoveFields(e, index, inputField.id)}
        >
          <RemoveCircleIcon className="width5" color="secondary" />
        </IconButton>
      )}
    </>
  );
};

export default ProjectTableRow;
