// import React, { useEffect, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormHeading from "../../../components/FormHeading/Heading";
import Machine from "../../../forms/CommonMaster/Machine";
import { machine } from "../../../redux/actions/CommonMasterAction";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";

export const MachineCreate = () => {
  const [machineGroup, setMachineGroup] = React.useState([]);
  const [formDetail, setFormDetail] = React.useState({});
  const [AllMachineGroup, setAllMachineGroup] = React.useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (formValues) => {
    dispatch(machine(formValues, history));
  };

  const SelectMachineGroup = async (
    MachineGcode,
    { machineCode, machineDes }
  ) => {
    const item = AllMachineGroup.find(
      (item) => item.MachineGroupCode === MachineGcode?.split(" ")[0]
    );
    if (!item) return;
    setFormDetail({
      ...formDetail,
      MachineCode: machineCode,
      Machinedes: machineDes,
      // MachineGcode: item.MachineGroupCode,
      MachineGdes: item.MachineGroupLongDes,
      MaterialCcode: item.MachineClassCode,
      MaterialCdes: item.MachineClassDes,
    });
  };

  React.useEffect(() => {
    async function fetchMachineGroup() {
      try {
        let { data } = await axios.get("/commonMaster/machineGroup");
        let MGrouplist = data.map(
          (item) => `${item.MachineGroupCode} - ${item.MachineGroupDes}`
        );
        setAllMachineGroup(data);
        setMachineGroup(MGrouplist);
      } catch (error) {
        alert(error);
      }
    }

    fetchMachineGroup();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Machine Create"
        linkName1="Machine Dashboard"
        to1="/commonMaster/machine"
      />
      <FormHeading label="Machine" />
      <Machine
        onSubmit={onSubmit}
        machineGroup={machineGroup}
        SelectMachineGroup={SelectMachineGroup}
        initialValues={pick(
          formDetail,
          "MachineCode",
          "Machinedes",
          "MachineGcode",
          "MachineGdes",
          "MaterialCcode",
          "MaterialCdes"
        )}
      />
    </div>
  );
};
