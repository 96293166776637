import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProjectTableRow from "./ProjectTableRow";

const ProjectAddRole = ({
  tableData,
  RoleList,
  workingEdit,
  Company,
  isEdit,
}) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (RoleList && Company) setLoading(false);
  }, [RoleList, Company]);

  useEffect(() => {
    if (!isEdit) {
      setInputFields([
        {
          SerialNo: 1,
          ProjectName: "",
          Role: "",
          BusinessUnit: "",
          Company: "",
          accessPermissions: null,
        },
      ]);
    } else if (isEdit && workingEdit) {
      setInputFields([...workingEdit]);
      onChange([...workingEdit]);
    }
  }, [workingEdit, isEdit]);

  const onChange = (values) => {
    if (values) {
      tableData(values);
      console.log(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: 0,
        ProjectName: "",
        Role: "",
        BusinessUnit: "",
        Company: "",
        accessPermissions: null,
        id: "new",
      },
    ]);
  };

  return (
    <>
      {!loading && inputFields ? (
        <div
          className="tableContainer"
          style={{ marginLeft: "7rem", width: "110%" }}
        >
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">Sl No.</th>
                <th className="head-cell">Company Name</th>
                <th className="head-cell">Business Unit</th>
                <th className="head-cell">Role</th>
                <th className="head-cell">Project Code Name</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <ProjectTableRow
                    Company={Company}
                    RoleList={RoleList}
                    inputField={inputField}
                    inputFields={inputFields}
                    index={index}
                    tableData={tableData}
                    setInputFields={setInputFields}
                    onChange={onChange}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          <div className="alignAt-right">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon />
              ADD Row
            </Button>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ProjectAddRole;
