import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { editPurchaseRequest } from "../../../redux/actions/materialSystemAction";
import pick from "lodash/pick";
import "../style/materialSystem.css";
import PurchaseRequestForm from "../../../forms/MaterialSystem/PurchaseRequestForm";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { editPurchaseRequest } from "../../../redux/actions/materialSystemAction";
import { useDispatch } from "react-redux";

let PNList = [];
export const PurchaseRequestFormEdit = (props) => {
  const [purchaseRequest, setPurchaseRequest] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [materialList, setMaterialList] = useState(null);
  const [previousFiles, setPreviousFiles] = useState();

  const { match } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        PNList = filtered;
        const mapped = filtered.map((item) => item.ProjectShortDescription);
        setList(mapped);
        const { data: pr } = await axios.get(
          `/materialManagement/purchaseRequest/${match.params.purchaseRequestId}`
        );
        setPurchaseRequest(pr);
        setPreviousFiles([...pr.Files]);
      } catch (err) {
        console.log("Error in purchaseRequest", err);
      }
    }
    fetch();
  }, [match.params.purchaseRequestId]);

  const selectProjectName = async (projectName) => {
    if (projectName) {
      console.log(PNList, projectName);
      let detail = PNList.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail.id}`
        );
        detail = {
          ProjectCodeName: projectName,
          Currency: data.Currency,
          WarehouseName: data.WareHouse,
          WareHouseGst: data.WareHouseGst,
        };
        setPurchaseRequest({ ...purchaseRequest, ...detail });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onSubmit = (formValues, history) => {
    console.log('form values', formValues);
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(
      editPurchaseRequest(
        formValues,
        props.match.params.purchaseRequestId,
        history
      )
    );
  };

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(`/commonMaster/material`);
      setMaterialList(data);
    };
    fetch();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Purchase Request  Edit"
        linkName1=" Purchase Request  Dashboard"
        to1="/materialManagement/purchaseRequest"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Purchase Request Edit
      </h3>
      <div className="line"></div>
      <PurchaseRequestForm
        onSubmit={(formValues) => onSubmit(formValues, history)}
        initialValues={pick(
          purchaseRequest,
          "TotalPRValue",
          "BilltoCustomer",
          "ProjectCodeName",
          "Currency",
          "WarehouseName",
          "PurchaseCategory",
          "DeliveryAt",
          "RequiredOn",
          "PRType",
          "Remarks",
          "UploadFiles",
          "WareHouseGst"
        )}
        list={list}
        selectProjectName={selectProjectName}
        materialsEditData={purchaseRequest.materials}
        materialList={materialList}
        edit={true}
        previousFiles={previousFiles}
      />
    </div>
  );
};
