import axios from "../../components/apis/axios";
import setAuthToken from "../helper/setAuthToken";

export const userLoginHelper = (data) => {
  return {
    type: "SET_USER_AUTH",
    payload: data,
  };
};

export const userSetWorkingOn = (data) => {
  return {
    type: "SET_USER_WORKING_ON",
    payload: data,
  };
};

const userLogoutHelper = (data) => {
  return {
    type: "DELETE_USERS_DATA",
    payload: data,
  };
};

export const userLogin = (loginCredentials, history) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/sessions", loginCredentials);

      console.log("data", data);

      const { token, user } = data;

      localStorage.setItem("userJwtToken", token);

      setAuthToken(token);

      dispatch(userLoginHelper(user));
    } catch (err) {
      console.log(err.response);
      if (err.response) {
        const message = { message: err.response.data.error };
        dispatch({
          type: "SET_LOGIN_ERRORS",
          payload: message,
        });
      } else {
        const message = { message: err };
        console.log(message);
        dispatch({
          type: "SET_LOGIN_ERRORS",
          payload: message,
        });
      }
    }
  };
};

export const userLogout = (history) => {
  return (dispatch) => {
    localStorage.removeItem("userJwtToken");
    setAuthToken(false);
    dispatch(userLogoutHelper({}));
  };
};

// err.response.data.error.email = "ndnodnnlkkdmml"
