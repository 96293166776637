import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allMachinePurchaseOrder } from "../../../redux/actions/MachineManagementaction";
import Table from "../../../components/FormDashBoard/FormDashBoard";
import RoleList from "../../../components/helpers/RoleList";

export const MachinePurchaseOrderDash = (props) => {
  const [lists, setLists] = useState([]);
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const button =
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  const visibleIcon =
    store.userRoot.workingOn.Role === RoleList.BU_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_SUPERVISOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_INCHARGE.code ||
    store.userRoot.workingOn.Role === RoleList.BU_COMMERCIAL.code ||
    store.userRoot.workingOn.Role === RoleList.COMMERCIAL_ASSISTANT.code ||
    store.userRoot.workingOn.Role === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
    store.userRoot.workingOn.Role === RoleList.DIRECTOR.code ||
    store.userRoot.workingOn.Role === RoleList.SITE_STORE_KEEPER.code ||
    store.userRoot.workingOn.Role === RoleList.ADMIN.code
      ? true
      : false;
  useEffect(() => {
    dispatch(allMachinePurchaseOrder());
  }, [dispatch]);
  useEffect(() => {
    if (store.machineMangementSystemRoot.AllMachinePurchaseOrder.length !== 0) {
      setLists(store.machineMangementSystemRoot.AllMachinePurchaseOrder);
    }
  }, [store.machineMangementSystemRoot.AllMachinePurchaseOrder]);
  return (
    <Table
      DeleteLink="/machineManagement/machinePurchaseOrder"
      lists={lists}
      tablecol1="MachinePurchaseOrderNo"
      heading="Machine Purchase Order"
      label1="Machine Purchase No"
      link="/machineManagement/machinePurchaseOrder/create"
      editLink="/machineManagement/machinePurchaseOrder/edit"
      viewLink="/machineManagement/machinePurchaseOrder/view"
      button={button}
      visibleIcon={visibleIcon}
    />
  );
};
