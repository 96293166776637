import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  approvalCommonMaster,
} from "../../../redux/actions/CommonMasterAction";
import useStyle from "../../CommonMaster/View/UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { searchHrDailyAttendanceById } from "../../../redux/actions/HRManagementActions";
import CustomDataGrid from "../../../components/FormDashBoard/CustomDataGrid/CustomDataGrid";

export const HrDailyAttendanceView = (props) => {
  const [data, setData] = useState(null);
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        const data = await searchHrDailyAttendanceById(match.params.id);
        setData(data);
    }
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  const onApprove = async (data) => {
    await dispatch(
      approvalCommonMaster("employee", match.params.id, history, data)
    );
  };

  const acessButton = () => {
    // if (post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code || post === "companyHead")
    return (
      <div className={classes.ButtonGrp}>
        <Button
          variant="contained"
          className={classes.approve}
          onClick={() => history.push(`/project-hr-management/overtime-entry/${match?.params?.id}`)}
        >
          Overtime Entry
        </Button>
        {/* <Button
          variant="contained"
          className={classes.approve}
          onClick={() => onApprove("Approved")}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          className={classes.reject}
          onClick={() => onApprove("Rejected")}
        >
          Reject
        </Button> */}
      </div>
    );
    // return;
  };

  const columns = [
    {
      field: "employee",
      headerName: "Employee",
      flex: 1,
    },
    {
      field: "shiftCode",
      headerName: "Shift Code",
      flex: 1,
    },
    {
      field: "timeIn",
      headerName: "Time In",
      flex: 1,
    },
    {
      field: "timeOut",
      headerName: "TimeOut",
      flex: 1,
    },
    {
      field: "normalHour",
      headerName: "Normal Hours",
      flex: 1,
    },
    {
      field: "totalHour",
      headerName: "Total Hours",
      flex: 1,
    },
    {
      field: "overtimeHour",
      headerName: "Overtime Hours",
      flex: 1,
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 1,
    },
    {
      field: "machine",
      headerName: "Machine",
      flex: 1,
    }
  ];

  if(!data) return null;

  return (
    <>
      <Breadcrumbs
        name="Daily Attendance View"
        linkName1="Daily Attendance Dashboard"
        to1="/project-hr-management/daily-attendance"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Daily Attendance</h3>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
          {data?.dailyAttendanceCode && (
             <Paper className={classes.paper}>
              <h6>Daily Attendance Code:</h6>
              <p>{data?.dailyAttendanceCode}</p>
            </Paper>)}
            {data?.project && (
             <Paper className={classes.paper}>
              <h6>Project Code:</h6>
              <p>{data?.project?.ProjectCode}</p>
            </Paper>)}
          </div>
        </Box>
        {acessButton()} 
        <CustomDataGrid
          columns={columns}
          rows={data.attendanceTable || []}
          hideActions
          showFilter={false}
          hideFooter
        />
      </Paper>
      {/* TODO: Implement approval functionality */}
    </>
  );
};
