import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import Machine from "../../../forms/CommonMaster/Machine";
import { useHistory } from "react-router-dom";
import {
  editMachine,
  searchMachineById,
} from "../../../redux/actions/CommonMasterAction";
import axios from "../../../components/apis/axios";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const MachineEdit = (props) => {
  const [machineGroup, setMachineGroup] = React.useState([]);
  const [data, setData] = useState({});
  const [AllMachineGroup, setAllMachineGroup] = React.useState([]);
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;

  useEffect(() => {
    dispatch(searchMachineById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachine) {
      setData(store.SingleMachine);
    }
  }, [store.SingleMachine]);
  const onSubmit = (formValues, history) => {
    dispatch(
      editMachine(
        { ...formValues, Tags: formValues.Tags ?? "NA" },
        props.match.params.id,
        history
      )
    );
  };

  const SelectMachineGroup = async (MachineGcode) => {
    const item = AllMachineGroup.find(
      (item) => item.MachineGroupCode === MachineGcode?.split(" ")[0]
    );
    if (!item) return;

    setData((prev) => ({
      ...prev,
      MachineGdes: item.MachineGroupLongDes,
      MaterialCcode: item.MachineClassCode,
      MaterialCdes: item.MachineClassDes,
      Tags: item.Tags,
    }));
  };

  React.useEffect(() => {
    async function fetchMachineGroup() {
      try {
        let { data } = await axios.get("/commonMaster/machineGroup");
        let MGrouplist = data.map(
          (item) => `${item.MachineGroupCode} - ${item.MachineGroupDes}`
        );
        setAllMachineGroup(data);
        setMachineGroup(MGrouplist);
      } catch (error) {
        alert(error);
      }
    }

    fetchMachineGroup();
  }, []);
  return (
    <div>
      <Breadcrumbs
        name="Machine Edit"
        linkName1="Machine Dashboard"
        to1="/commonMaster/Machine"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Edit
      </h3>
      <div className="line"></div>
      <Machine
        initialValues={pick(
          data,
          "MachineCode",
          "MachineGcode",
          "MaterialCdes",
          "MaterialCcode",
          "MachineGdes",
          "Machinedes",
          "MachineOwnership",
          "Tags"
        )}
        machineGroup={machineGroup}
        SelectMachineGroup={SelectMachineGroup}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
