import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format } from "date-fns";

const DisbursementSubSupplierTable = ({
  populatedData,
  tableData,
  DisbursementSubSupplierEdit,
}) => {
  const [inputFields, setInputFields] = useState();
  const [loading, setLoading] = useState(false);

  //  console.log("props" , props.BList)
  useEffect(() => {
    if (DisbursementSubSupplierEdit) {
      setInputFields([...DisbursementSubSupplierEdit]);
      if (tableData) tableData(DisbursementSubSupplierEdit);
    }
    // eslint-disable-next-line
  }, [DisbursementSubSupplierEdit]);

  useEffect(() => {
    let values = [];
    if (populatedData) {
      setLoading(true);
      for (let i = 0; i < populatedData.length; i++) {
        values = [
          ...values,
          {
            PurchaseOrderNo: populatedData[i].PurchaseOrderNo,
            PurchaseOrderDate: populatedData[i].PurchaseOrderDate,
            WarehouseReceiptNo: populatedData[i].WarehouseReceiptVoucherNo,
            WarehouseReceiptDate: populatedData[i].WarehouseReceiptDate,
            SupplierInvoiceNo: populatedData[i].SupplierInvoiceNo,
            SupplierInvoiceDate: populatedData[i].SupplierInvoiceDate,
            PurchaseJournalVoucherNo: populatedData[i].JournalNo,
            PurchaseJournalVoucherDate: populatedData[i].JournalDate,
            NetDue: populatedData[i].WarehouseReceiptAmount,
            DueDate: populatedData[i].WarehouseReceiptDate,
            PaidSoFar: populatedData[i].PaidSoFar,
            PayNow: populatedData[i].PayNow,
          },
        ];
      }
      onChange(values);
      setInputFields(values);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [populatedData]);

  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  return (
    <>
      {!loading && inputFields ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">SlNo</th>
                <th className="head-cell">Purchase Order No</th>
                <th className="head-cell">Purchase Order Date</th>
                <th className="head-cell">Warehouse Receipt No</th>
                <th className="head-cell">Warehouse Receipt Date</th>
                <th className="head-cell">Supplier Invoice No</th>
                <th className="head-cell">Supplier Invoice Date</th>
                <th className="head-cell">Purchase Journal No</th>
                <th className="head-cell">Purchase Journal Date</th>
                <th className="head-cell">Net Due</th>
                <th className="head-cell">Due Date</th>
                <th className="head-cell">Paid So Far</th>
                <th className="head-cell">Pay Now</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">{index + 1}</td>
                  <td className="table-data2">{inputField.PurchaseOrderNo}</td>
                  <td className="table-data2">
                    {format(
                      new Date(inputField.PurchaseOrderDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">
                    {inputField.WarehouseReceiptNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(inputField.WarehouseReceiptDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">
                    {inputField.SupplierInvoiceNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(inputField.SupplierInvoiceDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">
                    {inputField.PurchaseJournalVoucherNo}
                  </td>
                  <td className="table-data2">
                    {format(
                      new Date(inputField.PurchaseJournalVoucherDate),
                      "d MMM yyyy"
                    )}
                  </td>
                  <td className="table-data2">{inputField.NetDue}</td>
                  <td className="table-data2">
                    {format(new Date(inputField.DueDate), "d MMM yyyy")}
                  </td>
                  <td className="table-data2">{inputField.PaidSoFar}</td>
                  <td className="table-data4">
                    <input
                      type="number"
                      name="PayNow"
                      value={inputField.PayNow}
                      onChange={(event) => handleChangeInput(index, event)}
                      style={{ width: "6rem" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default DisbursementSubSupplierTable;
