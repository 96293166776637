import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
  searchDeliveryChallanById,
  approvalMaterialManagement,
} from "../../../redux/actions/materialSystemAction";
import { DeliveryChallanTable } from "../../../Tables/MaterialSystem/View/DeliveryChallanTableView";
import useStyle from "./UseStyle";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { FileListArea } from "../../../components/formcomponents/control";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const DeliveryChallanView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleModalAction = (data) => {
    dispatch(
      approvalMaterialManagement(
        "deliveryChallan",
        match.params.id,
        history,
        data
      )
    );
  };

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);
  useEffect(() => {
    dispatch(searchDeliveryChallanById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.materialSystemRoot.SingleDeliveryChallan) {
      setData(store.materialSystemRoot.SingleDeliveryChallan);
    }
  }, [store.materialSystemRoot.SingleDeliveryChallan]);

  const classes = useStyle();

  const column = {
    col1: "MaterialCode",
    col2: "MaterialDescription",
    col5: "DcQuantity",
    col6: "DcRate",
    col7: "ItemAmount",
  };
  const columnHeading = {
    col1: "Material Code",
    col2: "Material Description",
    col5: "DcQuantity",
    col6: "DC Rate",
    col7: "Item Total",
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name="Delivery Challan View"
        linkName1="Delivery Challan Dashboard"
        to1="/materialManagement/deliveryChallan"
      />
      <Paper className={classes.Container}>
        <div className="">
          <ComapnyHeader />
          <h3 className={classes.Heading}>Delivery Challan</h3>

          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>Purchase Request No​:</h6>
              <p>{data.PurchaseRequestNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Date:</h6>
              <p>{data.Date}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse Issue Vou No:</h6>
              <p>{data.WarehouseIssueVoucherNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse Issue Date:</h6>
              <p>{data.WarehouseIssueDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Issue Type:</h6>
              <p>{data.GoodsIssueType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Stock Type:</h6>
              <p>{data.GoodsStockType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Source Project:</h6>
              <p>{data.SourceProject}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Destination Project:</h6>
              <p>{data.DestinationProject}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>From Warehouse​:</h6>
              <p>{data.FromWarehouse}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>To Warehouse:</h6>
              <p>{data.ToWarehouse}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase / Sales Order No:</h6>
              <p>{data.PurchaseSalesOrderNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Purchase Date:</h6>
              <p>{data.PurchaseDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Transport WO Required​:</h6>
              <p>{data.TransportWORequired}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor Code / Name​:</h6>
              <p>{data.VendorCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>CustomerCode/Name:</h6>
              <p>{data.CustomerCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Transport Type​:</h6>
              <p>{data.TransportType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>LR / RR / Consignment No:</h6>
              <p>{data.LRRRConsignment}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>LR Date:</h6>
              <p>{data.LRDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Source GST No​​:</h6>
              <p>{data.SourceGSTNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Destination GST No​​:</h6>
              <p>{data.DestinationGSTNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Delivery Challan Value​​:</h6>
              <p>{data.DeliveryChallanValue}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Remarks​​:</h6>
              <p>{data.Remarks}</p>
            </Paper>
          </div>

          <h5 className={classes.Heading}>Material Detail</h5>
          <DeliveryChallanTable
            materials={data.materials}
            column={column}
            columnHeading={columnHeading}
          />
        </Box>

        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Delivery Challan"}
      />
    </div>
  );
};
