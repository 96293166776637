import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import {
  searchWarehouseIndentVoucherById,
  approvalMaterialManagement,
} from "../../../redux/actions/materialSystemAction";
import { WareHouseIndentTableView } from "../../../Tables/MaterialSystem/View/WareHouseIndentTableView";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import RoleList from "../../../components/helpers/RoleList";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const WarehouseIndentVoucherView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(searchWarehouseIndentVoucherById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.materialSystemRoot.SingleWarehouseIndentVoucher) {
      setData(store.materialSystemRoot.SingleWarehouseIndentVoucher);
      console.log(store.materialSystemRoot.SingleWarehouseIndentVoucher);
    }
    console.log(
      "SingleWarehouseIndentVoucher->",
      store.materialSystemRoot.SingleWarehouseIndentVoucher
    );
  }, [store.materialSystemRoot.SingleWarehouseIndentVoucher]);
  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalMaterialManagement(
        "warehouseIntent",
        match.params.id,
        history,
        data
      )
    );
  };

  const classes = useStyle();
  const column = {
    col5: "IssueRequestQty",
    col6: "ActualIssueQty",
  };
  const columnHeading = {
    col5: "Issue Request Qty",
    col6: "Actual Issue Qty",
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div ref={componentRef}>
      <Breadcrumbs
        name=" Warehouse Indent Voucher  View"
        linkName1=" Warehouse Indent Voucher  Dashboard"
        to1="/materialManagement/warehouseIntent"
      />
      <Paper className={classes.Container}>
        <div className="">
          <ComapnyHeader />
          <h3 className={classes.Heading}>Warehouse Indent Voucher </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by
              ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
              : ""
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>ProjectCode / Name:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Customer Name:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Warehouse:</h6>
              <p>{data.Warehouse}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Goods Issue Type:</h6>
              <p>{data.GoodsIssueType}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Work Order No​:</h6>
              <p>{data.WorkOrderNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6> Sales Order No​:</h6>
              <p>{data.SalesOrderNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Work / Sales Date:</h6>
              <p>{data.WorkSalesDate}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Vendor Code / Name:</h6>
              <p>{data.VendorCodeName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>Remarks:</h6>
              <p>{data.Remarks}</p>
            </Paper>
          </div>
          <h5 className={classes.Heading}>Material Detail</h5>
          <WareHouseIndentTableView
            materials={data.materials}
            column={column}
            columnHeading={columnHeading}
          />
        </Box>

        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>
      {(post === RoleList.ADMIN.code ||
        post === RoleList.SITE_INCHARGE.code ||
        post === RoleList.ACCOUNT_AND_ADMIN_HEAD.code ||
        post === RoleList.BU_HEAD.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Warehouse Indent"}
      />
    </div>
  );
};
