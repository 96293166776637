import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MachineTable from "./DailyProgressMachineTable";
import MaterialTable from "./DailyProgressMaterialTable";
import SCItemTable from "./DailyProgressSCItemTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import LinearProgress from "@material-ui/core/LinearProgress";
import { RenderTableDateField } from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  input: {
    width: "30%",
    margin: "1rem 1rem",
  },
  buttonContainer: {
    width: "30%",

    margin: "1rem",
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    margin: "1rem 1rem 0 1rem",
    fontSize: "2rem",
  },
  hr: {
    width: "50%",
    border: "1px dashed red",
  },
  formControl: {
    margin: "1rem",
    minWidth: 150,
  },
  removeDisabled: {
    border: "1px solid gray",
  },
  remove: {
    border: "1px solid red",
  },
});

const ActivityTable = ({
  storeDailyProgressReportSub,
  dailyProgressReportSubEditDetails,
  activityList,
  uom,
  vendorList,
  scItemList,
  machineList,
  materialList,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      ActivityCodeName: "",
      UOM: "",
      WorkDoneQuantity: "",
      DoneBy: "Direct",
      Involving: "Material",
      VendorCodeName: "",
      WorkOrderNo: "",
      WorkOrderDate: null,
      MachineDetails: [],
      MaterialDetails: [],
      ScItemDetails: [],
      id: "",
    },
  ]);
  const [listofActivity, setListofActivity] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  // console.log("listofActivity", listofActivity);
  useEffect(() => {
    if (activityList) {
      setListofActivity([...activityList]);
    }
    //eslint-disable-next-line
  }, [activityList]);

  useEffect(() => {
    setLoading(true);
    if (dailyProgressReportSubEditDetails) {
      setInputFields([...dailyProgressReportSubEditDetails]);
    }
    setLoading(false);
  }, [dailyProgressReportSubEditDetails]);

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        ActivityCodeName: "",
        UOM: "",
        WorkDoneQuantity: "",
        DoneBy: "Direct",
        Involving: "Material",
        VendorCodeName: "",
        WorkOrderNo: "",
        WorkOrderDate: null,
        MachineDetails: [],
        MaterialDetails: [],
        ScItemDetails: [],
        id: "",
      },
    ]);
  };

  const onItemSelect = (event, values, index, field, findHere) => {
    let tdata = inputFields;
    try {
      if (tdata[index]) {
        tdata[index][field] = values[findHere];
      }
      setInputFields(tdata);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    console.log(values);
    setInputFields(values);
    storeDailyProgressReportSub(values);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    storeDailyProgressReportSub(values);
    setInputFields(values);
  };

  const handleSCItemChange = (data, index) => {
    const values = [...inputFields];
    const row = values[index];
    row.ScItemDetails = data;
  };

  const handleMaterialChange = (data, index) => {
    const values = [...inputFields];
    const row = values[index];
    row.MaterialDetails = data;
  };

  const handleMachineChange = (data, index) => {
    const values = [...inputFields];
    const row = values[index];
    row.MachineDetails = data;
  };
  // console.log("machineList", machineList);

  const onDateChange = (index, value) => {
    const values = [...inputFields];
    values[index].WorkOrderDate = value;
    setInputFields(values);
  };
  const handleCheckNegativeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    for (let i = 0; i < values.length; i++) {
      if (+values[i].WorkDoneQuantity < 0) {
        values[i].WorkDoneQuantity = 0;
        enqueueSnackbar(`You can't enter Negative Number`, {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    // onChange(values);
  };
  const onActivityItemSelect = (event, values, index) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (values !== null) {
        row.ActivityCodeName =
          values.ActivityCode + "-" + values.ActivityDescription;
        row.UOM = values.UOM;

        tdata[index] = row;
        setInputFields(tdata);

        const list = listofActivity;
        const indexOfValues = list.indexOf(values);
        list.splice(indexOfValues, 1);
        setListofActivity([...list]);
      } else {
        const item = listofActivity.find(
          (item) => item.ActivityCode === row.ActivityCodeName
        );
        setListofActivity([...listofActivity, item]);
        row.ActivityCodeName = "";
        row.UOM = "";
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div>
      <>
        {!loading ? (
          <>
            <div>
              {inputFields.map((inputField, index) => (
                <div key={index}>
                  <br />
                  <div className="middle">
                    <hr className={classes.hr} align="center" />
                  </div>
                  <h4
                    style={{ display: "inline-block", marginLeft: "5rem" }}
                    className={classes.text}
                  >
                    Activity
                  </h4>
                  <div className="field-container">
                    {listofActivity && (
                      <Autocomplete
                        id="ActivityCodeName"
                        options={listofActivity}
                        getOptionLabel={(option) =>
                          option.ActivityCode + "-" + option.ActivityDescription
                        }
                        className={classes.input}
                        onChange={(event, values) =>
                          onActivityItemSelect(
                            event,
                            values,
                            index,
                            "ActivityCodeName",
                            "ActivityDescription"
                          )
                        }
                        renderInput={(params) => (
                          <TextField label="Activity Code Name" {...params} />
                        )}
                        defaultValue={listofActivity.find(
                          (x) =>
                            x.ActivityCode + "-" + x.ActivityDescription ===
                            inputField.ActivityCodeName
                        )}
                      />
                    )}
                    <TextField
                      label="UOM"
                      type="text"
                      className={classes.input}
                      name="UOM"
                      value={inputField.UOM}
                      disabled
                    />
                    <TextField
                      label="Work Done Quantity"
                      type="number"
                      className={classes.input}
                      name="WorkDoneQuantity"
                      value={inputField.WorkDoneQuantity}
                      onChange={(event) => {
                        handleChangeInput(index, event);
                        handleCheckNegativeInput(index, event);
                      }}
                    />
                    <FormControl className={classes.formControl}>
                      <InputLabel id="DoneBy">Done By</InputLabel>
                      <Select
                        name="DoneBy"
                        value={inputField.DoneBy}
                        onChange={(event) => {
                          handleChangeInput(index, event);
                        }}
                      >
                        <MenuItem value={"Direct"}>Direct</MenuItem>
                        <MenuItem value={"Sub-Contractor"}>
                          Sub-Contractor
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <h4
                      style={{ display: "inline-block", marginLeft: "5rem" }}
                      className={classes.text}
                    >
                      SC Item Details
                    </h4>
                    <SCItemTable
                      scItemList={scItemList}
                      uom={uom}
                      activityIndex={index}
                      WorkDoneQuantity={inputField.WorkDoneQuantity}
                      handleSCItemChange={handleSCItemChange}
                      scItemEditDetails={inputField.ScItemDetails}
                    />
                  </div>
                  {inputField.DoneBy === "Sub-Contractor" && (
                    <div>
                      <p className={classes.text}>Vendor Code</p>
                      {vendorList && (
                        <Autocomplete
                          id="VendorCodeName"
                          options={vendorList}
                          getOptionLabel={(option) => option.VendorName}
                          className={classes.input}
                          onChange={(event, values) =>
                            onItemSelect(
                              event,
                              values,
                              index,
                              "VendorCodeName",
                              "VendorName"
                            )
                          }
                          renderInput={(params) => (
                            <TextField label="Vendor Name" {...params} />
                          )}
                          defaultValue={vendorList.find(
                            (x) => x.VendorName === inputField.VendorCodeName
                          )}
                        />
                      )}
                      <TextField
                        label="Work Order No"
                        className={classes.input}
                        name="WorkOrderNo"
                        value={inputField.WorkOrderNo}
                        onChange={(event) => handleChangeInput(index, event)}
                      />
                      <RenderTableDateField
                        name="WorkOrderDate"
                        label="Work Order Date"
                        initialValue={inputField.WorkOrderDate}
                        onChange={(value) => onDateChange(index, value)}
                        style={{ width: "10rem" }}
                      />
                    </div>
                  )}
                  <FormControl className={classes.formControl}>
                    <InputLabel id="Involving">Involving</InputLabel>
                    <Select
                      labelId="Involving"
                      id="Involving"
                      name="Involving"
                      value={inputField.Involving}
                      onChange={(event) => {
                        handleChangeInput(index, event);
                      }}
                    >
                      <MenuItem value={"Material"}>Material</MenuItem>
                      <MenuItem value={"Machine"}>Machine</MenuItem>
                    </Select>
                  </FormControl>
                  {inputField.Involving === "Material" ? (
                    <div>
                      <p className={classes.text}>Material Details</p>
                      <MaterialTable
                        handleMaterialChange={handleMaterialChange}
                        activityIndex={index}
                        materialList={materialList}
                        materialDetailsEdit={inputField.MaterialDetails}
                      />
                    </div>
                  ) : (
                    <div>
                      <h4
                        style={{ display: "inline-block", marginLeft: "5rem" }}
                        className={classes.text}
                      >
                        Machine Details
                      </h4>
                      <MachineTable
                        machineList={machineList}
                        handleMachineChange={handleMachineChange}
                        activityIndex={index}
                        machineDetailsEdit={inputField.MachineDetails}
                      />
                      <br />
                      <br />
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                  <div className="middle">
                    <hr className={classes.hr} align="center" />
                  </div>
                  <div className="middle">
                    {index === 0 ? (
                      <Button
                        className={classes.removeDisabled}
                        color="disabled"
                      >
                        <DeleteSweepIcon /> &nbsp; Remove
                      </Button>
                    ) : (
                      <Button
                        className={classes.remove}
                        color="secondary"
                        onClick={(e) => handleRemoveFields(e, index)}
                      >
                        <DeleteSweepIcon /> &nbsp; Remove
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="middle">
              <Button
                color="primary"
                variant="contained"
                size="large"
                startIcon={<AddCircleIcon />}
                onClick={handleAddFields}
              >
                ADD
              </Button>
            </div>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    </div>
  );
};

export default ActivityTable;

