import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../apis/axios";

const SearchOptionCostCentre = ({ handleCostCentreChange }) => {
  const [costCentre, setcostCentre] = useState([]);

  useEffect(() => {
    const fetchCutomer = async () => {
      const { data } = await axios.get("/commonMaster/costCentre");
      setcostCentre(data);
    };
    fetchCutomer();
  }, []);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        disableClearable
        options={costCentre}
        getOptionLabel={(item) => item.CostCenterName}
        onChange={(event, values) =>
          handleCostCentreChange(values.CostCenterName)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter Cost Centre"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchOptionCostCentre;
