import React, { useState, useEffect } from "react";


const SalesOrderSummaryTable = ({
  invoiceSavedData,
  recoverySavedData,
  LastPaymentUptoLastBill,
  invoiceSummary,
  summaryTableEdit,
  gstPercentage,
}) => {
  const [invoiceSummaryFields, setInvoiceSummaryFields] = useState({
    ClaimAmtUptoLastBill: 0,
    ClaimAmtThisBill: 0,
    ClaimAmtCummBill: 0,
    CGSTPercentage: "",
    SGSTPercentage: "",
    IGSTPercentage: "",
    NetClaimAmtUptoLastBill: 0,
    NetClaimAmtThisBill: 0,
    NetClaimAmtCummBill: 0,
    TotalRecoveryAmtUptoLastBill: 0,
    TotalRecoveryAmtThisBill: 0,
    TotalRecoveryAmtCummBill: 0,
    TotalGrossAmtUptoLastBill: 0,
    TotalGrossAmtThisBill: 0,
    TotalGrossAmtCummBill: 0,
    NetPayableAmt: 0,
    LastPayment: 0,
    id: "",
  });

  // edit
  useEffect(() => {
    if (summaryTableEdit) {
      setInvoiceSummaryFields({
        ClaimAmtUptoLastBill: summaryTableEdit.ClaimAmtUptoLastBill,
        ClaimAmtThisBill: summaryTableEdit.ClaimAmtThisBill,
        ClaimAmtCummBill: summaryTableEdit.ClaimAmtCummBill,
        CGSTPercentage: summaryTableEdit.CGSTPercentage,
        SGSTPercentage: summaryTableEdit.SGSTPercentage,
        IGSTPercentage: summaryTableEdit.IGSTPercentage,
        NetClaimAmtUptoLastBill: summaryTableEdit.NetClaimAmtUptoLastBill,
        NetClaimAmtThisBill: summaryTableEdit.NetClaimAmtThisBill,
        NetClaimAmtCummBill: summaryTableEdit.NetClaimAmtCummBill,
        TotalRecoveryAmtUptoLastBill:
          summaryTableEdit.TotalRecoveryAmtUptoLastBill,
        TotalRecoveryAmtThisBill: summaryTableEdit.TotalRecoveryAmtThisBill,
        TotalRecoveryAmtCummBill: summaryTableEdit.TotalRecoveryAmtCummBill,
        TotalGrossAmtUptoLastBill: summaryTableEdit.TotalGrossAmtUptoLastBill,
        TotalGrossAmtThisBill: summaryTableEdit.TotalGrossAmtThisBill,
        TotalGrossAmtCummBill: summaryTableEdit.TotalGrossAmtCummBill,
        NetPayableAmt: summaryTableEdit.NetPayableAmt,
        LastPayment: summaryTableEdit.LastPayment,
        id: summaryTableEdit.id,
      });
    }
  }, [summaryTableEdit]);

  //create
  useEffect(() => {
    let fields;
    console.log("gstPercentage", gstPercentage);
    if (invoiceSavedData && recoverySavedData && gstPercentage) {
      console.log(invoiceSavedData, recoverySavedData);
      let lastBill = 0,
        thisBill = 0,
        cummBill = 0;
      let data = invoiceSavedData.fields;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        lastBill += +data[i].UptoLastBillQty * +data[i].Rate;
        thisBill += +data[i].ThisBillQty * +data[i].Rate;
      }
      cummBill = +invoiceSavedData.amt;
      console.log(lastBill, thisBill, cummBill);
      let recoveryUptoLastBill = 0,
        recoveryThisBill = 0,
        recoveryCummBill = 0;
      data = recoverySavedData.fields;
      for (let i = 0; i < data.length; i++) {
        recoveryUptoLastBill += +data[i].UptoLastBillAmt;
        recoveryThisBill += +data[i].ThisBillAmt;
      }
      recoveryCummBill = +recoverySavedData.amt;
      let last = 0;
      if (LastPaymentUptoLastBill) {
        last = +LastPaymentUptoLastBill;
      }
      fields = {
        ClaimAmtUptoLastBill: lastBill,
        ClaimAmtThisBill: thisBill,
        ClaimAmtCummBill: cummBill,
        CGSTPercentage: +gstPercentage.CGST,
        SGSTPercentage: +gstPercentage.SGST,
        IGSTPercentage: +gstPercentage.IGST,
        NetClaimAmtUptoLastBill: lastBill,
        NetClaimAmtThisBill: thisBill,
        NetClaimAmtCummBill: cummBill,
        TotalRecoveryAmtUptoLastBill: recoveryUptoLastBill,
        TotalRecoveryAmtThisBill: recoveryThisBill,
        TotalRecoveryAmtCummBill: recoveryCummBill,
        TotalGrossAmtUptoLastBill: +lastBill - +recoveryUptoLastBill,
        TotalGrossAmtThisBill: +thisBill - +recoveryThisBill,
        TotalGrossAmtCummBill: +cummBill - +recoveryCummBill,
        NetPayableAmt: "",
        LastPayment: last,
      };
      setInvoiceSummaryFields(fields);
    }
    if (invoiceSummary) {
      invoiceSummary(fields);
    }
    // eslint-disable-next-line
  }, [
    invoiceSavedData,
    recoverySavedData,
    LastPaymentUptoLastBill,
    gstPercentage,
  ]);

  const handleGstChange = (event) => {
    let temp = invoiceSummaryFields;
    let gst = 0;
    const value = event.target.value;
    temp[event.target.name] = +value;
    if (+temp.CGSTPercentage > 0) {
      temp.SGSTPercentage = +temp.CGSTPercentage;
      temp.IGSTPercentage = 0;
      gst = +temp.CGSTPercentage * 2;
    } else if (+temp.IGSTPercentage > 0) {
      temp.SGSTPercentage = 0;
      temp.CGSTPercentage = 0;
      gst = +temp.IGSTPercentage;
    }
    setInvoiceSummaryFields({ ...temp });

    // setting net claim amt
    temp.NetClaimAmtUptoLastBill =
      +(+temp.ClaimAmtUptoLastBill * +gst * 0.01).toFixed(2) +
      +temp.ClaimAmtUptoLastBill;

    temp.NetClaimAmtThisBill =
      +(+temp.ClaimAmtThisBill * +gst * 0.01).toFixed(2) +
      +temp.ClaimAmtThisBill;

    temp.NetClaimAmtCummBill =
      +(+temp.ClaimAmtCummBill * +gst * 0.01).toFixed(2) +
      +temp.ClaimAmtCummBill;

    // setting gross payable amt
    temp.TotalGrossAmtUptoLastBill = (
      +temp.NetClaimAmtUptoLastBill - +temp.TotalRecoveryAmtUptoLastBill
    ).toFixed(2);
    temp.TotalGrossAmtThisBill = (
      +temp.NetClaimAmtThisBill - +temp.TotalRecoveryAmtThisBill
    ).toFixed(2);
    temp.TotalGrossAmtCummBill = (
      +temp.NetClaimAmtCummBill - +temp.TotalRecoveryAmtCummBill
    ).toFixed(2);

    temp.NetPayableAmt = +temp.TotalGrossAmtCummBill - temp.LastPayment;

    setInvoiceSummaryFields({
      ...temp,
    });
    if (invoiceSummary) {
      invoiceSummary(temp);
    }
  };

  const handleChange = (e) => {
    let fields = invoiceSummaryFields;
    fields[e.target.name] = e.target.value;
    fields.NetPayableAmt = fields.TotalGrossAmtCummBill - fields.LastPayment;
    setInvoiceSummaryFields({ ...fields });
    if (invoiceSummary) {
      invoiceSummary(fields);
    }
  };

  return (
    <>
      <div className="tableContainer">
        <table>
          <thead className="table-head">
            <tr>
              <th rowSpan="2" className="head-cell"></th>
              <th rowSpan="2" className="head-cell">
                Upto Last Bill
              </th>
              <th rowSpan="2" className="head-cell">
                This Bill
              </th>
              <th rowSpan="2" className="head-cell">
                Cumm upto this Bill
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Total Claim Amount</th>
              <td className="table-data1">
                {invoiceSummaryFields.ClaimAmtUptoLastBill}
              </td>
              <td className="table-data2">
                {invoiceSummaryFields.ClaimAmtThisBill}
              </td>
              <td className="table-data4">
                {invoiceSummaryFields.ClaimAmtCummBill}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          margin: "1rem 0 1rem",
        }}
      >
        <label for="CGSTPercentage" className="table-data1">
          CGST %{" "}
        </label>
        <input
          className="table-data4"
          type="number"
          name="CGSTPercentage"
          value={invoiceSummaryFields.CGSTPercentage}
          onChange={handleGstChange}
          style={{ height: "1.4rem" }}
          required
        />
        <label for="SGSTPercentage" className="table-data1">
          SGST %{" "}
        </label>
        <input
          className="table-data4"
          type="number"
          name="SGSTPercentage"
          value={invoiceSummaryFields.SGSTPercentage}
          onChange={handleGstChange}
          disabled
          style={{ height: "1.4rem" }}
          required
        />
        <label for="IGSTPercentage" className="table-data1">
          IGST %
        </label>
        <input
          className="table-data4"
          type="number"
          name="IGSTPercentage"
          value={invoiceSummaryFields.IGSTPercentage}
          onChange={handleGstChange}
          style={{ height: "1.4rem" }}
          required
        />
      </div>
      <div className="tableContainer">
        <table>
          <thead className="table-head">
            <tr>
              <th rowSpan="2" className="head-cell"></th>
              <th rowSpan="2" className="head-cell">
                Upto Last Bill
              </th>
              <th rowSpan="2" className="head-cell">
                This Bill
              </th>
              <th rowSpan="2" className="head-cell">
                Cumm upto this Bill
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Net Claim Amount</th>
              <td className="table-data1">
                {invoiceSummaryFields.NetClaimAmtUptoLastBill}
              </td>
              <td className="table-data2">
                {invoiceSummaryFields.NetClaimAmtThisBill}
              </td>
              <td className="table-data4">
                {invoiceSummaryFields.NetClaimAmtCummBill}
              </td>
            </tr>
            <tr>
              <th>Total Recovery Amount</th>
              <td className="table-data1">
                {invoiceSummaryFields.TotalRecoveryAmtUptoLastBill}
              </td>
              <td className="table-data2">
                {invoiceSummaryFields.TotalRecoveryAmtThisBill}
              </td>
              <td className="table-data4">
                {invoiceSummaryFields.TotalRecoveryAmtCummBill}
              </td>
            </tr>
            <tr>
              <th>Gross Payable Amount</th>
              <td className="table-data1">
                {invoiceSummaryFields.TotalGrossAmtUptoLastBill}
              </td>
              <td className="table-data2">
                {invoiceSummaryFields.TotalGrossAmtThisBill}
              </td>
              <td className="table-data4">
                {invoiceSummaryFields.TotalGrossAmtCummBill}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            margin: "1rem 0 1rem",
          }}
        >
          <div>
            <label for="LastPayment" className="table-data1">
              Last Payment Upto Last Bill
            </label>
            <input
              type="number"
              name="LastPayment"
              className="table-data4"
              value={invoiceSummaryFields.LastPayment}
              onChange={handleChange}
              style={{ height: "1.2rem" }}
            />
          </div>
          <div>
            <label for="NetPayableAmt" className="table-data1">
              Net Payable Amount
            </label>
            <input
              type="number"
              className="table-data4"
              name="NetPayableAmt"
              value={invoiceSummaryFields.NetPayableAmt}
              style={{ height: "1.2rem" }}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesOrderSummaryTable;
