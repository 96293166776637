import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import pick from "lodash/pick";
import DeliveryChallanForm from "../../../forms/MaterialSystem/DeliveryChallanForm";
import { useHistory } from "react-router-dom";
import { editDeliveryChallan } from "../../../redux/actions/materialSystemAction";
import "../style/materialSystem.css";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

import axios from "../../../components/apis/axios";

let allWI = [];
let allProject = [];
let fileIds = [];

export const DeliveryChallanFormEdit = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [purchaseRequestList, setPurchaseRequestList] = useState([]);
  const [warehouseIssueVoucherList, setWarehouseIssueVoucherList] = useState(
    []
  );
  const [projectList, setProjectList] = useState([]);
  const [stocksDetails, setStocksDetails] = useState([]);
  const [material, setMaterial] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);

  const { match } = props;

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get(
        `/materialManagement/deliveryChallan/${match.params.id}`
      );
      setData(data);
      setMaterial(data.materials);
      setPreviousFiles(data.Files);
    };
    fetch();
  }, [match.params.id]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      delete formValues.PurchaseDate;
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    dispatch(editDeliveryChallan(formValues, props.match.params.id, history));
  };

  const selectSourceProject = async (project, formFields) => {
    try {
      let item = allProject.find(
        (item) => item.ProjectShortDescription === project
      );
      item = {
        SourceProject: project,
        FromWarehouse: item.WareHouse,
        SourceGSTNo: item.WareHouseGst,
      };
      setData({ ...data, ...formFields, ...item });
      if (formFields.DeliveryChallanType === "Purchase Request No​") {
        const { data } = await axios.post(
          "/materialManagement/shared/getPurchaseRequest/",
          {
            requestedFrom: "DeliveryChallan",
            ProjectCodeName: project,
          }
        );
        const mapped = data.map((item) => item.PurchaseRequestNo);
        setPurchaseRequestList(mapped);
      } else {
        let {
          data,
        } = await axios.post(
          "/materialManagement/warehouseIssue/getByProjectName",
          { ProjectCodeName: project }
        );
        console.log(data);
        allWI = data;
        let WIVlist = data.map((item) => item.WarehouseIssueVoucherNo);
        setWarehouseIssueVoucherList(WIVlist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const selectPurchaseReq = async (PurchaseRequestNo, formFields) => {
    let payload = await axios.get(
      `/materialManagement/purchaseRequest/getByPurchaseRequestNo/${PurchaseRequestNo}`
    );
    setMaterial(payload.data.materials);
    const materials = payload.data.materials;
    payload = {
      ...data,
      PurchaseRequestNo: payload.data.PurchaseRequestNo,
      DestinationProject: payload.data.ProjectCodeName,
      ToWarehouse: payload.data.WarehouseName,
      DestinationGSTNo: payload.data.WarehouseGSTNo,
      Date: payload.data.create_date,
    };
    setData({
      ...data,
      ...payload,
      ...formFields,
    });
    try {
      const Code = materials.map((item) => item.MaterialCode);
      const req = {
        WareHouse: data.FromWarehouse,
        Code,
      };
      console.log(JSON.stringify(req));
      const { data: code } = await axios.post(
        "/materialManagement/shared/getWarehouseStockRate",
        req
      );
      setStocksDetails(code);
    } catch (err) {
      console.error(err);
    }
  };

  const selectWarehouseIssue = async (WarehouseIssueVoucherNo, formFields) => {
    const item = allWI.find(
      (item) => item.WarehouseIssueVoucherNo === WarehouseIssueVoucherNo
    );

    if (item) {
      let payload = await axios.get(
        `/materialManagement/warehouseIssue/${item.id}`
      );
      const materials = payload.data.materials;
      setMaterial(payload.data.materials);
      payload = {
        ...data,
        WarehouseIssueVoucherNo: payload.data.WarehouseIssueVoucherNo,
        DestinationProject: payload.data.ProjectCodeName,
        ToWarehouse: payload.data.Warehouse,
        WarehouseIssueDate: payload.data.DateofIssue,
        GoodsIssueType: payload.data.GoodsIssueType,
        VendorCodeName: payload.data.VendorCodeName,
        CustomerCodeName: payload.data.CustomerCodeName,
        WorkOrderNo: payload.data.WorkOrderNo,
        SalesOrderNo: payload.data.WorkSalesNo,
      };
      setData({
        ...data,
        ...payload,
        ...formFields,
      });
      try {
        const Code = materials.map((item) => item.MaterialCode);
        const req = {
          WareHouse: data.FromWarehouse,
          Code,
        };
        console.log(JSON.stringify(req));
        const { data: code } = await axios.post(
          "/materialManagement/shared/getWarehouseStockRate",
          req
        );
        setStocksDetails(code);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetchProjects() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const filteredData = data.filter((item) => item.Status === "accepted");
        let projects = filteredData.map((item) => item.ProjectShortDescription);
        allProject = filteredData;
        setProjectList(projects);
      } catch (error) {
        alert(error);
      }
    }
    fetchProjects();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name="Delivery Challan Edit"
        linkName1="Delivery Challan Dashboard"
        to1="/materialManagement/deliveryChallan"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Delivery Challan Form Edit
      </h3>
      <div className="line"></div>
      <DeliveryChallanForm
        initialValues={pick(
          data,
          "DeliveryChallanType",
          "PurchaseRequestNo",
          "Date",
          "WarehouseIssueVoucherNo",
          "WarehouseIssueDate",
          "GoodsIssueType",
          "GoodsStockType",
          "SourceProject",
          "DestinationProject",
          "FromWarehouse",
          "ToWarehouse",
          "PurchaseSalesOrderNo",
          "PurchaseDate",
          "TransportWORequired",
          "VendorCodeName",
          "CustomerCodeName",
          "TransportType",
          "LRRRConsignment",
          "LRDate",
          "SourceGSTNo",
          "DestinationGSTNo",
          "Remarks",
          "DeliveryChallanValue"
        )}
        type={data.DeliveryChallanType}
        materialsTableEdit={material}
        onSubmit={(formValues) => onSubmit(formValues, history)}
        PRList={purchaseRequestList}
        WIVList={warehouseIssueVoucherList}
        ProjectList={projectList}
        stocksDetails={stocksDetails}
        selectPurchaseReq={selectPurchaseReq}
        selectWarehouseIssue={selectWarehouseIssue}
        selectSourceProject={selectSourceProject}
        fileId={fileIds}
        previousFiles={previousFiles}
      />
    </div>
  );
};
