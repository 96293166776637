import React, { useEffect, useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../components/apis/axios";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));

const EmployeeJoin = (props) => {
  const [show, setShow] = React.useState(false);
  const classes = useStyle();
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const fieldValues = useSelector((state) => state.form?.EmployeeJoin?.values);
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    if (employees.length > 0 && formValues?.employee) {
      const employee = employees.find(
        (acc) => acc?.employee?.EmployeeCode === formValues?.EmployeeCode
      );
      if (employee) {
        formValues["employee"] = employee?.value;
      }
    }
    if (projects.length > 0 && formValues?.project) {
      const project = projects.find(
        (acc) => acc?.project?.ProjectCode === formValues?.ProjectCode
      );
      if (project) {
        formValues["project"] = project?.value;
      }
    }
    props.onSubmit({
      employee: formValues.employee,
      project: formValues?.project,
      wageType: formValues?.wageType,
      allowances: formValues?.allowances,
      projectBenefits: formValues?.projectBenefits,
      projectJoiningDate: formValues?.projectJoiningDate,
    });
  };

  const toggleModal = () => setShow(!show);

  // const validatePhoneNo = (value) =>
  //   value && !/^[0-9]$/.test(value) && value.length !== 10
  //     ? "Phone number invalid"
  //     : undefined;

  // const validateEmail = (value) =>
  //   value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  //     ? "Invalid email address"
  //     : undefined;

  const [isValid, setIsValid] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (value) => {
    // const email = value;
    if (emailRegex.test(value)) {
      setIsValid(true);
      setMessage(`Great your "${value}" is valid 👍`);
    } else {
      setIsValid(false);
      setMessage("Please enter a valid email! 👎");
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular", {
          params: {
            status: "Active",
          },
        });
        setProjects(
          data
            ?.filter((item) => item?.Status === "accepted")
            .map((project) => ({
              label: `${project.ProjectCode} - ${project.ProjectShortDescription}`,
              value: project.id,
              project,
            }))
        );
      } catch (error) {
        alert(error);
      }
    };
    const fetchAccount = async () => {
      try {
        const { data } = await axios.get("/commonMaster/employeeDetail", {
          params: {
            status: "Accepted",
          },
        });
        setEmployees(
          data?.map((employee) => ({
            label: `${employee.EmployeeCode} - ${employee.FirstName} ${employee.LastName}`,
            value: employee.id,
            employee,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    const fetchBenefits = async () => {
      try {
        const { data } = await axios.get("/commonMaster/benefits");
        setBenefits(data?.map((account) => account.benefitCode));
      } catch (error) {
        console.log(error);
      }
    };
    fetchBenefits();
    fetchAccount();
    fetchProject();
  }, []);

  const handleChange = (value) => {
    if (value) {
      Object.entries(value?.employee || value?.project).map((field) => {
        let value = "";
        if (field[0] === "benefits") {
          value = field[1]?.benefitCode;
        } else {
          value = field[1];
        }
        dispatch(change("EmployeeJoin", field[0], value));
      });
    }
  };

  const {
    RenderTextField,
    AutoCompleteInput,
    RenderDatePastField,
    RenderDateField,
  } = Control;

  const inputsFields = [
    {
      component: AutoCompleteInput,
      name: "project",
      label: "Project Code / Name",
      list: projects,
      onChange: handleChange,
    },
    {
      component: RenderTextField,
      name: "CustomerName",
      label: "Customer Name",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "CostCentre",
      label: "Cost Centre",
      disabled: true,
    },
    {
      component: AutoCompleteInput,
      name: "allowances",
      label: "Allowances",
      list: [
        "HRA",
        "Off Day",
        "Site",
        "Extra",
        "Special",
        "Conveyance",
        "Detention",
        "Education",
      ],
    },
    {
      component: AutoCompleteInput,
      name: "wageType",
      label: "Wages Type",
      list: ["Single", "Double", "One and Half time", "Not Applicable"],
    },
    {
      component: AutoCompleteInput,
      name: "employee",
      list: employees,
      label: "Name of Employee",
      onChange: handleChange,
    },
    {
      component: AutoCompleteInput,
      name: "employeeType",
      list: ["Permanent", "Contract"],
      label: "Employee Type",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "fatherOrHusband",
      label: "Father / Husband Name",
      disabled: true,
    },
    {
      component: RenderDatePastField,
      name: "dateOfBirth",
      label: "Date Of Birth ( 18+ )",
      initialValue: fieldValues?.dateOfBirth || null,
      maxDate: moment().subtract(18, "year").format("d MMM yyyy"),
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "FirstName",
      label: "Employee First Name",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "LastName",
      label: "Employee Last Name",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "Email",
      label: "Email",
      validate: [validateEmail],
      helperText: message,
      disabled: true,
    },
    {
      component: AutoCompleteInput,
      name: "gender",
      list: ["Male", "Female", "Other"],
      label: "Gender",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "religion",
      label: "Religion",
      disabled: true,
    },
    {
      component: RenderTextField,
      name: "motherTongue",
      disabled: true,
      label: "Mother Tongue",
    },
    {
      component: RenderTextField,
      name: "presentAddress",
      disabled: true,
      label: "Present Address",
    },
    {
      component: RenderTextField,
      name: "permanentAddress",
      disabled: true,
      label: "Permanent Address",
    },
    {
      component: RenderTextField,
      name: "Phone",
      disabled: true,
      label: "Phone",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: RenderTextField,
      name: "emergencyContactNumber",
      disabled: true,
      label: "Emergency Contact Number",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: AutoCompleteInput,
      name: "maritalStatus",
      list: ["Married", "Unmarried"],
      disabled: true,
      label: "Marital Status",
    },
    {
      component: RenderTextField,
      name: "noOfChildren",
      disabled: true,
      label: "No of Children",
      isHidden: fieldValues?.maritalStatus !== "Married",
    },
    {
      component: RenderTextField,
      name: "identificationMark",
      disabled: true,
      label: "Identification Mark",
    },
    {
      component: RenderTextField,
      name: "height",
      disabled: true,
      label: "Height (In cent.)",
    },
    {
      component: RenderTextField,
      name: "weight",
      disabled: true,
      label: "Weight (In KG)",
    },
    {
      component: AutoCompleteInput,
      name: "vision",
      list: ["Normal", "Specs"],
      disabled: true,
      label: "Vision",
    },
    {
      component: RenderTextField,
      name: "leftEye",
      disabled: true,
      label: "Left Eye",
      isHidden: fieldValues?.vision !== "Specs",
    },
    {
      component: RenderTextField,
      name: "rightEye",
      disabled: true,
      label: "Right Eye",
      isHidden: fieldValues?.vision !== "Specs",
    },
    {
      component: RenderTextField,
      name: "educationalQualification",
      disabled: true,
      label: "Educational Qualification",
    },
    {
      component: RenderTextField,
      name: "pastExperience",
      disabled: true,
      label: "Past Experience",
    },
    {
      component: RenderTextField,
      name: "designation",
      disabled: true,
      label: "Designation",
    },
    {
      component: RenderTextField,
      name: "functionalRole",
      disabled: true,
      label: "Functional Role",
    },
    {
      component: RenderTextField,
      name: "pFAccountNo",
      disabled: true,
      label: "PF Account No",
    },
    {
      component: RenderTextField,
      name: "universalAccountNo",
      disabled: true,
      label: "Universal Account No",
    },
    {
      component: RenderTextField,
      name: "eSIC",
      disabled: true,
      label: "ESIC",
    },
    {
      component: RenderTextField,
      name: "bankAccountNo",
      disabled: true,
      label: "Bank Account No",
    },
    {
      component: RenderTextField,
      name: "bankName",
      disabled: true,
      label: "Bank Name",
    },
    {
      component: RenderTextField,
      name: "IFSC",
      disabled: true,
      label: "IFSC",
    },
    {
      component: RenderTextField,
      name: "aadhar",
      disabled: true,
      label: "Aadhar",
    },
    {
      component: RenderTextField,
      name: "PAN",
      disabled: true,
      label: "PAN",
    },
    {
      component: RenderTextField,
      name: "wages",
      disabled: true,
      label: "Wages ( In Rupees )",
    },
    {
      component: AutoCompleteInput,
      name: "oTType",
      disabled: true,
      label: "OT Type",
      list: ["Single", "Double", "One and Half time", "Not Applicable"],
    },
    {
      component: RenderTextField,
      name: "benefits",
      disabled: true,
      label: "Benefits",
    },
    {
      component: RenderTextField,
      name: "Company",
      disabled: true,
      label: "Company",
    },
    {
      component: RenderDateField,
      name: "dateOfJoining",
      initialValue: fieldValues?.dateOfJoining || null,
      label: "Date of Joining",
      disabled: true,
    },
    {
      component: RenderDateField,
      name: "dateOfExit",
      initialValue: fieldValues?.dateOfExit || null,
      label: "Date of Exit",
      disabled: true,
    },
    {
      component: RenderDateField,
      name: "projectJoiningDate",
      initialValue: fieldValues?.projectJoiningDate || null,
      label: "Project Joining Date",
    },
    {
      component: AutoCompleteInput,
      name: "projectBenefits",
      // initialValue: fieldValues?.projectBenefits || null,
      label: "Project Benefits",
      list: benefits,
      onChange: (data) => console.log({ data }),
    },
    {
      component: AutoCompleteInput,
      name: "Tags",
      disabled: true,
      label: "Tag",
      list: ["", "Active", "Inactive"],
    },
  ];

  return (
    <div className="newformcontainer">
      <form className={`form-main ${classes.formWrapper}`}>
        <Grid container spacing={2}>
          {inputsFields
            .filter((hiddenField) => !hiddenField.isHidden)
            .map((field, index) => (
              <Grid item xs={6} key={index}>
                <Field {...field} />
              </Grid>
            ))}
        </Grid>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Employee Joins"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};

  const requiredFields = ["employee", "wageType", "allowances", "project"];

  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "EmployeeJoin",
  validate,
  enableReinitialize: true,
})(EmployeeJoin);
