import React from "react";
import { useSelector } from "react-redux";
import Logo from "../components/assets/newLogo.svg";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ProjectSelect from "../components/fixedComponents/ProjectSelect";
import LoginForm from "../forms/Login/LoginForm";
import background from "../components/assets/login_background.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    paddingTop: "4rem",
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    height: "150px",
    width: "150px",
  },
}));

const Login = () => {
  const store = useSelector((store) => store);
  const classes = useStyles();

  return (
    <div className="">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} className={classes.image} />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} src={Logo}></Avatar>
            {!store.userRoot.isAuthenticated ? (
              <LoginForm />
            ) : (
              <ProjectSelect />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Login;
