import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import Table from "../../Tables/MaterialSystem/GoodsInspectionVoucherTable";
import * as Control from "../../components/formcomponents/control";
// import "./PurchaseOrderAmendmentForm.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const GoodsInspectionVoucherForm = (props) => {
  const [state, setState] = useState({
    storedData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({ ...state, tableError: true });
    } else {
      props.onSubmit({ ...formValues, tableData: state.tableData });
    }
  };

  const storeTableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            name="ProjectCodeName"
            component={Control.AutoCompleteInput}
            label="ProjectCode / Name"
            className="text-field"
            list={props.projectNameList}
            onChange={(value) =>
              props.selectProject && props.selectProject(value)
            }
          />
          <Field
            name="GoodsReceiptVoucherNo"
            component={Control.AutoCompleteInput}
            label="Goods Receipt Voucher No"
            className="text-field"
             list={props.list}
            onChange={(value) =>
              props.selectGoodRec && props.selectGoodRec(value)
            }
          />
          <Field
            name="DateofReceipt"
            component={Control.RenderDateField}
            initialValue={props.initialValues.DateofReceipt}
            label="Date of Receipt​"
            disabled
          />
          {props.initialValues.PurchaseRequestNo && (
            <Field
              name="PurchaseRequestNo"
              component={Control.RenderTextField}
              label="Purchase Request No"
              disabled
            />
          )}
          <Field
            name="CustomerName"
            component={Control.RenderTextField}
            label="Customer Name"
            disabled
          />
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse"
            disabled
          />
          {props.initialValues.SalesOrderNo && (
            <Field
              name="SalesOrderNo"
              component={Control.RenderTextField}
              label="Sales Order No​"
              disabled
            />
          )}
          <Field
            name="PurchaseOrderNo"
            component={Control.RenderTextField}
            label="Purchase Order No"
            disabled
          />
          <Field
            name="PurchaseDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.PurchaseDate}
            label="Purchase Date"
            disabled
          />
          <Field
            name="VendorCodeName"
            component={Control.RenderTextField}
            label="Vendor Code / Name"
            disabled
          />
          <Field
            name="VendorGstNo"
            component={Control.RenderTextField}
            label="Vendor GST No"
            disabled
          />
          {props.initialValues.CustomerCodeName && (
            <Field
              name="CustomerCodeName"
              component={Control.RenderTextField}
              label="CustomerCode/Name"
              disabled
            />
          )}
          <Field
            name="Warehouse"
            component={Control.RenderTextField}
            label="Warehouse Code / Name​"
            disabled
          />
          {props.initialValues.DeliveryChallanNo && (
            <>
              <Field
                name="DeliveryChallanNo"
                component={Control.RenderTextField}
                label="Delivery Challan No​"
                disabled
              />
            </>
          )}
          {props.initialValues.DeliveryDate && (
            <Field
              name="DeliveryDate"
              component={Control.RenderDateField}
              initialValue={props.initialValues.DeliveryDate}
              label="Delivery Date"
              disabled
            />
          )}
          <Field
            name="LRRRConsignmentNo"
            component={Control.RenderTextField}
            label="LR / RR / Consignment No​​"
            disabled
          />
          <Field
            name="LRDate"
            component={Control.RenderDateField}
            initialValue={props.initialValues.LRDate}
            label="LR Date​"
            disabled
          />
          <Field
            name="BatchIdentificationNo"
            component={Control.RenderTextField}
            label="Batch / Identification No​"
            disabled
          />
          <Field
            name="UnloadedAt"
            component={Control.RenderTextField}
            label="Unloaded At​"
            disabled
          />
        </div>
        <div className="excel">
          <Table
            storeTableData={storeTableData}
            materials={props.materials}
            materialsTable={props.materialsTable}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Goods Inspection Voucher"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};
const validate = (values) => {
  const errors = {};
  const requiredFields = ["GoodsReceiptVoucherNo"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "GoodsReceiptVoucherForm",
  validate,
  enableReinitialize: true,
})(GoodsInspectionVoucherForm);
