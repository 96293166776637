import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import GoodsReceiptType from "../../../forms/CommonMaster/GoodsReceiptType";
import { useHistory } from "react-router-dom";
import {
  editGoodsReceiptType,
  searchGoodsReceiptTypeById,
} from "../../../redux/actions/CommonMasterAction";

import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const GoodsReceiptTypeEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.commonMasterSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchGoodsReceiptTypeById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleGoodsReceiptType) {
      setData(store.SingleGoodsReceiptType);
    }
  }, [store.SingleGoodsReceiptType]);

  const onSubmit = (formValues) => {
    dispatch(editGoodsReceiptType(formValues, props.match.params.id, history));
  };
  return (
    <div>
      <Breadcrumbs
        name="Goods Receipt Type Edit"
        linkName1="Goods Receipt Type Dashboard"
        to1="/commonMaster/goodsReceiptType"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Goods Receipt Type
      </h3>
      <div className="line"></div>
      <GoodsReceiptType
        initialValues={pick(data, "ReceiptCode", "ReceiptDescription")}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
