import React, { useState, useEffect } from "react";

const DailyProgressMachineTableView = ({ DailyProgressMachineTableView }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (DailyProgressMachineTableView) {
      setData([...DailyProgressMachineTableView]);
    }
  }, [DailyProgressMachineTableView]);
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th rowSpan="2">SlNo</th>
            <th rowSpan="2">MachineCodeName</th>
            <th rowSpan="2">UOM</th>
            <th rowSpan="2">QuantityUtilised</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.SerialNo}</td>
              <td>{row.MachineCodeName}</td>
              <td>{row.UOM}</td>
              <td>{row.QuantityUtilised}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default DailyProgressMachineTableView;
