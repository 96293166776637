import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import axios from "../../../components/apis/axios";
import HurdleReport from "../../../forms/ExecutionSystem/HurdleReport";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];

export const HurdleReportEdit = (props) => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [formDetail, setFormDetail] = useState({});

  const { match } = props;

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.put(
      `/executionSystem/hurdleRegister/${props.match.params.id}`,
      formValues
    );
    console.log(response);
    history.push("/executionSystem/hurdleRegister");
  };

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/executionSystem/hurdleRegister/${match.params.id}`
        );
        let data = response.data;
        setFormDetail(data);
        console.log("hurdleRegister ", data);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [match.params]);

  const selectProjectName = async (projectName) => {
    if (projectName) {
      let detail = allProjects.find((item) =>
        item.ProjectShortDescription === projectName ? item : ""
      );
      try {
        const { data } = await axios.get(
          `/salesAccounting/projectCircular/${detail.id}`
        );

        detail = {
          ...formDetail,
          ProjectCodeName: data.ProjectShortDescription,
          CustomerName: data.CustomerName,
        };

        setFormDetail(detail);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        console.log(data);
        let filteredData = data.filter((item) => item.Status === "accepted");
        let mapedData = filteredData.map(
          (item) => item.ProjectShortDescription
        );
        setProjectList(mapedData);
        allProjects = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }

    fetch();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Hurdle Register Edit"
        linkName1="Hurdle Register Dashboard"
        to1="/executionSystem/hurdleRegister"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Hurdle Register
      </h3>
      <HurdleReport
        onSubmit={onSubmit}
        ProjectList={projectList}
        selectProjectName={selectProjectName}
        initialValues={pick(
          formDetail,
          "CustomerName",
          "ProjectCodeName",
          "ReportingDate",
          "StartTime",
          "EndTime",
          "Area",
          "Reason"
        )}
      />
    </div>
  );
};
