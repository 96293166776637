import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Currency = (props) => {
  const [show, setShow] = useState(false);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="CurrencyCode"
            label="Currency Code"
          />
          <Field
            component={Control.RenderTextField}
            name="CurrencyName"
            label="Currency"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Currency"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = ["CurrencyCode", "Currency"];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "Currency",
  validate,
  enableReinitialize: true,
})(Currency);
