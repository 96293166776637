import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import pick from "lodash/pick";
import PhysicalVerificationForm from "../../../forms/MaterialSystem/PhysicalVerificationForm";
import "../style/materialSystem.css";
import { PhysicalVerification } from "../../../redux/actions/materialSystemAction";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allPN = [];

export const PhysicalVerificationFormCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [WHname, setWHName] = useState([]);
  const [MCCode, setMCCode] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [WareHouse, setWarehouse] = useState("");

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(PhysicalVerification(formValues, history));
  };

  const selectMaterial = async (material) => {
    try {
      const MaterialCode = material.split("-")[0].trim();
      const req = {
        MaterialCode,
        WareHouse,
      };
      console.log(JSON.stringify(req));
      const { data } = await axios.post(
        "/materialManagement/shared/getMaterialStock",
        req
      );
      console.log(data);
      setFormDetail({
        ...formDetail,
        ...data,
        MaterialCodeName: material,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const selectProject = async (ProjectName) => {
    const item = allPN.find(
      (item) => item.ProjectShortDescription === ProjectName
    );
    console.log(item);

    let payload = {
      ...formDetail,
      CustomerName: item.CustomerName,
      ProjectCodeName: ProjectName,
      Warehouse: item.WareHouse,
    };
    setWarehouse(item.WareHouse);
    setFormDetail(payload);
  };

  const fetchAllList = async () => {
    const { data: projects } = await axios.get(
      `/salesAccounting/projectCircular/`
    );
    allPN = projects;
    let mapped = projects.map((item) => item.ProjectShortDescription);
    setProjectName(mapped);

    const { data: materials } = await axios.get(`/commonMaster/material/`);
    mapped = materials.map(
      (item) =>
        item.MaterialCode +
        " - " +
        item.MaterialDescription +
        " - " +
        item.MaterialAdditionalDescription
    );
    setMCCode(mapped);

    const { data: warehouse } = await axios.get("/commonMaster/wareHouse");
    mapped = warehouse.map((item) => item.WareHouseName);
    setWHName(mapped);
  };

  useEffect(() => {
    fetchAllList();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Physical Verification Create"
        linkName1=" Physical Verification Dashboard"
        to1="/materialManagement/physicalVerification"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Physical Verification Form
      </h3>
      <div className="line"></div>
      <PhysicalVerificationForm
        allPN={projectName}
        allWH={WHname}
        allMC={MCCode}
        selectProject={selectProject}
        selectMaterial={selectMaterial}
        onSubmit={onSubmit}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "StockQuantity",
          "MaterialCodeName",
          "Warehouse"
        )}
      />
    </div>
  );
};
