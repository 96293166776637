import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Activity = (props) => {
    const [show, setShow] = useState(false);
  const [state, setState] = useState("");

    const onSubmit = (formValues) => {
      props.onSubmit(formValues);
    };

    const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="ActivityCode"
            label="Activity Code"
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="CostType"
            label="Cost Type"
            className="text-field"
            list={[" ", "DirectCost", "IndirectCost"]}
            onChange={(value) => setState(value)}
          />
          {state === "DirectCost" && (
            <Field
              component={Control.AutoCompleteInput}
              name="MajorActivity"
              label="Major Activity"
              className="text-field"
              list={[
                " ",
                "Civil Works",
                "ElectricalWorks",
                " Micro-tunnelling Works",
                "Maintenance Work",
                "Goods Supply",
              ]}
            />
          )}
          <Field
            component={Control.RenderTextField}
            name="ActivityDescription"
            label="Activity Description"
            className="text-field"
          />
          <Field
            name="UOM"
            component={Control.AutoCompleteInput}
            label="UOM Code"
            list={props.UOMCodeList}
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Activity Tag"
            className="text-field"
            list={[" ", "Active", "Inactive"]}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Activity"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
}


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "CostType",
    "ActivityDescription",
    "UOM",
    "ActivityTag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "Activity",
  validate,
  enableReinitialize: true,
})(Activity);
