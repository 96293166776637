import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import * as DashBoard from "../../pages/ExecutionSystem/Dashboard/export";
import * as Create from "../../pages/ExecutionSystem/Create/export";
import * as Edit from "../../pages/ExecutionSystem/Edit/export";
import * as View from "../../pages/ExecutionSystem/View/export";
const ExecutionSystem = (
  <Switch>
    {/*Dash */}
    <Route
      exact
      path="/executionSystem/dailyProgressReport"
      component={DashBoard.DailyProgressReportDash}
    />
    <Route
      exact
      path="/executionSystem/hurdleRegister"
      component={DashBoard.HurdleReportDash}
    />

    {/*Create */}
    <Route
      exact
      path="/executionSystem/dailyProgressReport/create"
      component={Create.DailyProgressReportCreate}
    />
    <Route
      exact
      path="/executionSystem/hurdleRegister/create"
      component={Create.HurdleReportCreate}
    />

    {/*Edit */}
    <Route
      exact
      path="/executionSystem/dailyProgressReport/edit/:id"
      component={Edit.DailyProgressReportEdit}
    />
    <Route
      exact
      path="/executionSystem/hurdleRegister/edit/:id"
      component={Edit.HurdleReportEdit}
    />

    {/*view */}
    <Route
      exact
      path="/executionSystem/dailyProgressReport/view/:id"
      component={View.DailyProgressReportView}
    />
    <Route
      exact
      path="/executionSystem/hurdleRegister/view/:id"
      component={View.HurdleReportView}
    />
  </Switch>
);
export default ExecutionSystem;
