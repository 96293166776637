import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DailyAttendance from "../../../forms/ProjectWorkforceManagement/DailyAttendanceForm";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProject = [];

export const DailyAttendanceCreate = () => {
  const history = useHistory();
  const [costCentrelist, setCostCentrelist] = useState([]);
  const [shiftlist,setShiftlist] = useState([]);
  const [formDetail, setformDetail] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [machineList, setMachineList] = useState(null);
  const [activityList, setActivityList] = useState(null);
  const [workmenList, setWorkmenList] = useState(null);
  const [projectName, setProjectName] = useState();
  const [shiftCode, setShiftCode] = useState(null);
  const [list, setList] = useState([]);

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    const response = await axios.post(
      "workforceManagement/dailyAttendence",
      formValues
    );
    console.log(response);
    history.push("/workforceManagement/dailyAttendence/");
  };

  const selectProjectName = async (projectName) => {
    setProjectName(projectName);
    if (projectName) {
      let detail = allProject.find((item) =>
        item.ProjectCode === projectName?.split(" ")[0]
      );
      try {
        const { data } = await axios.post(
          `commonMaster/machine/findByProjectName/`,
          {
            currentlyAt: projectName,
          }
        );
        let machines = data.map((item) => item.machineID);
        const mapped = machines.map(
          (item) => item.MachineGdes + " - " + item.Machinedes
        );
        setMachineList(mapped);
      } catch (err) {
        console.error(err);
      }
      detail = {
        ...detail,
        ProjectCodeName: detail.ProjectShortDescription,
        CostCentre: detail.CostCentre,
        CustomerName: detail.CustomerName,
        Date: detail.created_at,
      };
      setformDetail(detail);
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter((item) => item.Status === "accepted");
        allProject = filtered;
        let PNlist = filtered.map((item) =>item.ProjectCode + " - " + item.ProjectShortDescription);
        setList(PNlist);
      } catch (error) {
        alert(error);
      }
    }

    async function getCostCenterDetails() {
      try {
        const { data } = await axios.get("/commonMaster/costCentre");
        const filteredData = data.map((item) => item.CostCenterName);
        setCostCentrelist(filteredData);
      } catch (err) {
        console.error(err);
      }
    }
    async function getVendorAndActivity() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        let VNList = data.map((item) => item.VendorName);
        setVendorList(VNList);
        const { data: activities } = await axios.get("/commonMaster/activity/");
        const mapped = activities.map(
          (item) => item.ActivityCode + " - " + item.ActivityDescription
        );
        setActivityList(mapped);
      } catch (err) {
        alert(err);
      }
    }
    async function getShiftCode() {
      try {
        const { data } = await axios.get("/commonMaster/shifts");
        setShiftlist(data);
      } catch (err) {
        console.error(err);
      }
    }
    async function getWorkMen() {
      try {
        let { data } = await axios.post(
          "/workforceManagement/shared/getAllWorkmenInProject",
          {
            ProjectCodeName: projectName ? projectName : "ProjectCodeName",
          }
        );
        setWorkmenList(data);
      } catch (err) {
        alert(err);
      }
    }
    getShiftCode();
    getWorkMen();
    getVendorAndActivity();
    fetchProject();
    getCostCenterDetails();
  }, [machineList, projectName]);

  return (
    <div>
      <BreadCrumbs
        name="Daily Attendance Create"
        linkName1="Daily Attendance Dashboard"
        to1="/workforceManagement/dailyAttendence/"
      />
      <h3 style={{ textAlign: "center" }}>Daily Attendance</h3>
      <DailyAttendance
        shiftlist={shiftlist}
        workmenList={workmenList}
        CCList={costCentrelist}
        shiftCode={shiftCode}
        list={list}
        VNList={vendorList}
        selectProjectName={selectProjectName}
        onSubmit={onSubmit}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "CostCentre",
          "Date"
        )}
        edit={false}
        machineList={machineList}
        activityList={activityList}
      />
    </div>
  );
};
