import React from "react";

const NotesTable = ({ CashDetails }) => {
  return (
    <>
      {CashDetails && (
        <div>
          <h4 className="table-header">Notes Details</h4>
          <div className="tablecontainer">
            <table>
              <thead className="table-head">
                <tr>
                  <th className="head-cell">2000</th>
                  <th className="head-cell">1000</th>
                  <th className="head-cell">500</th>
                  <th className="head-cell">200</th>
                  <th className="head-cell">100</th>
                  <th className="head-cell">50</th>
                  <th className="head-cell">20</th>
                  <th className="head-cell">10</th>
                  <th className="head-cell">5</th>
                  <th className="head-cell">2</th>
                  <th className="head-cell">1</th>
                  <th className="head-cell">TotalCash</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-data1">{CashDetails.TwoThousand}</td>
                  <td className="table-data3">{CashDetails.OneThousand}</td>
                  <td className="table-data3">{CashDetails.FiveHundred}</td>
                  <td className="table-data3">{CashDetails.TwoHundred}</td>
                  <td className="table-data3">{CashDetails.OneHundred}</td>
                  <td className="table-data3">{CashDetails.Fifty}</td>
                  <td className="table-data3">{CashDetails.Twenty}</td>
                  <td className="table-data3">{CashDetails.Ten}</td>
                  <td className="table-data3">{CashDetails.Five}</td>
                  <td className="table-data3">{CashDetails.Two}</td>
                  <td className="table-data3">{CashDetails.One}</td>
                  <td className="table-data4">{CashDetails.TotalCash}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default NotesTable;
