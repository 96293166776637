import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import SearchOptionCustomer from "../ReportsComponent/SearchOptionCustomer";
import SearchOptionProject from "../ReportsComponent/SearchOptionProject";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useStyle from "../helpers/UseStyle";
import FilterByDate from "../ReportsComponent/FilterByDate";
import FilterByValue from "../ReportsComponent/FilterByValue";
import { CircularProgress } from "@material-ui/core";

export default function SalesInvoiceReportTable(props) {
  const [lists, setLists] = useState();
  const [filterSelected, setFilterSelected] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const classes = useStyle();

  useEffect(() => {
    if (props.lists) {
      let data = props.lists;
      data = data.reverse();
      setLists(data);
    }
  }, [props.lists]);

  const StatusChecker = (status) => {
    if (status === "accepted") {
      return <h5 className={classes.approved}>Approved</h5>;
    }
    return;
  };

  const handleCustomerChange = async (value) => {
    const data = props.lists;
    const filtered = data.filter((item) => item.CustomerName === value);
    setLists(filtered);
  };

  const handleFilterByDate = (fromDate, toDate) => {
    let data = props.lists;
    const filtered = data.filter(
      (item) =>
        item.created_at >= new Date(fromDate).toLocaleString() &&
        item.created_at <= new Date(toDate).toLocaleString()
    );
    setLists(filtered);
  };

  const handleInvoiceTypeSelect = (event) => {
    setInvoiceType(event.target.value);
    let data = props.lists;
    const filtered = data.filter(
      (item) => item.InvoiceCategory === event.target.value
    );
    setLists(filtered);
  };

  const handleByValueFilter = (value1, value2) => {
    let data = props.lists;
    console.log(value1, value2);
    const filtered = data.filter(
      (item) => +item.OrderValue >= +value1 && +item.OrderValue <= +value2
    );
    console.log(filtered);
    setLists(filtered);
  };

  const handleFilterSelect = (event) => {
    setFilterSelected(event.target.value);
  };

  const handleProjectChange = (project) => {
    let data = props.lists;
    const filtered = data.filter((item) => item.ProjectCodeName === project);
    setLists(filtered);
  };

  return (
    <div className={classes.table}>
      <h2 className="text-center">{props.heading}</h2>
      <div
        id="filter-select"
        style={{
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControl style={{ margin: "1rem" }}>
          <InputLabel>Filter</InputLabel>
          <Select
            name="filter"
            value={filterSelected}
            onChange={(event) => {
              handleFilterSelect(event);
            }}
            style={{ width: "10rem" }}
          >
            <MenuItem value={"byCustomer"}>By Customer</MenuItem>
            <MenuItem value={"byDate"}>By Date</MenuItem>
            <MenuItem value={"byType"}>By Type</MenuItem>
            <MenuItem value={"byValue"}>By Value</MenuItem>
            <MenuItem value={"byProject"}>By Project</MenuItem>
          </Select>
        </FormControl>
        {filterSelected === "byCustomer" ? (
          <SearchOptionCustomer handleCustomerChange={handleCustomerChange} />
        ) : filterSelected === "byDate" ? (
          <FilterByDate handleFilterByDate={handleFilterByDate} />
        ) : filterSelected === "byType" ? (
          <FormControl style={{ margin: "1rem" }}>
            <InputLabel>Invoice Type</InputLabel>
            <Select
              name="invoiceType"
              value={invoiceType}
              onChange={handleInvoiceTypeSelect}
              style={{ width: "10rem" }}
            >
              <MenuItem value={"Proforma"}>Proforma</MenuItem>
              <MenuItem value={"Progressive"}>Progressive</MenuItem>
              <MenuItem value={"First&Final"}>First & Final</MenuItem>
            </Select>
          </FormControl>
        ) : filterSelected === "byValue" ? (
          <FilterByValue handleByValueFilter={handleByValueFilter} />
        ) : (
          filterSelected === "byProject" && (
            <SearchOptionProject
              handleProjectChange={handleProjectChange}
              label="Projects"
            />
          )
        )}
      </div>
      <div>
        {lists ? (
          <TableContainer className={classes.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{props.label1}</TableCell>
                  <TableCell align="center">CustomerName</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Operation</TableCell>
                  <TableCell align="center">
                    {lists.Status && "Status"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists.map((list, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <ListAltIcon color="primary" />
                      {list[props.tablecol1]}
                    </TableCell>
                    <TableCell align="center">{list.CustomerName}</TableCell>
                    <TableCell align="center">
                      <UpdateIcon color="primary" /> {list.created_at}
                    </TableCell>
                    <TableCell align="center">
                      {props.visibleIcon && (
                        <span>
                          <IconButton>
                            <Link to={`${props.viewLink}/${list.id}`}>
                              <VisibilityIcon color="primary" />
                            </Link>
                          </IconButton>
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {StatusChecker(list.Status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <CircularProgress />
        )}
      </div>
    </div>
  );
}
