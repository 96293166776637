import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Form from "../../../forms/MachineManagementSystem/MachineReceiptForm";
import { machineReceipt } from "../../../redux/actions/MachineManagementaction";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import { useSnackbar } from "notistack";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];
let allMachine = [];

export const MachineReceiptCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ProjectList, setProjectList] = useState([]);
  const [MachineList, setMachineList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [machineReceipts, setMachineReceipts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formValues) => {
    dispatch(machineReceipt(formValues, history, enqueueSnackbar));
  };

  const selectProjectName = (projectName) => {
    const item = allProjects.find(
      (item) => item.ProjectShortDescription === projectName
    );

    if (item) {
      let payload = {
        ...formDetail,
        ProjectCodeName: item.ProjectShortDescription,
      };

      setFormDetail(payload);
    }
  };

  const selectMachineProjectName = async (MachineCode) => {
    const item = allMachine.find(
      (item) => item.MachineCode + "-" + item.MaterialCdes === MachineCode
    );

    if (item) {
      let payload = await axios.post(
        `commonMaster/machine/findByMachineCode/`,
        {
          machinecodename: item.MachineCode,
        }
      );
      payload = {
        ...formDetail,
        MachineCodeName: MachineCode,
        LastProjectCodeName: payload.data.CurrentlyAt,
        LastMeterReading: payload.data.MeterReading,
      };
      setFormDetail(payload);
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        const filtered = data.filter(
          (item) =>
            item?.Status === "accepted" && item?.projectStatus === "Active"
        );
        console.log(filtered);
        allProjects = filtered;
        let projects = data.map(
          (item) => `${item.ProjectCode} - ${item.ProjectShortDescription}`
        );
        setProjectList(projects);
      } catch (err) {}
    }
    async function fetchMachine() {
      try {
        const { data } = await axios.get("/commonMaster/Machine");
        let { data: machineReceipt } = await axios.get(
          `/machineManagement/machineReceipt`
        );
        machineReceipt = machineReceipt.map((m) => m.MachineCodeName);

        allMachine = data;
        let Machine = data
          .map(
            (item) =>
              item.MachineCode + "-" + item.MachineGdes + "-" + item.Machinedes
          )
          .filter((m) => !machineReceipt.includes(m));

        setMachineList(Machine);
      } catch (err) {}
    }
    fetch();
    fetchMachine();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Machine Receipt Create"
        linkName1="Machine Receipt Dashboard"
        to1="/machineManagement/machineReceipt"
      />
      <h3 style={{ textAlign: "center" }}>Machine Receipt</h3>
      <Form
        edit={false}
        onSubmit={onSubmit}
        ProjectList={ProjectList}
        MachineList={MachineList}
        selectProjectName={selectProjectName}
        selectMachineProjectName={selectMachineProjectName}
        initialValues={pick(
          formDetail,
          "ProjectCodeName",
          "LastProjectCodeName",
          "MachineCodeName",
          "LastMeterReading"
        )}
      />
    </div>
  );
};
