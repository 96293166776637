import React ,{useState} from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import Table from "../../Tables/CommonMaster/StoreGST";

const WareHouse = (props) => {
  console.log("warehouse------", props)
  const [state, setState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  const [show, setShow] = useState(false);

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    if (!state.tableData) {
      setState({ tableError: true });
    } else if (state.tableData) {
      props.onSubmit({
        ...formValues,
        tableData: state.tableData,
      });
    }
  };
  console.log(props,"httttttttttttttttttttttttttttttttttttttttttt");
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="CompanyName"
            label="Company Name"
            list={props.companyDetail}
            className="text-field"
            onChange={(value) => props.findCostCenter(value)}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="CostCenterName"
            label="Cost Center Name"
            list={props.CClist}
            className="text-field"
            onChange={(value) => {
              props.findCostCenterGST(value);
              // props.findGst(value);
            }}
          />
          <Field
            component={Control.RenderTextField}
            name="WareHouseName"
            label="WareHouse Name"
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectSiteAddress"
            label="Project Site Address"
            multiline
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
        <div className="">
          <Table
            tableData={tableData}
            gsts={props.costCenterGST}
            Gst={props.Gst}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="WareHouse"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectName",
    "CostCenterName",
    "WareHouseName",
    "ProjectSiteAddress",
    "GSTRegNo",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "WareHouse",
  validate,
  enableReinitialize: true,
})(WareHouse);
