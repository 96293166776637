import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import "../style/materialSystem.css";
import { useHistory } from "react-router-dom";
import WarehouseReceiptVoucherform from "../../../forms/MaterialSystem/WarehouseReceiptVoucherForm";
import {
  searchWarehouseReceiptById,
  editwarehouseReceipt,
} from "../../../redux/actions/materialSystemAction";

import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allGRV = [];

export const WarehouseReceiptVoucherformEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.materialSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [poMaterials, setPoMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);

  const { match } = props;

  useEffect(() => {
    dispatch(searchWarehouseReceiptById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleWarehouseReceipt) {
      setData(store.SingleWarehouseReceipt);
      setMaterials(store.SingleWarehouseReceipt.materials);
      setPreviousFiles(store.SingleWarehouseReceipt.Files);
    }
  }, [store.SingleWarehouseReceipt]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editwarehouseReceipt(formValues, props.match.params.id, history));
  };

  const selectGoodRec = async (GoodsInspectionVoucherNo) => {
    const item = allGRV.find(
      (item) => item.GoodsInspectionVoucherNo === GoodsInspectionVoucherNo
    );
    try {
      if (item) {
        const payload = await axios.get(
          `/materialManagement/goodsInspection/${item.id}`
        );
        console.log(payload);
        if (payload.data.PurchaseOrderNo != null) {
          const { data: order } = await axios.get(
            `/materialManagement/purchaseOrder/findByOrderNo/${payload.data.PurchaseOrderNo}`
          );
          setPoMaterials(order.materials);
        }
        setMaterials(payload.data.materials);
        setFormDetail({
          ...payload.data,
          PurchaseDate: payload.data.PurchaseDate,
        });
      }
    } catch (error) {
      alert("Server Error!");
      console.log(error);
    }
  };
  const fetchGoodInsepection = async () => {
    try {
      const { data } = await axios.get("materialManagement/goodsInspection/");
      allGRV = data;
      console.log(data);
      let GIAccepted = data.filter((item) => item.Status === "accepted");
      const GIVN = GIAccepted.map((item) => item.GoodsInspectionVoucherNo);
      setList(GIVN);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchGoodInsepection();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Warehouse Receipt Voucher  Edit"
        linkName1=" Warehouse Receipt Voucher  Dashboard"
        to1="/materialManagement/warehouseReceipt"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Warehouse Receipt Voucher Edit
      </h3>
      <div className="line"></div>
      <WarehouseReceiptVoucherform
        initialValues={pick(
          data,
          formDetail,
          "ProjectCodeName",
          "CustomerName",
          "Warehouse",
          "StoredAt",
          "VendorCodeName",
          "CustomerCodeName",
          "WarehouseCodeName",
          "DeliveryChallanNo",
          "DeliveryDate",
          "LRRRConsignmentNo",
          "LRDate",
          "DateofReceipt",
          "BatchIdentificationNo",
          "GoodsReceiptVoucherNo",
          "GoodsInspectionVoucherNo",
          "GoodsStockType",
          "WarehouseReceiptVoucherValue",
          "VendorGstNo"
        )}
        list={list}
        selectGoodRec={selectGoodRec}
        onSubmit={onSubmit}
        previousFiles={previousFiles}
        materialsEditData={materials}
        poMaterials={poMaterials}
      />
    </div>
  );
};
