import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import ActivityTable from "../../Tables/ExecutionSystem/DailyProgressActivityTable";

import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";


const DailyProgressReport = (props) => {
  const [state, setState] = useState({ dailyProgressReportSub: [] });
  const [show, setShow] = useState(false);

  const toggleModal = () => setShow(!show);

  const storeDailyProgressReportSub = (data) => {
    setState({ dailyProgressReportSub: data });
  };

  const onSubmit = (formValues) => {
    if (!state.dailyProgressReportSub) {
      setState({ ...state, tableError: true });
    } else if (state.dailyProgressReportSub) {
      props.onSubmit({
        ...formValues,
        dailyProgressReportSub: state.dailyProgressReportSub,
      });
    }
  };

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            list={props.ProjectList}
            label="Project Code Name"
            className="text-field"
            onChange={(value) =>
              props.selectProjectName && props.selectProjectName(value)
            }
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            disabled
            label="Customer Name"
          />
          <Field
            component={Control.RenderDatePastField}
            name="ProgressDate"
            label="Progress Date"
            initialValue={props.initialValues.ProgressDate}
          />
        </div>
        <div className="excel">
          <ActivityTable
            storeDailyProgressReportSub={storeDailyProgressReportSub}
            dailyProgressReportSubEditDetails={
              props.dailyProgressReportSubEditDetails
            }
            activityList={props.activityList}
            uom={props.uom}
            vendorList={props.vendorList}
            machineList={props.machineList}
            materialList={props.materialList}
            scItemList={props.scItemList}
            SelectActivity={props.SelectActivity}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Daily Progress Report"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });
  return err;
};

export default reduxForm({
  form: "DailyProgressReport",
  validate,
  enableReinitialize: true,
})(DailyProgressReport);
