import React, { useState, useCallback } from "react";
import { Field, reduxForm } from "redux-form";
import SalesOrderInvoiceTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesOrderInvoiceTable";
import SalesInvoiceRecoveryTable from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceRecoveryTable";
import SalesInvoiceSummary from "../../Tables/SalesAcountingSystem/SalesInvoice/SalesInvoiceSummaryTable";
import * as Control from "../../components/formcomponents/control";
import TabLayout from "../../components/formcomponents/TabLayout";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";

const SalesInvoiceForm = (props) => {
  const [invoiceSavedData, setInvoiceSavedData] = useState(null);
  const [recoverySavedData, setRecoverySavedData] = useState(null);
  const [tabDisabledArr, setTabDisabledArr] = useState([false, true, true]);
  const [show, setShow] = useState(false);
  const [summaryData, setSummaryData] = useState({});

  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };
  const toggleModal = () => setShow(!show);

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (invoiceSavedData && recoverySavedData)
      props.onSubmit({
        ...formValues,
        InvoiceData: invoiceSavedData,
        RecoveryData: recoverySavedData,
        SummaryData: summaryData,
      });
    else alert("Table Error");
  };

  const invoiceSummary = useCallback((summary) => {
    setSummaryData({
      ...summary,
    });
  }, []);

  const saveInvoiceTable = useCallback(
    (inputFields, totalInvoiceAmt) => {
      console.log(props.orderValue);
      if (+props.orderValue >= +totalInvoiceAmt) {
        setInvoiceSavedData({ fields: inputFields, amt: totalInvoiceAmt });
        setTabDisabledArr([false, false, true]);
      } else {
        alert(`Invoice Value cannot be more than Order Value`);
      }
    },
    [props.orderValue]
  );

  const saveRecoveryTable = useCallback((inputFields, totalRecoveryAmt) => {
    setRecoverySavedData({ fields: inputFields, amt: totalRecoveryAmt });
    setTabDisabledArr([false, false, false]);
  }, []);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project Code / Name"
            list={props.ProjectList}
            className="text-field"
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          />
          <Field
            component={Control.RenderTextField}
            name="OriginalFormNo"
            label="Original Form No"
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="SalesOrderNo"
            label="​Sales Order No"
            disabled
          />
          <Field
            component={Control.RenderDateField}
            name="SalesOrderDate"
            label="Sales Order Date"
            initialValue={props.initialValues.SalesOrderDate}
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="LastInvoiceNo"
            label="Last Invoice No"
            type="text"
            disabled
          />
          <Field
            component={Control.RenderDateAllField}
            name="OrderReferenceDate"
            initialValue={props.initialValues.OrderReferenceDate}
            label="Order Reference Date"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderReferenceNumber"
            label="Order reference number"
          />
          <Field
            component={Control.RenderTextField}
            name="LastAmendmentNo"
            label="Last Amendment No"
            type="text"
            disabled
          />
          <Field
            component={Control.RenderDateField}
            initialValue={props.initialValues.LastAmendmentDate}
            name="LastAmendmentDate"
            label="Last Amendment Date"
            type="text"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="OrderValue"
            label="Order Value / Last Amendment Order Value"
            type="number"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CustomerGSTNo"
            label="Customer GST No"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="CompanyGstNo"
            label="FNC GST No"
            disabled
          />
          <Field
            component={Control.RenderSelectField}
            name="NatureOfInvoice"
            label="Nature Of Invoice"
          >
            <option value={""}></option>
            <option value={"Goods"}>Goods</option>
            <option value={"Services"}>Services</option>
          </Field>
          <Field
            component={Control.RenderSelectField}
            name="InvoiceCategory"
            label="Invoice Category"
          >
            <option value={""}></option>
            <option value={"Proforma"}>Proforma</option>
            <option value={"Progressive"}>Progressive</option>
            <option value={"First&Final"}>First & Final</option>
          </Field>
          <TabLayout
            labels={["Invoice", "Recovery", "Summary"]}
            // activeTabs={tabDisabledArr}
            tables={[
              <SalesOrderInvoiceTable
                invoiceTable={props.invoiceTable}
                salesOrderTable={props.salesOrderTable}
                staged={props.staged}
                stageData={props.stageData}
                saveInvoiceTable={saveInvoiceTable}
                invoiceSavedData={invoiceSavedData}
                invoiceEditData={props.invoiceEditData}
                materialQty={props.materialQty}
                invoiceTotalAmt={props.invoiceTotalAmt}
                disabled={false}
              />,
              // <SalesInvoiceRecoveryTable
              //   recoveryTypes={props.recoveryTypes}
              //   invoiceSavedData={invoiceSavedData}
              //   recoveryTable={props.recoveryTable}
              //   saveRecoveryTable={saveRecoveryTable}
              //   recoverySavedData={recoverySavedData}
              //   recoverTableEdit={props.recoverTableEdit}
              //   recoveryTotalAmt={props.recoveryTotalAmt}
              // />,
              // <SalesInvoiceSummary
              //   invoiceSavedData={invoiceSavedData}
              //   recoverySavedData={recoverySavedData}
              //   invoiceSummary={invoiceSummary}
              //   gstPercentage={props.gstPercentage}
              //   LastPaymentUptoLastBill={props.LastPaymentUptoLastBill}
              // />,
            ]}
          />
        </div>

        <div>
          <br />
          <br />
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Sales Invoice"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};
const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "CustomerName",
    "SalesOrderNo",
    "SalesOrderDate",
    "LastAmendmentOrderValue",
    "CustomerGSTNo",
    "CompanyGstNo",
    "NatureOfInvoice",
    "InvoiceCategory",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};

export default reduxForm({
  form: "SalesOrder",
  validate,
  enableReinitialize: true,
})(SalesInvoiceForm);
