import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MachineReceipt from "../../../forms/MachineManagementSystem/MachineReceiptForm";
import { useHistory } from "react-router-dom";
import {
  editMachineReceipt,
  searchMachineReceiptById,
} from "../../../redux/actions/MachineManagementaction";
import axios from "../../../components/apis/axios";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allProjects = [];
let allMachine = [];

export const MachineReceiptEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const [ProjectList, setProjectList] = useState([]);
  const [MachineList, setMachineList] = useState([]);
  const [formDetail, setFormDetail] = useState({});

  const [previousFiles, setPreviousFiles] = useState();

  useEffect(() => {
    dispatch(searchMachineReceiptById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMachineReceipt) {
      const data = store.SingleMachineReceipt;
      setData(data);
      setPreviousFiles(data.Files);
    }
    console.log("SingleMachineReceipt ->", store.SingleMachineReceipt);
  }, [store.SingleMachineReceipt]);

  const onSubmit = (formValues) => {
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    console.log(JSON.stringify(formValues));
    dispatch(editMachineReceipt(formValues, props.match.params.id, history));
  };
  const selectProjectName = (projectName) => {
    const item = allProjects.find(
      (item) => item.ProjectShortDescription === projectName
    );

    if (item) {
      console.log(item);
      let payload = {
        ...formDetail,
        ProjectCodeName: item.ProjectShortDescription,
      };

      setFormDetail(payload);
    }
  };

  const selectMachineProjectName = async (MachineCode) => {
    const item = allMachine.find(
      (item) => item.MachineCode + "-" + item.MaterialCdes === MachineCode
    );

    if (item) {
      let payload = await axios.post(
        `commonMaster/machine/findByMachineCode/`,
        {
          machinecodename: item.MachineCode,
        }
      );
      console.log("payload ->", payload);
      payload = {
        ...formDetail,
        MachineCodeName: MachineCode,
        LastProjectCodeName: payload.data.CurrentlyAt,
        LastMeterReading: payload.data.MeterReading,
      };
      console.log("here", payload);
      setData({ ...data, ...payload });
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("/salesAccounting/projectCircular");
        allProjects = data;
        let projects = data.map((item) => item.ProjectShortDescription);
        setProjectList(projects);
      } catch (err) {}
    }
    async function fetchMachine() {
      try {
        const { data } = await axios.get("/commonMaster/Machine");
        console.log(data);
        allMachine = data;
        console.log("Machine-> ", allMachine);
        let Machine = data.map(
          (item) => item.MachineCode + "-" + item.MaterialCdes
        );

        setMachineList(Machine);
      } catch (err) {}
    }
    fetch();
    fetchMachine();
  }, []);

  return (
    <div>
      <BreadCrumbs
        name="Machine Receipt Edit"
        linkName1="Machine Receipt Dashboard"
        to1="/machineManagement/machineReceipt"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Machine Receipt Edit
      </h3>
      <div className="line"></div>
      <MachineReceipt
        ProjectList={ProjectList}
        MachineList={MachineList}
        selectProjectName={selectProjectName}
        selectMachineProjectName={selectMachineProjectName}
        initialValues={pick(
          data,
          "ProjectCodeName",
          "MachineCodeName",
          "LastProjectCodeName",
          "DateofReceipt",
          "DateofCommissioning",
          "MachineIHCRate",
          "PurchaseValue",
          "AcquisitionValue",
          "MeterFlag",
          "BookValue",
          "PurchaseDate",
          "LastAcqsDate",
          "MinorMajor",
          "MeterType",
          "ShiftHours",
          "LastMeterReading"
        )}
        edit={true}
        previousFiles={previousFiles}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
