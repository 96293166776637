import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "../../components/apis/axios";

const useStyle = makeStyles((theme) => ({
  message: {
    marginTop: "20px",
    fontSize: "17px",
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  formWrapper: {
    padding: "0 30px",
    "& .MuiFormControl-root": {
      width: "100% !important",
      margin: "10px 0px !important",
    },
  },
}));


export const WorkmenCreate = (props) => {
  const classes = useStyle();
  // const [type, setType] = useState("");
  // const [mStatus, setMStatus] = useState("");
  // const [vision, setvision] = useState("");
  const [show, setShow] = useState(false);
  const fieldValues = useSelector(state => state.form?.WorkmenMaster?.values)
  const toggleModal = () => setShow(!show);

  console.log(fieldValues)
  const onSubmit = (formValues) => {

    console.log(formValues)


    
    // if (!state.table) {
    //   setState({ ...state, tableError: true });
    // } else if (state.table) {
    //   props.onSubmit({
    //     ...formValues,
    //     tableData: state.table,
    //   });
    // }

    props.onSubmit(formValues)
  };


  const {
    RenderTextField,
    AutoCompleteInput,
    RenderDatePastField,
    RenderMonthField,
    RenderDateField,
  } = Control;

  const inputsFields = [
    {
      component: RenderTextField,
      name: "WorkmenCode",
      label: "Workmen Code",
      disabled: !!props?.initialValues?.WorkmenCode,
    },
   {
      component: AutoCompleteInput,
      name: "WorkmenType",
      list: ["Departmental", "Sub-contractor"],
      label: "Workmen Type",
    },
    {
      component: AutoCompleteInput,
      name: "VendorCode",
      label: "Vendor Code / Name",
      isHidden: fieldValues?.WorkmenType !== 'Sub-contractor',
    },
    {
      component: RenderTextField,
      name: "WorkmenName",
      label: "WorkmenName",
    },
    {
      component: RenderTextField,
      name: "WorkmenFather",
      label: "Father / Husband Name",
    },
    {
      component: RenderDatePastField,
      name: "WorkmenDOB",
      label: "Date Of Birth ( 18+ )",
      initialValue: fieldValues?.WorkmenDOB || null,
      // maxDate: moment().subtract(18, "year").format("d MMM yyyy"),
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenGender",
      list: ["Male", "Female", "Other"],
      label: "Gender",
    },
    {
      component: RenderTextField,
      name: "WorkmenReligion",
      label: "Religion",
    },
    {
      component: RenderTextField,
      name: "WorkmenMotherTongue",
      label: "Mother Tongue",
    },
    {
      component: RenderTextField,
      name: "WorkmenPresentAddress",
      label: "Present Address",
    },
    {
      component: RenderTextField,
      name: "WorkmenPermanentAddress",
      label: "Permanent Address",
    },
    {
      component: RenderTextField,
      name: "WorkmenContact",
      label: "Phone",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: RenderTextField,
      name: "WorkmenPhone",
      label: "Emergency Contact Number",
      InputProps: {
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      },
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenMarital",
      list: ["Married", "Unmarried"],
      label: "Marital Status",
    },
    {
      component: RenderTextField,
      name: "WorkmenChild",
      label: "No of Children",
      isHidden: fieldValues?.WorkmenMarital !== 'Married',
    },
    {
      component: RenderTextField,
      name: "WorkmenIdentification",
      label: "Identification Mark",
    },
    {
      component: RenderTextField,
      name: "WorkmenHeight",
      label: "Height (In cent.)",
    },
    {
      component: RenderTextField,
      name: "WorkmenWeight",
      label: "Weight (In KG)",
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenVision",
      list: ["Normal", "Specs"],
      label: "Vision",
    },
    {
      component: RenderTextField,
      name: "WorkmenVisionLeft",
      label: "Left Eye",
      isHidden: fieldValues?.WorkmenVision !== 'Specs',
    },
    {
      component: RenderTextField,
      name: "WorkmenVisionRight",
      label: "Right Eye",
      isHidden: fieldValues?.WorkmenVision !== 'Specs',
    },
    {
      component: RenderTextField,
      name: "WorkmenEducation",
      label: "Educational Qualification",
    },
    {
      component: RenderTextField,
      name: "WorkmenExpInYears",
      label: "Past Experience In Years",
    },
    {
      component: RenderTextField,
      name: "WorkmenExpInMonths",
      label: "Past Experience In Months",
    },
    {
      component: RenderDateField,
      name: "WorkmenDOJ",
      label: "Date of Joining",
      initialValue: fieldValues?.WorkmenDOJ || null,
    },
    {
      component: RenderDateField,
      name: "WorkmenDOE",
      label: "Date of Exit",
      initialValue: fieldValues?.WorkmenDOE || null,
    },
    {
      component: RenderTextField,
      name: "WorkmenDesignation",
      label: "Designation",
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenSkill",
      label: "Skill category",
      list: ["Highly Skilled", "Semi Skilled", "Skilled", "Un Skilled"],
    },
    {
      component: RenderTextField,
      name: "WorkmenPF",
      label: "PF Account No",
    },
    {
      component: RenderTextField,
      name: "WorkmenUniAccNo",
      label: "Universal Account No",
    },
    {
      component: RenderTextField,
      name: "WorkmenESIC",
      label: "ESIC",
    },
    {
      component: RenderTextField,
      name: "WorkmenBankAccount",
      label: "Bank Account No",
    },
    {
      component: RenderTextField,
      name: "WorkmenBankName",
      label: "Bank Name",
    },
    {
      component: RenderTextField,
      name: "WorkmenBankIFSC",
      label: "IFSC",
    },
    {
      component: RenderTextField,
      name: "WorkmenAadhar",
      label: "Aadhar",
    },
    {
      component: RenderTextField,
      name: "WorkmenPAN",
      label: "PAN",
    },
    {
      component: RenderTextField,
      name: "WorkmenWages",
      label: "Wages ( In Rupees )",
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenWageType",
      label: "Wages Type",
      list: ["Daily", "Monthly"],
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenOT",
      label: "OT Type",
      list: ["Single", "Double", "One and Half time", "Not Applicable"],
    },
    {
      component: AutoCompleteInput,
      name: "WorkmenBenefits",
      label: "Benefits",
      list: props.benefitlist,
    },
  ];

  return (
    <div className="newformcontainer">
    <form className={`form-main ${classes.formWrapper}`}>
      <Grid container spacing={2}>
        {inputsFields
          .filter((hiddenField) => !hiddenField.isHidden)
          .map((field) => (
            <Grid item xs={6}>
              <Field {...field} />
            </Grid>
          ))}
      </Grid>
    </form>
    <SubmitModelPopup
      handleSubmitForm={props.handleSubmit(onSubmit)}
      formTitle="Employee Masters"
      onFormClick={toggleModal}
      open={show}
      onClose={toggleModal}
      closeAfterTransition
    />
  </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "WorkmenType",
    "WorkmenName",
    "WorkmenDOJ",
    "WorkmenVision",
    "WorkmenSkill",
    "WorkmenDesignation",
    "WorkmenESIC",
    "WorkmenBankAccount",
    "WorkmenBankName",
    "WorkmenBankIFSC",
    "WorkmenAadhar",
    "WorkmenPAN",
    "WorkmenWages",
    "WorkmenWageType",
    "WorkmenOT",
    "WorkmenUniAccNo",
    "WorkmenBenefits",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if(values?.dateOfJoining && values?.dateOfExit) {
    const dateOfJoining = moment(values?.dateOfJoining);
    const dateOfExit = moment(values?.dateOfExit)
    if(dateOfExit.isSameOrBefore(dateOfJoining)) {
      errors['dateOfExit'] = 'Date of exit should be greater than joining';
    }
  }
  return errors;
};

export default reduxForm({
  form: "WorkmenMaster",
  validate,
  enableReinitialize: true,
})(WorkmenCreate);
