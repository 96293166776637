import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import axios from "../../../components/apis/axios";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";

const Benefit = (props) => {
  const [show, setShow] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const fieldValues = useSelector(state => state.form?.Benefit?.values)

  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    if (accounts.length > 0 && formValues?.account) {
      const account = accounts.find(
        (acc) => acc.label === formValues?.account
      );
      if(account) {
        formValues["account"] = account?.value;
      }
    }
    props.onSubmit(formValues);
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const { data } = await axios.get("/commonMaster/account");
        setAccounts(
          data?.map((account) => ({
            label: account.AccountCode,
            value: account.id,
            desciption: account.AccountDes
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccount();
  }, []);

  const handleAccount = (e) => {
    dispatch(change('Benefit', 'accountDescription', e.desciption));
  }

  const toggleModal = () => setShow(!show);

  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="benefitCode"
            label="Benefit Code"
            disabled
          />
          <Field
            component={Control.RenderTextField}
            name="benefitDescription"
            label="Benefit Description"
          />
          <Field
            component={Control.RenderTextField}
            name="basicWage"
            label="% Of Basic Wage"
            type="number"
            min="0"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="applicableFor"
            label="Applicable For"
            list={["Both", "Workmen", "Employee"]}
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="account"
            label="Account"
            list={accounts}
            onChange={handleAccount}
            className="text-field"
          />
          <Field
            component={Control.RenderTextField}
            initialValue={fieldValues?.accountDescription  || null}
            name="accountDescription"
            label="Account Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="tag"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Benefit"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "benefitCode",
    "benefitDescription",
    "tag",
    "applicableFor",
    "accountDescription",
    "account",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
    if (values?.basicWage) {
      const parseNum = parseInt(values?.basicWage);
      if (parseNum < 0) {
        err["basicWage"] = "Value must be greater than 0";
      }
    }
  });

  return err;
};
export default reduxForm({
  form: "Benefit",
  validate,
  enableReinitialize: true,
})(Benefit);
