import React from "react";
import Route from "../PrivateRouter";
import { Switch } from "react-router-dom";
import * as DashBoard from "../../pages/MachineManagemetSystem/DashBoard/export";
import * as Create from "../../pages/MachineManagemetSystem/Create/export";
import * as Edit from "../../pages/MachineManagemetSystem/Edit/export";
import * as View from "../../pages/MachineManagemetSystem/View/ExportViewMMS";
import * as Reports from "../../pages/MachineManagemetSystem/Reports/export";

const MachineManagemetSystem = (
  <Switch>
    <Route
      exact
      path="/machineManagement/reports"
      component={Reports.ReportsList}
    />
    <Route
      exact
      path="/machineManagement/reports/machineReportVoucher"
      component={Reports.MachineReceiptVoucher}
    />
    <Route
      exact
      path="/machineManagement/reports/logsheetReport"
      component={Reports.LogSheetReport}
    />
    <Route
      exact
      path="/machineManagement/reports/logsheetReport/AttendanceView"
      component={Reports.LogCalender}
    />
    <Route
      exact
      path="/machineManagement/machineReceipt"
      component={DashBoard.MachineReceiptDash}
    />

    <Route
      exact
      path="/machineManagement/machineReceipt/create"
      component={Create.MachineReceiptCreate}
    />
    <Route
      exact
      path="/machineManagement/machineReceipt/edit/:id"
      component={Edit.MachineReceiptEdit}
    />
    <Route
      exact
      path="/machineManagement/machineReceipt/view/:id"
      component={View.MachineReceiptView}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseRequest"
      component={DashBoard.MachinePurchaseRequestDash}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseRequest/create"
      component={Create.MachinePurchaseRequestCreate}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseRequest/edit/:id"
      component={Edit.MachinePurchaseRequestEdit}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseRequest/view/:id"
      component={View.MachinePurchaseRequestView}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrder"
      component={DashBoard.MachinePurchaseOrderDash}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrder/create"
      component={Create.MachinePurchaseOrderCreate}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrder/edit/:id"
      component={Edit.MachinePurchaseOrderEdit}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrder/view/:id"
      component={View.MachinePurchaseOrderView}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrderAmendment"
      component={DashBoard.MachinePurchaseOrderAmendmentDash}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrderAmendment/create"
      component={Create.MachinePurchaseOrderAmendmentsCreate}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrderAmendment/edit/:id"
      component={Edit.MachinePurchaseOrderAmendmentsEdit}
    />
    <Route
      exact
      path="/machineManagement/machinePurchaseOrderAmendment/view/:id"
      component={View.MachinePurchaseOrderAmendmentsView}
    />
    <Route
      exact
      path="/machineManagement/minorMachineLink"
      component={DashBoard.MinorMachineLinkDash}
    />
    <Route
      exact
      path="/machineManagement/minorMachineLink/create"
      component={Create.MinorMachineLinkCreate}
    />
    <Route
      exact
      path="/machineManagement/minorMachineLink/edit/:id"
      component={Edit.MinorMachineLinkEdit}
    />
    <Route
      exact
      path="/machineManagement/minorMachineLink/view/:id"
      component={View.MinorMachineLinkView}
    />
    <Route
      exact
      path="/machineManagement/logSheetEntry"
      component={DashBoard.LogSheetEntry}
    />
    <Route
      exact
      path="/machineManagement/logSheetEntry/create"
      component={Create.LogSheetEntryCreate}
    />
    <Route
      exact
      path="/machineManagement/logSheetEntry/edit/:id"
      component={Edit.LogSheetEntryEdit}
    />
    <Route
      exact
      path="/machineManagement/logSheetEntry/view/:id"
      component={View.LogSheetEntryView}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRequest"
      component={DashBoard.MachineRepairRequestDash}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRequest/create"
      component={Create.MachineRepairRequestCreate}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRequest/edit/:id"
      component={Edit.MachineRepairRequestEdit}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRequest/view/:id"
      component={View.MachineRepairRequestView}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRegister"
      component={DashBoard.MachineRepairRegisterDash}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRegister/create"
      component={Create.MachineRepairRegisterCreate}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRegister/edit/:id"
      component={Edit.MachineRepairRegisterEdit}
    />
    <Route
      exact
      path="/machineManagement/machineRepairRegister/view/:id"
      component={View.MachineRepairRegisterView}
    />
    <Route
      exact
      path="/machineManagement/machinePhysicalVerification"
      component={DashBoard.MachinePhysicalVerificationDash}
    />
    <Route
      exact
      path="/machineManagement/machinePhysicalVerification/create"
      component={Create.MachinePhysicalVerificationCreate}
    />
    <Route
      exact
      path="/machineManagement/machinePhysicalVerification/edit/:id"
      component={Edit.MachinePhysicalVerificationEdit}
    />
    <Route
      exact
      path="/machineManagement/machinePhysicalVerification/view/:id"
      component={View.MachinePhysicalVerficationView}
    />
    <Route
      exact
      path="/machineManagement/machineRelease"
      component={DashBoard.MachineReleaseDash}
    />
    <Route
      exact
      path="/machineManagement/machineRelease/create"
      component={Create.MachineReleaseCreate}
    />
    <Route
      exact
      path="/machineManagement/machineRelease/edit/:id"
      component={Edit.MachineReleaseEdit}
    />
    <Route
      exact
      path="/machineManagement/machineRelease/view/:id"
      component={View.MachineReleaseView}
    />
    <Route
      exact
      path="/machineManagement/machineHireRequest"
      component={DashBoard.MachineHireRequestDash}
    />
    <Route
      exact
      path="/machineManagement/machineHireRequest/create"
      component={Create.MachineHireRequestCreate}
    />
    <Route
      exact
      path="/machineManagement/machineHireRequest/edit/:id"
      component={Edit.MachineHireRequestEdit}
    />
    <Route
      exact
      path="/machineManagement/machineHireRequest/view/:id"
      component={View.MachineHireRequestView}
    />
    <Route
      exact
      path="/machineManagement/machineDeHireRequest"
      component={DashBoard.MachineDeHireRequestDash}
    />
    <Route
      exact
      path="/machineManagement/machineDeHireRequest/create"
      component={Create.MachineDeHireRequestCreate}
    />
    <Route
      exact
      path="/machineManagement/machineDeHireRequest/edit/:id"
      component={Edit.MachineDeHireRequestEdit}
    />
    <Route
      exact
      path="/machineManagement/machineDeHireRequest/view/:id"
      component={View.MachineDeHireRequestView}
    />
  </Switch>
);
export default MachineManagemetSystem;
