import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import pick from "lodash/pick";
import MinorMachineLink from "../../../forms/MachineManagementSystem/MinorMachineLink";
import { useHistory } from "react-router-dom";
import {
  editMinorMachineLink,
  searchMinorMachineLinkById,
} from "../../../redux/actions/MachineManagementaction";

export const MinorMachineLinkEdit = (props) => {
  const [data, setData] = useState({});
  const store = useSelector((store) => store.machineMangementSystemRoot);
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  useEffect(() => {
    dispatch(searchMinorMachineLinkById(match.params.id));
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (store.SingleMinorMachineLink) {
      setData(store.SingleMinorMachineLink);
    }
  }, [store.SingleMinorMachineLink]);
  const onSubmit = (formValues) => {
    dispatch(editMinorMachineLink(formValues, props.match.params.id, history));
  };
  return (
    <div style={{ padding: "100px" }}>
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Minor Machine Link Edit
      </h3>
      <div className="line"></div>
      <MinorMachineLink
        initialValues={pick(
          data,
          "ProjectCodeName",
          "CustomerName",
          "ActivityCodeDescription1",
          "ActivityCodeDescription2",
          "ActivityCodeDescription3",
          "Percentage1",
          "Percentage2",
          "Percentage3",
          "Date",
          "MachineCodeName"
        )}
        onSubmit={(formValues) => onSubmit(formValues, history)}
      />
    </div>
  );
};
