/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { SalesOrderTableView } from "../../../Tables/SalesAcountingSystem/view/SalesOrderTableView";
import { SalesOrderPaymentDataTableView } from "../../../Tables/SalesAcountingSystem/view/SalesOrderPaymentDataTableView";
import { SalesOrderRetentionsTableView } from "../../../Tables/SalesAcountingSystem/view/SalesOrderRetentionsTableView";
import { SalesOrderStagesView } from "../../../Tables/SalesAcountingSystem/view/SalesOrderStagesView";
import { SalesOrderLdclauseView } from "../../../Tables/SalesAcountingSystem/view/SalesOrderLdclauseView";
import { approvalSalesAccounting } from "../../../redux/actions/SalesAcounts";
import LinearProgress from "@material-ui/core/LinearProgress";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import moment from "moment";

export const SalesOrderView = (props) => {
  const history = useHistory();
  const [data, setData] = useState();
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesOrder/${match.params.id}`
        );
        let data = response.data;
        console.log("data", data);
        data.created_at = new Date(data.created_at).toLocaleString();
        data.ProjectStartDate = new Date(
          new Date(data.ProjectStartDate) + " UTC"
        ).toDateString();

        data.OrderReferenceDate = new Date(
          new Date(data.OrderReferenceDate) + " UTC"
        ).toDateString();

        data.ProjectEndDate = new Date(
          new Date(data.ProjectEndDate) + " UTC"
        ).toDateString();
        console.log("data", data);
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  var projectOrderValue = data?.OrderValue;
  var lastThree = projectOrderValue?.substring(projectOrderValue?.length - 3);
  var otherNumbers = projectOrderValue?.substring(
    0,
    projectOrderValue?.length - 3
  );
  if (otherNumbers != "") lastThree = "," + lastThree;
  var orderValue =
    otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);

  const handleModalAction = (data) => {
    dispatch(
      approvalSalesAccounting("salesOrder", match.params.id, history, data)
    );
  };
  const classes = useStyle();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getDefectLiabilityPeriod = () => {
    let date = '';
    try {
      if(data.DefectLiabilityPeriod) {
        date = JSON.parse(data.DefectLiabilityPeriod);
        return `${moment(date?.from).format("DD-MM-YYYY")} - ${moment(date?.to).format("DD-MM-YYYY")}`;
      }
    } catch (error) {
      return date;
    }
  }

  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Sales Order  View"
        linkName1="Sales Order  Dashboard"
        to1="/salesAccounting/salesOrder"
      />
      <Paper className={classes.Container}>
        <div>
          <ComapnyHeader />
          <h3 className={classes.Heading}>Sales Order</h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
        </div>

        {data ? (
          <div>
            <h6>{`Created by ${
              data.created_by
                ? ` ${data.created_by.first_name} ${data.created_by.last_name}`
                : ""
            }`}</h6>
            <Box border={1} borderRadius={16} className={classes.Box}>
              <div className={classes.boxInner}>
                <Paper className={classes.paper}>
                  <h6>Project Code:</h6>
                  <p>{`${data.ProjectCodeName} - ${data.OriginalFormNo}`}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer Name:</h6>
                  <p>{data.CustomerName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Order Reference No:</h6>
                  <p>{data.OrderReferenceNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Order Reference Date:</h6>
                  <p>{data.OrderReferenceDate}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Order Type:</h6>
                  <p>{data.OrderType}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Advance BG:</h6>
                  <p>{data.AdvanceBG}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer GSTNo:</h6>
                  <p>{data.CustomerGSTNo}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Insurance:</h6>
                  <p>{data.Insurance}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Customer GST No:</h6>
                  <p>{data.CustomerGSTNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Company Name:</h6>
                  <p>{data.CompanyName}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Company GST No:</h6>
                  <p>{data.CustomerGSTNo}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Stage (Y/N):</h6>
                  <p>{data.Stage}</p>
                </Paper>
                {data.Stage === "No" && (
                  <>
                    <Paper className={classes.paper}>
                      <h6>Percent %:</h6>
                      <p>100%</p>
                    </Paper>
                  </>
                )}

                <Paper className={classes.paper}>
                  <h6>Order Category:</h6>
                  <p>{data.OrderCategory}</p>
                </Paper>
                <Paper className={classes.paper}>
                  <h6>Defect Liability Period:</h6>
                  <p>{getDefectLiabilityPeriod()}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Project Order Value:</h6>
                  <p>{orderValue}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>LD Clause:</h6>
                  <p>{data.LDClause}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Price Variation Clause:</h6>
                  <p>{data.PriceVariationClause}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Project End Date:</h6>
                  <p>{data.ProjectEndDate}</p>
                </Paper>

                <Paper className={classes.paper}>
                  <h6>Project Start Date:</h6>
                  <p>{data.ProjectStartDate}</p>
                </Paper>
              </div>

              <div>
              {data.LDClause === "Yes" && (
                  <>
                    <h2>Ld Clause</h2>
                    <SalesOrderLdclauseView ldclause={data.ldclause} />
                  </>
                )}
                {data.Stage === "Yes" && (
                  <>
                    <h2>Stage</h2>
                    <SalesOrderStagesView stages={data.stages} />
                  </>
                )}
                <br />
                <h2>Payments Terms </h2>
                <SalesOrderPaymentDataTableView payments={data.payments} />
                <br />
                <h2>Retentions Terms </h2>
                <SalesOrderRetentionsTableView retentions={data.retentions} />
                <br />
                <h2>Sales Order Details </h2>
                <SalesOrderTableView materials={data.materials} />
              </div>
            </Box>
            {data.Files && (
              <Paper className={classes.paper}>
                <h6>Files:</h6>
                <FileListArea fileList={data.Files} />
              </Paper>
            )}
            <div className={classes.row}>
              {data.Comment && (
                <Paper className={classes.paper}>
                  <h6 style={{ color: "red" }}>Comment:</h6>
                  <p>{data.Comment}</p>
                </Paper>
              )}
            </div>

            {(post === RoleList.ADMIN.code || post === RoleList.BU_HEAD.code) &&
              data.Status === "not_reviewed" && (
                <div className={classes.ButtonGrp}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={() => {
                      setIsApprove(true);
                      toggleModal();
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.reject}
                    onClick={() => {
                      setIsApprove(false);
                      toggleModal();
                    }}
                  >
                    Reject
                  </Button>
                </div>
              )}
            <ApprovalAndRejectModal
              showModal={showModal}
              toggleModal={toggleModal}
              handleModalAction={handleModalAction}
              isApprove={isApprove}
              formName={"Project Circular"}
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Paper>
    </div>
  );
};
