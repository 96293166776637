import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../apis/axios";

const SearchOptionMachine = ({ handleMachineChange }) => {
  const [machine, setMachine] = useState([]);

  useEffect(() => {
    const fetchCutomer = async () => {
      const { data } = await axios.get("/commonMaster/machine");
      setMachine(data);
      console.log("Machine", data);
    };
    fetchCutomer();
  }, []);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        options={machine}
        getOptionLabel={(item) => item?.MachineCode + "-" + item?.MachineGdes}
        onChange={(event, values) =>
          handleMachineChange(
            values ? values?.MachineCode + "-" + values?.MachineGdes : null
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter machine"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    </div>
  );
};

export default SearchOptionMachine;
