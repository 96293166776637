import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";

import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const Material = (props) => {
  const [show, setShow] = React.useState(false);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);
// {
//    "MaterialGroupCodeAndDescription": "innovacio",
//         "MaterialDescription": "test",
//         "MaterialAdditionalDescription": "test",
//         "UOM": "test",
//         "MaterialCode": "test",
//         "StatusTag": "Active",
//         "Tags": "test"

// }
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="MaterialGroupCodeAndDescription"
            label="Material Group Code"
            list={props.list}
            className="text-field"
          />

          <Field
            component={Control.RenderTextField}
            name="MaterialCode"
            label="Material Code"
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialDescription"
            label="Material Description"
          />
          <Field
            component={Control.RenderTextField}
            name="MaterialAdditionalDescription"
            label="Material Additional Description"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="UOM"
            label="UOM"
            list={props.UOMlist}
            onChange={(value) =>
              props.selectUOMGroup
                ? props.selectUOMGroup(value)
                : () => {
                    return;
                  }
            }
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Material"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};


const validate = (values) => {
  const err = {};
  const requiredFields = [
    "MaterialGroupCode",
    "MaterialGroupDescription",
    "MaterialDescription",
    "UOM",
    "Tag"
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "Material",
  validate,
  enableReinitialize: true,
})(Material);
