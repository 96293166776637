import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userLogout } from "../../redux/actions/userAction";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Box, Button } from "@material-ui/core";

import { NavLink, Link } from "react-router-dom";

import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Sidebar from "./sidebar";

import { MaterialSystemNav } from "./sectionsNavbar/MaterialSystemNav";
import { CommonMasterNav } from "./sectionsNavbar/CommonMasterNav";
import { projectHrManagementNav } from "./sectionsNavbar/ProjectHRManagementNav";
import { MachineManagementSystemNav } from "./sectionsNavbar/MachineManagementSystemNav";
import { AccountingSystemNav } from "./sectionsNavbar/AccountingSystemNav";
import { SalesAccountingSystemNav } from "./sectionsNavbar/SalesAccountingSystemNav";
import { WorkforceManagementNav } from "./sectionsNavbar/WorkforceManagementNav";
import { ExecutionSystemNav } from "./sectionsNavbar/ExecutionSystemNav";

import { FNC_COMPANY_NAME } from "../../utils/constants";

import AccessTime from "@material-ui/icons/AccessTime";

import { ReactComponent as WhiteLogo } from "../assets/logo_white.svg";
// import { ReactComponent as RedLogo } from "../assets/newLogo.svg";

import useStyle from "./UseStyle";
import store from "../../redux/store";
import RoleList from "../helpers/RoleList";
import ProjectSelect from "../fixedComponents/ProjectSelect";
import BasicDialog from "../Dialog/BasicDialog";

export default function PersistentDrawerLeft({
  open,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [switchAccount, setSwitchAccount] = useState(false);

  store.subscribe(() => {
    const updatedUser = store.getState().userRoot;
    setUser(updatedUser);
  }, []);

  useEffect(() => {
    const updatedUser = store.getState().userRoot;
    setUser(updatedUser);
    if (updatedUser.workingOn == null) history.replace("/");
  }, [user, history]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onLogOut = (history) => {
    dispatch(userLogout(history));
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const classes = useStyle();
  const theme = useTheme();

  const showAccounts = () => {
    setSwitchAccount(true);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/profile" style={{ textDecoration: "none", color: "black" }}>
          Profile
        </Link>
      </MenuItem>
      <MenuItem onClick={showAccounts}>Switch Account</MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onLogOut(history)}
        >
          Log-Out
        </Button>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {user && user.workingOn && (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Box
              bgcolor={
                user.workingOn.Company === FNC_COMPANY_NAME
                  ? "#47a747"
                  : "#3f51b5"
              }
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                  style={{ outline: "none" }}
                >
                  <MenuIcon />
                </IconButton>
                <NavLink to="/">
                  {user.workingOn.Company === FNC_COMPANY_NAME ? (
                    <Box>
                      <img src="/fncLogo.png" height="50px" />
                    </Box>
                  ) : (
                    <WhiteLogo style={{ height: "3rem" }} />
                  )}
                </NavLink>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    style={{ outline: "none" }}
                  >
                    <Typography
                      className={classes.title}
                      variant="h6"
                      noWrap
                      style={{ color: "#fff" }}
                    >
                      {user.user.first_name} &nbsp;
                    </Typography>
                    <AccountCircle />
                  </IconButton>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </Toolbar>
            </Box>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              {/* <div>
                <RedLogo style={{ width: "12rem" }} />
                <Typography>v{process.env.REACT_APP_VERSION}</Typography>
              </div> */}

              <IconButton
                onClick={handleDrawerClose}
                style={{ outline: "none" }}
              >
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>

            <Divider />
            <List>
              {user.workingOn.Role === RoleList.ADMIN.code && (
                <>
                  <NavLink
                    to="/accessControl/accessRequest"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={handleDrawerClose}
                  >
                    <ListItem>
                      <ListItemIcon>
                        <AccessTime
                          style={{ fontSize: "25px", color: "blue" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Access Control" />
                    </ListItem>
                  </NavLink>
                  <Divider />
                </>
              )}
              <Sidebar nav={CommonMasterNav} closeDrawer={handleDrawerClose} />
              <Sidebar nav={projectHrManagementNav} closeDrawer={handleDrawerClose} />
              <Sidebar
                nav={AccountingSystemNav}
                closeDrawer={handleDrawerClose}
              />
              <Sidebar
                nav={MaterialSystemNav}
                closeDrawer={handleDrawerClose}
              />
              <Sidebar
                nav={MachineManagementSystemNav}
                closeDrawer={handleDrawerClose}
              />
              <Sidebar
                nav={SalesAccountingSystemNav}
                closeDrawer={handleDrawerClose}
              />
              <Sidebar
                nav={ExecutionSystemNav}
                closeDrawer={handleDrawerClose}
              />
              <Sidebar
                nav={WorkforceManagementNav}
                closeDrawer={handleDrawerClose}
              />
            </List>
          </Drawer>
        </div>
      )}
      <BasicDialog
        title={"Switch Account"}
        open={switchAccount}
        setOpen={setSwitchAccount}
        content={
          <ProjectSelect
            isMenu
            handleSwitch={() => {
              setSwitchAccount(false);
              handleMenuClose();
            }}
          />
        }
      />
    </>
  );
}
