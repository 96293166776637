/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style/materialSystem.css";
import PurchaseOrderForm from "../../../forms/MaterialSystem/PurchaseOrder​Form";
import { useHistory } from "react-router-dom";
import { purchaseOrder } from "../../../redux/actions/materialSystemAction";
import pick from "lodash/pick";
import axios from "../../../components/apis/axios";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { allActiveProjects } from "../../../redux/actions/GeneralAction";

let AllVendorData = [];

export const PurchaseOrderFormCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const generalRoot = useSelector((store) => store.GeneralRoot);
  const [list, setList] = useState([]);
  const [vendorNameList, setVendorNameList] = useState([]);
  const [formDetail, setFormDetail] = useState({});
  const [VendorGsts, setVendorGsts] = useState([]);
  const [materials, setMaterials] = useState();
  const [materialsQty, setMaterialsQty] = useState();

  const onSubmit = (formValues) => {
    for (let key in formValues) {
      if (!formValues[key] || formValues[key] === "") {
        delete formValues[key];
      }
    }
    dispatch(purchaseOrder(formValues, history));
  };

  const selectProject = async (project) => {
    const selectedProject = generalRoot.AllProjectName?.find((item) =>
      project.includes(item.ProjectCode)
    );
    const req = {
      requestedFrom: "PurchaseOrder",
      ProjectCodeName: `${selectedProject.ProjectCode} - ${selectedProject?.ProjectShortDescription}`,
    };
    const { data } = await axios.post(
      "/materialManagement/shared/getPurchaseRequest/",
      req
    );
    const mapped = data.map((item) => item.PurchaseRequestNo);
    setList(mapped);

    let payload = {
      ...formDetail,
      ProjectCodeName: project,
      CustomerName: selectedProject.CustomerName,
    };
    setFormDetail(payload);
  };

  const selectPurchaseReq = async (PurchaseRequestNo) => {
    let tempMaterials = null;
    try {
      const { data } = await axios.get(
        `/materialManagement/purchaseRequest/getByPurchaseRequestNo/${PurchaseRequestNo}`
      );
      tempMaterials = data.materials;
      let payload = {
        ...formDetail,
        PurchaseRequestNo: data.PurchaseRequestNo,
        Warehouse: data.WarehouseName,
        FNCGSTNo: data.WarehouseGSTNo,
        Currency: data.Currency,
        Date: data.created_at,
        DeliveryAt: data.DeliveryAt,
      };
      setFormDetail(payload);
    } catch (err) {
      console.error(err);
    }
    try {
      const { data } = await axios.get(
        `/materialManagement/shared/getPurchaseRequestQty/${PurchaseRequestNo}`
      );
      for (let i = 0; i < tempMaterials.length; i++) {
        for (let j = 0; j < data.materials.length; j++) {
          if (tempMaterials[i].MaterialCode === data.materials[j].MaterialCode)
            tempMaterials[i].Quantity = data.materials[j].CurrentQuentity;
        }
      }
      setMaterials(tempMaterials);
      setMaterialsQty(data.materials);
    } catch (err) {
      console.error(err);
    }
  };

  const SelectVendorName = async (VendorName) => {
    const item = AllVendorData.find((item) => item.VendorName === VendorName);
    setFormDetail({
      ...formDetail,
      VendorName: item.VendorName,
    });
    try {
      const response = await axios.get(`/commonMaster/vendor/${item.id}`);
      const gsts = response.data.Gsts;
      console.log(gsts);
      const list = gsts.map((item) => item.GSTRegNo);
      console.log(list);
      setVendorGsts(list);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    async function fetchVendor() {
      try {
        let { data } = await axios.get("/commonMaster/vendor");
        const newData = data.filter((item) => item.Tags === "Active");
        AllVendorData = newData;
        let VNlist = newData.map((item) => item.VendorName);
        setVendorNameList(VNlist);
      } catch (error) {
        alert(error);
      }
    }
    dispatch(allActiveProjects());
    fetchVendor();
  }, []);

  return (
    <div>
      <Breadcrumbs
        name=" Purchase Order  Create"
        linkName1=" Purchase Order  Dashboard"
        to1="/materialManagement/purchaseOrder"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1 rem" }}>
        Purchase Order
      </h3>
      <PurchaseOrderForm
        list={list}
        VNlist={vendorNameList}
        SelectVendorName={SelectVendorName}
        selectProject={selectProject}
        onSubmit={onSubmit}
        selectPurchaseReq={selectPurchaseReq}
        VendorGsts={VendorGsts}
        projects={generalRoot?.AllActiveProjects}
        initialValues={pick(
          formDetail,
          "Warehouse",
          "ProjectCodeName",
          "PurchaseRequestNo",
          "VendorCodeName",
          "VendorGSTNo",
          "FNCGSTNo",
          "Currency",
          "Date",
          "DeliveryAt",
          "CustomerName"
        )}
        materialDataCreate={materials}
        materialsQty={materialsQty}
      />
    </div>
  );
};
