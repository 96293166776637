import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";

import { Button } from "@material-ui/core";

const ChequeRealisationTable = (props) => {
  const [inputFields, setInputFields] = useState([
    {
      ChequeNo: "",
      ChequesDate: "",
      BankPaymentVoucherNo: "",
      Date: "",
      Favoring: "",
      Amount: "",
      RealisedOn: "",
      id: "",
    },
  ]);

  const { cheques } = props;
  useEffect(() => {
    let values = [];
    if (cheques) {
      for (let i = 0; i < cheques.length; i++) {
        values = [
          ...values,
          {
            ChequeNo: cheques[i].ChequeNo,
            ChequesDate: cheques[i].ChequesDate,
            BankPaymentVoucherNo: cheques[i].BankPaymentVoucherNo,
            Date: cheques[i].Date,
            Favoring: cheques[i].Favoring,
            Amount: cheques[i].Amount,
            RealisedOn: cheques[i].RealisedOn,
            id: cheques[i].id,
          },
        ];
      }
      setInputFields(values);
    }
  }, [cheques]);

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    props.tableData(values);
    setInputFields(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        ChequeNo: "",
        ChequesDate: "",
        BankPaymentVoucherNo: "",
        Date: "",
        Favoring: "",
        Amount: "",
        RealisedOn: "",
        id: "",
      },
    ]);
  };

  const { tableData } = props;
  useEffect(() => {
    const onChange = () => {
      if (inputFields) {
        tableData(inputFields);
      } else {
        tableData({ error: true, errorKind: "some input field is empty" });
      }
    };
    onChange();
  }, [inputFields, tableData]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">Cheque No</th>
            <th className="head-cell">Cheques Date</th>
            <th className="head-cell">Bank Payment Voucher No</th>
            <th className="head-cell">Date</th>
            <th className="head-cell">Favoring</th>
            <th className="head-cell">Amount</th>
            <th className="head-cell">Realised On</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">
                <input
                  type="text"
                  name="ChequeNo"
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "8rem" }}
                />
              </td>
              <td className="table-data2">
                <input
                  type="text"
                  name="ChequesDate"
                  value={inputField.ChequesDate}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "6rem" }}
                />
              </td>
              <td className="table-data3">
                <input
                  type="text"
                  name="BankPaymentVoucherNo"
                  value={inputField.BankPaymentVoucherNo}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "auto" }}
                />
              </td>
              <td className="table-data3">
                <input
                  type="text"
                  name="Date"
                  value={inputField.Date}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "6rem" }}
                />
              </td>
              <td className="table-data3">
                <input
                  type="text"
                  name="Favoring"
                  value={inputField.Favoring}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "20rem" }}
                />
              </td>
              <td className="table-data3">
                <input
                  type="number"
                  name="Amount"
                  value={inputField.Amount}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "auto" }}
                />
              </td>
              <td className="table-data4">
                <input
                  type="text"
                  name="RealisedOn"
                  value={inputField.RealisedOn}
                  onChange={(event) => handleChangeInput(index, event)}
                  style={{ width: "auto" }}
                />
              </td>
              <td>
                {index === 0 ? (
                  <IconButton>
                    <RemoveCircleIcon className="width5" color="disabled" />
                  </IconButton>
                ) : (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          ADD Row
        </Button>
      </div>
    </div>
  );
};

export default ChequeRealisationTable;
