import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { AutoCompleteTableInput } from "../../components/formcomponents/AutoCompleteTableInput";
import LinearProgress from "@material-ui/core/LinearProgress";

const LogSheetEntryActivityHours = ({
  tableEditFields,
  tableData,
  ActivityList,
  ActivityDetailList,
  isEdit,
  fetchingActivities,
}) => {
  const [inputFields, setInputFields] = useState();
  const [listOfActivities, setListOfActivities] = useState(null);
  const [listOfActivitieDetail, setListOfActivitieDetail] = useState(null);

  useEffect(() => {
    if (!isEdit) {
      setInputFields([
        {
          SerialNo: "",
          ActivityCodeName: "",
          ActivityUOM: "",
          WorkingHour: "",
          WorkDoneQty: "",
        },
      ]);
    } else if (isEdit && tableEditFields) {
      console.log("here", tableEditFields);
      setInputFields([...tableEditFields]);
      onChange([...tableEditFields]);
    }
    // eslint-disable-next-line
  }, [tableEditFields, isEdit]);

  useEffect(() => {
    if (ActivityList) {
      setListOfActivities(ActivityList);
      setListOfActivitieDetail(ActivityDetailList);
    }
  }, [ActivityList, ActivityDetailList]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    onChange(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        SerialNo: "",
        ActivityCodeName: "",
        ActivityUOM: "",
        WorkingHour: "",
        WorkDoneQty: "",
        id: "new",
      },
    ]);
  };

  const onActivitySelect = (value, index, fieldName) => {
    let tdata = inputFields;
    let row = tdata[index];
    try {
      if (value !== null) {
        row[fieldName] = value;
        const list = listOfActivities;
        const indexOfValue = list.indexOf(value);
        list.splice(indexOfValue, 1);
        const listDetails = listOfActivitieDetail;
        row["ActivityUOM"] = ActivityDetailList[indexOfValue].UOM;
        const indexOfDetailsValue = listDetails.indexOf(value);
        listDetails.splice(indexOfDetailsValue, 1);
        setListOfActivitieDetail([...listOfActivitieDetail]);
        setListOfActivities([...list]);
      } else {
        setListOfActivities([...listOfActivities, row[fieldName]]);
        setListOfActivitieDetail([
          ...listOfActivitieDetail,
          row["ActivityUOM"],
        ]);
        row[fieldName] = "";
      }
    } catch (err) {
      console.error(err);
    } finally {
      tdata[index] = row;
      setInputFields(tdata);
      tableData(tdata);
    }
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  const onChange = (values) => {
    if (values) {
      tableData(values);
    } else {
      tableData({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  return (
    <>
      {!fetchingActivities && inputFields ? (
        <div className="tableContainer">
          <table>
            <thead className="table-head">
              <tr>
                <th className="head-cell">SerialNo.</th>
                <th className="head-cell">Activity Code Name</th>
                <th className="head-cell">Activity UOM</th>
                <th className="head-cell">Working Hour</th>
                <th className="head-cell">Work Done Qty</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField, index) => (
                <tr key={index}>
                  <td className="table-data1">
                    {inputField.SerialNo || index + 1}
                  </td>
                  <td className="table-data2">
                    {listOfActivities && (
                      <AutoCompleteTableInput
                        list={listOfActivities}
                        inputChange={(e) =>
                          onActivitySelect(e, index, "ActivityCodeName")
                        }
                        tableIndex={index}
                        fieldName={"ActivityCodeName"}
                        value={inputField.ActivityCodeName}
                      />
                    )}
                  </td>
                  <td className="table-data2">
                    <input
                      type="text"
                      style={{ width: "10rem" }}
                      name="UOM"
                      value={inputField.ActivityUOM}
                    />
                  </td>
                  <td className="table-data2">
                    <input
                      type="number"
                      style={{ width: "10rem" }}
                      name="WorkingHour"
                      value={inputField.WorkingHour}
                      onChange={(event) => handleChangeInput(index, event)}
                    />
                  </td>
                  <td className="table-data4">
                    <input
                      type="number"
                      style={{ width: "10rem" }}
                      name="WorkDoneQty"
                      value={inputField.WorkDoneQty}
                      onChange={(event) => handleChangeInput(index, event)}
                    />
                  </td>
                  <td>
                    {index === 0 ? (
                      <IconButton>
                        <RemoveCircleIcon className="width5" color="disabled" />
                      </IconButton>
                    ) : (
                      <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                        <RemoveCircleIcon
                          className="width5"
                          color="secondary"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="middle">
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="contained"
              color="primary"
            >
              <AddCircleIcon />
              ADD Row
            </Button>
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};
export default LogSheetEntryActivityHours;
