import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import ScrutinySubContractorTable from "../../../Tables/AccountingSystem/SubContractorTable";
import * as Control from "../../../components/formcomponents/control";
import "../../../components/helpers/form.style.css";
import SubmitModelPopup from "../../../components/modals/SubmitModelPopup";
import AccountingSystemFormHeader from "../../../components/AccountingSystemDisplay/AccountingSystemFormHeader";

const ScrutinySubContractorForm = (props) => {
  const [state, setState] = useState({
    tableErrorKind: "",
    tableError: false,
    tableData: null,
  });
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const onSubmit = (formValues) => {
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    props.onSubmit({
      ...formValues,
      paymentDetails: state.tableData,
    });
  };

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <AccountingSystemFormHeader
          handleHeaderChange={props?.handleHeaderChange}
          CostShow={true}
        />
        <div className="field-container">
          {props.user.workingOn.Role === "accountAndAdminHead" ? (
            <Field
              name="ProjectCodeName"
              label="Project Code"
              component={Control.AutoCompleteInput}
              className="text-field"
              list={props.ProjectCodeName}
              onChange={(value) =>
                props.selectProjectbyRole && props.selectProjectbyRole(value)
              }
            />
          ) : (
            <Field
              name="ProjectCodeName"
              label="Project Code"
              component={Control.AutoCompleteInput}
              className="text-field"
              list={props.list}
              onChange={(value) =>
                props.selectProject && props.selectProject(value)
              }
            />
          )}

          <Field
            name="CustomerName"
            label="Customer Name"
            disabled
            component={Control.RenderTextField}
          />
          <Field
            name="VendorCodeName"
            label="Vendor Code/Name"
            component={Control.AutoCompleteInput}
            className="text-field"
            list={props.vendorNameList}
            // onChange={(value) =>
            //   props.onVendorChange && props.onVendorChange(value)
            // }
          />
          {/* <Field
            name="FromDate"
            label="From Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues.FromDate}
            onChange={(value) =>
              props.onFromDateChange && props.onFromDateChange(value)
            }
          />
          <Field
            name="ToDate"
            label="To Date"
            component={Control.RenderDateField}
            initialValue={props.initialValues.ToDate}
            onChange={(value) =>
              props.onToDateChange && props.onToDateChange(value)
            }
          /> */}
        </div>

        <div className="excel">
          <ScrutinySubContractorTable tableData={tableData} />
        </div>
        <div className="middle">
          <p className=" Mui-error excelError">
            {state.tableError ? `${state.tableErrorKind}` : ""}
          </p>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Scrutiny Sub-Contractor"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "CostCenter",
    "FinancialPeriod",
    "Currency",
    "ProjectCode",
    // "CustomerName",
    "VendorCodeName",
    // "FromDate",
    // "ToDate",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

export default reduxForm({
  form: "ScrutinySubContractorForm",
  validate,
  enableReinitialize: true,
})(ScrutinySubContractorForm);
