import React from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const MajorSCItemGroup = (props) => {
  const [show, setShow] = React.useState(false);

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  const toggleModal = () => setShow(!show);
  //   {
  //    "MajorSCItemGroupDescription": "innovacio",
  //         "UOM": "test",
  //         "GroupCategory": "test",
  //         "SCItemTag": "test",
  //         "Status": "test",
  //         "Tags": "Active"

  // }
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.RenderTextField}
            name="MajorSCItemGroupCodeDescription"
            label="Major SC Item Group Code"
          />
          <Field
            component={Control.RenderTextField}
            name="Status"
            label="Status"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="UOM"
            label="UOM"
            list={props.UOM}
            className="text-field"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="GroupCategory"
            label="Group Category"
            className="text-field"
            list={[" ", "Regular", "Service", "Machine"]}
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Major SCItem Tag"
            className="text-field"
            list={[" ", "Active", "Inactive"]}
          />
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="majorSCItemGroup"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "MajorSCItemGroupCode",
    "MajorSCItemGroupDescription",
    "UOM",
    "GroupCategory",
    "SCItemTag",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });

  return err;
};
export default reduxForm({
  form: "majorSCItemGroup",
  validate,
  enableReinitialize: true,
})(MajorSCItemGroup);
