import React, { useState, useEffect } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";


const SalesOrderStageTable = ({ stageTermsTable, stage }) => {
  const [inputFields, setInputFields] = useState([
    {
      SerialNo: 1,
      StagePercentage: "",
      StageName: "",
      StageDescription: "",
      id: "new",
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (stage) {
      setInputFields([...stage]);
      onChange([...stage]);
    }
    // eslint-disable-next-line
  }, [stage]);

  const onChange = (values) => {
    if (values) {
      stageTermsTable(values);
    } else {
      stageTermsTable({
        error: true,
        errorKind: "some input field is empty",
      });
    }
  };

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    let sum = 0;
    console.log(values);
    for (let i = 0; i < values.length; i++) {
      sum = sum + +values[i].StagePercentage;
      if (sum > 100) {
        values[i].StagePercentage = 0;
        enqueueSnackbar("Payment percentage cannot be greater than 100", {
          variant: "warning",
        });
      }
    }
    setInputFields(values);
    onChange(values);
  };

  const handleAddFields = (e) => {
    e.preventDefault();
    setInputFields([
      ...inputFields,
      {
        No: "",
        StagePercentage: "",
        StageDescription: "",
        StageName: "",
        id: "",
      },
    ]);
  };

  const handleRemoveFields = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values.splice(index, 1);
    onChange(values);
    setInputFields(values);
  };

  return (
    <div className="tableContainer">
      <table>
        <thead className="table-head">
          <tr>
            <th className="head-cell">SerialNo.</th>
            <th className="head-cell">Stage Code</th>
            <th className="head-cell">Stage Percentange</th>
            <th className="head-cell">Stage Description</th>
          </tr>
        </thead>
        <tbody>
          {inputFields.map((inputField, index) => (
            <tr key={index}>
              <td className="table-data1">{index + 1}</td>
              <td className="table-data2">
                <input
                  type="text"
                  style={{ width: "10rem" }}
                  name="StageName"
                  value={inputField.StageName}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data3">
                <input
                  type="number"
                  style={{ width: "15rem" }}
                  name="StageDescription"
                  value={inputField.StageDescription}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td className="table-data4">
                <input
                  type="number"
                  style={{ width: "15rem" }}
                  name="StagePercentage"
                  value={inputField.StagePercentage}
                  onChange={(event) => handleChangeInput(index, event)}
                />
              </td>
              <td>
                {index >= 0 ? (
                  <IconButton onClick={(e) => handleRemoveFields(e, index)}>
                    <RemoveCircleIcon className="width5" color="secondary" />
                  </IconButton>
                ) : ""
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="middle">
        <Button
          onClick={(e) => handleAddFields(e)}
          variant="contained"
          color="primary"
        >
          <AddCircleIcon />
          ADD Row
        </Button>
      </div>
    </div>
  );
};
export default SalesOrderStageTable;
