import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import useStyle from "./UseStyle";
import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import moment from "moment";
import { getShiftById } from "../../../redux/actions/CommonMasterAction";

export const ShiftsView = (props) => {
  const [data, setData] = useState({});
  const [, setPost] = useState("");
  const store = useSelector((store) => store);
  const { match } = props;

  useEffect(() => {
    if (store.userRoot.user) {
      setPost(store.userRoot.user.position_title);
    }
  }, [store.userRoot]);

  useEffect(() => {
    if(!match.params.id) return;
    const fetch = async () => {
        try {
            const data = await getShiftById(match.params.id);
            setData(data);
        } catch (error) {
            // 
        }
    }
    fetch();
  }, [match.params.id]);

  const classes = useStyle();

  return (
    <>
      <Breadcrumbs
        name="Shift View"
        linkName1="/commonMaster/shifts"
        to1="Shift Dashboard"
      />
      <Paper className={classes.Container}>
        <div className="">
          <h3 className={classes.Heading}>Shift</h3>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            {data.shift_code && (
              <Paper className={classes.paper}>
                <h6>Shift Code:</h6>
                <p>{data.shift_code}</p>
              </Paper>)}

              {data.tag && (
              <Paper className={classes.paper}>
                <h6>Tag:</h6>
                <p>{data.tag}</p>
              </Paper>)}

              {data.shift_in_Time && (
              <Paper className={classes.paper}>
                <h6>Shift In Time:</h6>
                <p>{moment(data.shift_in_Time).format('hh:mm a')}</p>
              </Paper>)}

              {data.shift_out_Time && (
              <Paper className={classes.paper}>
                <h6>Shift Out Time:</h6>
                <p>{moment(data.shift_out_Time).format('hh:mm a')}</p>
              </Paper>)}
          </div>
        </Box>
      </Paper>
    </>
  );
};
