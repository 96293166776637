import React, { useEffect, useState } from "react";
import axios from "../../../components/apis/axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useStyles from "../../../components/helpers/UseStyle";
import { format } from "date-fns";

const ReportAdvanceStatementDetails = ({ details }) => {
  const [advanceDetails, setAdvanceDetails] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (details) {
      const getData = async () => {
        const { data } = await axios.post(
          "/AccountingSystem/Reports/advance/advanceDetails",
          {
            Name: details.Name,
          }
        );
        console.log(data);
        setAdvanceDetails(data);
      };
      getData();
    }
  }, [details]);

  return (
    <div>
      {advanceDetails ? (
        <>
          <div className={classes.ButtonGrp}>
            <Paper className={classes.paper}>Name: {details.Name}</Paper>
            <Paper className={classes.paper}>
              Project Code Name: {details.ProjectCodeName}
            </Paper>
          </div>
          <TableContainer className={classes.table} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nature of Expense</TableCell>
                  <TableCell align="center">Bill Document Date</TableCell>
                  <TableCell align="center">Bill Document Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advanceDetails.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {item.NatureOfExprensesOrAccounting}
                    </TableCell>
                    <TableCell align="center">
                      {format(new Date(item.BillDocumentDate), "d MMM yyyy")}
                    </TableCell>
                    <TableCell align="center">
                      {item.BillDocumentAmount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        "loading"
      )}
    </div>
  );
};

export default ReportAdvanceStatementDetails;
