import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import useStyle from "./UseStyle";
import axios from "../../../components/apis/axios";
import { userLogout } from "../../../redux/actions/userAction";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { approvalExecutionSystem } from "../../../redux/actions/ExecutionSystem";
import ApprovalAndRejectModal from "../../../components/modals/ApprovalAndRejectModal";
import { Button } from "@material-ui/core";
import { DailyProgressActivityTableView } from "../../../Tables/ExecutionSystem/View/DailyProgressActivityTableView";
import { FileListArea } from "../../../components/formcomponents/control";
import ComapnyHeader from "../../../components/CompanyHeader/CompanyHeader";
import { format } from "date-fns";
import RoleList from "../../../components/helpers/RoleList";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

export const DailyProgressReportView = (props) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [post, setPost] = useState("");
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { match } = props;
  const [showModal, setShowModal] = useState(false);
  const [isApprove, setIsApprove] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get(
          `/executionSystem/dailyProgressReport/${match.params.id}`
        );
        console.log("dailyProgressReport ->", data);
        setData(data);
      } catch (err) {
        dispatch(userLogout(history));
      }
    }
    fetch();
  }, [dispatch, history, match.params.id]);

  useEffect(() => {
    if (store.userRoot) {
      setPost(store.userRoot.workingOn.Role);
    }
  }, [store.userRoot]);
  const classes = useStyle();

  const handleModalAction = (data) => {
    dispatch(
      approvalExecutionSystem(
        "dailyProgressReport",
        match.params.id,
        history,
        data
      )
    );
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div ref={componentRef}>
      <BreadCrumbs
        name="Daily Progress Report View"
        linkName1="Daily Progress Report Dashboard"
        to1="/executionSystem/dailyProgressReport"
      />
      <Paper className={classes.Container}>
        <ComapnyHeader />
        <div>
          <h3 className={classes.Heading}>Daily Progress Report </h3>
          <div id="print-btn" className="text-right">
            <button
              className="btn btn-warning"
              type="button"
              onClick={handlePrint}
            >
              <PrintIcon />
            </button>
          </div>
          <h6>{`Created by ${
            data.created_by &&
            ` ${data.created_by.first_name} ${data.created_by.last_name}`
          }`}</h6>
          <h6 className="text-right">{`Created Date : ${
            data.created_by && ` ${data.created_at}`
          }`}</h6>
        </div>
        <Box border={1} borderRadius={16} className={classes.Box}>
          <div className={classes.boxInner}>
            <Paper className={classes.paper}>
              <h6>DailyProgressReportNo:</h6>
              <p>{data.DailyProgressReportNo}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>CustomerName:</h6>
              <p>{data.CustomerName}</p>
            </Paper>
            <Paper className={classes.paper}>
              <h6>ProjectCodeName:</h6>
              <p>{data.ProjectCodeName}</p>
            </Paper>
            {data.ProgressDate && (
              <Paper className={classes.paper}>
                <h6>ProgressDate:</h6>
                <p>{format(new Date(data.ProgressDate), "d MMM yyyy")}</p>
              </Paper>
            )}
          </div>
          <DailyProgressActivityTableView
            DailyProgressReportSub={data.dailyProgressReportSub}
          />
        </Box>
        {data.Files && (
          <Paper className={classes.paper}>
            <h6>Files:</h6>
            <FileListArea fileList={data.Files} />
          </Paper>
        )}
        <div className={classes.row}>
          {data.Comment && (
            <Paper className={classes.paper}>
              <h6 style={{ color: "red" }}>Comment:</h6>
              <p>{data.Comment}</p>
            </Paper>
          )}
        </div>
      </Paper>
      {(post === RoleList.ADMIN.code || post === RoleList.SITE_INCHARGE.code) &&
        data.Status === "not_reviewed" && (
          <div className={classes.ButtonGrp}>
            <Button
              variant="contained"
              className={classes.approve}
              onClick={() => {
                setIsApprove(true);
                toggleModal();
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={classes.reject}
              onClick={() => {
                setIsApprove(false);
                toggleModal();
              }}
            >
              Reject
            </Button>
          </div>
        )}
      <ApprovalAndRejectModal
        showModal={showModal}
        toggleModal={toggleModal}
        handleModalAction={handleModalAction}
        isApprove={isApprove}
        formName={"Daily Progress Report"}
      />
    </div>
  );
};
