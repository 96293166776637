import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/CommonMaster/CostCenterGSTTable";
import "../../components/helpers/form.style.css";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";

const CostCentre = (props) => {
  const [state, setState] = useState({
    tableData: null,
    tableError: false,
    tableErrorKind: "",
    isSubmit: false,
  });
  const [show, setShow] = useState(false);

  const tableData = (data) => {
    if (!data) {
      setState({
        ...state,
        tableErrorKind: "table row are not fully field",
        tableError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableErrorKind: "",
        tableError: false,
      });
    }
  };
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    if (!state.tableData) {
      setState({ tableError: true });
    } else if (state.tableData) {
      props.onSubmit({
        ...formValues,
        tableData: state.tableData,
      });
    }
  };
  console.log(show);
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="CompanyName"
            label="Company Name"
            list={props.companyDetail}
            className="text-field"
            onChange={(value) => props.findGst(value)}
          />
          <Field
            component={Control.RenderTextField}
            name="CostCenterName"
            label="Cost Center Name"
          />
          <Field
            component={Control.RenderTextField}
            name="ProjectSiteAddress"
            label="Project Site Address"
          />
          <Field
            component={Control.AutoCompleteInput}
            name="Tags"
            label="Tag"
            list={["", "Active", "Inactive"]}
            className="text-field"
          />
        </div>
        <div className="Active">
          <Table
            tableData={tableData}
            Companygsts={props.companyGsts}
            costcenteredit={props.costcenteredit}
            Gst={props.Gst}
            isEdit={props.edit}
            gsts={props.gsts}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableError ? `${state.tableErrorKind}` : ""}
            </p>
          </div>
        </div>
      </form>
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Cost Centre"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectName",
    "CostCenterName",
    "ProjectSiteAddress",
    "GSTRegNo",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });
  return err;
};
export default reduxForm({
  form: "CostCentre",
  validate,
  enableReinitialize: true,
})(CostCentre);
