import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import * as Control from "../../components/formcomponents/control";
import Table from "../../Tables/ProjectWorkforceManagement/DailyAttendanceFormTable";
import SubmitModelPopup from "../../components/modals/SubmitModelPopup";
import "../../components/helpers/form.style.css";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const DailyAttendance = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    isSubmit: false,
    tableData: null,
    tableDataErrorKind: "",
    tableDataError: false,
  });
  const [workmenType, setWorkmenType] = useState("");
  //file upload
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const storeUploadedFiles = (data) => {
    setUploadedFiles(data);
  };

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const onSubmit = (formValues) => {
    // console.log(JSON.stringify(formValues));
    if (uploadedFiles) {
      const files = uploadedFiles.map((item) => item.id);
      formValues = {
        ...formValues,
        files,
      };
    }
    if (!state.tableData) {
      setState({ ...state, tableDataError: true });
    } else if (state.tableData) {
      props.onSubmit({
        ...formValues,
        tableData: state.tableData,
      });
    }
  };

  const tableData = (data) => {
    console.log("tableData==>", data);
    if (!data) {
      setState({
        ...state,
        tableDataErrorKind: "table row are not fully field",
        tableDataError: true,
      });
    } else {
      setState({
        ...state,
        tableData: data,
        tableDataErrorKind: "",
        tableDataError: false,
      });
    }
  };
  return (
    <div className="newformcontainer">
      <form className="form-main">
        <div className="field-container">
          <Field
            component={Control.AutoCompleteInput}
            name="ProjectCodeName"
            label="Project Code /Name"
            list={props.list}
            className="text-field"
            onChange={(value) =>
              props.selectProjectName
                ? props.selectProjectName(value)
                : () => {
                    return;
                  }
            }
          ></Field>
          <Field
            component={Control.AutoCompleteInput}
            name="CostCentre"
            label="Cost Centre"
            list={props.CCList}
            className="text-field"
          ></Field>
          <Field
            component={Control.RenderTextField}
            name="CustomerName"
            label="Customer Name"
            disabled
          ></Field>
          <Field
            component={Control.RenderSelectField}
            name="WorkmenType"
            label="Workmen Type"
            onChange={(value) => setWorkmenType(value)}
          >
              <option value={""}></option>
            <option value={"Departmental"}>Departmental</option>
            <option value={"SubContractor"}>Sub-Contractor</option>
          </Field>
          {workmenType === "SubContractor" && (
            <>
              <Field
                component={Control.AutoCompleteInput}
                name="VendorCode"
                list={props.VNList}
                label="Vendor Code / Name"
                className="text-field"
              />
              <Field
                component={Control.RenderTextField}
                name="WorkOrderNo"
                label="Work Order No"
              />
              <Field
                component={Control.RenderDateAllField}
                name="WorkOrderDate"
                label="Work Order Date"
                // initialValue={props.initialValues.WorkOrderDate}
              />
            </>
          )}
        </div>

        <div className="excel">
          <Table
            tableData={tableData}
            tables={props.tables}
            shiftCode={props.shiftCode}
            machineList={props.machineList}
            activityList={props.activityList}
            workmenList={props.workmenList}
            shiftList={props.shiftlist}
          />
          <div className="middle">
            <p className=" Mui-error excelError">
              {state.tableDataError ? `${state.tableDataErrorKind}` : ""}
            </p>
          </div>
        </div>
        <div>
          <Field
            component={Control.UploaderV2}
            name="UploadFiles"
            label="Upload Files​"
            storeUploadedFiles={storeUploadedFiles}
            previousFiles={props.previousFiles}
          />
        </div>
        {/* <div style={{ marginLeft:'80px' }}>
        <Button variant="contained"
          color="primary"
          type="submit" onClick={Handler}>Over Time Entry</Button>
        </div> */}
      </form>
    
      <SubmitModelPopup
        handleSubmitForm={props.handleSubmit(onSubmit)}
        formTitle="Daily Attendance"
        onFormClick={toggleModal}
        open={show}
        onClose={toggleModal}
        closeAfterTransition
      />
    </div>
  );
};

const validate = (values) => {
  const err = {};
  const requiredFields = [
    "ProjectCodeName",
    "WorkOrderNo",
    "VendorCode",
    "CostCentre",
    "WorkmenType",
    "CustomerName",
    "Date",
  ];
  requiredFields.forEach((requiredField) => {
    if (!values[requiredField]) {
      err[requiredField] = "required";
    }
  });
  return err;
};

export default reduxForm({
  form: "dailyAttendance",
  validate,
  enableReinitialize: true,
})(DailyAttendance);
