import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { userSetWorkingOn } from "../../redux/actions/userAction";
import { userLogout } from "../../redux/actions/userAction";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { setUserProjectAndPosition } from "../../redux/helper/setUserProjectandPosition";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "2rem",
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  li: {
    width: "100%",
    maxWidth: 380,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    padding: "20px",
    margin: "20px auto",
    alignContent: "center",
    justifyItems: "center",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  cardRoot: {
    maxWidth: 345,
    marginBottom: "10px",
  },
  Button: {
    position: "relative",
    padding: "20px auto",
    margin: "20px auto",
  },
}));

const ProjectSelect = ({ handleSwitch, isMenu = false }) => {
  const classes = useStyles();
  const user = useSelector((store) => store.userRoot);
  const history = useHistory();
  const dispatch = useDispatch();

  const onWorkingOnSelect = (data) => {
    dispatch(userSetWorkingOn(data));
    setUserProjectAndPosition(data);

    if (handleSwitch) {
      handleSwitch();
    }

    history.push("/dashboard");
  };

  const onLogOut = (history) => {
    dispatch(userLogout(history));
  };

  return (
    <>
      <Container>
        <div className={classes.root}>
          {!isMenu && (
            <Typography
              style={{
                fontWeight: "700",
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              Welcome Back, {user.user.first_name} {user.user.last_name}
            </Typography>
          )}
          <Typography variant="h4" align="center">
            Login As
          </Typography>
          <List className={classes.li} subheader={<li />}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                {user.user.working_on.map((item, index) => (
                  <Card
                    className={classes.cardRoot}
                    onClick={() => onWorkingOnSelect(item)}
                    key={index}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {item?.ProjectName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {item?.BusinessUnit}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          component="p"
                        >
                          {item?.Role}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </ul>
            </li>
          </List>
        </div>
        {!isMenu && (
          <div className="middle">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onLogOut(history)}
            >
              <ExitToAppIcon />
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default ProjectSelect;
