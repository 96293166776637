import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../../components/apis/axios";
import pick from "lodash/pick";
import SalesOrderAmendmentForm from "../../../forms/SalesAccountingSystem/SalesOrderAmendmentForm";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";

let allSalesOrder = [];
let allCompany = [];

export const SalesOrderAmendmentEdit = (props) => {
  const history = useHistory();
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [salesOrderAmendment, setSalesOrderAmendment] = useState({});
  const [orderValue, setOrderValue] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [companyGsts, setCompanyGsts] = useState([]);
  const [uom, setUom] = useState(null);
  const [soTable, setSoTable] = useState([]);
  const [retentions, setRetentions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [previousFiles, setPreviousFiles] = useState();

  const onSubmit = async (formValues) => {
    console.log(JSON.stringify(formValues));
    for (var key in formValues) {
      if (!formValues[key]) {
        delete formValues[key];
      }
    }
    const response = await axios.put(
      `/salesAccounting/salesOrderAmendment/${props.match.params.id}`,
      formValues
    );
    console.log(response);
    history.push("/salesAccounting/salesOrderAmendment/");
  };

  async function selectSalesOrder(salesOrder) {
    console.log(allSalesOrder, salesOrder);
    let detail = allSalesOrder.find((item) => item.SalesOrderNo === salesOrder);
    console.log("detail", detail);
    if (detail) {
      try {
        const { data } = await axios.get(
          `/salesAccounting/salesOrder/${detail.id}`
        );
        console.log("data->", data);
        let salesOrder;
        let payload = {
          OrderValue: data.OrderValue,
        };
        if (data.amendment) {
          salesOrder = data.amendment;
        } else salesOrder = data;

        console.log(salesOrder);
        setSoTable(salesOrder.materials);
        setRetentions(salesOrder.retentions);
        setPayments(salesOrder.payments);

        payload = {
          ...payload,
          LastAmendmentValue: salesOrder?.OrderValue,
          LastAmendmentNo: salesOrder?.SalesOrderAmendmentNo?.slice(13),
          LastAmendmentDate: salesOrder?.created_at,
          SalesOrderNo: salesOrder?.SalesOrderNo,
          ProjectCodeName: salesOrder?.ProjectCodeName,
          CustomerName: salesOrder?.CustomerName,
          CompanyName: salesOrder?.CompanyName,
          OrderReferenceNo: salesOrder?.OrderReferenceNo,
          OrderReferenceDate: salesOrder?.OrderReferenceDate,
          OrderType: salesOrder?.OrderType,
          AdvanceBG: salesOrder?.AdvanceBG,
          PerformanceBG: salesOrder?.PerformanceBG,
          Insurance: salesOrder?.Insurance,
          CustomerGSTNo: salesOrder?.CustomerGSTNo,
          CompanyGstNo: salesOrder?.CompanyGstNo,
          OrderCategory: salesOrder?.OrderCategory,
          LDClause: salesOrder?.LDClause,
          PriceVariationClause: salesOrder?.PriceVariationClause,
          ProjectStartDate: salesOrder?.ProjectStartDate,
          ProjectEndDate: salesOrder?.ProjectEndDate,
          RevisedDuration: Math.round(
            (new Date(salesOrder?.ProjectEndDate) -
              new Date(salesOrder?.ProjectStartDate)) /
              (1000 * 60 * 60 * 24 * 30)
          ),
        };
        setOrderValue(detail.ProjectOrderValue);
        setSalesOrderAmendment({
          ...salesOrderAmendment,
          ...payload,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const selectCompany = async (company) => {
    if (company) {
      let detail = allCompany.find((item) => item.CompanyName === company);
      try {
        const { data } = await axios.get(
          `/commonMaster/companyDetails/${detail.id}`
        );
        const gsts = data.Gsts.map((item) => item.GSTRegNo);
        setCompanyGsts(gsts);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.get("salesAccounting/salesOrder/");
        let filteredData = data.filter((item) => item.Status === "accepted");
        const mapped = filteredData.map((item) => item.SalesOrderNo);
        setSalesOrderList(mapped);
        allSalesOrder = filteredData;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    async function getUOM() {
      try {
        const { data } = await axios.get("/commonMaster/unitOfMeasurement");
        console.log(data);
        setUom(data);
      } catch (err) {
        console.error(err);
      }
    }
    async function getCompanyDetails() {
      try {
        const { data } = await axios.get("/commonMaster/companyDetails");
        const filteredData = data.map((item) => item.CompanyName);
        setCompanyList(filteredData);
        allCompany = data;
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
    getUOM();
    getCompanyDetails();
  }, []);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await axios.get(
          `/salesAccounting/salesOrderAmendment/${props.match.params.id}`
        );
        let sales_order_amendment = response.data;
        console.log("sales_order_amendment ->", sales_order_amendment);
        setSalesOrderAmendment(sales_order_amendment);
        setSoTable(sales_order_amendment.materials);
        setRetentions(sales_order_amendment.retentions);
        setPayments(sales_order_amendment.payments);
        setPreviousFiles([...sales_order_amendment.Files]);
        const { data } = await axios.get("salesAccounting/salesOrder/");
        let filteredData = data.map((item) => item.SalesOrderNo);
        setSalesOrderList(filteredData);
        allSalesOrder = data;
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
    fetch();
  }, [props.match.params.id]);

  return (
    <div>
      <BreadCrumbs
        name="Sales Order Amendment Edit"
        linkName1="Sales Order Amendment Dashboard"
        to1="/salesAccounting/salesOrderAmendment"
      />
      <h3 style={{ textAlign: "center", margin: "1rem 1rem 0 1rem" }}>
        Sales Order Amendment Edit
      </h3>
      <SalesOrderAmendmentForm
        onSubmit={onSubmit}
        SalesOrderList={salesOrderList}
        selectSalesOrder={selectSalesOrder}
        companyList={companyList}
        selectCompany={selectCompany}
        companyGsts={companyGsts}
        orderValue={orderValue}
        uom={uom}
        soTable={soTable}
        retentions={retentions}
        payments={payments}
        edit={true}
        previousFiles={previousFiles}
        initialValues={pick(
          salesOrderAmendment,
          "OriginalFormNo",
          "SalesOrderNo",
          "ProjectCodeName",
          "CustomerName",
          "CompanyName",
          "OrderReferenceNo",
          "OrderReferenceDate",
          "OrderType",
          "Insurance",
          "CustomerGSTNo",
          "CompanyGstNo",
          "ProjectOrderValue",
          "LDClause",
          "PriceVariationClause",
          "LastAmendmentNo",
          "LastAmendmentDate",
          "LastAmendmentValue",
          "OrderValue",
          "ProjectStartDate",
          "ProjectEndDate",
          "RevisedDuration",
          "AdvanceBG"
        )}
      />
    </div>
  );
};
