import { Chip, createStyles, makeStyles, Paper } from "@material-ui/core";
import React from "react";

export const FileListArea = ({
  input,
  deleteFiles,
  fileList,
  // meta: { touched, invalid, error },
  ...custom
}) => {
  // const [fileObjects, setFileObjects] = React.useState([]);
  // const { onChange, ...rest } = input;
  // console.log(fileList);
  // setFileObjects(fileList);

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
      },
      chip: {
        margin: theme.spacing(0.5),
      },
    })
  );

  // const handleDelete = (chipToDelete) => () => {
  //   let newData = fileObjects.filter(
  //     (file) => file.file.name !== chipToDelete.file.name
  //   );
  //   setFileObjects(newData);
  // };
  // function handleChange(files) {
  //   this.setState({
  //     files: files,
  //   });
  // }

  const classes = useStyles();

  return (
    <div>
      <Paper component="ul" className={classes.root}>
        {fileList.map((data) => {
          return (
            data && (
              <li key={data.name}>
                <Chip
                  component={"a"}
                  href={data.path}
                  target={"_blank"}
                  label={data.name}
                  size={"medium"}
                  clickable
                  // onDelete={(data) => {
                  //   console.log(data);
                  //   deleteFiles(data);
                  //   handleDelete(data);
                  // }}
                  className={classes.chip}
                />
              </li>
            )
          );
          // console.log(data);
        })}
      </Paper>
    </div>
  );
};
