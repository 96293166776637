import { makeStyles } from "@material-ui/core/styles";
const useStyle = makeStyles({
  Container: {
    width: "90%",
    margin: "80px auto 0 auto",
    padding: "1rem",
  },
  Container2: {
    width: "100%",
    margin: "80px auto 0 auto",
    padding: "1rem",
  },
  Heading: {
    margin: "1rem auto",
    textAlign: "center",
  },
  Box: {
    padding: "0.5rem",
    margin: "1rem",
  },
  paper: {
    margin: "0.5rem 0rem",
    backgroundColor: "white",
    border: "1px solid #000",
    padding: "15px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& p": {
      marginRight: "0.5rem ",
      display: "inline",
    },
    "& h6": {
      marginRight: "0.5rem",
      display: "inline",
      color: "#2c387e",
    },
    "& span": {
      margin: "0.5rem",
    },
  },
  ButtonGrp: {
    margin: "2rem 0",
    display: "flex",
    justifyContent: "space-evenly",
  },
  approve: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "white",
    background:
      "linear-gradient(to right top, #051937, #00466b, #00799a, #00afbf, #55e7d8)",
  },
  reject: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "white",
    background:
      "linear-gradient(to right top, #f3e879, #fcc866, #fea85f, #fa8962, #ed6b6b)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  yesApprove: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "black",
    background: "#0CE90B",
  },
  NoApprove: {
    borderRadius: "15px",
    padding: "0.5rem",
    color: "black",

    background: "#FF4741",
  },
  textField: {
    margin: "0.5rem",
    width: "10px",
  },
});

export default useStyle;
